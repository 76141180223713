import React from "react";
import {observer} from "mobx-react-lite";
import MITextField from "../Widgets/MITextField";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useResponsive} from "../../services/useResponsive";

const desktopStyles = theme => {
    return {
        siteSearch: {
            display: 'inline-block',
            width: '100%',
            marginTop: theme.sizes.small_gap,
            background: theme.palette.background.paper,
        },
    }
};

const mobileStyles = theme => {
    return {
        siteSearch: {

            display: 'inline-block',
            width: 'fill-available',
            background: theme.palette.background.paper,
            marginTop: theme.sizes.nano_gap,
            marginBottom: theme.sizes.small_gap,
            marginStart: theme.sizes.micro_gap,
            marginEnd: theme.sizes.micro_gap,
        }
    }
};

const SearchTextField = observer((props) => {
    const {t} = useTranslation();
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    return (
        <MITextField
            {...props}
            className={classes.siteSearch}
            ariaLabel={t('aria_label_site_search')}
            variant='outlined'
            name='site-search'
            placeholder={t('search_placeholder')}
        />
    );
})

export default SearchTextField;