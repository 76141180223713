import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { useTranslation } from 'react-i18next';
import MIModal from '../../../components/Widgets/MIModal';
import MIButton from '../../../components/Widgets/MIButton';
import MICheckbox from '../../../components/Widgets/MICheckbox';
import WRadioGroup from '../../../components/Widgets/WRadioGroup';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import {
    CONDITION_EQUALS,
    CONDITION_GREATER_THAN,
    CONDITION_SEARCH,
    CONDITION_TIME_AFTER, TIME_1_DAY, TIME_1_MONTH, TIME_1_WEEK, TIME_YTD
} from '../../../rentokil-react-ui';
import { isEmpty } from '../../../utils/utils';
import {
    DEVICE_TYPE_BATH_MAT,
    DEVICE_TYPE_DISINFECTION,
    DEVICE_TYPE_HAND_HYGIENE,
    DEVICE_TYPE_INDOOR_AIR_HYGIENE,
    DEVICE_TYPE_SANITARY_BIN,
    DEVICE_TYPE_WASHROOM_HYGIENE,
    DEVICE_TYPE_WC_HYGIENE
} from '../../../services/floorplan/useLocationIcon';
import IconButton from '@material-ui/core/IconButton';
import HighlightOff from '@material-ui/icons/HighlightOff';
import { MITextField } from '../../../rentokil-react-ui';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => {
    return {
        titleContainer: {
            width: '530px',
            display: 'inline-flex',
            flexDirection: 'column',
        },
        title: {
            fontWeight: 'bold',
            fontSize: theme.sizes.large_gap,
            fontFamily: theme.typography.h1.fontFamily,
        },
        bodyContainer: {
            width: '100%',
        },
        button: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        topSection: {
            display: 'flex',
            flexDirection: 'row',
        },
        counts: {
            fontFamily: theme.typography.h1.fontFamily,
            fontSize: theme.typography.body1.fontSize,
            marginInlineStart: theme.sizes.small_gap,
        },
        selectedCount: {
            marginBlockStart: theme.sizes.nano_gap,
            marginBlockEnd: theme.sizes.nano_gap,
        },
        deviceCount: {
            fontSize: theme.sizes.small_gap,
            marginBlockStart: theme.sizes.nano_gap,
            marginBlockEnd: theme.sizes.nano_gap,
            color: theme.palette.grey['500'],
        },
        separator: {
            width: '100%',
            height: '1px',
            background: theme.palette.grey['300'],
            marginBlockStart: theme.sizes.small_gap,
            marginBlockEnd: theme.sizes.small_gap,
        },
        groupHeader: {
            marginBlockEnd: theme.sizes.nano_gap,
            fontFamily: theme.typography.h1.fontFamily,
        },
        filterSection: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        filterColumn: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
        },
        clearActivityContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginInlineStart: '-12px',
            alignItems: 'center',
        },
        clearActivityIcon: {
            display: 'flex',
            width: '42px',
        },
        clearActivityText: {
            display: 'flex',
        },
    };
});

const FilterPopup = observer((props) => {
    const { isShowing, onClose, filter, deviceCount, deviceTotal } = props;
    const { t } = useTranslation();
    const { control } = useForm();
    const classes = useStyles();
    const [ searchValue, setSearchValue ] = useState('');
    const detectorGroup = 'detectors';
    const detectorField = 'detectorSearch';

    const clearSearch = () => {
        if(!isEmpty(filter.getValue(detectorGroup, detectorField, CONDITION_SEARCH))) {
            filter.removeFilter(detectorGroup, detectorField, CONDITION_SEARCH, searchValue);
        }
    };

    const handleSearch = (value) => {
        clearSearch();
        setSearchValue(value);
        if(!isEmpty(value)) {
            filter.addFilter(detectorGroup, detectorField, CONDITION_SEARCH, value);
        }
    };

    const handleCheckboxEvent = (event, field) => {
        let condition;
        let value;
        switch (field) {
        case 'type':
            condition = CONDITION_EQUALS;
            value = event.target.name;
            break;
        case 'openRecommendations':
            condition = CONDITION_GREATER_THAN;
            value = 0;
            break;
        default:
            console.error('Unknown type');
        }
        if (condition) {
            if (event.target.checked) {
                filter.addFilter(field, field, condition, value);
            } else {
                filter.removeFilter(field, field, condition, value);
            }
        }
    };

    const handleClearAll = () => {
        filter.reset();
        clearSearch();
    };

    const getHeader = () => {
        return (
            <div className={classes.titleContainer} tabIndex={0} aria-label={'filters'}>
                <span className={classes.title}>{t('filters')}</span>
            </div>
        );
    };

    const addCheckbox = (field, name, value) => {
        value = value !== undefined ? value : name;
        return <MICheckbox
            groupName={field}
            name={name}
            label={t(name)}
            handleChange={handleCheckboxEvent}
            checked={filter.hasCondition(field, field, value)}
        />;
    };

    const timePeriodOptions = [
        { label: t('last_24_hours'), value: TIME_1_DAY },
        { label: t('last_1_week'), value: TIME_1_WEEK },
        { label: t('last_1_month'), value: TIME_1_MONTH },
        { label: t('ytd'), value: TIME_YTD },
    ];

    const activityField = 'localLatestInfestationDate';
    const currentActivityValue = filter.getValue(activityField, activityField, CONDITION_TIME_AFTER);
    const handleLastActivityChange = (event) => {
        if (isEmpty(currentActivityValue)) {
            filter.addFilter(activityField, activityField, CONDITION_TIME_AFTER, event.target.value);
        } else if (event.target.value === currentActivityValue) {
            filter.removeFilter(activityField, activityField, CONDITION_TIME_AFTER, event.target.value);
        } else {
            filter.removeFilter(activityField, activityField, CONDITION_TIME_AFTER, currentActivityValue);
            if (!isEmpty(event.target.value)) {
                filter.addFilter(activityField, activityField, CONDITION_TIME_AFTER, event.target.value);
            }
        }
    };

    const clearActivity = () => {
        if (!isEmpty(currentActivityValue)) {
            filter.removeFilter(activityField, activityField, CONDITION_TIME_AFTER, currentActivityValue);
        }
    };

    const getTopSection = () => {
        return (
            <div className={classes.topSection}>
                <MIButton
                    ariaLabel={t('filters')}
                    mi_size="small"
                    mi_type="primary"
                    variant="outlined"
                    type="button"
                    onClick={handleClearAll}
                >
                    <div className={classes.button}>
                        {t('clear_all')}
                    </div>
                </MIButton>
                <div className={classes.counts}>
                    <p className={classes.selectedCount}>{filter.filterCount()} {t('filters_selected')}</p>
                    <p className={classes.deviceCount}>{deviceCount}/{deviceTotal} {t('devices')}</p>
                </div>
            </div>
        );
    };

    const getFilterSection = () => {
        return (
            <div className={classes.filterSection}>
                <div className={classes.filterColumn}>
                    <h3 className={classes.groupHeader}>{t('recommendations')}:</h3>
                    {addCheckbox('openRecommendations', 'recommendations', 0)}
                    <h3 className={classes.groupHeader}>{t('last_recorded_activity')}:</h3>
                    <div className={classes.clearActivityContainer}>
                        <IconButton
                            className={classes.clearActivityIcon}
                            aria-label={t('clear_selection')}
                            onClick={clearActivity}
                        >
                            <HighlightOff/>
                        </IconButton>
                        <span className={classes.clearActivityText}>{t('clear_selection')}</span>
                    </div>
                    <WRadioGroup
                        control={control}
                        ariaLabel={t('last_recorded_activity')}
                        name="last_recorded_activity"
                        options={timePeriodOptions}
                        value={currentActivityValue}
                        handleChange={handleLastActivityChange}
                    />
                </div>
                <div className={classes.filterColumn}>
                    <h3 className={classes.groupHeader}>{t('device_type')}:</h3>
                    {addCheckbox('type', DEVICE_TYPE_HAND_HYGIENE)}
                    {addCheckbox('type', DEVICE_TYPE_DISINFECTION)}
                    {addCheckbox('type', DEVICE_TYPE_WASHROOM_HYGIENE)}
                    {addCheckbox('type', DEVICE_TYPE_SANITARY_BIN)}
                    {addCheckbox('type', DEVICE_TYPE_INDOOR_AIR_HYGIENE)}
                    {addCheckbox('type', DEVICE_TYPE_WC_HYGIENE)}
                    {addCheckbox('type', DEVICE_TYPE_BATH_MAT)}
                </div>
            </div>
        );
    };

    const getDetectorSearchSection = () => {
        return (
            <MITextField
                className={classes.faqSearch}
                ariaLabel={t('aria_label_detector_search')}
                variant="outlined"
                name="search_detectors"
                placeholder={t('search_detectors')}
                value={filter.getValue(detectorGroup, detectorField, CONDITION_SEARCH)}
                onChange={(event) => {
                    const { value } = event.target;
                    handleSearch(value);
                }}
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon}/>
                        </InputAdornment>

                }}
            />
        );
    };

    const getBody = () => {
        return (
            <div className={classes.bodyContainer}>
                {getTopSection()}
                <div className={classes.separator}/>
                {getDetectorSearchSection()}
                {getFilterSection()}
            </div>
        );
    };

    return (
        <MIModal
            header={getHeader()}
            body={getBody()}
            isShowing={isShowing}
            onClose={onClose}
            customStyle={{ rootHeight: 'fit-content' }}
        />
    );
});

export default FilterPopup;
