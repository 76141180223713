import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Grid } from '@material-ui/core';
import MIModal from '../Widgets/MIModal';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import MICountry from '../Widgets/MICountry';
import { observer } from 'mobx-react-lite';
import { getBool } from '../../utils/utils';
import { useResponsive } from '../../services/useResponsive';
import { isEmpty } from '../../utils/utils';
import { SUCCESS_STATUS } from '../../contexts/AuthProvider/AuthConst';

const desktopStyles = (theme) => {
    return {
        root: {
            flexGrow: 1,
        },
        title: {
            color: theme.colors.mainText,
            margin: '0px'
        },
        titleContainer: {
            'display': 'inline-flex',
            '&:focus': theme.ariaFocus,
            'justifyContent': 'center',
            'alignItems': 'center',
            'alignContent': 'center',
        },
        languageSwitcher: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            width:'100%',
        },
        regionTitle: {
            color: theme.colors.mainText,
            fontWeight: theme.typography.fontWeightBold,
        },
        regionContainer: {
            marginTop: theme.sizes.small_gap,
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        title: {
            ...baseStyle.regionTitle,
            fontSize: theme.fontSizes.mobileTitle,
            margin: theme.sizes.no_gap,
            marginTop: theme.sizes.femto_gap,
        },
        regionTitle: {
            ...baseStyle.regionTitle,
            fontSize: theme.fontSizes.mobileBody,
        },
        languageSwitcher: {
            justifyContent: 'left',
            alignItems: 'left',
            alignContent: 'left',
        },
        titleContainer: {
            paddingBottom: theme.sizes.pico_gap,
        },
    });
};


const CountrySelector = observer((props) => {
    const { t } = useTranslation();
    const { countriesList = {}, isShowing, changeStatus, hideCloseIcon, changeLanguage } = props;
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );
    const classes = useStyles();

    const handleClick = () => {
        changeStatus();
    };

    const getHeader = () => {
        return <div className={classes.titleContainer} tabIndex={0} aria-label={t('aria_label_select_language_and_country_title')} >
            <h2 className={classes.title}>{t('select_language_and_country')}</h2>
        </div>;
    };

    function renderRegion(region) {
        const regionData = countriesList[region];
        const adminDetails = isEmpty(regionData) || regionData.status !== SUCCESS_STATUS ? [] : regionData.adminDetails;
        return (
            !isEmpty(adminDetails) ?
                <Fragment key={region}>
                    <Grid className={classes.regionContainer} item xs={12}/>
                    {adminDetails.map((countryData) => {
                        return <MICountry key={countryData.countryCode} regionName={region} regionData={regionData} countryData={countryData} handleClick={handleClick} changeLanguage={changeLanguage}/>;
                    }
                    )}
                </Fragment> : null
        );
    }


    const getBody = () => {
        return (
            <div className={classes.root}>
                <LanguageSwitcher className={classes.languageSwitcher}/>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    {Object.keys(countriesList).map((region) => {
                        return renderRegion(region);
                    }
                    )}
                </Grid>
            </div>
        );
    };

    return (
        <MIModal
            header={getHeader()}
            body={getBody()}
            isShowing={isShowing}
            customStyle={getStyles({
                desktop: { rootHeight: 'fit-content', rootWidth:'500px', centerTitle: 'true' },
                mobile: { rootHeight: 'fit-content', rootWidth:'300px', mobilePaddingLeft: '0px' }
            })}
            hideCloseIcon={getBool(hideCloseIcon)}
            onClose={!getBool(hideCloseIcon) ? handleClick : () => {}}
        />
    );
});

export default CountrySelector;
