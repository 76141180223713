import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthState } from '../../contexts/AuthProvider';
import { useTranslation } from 'react-i18next';
import { getQueryString, isEmpty } from '../../utils/utils';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import { FETCHING } from '../../services/requests/requestReducer';
import { ResponsiveComponent, useResponsive } from '../../services/useResponsive';
import { LOGGED_IN_STATUS, PENDING_STATUS } from '../../contexts/AuthProvider/AuthConst';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import HomeBanner from '../../components/Marketing/HomeBanner';
import Theme from '../../components/Theme/Theme';

const useStyles = makeStyles((theme) => {
    return {
        headerAndFooter: {
            background: theme.palette.background.paper,
        },
    };
});

const LoginSSO = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { status, loginViaSSO } = useAuthState();
    const authStatus = status();
    const { getStyles } = useResponsive();

    const spinnerMarginTop = getStyles({
        desktop: Theme.sizes.xxlarge_gap,
        mobile: Theme.sizes.giga_gap
    });

    useEffect(() => {
        if (window &&
            authStatus !== LOGGED_IN_STATUS &&
            authStatus !== PENDING_STATUS) {
            const { token, redirectURL } = getQueryString();
            if (!isEmpty(token) && !isEmpty(redirectURL)) {
                loginViaSSO(token, redirectURL);
            }
        }
    }, [ authStatus, loginViaSSO ]);

    return (
        <div>
            <div className={classes.headerAndFooter}>
                <ResponsiveComponent
                    components={{
                        mobile: null,
                        desktop: <NavigationBar routes={[]} showNavBar={false}/>,
                    }}
                />
            </div>
            <main>
                <ResponsiveComponent
                    components={{
                        mobile: null,
                        desktop: <HomeBanner/>,
                    }}
                />
            </main>
            <MIApiLoader
                customStyle={{ marginTop: spinnerMarginTop }}
                MIComponent={<div></div>}
                responseStatus={FETCHING}
                ariaLabelProgressBar={t('aria_label_progressbar_app')}
            />
        </div>
    );
};

export default LoginSSO;
