import {useState, useEffect, useCallback} from 'react';
import {SUCCESS} from '../requests/requestReducer';
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";
import {useAuthState} from "../../contexts/AuthProvider";
import {useTranslation} from "react-i18next";
import {useRecommendationsStore} from "../../contexts/StateProvider/StateProvider";

export const getLogStatus = (currentStatus, newStatus) => {
    return currentStatus === newStatus ? '' : `(${currentStatus} -> ${newStatus})`;
};

export const useUpdateRecommendation = () => {
    const [updateResponse, setUpdateResponse] = useState({});
    const [{responseStatus, response}, makeFloorPlanApiRequestWithParams] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/updateRecommendationLog';
    const {forename, surname, emailAddress, countryCode} = useAuthState();
    const {t} = useTranslation();
    const recommendationsStore = useRecommendationsStore();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setUpdateResponse(response.data);
                // wipe the cached recommendations so it will update the list with the new status
                recommendationsStore.reset();
            } catch (error) {
                console.error('useUpdateRecommendationDetails error: ', error);
            }
        }
    }, [responseStatus, response, setUpdateResponse, recommendationsStore]);

    const updateRecommendation = useCallback((recommendationId, commentId, comment, currentStatus, newStatus) => {
        setUpdateResponse({});
        const url = [endpoint, recommendationId].join('/');
        const params = {
            commentId: commentId,
            status: newStatus,
            previousStatus: currentStatus,
            username: `${forename} ${surname}`,
            email: emailAddress,
            reasonForChange: getLogStatus(t(currentStatus.toLowerCase()), t(newStatus.toLowerCase())),
            countryCode: countryCode,
            comment: comment
        };

        makeFloorPlanApiRequestWithParams(url, 'post', params).catch((error) => {
            console.error('useUpdateRecommendation error: ', error);
        });
    }, [makeFloorPlanApiRequestWithParams, forename, surname, emailAddress, countryCode, t]);

    return [updateResponse, responseStatus, updateRecommendation];
};
