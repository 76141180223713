import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getGMTOffsetDateFromString, getLocalizedDate, getLocalizedStatus } from '../../utils/utils';
import { useAuthState } from '../../contexts/AuthProvider';
import { FETCHING } from '../../services/requests/requestReducer';
import { observer } from 'mobx-react-lite';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import RecommendationsDesktop from './variant/RecommendationsDesktop';
import RecommendationsMobile from './variant/RecommendationsMobile';
import { isEmpty } from '../../utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import { ResponsiveComponent, useResponsive } from '../../services/useResponsive';
import { useHistory } from 'react-router-dom';
import DownloadRecommendations from './DownloadRecommendations';
import { useGetMyRentokilSiteId } from '../../services/recommendations/useGetMyRentokilSiteId';
import OpenRecommendationsCount from './OpenRecommendationsCount';
import { useGetRecommendations } from '../../services/recommendations/useGetRecommendations';
import { useGetPNOLShutdownCountries } from '../../services/recommendations/useGetPNOLShutdownCountries';
import { MYRENTOKILSITEID } from '../../pages/Service/FloorPlan/FloorPlanEnums';

const PDF_ENDPOINT_TYPE = 'pdf';
const EXCEL_ENDPOINT_TYPE = 'excel';

export const useBaseStyles = makeStyles((theme) => {
    return {
        linkContainer: {
            margin: 'auto',
            textAlign: 'center',
        },
        selectLink: {
            'display': 'flex',
            '&, &:visited, &:hover': {
                color: theme.palette.primary.main
            },
            '&:focus': theme.ariaFocus,
        },
    };
});

const mobileStyles = (theme) => {
    return {
        content: {
            paddingLeft: theme.sizes.micro_gap,
            paddingRight: theme.sizes.micro_gap,
        },
    };
};

const Recommendations = observer((props) => {
    const {t} = useTranslation();
    const [openRecommendations, responseStatusGetRecommendations, getRecommendations] = useGetRecommendations();
    const [ PNOLShutdownCountries, responseStatusPNOL, getPNOLShutdownCountries ] = useGetPNOLShutdownCountries();
    const { countryCode, locale } = useAuthState();
    const siteStore = useSiteStore();
    const history = useHistory();
    const [ myRentokilSiteId, responseStatusGetMyRentokilSiteId, getMyRentokilSiteId ] = useGetMyRentokilSiteId();
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: null,
            mobile: mobileStyles(theme)
        });
    });

    const classes = useStyles();

    const getSiteId = useCallback(() => {
        return myRentokilSiteId !== MYRENTOKILSITEID.NO_ID && !isEmpty(myRentokilSiteId) ? myRentokilSiteId : '';
    }, [ myRentokilSiteId ]);

    useEffect(() => {
        if (!isEmpty(countryCode) && !isEmpty(siteStore) && !isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber)) {
            getMyRentokilSiteId(countryCode, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber);
        }
    }, [ getMyRentokilSiteId, countryCode, siteStore, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber ]);

    useEffect(() => {
        if (!isEmpty(myRentokilSiteId) && !isEmpty(countryCode) && !isEmpty(locale)) {
            getRecommendations(countryCode, locale, myRentokilSiteId);
            getPNOLShutdownCountries();
        }
    }, [ myRentokilSiteId, getRecommendations, countryCode, locale, getPNOLShutdownCountries ]);
    
    const getLocation = (location) => {
        const department = !isEmpty(location.department) ? location.department : {};
        const floor = !isEmpty(department) ? department.floor : {};
        const building = !isEmpty(floor) ? floor.building : {};

        const specificLocation = [ building.description, floor.description, department.description ].join(', ');
        return !isEmpty(specificLocation) ? [ location.description, ' (', specificLocation, ')' ].join('') : location.description;
    };

    const getRows = () => {
        let rows = [];
        if (!isEmpty(openRecommendations) && openRecommendations.length > 0) {
            openRecommendations.forEach((record) => {
                // not all the recommendations have the location set
                if (!isEmpty(record.location)) {
                    const filteredDate = !isEmpty(record.visit?.localStartDateTime) ? record.visit.localStartDateTime.split(' ')[0].replaceAll('-', '/') : null; // Remove time from date and format
                    rows.push({
                        id: record.id,
                        priority: !isEmpty(record.priority) ? record.priority.descriptionTranslated : '-',
                        locStatus: !isEmpty(record.status) ? getLocalizedStatus(t,record.status) : '-',
                        status: !isEmpty(record.status) ? record.status : '-',
                        locDate: !isEmpty(filteredDate) ? getLocalizedDate(t, filteredDate, 'YYYY/MM/DD') : '-',
                        date: !isEmpty(filteredDate) ? getGMTOffsetDateFromString(filteredDate, 'YYYY/MM/DD') : null,
                        ref: !isEmpty(record.reference) ? record.reference.toString() : '-',
                        type: !isEmpty(record.recommendationType) ? record.recommendationType.descriptionTranslated : '-',
                        location: !isEmpty(record.location) ? getLocation(record.location) : '-',
                        description: !isEmpty(record.text) ? record.text : '-',
                        recommendationNumber: record.id,
                    });
                }
            });
        }

        return rows;
    };

    const baseUrl = [
        '/floorPlanApi/exportRecommendations',
        countryCode,
        locale,
        getSiteId(),
    ].join('/');

    const buttonData = [
        { title: t('excel'), url:[ baseUrl, EXCEL_ENDPOINT_TYPE ].join('/') },
        { title: t('pdf'), url:[ baseUrl, PDF_ENDPOINT_TYPE ].join('/') }
    ];

    const goToRecommendationDetails = (data) => {
        return history.push({
            pathname: '/service/recommendations/recommendation-details',
            state: {
                data: data,
                myRentokilSiteId: getSiteId(),
                PNOLShutdownCountries: isEmpty(PNOLShutdownCountries) ? '' : PNOLShutdownCountries.toString()
            }
        });
    };

    const params = {
        isLoading: responseStatusGetRecommendations === FETCHING ||
            responseStatusGetMyRentokilSiteId === FETCHING ||
            responseStatusPNOL === FETCHING,
        rows: getRows(),
        myRentokilSiteId: getSiteId(),
        goToRecommendationDetails,
        PNOLShutdownCountries: PNOLShutdownCountries
    };

    const mobileParams = {
        showRowCount: true,
        mobilePageSize: 10,
    };

    return (
        <div>
            <div className={classes.content}>
                <DownloadRecommendations buttonData={buttonData} myRentokilSiteId={getSiteId()}/>
                <OpenRecommendationsCount myRentokilSiteId={myRentokilSiteId}/>
            </div>
            <ResponsiveComponent
                components={{
                    mobile: <RecommendationsMobile {...params} {...mobileParams} {...props}/>,
                    desktop: <RecommendationsDesktop {...params} {...props}/>
                }}
            />
        </div>
    );
});

export default Recommendations;
