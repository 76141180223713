import {useCallback, useEffect, useState} from "react";
import {SUCCESS} from "../requests/requestReducer";
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";

export const useGetRecommendationDetails = () => {
    const [recommendationDetails, setRecommendationDetails] = useState([]);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getRecommendationDetails';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setRecommendationDetails(response.data);
            } catch (error) {
                console.error('useGetRecommendationDetails error: ', error);
                setRecommendationDetails([]);
            }
        }
    }, [responseStatus, response, setRecommendationDetails]);

    const getRecommendationDetails = useCallback((countryCode, locale, recommendationId) => {
        const url = [endpoint, countryCode, locale, recommendationId].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetRecommendationDetails makeRequestWithParams: ', error);
        });
    }, [makeFloorPlanApiRequestWithParams]);

    return [recommendationDetails, responseStatus, getRecommendationDetails];
};
