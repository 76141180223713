import { useState, useEffect, useCallback, useRef } from 'react';
import useFloorPlanApiRequest from '../requests/useFloorPlanApiRequest';
import { ERROR, FETCHING, SUCCESS } from '../requests/requestReducer';
import { useRecommendationsStore } from "../../contexts/StateProvider/StateProvider";
import { isEmpty } from '../../utils/utils';
import { MYRENTOKILSITEID } from '../../pages/Service/FloorPlan/FloorPlanEnums';

const PAGE_LIMIT = 1000;

export const useGetRecommendations = () => {
    const recommendationsStore = useRecommendationsStore();
    const [ recommendations, setRecommendations ] = useState([]);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getRecommendations';
    const downloadCount = useRef(0);
    const total = useRef(0);
    const [ status, setStatus ] = useState('');
    const [ siteId, setSiteId ] = useState(null);
    const [ countryCode, setCountryCode] = useState(null);
    const [ locale, setLocale ] = useState(null);

    const getRecommendationsPage = useCallback((countryCode, locale, siteId, start, limit) => {
        const url = [ endpoint, countryCode, locale, siteId, start, limit ].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetRecommendations makeRequest: ', error);
        });
    }, [ makeFloorPlanApiRequestWithParams ]);

    const getRecommendations = useCallback((countryCode, locale, siteId) => {
        if(siteId === MYRENTOKILSITEID.NO_ID){
            setRecommendations([]);
            return;
        }
        const recommendations = recommendationsStore.getRecommendations(siteId);
        if (!isEmpty(recommendations)) {
            setRecommendations(recommendations);
        } else {
            setRecommendations([]);
            setSiteId(siteId);
            setLocale(locale);
            setCountryCode(countryCode);
            if (downloadCount.current === 0) {
                setStatus(FETCHING);
                downloadCount.current = 0;
                total.current = 0;
                getRecommendationsPage(countryCode, locale, siteId, 0, PAGE_LIMIT);
            } else {
                setStatus(SUCCESS);
            }
        }
    }, [ getRecommendationsPage, setStatus, recommendationsStore ]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                const downloaded = downloadCount.current + response.data.results.length;
                downloadCount.current = downloaded;
                total.current = response.data.count;
                const result = response.data.results;

                setRecommendations((currentRecommendations) => {
                    const newRecommendations = currentRecommendations.concat(result);
                    recommendationsStore.setRecommendations(siteId, newRecommendations);
                    return newRecommendations;
                });
                if (downloaded < response.data.count) {
                    getRecommendationsPage(countryCode, locale, siteId, downloaded, PAGE_LIMIT);
                } else {
                    //reset the download count
                    downloadCount.current = 0;
                    setStatus(SUCCESS);
                }
            } catch (error) {
                recommendationsStore.setRecommendations([]);
                setStatus(ERROR);
            }
        }
    }, [ responseStatus, response, siteId, countryCode, locale, getRecommendationsPage, recommendationsStore ]);

    return [ recommendations, status, getRecommendations ];

};
