import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {useContentFromUrl} from '../../services/useContentFromUrl';
import {setDocumentTitle} from '../../utils/utils';
import MIButton from '../../components/Widgets/MIButton';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useAuthState} from '../../contexts/AuthProvider/AuthProvider';
import { useUpdateUserPreference } from '../../services/useUpdateUserPreference';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import {SUCCESS, ERROR, FETCHING} from "../../services/requests/requestReducer";
import MIAlert from '../../components/Widgets/MIAlert';
import {useResponsive} from "../../services/useResponsive";
import { Typography } from '@material-ui/core';



const desktopStyles = theme => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        title: {
            marginTop: theme.sizes.xlarge_gap,
            display: 'inline-block',
        },
        titleOuter: {
            display: 'flex',
            height: theme.sizes.xxxlarge_gap,
            alignItems: 'center',
            justifyContent: 'center-left',
            width: '100%',
            marginBottom: '20px',
            '&:focus': theme.ariaFocus,
        },
        link: {
            marginBottom: theme.sizes.small_gap,
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            ":hover": { textDecoration: 'none' },
            ":visited": { textDecoration: 'none' },
            ":active": { textDecoration: 'none' },
            '&:focus': {
                outline: 'none'
            },
        },
        continueButton: {
            marginRight: theme.sizes.small_gap,
        },
        buttons: {
            marginTop: theme.sizes.small_gap,
        },
        continueButtonContainer: {
            display: 'inline-block',
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: '100vh',
            marginBottom: theme.sizes.no_gap,
            paddingBottom: theme.sizes.no_gap,
            fontFamily: theme.typography.fontFamily,
        },
        pageContents: {
            padding: theme.sizes.small_gap,
            fontSize: theme.fontSizes.mobile
        },
        title: {
            marginTop: theme.sizes.small_gap,
            fontSize: theme.fontSizes.mobileTitle,
        },
        titleOuter: {
            '&:focus': theme.ariaFocus,
        },
        acknowledgementText: {
            fontSize: theme.fontSizes.mobile,
        },
        continueButton: {
            marginRight: theme.sizes.small_gap,
            width: "40vw",
        },
        cancelButton: {
            width: "40vw",
        },
        checkboxAndText: {
            marginTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.small_gap,
        }
    })
}

const TermsAndConditions = () => {
    const {t} = useTranslation();
    const title = t('aria_label_link_opened_in_new_tab');
    const [content, , getContentFromUrl, getContentUrl] = useContentFromUrl();
    const {legalStatement, privacyPolicyLink} = content;
    const [acknowledged, setAcknowledged] = useState(false);
    const {logout} = useAuthState();
    const [result, responseStatus, updateUserPreference] = useUpdateUserPreference();
    const [alert, setAlert] = useState({});
    const [isShowing, setIsShowing] = useState(false);
    let status = responseStatus;

    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    useEffect(() => {
        const url = getContentUrl('../links.json');
        getContentFromUrl(url);
    }, [getContentFromUrl, getContentUrl]);

    useEffect(() => {
        setDocumentTitle(t, 'legal_and_privacy');
    }, [t]);

    const handleChange = () => {
       setAcknowledged(!acknowledged);
    };

    const handleClick = () => {
        updateUserPreference('true', 'NA');
    };

    useEffect(() => {
        if(result === 'success' && responseStatus === SUCCESS){
            window.location.reload();
        }
        if(result === 'error' || responseStatus === ERROR){
            showAlert();
        }
    }, [result, responseStatus]);

    if (status === SUCCESS) {
        status = FETCHING;
    }

    const showAlert = () => {
        setAlert({
            message: 'error_try_again',
            severity: 'error'
        })
        setIsShowing(true);
    }

    return (
        <Paper className={classes.root} aria-label={t('aria_label_legal_and_privacy_page')}>
            <div className={classes.pageContents}>
            <div className={classes.titleOuter} aria-label={t('aria_label_legal_and_privacy_title')} tabIndex={0}
                 aria-live={'polite'}>
                <h1 className={classes.title}>
                    {t('legal_and_privacy')}
                </h1>
            </div>
            <div>
                <p>{t('legal_and_privacy_content')}</p>
            </div>
            <div className={classes.link}>
                <a 
                    className={classes.link} 
                    target='_blank' 
                    rel='noopener noreferrer' 
                    href={['//', legalStatement].join('')}
                    title={title}
                    aria-label={t('aria_label_legal_link')}
                >
                    {t('legal_statement')}
                </a>
            </div>
            <div className={classes.link}>
                <a 
                    className={classes.link} 
                    target='_blank' 
                    rel='noopener noreferrer' 
                    href={['//', privacyPolicyLink].join('')}
                    title={title}
                    aria-label={t('aria_label_privacy_link')}
                >
                    {t('privacy_policy')}
                </a>
            </div>
            <div>
                <FormControlLabel
                    className={classes.checkboxAndText}
                    control={
                    <Checkbox
                        checked={acknowledged}
                        onChange={handleChange}
                        name={t('acknowledgement')}
                        color='primary'
                        inputProps={{ 'aria-label': t('aria_label_acknowledgement_checkbox')}}
                    />
                    }
                    label={<Typography className={classes.acknowledgementText}>{t('acknowledgement_statement')}</Typography>}
                />
            </div>
            <div className={classes.buttons}>
                <div className={classes.continueButtonContainer}>
                    <MIApiLoader
                        customStyle={{
                            'spinnerWidth': '25px',
                            'spinnerHeight': '25px',
                            marginLeft: '59px', 
                            marginRight: '60px',
                        }}
                        MIComponent={
                            <MIButton
                                className={classes.continueButton}
                                ariaLabel={t('aria_label_continue_button')}
                                mi_size='small'
                                mi_type={acknowledged ? 'primary' : 'disabled'}
                                onClick={handleClick}
                            >
                                {t('continue')}
                            </MIButton>
                        }
                        responseStatus={status}
                    />
                </div>
                <MIButton
                    className={classes.cancelButton}
                    ariaLabel={t('aria_label_cancel_button')}
                    mi_size='small'
                    mi_type='secondary'
                    onClick={logout}
                >
                    {t('cancel')}
                </MIButton>
            </div>
            <MIAlert isShowing={isShowing} setIsShowing={setIsShowing} message={t(alert.message)} severity={alert.severity} ariaLabel={t('aria_success_message')}/>
            </div>
        </Paper>
    );
};

export default TermsAndConditions;
