import { useCallback, useEffect, useState } from 'react';
import { SUCCESS, ERROR } from './requests/requestReducer';
import useApiRequest from './requests/useApiRequest';
import { isEmpty, getSiteAddress } from '../utils/utils';
import { usePremiseStore } from '../contexts/StateProvider/StateProvider';
import { useAuthState } from '../contexts/AuthProvider';

export const usePremiseList = () => {
    const premiseStore = usePremiseStore();
    const [ premiseList, setPremiseList ] = useState([]);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const [ premiseRequestCount, setPremiseRequestCount ] = useState(0);
    const { countryCode, locale } = useAuthState();
    const endpoint = '/premiseService/premiseList';

    const decrementRequestCount = useCallback(() => {
        if (premiseRequestCount > 0) {
            setPremiseRequestCount(premiseRequestCount - 1);
        }
    }, [ premiseRequestCount ]);


    const getPremiseList = useCallback((contractId) => {
        const params = {
            locale: locale,
            contractId: contractId,
            countryCode: countryCode
        };
        makeRequestWithParams(endpoint, 'post', params)
            .catch((error) => {
                console.error('premiseList makeRequest: ', error);
            });
    }, [ makeRequestWithParams, countryCode, locale ]);

    const getContractPremises = useCallback((contracts) => {
        let pendingRequestCount = 0;
        let completeList = [];
        contracts.forEach(async(contract) => {
            const premises = premiseStore.getPremises(contract.contractNo);
            if (!isEmpty(premises)) {
                completeList = completeList.concat(premises);
            } else {
                getPremiseList(contract.contractNo);
                pendingRequestCount++;
            }
        });
        setPremiseList(completeList);
        setPremiseRequestCount(pendingRequestCount);
    }, [ getPremiseList, setPremiseList, premiseStore ]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            decrementRequestCount();
            try {
                const newPremises = [ ...response.data.premise ].map((premise) => {
                    const newPremise = JSON.parse(JSON.stringify(premise));
                    newPremise.siteAddress = getSiteAddress(premise, countryCode);
                    return newPremise;
                });
                const completeList = newPremises.concat(premiseList);
                setPremiseList(completeList);
                const contactId = JSON.parse(response.config.data).contractId;
                premiseStore.setPremises(contactId, completeList);
            } catch (error) {
                console.error('usePremiseList error: ', error);
            }
        } else if(responseStatus === ERROR) {
            // The request failed but we need to decrement the request count otherwise it will be stuck in loading
            decrementRequestCount();
        }
        // depending on premiseList would create an endless loop here so we'll disable the warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ responseStatus, response, setPremiseList, countryCode ]);

    return [ premiseList, getContractPremises, responseStatus, premiseRequestCount ];
};
