import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { calcClusters } from '../utils/clusters';
import { LocationsLayer } from './LocationsLayer';
import { RecsLayer } from './Layers/RecsLayer';
import { BullseyeLayer } from './Layers/BullseyeLayer';
import { isObjectEmpty } from '../utils/utils';
import { MapLayer, MIN_SCALE } from './MapLayer';
import HeatMap from './HeatMap';
import MISlider from '../widgets/MISlider';

const useStyles = makeStyles((theme) => ({
    mapOuter: {
        flex: 1,
        position: 'relative',
        overflow: 'visible',
        width: '100%',
        height: '100%',
        '& canvas.heatmap-canvas': {
            pointerEvents: 'none',
        },
    },
}));

const FloorPlanMapImpl = (props) => {
    const { t } = useTranslation();
    const {
        image,
        points,
        handleDetectorClick,
        width,
        height,
        showHeatMap,
        heatMapEnabled,
        showSlider,
        showLabels,
        minSlider,
        maxSlider,
        valueSlider,
        marks,
        onChangeSlider,
        timePeriod,
        heatMapPoints,
        getHeatMapSpot,
        sliderCustomStyle,
        selectedId,
        showRecommendationsLayer,
        recommendations,
        recsCountFilter,
        activities,
        showBullseyeLayer,
        hideControls,
        forceVisibility,
        disableClustering,
        customStyle
    } = props;
    const classes = useStyles({ width: width, height: height });
    const [ value, setValue ] = useState({
        scale: MIN_SCALE,
        translation: { x: 0, y: 0 }
    });
    const [ detectorScale, setDetectorScale ] = useState(0);
    const [ clusters, setClusters ] = useState(null);
    const containerRef = useRef(null);

    const getLocationScale = useCallback(() => detectorScale * value.scale, [ detectorScale, value.scale ]);

    useEffect(() => {
        if (disableClustering) {
            let clusterGroups = null;
            if (!isObjectEmpty(points)) {
                clusterGroups = {
                    clusters: [],
                    singles: points.map((_, index) => index)
                };
            }
            setClusters(clusterGroups);
        } else {
            setClusters(calcClusters(points, getLocationScale()));
        }
    }, [ points, getLocationScale, disableClustering ]);

    const getHeatMapLayer = () => heatMapEnabled &&
            <div>
                {showSlider &&
                <div id={'heat-map-slider'}>
                    <MISlider
                        min={minSlider}
                        max={maxSlider}
                        value={valueSlider}
                        marks={marks}
                        onChange={onChangeSlider}
                        sliderCustomStyle={sliderCustomStyle}
                    />
                </div>}
                <HeatMap
                    containerRef={containerRef}
                    points={heatMapPoints}
                    transform={value}
                    detectorScale={detectorScale}
                    timePeriod={timePeriod}
                    getHeatMapSpot={getHeatMapSpot}
                    showHeatMap={showHeatMap}
                    width={width}
                    height={height}
                />
            </div>;

    return (
        <div className={classes.mapOuter} ref={containerRef} id="floor-plan">
            <MapLayer
                image={image}
                imageAlt={t('floor_plan_image')}
                imageAriaLabel={t('aria_label_floorplan_image')}
                scale={value.scale}
                translationX={value.translation.x}
                translationY={value.translation.y}
                width={width}
                height={height}
                setValue={setValue}
                setDetectorScale={setDetectorScale}
                hideControls={hideControls}
                forceVisibility={forceVisibility}
                customStyle={customStyle}
            />
            {getHeatMapLayer()}
            <LocationsLayer
                points={points}
                clusters={clusters}
                scale={getLocationScale()}
                width={width}
                height={height}
                translation={value.translation}
                handleDetectorClick={handleDetectorClick}
                showLabels={showLabels}
                selectedId={selectedId}
            />
            {showBullseyeLayer && <BullseyeLayer
                points={points}
                clusters={clusters}
                scale={getLocationScale()}
                width={width}
                height={height}
                translation={value.translation}
                handleClick={handleDetectorClick}
                activities={activities}
            />}
            {showRecommendationsLayer && <RecsLayer
                points={points}
                clusters={clusters}
                scale={getLocationScale()}
                width={width}
                height={height}
                translation={value.translation}
                handleClick={handleDetectorClick}
                recommendations={recommendations}
                recsCountFilter={recsCountFilter}
            />}
        </div>
    );
};

export const FloorPlanMap = memo(FloorPlanMapImpl);
