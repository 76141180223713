import React, {useEffect, useState} from 'react';
import {useAuthState} from "../../contexts/AuthProvider";
import {useExistingReports} from "../../services/useExistingReports";
import {useTranslation} from "react-i18next";
import MIAlert from "../../components/Widgets/MIAlert";
import {isEmpty, formatStringDate, getGMTOffsetDateFromString, getLocalizedDate} from '../../utils/utils';
import ExistingReportsTableDesktop from "./variant/ExistingReportsTableDesktop";
import ExistingReportsTableMobile from "./variant/ExistingReportsTableMobile";
import {observer} from "mobx-react-lite";
import {ResponsiveComponent} from "../../services/useResponsive";

const ExistingReportsTable = observer((props) => {
    const {t} = useTranslation();
    const {locale, countryCode} = useAuthState();
    const [existingReports, responseStatus, getExistingReports] = useExistingReports();
    const [isShowing, setIsShowing] = useState(false);
    const [alert, setAlert] = useState({});

    useEffect(() => {
        if (!isEmpty(locale)) {
            getExistingReports(
                countryCode,
                locale
            )
        }
    }, [locale, getExistingReports]);

    //"isSchedule" is a typo in the API
    let rows = [];
    if (!isEmpty(existingReports)) {
        existingReports.forEach((report) => {
            if (report.reportId) {
                rows.push({
                    id: report.reportId,
                    reportTitle: report.reportTitle,
                    nextRunLocDate: report.nextRundate === 'Not Scheduled' ? '-' : getLocalizedDate(t, formatStringDate(report.nextRundate, 'YYYY/MM/DD', 'DD/MM/YYYY')),
                    nextRunDate: report.nextRundate === 'Not Scheduled' ? null : getGMTOffsetDateFromString(formatStringDate(report.nextRundate, 'YYYY/MM/DD', 'DD/MM/YYYY')),
                    isScheduled: report.isSchedule === 'Y' ? t('yes') : t('no'),
                    visitType: report.visitType ? report.visitType : t('all'),
                    report: report,

                });
            }
        });
    }

    const params = {
        getExistingReports,
        setAlert,
        setIsShowing, 
        responseStatus, 
        rows,
    };

    const mobileParams = {
        showRowCount: true,
        mobilePageSize: 10,
    };

    return (
        <div>
            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                severity={alert.severity}
                ariaLabel={t('aria_label_operation_response_dialog')}
            />

            <ResponsiveComponent
                components={{
                    mobile: <ExistingReportsTableMobile {...params} {...mobileParams} {...props}/>,
                    desktop: <ExistingReportsTableDesktop {...params} {...props}/>,
                }}
            />
        </div>
    )
});

export default ExistingReportsTable;
