import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {observer} from "mobx-react-lite";
import {useResponsive} from '../../services/useResponsive';
import {isEmpty} from '../../utils/utils';

const desktopStyles = (theme) => {
    return {
        tabContainer: {
            "& span.MuiTabs-indicator": {
                height: '4px',
            },
            marginRight: 'auto',
            width: '50%',
        },
        tab: {
            textTransform: "none",
            fontWeight: theme.typography.fontWeightBold,
            minWidth: "50%",
            color: theme.colors.mainText,
            fontSize: theme.fontSizes.nav,
            "&.Mui-selected": {
                fontWeight: theme.typography.fontWeightBold,
            },
            borderBottom: '4px solid #CDD2D5',
        },
    }
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        tabContainer: {
            ...baseStyle.tabContainer,
            height: theme.sizes.mobile_tabs,
            width: '100%',
        },
        tab: {
            ...baseStyle.tab,
            padding: [theme.sizes.micro_gap, theme.sizes.pico_gap].join(' '),
            fontSize: theme.fontSizes.mobileTitle,
        }
    });
};
const MITabs = observer((props) => {
    const {tabs} = props;
    const [value, setValue] = useState(0);
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Tabs
                className={classes.tabContainer}
                value={value}
                onChange={handleChange}
                indicatorColor='primary'
                textColor="primary"
                centered
            >
                {!isEmpty(tabs) && tabs.map(({label, groupId}, index) => (
                    <Tab className={classes.tab} key={groupId} value={index} label={label} aria-label={groupId}/>
                ))}
            </Tabs>
            
            {tabs[value].table}
        </>
    );
});

export default MITabs;
