import React, {createContext, useContext} from 'react';
import {SiteStore} from "./SiteStore";
import {isEmpty} from "../../utils/utils";
import {ContractStore} from "./ContractStore";
import {VisitStore} from "./VisitStore";
import {PremiseStore} from "./PremiseStore";
import {LanguageStore} from "./LanguageStore";
import {RegionStore} from "./RegionStore";
import {HealthStatusStore} from "./HealthStatusStore";
import { DetectorStore } from './DetectorStore';
import {WasteStore} from "./WasteStore";
import { FilterStore } from '../../rentokil-react-ui';
import { DevicesMappingStore } from './DevicesMappingStore';
import { RecommendationsStore } from './RecommendationsStore';
import { NotificationsStore } from './NotificationsStore';
import { EntitlementsStore } from './EntitlementsStore';

class LocalStore {
    contractStore;
    detectorStore;
    siteStore;
    visitStore;
    languageStore;
    premiseStore;
    healthStatus;
    wasteStore;
    filterStore;
    devicesMappingStore;
    recommendationsStore;
    notificationsStore;
    entitlementsStore;

    constructor() {
        this.contractStore = new ContractStore();
        this.siteStore = new SiteStore();
        this.visitStore = new VisitStore();
        this.premiseStore = new PremiseStore();
        this.regionStore = new RegionStore();
        this.languageStore = new LanguageStore(this.regionStore);
        this.healthStatus = new HealthStatusStore();
        this.wasteStore = new WasteStore();
        this.detectorStore = new DetectorStore();
        this.devicesMappingStore = new DevicesMappingStore();
        this.recommendationsStore = new RecommendationsStore();
        this.filterStore = new FilterStore();
        this.notificationsStore = new NotificationsStore();
        this.entitlementsStore = new EntitlementsStore();
    }

    reset() {
        this.resetCountry();
        this.regionStore.reset();
        this.detectorStore.reset();
        this.devicesMappingStore.reset();
        this.filterStore.reset();
    }

    resetCountry() {
        this.contractStore.reset();
        this.siteStore.reset();
        this.visitStore.reset();
        this.premiseStore.reset();
        this.wasteStore.reset();
        this.recommendationsStore.reset();
        this.notificationsStore.reset();
        this.entitlementsStore.reset();
    }
}

const stateContext = createContext(null);
const defaultStore = new LocalStore();

const StateProvider = ({store, children}) => {
    const localStore = !isEmpty(store) ? store : defaultStore;
    return <stateContext.Provider value={localStore}>{children}</stateContext.Provider>
};

const useStoreRoot = () => {
    return useContext(stateContext);
};

const useContractStore = () => {
    return useStoreRoot().contractStore;
};

const useSiteStore = () => {
    return useStoreRoot().siteStore;
};

const useVisitStore = () => {
    return useStoreRoot().visitStore;
};

const useLanguageStore = () => {
    return useStoreRoot().languageStore;
};

const usePremiseStore = () => {
    return useStoreRoot().premiseStore;
};

const useHealthStatusStore = () => {
    return useStoreRoot().healthStatus;
};

const useRegionStore = () => {
    return useStoreRoot().regionStore;
};

const useWasteStore = () => {
    return useStoreRoot().wasteStore;
};

const useDetectorStore = () => {
    return useStoreRoot().detectorStore;
};

const useFilterStore = () => {
    return useStoreRoot().filterStore;
};

const useDevicesMappingStore = () => {
    return useStoreRoot().devicesMappingStore;
};

const useRecommendationsStore = () => {
    return useStoreRoot().recommendationsStore;
};

const useNotificationsStore = () => {
    return useStoreRoot().notificationsStore;
};

const useEntitlementsStore = () => {
    return useStoreRoot().entitlementsStore;
};

export {
    LocalStore,
    StateProvider,
    useStoreRoot,
    useContractStore,
    useSiteStore,
    useVisitStore,
    usePremiseStore,
    useLanguageStore,
    useHealthStatusStore,
    useRegionStore,
    useWasteStore,
    useDetectorStore,
    useFilterStore,
    useDevicesMappingStore,
    useRecommendationsStore,
    useNotificationsStore,
    useEntitlementsStore,
};
