import React from "react";
import {Radio, RadioGroup, FormControlLabel} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

const WRadioGroup = (props) => {
    const {handleChange, options, value, ariaLabel, name} = props;

    const onChange = (event) => {
        if (handleChange) {
            handleChange(event);
        }
    };

    return (
        <FormControl component="fieldset">
            <RadioGroup aria-label={ariaLabel} name={name} value={value} onChange={onChange}>
                {options.map((option, index) =>
                    <FormControlLabel
                        key={ariaLabel + ' ' + index}
                        value={option.value}
                        control={<Radio color='primary'/>}
                        label={option.label}
                    />
                )}
            </RadioGroup>
        </FormControl>
    )
}
export default WRadioGroup;