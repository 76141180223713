import {useEffect, useCallback, useState} from 'react';
import useApiRequest from "./requests/useApiRequest";
import {ERROR, FETCHING, SUCCESS} from "./requests/requestReducer";
import {base64Encode, isEmpty} from "../utils/utils";
import {isRegionPasswordUpdate} from "../utils/multyCountryUtils";
import {PASSWORD_INVALID, PASSWORD_UPDATE_SUCCESS} from "../contexts/AuthProvider/AuthConst";

export const useChangePassword = () => {
    const [changeStatus, setChangeStatus] = useState('');
    const [changeStatusPartial, setChangeStatusPartial] = useState({});
    const [apiStatus, setApiStatus] = useState(null);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/loginService/changePasswordService';
    const regions = process.env.REACT_APP_BACKEND_REGIONS.split(',');
    const [apiRequestCount, setApiRequestCount] = useState(0);

    const changePassword = useCallback((email, password, newPassword) => {

        setChangeStatus([]);
        setChangeStatusPartial([]);
        setApiRequestCount(0);
        setApiStatus(FETCHING);

        const params = {
            locale: 'en',
            email: email,
            curPass: base64Encode(base64Encode(password)),
            newPass: base64Encode(base64Encode(newPassword))
        };

        regions.forEach(function (region) {
            makeRequestWithParams(endpoint, 'post', params, region).catch(error => {
                console.error('changePassword makeRequestWithParams: ', error)
            });
        });

    }, [makeRequestWithParams, regions, setApiRequestCount]);


    const addRegion = useCallback((response) => {
        if (!isEmpty(response) && !isEmpty(response.data)) {
            const region = response.data.region;
            if (!isEmpty(region)) {
                let regionData = {};
                regionData[region] = response.data;
                setChangeStatusPartial(currentUserDataPartial => (Object.assign({}, regionData, currentUserDataPartial)))
            }
        }
    }, []);

    useEffect(() => {
        if (responseStatus === SUCCESS || responseStatus === ERROR) {
            // responseStatus === ERROR only if the backend is not available or it returns 500 otherwise
            // in case of either wrong user or wrong password kony returns success
            setApiRequestCount(prevValue => prevValue + 1);
        }
    }, [responseStatus, response]);

    useEffect(() => {
        if (apiRequestCount > 0) {
            addRegion(response);
        }
    }, [apiRequestCount, addRegion, response]);

    useEffect(() => {
        if (apiRequestCount === regions.length && !isEmpty(changeStatusPartial)) {
            const status = isRegionPasswordUpdate(changeStatusPartial) ? PASSWORD_UPDATE_SUCCESS: PASSWORD_INVALID;
            setChangeStatus(status);
            setApiRequestCount(0);
            setApiStatus(responseStatus)
        }
    }, [apiRequestCount, setApiRequestCount, regions.length, changeStatusPartial, responseStatus, setApiStatus, setChangeStatus]);


    return [changeStatus, apiStatus, changePassword];
};
