import {useCallback, useEffect, useState} from "react";
import {SUCCESS} from "./requests/requestReducer";
import {useTranslation} from "react-i18next";
import useRegionalGCPApiRequest from "./requests/useRegionalGCPApiRequest";

export const useVisitType = () => {
    const [visitTypes, setVisitTypes] = useState([]);
    const [{responseStatus, response}, makeRegionalGCPRequestWithParams] = useRegionalGCPApiRequest();
    const endpoint = '/premiseService/getVisitTypesService';
    const {t} = useTranslation();
    const all_text = t('all');

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                let types = [{'VisitTypeCode': 'ALL', 'VisitTypeDesc': all_text}].concat(response.data.visitType);
                setVisitTypes(types);
            } catch (error) {
                console.error('useVisitTypes error: ', error);
                setVisitTypes([]);
            }
        }
    }, [responseStatus, response, setVisitTypes, all_text]);

    const getVisitTypes = useCallback((siteId, locale, countryCode) => {
        const url = [endpoint, siteId, countryCode, locale].join('/') ;
        makeRegionalGCPRequestWithParams(url).catch(error => {
            console.error('useVisitTypes error: ', error);
        });
    },[makeRegionalGCPRequestWithParams]);

    return [visitTypes, getVisitTypes, responseStatus];
};
