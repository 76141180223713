import React from 'react';
import {useTranslation} from "react-i18next";
import {Route, Switch} from "react-router-dom";
import MINavMenu from "../../components/Widgets/MINavMenu";
import NewReport from "./NewReport";
import ExistingReports from "./ExistingReports";

const Reports = () => {
    const {t} = useTranslation();

    const navigation = [
        {path: '/reports/view-reports', label: t('nav_existing_reports'), ariaLabel: t('aria_label_nav_existing_reports')},
        {path: '/reports/create-report', label: t('nav_new_report'), ariaLabel: t('aria_label_nav_new_report')}, 
    ];

    return (
        <>
            <MINavMenu routes={navigation} level={'secondary'}/>
            <Switch>
                <Route path={navigation[0].path} component={ExistingReports}/>
                <Route path={navigation[1].path} component={NewReport}/>
                <Route path='/reports' component={ExistingReports}/>
            </Switch>
        </>
    );
};

export default Reports;