import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import MIApiLoader from "../../Widgets/MIApiLoader";
import PlannedVisitCard from "../PlannedVisitCard";
import PlannedVisitsCalendar from "../PlannedVisitsCalendar";
import MITopBorder from "../../Widgets/MITopBorder"
import { observer } from "mobx-react-lite";
import { mergeStyles } from "../../../utils/utils";
import { isEmpty } from "../../../utils/utils"
import {useContentFromUrl} from '../../../services/useContentFromUrl';
import MICmsContainer from '../../../components/Widgets/MICmsContainer';


const useStyles = makeStyles(theme => ({
    title: {
        padding: '2px',
    },
    paper: {
        width: '608px',
        height: '318px',
        border: theme.borders.outline_regular,
        display: 'inline-flex',
    },
    selector: {
        display: 'inline-block',
        width: '226px',
        backgroundColor: theme.palette.grey['100'],
        padding: theme.sizes.xlarge_gap,
    },
    calendar: {
        width: '320px',
    },
    noPlannedVisits: {
        color: theme.colors.noData,
        height: '226px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: theme.sizes.small_gap,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        '&:focus': theme.ariaFocus,
        textAlign: 'center',
    },
    disclaimer: {
        width: '608px',
        height: '40px'
    }
}));

const PlannedVisitsDesktop = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const maxCards = 3;
    let cards =[];
    const [content, responseStatusContent, getContentFromUrl, getContentUrl] = useContentFromUrl();
    const {sharedClasses, plannedVisits, previousVisits, responseStatus, selectedPlannedVisitDateString, setSelectedPlannedVisitDateString} = props;

    useEffect(() => {
        const url = getContentUrl('planned-visits-disclaimer.html');
        getContentFromUrl(url);
    }, [getContentFromUrl, getContentUrl]);

    const GetCards = function () {
        if (plannedVisits && plannedVisits.length > 0) {
            const plannedVisitsCards = plannedVisits.slice(0, maxCards);
            plannedVisitsCards.forEach(function (record, index) {
                cards.push(<PlannedVisitCard key={index}
                    plannedVisit={record}
                    selectedPlannedVisitDateString={selectedPlannedVisitDateString}
                    setSelectedPlannedVisitDateString={setSelectedPlannedVisitDateString} />)
            });
            return cards;
        } else {
            return (
                <p className={classes.noPlannedVisits} tabIndex={0}>{t('no_planned_visits')}</p>
            );
        }
    };

    return (
        <div>
            <h1
                className={mergeStyles(sharedClasses.title, classes.title)}
                aria-label={t('aria_label_planned_visits_title')}
                tabIndex={0}
            >
                {t('planned_visits')}
            </h1>
            <MIApiLoader
                MIComponent={
                    <div className={classes.disclaimer}>
                        {!isEmpty(content) ?
                                <MICmsContainer html={content} />
                                : null}
                        </div>

                }
                responseStatus={responseStatusContent}
                ariaLabelProgressBar={t('aria_label_progressbar_planned_visits_disclaimer')}
            />
            <MITopBorder topBorderStyle={sharedClasses.topBorder} />
            <div className={classes.paper}>
                <div className={classes.selector}>
                    <MIApiLoader
                        MIComponent={GetCards()}
                        responseStatus={responseStatus}
                        ariaLabelProgressBar={t('aria_label_progressbar_planned_visits')}
                    />
                </div>
                <div className={mergeStyles(sharedClasses.calendar, classes.calendar)} aria-label={t('aria_label_calendar')}>
                    <PlannedVisitsCalendar plannedVisits={plannedVisits}
                                           previousVisits={previousVisits}
                                           selectedPlannedVisitDateString={selectedPlannedVisitDateString}
                                           setSelectedPlannedVisitDateString={setSelectedPlannedVisitDateString}/>
                </div>
            </div>
        </div>
    );
});

export default PlannedVisitsDesktop;
