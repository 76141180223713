import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { compareDate, getDateDayDifference, isObjectEmpty } from '../../utils/utils';
import { Box, Tooltip } from '@material-ui/core';

export const RINGS_MAP = {
    ONE_WEEK: 'between0And7',
    ONE_MONTH: 'between8And30',
    TWO_MONTHS: 'between31And60',
};

const useStyles = makeStyles(() => ({
    icon: {
        position: 'relative',
        cursor: (props) => (props.handleClick ? 'pointer' : 'auto'),
    },
    between0And7: {
        borderColor: '#ED1C24',
        position: 'absolute'
    },
    between8And30: {
        borderColor: '#FBA332',
        position: 'absolute'
    },
    between31And60: {
        borderColor: '#5c677199',
        position: 'absolute'
    }
}));

const RINGS_STYLE = [
    {
        width: '2.8rem',
        height: '2.8rem',
        margin: '40px'
    },
    {
        width: '3.8rem',
        height: '3.8rem',
        margin: '32px'
    },
    {
        width: '4.8rem',
        height: '4.8rem',
        margin: '24px'
    },
    {
        width: '5.8rem',
        height: '5.8rem',
        margin: '16px'
    },
    {
        width: '6.8rem',
        height: '6.8rem',
        margin: '8px'
    },
    {
        width: '7.8rem',
        height: '7.8rem',
        margin: '0px'
    }
];

const MAX_RINGS = 6;
const ONE_WEEK_IN_DAYS = 7;
const ONE_MONTH_IN_DAYS = 30;
const TWO_MONTHS_IN_DAYS = 60;

const Bullseye = (props) => {
    const { t } = useTranslation();
    const { pointActivities, id, isCluster } = props;
    const classes = useStyles(props);
    const defaultProps = {
        m: 1,
        border: 3,
        borderRadius: '50%',
    };

    const getRingType = (daysSince) => {
        if (daysSince <= ONE_WEEK_IN_DAYS) {
            return RINGS_MAP.ONE_WEEK;
        } else if (daysSince <= ONE_MONTH_IN_DAYS) {
            return RINGS_MAP.ONE_MONTH;
        } else if (daysSince <= TWO_MONTHS_IN_DAYS) {
            return RINGS_MAP.TWO_MONTHS;
        }
    };

    const addTooltip = (rings, totalValidVisits) => {
        if (!isObjectEmpty(rings)) {
            // Add tooltip on the last ring only
            const lastRing = rings[rings.length - 1];
            const title = isCluster ? t('heatmaps_total_visits_cluster') : t('heatmaps_total_visits');
            rings[rings.length - 1] = <Tooltip
                title={`${title} ${totalValidVisits}`}
                arrow
                placement={'bottom'}
                key={[ id, 'tooltip' ].join('_')}>
                {lastRing}
            </Tooltip>;
        }
        return rings;
    };

    const getRings = () => {
        // the "date" field inside the API is always in UTC
        const formatDate = 'YYYY-MM-DD';

        // sort by newest
        pointActivities.sort((activity1, activity2) => compareDate(activity2.date, formatDate, activity1.date, formatDate));

        const rings = [];
        let totalValidVisits = 0;

        pointActivities.forEach((activity, index) => {
            const daysSince = getDateDayDifference(activity.date, formatDate);
            const ringType = getRingType(daysSince);

            if (!isObjectEmpty(ringType)) {
                // a visit is valid if we have a ring for it
                totalValidVisits = totalValidVisits + 1;

                if (totalValidVisits <= MAX_RINGS) {
                    rings.push(<Box
                        {...defaultProps}
                        style={RINGS_STYLE[index]}
                        className={classes[ringType]}
                        key={[ id, 'ring', index ].join('_')}
                        data-testid={[ ringType, index ].join('_')}
                    />);
                }
            }
        });
        return addTooltip(rings, totalValidVisits);
    };
    return (
        <div className={classes.icon} aria-label={t('bullseye_icon')}>
            {getRings()}
        </div>
    );
};

export default Bullseye;
