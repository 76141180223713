import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import NavBarDesktop from "./variant/NavBarDesktop"
import NavBarMobile from "./variant/NavBarMobile";
import {ResponsiveComponent} from "../../services/useResponsive";
import myInitialLogoMobile from "../../assets/nav_logo_mobile.svg";
import myInitialLogo from "../../assets/nav_logo.svg";
import {useAppBranding} from "../../services/useAppBranding";
import { useFlags } from "../../contexts/FlagsProvider/FlagsProvider";

const useStyles = makeStyles(theme => ({
    logo: {
        margin: 0,
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
}));

const NavigationBar = (props) => {
    const sharedClasses = useStyles();
    const { feature_notifications} = useFlags();
    const [ getAppBrandUrl ] = useAppBranding();

    const params = {
        sharedClasses: sharedClasses,
        desktopLogo: getAppBrandUrl('logo.png', myInitialLogo),
        mobileLogo: getAppBrandUrl('nav_mobile_logo.svg', myInitialLogoMobile),
        showNotifications: feature_notifications,
    };

    return (
        <ResponsiveComponent
            components={{
                mobile: <NavBarMobile {...params} {...props}/>,
                desktop: <NavBarDesktop {...params} {...props}/>,
            }}
        />
    );
};

export default NavigationBar;
