export const IS_RENTOKIL_MAP = [
    'rentokil-initial.com', 
    'rentokil.com'
];

export const LOG_EVENT_PARAMS = {
    PAGE_CLICK: {
        NAME: 'custom_page_click',
        PARAM: 'page_action'
    },
    PAGE_VIEW: {
        NAME: 'custom_page_view', 
        PARAM: 'page_title'
    }
};

export const LOG_EVENT_ACTIONS = {
    LOGIN: 'Login'
};

export const ADD_USER_PROPERTIES_PARAMS = {
    USER_DOMAIN: 'userDomain',
    IS_RENTOKIL: 'isRentokil',
    USER_COUNTRY: 'userCountry'
};
