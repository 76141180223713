import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {isEmpty} from "../../../utils/utils";
import {Link} from "react-router-dom";
import SiteInfoDialog from '../SiteInfoDialog';

const useStyles = makeStyles(theme => ({
    labelMobile: {
        display: 'inline-block',
        width: 'fill-available',
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.secondary.contrastText,
        marginTop: theme.sizes.small_gap,
        marginStart: theme.sizes.micro_gap,
        marginEnd: theme.sizes.micro_gap,
    },
    siteSelectLinkMobile: {
        color: theme.palette.secondary.contrastText,
        marginStart: theme.sizes.nano_gap,
        textDecoration: 'none',
        '&:focus': theme.ariaFocus,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    siteSelectLinkMobileContainer: {
        display: 'inline',
    },
}));

const SelectedSiteLinkMobile = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {selectedSite} = props;
    const [showDialog, setShowDialog] = useState(false);

    const toggleInfoDialog = () => {
        setShowDialog(!showDialog);
    }

    return (
        <div>
            <Typography
                className={classes.labelMobile}
                noWrap={true}
            >
                {t('selected_site_mobile')}
                {isEmpty(selectedSite) ?
                    <span className={classes.siteSelectLinkMobile}>-</span> :
                    <Link 
                        to='#' 
                        onClick={toggleInfoDialog} 
                        className={classes.siteSelectLinkMobile} 
                        id={'selected_site'}
                    >
                        {selectedSite.siteName.trim()}, {selectedSite.siteAddress}
                    </Link>
                }
            
            </Typography> 
            <SiteInfoDialog
                open={showDialog}
                handleClose={toggleInfoDialog}
                selectedSite={selectedSite}
            />  
        </div>
    );
})

export default SelectedSiteLinkMobile;