import moment from 'moment';
import PostalAddress from 'i18n-postal-address';

const ONE_MINUTE = 60000;
export const RENTOKIL_POSITION_ADJUSTMENT = 5;

export function getBool(val) {
    let num = Number(val);
    if (val === undefined) {
        return false;
    }
    return !isNaN(num) ? Boolean(num) : Boolean(String(val).toLowerCase().replace(Boolean(0), ''));
}

export function isEmpty(obj) {
    if (obj === undefined || obj === null) {
        return true;
    }

    if (typeof obj === 'string') {
        return obj.trim() === '';
    }

    for (let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({}) ||
        JSON.stringify(obj) === JSON.stringify([]);
}

export function addTime(date, hours, minutes) {
    return moment(date).add(hours, 'hours').add(minutes, 'minutes').toDate();
}

export function getGMTOffsetDateFromString(dateString, format) {
    // This is a work around for issues with the XGrid filter.
    // The local timezone is applied to the date object internally within XGrid
    // when it compares two dates. This means that the 'is' filter option fails.
    // We have to actually adjust the time to compensate.
    // Hopefully, in a future iteration of the component, we can remove this adjustment
    const dateFormat = format || 'DD/MM/YYYY';
    const dateObject = moment(dateString, dateFormat).toDate();
    const userTimezoneOffset = dateObject.getTimezoneOffset() * ONE_MINUTE;
    return new Date(dateObject.getTime() - userTimezoneOffset);
}

export function getDateFromString(dateString, format) {
    const dateFormat = format || 'DD/MM/YYYY';
    return moment(dateString, dateFormat).toDate();
}

export function getDateDayDifference(dateString, format) {
    return moment().diff(getDateFromString(dateString, format), 'days');
}

export function getTime(dateString, timeString) {
    let timeParts = timeString.split(':');
    let startTime = null;
    if (timeParts.length === 2) {
        const hours = parseInt(timeParts[0], 0);
        const minutes = parseInt(timeParts[1], 0);
        if (hours + minutes > 0) {
            startTime = addTime(getDateFromString(dateString), hours, minutes);
            return startTime;
        }
    }
    return startTime;
}

export function isToday(date, format) {
    // Returns true if it is today or false if it's not
    const dateMoment = moment(date, format);
    return moment(dateMoment).isSame(moment(), 'day');
}

export function getLocalizedTime(t, dateString, timeString) {
    const time = getTime(dateString, timeString);

    return time !== null ? t('localised_time', { time: time }) : '-';
}

export function getLocalizedDate(t, dateString, dateFormat) {
    const date = getDateFromString(dateString, dateFormat);
    return t('localised_date', { today: date, defaultValue:  '{{today, date}}' });
}

export const findArrayIndex = (array, callback) => {
    const length = array === null ? 0 : array.length;
    if (!length) {
        return -1;
    }
    let index = -1;
    for (let i = 0; i < array.length; ++i) {
        if (callback(array[i])) {
            index = i;
            break;
        }
    }
    return index;
};

export const formatStringDate = (date, inputFormat, outputFormat) => moment(date, inputFormat).locale('en').format(outputFormat);

export const formatDate = (date, inputFormat, outputFormat) => {
    try {
        const originalDateFormat = inputFormat || 'DD/MM/YYYY';
        const dateAPIFormat = outputFormat || 'YYYY/MM/DD';
        let dataString = formatStringDate(date, originalDateFormat, dateAPIFormat);
        dataString = dataString.replace(/\//g, '-');
        return dataString;
    } catch (error) {
        console.error(error);
        return '';
    }
};

export const getLocaleSections = (locale) => {
    let language = locale;
    let country = '';
    if (locale.includes('-')) {
        const parts = locale.split('-');
        language = parts[0];
        country = parts[1];
    }

    return [ language, country ];
};

export const base64Encode = (_str) => Buffer.from(_str).toString('base64');

export const extractBaseURL = (url = '') =>
    url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').replace(/\/+$/, '')
;

export const getSiteAddress = (_premise, countryCode) => {
    const address = new PostalAddress();
    address
        .setAddress1(_premise.siteAddress1)
        .setCity(_premise.siteAddress4)
        .setPostalCode(_premise.premisePinCode)
        .setFormat({
            country: countryCode,
            type: 'default',
        });
    return address.output().flat().join(', ').replace(',,', ',');
};

export const getCountryNameByCode = (lang, code) => {
    const regionNames = new Intl.DisplayNames([ lang ], { type: 'region' });
    return regionNames.of(code);
};

export const getLanguageNameByLocale = (lang) => {
    const languageNames = new Intl.DisplayNames([ lang ], { type: 'language' });
    return languageNames.of(lang);
};

export const removeLanguageVariation = (lang) => lang.split('-')[0];

export function isObjectEmpty(obj) {
    if (obj === undefined || obj === null) {
        return true;
    }

    if (typeof obj === 'string') {
        return obj.trim() === '';
    }

    for (let prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({}) ||
      JSON.stringify(obj) === JSON.stringify([]);
}

export const mergeStyles = (...styles) => styles.join(' ');

export const getRouteIndex = (fullPath, routes) => {
    let path = fullPath;
    let index = routes.findIndex((route) => route.path === path);
    while (path !== '' && index === -1) {
        const parent = path.substring(0, path.lastIndexOf("/"));
        index = findArrayIndex(routes, (route) => route.path === parent);
        path = parent;
    }
    return index === -1 ? 0 : index;
};

export const groupByLocationId = (elements) => {
    // map list by location id ex. { 'foo' : [ {elem1}, {elem2}] }
    if (!isObjectEmpty(elements)) {
        return elements.reduce((obj, item) => {
            const locationId = item.locationId || item.location?.id;
            return !isObjectEmpty(locationId) ? {
                ...obj,
                [locationId]: [ ...obj[locationId] || [], item ]
            } : obj}, {})
    }
    return {}
}

export const subTime = (amount, format) => {
    return moment().subtract(amount,'d').format(format);
}

export function compareDate(dateString1, format1, dateString2, format2) {
    return moment(dateString1, format1).diff(getDateFromString(dateString2, format2), 'days');
}

export const getFilteredDate = (date) => (!isEmpty(date) ? date.split(' ')[0].replace(/-/g, '/') : null);

export const getLocation = (location) => {
    if (!isEmpty(location)) {
        const department = !isEmpty(location.department) ? location.department : {};
        const floor = !isEmpty(department) ? department.floor : {};
        const building = !isEmpty(floor) ? floor.building : {};

        const specificLocation = [ building.description, floor.description, department.description ].join(', ');
        return [ location.description, ' (', specificLocation, ')' ].join('');
    }
    return null;
};
