import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import MIDownload from '../Widgets/MIDownload';
import MIAlert from '../Widgets/MIAlert';
import { useResponsive } from '../../services/useResponsive';
import { isEmpty } from '../../utils/utils';

const desktopStyles = (theme) => {
    return {
        buttonContainer: {
            marginTop: theme.sizes.micro_gap,
            marginBottom: theme.sizes.xlarge_gap,
            display: 'flex'
        },
        button: {
            marginRight: theme.sizes.micro_gap,
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        buttonContainer: {
            ...baseStyle.buttonContainer,
            marginBottom: theme.sizes.micro_gap,
        },
    });
};

const DownloadRecommendations = (props) => {
    const { t } = useTranslation();
    const { buttonData, myRentokilSiteId } = props;
    const [ isShowing, setIsShowing ] = useState(false);
    const [ alert, setAlert ] = useState({});
    const { getStyles } = useResponsive();
    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    });

    const classes = useStyles();

    return (
        <div className={classes.buttonContainer}>
            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                severity={alert.severity}
                ariaLabel={t('aria_label_operation_response_dialog')}
            />
            {buttonData.map((data, index) => {
                return <div className={classes.button} key={index}>
                    <MIDownload
                        setAlert={setAlert}
                        setIsShowing={setIsShowing}
                        title={data.title}
                        url={data.url}
                        useFloorPlanApi={true}
                        miType={isEmpty(myRentokilSiteId) ? 'disabled' : 'primary'}
                    />
                </div>;
            })}
        </div>
    );
};

export default DownloadRecommendations;
