import { useState, useEffect, useCallback, useRef } from 'react';
import { FETCHING, SUCCESS } from '../requests/requestReducer';
import { useAuthState } from '../../contexts/AuthProvider';
import { isEmpty } from '../../utils/utils';
import useFloorPlanApiRequest from '../requests/useFloorPlanApiRequest';

const PAGE_LIMIT = 500;

export const useGetDeviceHistory = () => {
    const [ deviceHistory, setDeviceHistory ] = useState(null);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const { countryCode, locale } = useAuthState();
    const endpoint = '/floorPlanApi/getDeviceHistory';
    const downloadCount = useRef(0);
    const total = useRef(0);
    const deviceHistoryPartial = useRef([]);
    const [ locationId, setLocationId ] = useState(null);

    const getDeviceHistoryPage = useCallback((id, start, limit) => {
        const url = [ endpoint, countryCode, locale, id, start, limit, '-scanDatetime' ].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetDeviceHistory makeRequest: ', error);
        });
    }, [ makeFloorPlanApiRequestWithParams, countryCode, locale ]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                if (!isEmpty(response.data)) {
                    downloadCount.current = downloadCount.current + response.data.results.length;
                    total.current = response.data.count;
                    deviceHistoryPartial.current.push(...response.data.results);

                    if (downloadCount.current < total.current) {
                        getDeviceHistoryPage(locationId, downloadCount.current, PAGE_LIMIT);
                    } else if (downloadCount.current >= total.current) {
                        setDeviceHistory(deviceHistoryPartial.current);
                    }
                }
            } catch (error) {
                console.error('useGetDeviceHistory error: ', error);
                setDeviceHistory(null);
            }
        }
    }, [ responseStatus, response, setDeviceHistory, getDeviceHistoryPage, locationId ]);


    const getDeviceHistory = useCallback((id) => {
        setLocationId(id);
        total.current = 0;
        downloadCount.current = 0;
        deviceHistoryPartial.current = [];

        getDeviceHistoryPage(id, 0, PAGE_LIMIT);
    }, [ getDeviceHistoryPage, setLocationId ]);

    const getStatus = useCallback(() => {
        let status = responseStatus;
        if (responseStatus === SUCCESS) {
            status = downloadCount.current < total.current ? FETCHING : SUCCESS;
        }
        return status;
    }, [ responseStatus ]);


    return [ deviceHistory, getStatus(), getDeviceHistory ];
};
