import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MIApiLoader from '../../../components/Widgets/MIApiLoader';
import { useAuthState } from '../../../contexts/AuthProvider';
import { useEditReport } from '../../../services/useEditReport';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
    return {
        actionIconContainer: {
            alignItems: 'center',
            display: 'flex',
            marginRight: theme.sizes.small_gap,
        },
        actionIconOuter: {
            'display': 'flex',
            '&:focus': theme.ariaFocus,
        },
    };
});


const OpenReport = (props) => {
    const { reportId, component, newReportTitle, setAlert, setIsShowing, action, ariaLabel, ariaLabelProgressBar } = props;
    const history = useHistory();
    const classes = useStyles();
    const [ report, responseStatus, getReport ] = useEditReport();
    const { locale, countryCode } = useAuthState();


    const showAlert = useCallback(() => {
        setAlert({ message: 'open_report_dialog_error', severity: 'error' });
        setIsShowing(true);
    }, [ setAlert, setIsShowing ]);

    const handleOnClick = () => {
        if (reportId && locale && countryCode) {
            getReport(reportId, locale, countryCode);
        }
    };

    useEffect(() => {
        if (report) {
            try {
                if (report.reportId) {
                    report.reportTitle = newReportTitle ? newReportTitle : report.reportTitle;
                    // move to the create report section
                    history.push({
                        pathname: '/reports/create-report',
                        state: { report: report, action: action }
                    });
                } else if (report.reportId === '') {
                    // API always returns a report and a reportId, but if it failed to get a report then the Id will be an empty string
                    showAlert();
                }
            } catch (error) {
                console.error('open report error: ', error);
            }
        }
    }, [ report, action, history, newReportTitle, showAlert ]);

    return (
        <div
            className={classes.actionIconContainer}
            onClick={handleOnClick}
            data-testid={'open_icon_report'}
        >
            <MIApiLoader
                customStyle={{
                    spinnerWidth: '20px',
                    spinnerHeight: '20px',
                    marginTop: '2px',
                    marginLeft: '2px',
                    marginRight: '2px'
                }}
                MIComponent={
                    <div
                        className={classes.actionIconOuter}
                        tabIndex={0}
                        aria-label={ariaLabel}
                    >
                        {component}
                    </div>
                }
                responseStatus={responseStatus}
                ariaLabelProgressBar={ariaLabelProgressBar}
            />
        </div>
    );
};


export default OpenReport;
