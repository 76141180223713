import React, {useEffect, useState } from 'react'
import {makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import {useContentFromUrl} from '../../services/useContentFromUrl';
import MIAccordion from '../../components/Widgets/MIAccordion';
import dompurify from 'dompurify';
import parse from 'html-react-parser';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import {isEmpty, setDocumentTitle, textContent} from "../../utils/utils";
import {ResponsiveComponent, useResponsive} from "../../services/useResponsive";
import MITextField from '../../components/Widgets/MITextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import MIPageTitle from '../../components/Widgets/MIPageTitle';

const desktopStyles = theme => {
    return {
        root: {
            width: "100%",
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        answer: {
            paddingBottom: theme.sizes.medium_gap,
        },
        faqSearch: {
            display: 'flex',
            width: '99%',
            marginTop: theme.sizes.small_gap,
            '& fieldset': {
                borderRadius: theme.sizes.no_gap,
                border: '1px solid ' + theme.palette.grey['500'] + ' !important',
            },
        },
}};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: '100vh',
            paddingTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.no_gap,
            paddingBottom: theme.sizes.no_gap,
        },
        listRoot: {
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        },
        answer: {
            fontFamily: theme.typography.fontFamily,
            paddingBottom: theme.sizes.medium_gap,
        },
        faqSearch: {
            ...baseStyle.documentSearch,
            width: 'inherit',
            marginTop: theme.sizes.no_gap,
            padding: theme.sizes.small_gap,
            borderBottom: theme.borders.outline_regular,
            height: 'unset'
        },
    })};

const FAQs = () => {
    const {t} = useTranslation();
    const clean = dompurify.sanitize;
    const [content, responseStatus, getContentFromUrl, getContentUrl] = useContentFromUrl();
    const [openRow, setOpenRow] = useState();
    const {getStyles} = useResponsive();
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ searchResult, setSearchResult] = useState([]);

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_faqs');
     }, [t]);

    useEffect(() => {
        const url = getContentUrl('faq.html');
        getContentFromUrl(url);
    }, [getContentFromUrl, getContentUrl]);

    useEffect(() => {
        const parsedHTML = parse(clean(content));
        const allQuestions = [];
        parsedHTML.forEach((block) => {
            if (block.type && block.type === 'ul') {
                const questionAndAnswer = block.props && block.props.children ? block.props.children : [];
                let question = {};
                questionAndAnswer.forEach((section) => {
                    if (!isEmpty(section.props) && !isEmpty(section.props.className) && !isEmpty(section.props.children)) {
                        if (section.props.className === 'question') {
                            question.question = section.props.children;
                        } else if (section.props.className === 'answer') {
                            question.answer = section.props.children;
                        }
                    }
                })
                if (question.question && question.answer) {
                    const answer = textContent(question.answer).toLowerCase();
                    if (!isEmpty(searchTerm)) {
                        if (question.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            answer.includes(searchTerm.toLowerCase())) {
                            allQuestions.push(question);
                        }
                    } else {
                        setOpenRow(null);
                        allQuestions.push(question);
                    }
                }
                setSearchResult(allQuestions);
            }
        });
    }, [ setSearchResult, content, searchTerm, clean, setOpenRow ]);

    const formatFAQs = () => {
        return <div className={classes.listRoot} aria-label={t('aria_label_faqs_list')}>
            {
                searchResult.map((question, index) => (
                    <div key={index} onClick={() => setOpenRow(index)} className={classes.listSection}>
                        <MIAccordion
                            className={classes.ul}
                            aria-label={t('aria_label_question')}
                            header={question.question}
                            id={index}
                            expandedValue={openRow === index}
                            content={
                                <div className={classes.answer}>
                                    {question.answer}
                                </div>
                            }
                        />
                    </div>
                ))
            }
        </div>
    };

    return (
        <Paper className={classes.root} aria-label={t('aria_label_faqs_page')}>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: <MIPageTitle title={t('faqs')} ariaLabel={t('aria_label_faqs_title')}/>
                }} />
            <div>
                <MITextField
                    className={classes.faqSearch}
                    ariaLabel={t('aria_label_faq_search')}
                    variant='outlined'
                    name='faq-search'
                    placeholder={t('search_faq')}
                    onChange={(event) => {
                        const {value} = event.target;
                        setSearchTerm(value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon className={classes.searchIcon}/>
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            <MIApiLoader
                MIComponent={
                    <div>
                        {(!isEmpty(content) && !isEmpty(searchResult)) ? 
                            formatFAQs() : 
                            <h1>{ isEmpty(content) ? 
                                t('no_faq_content') : 
                                t('no_faq_match_result') }
                            </h1>}
                    </div>
                }
                responseStatus={responseStatus}
                ariaLabelProgressBar={t('aria_label_progressbar_faqs')}
            />
        </Paper>
    );
};

export default FAQs;
