import { useCallback, useEffect, useState } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import { isEmpty } from '../../utils/utils';
import { useVisitStore } from '../../contexts/StateProvider/StateProvider';
import { useAuthState } from '../../contexts/AuthProvider';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';

export const useVisitHistory = () => {
    const visitStore = useVisitStore();
    const [ currentSiteId, setCurrentSiteId ] = useState(null);
    const [ visits, setVisits ] = useState(null);
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const { countryCode } = useAuthState();
    const endpoint = '/premiseService/visitHistory';

    useEffect(() => {
        if (responseStatus === SUCCESS && !isEmpty(currentSiteId)) {
            try {
                visitStore.setVisitHistory(currentSiteId, response.data.visitHistory);
                setVisits(response.data.visitHistory);
            } catch (error) {
                console.error('useVisitHistory error: ', error);
                visitStore.setVisitHistory(currentSiteId, []);
                setVisits(null);
            }
        }
    }, [ responseStatus, response, visitStore, currentSiteId ]);

    const getVisitHistory = useCallback((siteId, contractNumber, premiseNumber) => {
        const siteVisits = visitStore.getVisitHistory(siteId);
        const language = 'en_GB'; // Hardcoded until we require/implement the translation logic in the gcp api
        if (!isEmpty(siteVisits)) {
            setVisits(siteVisits);
        } else {
            setVisits(null);
            setCurrentSiteId(siteId);
            const url = [ endpoint, countryCode, contractNumber, premiseNumber, language ].join('/');
            makeRegionalGCPRequestWithParams(url).catch((error) => {
                console.error('useVisitHistory error: ', error);
            });
        }
    }, [ makeRegionalGCPRequestWithParams, visitStore, countryCode ]);

    return [ visits, visits !== null ? SUCCESS : responseStatus, getVisitHistory ];
};
