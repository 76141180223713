import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import MIGrid from "../../../../components/Widgets/MIGrid";
import { isEmpty } from '../../../../utils/utils';

const useStyles = makeStyles(theme => ({
    groupName: {
        fontSize: theme.typography.h4.fontSize,
        fontFamily: theme.typography.h2.fontFamily,
        color: theme.palette.text.primary,
        marginTop: theme.sizes.large_gap,
        '&:focus': theme.ariaFocus,
    },
}));

const ProductTableDesktop = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {groupName, rows, columns, isLoading} = props;

    return (
        <>
            {!isEmpty(groupName) && <h2 className={classes.groupName} aria-label={groupName} tabIndex={0}>{groupName}</h2>}
            <MIGrid
                columns={columns}
                rows={rows}
                noElementsText={t('no_product')}
                colCellFlex={true}
                ariaLabel={t('aria_label_contract_table_named') + ' ' + groupName}
                autoHeight={true}
                pagination={true}
                hideFooterForOnePage={true}
                heightToFitContent={true}
                pageSize={5}
                isLoading={isLoading}
                {...props}
            />
        </>
    );
});

export default ProductTableDesktop;
