import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import { setDocumentTitle } from '../../utils/utils';
import { observer} from "mobx-react-lite";
import { ResponsiveComponent, useResponsive } from "../../services/useResponsive";
import SiteGrid from '../../components/SiteSelector/SiteGrid';
import MIPageTitle from '../../components/Widgets/MIPageTitle';

const desktopStyles = theme => {
    return {
        root: {
            width: "100%",
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        bottomSpacer: {
            height: '68px',
        },
        linkContainer: {
            margin: 'auto',
            textAlign: 'center',
        },
        siteSelectLink: {
            display: 'flex',
            '&, &:visited, &:hover, &:focus': {
                color: theme.palette.primary.main
            }
        },
        footer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            border: '1px solid',
            borderColor: theme.palette.divider,
            margin: '-1px',
            position: 'absolute',
            bottom: 0,
            width: '100%',
            height: '44px',
            background: theme.palette.background.paper,
            fontFamily: theme.typography.caption.fontFamily,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: theme.typography.caption.fontWeight,
            lineHeight: theme.typography.caption.lineHeight,
            color: theme.palette.text.secondary,
            textAlign: 'center',
        },
    }
}

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.small_gap,
            paddingRight: theme.sizes.no_gap,
            paddingLeft: theme.sizes.no_gap,
            maxHeight: 'none',
        },
        content: {
            marginBottom: theme.sizes.xxlarge_gap,
        }
    });
}

const SiteList = observer(() => {
    const { getStyles } = useResponsive();
    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_site_list');
    }, [t]);


    return (
        <Paper className={ classes.root } aria-label={ t('aria_label_site_list_page') }>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: <MIPageTitle title={t('site_list')} ariaLabel={t('aria_label_site_list_title')}/>
                }}
            />
            <div className={ classes.content }>
                <SiteGrid
                    showInfoModal={ true }
                    waitFullyLoaded={ true }
                    showRowCount={ true }
                    mobilePageSize={ 10 }
                    hideFooter={ false }
                />
            </div>
        </Paper>
    );
});

export default SiteList;
