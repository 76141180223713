import { useCallback, useEffect, useState } from 'react';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';
import { SUCCESS } from '../requests/requestReducer';
import { useAuthState } from '../../contexts/AuthProvider/AuthProvider';
import { isEmpty } from '../../utils/utils';

export const useRefillLevels = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/rapidService/refillLevels';
    const [ refillLevels, setRefillValues ] = useState({});
    const { countryCode } = useAuthState();
    // Hardcoded value for Interactions values because there is no data in staging
    const smartHygienePresetContractAndPremise = process.env.REACT_APP_PRESET_SMART_HYGIENE_CONTRACT_AND_PREMISE;

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setRefillValues(response.data.result);
            } catch (error) {
                console.error('useRefillLevels error: ', error);
                setRefillValues([]);
            }
        }
    }, [ responseStatus, response ]);

    const getRefillLevels = useCallback((contractNumber, premiseNumber) => {
        const contractAndPremiseNo = isEmpty(smartHygienePresetContractAndPremise) ? [ contractNumber, premiseNumber ].join('/') : smartHygienePresetContractAndPremise;
        const url = [ endpoint, contractAndPremiseNo, countryCode ].join('/');

        makeRequestWithParams(url).catch((error) => {
            console.error('useRefillLevels error: ', error);
        });
    }, [ makeRequestWithParams, countryCode, smartHygienePresetContractAndPremise ]);

    return [ refillLevels, responseStatus, getRefillLevels ];
};
