import { useEffect, useCallback, useRef } from 'react';
import {useAuthState} from '../../contexts/AuthProvider';
import { useLocationIcon } from './useLocationIcon';
import useFloorPlanApiRequest from '../requests/useFloorPlanApiRequest';
import { useDetectorStore } from '../../contexts/StateProvider/StateProvider';
import { isEmpty } from '../../utils/utils';
import { SUCCESS } from '../requests/requestReducer';

export const useDetectors = () => {
    const detectorStore = useDetectorStore();
    const currentFloorId = useRef('');
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const { countryCode, locale } = useAuthState();
    const endpoint = '/floorPlanApi/getDetectors';
    const { getIcon } = useLocationIcon();

    const convertToPoints = useCallback((detectors) => {
        let points = [];
        if (!isEmpty(detectors)) {
            detectors.forEach((detector) => {
                if (!isEmpty(detector.location) &&
                    !isEmpty(detector.location.screenLeft) &&
                    !isEmpty(detector.location.screenTop)) {
                    const [ icon, centreOffset, type ] = getIcon(
                        detector.id,
                        detector.detectorType.descriptionTranslated
                    );
                    detector.type = type;
                    points.push(
                        {
                            id: detector.location.id,
                            properties: detector,
                            label: detector.location.description,
                            component: icon,
                            centre: centreOffset,
                            coordinates: { x: detector.location.screenLeft, y: detector.location.screenTop }
                        }
                    );
                }
            });
        }
        return points;
    }, [ getIcon ]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                const detectors = convertToPoints(response.data.results);
                detectorStore.setDetectors(currentFloorId.current, detectors);
            } catch (error) {
                console.error('useDetectors error: ', error);
            }
        }
    }, [ responseStatus, response, convertToPoints, detectorStore ]);

    const getDetectors = useCallback((floorId) => {
        currentFloorId.current = floorId;
        const detectors = detectorStore.getDetectors(floorId);
        if (isEmpty(detectors)) {
            const url = [ endpoint, countryCode, locale, floorId ].join('/');
            makeFloorPlanApiRequestWithParams(url).catch((error) => {
                console.error('useDetectors makeRequest: ', error);
            });
        }
    }, [ makeFloorPlanApiRequestWithParams, countryCode, locale, detectorStore ]);

    return [ responseStatus, getDetectors ];
};
