import {useState, useEffect, useCallback} from 'react';
import useApiRequest from "./requests/useApiRequest";
import {SUCCESS} from "./requests/requestReducer";

export const useExistingReports = () => {
    const [existingReports, setExistingReports] = useState([]);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/reportService/listReports';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            setExistingReports(response.data.reportList);
        }
    }, [responseStatus, response, setExistingReports]);

    const getExistingReports = useCallback((countryCode, locale) => {
        const url = endpoint + '/' + countryCode + '/' + locale;
        makeRequestWithParams(url).catch(error => {
            console.error('useExistingReports error: ', error);
        });
    }, [makeRequestWithParams]);
    
    return [existingReports, responseStatus, getExistingReports];

}