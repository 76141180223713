import React from "react";
import MICard from "../Widgets/MICard";
import PlannedVisitDetails from "./PlannedVisitDetails";
import useModal from "../../services/useModal";
import {useTranslation} from "react-i18next";

const PlannedVisitCard = (props) => {
    const {isShowing, changeStatus} = useModal();
    const {t} = useTranslation();

    const handleOnClick = (dateString) => {
        changeStatus();
        props.setSelectedPlannedVisitDateString(dateString);
    };

    return (
        <div aria-label={t('aria_planning_card')}>
            <MICard plannedVisit={props.plannedVisit}
                    onClick={handleOnClick}
                    selectedPlannedVisitDateString={props.selectedPlannedVisitDateString}/>

            <PlannedVisitDetails isShowing={isShowing}
                                 onClose={changeStatus}
                                 plannedVisit={props.plannedVisit}/>
        </div>
    );
};

export default PlannedVisitCard;
