import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {Tabs, Tab, Fade} from '@material-ui/core';
import {HEADER_HEIGHT} from "./ActionBar";
import {observer} from 'mobx-react-lite';
import { MapLegend } from '../../../rentokil-react-ui';
import { getLocalizedDate } from '../../../utils/utils';

const useStyles = makeStyles((theme) => {
    return {
        headerContainer: {
            display: 'inline-flex',
            flexDirection: 'row',
            backgroundColor: theme.palette.background.paper,
            zIndex: 10,
        },
        tabContainer: {
            'height': `${HEADER_HEIGHT}px`,
            '& span.MuiTabs-indicator': {
                height: '8px',
            },
        },
        tab: {
            'textTransform': 'none',
            'padding': '16px 16px',
            'fontWeight': theme.typography.fontWeightBold,
            'height': `${HEADER_HEIGHT}px`,
            'color': theme.colors.mainText,
            '&.Mui-selected': {
                fontWeight: theme.typography.fontWeightBold,
            },
            'borderLeft': '1px solid #CDD2D5',
            'fontFamily': theme.typography.h1.fontFamily,
            'fontSize': theme.typography.body1.fontSize,
        },
        main: {
            flex: 1,
            background: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
        },
        root: {
            padding: '0px',
            minWidth: '100px',
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            borderTop: `solid 1px ${theme.palette.grey['300']}`,
        },
        sideBar: {
            display: 'inline-flex'
        },
    };
});

const MAP_TAB = 'map_tab';
const DEVICES_TAB = 'devices_tab';

const FloorPlanTabs = observer((props) => {
    const {t} = useTranslation();
    const {
        mapTab, 
        devicesTab, 
        header,
        getLegendIconGroups,
        selectedFloor,
        showLegend,
        mapSize,
        toggleEndOfLegendTransition,
        onTabChange
    } = props;
    const classes = useStyles();
    const [value, setValue] = useState(MAP_TAB);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (onTabChange) {
            onTabChange();
        }
    };

    return (
        <div className={classes.main}>
            <div className={classes.headerContainer}>
                {header}
                <Tabs
                    className={classes.tabContainer}
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab
                        className={classes.tab}
                        key={MAP_TAB}
                        label={t('floor_plan')}
                        aria-label={t('aria_label_floor_plan_tab')}
                        value={MAP_TAB}
                        classes={{root: classes.root}}
                    />
                    <Tab
                        className={classes.tab}
                        key={DEVICES_TAB}
                        label={t('devices_list')}
                        aria-label={t('aria_label_devices_list_tab')}
                        value={DEVICES_TAB}
                        classes={{root: classes.root}}
                    />
                </Tabs>
            </div>

            <div className={classes.container}>
                <Fade in={showLegend} mountOnEnter unmountOnExit onEnter={toggleEndOfLegendTransition} onExited={toggleEndOfLegendTransition}>
                    <div className={classes.sideBar}>
                        <MapLegend
                            height={mapSize.height}
                            iconGroups={getLegendIconGroups()}
                            selectedFloor={selectedFloor}
                            strings={{
                                dateCreatedLabel: t('date_created'),
                                versionLabel: t('header_version'),
                                headingLabel: t('map_legend_heading'),
                                // API Returns in 'YYYY-MM-DD' format but getLocalizedDate accepts 'YYYY/MM/DD' format.
                                creationDate: selectedFloor.creationDate ? getLocalizedDate(t, selectedFloor.creationDate.split(' ')[0].replaceAll('-', '/')) : '',
                            }}
                        />
                    </div>
                </Fade>
                {value === MAP_TAB && mapTab}
                {value === DEVICES_TAB && devicesTab}
            </div>

        </div>
    );
});

export default FloorPlanTabs;
