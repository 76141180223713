import { SUCCESS } from '../requests/requestReducer';
import useApiRequest from '../requests/useApiRequest';
import { useCallback, useEffect, useState } from 'react';
import { isEmpty } from '../../utils/utils';

export const useGetWasteTransferNotes = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/hazardWasteService/transferNotes';
    const [ transferNotes, setTransferNotes ] = useState([]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                if (!isEmpty(response?.data)) {
                    setTransferNotes(response.data.results);
                }
            } catch (error) {
                console.error('useGetWasteTransferNotes error: ', error);
            }
        }
    }, [ responseStatus, response ]);


    const getWasteTransferNotes = useCallback((contractNumber, premiseNumber) => {
        const url = [ endpoint, contractNumber, premiseNumber ].join('/');
        makeRequestWithParams(url).catch((error) => {
            console.error('useGetWasteTransferNotes error: ', error);
        });
    }, [ makeRequestWithParams ]);


    return [ transferNotes, responseStatus, getWasteTransferNotes ];
};
