import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { FETCHING } from '../../services/requests/requestReducer';
import Link from '@material-ui/core/Link';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { useAuthState } from '../../contexts/AuthProvider';
import { useWasteNotePdf } from '../../services/waste/useWasteNotePdf';
import { isEmpty } from '../../utils/utils';
import MIApiLoader from '../Widgets/MIApiLoader';
import { useResponsive } from '../../services/useResponsive';
import { useVisitDetails } from '../../services/visits/useVisitDetails';

const desktopStyles = (theme) => {
    return {
        report: {
            'color': theme.palette.primary.main,
            'cursor': 'pointer',
            'width': 'auto',
            '&:hover': {
                textDecoration: 'none',
            },
            '&:focus': theme.ariaFocus,
        },
        disabled: {
            'color': theme.palette.grey['400'],
            'cursor': 'not-allowed',
            'width': 'auto',
            '&:hover': {
                textDecoration: 'none',
            },
        },
        pdfIconContainer: {
            display: 'flex',
            marginLeft: '-3px',
            width: 'fit-content',
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        report: {
            ...baseStyle.report,
            height: theme.sizes.large_gap,
            width: theme.sizes.large_gap,
        },
    });
};

const ENTER_KEY_CODE = 13;

const DownloadWasteNote = observer((props) => {
    const { t } = useTranslation();
    const { setAlert, setIsShowing, premiseVisitNumber, consignmentNoteCode, visitDate } = props;
    const { countryCode, locale } = useAuthState();
    const [ isLoading, setIsLoading ] = useState(false);
    const [ wasteNoteUrl, setWasteNoteUrl ] = useState('');
    const [ visit, responseVisitDetailStatus, getVisitDetails ] = useVisitDetails();
    const [ wasteNotePdf, responseWasteNotePdfStatus, getWasteNoteUrl, getWasteNotePdf ] = useWasteNotePdf();
    const NOT_FOUND = 'NOT_FOUND';

    const siteStore = useSiteStore();

    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );

    const classes = useStyles();

    const showNotFound = useCallback(() => {
        setAlert({
            message: countryCode === 'UK' ? 'hazardous_waste_uk_alert_not_found' : 'hazardous_waste_alert_not_found',
            secondaryMessage: countryCode === 'UK' ? 'hazardous_waste_uk_alert_secondary' : 'alert_not_found_secondary',
            severity: 'warning'
        });
        setIsShowing(true);
    }, [ setAlert, setIsShowing, countryCode ]);

    useEffect(() => {
        if (wasteNotePdf?.signedUrl === NOT_FOUND) {
            showNotFound();
        }
    }, [ wasteNotePdf, showNotFound ]);

    const handleOnClick = () => {
        setIsLoading(true);
        getWasteNotePdf(wasteNoteUrl);
    };

    useEffect(() => {
        if (!isEmpty(premiseVisitNumber) && !isEmpty(countryCode) && !isEmpty(siteStore.selectedPremiseNumber) &&
            !isEmpty(siteStore.selectedContractNumber) && !isEmpty(locale) &&
            !isEmpty(siteStore.selectedSiteId) && !isEmpty(visitDate)) {
            getVisitDetails(
                countryCode,
                siteStore.selectedContractNumber,
                siteStore.selectedPremiseNumber,
                premiseVisitNumber,
                siteStore.selectedSiteId,
                visitDate,
                locale
            );
        }
    }, [ getVisitDetails, premiseVisitNumber, countryCode, siteStore.selectedPremiseNumber, siteStore.selectedContractNumber, locale, visitDate, siteStore.selectedSiteId ]);

    useEffect(() => {
        if (!isEmpty(siteStore.selectedContractNumber) &&
            !isEmpty(siteStore.selectedPremiseNumber) && !isEmpty(visitDate)) {
            if (isEmpty(consignmentNoteCode)) {

                if (!isEmpty(visit?.visitDetails?.WasteConsignmentNoteNumber)) {
                    const url = getWasteNoteUrl(
                        visit.visitDetails.WasteConsignmentNoteNumber,
                        siteStore.selectedContractNumber,
                        siteStore.selectedPremiseNumber,
                        visitDate
                    );
                    setWasteNoteUrl(url);
                }
            } else {
                const url = getWasteNoteUrl(
                    consignmentNoteCode,
                    siteStore.selectedContractNumber,
                    siteStore.selectedPremiseNumber,
                    visitDate
                );
                setWasteNoteUrl(url);
            }
        }
    }, [ visit, responseVisitDetailStatus, getWasteNoteUrl, consignmentNoteCode, siteStore.selectedContractNumber,
        visitDate, siteStore.selectedPremiseNumber ]);

    useEffect(() => {
        if (!isEmpty(wasteNotePdf) && wasteNotePdf?.signedUrl !== NOT_FOUND && typeof window != 'undefined') {
            window.open(
                wasteNotePdf.signedUrl,
                '_blank',
                'noopener,noreferrer'
            );
        }

        if (responseWasteNotePdfStatus !== FETCHING) {
            setIsLoading(false);
        }
    }, [ wasteNotePdf, responseWasteNotePdfStatus ]);

    const handleKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY_CODE) {
            handleOnClick();
        }
    };

    const getAppIcon = () => {
        const hasHazardousWaste = !isEmpty(visit) && !isEmpty(visit.visitDetails) && !isEmpty(visit.visitDetails.WasteConsignmentNoteNumber);
        if (hasHazardousWaste || !isEmpty(consignmentNoteCode)) {
            return (
                <GetAppIcon
                    className={classes.report}
                    type="button"
                    aria-label={t('aria_label_download_waste')}
                    onClick={handleOnClick}
                    tabIndex={0}
                    onKeyDown={handleKeyDown}/>
            );
        }
        return (
            <GetAppIcon
                className={classes.disabled}
                type="button"
                aria-label={t('aria_label_download_waste')}
            />
        );
    };

    const containerClass = props.widget ? '' : classes.pdfIconContainer;
    const widget = props.widget ?
        <Link className={classes.report} onClick={handleOnClick}>
            {props.widget}
        </Link> :
        getAppIcon();

    return (
        <div className={containerClass}>
            {isLoading ?
                <MIApiLoader
                    isLoading={isLoading}
                    customStyle={{
                        spinnerWidth: '24px',
                        spinnerHeight: '24px'
                    }}
                    MIComponent={null}
                    responseStatus={
                        [ responseWasteNotePdfStatus, responseVisitDetailStatus ]
                            .includes(FETCHING) ?
                            FETCHING : responseWasteNotePdfStatus
                    }
                    ariaLabelProgressBar={'aria_label_progressbar_waste_note_download'}/> :
                null}
            {!isLoading && widget}
        </div>
    );
});

export default DownloadWasteNote;
