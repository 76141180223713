import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {makeStyles} from '@material-ui/core/styles';
import {isEmpty} from '../../utils/utils';
import {useResponsive} from '../../services/useResponsive';
import {useOpenRecommendationsCount} from '../../services/recommendations/useOpenRecommendationsCount';

const desktopStyles = theme => {
    return {
        rowCount: {
            marginBottom: theme.sizes.xlarge_gap,
            fontSize: theme.fontSizes.subHeading,
        },
        openRecommendationsCount: {
            fontWeight: 'bold'
        }
    };
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        rowCount: {
            marginBottom: theme.sizes.micro_gap,
            fontSize: theme.fontSizes.mobileBody,
        },
    })
};

const OpenRecommendationsCount = observer((props) => {
    const {t} = useTranslation();
    const [openRecommendationsCount, , getOpenRecommendationsCount] = useOpenRecommendationsCount();
    const {myRentokilSiteId} = props;

    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    useEffect(() => {
        if (!isEmpty(myRentokilSiteId)) {
            getOpenRecommendationsCount(myRentokilSiteId);
        }
    }, [myRentokilSiteId, getOpenRecommendationsCount]);

    return (
        <div
            className={classes.rowCount}
            aria-label={t('aria_label_open_recomendations')}
        >
            <span className={classes.openRecommendationsCount}> {!isEmpty(openRecommendationsCount) ? openRecommendationsCount : '-'} </span>
            <span> {t('open_recomendations')} </span>
        </div>
    );
});

export default OpenRecommendationsCount;
