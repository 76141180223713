import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LoginMessage from '../../components/Marketing/LoginMessage';
import {useTranslation} from 'react-i18next';
import LanguageSwitcher from '../../components/LanguageSwitcher/LanguageSwitcher';
import ForgottenPassword from "./ForgottenPassword";
import PasswordChanged from "./PasswordChanged";
import LoginForm from "./LoginForm";
import myInitialLogoMobile from '../../assets/nav_logo_mobile.svg';
import {useAuthState} from "../../contexts/AuthProvider";
import {LOGGED_OUT_STATUS} from '../../contexts/AuthProvider/AuthConst'
import {getQueryString, isEmpty, setDocumentTitle} from '../../utils/utils';
import {useResponsive, ResponsiveComponent} from "../../services/useResponsive";
import {useAppBranding} from "../../services/useAppBranding";
import LoginSSO from "./LoginSSO";

const desktopStyles = theme => {
    return {
        container: {
            display: 'flex',
            height: 'calc(100vh - 104px)',
            background: theme.palette.background.paper,
        },
        center: {
            display: 'flex',
            flexGrow: 1,
            background: theme.palette.background.paper,
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '1440px',
        },
        languageSelectContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            textAlign: 'end',
        },
        languageSelect: {
            padding: theme.sizes.small_gap,
        }
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        container: {
            display: 'flex',
            height: '100vh',
            background: theme.palette.background.paper,
        },
        languageSelectContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: theme.sizes.xxlarge_gap,
            width: '100%',
            textAlign: 'end',
        },
        languageSelect: {
            padding: theme.sizes.large_gap
        },
        myInitialLogo: {
            width: theme.widths.mobile_login_logo,
            height: theme.heights.mobile_login_logo,
            marginBottom: theme.sizes.nano_gap,
        },
        center: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: theme.sizes.micro_gap,
            marginRight: theme.sizes.micro_gap,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
            width: '100%'
        },
    });
};

const forms = {
    LOGIN: "login",
    RESET_PASSWORD: "reset_password",
    PASSWORD_CHANGED: 'password_changed',
    SSO_LOGIN: 'sso_login',
};

const Login = () => {
    const {t, i18n} = useTranslation();
    const {isPasswordChanged, isExpired, setStatus} = useAuthState();
    const [userEmail, setUserEmail] = useState('');
    const [resetPasswordMode, setResetPasswordMode] = useState(false);
    const {getStyles} = useResponsive();
    const [ getAppBrandUrl ] = useAppBranding();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, '');
     }, [t]);

    const callback = (userEmail) => {
        setUserEmail(userEmail);
    };

    const loginFormPage = (form) => {
        return <div dir={i18n.dir()} className={classes.container}>
            <div className={classes.languageSelectContainer}>
                <div className={classes.languageSelect}>
                    <LanguageSwitcher/>
                </div>
            </div>
            <div className={classes.center}>
                <ResponsiveComponent
                    components={{
                        mobile:
                            <img
                                className={classes.myInitialLogo}
                                src={getAppBrandUrl('mobile_logo.svg', myInitialLogoMobile)}
                                alt={t('myInitial_logo')}
                            />,
                        desktop: <LoginMessage/>
                    }}
                />
                {form}
            </div>
        </div>;
    };


    const showForm = () => {
        const {token, redirectURL} = getQueryString();

        let currentForm = forms.LOGIN;
        if (resetPasswordMode && !isExpired) {
            currentForm = forms.RESET_PASSWORD;
        } else if (resetPasswordMode || isPasswordChanged) {
            currentForm = forms.PASSWORD_CHANGED;
        } else if (!isEmpty(token) && !isEmpty(redirectURL)) {
            currentForm = forms.SSO_LOGIN;
        }
        switch (currentForm) {
            case forms.LOGIN:
            default:
            return loginFormPage(<LoginForm
                    username={userEmail}
                    callback={callback}
                    onForgotPassword={() => {
                        !isExpired && setStatus(LOGGED_OUT_STATUS)
                        setResetPasswordMode(true)
                    }}/>);
            case forms.RESET_PASSWORD:
                return loginFormPage(<ForgottenPassword
                    username={userEmail}
                    callback={callback}
                    onCancel={() => {
                        setResetPasswordMode(false)
                    }}/>);
            case forms.PASSWORD_CHANGED:
                return loginFormPage(<PasswordChanged username={userEmail}/>);
            case forms.SSO_LOGIN:
                return <LoginSSO/>;
        }
    };

    return (
        <div>
            {showForm()}
        </div>
    );
};

export default Login;
