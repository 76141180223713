import { useState } from 'react';

const useModal = () => {
    const [ isShowing, setIsShowing ] = useState(false);

    function changeStatus() {
        setIsShowing(!isShowing);
    }

    return {
        isShowing: isShowing,
        changeStatus: changeStatus,
    };
};

export default useModal;
