import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { FALLBACK_LANGUAGES } from '../utils/utils';
import { FALLBACK_LANGUAGE } from '../contexts/StateProvider/LanguageStore';


const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
};


i18next
    .use(initReactI18next)
    .use(HttpApi)
    .use(LanguageDetector)
    .init({
        fallbackLng: {
            en: [ FALLBACK_LANGUAGES.en ],
            default: [ FALLBACK_LANGUAGE ]
        },
        nonExplicitSupportedLngs: true,
        interpolation: {
            format: function(value, format, lng) {
                let actualLng = lng;
                if(format === 'date' || format === 'time') {
                    //use en-gb format for all the en variations expect for en-us
                    actualLng = lng.includes('en-') && lng.toLowerCase() !== 'en-us' ? 'en-gb' : lng;
                }

                if (format === 'date') {
                    return new Intl.DateTimeFormat(actualLng).format(value);
                }
                if (format === 'time') {
                    return new Intl.DateTimeFormat(actualLng, timeOptions).format(value);
                }
                if (format === 'number') {
                    return new Intl.NumberFormat(actualLng).format(value);
                }
                return value;
            },
            escapeValue: false,
        },
        debug: false,
    });

export default i18next;
