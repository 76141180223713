import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => {
    return {
        checkbox: {
            display: 'block',
        },
    };
});

const MICheckbox = (props) => {
    const { name, label, handleChange, groupName, checked, checkboxColor } = props;
    const classes = useStyles();

    const onChange = (event) => {
        if (handleChange) {
            handleChange(event, groupName);
        }
    };

    return (
        <FormControlLabel
            className={classes.checkbox}
            control={
                <Checkbox
                    style={{ 'color': checkboxColor, '&$checked': checkboxColor }}
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    color="primary"
                />
            }
            label={label}
        />
    );
};

export default MICheckbox;
