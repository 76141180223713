import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import { useAuthState } from '../../contexts/AuthProvider';
import { useTranslation } from 'react-i18next';
import { MIButton, MIPasswordField, MITextField } from '../../rentokil-react-ui';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { isEmpty } from '../../utils/utils';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import { FETCHING, SUCCESS } from '../../services/requests/requestReducer';
import { useResponsive } from '../../services/useResponsive';

const desktopStyles = (theme) => {
    return {
        loginForm: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: theme.sizes.large_gap,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
        },
        formField: {
            marginBottom: theme.sizes.xlarge_gap,
        },
        loginTitle: {
            marginBlockEnd: theme.sizes.xlarge_gap,
        },
        loginLabel: {
            display: 'flex',
            marginBottom: theme.sizes.micro_gap,
            fontWeight: 500,
        },
        forgottenPassword: {
            marginInlineStart: 'auto',
            marginInlineEnd: 0,
            fontSize: theme.typography.body2.fontSize,
        },
        loginButton: {
            marginTop: theme.sizes.large_gap,
        },
        loginButtonContainer: {
            display: 'inline-block',
            width: theme.widths.login_button,
            height: theme.sizes.xxlarge_gap,
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        loginForm: {},
        formField: {
            marginBottom: theme.sizes.xlarge_gap,
            width: theme.widths.mobile_textField,
        },
        loginButtonContainer: {
            display: 'inline-block',
            width: theme.widths.mobile_textField,
            marginTop: theme.sizes.tiny_gap,
        },
        loginButton: {
            width: '100%',
        },
        loginTitle: {
            fontSize: theme.fontSizes.small,
            textAlign: 'center',
            marginTop: theme.sizes.no_gap,
            marginBottom: theme.sizes.xmlarge_gap,
        },
    });
};

const emailErrorsText = {
    required: 'email_required',
    validate: 'email_invalid',
};

const LoginForm = (props) => {
    const { t } = useTranslation();
    const { login, isFailed, isLocked, isExpired, isNoSite, responseStatus } = useAuthState();
    const [ loginError, setLoginError ] = useState({});
    const { callback, username, onForgotPassword } = props;
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );
    const classes = useStyles();

    const { register,
        handleSubmit,
        watch,
        clearErrors,
        formState: { errors } } = useForm({
        shouldFocusError: false
    });

    const doLogin = (values) => {
        callback(values.username);
        login(values.username, values.password);
    };

    useEffect(() => {
        callback(watch('username'));
    }, [ callback, watch ]);

    useEffect(() => {
        if (isFailed) {
            setLoginError({ type: 'password_incorrect' });
        } else if (isLocked) {
            setLoginError({ type: 'password_locked' });
        } else if (isExpired) {
            setLoginError({ type: 'password_expired' });
        } else if (isNoSite) {
            setLoginError({ type: 'no_site' });
        } else {
            setLoginError({});
        }
    }, [ isFailed, isLocked, isNoSite, isExpired, setLoginError ]);

    const passwordErrorText = (error) => {
        return (error && error.type === 'required' && t('password_required')) ||
            (loginError && loginError.type === 'password_incorrect' && t('password_incorrect')) ||
            (loginError && loginError.type === 'password_locked' && t('password_locked')) ||
            (loginError && loginError.type === 'password_expired' && t('password_expired')) ||
            (loginError && loginError.type === 'no_site' && t('no_site'));
    };

    // Continue showing the spinner when login is successful
    let status = responseStatus;
    if (status === SUCCESS && !isFailed && !isLocked && !isExpired && !isNoSite) {
        status = FETCHING;
    }

    return (
        <div className={classes.loginForm}>
            <form onSubmit={handleSubmit(doLogin)} name="login" id="loginForm" action="" method="post" noValidate>
                <h1 className={classes.loginTitle}
                    tabIndex={0}
                    aria-live={'polite'}>
                    {t('account_login')}
                </h1>
                <label className={classes.loginLabel}>
                    {t('email_address')}
                </label>
                <MITextField
                    className={classes.formField}
                    ariaLabel={t('aria_label_email')}
                    inputRef={register({
                        required: true,
                        validate: (value) => {
                            return isEmail(value);
                        }
                    })}
                    id="txtUserName"
                    variant="outlined"
                    name="username"
                    type="text"
                    error={
                        (!isEmpty(errors.username) && errors.username.type === 'required') ||
                        (!isEmpty(errors.username) && errors.username.type === 'validate') ||
                        (!isEmpty(loginError) && loginError.type === 'password_incorrect')
                    }
                    helperText={!isEmpty(errors.username) && t(emailErrorsText[errors.username.type])}
                    defaultValue={!isEmpty(username) ? username : null}
                    onChange={() => {
                        clearErrors();
                        setLoginError({});
                    }}
                />
                <label className={classes.loginLabel}>
                    {t('password')}
                    <Link href="#"
                        aria-label={t('aria_label_forgotten_password_link')}
                        onClick={onForgotPassword}
                        className={classes.forgottenPassword}>
                        {isExpired ? t('change_password') : t('forgotten_password')}
                    </Link>
                </label>
                <MIPasswordField
                    className={classes.formField}
                    ariaLabel={t('aria_label_password')}
                    inputRef={register({
                        required: true,
                    })}
                    id="txtPassword"
                    variant="outlined"
                    name="password"
                    error={!isEmpty(errors.password) ||
                        !isEmpty(loginError)}
                    helperText={passwordErrorText(errors.password)}
                    onChange={() => {
                        clearErrors();
                        setLoginError({});
                    }}
                />
                <div className={classes.loginButtonContainer}>
                    <MIApiLoader
                        customStyle={{
                            spinnerWidth: '30px',
                            spinnerHeight: '30px',
                            marginLeft: '2px',
                            marginRight: '2px',
                            marginTop: '24px',
                        }}
                        MIComponent={
                            <MIButton
                                className={classes.loginButton}
                                id="btnSubmit"
                                name="submit"
                                ariaLabel={t('login')}
                                mi_size="large"
                                mi_type="primary"
                                type="submit"
                            >
                                {t('login')}
                            </MIButton>
                        }
                        responseStatus={status}
                        ariaLabelProgressBar={t('aria_label_progressbar_login_form')}
                    />
                </div>

            </form>
        </div>
    );
};

export default LoginForm;
