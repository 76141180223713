import {makeAutoObservable} from "mobx";

class PremiseStore {
    premises = {};

    constructor() {
        makeAutoObservable(this);
    }

    getPremises(contractId) {
        return this.premises[contractId];
    }

    setPremises(contractId, premises) {
        this.premises[contractId] = premises;
    }

    reset() {
        this.premises = {};
    }
}

export {PremiseStore};