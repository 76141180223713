import { useCallback, useEffect, useState } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';
import { useAuthState } from '../contexts/AuthProvider';

export const useUpdateUserPreference = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/loginService/updateUserPreference';
    const [ result, setResult ] = useState({});
    const { countryCode, locale } = useAuthState();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setResult(response.data.status);
            } catch (error) {
                console.error('useUpdateUserPreference error: ', error);
                setResult({});
            }
        }
    }, [ responseStatus, response ]);

    const updateUserPreference = useCallback((legal, market) => {
        const url = [ endpoint, market, legal, countryCode, locale ].join('/');
        makeRequestWithParams(url).catch((error) => {
            console.error('useUpdateUserPreference error: ', error);
        });
    }, [ makeRequestWithParams, countryCode, locale ]);

    return [ result, responseStatus, updateUserPreference ];
};
