import {useState, useEffect, useCallback} from 'react';
import useRawRequest from "./requests/useRawRequest";
import {SUCCESS} from "./requests/requestReducer";
import {useAuthState} from '../contexts/AuthProvider';
import {isEmpty} from "../utils/utils";

export const useContentFromUrl = () => {
    const {countryCode, language} = useAuthState();
    const [{responseStatus, response}, makeRequestWithParams] = useRawRequest();
    const [content, setContent] = useState('');
    const baseUrl = process.env.REACT_APP_MYINITIAL_CMS_BUCKET;

    const getContentFromUrl = useCallback((url) => {
        setContent('');
        makeRequestWithParams(url).catch(error => {
            console.error('getContentFromUrl error: ', error);
        });
    }, [makeRequestWithParams]);

    const getContentUrl = useCallback((fileName, userCountryCode) => {
        const country = !isEmpty(userCountryCode)? userCountryCode : countryCode;

        return [baseUrl, country, language, fileName].filter(Boolean).join('/');
    }, [baseUrl, countryCode, language]);

    const getBaseUrl = useCallback(() => {
        return baseUrl;
    }, [baseUrl]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            setContent(response.data);
        }
    }, [responseStatus, response, setContent]);

    
    return [content, responseStatus, getContentFromUrl, getContentUrl, getBaseUrl];
}