import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useApiRequest from '../requests/useApiRequest';
import { isEmpty } from '../../utils/utils';
import { useRecommendationsStore } from '../../contexts/StateProvider/StateProvider';

export const useGetPNOLShutdownCountries = () => {
    const [ PNOLShutdownCountries, setPNOLShutdownCountries ] = useState(null);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/configService/getPNOLShutdownCountries';
    const recommendationsStore = useRecommendationsStore();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                const values = !isEmpty(response) &&
                                !isEmpty(response.data) &&
                                !isEmpty(response.data.configVal) ? response.data.configVal.split(',') : [];
                setPNOLShutdownCountries(values);
                recommendationsStore.setPNOLShutdownCountries(values);
            } catch (error) {
                console.error('useGetPNOLShutdownCountries error: ', error);
                recommendationsStore.setPNOLShutdownCountries([]);
            }
        }
    }, [ responseStatus, response, setPNOLShutdownCountries, recommendationsStore ]);

    const getPNOLShutdownCountries = useCallback(() => {
        const PNOLShutdownCountriesCached = recommendationsStore.getPNOLShutdownCountries();
        if (!isEmpty(PNOLShutdownCountriesCached)) {
            setPNOLShutdownCountries(PNOLShutdownCountriesCached);
        } else {
            setPNOLShutdownCountries(null);
            makeRequestWithParams(endpoint).catch((error) => {
                console.error('useGetPNOLShutdownCountries error: ', error);
            });
        }
    }, [ makeRequestWithParams, setPNOLShutdownCountries, recommendationsStore ]);

    return [ PNOLShutdownCountries, PNOLShutdownCountries !== null ? SUCCESS : responseStatus, getPNOLShutdownCountries ];
};
