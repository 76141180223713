import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import MIFileHandler from "../Widgets/MIFileHandler";
import MIButton from "../Widgets/MIButton";
import Link from '@material-ui/core/Link';
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";
import {useResponsive} from "../../services/useResponsive";
import {mergeStyles} from "../../utils/utils";

const desktopStyles = theme => {
    return {
        pdfIconContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '40px',
        },
        report: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            width: 'auto',
            '&:hover': {
                textDecoration: 'none',
            },
            '&:focus': theme.ariaFocus,
        },
        pdfLink: {
            fontWeight: 'bold',
            minWidth: theme.widths.download_buttons, 
        },
        icon: {
            marginBottom: [ '-', theme.sizes.nano_gap ].join(''),
        },
        downloadContainer: {
            minWidth: theme.widths.download_buttons, 
            minHeight: theme.sizes.xxlarge_gap, 
        }
    };
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        pdfLink: {
            ...baseStyle.pdfLink,
            fontSize: theme.fontSizes.mobileBody
        },
    })
};

const MIDownload = observer((props) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const {getStyles} = useResponsive();
    const {button, className, title, setAlert, setIsShowing, customStyle, url, isIcon, useFloorPlanApi, miType, requestMethod, requestParams} = props;

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    const handleOnClick = () => {
        setIsLoading(true);
    };

    let containerClass = isIcon ? classes.pdfIconContainer : classes.downloadContainer;
    containerClass = mergeStyles(containerClass, className);
    const displayWidget = isIcon ? (
        <PrintIcon className={classes.report} type='button' aria-label={t('aria_label_open_pdf')} tabIndex={0} onClick={handleOnClick}/>
    ) : (
        <Link onClick={handleOnClick}>
            {
                button ||
                <MIButton
                    className={classes.pdfLink}
                    mi_size={'fit'}
                    endIcon={<GetAppIcon className={classes.icon}/>}
                    mi_type={miType}
                >
                    {title}
                </MIButton>
            }
        </Link>
    );

    return (
        <div className={containerClass}>
            <MIFileHandler
                isLoading={isLoading}
                url={url}
                setIsLoading={setIsLoading}
                setAlert={setAlert}
                setIsShowing={setIsShowing}
                customStyle={customStyle ? customStyle : {'spinnerWidth': '24px', 'spinnerHeight': '24px', 'marginTop': '12px'}}
                ariaLabelProgressBar={t('aria_label_progressbar_print_report')}
                useFloorPlanApi={useFloorPlanApi}
                requestMethod={requestMethod}
                requestParams={requestParams}
            />
            {!isLoading && displayWidget}
        </div>
    );
});

export default MIDownload;
