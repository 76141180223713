import {makeAutoObservable} from "mobx";

class VisitStore {
    visitHistory = {};
    plannedVisits = {};

    constructor() {
        makeAutoObservable(this);
    }

    getVisitHistory(siteId) {
        return this.visitHistory[siteId];
    }

    setVisitHistory(siteId, visitHistoryList) {
        this.visitHistory[siteId] = visitHistoryList;
    }

    getPlannedVisits(siteId) {
        return this.plannedVisits[siteId];
    }

    setPlannedVisits(siteId, plannedVisits) {
        this.plannedVisits[siteId] = plannedVisits;
    }

    reset() {
        this.visitHistory = {};
        this.plannedVisits = {};
    }
}

export {VisitStore};