import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import SiteSelector from '../../../components/SiteSelector/SiteSelector';
import MIPageTitle from '../../../components/Widgets/MIPageTitle';
import SiteLevel from './SiteLevel';
import ContractLevel from './ContractLevel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { observer } from 'mobx-react-lite';
import { setDocumentTitle } from '../../../utils/utils';
import { useResponsive, ResponsiveComponent } from '../../../services/useResponsive';

const desktopStyles = (theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        tabOuter: {
            display: 'flex',
        },
        tabFiller: {
            flex: 1,
            borderBottom: '4px solid #CDD2D5',
        },
        tabContainer: {
            'height': theme.sizes.mega_gap,
            '& span.MuiTabs-indicator': {
                height: '4px',
            },
            'marginRight': 'auto',
        },
        tab: {
            'textTransform': 'none',
            'padding': '16px 16px',
            'fontWeight': theme.typography.fontWeightBold,
            'color': theme.colors.mainText,
            'fontSize': theme.fontSizes.nav,
            '&.Mui-selected': {
                fontWeight: theme.typography.fontWeightBold,
            },
            'borderBottom': '4px solid #CDD2D5',
            'minWidth': '180px',
            'width': 'fit-content',
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.xxxlarge_gap,
            paddingRight: theme.sizes.no_gap,
            paddingLeft: theme.sizes.no_gap,
            minHeight: 'calc(100vh - 160px)',
        },
        content: {
            paddingRight: theme.sizes.small_gap,
            paddingLeft: theme.sizes.small_gap,
        },
        tabFiller: {
            flex: 0,
        },
        tabContainer: {
            ...baseStyle.tabContainer,
            height: theme.sizes.mobile_tabs,
            width: '100%',
        },
        tab: {
            ...baseStyle.tab,
            minWidth: 'unset',
            padding: theme.sizes.micro_gap,
            fontSize: theme.fontSizes.mobileTitle,
        }
    });
};
const ContractOverview = observer(() => {
    const { t } = useTranslation();
    const [ value, setValue ] = useState(0);
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_contract_overview');
    }, [ t ]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper className={classes.root} aria-label={t('aria_label_contract_overview_page')}>
            <SiteSelector/>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop:
                        <MIPageTitle
                            title={t('contract_overview')}
                            ariaLabel={t('aria_label_contract_overview_title')}
                            marginTop={8}
                            marginBottom={0}
                        />
                }}
            />
            <div className={classes.content}>
                <div className={classes.tabOuter}>
                    <Tabs
                        className={classes.tabContainer}
                        value={value}
                        onChange={handleChange}
                        variant={'scrollable'}
                        indicatorColor={'primary'}
                        textColor={'primary'}
                    >
                        <Tab className={classes.tab} key={0} label={t('nav_site_level')}
                            aria-label={t('aria_label_site_level_tab')}/>
                        <Tab className={classes.tab} key={1} label={t('nav_contract_level')}
                            aria-label={t('aria_label_contract_level_tab')}/>
                    </Tabs>
                    <div className={classes.tabFiller}/>
                </div>

                {value === 0 && <SiteLevel/>}
                {value === 1 && <ContractLevel/>}
            </div>
        </Paper>
    );
});

export default ContractOverview;
