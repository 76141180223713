import React from 'react';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isObjectEmpty } from '../utils/utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    option: {
        height: theme.sizes.xxxlarge_gap,
        display: 'flex',
        alignItems: 'center',
    },
    optionText: {
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
    },
    optionInner: {
        marginTop: theme.sizes.nano_gap,
        color: theme.palette.text.primary,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
    },
    formField: {
        'display': 'flex',
        'width': (props) => (props.width ? `${props.width}px` : '145px'),
        'height': theme.sizes.xxxlarge_gap,
        '& fieldset': {
            borderRadius: '0px',
            border: theme.borders.listBorders,
        },
    },
    listbox: {
        borderRadius: '0px',
        border: theme.borders.listBorders,
        borderWidth: '1px',
        padding: '0px',
    },
}));

const MIDropDown = ({ options = [], ariaLabel, dropDownProps, onHandleChange, dataTestId, disableClearable, ...props }) => {
    const classes = useStyles(props);
    const disabled = props.disabled;
    const { t } = useTranslation();

    return (
        <Controller
            render={({ onChange, ...props }) =>
                <Autocomplete
                    className={classes.formField}
                    aria-label={ariaLabel}
                    options={options}
                    openText={t('open')}
                    closeText={t('close')}
                    classes={{ listbox: classes.listbox, option: classes.option }}
                    popupIcon={<KeyboardArrowDownIcon/>}
                    handleHomeEndKeys
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={(e, data) => (!isObjectEmpty(onHandleChange) ? onHandleChange(data) : onChange(data))}
                    data-testid={dataTestId}
                    disableClearable={disableClearable}
                    disabled={disabled}
                    {...props}
                    {...dropDownProps}
                />
            }
            rules={{ required: true }}
            {...props}
        />
    );
};

export default MIDropDown;
