import React, { useCallback, useEffect, useState } from 'react';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import Theme from '../Theme/Theme';
import Footer from '../Footer/Footer';
import Login from '../../pages/Login/Login';
import Home from '../../pages/Home/Home';
import { useAuthState } from '../../contexts/AuthProvider';
import { useTranslation } from 'react-i18next';
import {
    LOGGED_IN_STATUS,
    LOGGED_OUT_STATUS,
    PENDING_STATUS,
    SUCCESS_STATUS
} from '../../contexts/AuthProvider/AuthConst';
import { BrowserRouter, Switch, Route, useHistory } from 'react-router-dom';
import NavigationBar from '../NavigationBar/NavigationBar';
import Container from '@material-ui/core/Container';
import Service from '../../pages/Service/Service';
import Reports from '../../pages/Reports/Reports';
import Waste from '../../pages/Waste/Waste';
import Support from '../../pages/Support/Support';
import Account from '../../pages/Account/Account';
import Documents from '../../pages/Documents/Documents';
import HomeBanner from '../Marketing/HomeBanner';
import MISpinner from '../Widgets/MISpinner';
import TermsAndConditions from '../../pages/TermsAndConditions/TermsAndConditions';
import { useGetAudit } from '../../services/useGetAudit';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import MIApiLoader from '../Widgets/MIApiLoader';
import { isEmpty, getBool, removeSSOParams } from '../../utils/utils';
import {
    useHealthStatusStore, useLanguageStore,
    useRegionStore,
} from '../../contexts/StateProvider/StateProvider';
import { ERROR, FETCHING, NOT_FOUND } from '../../services/requests/requestReducer';
import { observer } from 'mobx-react-lite';
import MIAlert from '../Widgets/MIAlert';
import { useUserPermissions } from '../../services/useUserPermissions';
import FooterStickyMenu from '../Footer/FooterStickyMenu';
import OneTrustCookie from '../OneTrustCookie/OneTrustCookie';
import { useResponsive, ResponsiveComponent } from '../../services/useResponsive';
import CountrySelector from '../../components/CountrySelector/CountrySelector';
import useModal from '../../services/useModal';
import HealthCheck from './HealthCheck';
import { handleMultiLoginLogic } from '../../utils/multyCountryUtils';
import { useAppBranding } from '../../services/useAppBranding';
import Chatbot from '../Chatbot/Chatbot';
import { useAnalytics } from '../../services/analytics/useAnalytics';
import {
    LOG_EVENT_PARAMS,
    LOG_EVENT_ACTIONS,
    ADD_USER_PROPERTIES_PARAMS,
    IS_RENTOKIL_MAP
} from '../../services/analytics/FirebaseAnalyticsEnum';

const useStyles = makeStyles(theme => ({
    headerAndFooter: {
        background: Theme.palette.background.paper,
    },
    container: {
        maxWidth: '100%',
        background: Theme.palette.background.default,
        padding: 0,
        position: 'relative',
        minHeight: '100vh',
        overflowY: 'hidden',
    },
    serviceUnavailable: {
        paddingLeft: Theme.sizes.large_gap,
        paddingRight: Theme.sizes.large_gap,
    },
}));

const App = observer(() => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const regionStore = useRegionStore();
    const observerHealthStatus = useHealthStatusStore();
    const { status, locale, emailAddress, countryCode } = useAuthState();
    const {
        feature_hazardous_waste_consignment_notes,
        feature_cookie_management,
        feature_rapid_smart_hygiene,
        feature_ebilling,
        feature_medical_training,
        feature_web_shop,
        feature_chatbot,
        feature_hazardous_waste_portugal,
        feature_hazardous_waste_transfer_notes
    } = useFlags();
    const [ getAppBrandUrl ] = useAppBranding();
    const authStatus = status();
    const [ audit, responseStatus, getAudit ] = useGetAudit();
    const [ legalAccepted, setLegalAccepted ] = useState();
    const [ isShowing, setIsShowing ] = useState(false);
    const [ alert, setAlert ] = useState({});
    const healthStatus = observerHealthStatus.getHealthStatus();
    const [ , getUserPermissions ] = useUserPermissions();
    // eslint-disable-next-line no-unused-vars
    const { isShowing:hideCountrySelector, changeStatus:toggleCountrySelector } = useModal();
    const { getStyles } = useResponsive();
    const history = useHistory();
    const { logEvent, setUserProperties } = useAnalytics();

    const languageStore = useLanguageStore();

    useEffect(() => {
        // import the right moment locale based of the current language
        if (!isEmpty(languageStore.language)) {
            languageStore.importMomentLocale();
        }
    }, [ languageStore, languageStore.language ]);

    useEffect(() => {
        if (!isEmpty(emailAddress) && !isEmpty(countryCode)) {
            // Anonymise user email domain.
            const userDomain = emailAddress.split('@')[1];
            const isRentokil = IS_RENTOKIL_MAP.includes(userDomain);
            setUserProperties({
                [ADD_USER_PROPERTIES_PARAMS.USER_DOMAIN]: userDomain,
                [ADD_USER_PROPERTIES_PARAMS.IS_RENTOKIL]: isRentokil,
                [ADD_USER_PROPERTIES_PARAMS.USER_COUNTRY]: countryCode
            });
        }
    }, [ setUserProperties, emailAddress, countryCode ]);

    const spinnerMarginTop = getStyles({
        desktop: Theme.sizes.xxlarge_gap,
        mobile: Theme.sizes.giga_gap
    });

    const showAccountTab = useCallback(() => {
        // show the tab only if there is at least one feature enabled for the country
        return feature_rapid_smart_hygiene || feature_ebilling || feature_medical_training || feature_web_shop;
    }, [ feature_rapid_smart_hygiene, feature_ebilling, feature_medical_training, feature_web_shop ]);


    let navigation = {};
    let routes = [];
    if (!isEmpty(audit) && legalAccepted) {
        navigation = {
            homepage: {
                nav: { path: '/', label: t('nav_home'), ariaLabel: t('aria_label_homepage_tab') },
                component: Home
            },
            service: {
                nav: { path: '/service', label: t('nav_service'), ariaLabel: t('aria_label_service_tab') },
                component: Service
            },
            reports: {
                nav: { path: '/reports', label: t('nav_reports'), ariaLabel: t('aria_label_reports_tab') },
                component: Reports
            },
            documents: {
                nav: { path: '/documents', label: t('nav_documents'), ariaLabel: t('aria_label_documents_tab') },
                component: Documents
            },
            support: {
                nav: { path: '/support', label: t('nav_support'), ariaLabel: t('aria_label_support_tab') },
                component: Support
            },
        };

        routes = [ 'homepage', 'service', 'reports', 'documents', 'support' ];

        if (feature_hazardous_waste_consignment_notes ||
            feature_hazardous_waste_portugal ||
            feature_hazardous_waste_transfer_notes) {
            navigation['waste'] = {
                nav: { path: '/waste', label: t('nav_waste'), ariaLabel: t('aria_label_waste_tab') },
                component: Waste
            };
            routes.splice(3, 0, 'waste');
        }

        if (showAccountTab()) {
            navigation['account'] = {
                nav: { path: '/account', label: t('nav_account'), ariaLabel: t('aria_label_account_tab') },
                component: Account
            };
            routes.splice(routes.indexOf('documents'), 0, 'account');
        }
    } else {
        navigation = {
            termsAndConditions: {
                nav: { path: '/', label: t('terms_and_conditions'), ariaLabel: t('aria_label_terms_and_conditions') },
                component: TermsAndConditions
            }
        };
        routes = [ 'termsAndConditions' ];
    }

    const showAlert = (message, secondaryMessage) => {
        setAlert({
            message: message,
            secondaryMessage: secondaryMessage,
            severity: 'error'
        });
        setIsShowing(true);
    };

    const onHideAlert = () => {
        // reset the status otherwise the alert will be triggered only once if the error persist
        observerHealthStatus.reset();
    };

    const showCountryPopup = () => {
        return handleMultiLoginLogic(regionStore.getAvailableRegions()) === SUCCESS_STATUS && isEmpty(regionStore.getSelectedCountry());
    };

    useEffect(() => {
        if (healthStatus === ERROR) {
            showAlert(
                'alert_backend_down',
                'alert_backend_down_secondary'
            );
        }
        if (healthStatus === NOT_FOUND) {
            showAlert(
                'alert_not_found',
                'alert_not_found_secondary'
            );
        }
    }, [ healthStatus ]);

    useEffect(() => {
        if (authStatus === LOGGED_IN_STATUS) {
            logEvent(LOG_EVENT_PARAMS.PAGE_CLICK.NAME, { [LOG_EVENT_PARAMS.PAGE_CLICK.PARAM]: LOG_EVENT_ACTIONS.LOGIN });
        }
    }, [ authStatus, logEvent ]);

    useEffect(() => {
        if (!isEmpty(audit.legalAccpt)) {
            setLegalAccepted(getBool(audit.legalAccpt));
        }
    }, [ audit ]);

    useEffect(() => {
        if (authStatus === LOGGED_IN_STATUS) {
            getAudit('NA', true);
        }
    }, [ authStatus, getAudit ]);

    useEffect(() => {
        if (authStatus === LOGGED_IN_STATUS) {
            getUserPermissions();
        }
    }, [ authStatus, getUserPermissions, locale ]);

    useEffect(() => {
        if (authStatus === LOGGED_IN_STATUS) {
            // remove SSO params after login
            if (history) {
                history.replace({
                    search: removeSSOParams(),
                });
            }
        }
    }, [ authStatus, history ]);


    useEffect(() => {
        const favicon = document.getElementById('favicon');
        const customBrandUrl = getAppBrandUrl('favicon.ico', `${process.env.PUBLIC_URL}/favicon.ico`);
        if (favicon) {
            favicon.href = customBrandUrl;
        }
    }, [ getAppBrandUrl ]);

    const getNav = (routes) => routes.map((route) => navigation[route].nav);

    const navSwitch = (routes) => (
        <MIApiLoader
            customStyle={{
                marginTop: spinnerMarginTop
            }}
            MIComponent={
                <Switch>
                    {routes.map((route) => (
                        <Route
                            exact={navigation[route].nav.path === '/'}
                            path={navigation[route].nav.path}
                            component={navigation[route].component}
                            key={navigation[route].nav.label}
                        />
                    ))}
                </Switch>
            }
            responseStatus={isEmpty(audit) || isEmpty(legalAccepted) ? FETCHING : responseStatus}
            ariaLabelProgressBar={t('aria_label_progressbar_app')}
        />
    );

    const getAppContent = () => {
        switch (authStatus) {
            case PENDING_STATUS:
            case FETCHING:
                return (
                    <>
                        <BrowserRouter>
                            <MISpinner customStyle={{'spinnerWidth': '80px', 'spinnerHeight': '80px', marginTop: '20%'}}
                                ariaLabelProgressBar={t('aria_label_progressbar_app')}/>
                            <HealthCheck/>
                        </BrowserRouter>
                    </>
                );
            case LOGGED_IN_STATUS:
                return (
                    <BrowserRouter>
                        <HealthCheck/>
                        {feature_chatbot && <Chatbot/>}
                        <div className={classes.headerAndFooter}>
                            <NavigationBar routes={getNav(routes)} showNavBar={!isEmpty(audit) && legalAccepted}/>
                        </div>
                        <main>
                            <ResponsiveComponent
                                components={{
                                    mobile: null,
                                    desktop: <HomeBanner/>,
                                }}
                            />
                            {navSwitch(routes)}
                        </main>

                        <MIAlert
                            isShowing={isShowing}
                            setIsShowing={setIsShowing}
                            message={t(alert.message)}
                            secondaryMessage={t(alert.secondaryMessage)}
                            severity={alert.severity}
                            ariaLabel={t('aria_backend_down_message')}
                            onHide={onHideAlert}
                        />
                        <ResponsiveComponent
                            components={{
                                mobile: <FooterStickyMenu />,
                                desktop: null,
                            }}
                        />
                    </BrowserRouter>
                );
            case LOGGED_OUT_STATUS:
            default:
                return (
                    <main>
                        {/*if for some reason we show an empty list allow the user to close the region selector*/}
                        {showCountryPopup() &&
                            <CountrySelector
                                countriesList={regionStore.getAvailableRegions()}
                                isShowing={showCountryPopup()}
                                changeStatus={toggleCountrySelector}
                                hideCloseIcon={!isEmpty(regionStore.getAvailableRegions())}
                                changeLanguage={true}
                            />
                        }
                        <Login/>
                        <MIAlert
                            isShowing={isShowing}
                            setIsShowing={setIsShowing}
                            message={t(alert.message)}
                            secondaryMessage={t(alert.secondaryMessage)}
                            severity={alert.severity}
                            ariaLabel={t('aria_backend_down_message')}
                            onHide={onHideAlert}
                        />
                    </main>
                );
        }
    }

    return (
        <MuiThemeProvider theme={Theme}>
            <Container dir={i18n.dir()} className={classes.container}>
                {!isEmpty(feature_cookie_management) && feature_cookie_management && !isEmpty(process.env.REACT_APP_ONE_TRUST_COOKIE_ID) ?
                    <OneTrustCookie/> : ''}
                {getAppContent()}
                <ResponsiveComponent
                    components={{
                        mobile: null,
                        desktop:
                            <footer className={classes.headerAndFooter}>
                                <Footer/>
                            </footer>,
                    }}
                />
            </Container>
        </MuiThemeProvider>
    );
});

export default App;
