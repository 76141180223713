import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {usePlannedVisits} from "../../services/plannedVisits/usePlannedVisits";
import {useAuthState} from "../../contexts/AuthProvider";
import { useTranslation } from 'react-i18next';
import {useSiteStore} from "../../contexts/StateProvider/StateProvider";
import {observer} from "mobx-react-lite";
import PlannedVisitsDesktop from "./variant/PlannedVisitsDesktop";
import PlannedVisitsMobile from "./variant/PlannedVisitsMobile";
import {ResponsiveComponent} from "../../services/useResponsive";
import {useFlags} from "../../contexts/FlagsProvider/FlagsProvider";
import {getBool, isEmpty} from "../../utils/utils";
import {usePlannedVisitsGCP} from "../../services/plannedVisits/usePlannedVisitsGCP";
import { useVisitHistoryByFlag } from '../../services/visits/useVisitHistoryByFlag';

const useStyles = makeStyles(theme => ({
    plannedVisits: {
        display: 'inline-flex',
        marginRight: theme.sizes.small_gap,
    },
}));

const useSharedStyles = makeStyles(theme => ({
    title: {
        '&:focus': theme.ariaFocus,
    },
    calendar: {
        overflow: 'hidden'
    },
    topBorder: {
        width: '100%',
    }
}));

const PlannedVisits = observer((props) => {
    const {locale, countryCode} = useAuthState();
    const {i18n} = useTranslation();
    const siteStore = useSiteStore();
    const classes = useStyles();
    const sharedClasses = useSharedStyles();
    const {feature_planned_visits_gcp} = useFlags();
    const [plannedVisitsKony, responseStatusKony, getPlannedVisits] = usePlannedVisits();
    const [plannedVisitsGCP, responseStatusGCP, getPlannedVisitsGCP] = usePlannedVisitsGCP();
    const [previousVisits,, getVisitHistory] = useVisitHistoryByFlag();
    const [selectedPlannedVisitDateString, setSelectedPlannedVisitDateString] = useState(null);

    const plannedVisits = feature_planned_visits_gcp? plannedVisitsGCP : plannedVisitsKony;
    const responseStatus = feature_planned_visits_gcp? responseStatusGCP :responseStatusKony;

    const resetDate = useCallback(() => {
        // reset the date on site change and on lang change
        setSelectedPlannedVisitDateString(null);
    }, [ setSelectedPlannedVisitDateString, i18n.language ]);

    useEffect(() => {
        if (getBool(feature_planned_visits_gcp) && !isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber)) {
            getPlannedVisitsGCP(siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, countryCode);
            resetDate();
        }
    }, [ getPlannedVisitsGCP, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, feature_planned_visits_gcp, countryCode, resetDate]);

    useEffect(() => {
        if (feature_planned_visits_gcp === false && locale && siteStore.selectedSiteId) {
            getPlannedVisits(siteStore.selectedSiteId, locale);
            resetDate();
        }
    }, [locale, siteStore.selectedSiteId, getPlannedVisits, feature_planned_visits_gcp, resetDate]);

     useEffect(() => {
        if (!isEmpty(siteStore.selectedSiteId) && !isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber)) {
            getVisitHistory(siteStore.selectedSiteId,
                siteStore.selectedContractNumber,
                siteStore.selectedPremiseNumber);
        }
    }, [ siteStore.selectedSiteId, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, getVisitHistory ]);

    const params = {
        sharedClasses,
        plannedVisits,
        responseStatus,
        previousVisits,
        selectedPlannedVisitDateString,
        setSelectedPlannedVisitDateString,
    };

    return (
        <ResponsiveComponent
            components={{
                mobile: <PlannedVisitsMobile {...params} {...props}/>,
                desktop: <div className={classes.plannedVisits}> <PlannedVisitsDesktop {...params} {...props}/> </div>,
            }}
        />
    );
});

export default PlannedVisits;
