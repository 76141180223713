import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import { MIPasswordField } from '../../rentokil-react-ui';
import MIButton from '../../components/Widgets/MIButton';
import {useForm} from 'react-hook-form';
import {isEmpty, validatePassword} from "../../utils/utils";
import {useChangePassword} from "../../services/useChangePassword";
import {FETCHING, SUCCESS} from "../../services/requests/requestReducer";
import MIAlert from "../../components/Widgets/MIAlert";
import {useAuthState} from "../../contexts/AuthProvider";
import MIApiLoader from "../../components/Widgets/MIApiLoader";
import {useResponsive} from "../../services/useResponsive";
import {PASSWORD_INVALID} from "../../contexts/AuthProvider/AuthConst";

const desktopStyles = theme => {
    return {
        changePasswordForm: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: theme.sizes.large_gap,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
        },
        formField: {
            marginBottom: theme.sizes.xmlarge_gap,
        },
        changePasswordTitle: {
            marginBlockEnd: theme.sizes.xlarge_gap,
        },
        fieldLabel: {
            display: 'flex',
            marginBottom: theme.sizes.micro_gap,
            fontWeight: 500,
        },
        requestError: {
            maxWidth: '448px',
            fontSize: theme.typography.body2.fontSize,
            color: theme.palette.error.dark,
        },
        changePasswordButtonContainer: {
            marginTop: theme.sizes.large_gap,
            display: 'inline-block',
            width: theme.widths.login_button,
            height: theme.sizes.xxlarge_gap
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        changePasswordForm: {},
        formField: {
            marginBottom: theme.sizes.xlarge_gap,
            width: theme.widths.mobile_textField,
        },
        changePasswordTitle: {
            fontSize: theme.fontSizes.small,
            textAlign: 'center',
            marginTop: theme.sizes.no_gap,
            marginBottom: theme.sizes.small_gap,      
        },
        changePasswordButtonContainer: {
            display: 'inline-block',
            width: theme.widths.mobile_textField,
            marginTop: theme.sizes.tiny_gap,
        },
        changePasswordButton: {
            width: '100%',
        },
        requestError: {
            maxWidth: theme.widths.mobile_textField,
            fontSize: theme.fontSizes.mobileLabel,
            color: theme.palette.error.dark,
        },
    });
};

const PasswordChanged = (props) => {
    const {t} = useTranslation();
    const {logout} = useAuthState();
    const [changePasswordStatus, changeRequestStatus, changePassword] = useChangePassword();
    const [requestError, setRequestError] = useState('');
    const [isSuccessShowing, setIsSuccessShowing] = useState(false);
    const {username} = props;
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors}
    } = useForm({
        shouldFocusError: false,
        mode: 'all'
    });

    useEffect(() => {
        if (changeRequestStatus === SUCCESS) {
            if (changePasswordStatus === PASSWORD_INVALID) {
                setRequestError('previous_password');
            } else {
                setRequestError('');
                setIsSuccessShowing(true);
            }
        } else if (!isEmpty(changeRequestStatus) && changeRequestStatus !== FETCHING) {
            setRequestError('service_unavailable');
        }
    }, [changeRequestStatus, changePasswordStatus]);

    const onClose = () => {
        logout();
    };

    const doChangePassword = ({currentPassword, newPassword}) => {
        setRequestError('');
        changePassword(username, currentPassword, newPassword);
    };

    const passwordError = (error) => {
        return (
            error && error.type === 'required' && t('password_required')
        ) || (
            error && error.type === 'validate' && t('password_invalid')
        )
    };

    const confirmPasswordError = (error) => {
        return (
            error && error.type === 'required' && t('password_required')
        ) || (
            error && error.type === 'validate' && t('password_must_match')
        )
    };

    const showRequestError = () => {
        return isEmpty(requestError) ? null : <p className={classes.requestError}>{t(requestError)}</p>;
    };

    return (
        <div className={classes.changePasswordForm}>
            <MIAlert
                isShowing={isSuccessShowing}
                setIsShowing={setIsSuccessShowing}
                message={t('password_updated')}
                onHide={onClose}
                severity='success'
                ariaLabel={t('aria_label_change_password_success')}
                autoHideDuration={2000}
            />
            <form onSubmit={handleSubmit(doChangePassword)}>
                <h1 className={classes.changePasswordTitle}
                    tabIndex={0}
                    aria-live={"polite"}>{t('change_password')}</h1>
                <label className={classes.fieldLabel}>
                    {t('current_password')}
                </label>
                <MIPasswordField
                    className={classes.formField}
                    ariaLabel={t('current_password')}
                    inputRef={register({
                        required: true
                    })}
                    id='currentPassword'
                    variant='outlined'
                    name='currentPassword'
                    error={!isEmpty(errors.currentPassword)}
                    helperText={passwordError(errors.currentPassword)}
                />
                <label className={classes.fieldLabel}>
                    {t('new_password')}
                </label>
                <MIPasswordField
                    className={classes.formField}
                    ariaLabel={t('new_password')}
                    inputRef={
                        register({
                            required: true,
                            validate: validatePassword
                        })}
                    id='newPassword'
                    variant='outlined'
                    name='newPassword'
                    error={!isEmpty(errors.newPassword)}
                    helperText={passwordError(errors.newPassword)}
                    onChange={() => {
                        setRequestError('');
                    }}
                />
                <label className={classes.fieldLabel}>
                    {t('confirm_new_password')}
                </label>
                <MIPasswordField
                    className={classes.formField}
                    ariaLabel={t('confirm_new_password')}
                    inputRef={register({
                        required: true,
                        validate: value => (value === watch('newPassword'))
                    })}
                    id='confirmNewPassword'
                    variant='outlined'
                    name='confirmNewPassword'
                    error={!isEmpty(errors.confirmNewPassword)}
                    helperText={confirmPasswordError(errors.confirmNewPassword)}
                    onChange={() => {
                        setRequestError('');
                    }}
                />
                {showRequestError()}
                <div className={classes.changePasswordButtonContainer}>
                    <MIApiLoader
                        customStyle={{
                            'spinnerWidth': '30px',
                            'spinnerHeight': '30px',
                            marginLeft: '2px',
                            marginRight: '2px'
                        }}
                        MIComponent={<MIButton
                            className={classes.changePasswordButton}
                            ariaLabel={t('change_password')}
                            mi_size='large'
                            mi_type='primary'
                            type='submit'
                        >
                            {t('change_password')}
                        </MIButton>}
                        responseStatus={changeRequestStatus}
                        ariaLabelProgressBar={t('aria_label_progressbar_password_changed')}
                    />
                </div>
            </form>
        </div>
    );
};

export default PasswordChanged;
