import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { getBool } from '../utils/utils';
import MIButton from './MIButton';
import { isObjectEmpty } from '../utils/utils';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    menuIcon: {
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.primary.main,
    },
    menuContainer: {
        alignItems: 'center',
        margin: 'auto',
    },
}));

const MIActionMenu = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { menuItems, anchorEl, setAnchorEl, closeActionsMenu, useMIButton, buttonName, transformOrigin, anchorOrigin } = props;

    const handleClick = (event) => {
        if (event.currentTarget !== anchorEl) {
            setAnchorEl(event.currentTarget);
        }
    };

    const getMenuItems = () => {
        let items = [];
        menuItems.forEach((menuItem) => {
            items.push(
                <MenuItem key={menuItem.key}>
                    {menuItem}
                </MenuItem>
            );
        });
        return items;
    };

    return (
        <div className={classes.menuContainer}>
            {useMIButton ?
                <MIButton
                    className={classes.actionButton}
                    ariaLabel={buttonName}
                    display={'flex'}
                    bordercolor={'primary'}
                    borderradius={'0px'}
                    variant={'outlined'}
                    onClick={handleClick}
                    startIcon={<MoreVertIcon className={classes.icon}/>}
                >
                    {buttonName}
                </MIButton> :
                <IconButton
                    aria-label={t('aria_label_action_menu_icon')}
                    className={classes.menuIcon}
                    onClick={handleClick}
                >
                    <MoreVertIcon className={classes.icon}/>
                </IconButton>
            }
            <Menu
                aria-label={t('aria_label_action_menu')}
                anchorEl={anchorEl}
                open={getBool(anchorEl)}
                onClose={closeActionsMenu}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: isObjectEmpty(anchorOrigin) ? 0 : anchorOrigin.vertical,
                    horizontal:  isObjectEmpty(anchorOrigin) ? 'right' : anchorOrigin.horizontal,
                }}
                transformOrigin={{
                    vertical: isObjectEmpty(transformOrigin) ? 'top' : transformOrigin.vertical,
                    horizontal: isObjectEmpty(transformOrigin) ? 'left' : transformOrigin.horizontal,
                }}
                PaperProps={{
                    style:{
                        border: '1px solid #d3d4d5',
                    }
                }}
            >
                {getMenuItems()}
            </Menu>
        </div>
    );
};

MIActionMenu.propTypes = {

    /** Accessibility aria label */
    ariaLabel: PropTypes.string.isRequired,

    /** Menu item array */
    menuItems: PropTypes.arrayOf(PropTypes.element).isRequired,

    /** Anchor element state value */
    anchorEl: PropTypes.element,

    /** Anchor element set state function */
    setAnchorEl: PropTypes.func,

    /** Menu close function */
    closeActionsMenu: PropTypes.func,

    /** Optional should use the internal MIButton widget */
    useMIButton: PropTypes.bool,

    /** Button text (used for MIButton) */
    buttonName: PropTypes.string,

    /** Location from where the menu with transition */
    transformOrigin: PropTypes.exact({
        vertical: PropTypes.string,
        horizontal: PropTypes.string
    }),

    /** Location of the menu's anchor point */
    anchorOrigin: PropTypes.exact({
        vertical: PropTypes.string,
        horizontal: PropTypes.string
    }),

};

export default MIActionMenu;
