import React, {useEffect, useState} from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import IconButton from '@material-ui/core/IconButton';
import { MuiPickersUtilsProvider, Calendar } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import { isToday, mergeStyles } from '../../utils/utils';

const materialTheme = createTheme({
    overrides: {
        MuiPickersDay: {
            current: {
                'color': '#000000de',
                'backgroundColor': '#inherit',
                '&:hover': {
                    backgroundColor: '#inherit',
                },
            },
            daySelected: {
                'color': '#000000de',
                'backgroundColor': 'inherit',
                '&:hover': {
                    backgroundColor: '#inherit',
                },
            },
        },
    },
});


const useStyles = makeStyles((theme) => {
    return {
        icon: {
            top: 0,
            bottom: 0,
            borderRadius: '50%',
            width: '36px',
            height: '36px',
            margin: '0 2px',
            padding: 0,
            fontSize: '0.85rem',
        },
        plannedVisitIcon: {
            color: theme.colors.plannedVisitIcon,
            border: `2px solid ${ theme.colors.plannedVisitIcon}`,
        },
        previousVisitIcon: {
            color: theme.colors.previousVisitIcon,
            border: `2px solid ${ theme.colors.previousVisitIcon}`,
        },
        daySelected: {
            'color': '#fff',
            'backgroundColor': '#007dc5',
            '&:hover': {
                backgroundColor: '#007dc5',
            },
        },
        previousVisitsDaySelected: {
            'color': '#fff',
            'backgroundColor': theme.colors.previousVisitIcon,
            '&:hover': {
                backgroundColor: theme.colors.previousVisitIcon,
            },
        },
        today: {
            'backgroundColor': '#CDD2D5',
            '&:hover': {
                backgroundColor: '#CDD2D5',
            },
            'color': theme.palette.text.primary,
        },
        hidden: {
            opacity: 0,
            pointerEvents: 'none',
            marginLeft: '2px',
            marginRight: '2px',
            borderRadius: 0
        }
    };
});

const MICalendar = (props) => {
    const { t } = useTranslation();
    let selectedDate = moment();
    const { plannedVisits, previousVisits, selectedPlannedVisitDateString, onDayClick } = props;
    const classes = useStyles();
    const currentLocale = moment().locale();

    const getCustomDateElement = (day, className) => {
        return <div>
            <IconButton className={mergeStyles(classes.icon, className)}>
                <span> {moment(day).locale(currentLocale).format('D')} </span>
            </IconButton>
        </div>;
    };

    function renderDay(day, selected, dayInCurrentMonth, dayComponent) {
        // Currently the date format in the api is always in the same format regardless the current locale
        if(!dayInCurrentMonth) {
            return getCustomDateElement(day, classes.hidden);
        }
        const dateFormat = 'DD/MM/YYYY';
        const dataString = day.format(dateFormat);
        if (plannedVisits[dataString]) {
            const buttonClass = selected.isSame(day, 'date') && !isToday(day) ? classes.daySelected : classes.plannedVisitIcon;
            return getCustomDateElement(day, buttonClass);
        } else if (previousVisits[dataString]) {
            const buttonClass = selected.isSame(day, 'date') && !isToday(day) ? classes.previousVisitsDaySelected : classes.previousVisitIcon;
            return getCustomDateElement(day, buttonClass);
        }

        if (isToday(day)) {
            return getCustomDateElement(day, classes.today);
        }

        return dayComponent;
    }

    const handleDateChange = (date) => {
        selectedDate = date;
        onDayClick(date);
    };

    if (selectedPlannedVisitDateString) {
        // the date is in the en locale since it is coming from the api
        const dateFormat = 'DD/MM/YYYY';
        const dateMoment = moment(selectedPlannedVisitDateString, dateFormat);

        // convert to appropriate locale for the language
        dateMoment.locale(currentLocale);
        selectedDate = dateMoment;
    }

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={currentLocale}>
            <ThemeProvider theme={materialTheme}>
                <Calendar
                    date={selectedDate}
                    onChange={handleDateChange}
                    renderDay={renderDay}
                    leftArrowButtonProps={{ 'aria-label': t('aria_label_left_arrow_calendar') }}
                    rightArrowButtonProps={{ 'aria-label': t('aria_label_right_arrow_calendar') }}
                />
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default MICalendar;
