import { useEffect, useState, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import { getSiteKey, isEmpty } from '../../utils/utils';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';
import { useEntitlementsStore } from '../../contexts/StateProvider/StateProvider';


export const useEntitlements = () => {
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const entitlementsStore = useEntitlementsStore();
    const [ currentSiteKey, setCurrentSiteKey ] = useState(null);
    const [ entitlements, setEntitlements ] = useState(null);
    const endpoint = '/contractService/entitlements';

    useEffect(() => {
        if (responseStatus === SUCCESS && !isEmpty(currentSiteKey)) {
            try {
                if (!isEmpty(response.data)) {
                    const entitlementsData = response.data.entitlements;
                    entitlementsStore.setEntitlements(currentSiteKey, entitlementsData);
                    setEntitlements(entitlementsData);
                }
            } catch (error) {
                console.error('useEntitlements error: ', error);
                entitlementsStore.setEntitlements(currentSiteKey, null);
                setEntitlements(null);
            }
        }
    }, [ responseStatus, response, entitlementsStore, currentSiteKey ]);


    const getEntitlements = useCallback((countryCode, contractNumber, premiseNumber) => {
        setEntitlements(null);
        const siteKey = getSiteKey(contractNumber, premiseNumber);
        const storedEntitlements = entitlementsStore.entitlements[siteKey];
        if (isEmpty(storedEntitlements)) {
            const url = [ endpoint, countryCode, contractNumber, premiseNumber ].join('/');
            makeRegionalGCPRequestWithParams(url).catch((error) => {
                console.error('useEntitlements error: ', error);
            });
            setCurrentSiteKey(siteKey);
        } else {
            setEntitlements(storedEntitlements);
        }
    }, [ makeRegionalGCPRequestWithParams, entitlementsStore ]);

    return [ entitlements, entitlements !== null ? SUCCESS : responseStatus, getEntitlements ];
};
