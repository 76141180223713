import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FETCHING } from '../../../services/requests/requestReducer';
import MIGrid, { GetRowCount, STYLE_HEADER_CENTER } from '../../Widgets/MIGrid';
import { observer } from 'mobx-react-lite';
import { Typography } from '@material-ui/core';
import QuarterlyReturnsDocument from '../QuarterlyReturnsDocument';

const useStyles = makeStyles((theme) => {
    return {
        dataCell: {
            lineHeight: 0,
            width: '100%',
            whiteSpace: 'normal',
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        },
        locDate: {
            fontSize: theme.fontSizes.mobileBody,
            display: 'inline-flex',
            fontFamily: theme.fontFamily.proximaBold,
        },
        quarterlyReturnName: {
            fontSize: theme.fontSizes.mobileBody,
            overflowWrap: 'break-word',
        },
        gridContainer: {
            '& div.MuiDataGrid-cell': {
                padding: theme.sizes.no_gap,
                whiteSpace: 'normal',
            },
            'marginBottom': theme.sizes.xxlarge_gap,
            'borderTop': theme.borders.outline_bold,
        },
        rowCountContainer: {
            display: 'flex'
        },
        rowCountText: {
            marginLeft: 'auto',
            marginTop: 'auto',
        },
    };
});

const QuarterlyReturnsTableMobile = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        responseStatus,
        rows,
        showRowCount,
        mobilePageSize,
    } = props;

    const getDateAndNameCell = (params) => {
        return (
            <div className={classes.dataCell}>
                <Typography className={classes.locDate}>{params.row.locDate}</Typography>
                <Typography className={classes.quarterlyReturnName}>{params.row.title}</Typography>
            </div>
        );
    };

    const columns = [
        {
            field: 'date', headerName: t('date'), type: 'date', resizable: false, flex: 3,
            renderCell: (params) => {
                return getDateAndNameCell(params);
            },
        },
        {
            field: 'download', headerName: t('download_note'), resizable: false, filterable: false,
            width: 40,
            sortable: false,
            headerClassName: STYLE_HEADER_CENTER,
            renderCell: (params) => {
                return <QuarterlyReturnsDocument
                    filePath={params.row.filePath}
                />;
            }
        }
    ];

    return (
        <div>
            <div className={classes.rowCountContainer}>
                <div className={classes.rowCountText}>
                    {GetRowCount({ rows: rows, showRowCount: showRowCount })}
                </div>
            </div>
            <div className={classes.gridContainer}>
                <MIGrid
                    isMobile={true}
                    showInfoModal={true}
                    isLoading={responseStatus === FETCHING}
                    columns={columns}
                    rows={rows}
                    rowHeight={55}
                    hasFilters={false}
                    pagination={true}
                    autoPageSize={false}
                    pageSize={mobilePageSize ? mobilePageSize : rows.length}
                    headerHeight={0}
                    heightToFitContent={true}
                    hideFooterForOnePage={true}
                    hideFooter={false}
                    noEvenRowStyling={true}
                    noElementsText={t('no_quarterly_returns')}
                    ariaLabel={t('aria_label_quarterly_returns_table')}
                    ariaLabelProgressBar={t('aria_label_progressbar_quarterly_returns')}
                    waitFullyLoaded={true}
                    {...props}
                />
            </div>
        </div>
    );
});

export default QuarterlyReturnsTableMobile;
