import {
    DIRECT_LOGIN_STATUS, ERROR_STATUS,
    FAILED_STATUS,
    LOCK_STATUS,
    NO_SITE,
    PASSWORD_CHANGED,
    PASSWORD_EXPIRED, PASSWORD_UPDATE_SUCCESS, SUCCESS_STATUS
} from "../contexts/AuthProvider/AuthConst";
import {isEmpty} from "./utils";
import {toJS} from 'mobx';

export const isSuccessStatus = (availableRegions) => {
    const successRegionsKeys = getSuccessRegionsKeys(availableRegions);
    return !isEmpty(successRegionsKeys);
};

export const isRegionPasswordChanged = (availableRegions) => {
    const passwordChangedRegionsKeys = getRegionsKeysByStatus(availableRegions, PASSWORD_CHANGED);
    return !isEmpty(passwordChangedRegionsKeys);
};

export const isRegionExpired = (availableRegions) => {
    const expiredPasswordRegionsKeys = getRegionsKeysByStatus(availableRegions, PASSWORD_EXPIRED);
    const successRegionsKeys = getSuccessRegionsKeys(availableRegions);

    return !isEmpty(expiredPasswordRegionsKeys) && isEmpty(successRegionsKeys);
};

export const isRegionLocked = (availableRegions) => {
    const lockedRegionsKeys = getRegionsKeysByStatus(availableRegions, LOCK_STATUS);
    const successRegionsKeys = getSuccessRegionsKeys(availableRegions);

    return !isEmpty(lockedRegionsKeys) && isEmpty(successRegionsKeys);
};

export const isRegionPasswordUpdate = (availableRegions) => {
    // The assumption is the all regions have the same password,
    // so if you change your password in one region it will be replicated in all regions.
    const passwordUpdateRegionsKeys = getRegionsKeysByStatus(availableRegions, PASSWORD_UPDATE_SUCCESS);
    return !isEmpty(passwordUpdateRegionsKeys);
};

export const allRegionsFailed = (availableRegions) => {
    const failedChangedRegionsKeys = getRegionsKeysByStatus(availableRegions, FAILED_STATUS);
    return !isEmpty(failedChangedRegionsKeys) && failedChangedRegionsKeys.length === Object.keys(availableRegions).length;
};

export const allRegionsNoSite = (availableRegions) => {
    const noSiteRegionsKeys = getRegionsKeysByStatus(availableRegions, NO_SITE);
    return !isEmpty(noSiteRegionsKeys) && noSiteRegionsKeys.length === Object.keys(availableRegions).length;
};

export const countAvailableCountries = (availableRegions) => {
    let counter = 0;
    const successRegionsKeys = getSuccessRegionsKeys(availableRegions);
    successRegionsKeys.forEach((regionKey) => {
        counter = counter + availableRegions[regionKey].adminDetails.length;
    });
    return counter;
};

export const getSuccessRegionsKeys = (availableRegions) => {
    return getRegionsKeysByStatus(availableRegions, SUCCESS_STATUS);
};

export const getRegionsKeysByStatus = (availableRegions, status) => {
    return isEmpty(availableRegions) ? [] : Object.keys(availableRegions).filter(x => availableRegions[x].status === status);
};

export const handleMultiLoginLogic = (userDataRegions) => {
    if (isRegionPasswordChanged(userDataRegions)) {
        // we have to stop the login and show the change password UI if there is at least 1 region with PASSWORD_CHANGED.
        return PASSWORD_CHANGED
    }
    if (isRegionExpired(userDataRegions)) {
        // the multi region reset password in not available at the moment .
        // When it will be available we have to stop the login if there is at least 1 region with PASSWORD_EXPIRED (same logic of isRegionPasswordChanged).
        // In the meantime if the password in one region is expired but you we have regions with status success continue the login
        // else stop the login and ask for a reset password
        return PASSWORD_EXPIRED
    }
    if (isRegionLocked(userDataRegions)) {
        // see isRegionExpired above
        return LOCK_STATUS
    }
    if (allRegionsNoSite(userDataRegions)) {
        // stop the login only if all regions have status NO_SITE

        return NO_SITE
    }
    if (allRegionsFailed(userDataRegions)) {
        // The assumption is the all regions have the same password so is a failure status when in all regions the password is wrong.
        // the only scenario where in the same login there are regions with failure and success if when the user does not exist in the failure region
        return FAILED_STATUS
    }
    if (isSuccessStatus(userDataRegions)) {
        // is a isSuccessStatus is there is at least 1 region with status success

        if (countAvailableCountries(userDataRegions) === 1) {
            return DIRECT_LOGIN_STATUS
        }
        // else  if there are more than 1 countries we show the popup
        return SUCCESS_STATUS

    } else {
        if (!isEmpty(userDataRegions)) {
            console.error('scenario not handled :', toJS(userDataRegions));
        }
        return ERROR_STATUS
    }
};