import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles, Grid, Typography} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {useResponsive} from "../../services/useResponsive";
import {useAuthState} from "../../contexts/AuthProvider";
import {toJS} from "mobx";

const desktopStyles = (theme) => {
    return {
        countryTitle: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
            width: 'fit-content',
            '&:focus': theme.ariaFocus,
        },
        countryContainer: {
            paddingTop: theme.sizes.tiny_gap,
        },
    }
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        countryTitle: {
            ...baseStyle.countryTitle,
            fontSize: theme.fontSizes.mobileBody,
        },
    });
};


const MICountry = observer((props) => {
    const {t} = useTranslation();
    const {regionName, regionData, countryData, handleClick, changeLanguage} = props;
    const {countrySignIn} = useAuthState();
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    const onClick = () => {
        countrySignIn(toJS(regionData), countryData, regionName, changeLanguage);
        handleClick();
    };

    return (
        <Grid className={classes.countryContainer} item xs={4}>
            <Typography 
                className={classes.countryTitle} 
                onClick={onClick}
                aria-label={t(countryData.countryCode)}
                aria-live={'polite'}
                tabIndex={0}
            >
                {t(countryData.countryCode)}
            </Typography> 
        </Grid>
);
});

export default MICountry;
