import {useState, useEffect, useCallback} from 'react';
import {SUCCESS} from "../requests/requestReducer";
import {formatDate} from "../../utils/utils";
import useRegionalGCPApiRequest from "../requests/useRegionalGCPApiRequest";

export const usePlannedVisitDetailsGCP = () => {
    const [plannedVisitDetails, setPlannedVisitDetails] = useState([]);
    const [{responseStatus, response}, makeRegionalGCPRequestWithParams] = useRegionalGCPApiRequest();
    const endpoint = '/premiseService/plannedVisits/visitDetails';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setPlannedVisitDetails(response.data.visitPlan);
            } catch (error) {
                console.error('usePlannedVisitDetailsGCP error: ', error);
                setPlannedVisitDetails([]);
            }
        }
    }, [responseStatus, response, setPlannedVisitDetails]);

    const getPlannedVisitDetailsGCP = useCallback((countryCode, contractNumber, premiseNumber, plannedVisitDate) => {
        // reset the content otherwise it can show the content of another card
        setPlannedVisitDetails([]);
        const dateFormat = 'DD/MM/YYYY';
        const url = [endpoint, countryCode, contractNumber, premiseNumber, formatDate(plannedVisitDate, dateFormat, dateFormat)].join('/');
        makeRegionalGCPRequestWithParams(url).catch(error => {
            console.error('usePlannedVisitDetailsGCP error: ', error);
        });
    }, [makeRegionalGCPRequestWithParams, setPlannedVisitDetails]);

    return [plannedVisitDetails, responseStatus, getPlannedVisitDetailsGCP];
};
