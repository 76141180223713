import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import HandHygieneIconUrl, { ReactComponent as HandHygieneIcon } from '../../assets/floorplan/hand_hygiene_icon.svg';
import DisinfectionIconUrl, { ReactComponent as DisinfectionIcon } from '../../assets/floorplan/disinfection_icon.svg';
import WashroomHygieneIconUrl, { ReactComponent as WashroomHygieneIcon } from '../../assets/floorplan/washroom_hygiene_icon.svg';
import SanitaryBinIconUrl, { ReactComponent as SanitaryBinIcon } from '../../assets/floorplan/sanitary_bin.svg';
import IndoorAirHygieneIconUrl, { ReactComponent as IndoorAirHygieneIcon } from '../../assets/floorplan/indoor_air_hygiene_icon.svg';
import WCHygieneIconUrl, { ReactComponent as WCHygieneIcon } from '../../assets/floorplan/wc_hygiene_icon.svg';
import BathMatIconUrl, { ReactComponent as BathMatIcon } from '../../assets/floorplan/bath_mat.svg';
import NoIconUrl, { ReactComponent as NoIcon } from '../../assets/floorplan/no_icon.svg';

import { Icon } from '../../rentokil-react-ui';
import { isEmpty } from '../../utils/utils';
import { useDevicesMappingStore } from '../../contexts/StateProvider/StateProvider';

export const RENTOKIL_POSITION_ADJUSTMENT = 5;
const ICON_X_OFFSET = 16;
const ICON_Y_OFFSET = 14;
const X_CENTRE = ICON_X_OFFSET - RENTOKIL_POSITION_ADJUSTMENT;
const Y_CENTRE = ICON_Y_OFFSET - RENTOKIL_POSITION_ADJUSTMENT;

const DEFAULT_SIZE = { width: 32, height: 32 };

export const DEVICE_TYPE_UNKNOWN = 'unknown';
export const DEVICE_TYPE_HAND_HYGIENE = 'hand_hygiene';
export const DEVICE_TYPE_DISINFECTION = 'disinfection';
export const DEVICE_TYPE_WASHROOM_HYGIENE = 'washroom_hygiene';
export const DEVICE_TYPE_SANITARY_BIN = 'sanitary_bin';
export const DEVICE_TYPE_INDOOR_AIR_HYGIENE = 'indoor_air_hygiene';
export const DEVICE_TYPE_WC_HYGIENE = 'wc_hygiene';
export const DEVICE_TYPE_BATH_MAT = 'bath_mat';

export const useLocationIcon = () => {
    const { t } = useTranslation();
    const devicesMappingStore = useDevicesMappingStore();

    const getDetectorFromMapping = useCallback((detectorId) => {
        const devicesMapping = devicesMappingStore.getDevicesMapping() || {};
        return devicesMapping[detectorId];
    }, [ devicesMappingStore ]);

    const getIcon = useCallback((detectorId) => {
        let icon;
        let iconType = NoIcon;
        let iconUrl = NoIconUrl;
        let size = DEFAULT_SIZE;
        let offset = { x: X_CENTRE, y: Y_CENTRE };
        const detector = getDetectorFromMapping(detectorId);
        let detectorType = DEVICE_TYPE_UNKNOWN;
        if (!isEmpty(detector)) {
            detectorType = detector.type;
            switch (detectorType) {
            case DEVICE_TYPE_HAND_HYGIENE:
                iconType = HandHygieneIcon;
                iconUrl = HandHygieneIconUrl;
                break;
            case DEVICE_TYPE_DISINFECTION:
                iconType = DisinfectionIcon;
                iconUrl = DisinfectionIconUrl;
                break;
            case DEVICE_TYPE_WASHROOM_HYGIENE:
                iconType = WashroomHygieneIcon;
                iconUrl = WashroomHygieneIconUrl;
                break;
            case DEVICE_TYPE_SANITARY_BIN:
                iconType = SanitaryBinIcon;
                iconUrl = SanitaryBinIconUrl;
                break;
            case DEVICE_TYPE_INDOOR_AIR_HYGIENE:
                iconType = IndoorAirHygieneIcon;
                iconUrl = IndoorAirHygieneIconUrl;
                break;
            case DEVICE_TYPE_WC_HYGIENE:
                iconType = WCHygieneIcon;
                iconUrl = WCHygieneIconUrl;
                break;
            case DEVICE_TYPE_BATH_MAT:
                iconType = BathMatIcon;
                iconUrl = BathMatIconUrl;
                break;
            default:
                break;
            }
        }
        icon = <Icon icon={iconType}
            iconUrl={iconUrl}
            size={size}/>;

        return [ icon, offset, detectorType ];
    }, [ getDetectorFromMapping ]);

    const getLegendIconGroups = useCallback(() => {
        return [
            [
                {
                    icon: HandHygieneIcon,
                    title: t('hand_hygiene'),
                    size: DEFAULT_SIZE
                },
                {
                    icon: DisinfectionIcon,
                    title: t('disinfection'),
                    size: DEFAULT_SIZE },
                {
                    icon: WashroomHygieneIcon,
                    title: t('washroom_hygiene'),
                    size: DEFAULT_SIZE
                },
                {
                    icon: SanitaryBinIcon,
                    title: t('sanitary_bin'),
                    size: DEFAULT_SIZE,
                },
                {
                    icon: IndoorAirHygieneIcon,
                    title: t('indoor_air_hygiene'),
                    size: DEFAULT_SIZE
                },
                 {
                    icon: WCHygieneIcon,
                    title: t('wc_hygiene'),
                    size: DEFAULT_SIZE,
                },
                {
                    icon: BathMatIcon,
                    title: t('bath_mat'),
                    size: DEFAULT_SIZE
                },
            ]
        ];
    }, [ t ]);

    return { getIcon: getIcon, getLegendIconGroups: getLegendIconGroups, getDetectorFromMapping: getDetectorFromMapping };
};
