export const DAY = {
    1: 'sun',
    2: 'mon',
    3: 'tue',
    4: 'wed',
    5: 'thu',
    6: 'fri',
    7: 'sat'
};

export const MONTH = {
    1: 'jan',
    2: 'feb',
    3: 'mar',
    4: 'apr',
    5: 'may',
    6: 'jun',
    7: 'jul',
    8: 'aug',
    9: 'sep',
    10: 'oct',
    11: 'nov',
    12: 'dec',
};

export const TIME_PERIOD = {
    BY_MONTH: {
        name: 'month',
        key: 'average_by_month',
        apiEndpoint: 'monthlyAverage',
        apiReturn: 'monthlyInteractions',
        options: MONTH
    },
    BY_DAY: {
        name: 'day',
        key: 'average_by_day',
        apiEndpoint: 'dailyAverage',
        apiReturn: 'dailyInteractions',
        options: DAY
    }
};

export const INTERACTION_TYPES = {
    BLU: {
        NAME: 'blueActivations',
        CHECKED: true
    },
    SANITISER: {
        NAME: 'sanitiserActivations',
        CHECKED: true
    },
    FOAM: {
        NAME: 'soapActivations',
        CHECKED: true
    },
    WATER: {
        NAME: 'tapWaterActivations',
        CHECKED: true
    }
};

export const INTERACTION_NAMES = {
    BLU: 'BLU',
    SANITISER: 'SANITISER',
    FOAM: 'FOAM',
    WATER: 'WATER',
};

export const NO_INTERACTION_TYPE = {
    NO_DATA: 'No data',
};

export const INTERACTION_COLORS = {
    BLU: '#147FC3',
    SANITISER: '#639C43',
    FOAM: '#D37331',
    WATER: '#30C2E4',
    NULL: '#fff'
};

export const REFILL_TYPES = {
    BLUE_EMPTY: 'blueReservoirsEmpty',
    BLUE_LOW: 'blueReservoirsLow',
    BLUE_NOT_LOW: 'blueReservoirsNotLow',
    SOAP_EMPTY: 'soapReservoirsEmpty',
    SOAP_LOW: 'soapReservoirsLow',
    SOAP_NOT_LOW: 'soapReservoirsNotLow',
    SANITISER_EMPTY: 'sanitiserReservoirsEmpty',
    SANITISER_LOW: 'sanitiserReservoirsLow',
    SANITISER_NOT_LOW: 'sanitiserReservoirsNotLow',

};

export const REFILL_SOONEST = {
    ANY_FOAM: 'refillSoonestAnyFoam',
    BLU: 'refillSoonestBlue',
};
