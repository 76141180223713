import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import {useTranslation} from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import {useResponsive} from "../../services/useResponsive";

const desktopStyles = theme => {
    return {
        root: props => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: theme.palette.background.paper,
            padding: 10,
            width: props.rootWidth? props.rootWidth : '704px',
            height: props.rootHeight? props.rootHeight : '600px',
            paddingTop: theme.sizes.xlarge_gap,
            paddingBottom: theme.sizes.xlarge_gap,
            paddingLeft: theme.sizes.large_gap,
            paddingRight: theme.sizes.large_gap,
            fontFamily: theme.typography.fontFamily,
            border:'2px solid',
            borderColor:  theme.palette.primary.light,
            '&:focus': theme.ariaFocus,
           }),
    
        icon: {
            fill: theme.palette.primary.main,
            cursor: 'pointer',
            '&:focus': theme.ariaFocus,
        },
        titleContainer: props => ({
            paddingBottom: theme.sizes.large_gap,
            textAlign: props.centerTitle? 'center': 'left',
            paddingRight: props.centerTitle? theme.sizes.xmlarge_gap : '',
            paddingLeft: props.centerTitle? theme.sizes.xmlarge_gap : '',
        }),
        iconContainer: {
            width: '30px',
            height: '30px',
            display: 'inline-flex',
            position: 'absolute',
            right: theme.sizes.large_gap,
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        titleContainer: props => ({
            ...baseStyle.titleContainer,
            paddingRight: theme.sizes.small_gap,
            paddingLeft: props.mobilePaddingLeft? props.mobilePaddingLeft: theme.sizes.small_gap,
        }),
        iconContainer: {
            ...baseStyle.iconContainer,
            right: theme.sizes.tiny_gap,
        },
    });
};


const MIModal = (props) => {
    const {isShowing, customStyle, onClose, body, header, hideCloseIcon} = props;
    const {t, i18n} = useTranslation();
    const float = (i18n.dir() === 'ltr') ? 'right' : 'left';
    const style = Object.assign({}, customStyle, {float:float});

    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles(style);

    if (isShowing) {
        return (
            <Modal open={isShowing} onClose={onClose}>
                <Container dir={i18n.dir()} className={classes.root}>
                    <div className={classes.titleContainer}>
                        {header}
                        {!hideCloseIcon &&
                            <div className={classes.iconContainer}>
                                <CloseIcon
                                    data-testid={'close_modal_icon'}
                                    aria-label={t('aria_label_close_modal_button')}
                                    className={classes.icon}
                                    onClick={onClose}
                                    data-dismiss='modal'
                                    aria-live={'polite'}
                                    tabIndex={0}
                                />
                            </div>
                        }
                    </div>
                    {body}
                </Container>
            </Modal>
        )
    } else {
        return null
    }
};

export default MIModal;