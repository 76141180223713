import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FETCHING } from '../../../services/requests/requestReducer';
import MIGrid, { GetRowCount } from '../../Widgets/MIGrid';
import { observer } from 'mobx-react-lite';
import DownloadWasteNote from '../../VisitHistory/DownloadWasteNote';
import Link from '@material-ui/core/Link';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useFlags } from '../../../contexts/FlagsProvider/FlagsProvider';

const useStyles = makeStyles((theme) => {
    return {
        dataCell: {
            lineHeight: 0,
            width: '100%',
            whiteSpace: 'normal',
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        },
        linkContainer: {
            display: 'inline-flex',
            float: 'right',
        },
        locDate: {
            fontSize: theme.fontSizes.mobileBody,
            display: 'inline-flex',
            fontFamily: theme.fontFamily.proximaBold,
        },
        consignmentNoteCode: {
            fontSize: theme.fontSizes.mobileBody,
            overflowWrap: 'break-word',
        },
        gridContainer: {
            '& div.MuiDataGrid-cell': {
                padding: theme.sizes.no_gap,
                whiteSpace: 'normal',
            },
            'marginBottom': theme.sizes.xxlarge_gap,
            'borderTop': theme.borders.outline_bold,
        },
        rowCountContainer: {
            display: 'flex'
        },
        rowCountText: {
            marginLeft: 'auto',
            marginTop: 'auto',
        },
    };
});

const WasteConsignmentTableMobile = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        setAlert,
        setIsShowing,
        responseStatus,
        rows,
        wasteConsignment,
        showRowCount,
        mobilePageSize,
        yearPicker
    } = props;

    const { feature_hazardous_waste_portugal } = useFlags();

    const getPremiseAndAddressCell = (params) => {
        return (
            <div className={classes.dataCell}>
                <Typography className={classes.locDate}>{params.row.locDate}</Typography>
                <Typography className={classes.consignmentNoteCode}>{params.row.consignmentNoteCode}</Typography>
            </div>
        );
    };

    const columns = [
        {
            field: 'consignmentNoteCode',
            type: 'string',
            resizable: false,
            flex: 3,
            renderCell: (params) => {
                return getPremiseAndAddressCell(params);
            },
        },
        {
            field: 'download', headerName: t('download_note'), resizable: false, filterable: false, width: 40,
            sortable: false,
            renderCell: (params) => {
                return <div className={classes.linkContainer}>
                    {feature_hazardous_waste_portugal ?
                        <Link href={params.row.url} target={'_blank'} rel={'noopener noreferrer'}>
                            <GetAppIcon className={classes.icon}/>
                        </Link> :
                        <DownloadWasteNote
                            consignmentNoteCode={params.row.consignmentNoteCode}
                            visitDate={params.row.locDate}
                            setAlert={setAlert}
                            setIsShowing={setIsShowing}
                        />
                    }
                </div>;
            }
            ,
        }
    ];

    return (
        <div>
            <div className={classes.rowCountContainer}>
                {feature_hazardous_waste_portugal && yearPicker}
                <div className={classes.rowCountText}>
                    {GetRowCount({ rows: wasteConsignment, showRowCount: showRowCount})}
                </div>
            </div>
            <div className={classes.gridContainer}>
                <MIGrid
                    isMobile={true}
                    showInfoModal={true}
                    isLoading={responseStatus === FETCHING}
                    columns={columns}
                    rows={rows}
                    rowHeight={55}
                    hasFilters={false}
                    pagination={true}
                    autoPageSize={false}
                    pageSize={mobilePageSize ? mobilePageSize : wasteConsignment.length}
                    headerHeight={0}
                    heightToFitContent={true}
                    hideFooterForOnePage={true}
                    hideFooter={false}
                    noEvenRowStyling={true}
                    noElementsText={t('no_waste_notes')}
                    ariaLabel={t('aria_label_visit_history_table')}
                    ariaLabelProgressBar={t('aria_label_progressbar_waste_notes')}
                    waitFullyLoaded={true}
                    {...props}
                />
            </div>
        </div>
    );
});

export default WasteConsignmentTableMobile;
