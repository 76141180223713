import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import pdfLogo from '../../assets/pdf_logo.png';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useTheme } from '@material-ui/styles';
import { MuiThemeProvider } from '@material-ui/core';
import Benefits from '../../pages/Service/SmartHygiene/Benefits';
import { SUCCESS } from '../requests/requestReducer';
import RefillEstimates from '../../pages/Service/SmartHygiene/RefillEstimates';
import InteractionChart from '../../pages/Service/SmartHygiene/InteractionChart';
import InteractionTitle from '../../pages/Service/SmartHygiene/InteractionTitle';

export const PDF_WIDTH = 732;

const renderBenefitsLevels = (theme, data) => {
    return new Promise((resolve, reject) => {
        const containerDiv = document.createElement('div');
        containerDiv.setAttribute('id', 'benefits');
        ReactDOM.render(
            <MuiThemeProvider theme={theme}>
                <Benefits
                    customStyles={{
                        cardWidth: 180,
                        cardSpacing: theme.sizes.pico_gap,
                        cardFlexDirection: 'column'
                    }}
                    usageData={data.benefitsData}
                    apiStatus={SUCCESS}
                    secondaryTitle={data.benefitsPeriod}
                />
                <RefillEstimates refillEstimates={data.refillValues} asPDF={true}/>
            </MuiThemeProvider>,
            containerDiv,
            () => {
                document.body.appendChild(containerDiv);
                resolve(containerDiv);
            });
    });
};

const renderInteractions = (theme, data) => {
    return new Promise((resolve, reject) => {
        const containerDiv = document.createElement('div');
        containerDiv.setAttribute('id', 'interactions');
        ReactDOM.render(
            <MuiThemeProvider theme={theme}>
                <InteractionTitle
                    interactionTypes={data.interactionTypes}
                    timePeriod={data.interactionsPeriod}
                    asPDF={true}/>
                <div style={{ width: PDF_WIDTH, border: theme.borders.chartBorder }}>
                    <InteractionChart data={data.patternData} width={ '100%' } />
                </div>
            </MuiThemeProvider>,
            containerDiv,
            () => {
                document.body.appendChild(containerDiv);
                resolve(containerDiv);
            });
    });
};

export const useSmartHygienePDF = () => {
    let pdf = null;
    let pdfData = null;
    const { t } = useTranslation();
    const theme = useTheme();

    const header = () => {
        const logoImage = new Image();
        logoImage.src = pdfLogo;
        pdf.addImage(logoImage, 'PNG', 18, 20);
        pdf.text(200, 25, t('pdf_generated_on'));
        pdf.text(200, 40, t('pdf_site_name'));
        pdf.text(300, 25, pdfData.generatedAt);
        const splitSiteName = pdf.splitTextToSize(pdfData.siteName, 130);
        pdf.text(300, 40, splitSiteName);
    };

    const addSection = async(renderFunc) => {
        try {
            let top = 70;
            const containerDiv = await renderFunc(theme, pdfData);
            header();
            await new Promise((renderWait) => {
                return setTimeout(renderWait, 1000);
            });
            const containerData = await domtoimage.toPng(containerDiv);
            pdf.addImage(containerData, 'PNG', 18, top);
            containerDiv.remove();
        } catch (error) {
            console.error('PDF export addSection error:', error);
        }
    };

    const addPageTotal = () => {
        for (let page = 1; page <= pdf.page; page++) {
            pdf.setPage(page);
            pdf.text(20, 625, `${ page } / ${ pdf.page.toString()}`);
        }
    };

    const exportSmartHygiene = async(data) => {
        pdf = new jsPDF('p', 'px', 'a4');
        pdfData = data;
        pdf.setFont('helvetica');
        pdf.setFontSize(10);

        pdf.page = 1;
        await addSection(renderBenefitsLevels);
        pdf.addPage();
        pdf.page++;
        await addSection(renderInteractions);
        addPageTotal();
        pdf.save(`SmartHygiene-${data.generatedAt}-${data.site}.pdf`);
    };

    return { exportSmartHygiene: exportSmartHygiene };
};
