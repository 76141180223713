import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import {FETCHING} from "../../services/requests/requestReducer";
import LinearProgress from "@material-ui/core/LinearProgress";
import {isEmpty} from '../../utils/utils';
import {Typography} from '@material-ui/core';
import { useResponsive } from "../../services/useResponsive";

const desktopStyles = theme => {
    return {
        infoBlock: {
            display: 'inline-block',
            width: '100%',
            minHeight: '100px',
            marginTop: props => props.marginTop ? props.marginTop : theme.sizes.large_gap,
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.small_gap,
            backgroundColor: props => props.backgroundColor ? props.backgroundColor : 'unset',
            '&:focus': theme.ariaFocus,
        },
        infoLabel: {
            fontWeight: theme.typography.fontWeightBold,
            '&:focus': theme.ariaFocus,
        },
        infoRow: {
            marginLeft: theme.sizes.no_gap,
            '&:focus': theme.ariaFocus,
            width: 'inherit',
        },
        infoValue: {
            marginLeft: theme.sizes.no_gap,
        },
        gridItem: {
            "&.MuiGrid-item": {
                padding: theme.sizes.tiny_gap,
            },
            '&:focus': theme.ariaFocus,
        },
    }
}

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        infoBlock: {
            display: 'inline-block',
            width: '100%',
            minHeight: theme.sizes.medium_gap,
            marginTop: props => props.marginTop ? props.marginTop : theme.sizes.small_gap,
            backgroundColor: 'unset',
            '&:focus': theme.ariaFocus,
        },
        infoRow: {
            margin: [theme.sizes.tiny_gap, theme.sizes.no_gap].join(' '),
            '&:focus': theme.ariaFocus,
            width: 'inherit',
        },
        infoLabel: {
            fontFamily: theme.fontFamily.proximaBold,
            fontSize: theme.fontSizes.mobileTitle,
        },
        gridItem: {
            fontFamily: theme.fontFamily.proximaMedium,
            fontSize: theme.fontSizes.mobileBody,
            overflowWrap: 'break-word',
            whiteSpace: 'normal'
        },
    });
}

const MIInfoBlock = (props) => {
    const {optionalCondition, info, infoStatus, hasColumns} = props;
    const {getStyles} = useResponsive();
    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles(props);

    const infoRowDesktop = (label, text) => {
        return (
            <Grid container className={classes.infoRow} spacing={3} alignItems="center" key={label}>
                <Grid item xs={2} tabIndex={0}>
                    <Typography className={classes.infoLabel}> {label} </Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridItem} tabIndex={0}>
                    <Typography> {text} </Typography>
                </Grid>
            </Grid>
        )
    };

    const infoRowMobile = (label, text) => {
        return (
            <Grid 
                container 
                className={classes.infoRow}   
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                key={label}
            >
                <Grid item tabIndex={0}>
                    <Typography className={classes.infoLabel}> {label} </Typography>
                </Grid>
                <Grid item tabIndex={0}>
                    <Typography className={classes.gridItem}> {text} </Typography>
                </Grid>
            </Grid>
        )
    };

    const getGridItem = (xs, key, value, className) => {
        return (
            <Grid item xs={xs} key={key} className={classes.gridItem}>
                    <Typography className={className}> {value === 'spaceHolder' ? '' : value} </Typography>
            </Grid>
        );
    };

    const infoRows = () => {
        let rows = [];
        info.forEach(row => {
            //column width should be (2,4) in order for infoLabel and infoValue to be inside the container
            rows.push(getGridItem(2, row.label, row.label, classes.infoLabel));
            rows.push(getGridItem(4, row.value + row.label, row.value, classes.infoValue));
        });
        return rows;
    };

    const columnInfo = () => {
        return (
            <Grid container className={classes.infoRow} spacing={3} alignItems="center" key="columnInfoGrid">
                {infoRows()}
            </Grid>
        );
    };

    const infoBlock = () => {
        if (isEmpty(infoStatus) || infoStatus === FETCHING || optionalCondition) {
            return (<LinearProgress/>)
        } else {
            let displayInfo = [];
            if(!isEmpty(hasColumns) && hasColumns){
                displayInfo = columnInfo();
            }else{
                info.forEach(row => {
                    displayInfo.push(
                        getStyles(
                            {
                                desktop: infoRowDesktop(row.label, row.value),
                                mobile: infoRowMobile(row.label, row.value)
                            }
                        )
                    )
                }); 
            }
            return displayInfo;
        }
    };

    return (
        <div className={classes.infoBlock} tabIndex={0}>
            {infoBlock()}
        </div>
    );
};

export default MIInfoBlock;
