import { useState, useEffect, useCallback } from 'react';
import useFloorPlanApiRequest from '../requests/useFloorPlanApiRequest';
import { SUCCESS } from '../requests/requestReducer';

export const useGetBuildings = () => {
    const [ buildings, setBuildings ] = useState(null);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getBuildings';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setBuildings(response.data.results);
            } catch (error) {
                console.error('useGetBuildings error: ', error);
                setBuildings(null);
            }
        }
    }, [ responseStatus, response, setBuildings ]);

    const getBuildings = useCallback((siteId) => {
        const url = [ endpoint, siteId ].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetBuildings makeRequest: ', error);
        });
    }, [ makeFloorPlanApiRequestWithParams ]);

    return [ buildings, responseStatus, getBuildings ];
};
