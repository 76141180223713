import { makeAutoObservable, runInAction } from "mobx";

class ContractStore {
    contracts = [];
    contractDetail = {};
    siteContractDetail = {};
    extraContractDetail = {};

    constructor() {
        makeAutoObservable(this);
    }

    setContracts(contractList) {
        this.contracts = contractList;
    }

    getSiteContractDetail(siteId) {
        return this.siteContractDetail[siteId];
    }

    setSiteContractDetail(siteId, contractDetail) {
        this.siteContractDetail[siteId] = contractDetail;
    }

    getContractDetail(contractId) {
        return this.contractDetail[contractId];
    }

    setContractDetail(contractId, contractDetail) {
        this.contractDetail[contractId] = contractDetail;
    }

    getExtraContractDetails(contractId) {
        return this.extraContractDetail[contractId];
    }

    setExtraContractDetails(contractId, extraContractDetail) {
        this.extraContractDetail[contractId] = extraContractDetail;
    }

    reset() {
        const self = this;
        runInAction(() => {
            self.contracts = [];
            self.contractDetail = {};
            self.extraContractDetail = {};
            self.siteContractDetail = {};
        });
    }
}

export {ContractStore};