import { useCallback, useEffect } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';
import { isEmpty } from '../utils/utils';
import { useAuthState } from '../contexts/AuthProvider';
import { useSiteStore } from '../contexts/StateProvider/StateProvider';

export const useUserPermissions = () => {
    const { countryCode } = useAuthState();
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/configService/userPermissions';
    const siteStore = useSiteStore();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                siteStore.setPremiseTypes(response.data.userPermissionsDetails);
                siteStore.setAccountNumbers(response.data.userPermissionsDetails);
            } catch (error) {
                console.error('useContractList error: ', error);
            }
        }
    }, [ responseStatus, response, siteStore ]);

    const getUserPermissions = useCallback(() => {
        if (!isEmpty(countryCode) && isEmpty(siteStore.premiseTypes)) {
            const url = [ endpoint, countryCode, 'I', 'false' ].join('/');
            makeRequestWithParams(url).catch((error) => {
                console.error('getUserPermissions makeRequestWithParams: ', error);
            });
        }
    }, [ makeRequestWithParams, siteStore.premiseTypes, countryCode ]);

    return [ responseStatus, getUserPermissions ];
};
