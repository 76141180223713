import {useState, useEffect, useCallback} from 'react';
import useRawRequest from "./requests/useRawRequest";
import {SUCCESS, ERROR} from "./requests/requestReducer";
import {isEmpty} from "../utils/utils";

export const useIpCountry = () => {
    const konyCountryMapping = {
        'GB': 'UK'
    };

    const [{responseStatus, response}, makeRequestWithParams] = useRawRequest();
    const [country, setCountry] = useState('');


    const getCountryFromIp = useCallback(() => {
        setCountry('');
        const key = process.env.REACT_APP_GEO_IP_KEY;
        const url = 'https://pro.ip-api.com/json/?fields=status,message,countryCode&key='+key;
        makeRequestWithParams(url).catch(error => {
            console.error('useIpCountry error: ', error);
        });
    }, [makeRequestWithParams]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            const countryCode = !isEmpty(response.data) ? response.data.countryCode : '';
            const konyCountryCode = konyCountryMapping[countryCode];
            setCountry(!isEmpty(konyCountryCode) ? konyCountryCode : countryCode);
        }else if (responseStatus === ERROR) {
            console.error("API not able to retrieve user country")
        }
    }, [responseStatus, response, setCountry, konyCountryMapping]);


    return [country, responseStatus, getCountryFromIp];
};