import React from 'react';
import MIDownload from '../Widgets/MIDownload';
import { useAuthState } from '../../contexts/AuthProvider';
import { formatDate } from '../../utils/utils.js';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { observer } from 'mobx-react-lite';

const PrintReport = observer((props) => {
    const { countryCode, locale } = useAuthState();
    const siteStore = useSiteStore();
    const { premiseVisitNumber, visitDate, title, setAlert, setIsShowing, customStyle, isIcon } = props;
    const pdfUrl = [
        '/premiseService/getServiceReceipt',
        siteStore.selectedSiteId,
        premiseVisitNumber,
        formatDate(visitDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
        countryCode,
        locale
    ].join('/');

    return (
        <MIDownload
            setAlert={setAlert}
            setIsShowing={setIsShowing}
            title={title}
            customStyle={customStyle}
            url={pdfUrl}
            isIcon={isIcon}
        />
    );
});

export default PrintReport;
