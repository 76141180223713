import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';

export const useVisitDetails = () => {
    const [ visitDetails, setVisitDetails ] = useState([]);
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/premiseService/premiseVisitDetails';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setVisitDetails(response.data);
            } catch (error) {
                console.error('useVisitDetails error: ', error);
                setVisitDetails([]);
            }
        }
    }, [ responseStatus, response, setVisitDetails ]);

    const getVisitDetails = useCallback((countryCode, contractNumber, premiseNumber, premiseVisitNumber) => {
        setVisitDetails([]);
        const url = [ endpoint, countryCode, contractNumber, premiseNumber, premiseVisitNumber].join('/');
        makeRegionalGCPRequestWithParams(url).catch((error) => {
            console.error('useVisitDetails error: ', error);
        });
    }, [ makeRegionalGCPRequestWithParams ]);

    return [ visitDetails, responseStatus, getVisitDetails ];
};
