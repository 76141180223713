import { makeAutoObservable, runInAction } from "mobx";
import {isEmpty} from "../../utils/utils";

const LOCAL_STORAGE_KEY_ID = 'selectedSiteId';
const LOCAL_STORAGE_KEY_SELECTED = 'selectedSite';

class SiteStore {
    selectedSiteId;
    hasSelectedSite = false;
    selectedSite;
    sites = [];
    siteAddresses = [];
    loadComplete = false;
    siteInfo = {};
    premiseTypes = [];
    accountNumbers = [];
    myRentokilSiteId = '';
    selectedContractNumber = null;
    selectedPremiseNumber = null;

    constructor() {
        this.selectedSiteId = localStorage.getItem(LOCAL_STORAGE_KEY_ID);
        const selectedStr = localStorage.getItem(LOCAL_STORAGE_KEY_SELECTED);
        this.selectedSite = JSON.parse(selectedStr);
        makeAutoObservable(this);
    }

    setSelectedSiteId(siteId) {
        this.selectedSiteId = siteId;
        localStorage.setItem(LOCAL_STORAGE_KEY_ID, siteId.toString());
        this.setSelection();
    }

    setSelectedmyRentokilSiteId(myRentokilSiteId) {
        this.myRentokilSiteId = myRentokilSiteId;
    }

    getSelectedmyRentokilSiteId() {
        return this.myRentokilSiteId;
    }

    setSelectedSite(selection) {
        const newPropertiesCount = Object.keys(selection).length;
        const savedPropertiesCount = !isEmpty(this.selectedSite) ? Object.keys(this.selectedSite).length : 0;
        if (this.selectedSiteId !== selection.siteId || newPropertiesCount >= savedPropertiesCount) {
            this.selectedSiteId = selection.siteId;
            this.selectedSite = selection;
            this.selectedContractNumber = selection.contractId;
            this.selectedPremiseNumber = selection.premiseNo;
            localStorage.setItem(LOCAL_STORAGE_KEY_SELECTED, JSON.stringify(selection));
            this.hasSelectedSite = !isEmpty(selection);
            this.myRentokilSiteId = '';
        }
    }

    setSites(siteList) {
        this.sites = siteList;
        this.setSiteAddresses(siteList);
        this.setSelection();
    }

    setLoadComplete(complete) {
        this.loadComplete = complete;
    }

    getSiteKey(contractNumber, premiseNumber) {
        return `${contractNumber}_${premiseNumber}`;
    }

    getSiteInfo(uniqueKey) {
        return this.siteInfo[uniqueKey];
    }

    setSiteInfo(uniqueKey, siteInfo) {
        this.siteInfo[uniqueKey] = siteInfo;
    }

    setPremiseTypes(userPremises) {
        if (isEmpty(userPremises)) {
            this.premiseTypes = [];
        } else {
            let types = []
            for (const premise of userPremises) {
                const type = isEmpty(premise.premiseType) ? '' : premise.premiseType.trim().toUpperCase();
                if (!isEmpty(type) && !this.premiseTypes.includes(type)) {
                    types.push(type);
                }
            }
            this.premiseTypes = [...types];
        }
    }

    setAccountNumbers(userPremises) {
        // userPremises contains many premises, each with an account number. In order to be able to find the account number for the selected contract,
        // store the account number against the contract number as well
        let numbers = [];
        if (isEmpty(userPremises)) {
            this.accountNumbers = [];
        } else {
            for (const premise of userPremises) {
                if (!(isEmpty(premise.accountNumber) || isEmpty(premise.contractNumber))) {
                    numbers.push({accountNumber: premise.accountNumber, contractNumber: premise.contractNumber});
                }
            }
            this.accountNumbers = [...numbers];
        }
    }

    setSiteAddresses(siteList) {
        this.siteAddresses = [];
        siteList.forEach(site => {
            this.siteAddresses.push({  
                premise_number: site.premiseNo,
                address: site.siteAddress,
                contract: site.contractId
            })
        })
    }

    getAccountNumberWithContractNumber(contractNumber) {
        let accountNumber = undefined;
        for (let item of this.accountNumbers) {
            if (item.contractNumber === contractNumber) {
                accountNumber = item.accountNumber;
                break;
            }
        }
        return accountNumber;
    }

    reset() {
        runInAction(() => {
            this.sites = [];
            this.setSiteAddresses([]);
            this.setSelectedSiteId('');
            this.setSelectedmyRentokilSiteId('');
            this.setSelectedSite('');
            this.siteInfo = {};
            this.setPremiseTypes([]);
            this.setAccountNumbers([]);
            this.hasSelectedSite = false;
            this.setLoadComplete(false);
            this.selectedContractNumber = null;
            this.selectedPremiseNumber = null;
        });
    }

    setToFirstItem() {
        if (!isEmpty(this.sites)) {
            this.setSelectedSite(this.sites[0]);
            localStorage.setItem(LOCAL_STORAGE_KEY_ID, this.selectedSiteId.toString());
        }
    }

    //Remove this in ACD-8522
    getSelectedContractNumber() {
        return !isEmpty(this.selectedSite)? this.selectedSite.contractId: null;
    }

    //Remove this in ACD-8522
    getSelectedPremiseNumber() {
        return !isEmpty(this.selectedSite)? this.selectedSite.premiseNo: null;
    }

    setSelection() {
        let selection;
        for (let i = 0; i < this.sites.length; i++) {
            if (this.sites[i]?.siteId === this.selectedSiteId) {
                selection = this.sites[i];
                break;
            }
        }
        if (selection) {
            this.setSelectedSite(selection);
        } else {
            this.setToFirstItem();
        }
    }
}

export {
    SiteStore
};
