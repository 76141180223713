import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useResponsive } from '../../services/useResponsive';
import { observer } from 'mobx-react-lite';
import MIDownload from '../Widgets/MIDownload';
import { useTranslation } from 'react-i18next';

const desktopStyles = (theme) => {
    return {
        itemContainer: {
            textAlign: 'center',
            paddingTop: theme.sizes.micro_gap,
            width: '100%',
        },
        downloadIcon: {
            'cursor': 'pointer',
            '&:focus': theme.ariaFocus,
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);
    return Object.assign(baseStyle, {
        itemContainer: {
            paddingTop: theme.sizes.micro_gap,
            minWidth: 'unset !important',
        },
    });
};

const QuarterlyReturnsDocument = observer((props) => {
    const { filePath } = props;
    const { t } = useTranslation();
    const url = '/hazardWasteService/quarterlyReturnsDocuments/pdf';
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    });

    const classes = useStyles();

    return (
        <MIDownload
            setAlert={null}
            setIsShowing={null}
            url={url}
            isIcon={false}
            requestMethod={'post'}
            requestParams={{ filePath : filePath }}
            button={<GetAppIcon className={classes.downloadIcon} aria-label={t('aria_label_quarterly_returns_download_icon')}/>}
            className={classes.itemContainer}
        />
    );
});


export default QuarterlyReturnsDocument;
