import React, {useCallback, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {useTranslation} from "react-i18next";
import {isEmpty} from "../../utils/utils";
import {useStateWithLocalStorage} from "../../utils/localStorage";
import {useSiteList} from "../../services/useSiteList";
import {observer} from "mobx-react-lite";
import {useSiteStore} from "../../contexts/StateProvider/StateProvider";
import {useResponsive} from "../../services/useResponsive";

const MAX_RECENT_SITES = 4;

const desktopStyles = theme => {
    return {
        miAutocomplete: {
            '& fieldset': {
                borderRadius: '0px',
                border: '1px solid ' + theme.palette.grey['500'] + ' !important',
            },
            '& div.MuiOutlinedInput-root': {
                paddingLeft: theme.sizes.small_gap,
                paddingRight: theme.sizes.small_gap + ' !important',
            },
            '& div.MuiOutlinedInput-root:hover': {
                boxShadow: 'rgba(205,210,213,1.0) 0px 2px 5px 1px',
            },
            '& div.MuiOutlinedInput-root.Mui-focused': {
                borderWidth: '1px',
                backgroundColor: '#fff',
                boxShadow: 'rgba(205,210,213,1.0) 0px 2px 5px 1px',
                '& fieldset': {
                    borderWidth: '1px',
                },
            },
        },
        paper: {
            margin: '-5px 0px',
            boxShadow: 'rgba(205,210,213,1.0) 0px 6px 5px 1px',
        },
        listbox: {
            padding: '0px',
            borderLeft: '1px solid ' + theme.palette.grey['500'],
            borderRight: '1px solid ' + theme.palette.grey['500'],
            borderBottom: '1px solid ' + theme.palette.grey['500'],
            '& div.MuiAutocomplete-groupLabel': {
                fontWeight: theme.typography.fontWeightBold,
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.text.primary,
            }
        },
        option: {
            height: '88px',
            color: theme.palette.primary.main,
            fontWeight: theme.typography.fontWeightBold,
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        option: {
            ...baseStyle.option,
            minHeight: '56px',
            borderTop: '1px solid' + theme.palette.grey['300'],
        },
        paper: {
            ...baseStyle.paper,
            margin: '0px 0px',
        },
        listbox: {
            ...baseStyle.listbox,
            maxHeight: 'unset',
            width: '100vw',
        },
    });
};


const filter = createFilterOptions({
    trim: 'true',
});

function sortRecentCompare(a, b) {
    // This function compares two site entries (a and b) for sorting based on the boolean 'recent' flag
    // an entry is 'smaller' its recent flag is set the true while the other entry's is false
    if (a.recent && !b.recent) {
        return -1;
    } else if (!a.recent && b.recent) {
        return 1;
    }
    return 0;
}

const SiteSelectorAutocomplete = observer((props) => {
    const [value, setValue] = useState("");
    const [open, setOpen] = useState(false);
    const [recentList, setRecentList] = useStateWithLocalStorage('recent_sites');
    const {sites} = useSiteList();
    const {t} = useTranslation();
    const siteStore = useSiteStore();
    const {getStyles} = useResponsive();

    const isSelectedSite = useCallback((siteId) => {
        return parseInt(siteStore.selectedSiteId) === parseInt(siteId);
    }, [siteStore.selectedSiteId]);

    const isRecentSite = useCallback((siteId) => {
        return recentList && recentList.includes(siteId);
    }, [recentList]);

    const addToRecentList = (siteId) => {
        if (siteId && siteId.trim() !== '') {
            if (recentList && recentList.length >= MAX_RECENT_SITES) {
                !isRecentSite(siteId) ? recentList.pop() : recentList.splice(recentList.indexOf(siteId), 1);
            }
            let newList = [].concat(recentList);
            newList.unshift(siteId);
            setRecentList(newList);
        }
    }

    const sortRecentSiteList = (recentSiteList) => {
        let sortedList = [];
        recentSiteList.forEach((recentSite) => {
            sortedList[recentList.indexOf(recentSite.siteId)] = recentSite;
        })
        return sortedList;
    }

    let unsortedSiteList = [];
    let recentSiteList = [];
    sites.forEach((site) => {
        if (!isSelectedSite(site.siteId)) {
            if (isRecentSite(site.siteId)) {
                recentSiteList.push({recent: true, group: t('recent_sites'), groupMobile: [t('premise_no'), '/', t('premise_address'), t('contract_no')].join(' '), ...site});
            } else {
                unsortedSiteList.push({recent: false, group: t('sites'), groupMobile: [t('premise_no'), '/', t('premise_address'), t('contract_no')].join(' '), ...site});
            }
        }
    });

    let siteList = unsortedSiteList.concat(sortRecentSiteList(recentSiteList));

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    const getOptionLabel = (option) => {
        return option.siteId ? 'Contract Number: ' + option.contractId
            + ', Premise Number:' + option.premiseNo + ', '
            + option.siteName.trim() + ', ' + option.siteAddress : '';
    }

    return (         
        <Autocomplete
            aria-label={t('aria_label_autocomplete')}
            className={classes.miAutocomplete}
            open={open}
            onOpen={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            classes={{
                paper: classes.paper,
                listbox: classes.listbox,
                option: classes.option
            }}
            inputValue={value}
            onChange={(event, newValue) => {
                setValue("");
                if (!isEmpty(newValue) && !isEmpty(newValue.siteId)) {
                    siteStore.setSelectedSiteId(newValue.siteId);
                    addToRecentList(newValue.siteId);
                }
            }}
            onInputChange={(event, newValue) => {
                if (isEmpty(event) || event.type === 'blur') {
                    setValue("");
                } else {
                    setValue(newValue);
                }
            }}
            filterOptions={(options, params) => {
                return filter(options, params);
            }}
            clearOnBlur
            blurOnSelect
            clearOnEscape
            handleHomeEndKeys
            options={siteList.sort(sortRecentCompare)}
            getOptionLabel={(option) => {
                return getOptionLabel(option);
            }}
            freeSolo
            {...props}
        />
    )
})

export default SiteSelectorAutocomplete;
