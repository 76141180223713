import React, { useEffect } from 'react';
import { useAuthState } from '../../../contexts/AuthProvider/AuthProvider';
import { useFlags } from '../../../contexts/FlagsProvider/FlagsProvider';
import { useSiteStore } from '../../../contexts/StateProvider/StateProvider';
import { useEntitlements } from '../../../services/entitlements/useEntitlements';
import { isEmpty, setDocumentTitle } from '../../../utils/utils';
import EntitlementsTable from './EntitlementsTable';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import SiteSelector from '../../../components/SiteSelector/SiteSelector';
import MIPageTitle from '../../../components/Widgets/MIPageTitle';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        tabOuter: {
            display: 'flex',
        },
        tabFiller: {
            flex: 1,
            borderBottom: '4px solid #CDD2D5',
        },
        tabContainer: {
            'height': theme.sizes.mega_gap,
            '& span.MuiTabs-indicator': {
                height: '4px',
            },
            'marginRight': 'auto',
        },
        tab: {
            'textTransform': 'none',
            'padding': '16px 16px',
            'fontWeight': theme.typography.fontWeightBold,
            'maxWidth': '50%',
            'color': theme.colors.mainText,
            'fontSize': theme.fontSizes.nav,
            '&.Mui-selected': {
                fontWeight: theme.typography.fontWeightBold,
            },
            'borderBottom': '4px solid #CDD2D5',
            'minWidth': '180px',
        },
    };
});

const Entitlements = observer(() => {
    const { t } = useTranslation();
    const classes = useStyles();
    const siteStore = useSiteStore();
    const { countryCode } = useAuthState();
    const { feature_entitlements } = useFlags();
    const [ entitlements, responseStatusEntitlements, getEntitlements ] = useEntitlements();

    useEffect(() => {
        if (!isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber) &&
            !isEmpty(countryCode) && feature_entitlements) {
            getEntitlements(countryCode, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber);
        }
    }, [ siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, countryCode, feature_entitlements, getEntitlements ]);


    useEffect(() => {
        setDocumentTitle(t, 'nav_entitlements');
    }, [ t ]);

    return (
        <Paper className={classes.root} aria-label={t('aria_label_entitlements_page')}>
            <SiteSelector/>
            <MIPageTitle
                title={t('entitlements')}
                ariaLabel={t('aria_label_entitlements_title')}
                marginTop={8}
                marginBottom={0}
            />
            <div className={classes.content}>
                <EntitlementsTable entitlements={entitlements} responseStatusEntitlements={responseStatusEntitlements}/>
            </div>
        </Paper>
    );
});

export default Entitlements;

