import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {useContentFromUrl} from '../../services/useContentFromUrl';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import MICmsContainer from "../../components/Widgets/MICmsContainer";
import {isEmpty, setDocumentTitle} from "../../utils/utils";
import {ResponsiveComponent, useResponsive} from "../../services/useResponsive";
import MIPageTitle from '../../components/Widgets/MIPageTitle';

const desktopStyles = theme => {
    return {
        root: {
            width: "100%",
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
}};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: '100vh',
            paddingTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.no_gap,
            paddingBottom: theme.sizes.no_gap,
        },
        htmlContainer: {
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        }
    })};

const OurServices = (props) => {
    const {t} = useTranslation();
    let [content, responseStatus, getContentFromUrl, getContentUrl] = useContentFromUrl();
    let {url} = props;
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_our_services');
     }, [t]);

    useEffect(() => {
        const fullPath = getContentUrl(url);
        getContentFromUrl(fullPath);
    }, [getContentFromUrl, getContentUrl, url]);

    return (
        <Paper className={classes.root} aria-label={t('aria_label_our_services_page')}>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: <MIPageTitle title={t('our_services')} ariaLabel={t('aria_label_our_services_title')}/>
                }} />
            <MIApiLoader
                MIComponent={
                    <div className={classes.htmlContainer}>
                        {!isEmpty(content) ? <MICmsContainer html={content}/>  : <h1>{t('content_unavailable')}</h1>}
                    </div>
                }
                responseStatus={responseStatus}
                ariaLabelProgressBar={t('aria_label_progressbar_our_service')}
            />
        </Paper>
    );
};

export default OurServices;
