import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TranslateIcon from '@material-ui/icons/Translate';
import { useLanguageStore } from '../../contexts/StateProvider/StateProvider';
import { getLocaleSections } from '../../utils/utils';

const useStyles = makeStyles((theme) => {
    return {
        icon: {
            verticalAlign: 'middle',
            color: theme.palette.text.primary,
        },
        selector: {
            'fontSize': theme.fontSizes.mobileTitle,
            'border':0,
            'color': theme.colors.mainText,
            'paddingLeft': theme.sizes.planck_gap,
            'fontFamily': theme.typography.fontFamily,
            'background': theme.palette.background.white,
            '&:focus': theme.ariaFocus,
        },
    };
});

const LanguageSwitcher = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const languageStore = useLanguageStore();

    const [ selectedLanguage ] = getLocaleSections(i18n.language);

    return (
        <div className={props.className}>
            <TranslateIcon className={classes.icon}/>

            <select
                name={'language_selector'}
                id={'language_selector'}
                data-testid={'select'}
                onChange={(e) => {
                    languageStore.setLanguage(e.target.value, i18n);
                }}
                aria-label={t('aria_label_language_selector')}
                className={classes.selector}
                value={selectedLanguage}
            >
                <option value={'en'}>English</option>
                <option value={'it'}>Italiano</option>
                <option value={'no'}>Norsk</option>
                <option value={'id'}>Bahasa Indonesia</option>
                <option value={'es'}>Español</option>
                <option value={'pt'}>Português</option>
                <option value={'de'}>Deutsch</option>
                <option value={'nl'}>Nederlands</option>
                <option value={'ko'}>한국인</option>
                <option value={'th'}>ไทย</option>
                <option value={'fr'}>Français</option>
                <option value={'zh'}>繁体中文</option>
            </select>
        </div>
    );
};

export default LanguageSwitcher;
