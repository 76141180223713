import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { SERVICE_GROUP_ID, LOCATION_ID } from '../../../../services/contract/useContractDetails';
import { observer } from 'mobx-react-lite';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Typography } from '@material-ui/core';
import MITabs from '../../../../components/Widgets/MITabs';
import { isEmpty } from '../../../../utils/utils';
import { getTables } from '../SiteLevel';
import ContractGroupTableMobile from './ContractGroupTableMobile';

const useStyles = makeStyles((theme) => {
    return {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.large_gap,
            paddingRight: theme.sizes.no_gap,
            paddingLeft: theme.sizes.no_gap,
            maxHeight: 'none',
        },
        noElementsText: {
            'color': theme.palette.text.disabled,
            '&:focus': theme.ariaFocus,
        },
        dataCell: {
            lineHeight: 0,
            width: '100%'
        },
        linkContainer: {
            display: 'inline-flex',
            justifyContent: 'flex-end',
            width: '100%'
        },
        location: {
            fontSize: theme.fontSizes.mobileLabel,
            display: 'inline-flex',
            fontFamily: theme.fontFamily.proximaBold,
        },
        product: {
            fontSize: theme.fontSizes.mobileBody,
            overflowWrap: 'break-word',
        },
        selectLinkMobile: {
            height: theme.sizes.large_gap,
            width: theme.sizes.large_gap,
            color: theme.palette.primary.main
        },
        groupTitle: {
            fontSize: theme.fontSizes.mobileTitle,
            fontFamily: theme.fontFamily.proximaMedium,
        },
    };
});

const SiteLevelMobile = observer((props) => {
    const { t } = useTranslation();
    const { contractDetails, contractGroupId } = props;
    const classes = useStyles();

    const getLocationAndProduct = (params) => {
        return (
            <div className={ classes.dataCell }>
                <Typography className={ classes.location }>{ [ t('location'), params.row.location ].join(': ') }</Typography>
                <Typography className={ classes.product }>{params.row.product}</Typography>
            </div>
        );
    };

    const getGroupAndProduct = (params) => {
        return (
            <div className={ classes.dataCell }>
                <Typography className={ classes.location }>{ [ t('service_group_name'), params.row.serviceGroup ].join(': ') }</Typography>
                <Typography className={ classes.product }>{params.row.product}</Typography>
            </div>
        );
    };

    const columnsMobile = [
        {
            field: 'info',
            resizable: false,
            filterable: false,
            width: 40,
            sortable: false,
            renderCell: (params) => {
                return <div className={ classes.linkContainer }>
                    <InfoOutlinedIcon className={ classes.selectLinkMobile }/>
                </div>;
            }
            ,
        }
    ];


    const renderTables = (id, group) => {
        const tables = getTables(group);
        const items = Object.keys(tables).map((groupName, index) => {
            let columns = [
                id === SERVICE_GROUP_ID ?
                    {
                        field: 'locationAndProduct',
                        type: 'string',
                        resizeable: false,
                        flex: 3,
                        renderCell: (params) => {
                            return getLocationAndProduct(params);
                        }
                    } :
                    {
                        field: 'groupAndProduct',
                        type: 'string',
                        resizeable: false,
                        flex: 3,
                        renderCell: (params) => {
                            return getGroupAndProduct(params);
                        }
                    },
                ...columnsMobile
            ];
            return <div key={ index }>
                <ContractGroupTableMobile
                    groupName={ groupName }
                    rows={ tables[groupName] }
                    columns={ columns }
                    hideFooterForOnePage={ true }
                    pageSize={ 5 }
                />
            </div>;
        });
        if (items.length === 0) {
            return (
                <div>
                    <h2 className={classes.noElementsText} tabIndex={0}>
                        { t('no_contracts_available') }
                    </h2>
                </div>
            );
        }

        return <div>{ items }</div>;
    };

    const displayTable = (group) => {
        const { label, groupId, table } = group;
        return (
            <div key={ label }>
                {!isEmpty(label) && <h1 className={classes.groupTitle} aria-label={groupId} tabIndex={0}>{label}</h1>}
                {table}
            </div>
        );
    };

    const getGroups = () => {
        if (isEmpty(contractDetails)) {
            return null;
        }

        const renderedTable = [];
        const isInTab = [ SERVICE_GROUP_ID, LOCATION_ID ];
        const tabs = [];

        contractGroupId.forEach((contractGroup) => {
            const groupId = contractGroup.id;
            if (!isEmpty(contractDetails[groupId])) {
                const group = {
                    label: contractGroup.name,
                    groupId: groupId,
                    table: renderTables(groupId, contractDetails[groupId])
                };
                isInTab.includes(groupId) ? tabs.push(group) : renderedTable.push(displayTable(group));
            }
        });

        if (!isEmpty(tabs)) {
            renderedTable.unshift(tabs.length > 1 ? <MITabs tabs={tabs} key="site_level_tabs" /> : displayTable(tabs[0]));
        }

        return renderedTable;
    };

    return (
        <>
            {getGroups()}
        </>
    );
});

export default SiteLevelMobile;
