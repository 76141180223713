import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


const useStyles = makeStyles(theme => ({
    root: {
        width: '288px',
        height: theme.sizes.support_card,
        marginBottom: '44px',
        borderRadius: 0,
        border: theme.borders.outline_regular,
        borderColor: "#9AA5AA",
        '&:hover': {
             boxShadow: 'rgba(0, 0, 0, 0.5) 0px 2px 5px 1px',
        },
        boxShadow:'none',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
    },
    icon: {
        width: theme.sizes.support_card,
        height: theme.sizes.support_card
    },
    content: {
        width: '208px',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.fontSizes.small,
        padding: theme.sizes.no_gap,
        '&:focus': theme.ariaFocus,
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
        '&:last-child': {
            paddingBottom: theme.sizes.no_gap,
        }
    },
    text: {
        margin: 'auto',
        paddingLeft: '16px',
        paddingRight: '16px',
        color: '#1d7dc5',
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '1em',
        textAlign: 'center',
        display: 'inline-flex',
    }
}));

const ImageCard = (props) => {
    const classes = useStyles();
    const {image, text} = props;

    return (
    <Card className={classes.root}>
        <CardMedia
            className={classes.icon}
            image={image}
        />
        <CardContent className={classes.content} tabIndex={0}>
            <span className={classes.text}>{text}</span>
        </CardContent>
    </Card>
    );
};

export default ImageCard;
