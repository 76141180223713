import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useHealthStatusStore } from '../../contexts/StateProvider/StateProvider';
import { useHealthCheck } from '../../services/useHealthCheck';
import { useAuthState } from '../../contexts/AuthProvider';
import { SUCCESS, UNAUTHORIZED } from '../../services/requests/requestReducer';
import {LOGGED_IN_STATUS} from "../../contexts/AuthProvider/AuthConst";

const HealthCheck = () => {
    const location = useLocation();
    const healthStatus = useHealthStatusStore();
    const [ , , getHealthCheck ] = useHealthCheck();
    const { logout, setLogoutTimer, status } = useAuthState();
    const authStatus = status();

    useEffect(() => {
        if (healthStatus.checkHealthStatus()) {
            getHealthCheck();
        }

        // only logout the user if he is logged in
        if (healthStatus.getHealthStatus() === UNAUTHORIZED && authStatus === LOGGED_IN_STATUS) {
            logout();
        } else if (healthStatus.getHealthStatus() === SUCCESS) {
            setLogoutTimer();
        }

    }, [ location.pathname, getHealthCheck, healthStatus, logout, setLogoutTimer, authStatus ]);

    return <></>;
};

export default HealthCheck;
