import {makeAutoObservable, toJS} from 'mobx';
import { isEmpty } from '../../utils/utils';

export const CONDITION_LESS_THAN = 'less_than';
export const CONDITION_GREATER_THAN = 'greater_than';
export const CONDITION_EQUALS = 'equals';
export const CONDITION_TIME_AFTER = 'time_after';
export const CONDITION_SEARCH = 'search';
export const CONDITION_CONTAINS = 'contains';

export const TIME_1_DAY = '1_DAY';
export const TIME_1_WEEK = '1_WEEK';
export const TIME_1_MONTH = '1_MONTH';
export const TIME_YTD = 'YTD';

const dateLaterThan = (property, checkType) => {
    const dt = new Date(property);
    let target = new Date();
    switch (checkType) {
    case TIME_1_DAY:
        target.setDate(target.getDate() - 1);
        return dt >= target;
    case TIME_1_WEEK:
        target.setDate(target.getDate() - 7);
        return dt >= target;
    case TIME_1_MONTH:
        target.setMonth(target.getMonth() - 1);
        return dt >= target;
    case TIME_YTD:
        target.setFullYear(target.getFullYear() - 1);
        return dt >= target;
    default:
        return false;
    }
};

const checkFilter = (item, filter) => {
    const property = !isEmpty(item.properties) ? item.properties[filter.field] : item[filter.field];
    switch (filter.condition) {
    case CONDITION_LESS_THAN:
        return property < filter.value;
    case CONDITION_GREATER_THAN:
        return property > filter.value;
    case CONDITION_EQUALS:
        return property === filter.value;
    case CONDITION_TIME_AFTER:
        return dateLaterThan(property, filter.value);
    case CONDITION_SEARCH:
        return !isEmpty(item.label) && !isEmpty(filter.value) ?
            item.label.toString().toLowerCase().includes(filter.value.toLowerCase()) : false;
    case CONDITION_CONTAINS:
        return !isEmpty(property) && !isEmpty(filter.value) ?
            property.toString().toLowerCase().includes(filter.value.toLowerCase()) : false;
    default:
        return false;
    }
};

const hasGroupMatch = (filters, group, item) => {
    if (filters[group].length === 0) {
        // If there are no filters then the group passes
        return true;
    }
    for (let currentFilter of filters[group]) {
        if (checkFilter(item, currentFilter)) {
            return true;
        }
    }
    return false;
};

const applyFilters = (filters, item) => {
    // Within a group, filters are 'OR' evaluated
    // Groups are 'AND' evaluated
    let matches = true;

    Object.keys(filters).forEach((key) => {
        matches = matches && hasGroupMatch(filters, key, item);
    });

    return matches;
};

class FilterStore {
    filters = {};

    constructor() {
        makeAutoObservable(this);
    }

    addFilter(group, field, condition, value) {
        if (!this.filters[group]) {
            this.filters[group] = [];
        }
        this.filters[group].push({ field: field, condition: condition, value: value });
    }

    removeFilter(group, field, condition, value) {
        this.filters[group] = this.filters[group].filter((currentFilter) => !(
            currentFilter.field === field &&
                currentFilter.condition === condition &&
                currentFilter.value === value
        ));
    }

    removeFilterGroup(group) {
        this.filters[group] = [];
    }

    hasCondition (group, field, value) {
        let foundEntry = null;
        if (!isEmpty(this.filters[group])) {
            foundEntry = this.filters[group].find((f) => f.field === field && f.value === value);
        }
        return !isEmpty(foundEntry);
    }

    getValue(group, field, condition) {
        let foundEntry = null;
        if (!isEmpty(this.filters[group])) {
            foundEntry = this.filters[group].find((f) => f.field === field && f.condition === condition);
        }
        return isEmpty(foundEntry) ? '' : foundEntry.value;
    }

    filterCount() {
        let total = 0;
        Object.keys(this.filters).forEach((key) => {
            total = total + this.filters[key].length;
        });
        return total;
    }

    filterList(list) {
        const filteredList = list && this.filterCount() > 0 ? list.filter(applyFilters.bind(this, toJS(this.filters))) : list;
        return [ filteredList, list ? list.length : 0 ];
    }

    reset() {
        this.filters = {};
    }
}

export { FilterStore };
