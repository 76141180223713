import React, { useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { useContentFromUrl } from '../../services/useContentFromUrl';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import MICmsContainer from '../../components/Widgets/MICmsContainer';
import { isEmpty, setDocumentTitle } from '../../utils/utils';
import { useResponsive } from '../../services/useResponsive';
import { useLocation } from 'react-router-dom';
import MIButton from '../../components/Widgets/MIButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useNotificationSignedUrl } from '../../services/notifications/useNotificationSignedUrl';
import { FETCHING, SUCCESS } from '../../services/requests/requestReducer';
import MIPageTitle from '../../components/Widgets/MIPageTitle';

const desktopStyles = (theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        floatMain: {
            float: (props) => {
                return props.floatMain ? props.floatMain : 'left';
            },
        },
        buttonContainer: {
            display: 'block',
            height: theme.sizes.xxlarge_gap,
            marginBottom: (props) => {
                return !isEmpty(props.marginBottom) ? props.marginBottom : theme.sizes.medium_gap;
            },
        },
        viewAll: {
            color: theme.palette.primary.main,
            display: 'inline',
            marginRight: theme.sizes.micro_gap,
            width: '160px',
        },
        icon: {
            marginBottom: `-${theme.sizes.pico_gap}`,
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: '100vh',
            paddingTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.no_gap,
            paddingBottom: theme.sizes.no_gap,
        },
        htmlContainer: {
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        },
        buttonContainer: {
            display: 'block',
            height: theme.sizes.xxlarge_gap,
            marginTop: `-${ theme.sizes.small_gap}`,
        },
        viewAll: {
            color: theme.palette.primary.main,
            display: 'inline',
            width: 'fit-content',
            border: 'none',
        },
    });
};

const NotificationContent = (props) => {
    const location = useLocation();
    const { t } = useTranslation();
    const [ content, responseStatus, getContentFromUrl, ] = useContentFromUrl();
    const url = !isEmpty(location.state) && !isEmpty(location.state.url) ? location.state.url : '';
    const title = !isEmpty(location.state) && !isEmpty(location.state.title) ? location.state.title : '';
    const [ notificationSignedUrl, urlResponseStatus, getNotificationContentSignedUrl ] = useNotificationSignedUrl();
    const { getStyles } = useResponsive();
    const history = useHistory();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    });

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_messages');
    }, [ t ]);

    useEffect(() => {
        if(!isEmpty(url)) {
            getNotificationContentSignedUrl(url);
        }
    }, [ getNotificationContentSignedUrl, url ]);

    useEffect(() => {
        if(!isEmpty(notificationSignedUrl)) {
            getContentFromUrl(notificationSignedUrl);
        }
    }, [ getContentFromUrl, notificationSignedUrl ]);


    const redirectToViewAll = () => {
        history.push({
            pathname: '/support/messages',
        });
    };

    const getApiStatus = useCallback(() => {
        if (urlResponseStatus === FETCHING) {
            return FETCHING;
        }
        if (urlResponseStatus === SUCCESS && (responseStatus === null || responseStatus === FETCHING)) {
            return FETCHING;
        }
        return urlResponseStatus || responseStatus;
    }, [ urlResponseStatus, responseStatus ]);

    return (
        <Paper className={classes.root} aria-label={t('aria_label_messages_page')}>
            <div className={classes.buttonContainer}>
                <div className={classes.floatMain}>
                    <MIButton
                        className={classes.viewAll}
                        variant={'outlined'}
                        startIcon={<ArrowBackIcon className={classes.icon}/>}
                        onClick={redirectToViewAll}>
                        {t('view_all')}
                    </MIButton>
                </div>
            </div>
            <MIPageTitle
                title={title}
                ariaLabel={t('aria_label_message_content_title')}
                marginBottom={12}
                marginLeft={16}
            />
            <MIApiLoader
                MIComponent={
                    <div className={classes.htmlContainer}>
                        {!isEmpty(content) ? <MICmsContainer html={content} /> : <h1>{t('content_unavailable')}</h1>}
                    </div>
                }
                responseStatus={getApiStatus()}
                ariaLabelProgressBar={t('aria_label_progressbar_messages')}
            />
        </Paper>
    );
};

export default NotificationContent;
