import React, {useEffect, useState, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useResponsive} from "../../services/useResponsive";
import {isEmpty} from "../../utils/utils";
import Theme from '../../components/Theme/Theme';

const desktopStyles = theme => {
    return {
        accordion: {
            boxShadow: 'none',
            borderBottom: theme.borders.outline_regular,
            borderRadius: theme.sizes.no_gap,
            '&:focus': theme.ariaFocus,
            '&:expanded': {
                margin: 'auto',
            },
        },
        expanded: {
            minHeight: '160px',
        },
        heading: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.typography.h2.fontFamily,
            color: theme.palette.text.primary,
            '&:focus': theme.ariaFocus,
        },
        content: {
            width: '100%',
            '&:focus': theme.ariaFocus,
        },
        details: {
            padding: theme.sizes.no_gap,
        },
        summary: {
            padding: theme.sizes.no_gap,
        },
        icon: {
            color: theme.palette.primary.main,
            width: theme.sizes.xlarge_gap,
            height: theme.sizes.xlarge_gap,
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        heading: {
            ...baseStyle.heading,
            fontSize: theme.fontSizes.small,
        },
        icon: {
            ...baseStyle.icon,
            width: theme.sizes.large_gap,
            height: theme.sizes.large_gap,
        },
    });

};

const MIAccordion = (props) => {
    const {header, content, expandedValue, id, hideBottomBorder, customStyle} = props;
    const [expanded, setExpanded] = useState(false);
    const {getStyles} = useResponsive();
    const accordionRef = useRef();

    const useStyles = makeStyles(theme => {
        const baseStyle = getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
        return isEmpty(customStyle) ? baseStyle : Object.assign(baseStyle, {
            accordion: {
                ...baseStyle.accordion,
                ...customStyle.accordion,
            },
            summary: {
                ...baseStyle.summary,
                ...customStyle.summary,
            },
            heading: {
                ...baseStyle.heading,
                ...customStyle.heading,
            },
        });
    });
    const classes = useStyles();

    useEffect(() => {
        if(!isEmpty(expandedValue)){
            setExpanded(expandedValue);
        }
    }, [expandedValue, setExpanded]);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const handleScroll = () => {
        accordionRef.current.scrollIntoView({ behavior: 'smooth' });
    }

    useEffect(() => {
        if(hideBottomBorder){
            const accordion = document.getElementById(id);
            accordion.style['border-bottom'] = !isEmpty(expanded) && expanded ? 'none' : Theme.borders.outline_regular;
        }
    }, [hideBottomBorder, expanded, id]);

    return (
        <Accordion square expanded={expanded} className={classes.accordion} id={id} TransitionProps={{onEntered: handleScroll}} onChange={handleChange} classes={{expanded: classes.expanded }}>
            <AccordionSummary
                ref={accordionRef}
                expandIcon={<ExpandMoreIcon className={classes.icon}/>}
                aria-controls={'panel-' + id}
                id={'panel-' + id + '-header'}
                className={classes.summary}>
                <span className={classes.heading}>{header}</span>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
                <div className={classes.content}>
                    {content}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default MIAccordion;
