import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import PlannedVisitGrid from "../PlannedVisitGrid";
import PlannedVisitsCalendar from "../PlannedVisitsCalendar";
import MITopBorder from "../../Widgets/MITopBorder"
import {observer} from "mobx-react-lite";
import {mergeStyles, isEmpty} from "../../../utils/utils";
import MIApiLoader from "../../Widgets/MIApiLoader";
import {useContentFromUrl} from '../../../services/useContentFromUrl';
import MICmsContainer from '../../../components/Widgets/MICmsContainer';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'block',
        height: '100%'
    },
    titleMobile: {
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.fontSizes.mobileTitle,
            marginTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.large_gap,
        },
    },
    paper: {
        width: '100%',
        marginBottom: theme.sizes.large_gap,
    },
    calendar: {
        width: '100%',
    },
    topBorder: {
        height: '2px'
    },
    disclaimer: {
        fontSize: theme.fontSizes.mobileBody,
    }
}));

const PlannedVisitsMobile = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [content, responseStatusContent, getContentFromUrl, getContentUrl] = useContentFromUrl();
    const {sharedClasses, plannedVisits, previousVisits, selectedPlannedVisitDateString, setSelectedPlannedVisitDateString} = props;

    useEffect(() => {
        const url = getContentUrl('planned-visits-disclaimer.html');
        getContentFromUrl(url);
    }, [getContentFromUrl, getContentUrl]);

    return (
        <div className={classes.root}>
            <h1 
                className={mergeStyles(sharedClasses.title, classes.titleMobile)} 
                aria-label={t('aria_label_planned_visits_title')} 
                tabIndex={0}
            >
                {t('planned_visits')}
            </h1>
            <MIApiLoader
                MIComponent={
                    !isEmpty(content) ?
                        <div className={classes.disclaimer}>
                            <MICmsContainer html={content} />
                        </div>
                        : null
                }
                responseStatus={responseStatusContent}
                ariaLabelProgressBar={t('aria_label_progressbar_planned_visits_disclaimer')}
            />
            <MITopBorder topBorderStyle={mergeStyles(sharedClasses.topBorder, classes.topBorder)}/>
            <div className={classes.paper}>
                <div 
                    className={mergeStyles(sharedClasses.calendar, classes.calendar)} 
                    aria-label={t('aria_label_calendar')}
                >
                    <PlannedVisitsCalendar plannedVisits={plannedVisits}
                                           previousVisits={previousVisits}
                                           selectedPlannedVisitDateString={selectedPlannedVisitDateString}
                                           setSelectedPlannedVisitDateString={setSelectedPlannedVisitDateString}/>
                </div>
                <PlannedVisitGrid
                    plannedVisits={plannedVisits}
                    maxCards={3}
                    selectedPlannedVisitDateString={selectedPlannedVisitDateString}
                    setSelectedPlannedVisitDateString={setSelectedPlannedVisitDateString}/>
            </div>
        </div>
    );
});

export default PlannedVisitsMobile;
