import IconButton from "@material-ui/core/IconButton";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {mergeStyles} from '../../utils/utils';

const useStyles = makeStyles(theme => ({
    iconLink: {
        padding: '8px',
        margin: 'auto 0',
        textAlign: 'center',
    },
}));

const MISocialMediaButton = (props) => {
    const classes = useStyles();
    const {icon, ariaLabel, iconHover, href, title, iconLinkStyle} = props;

    return (
        <IconButton 
            className={mergeStyles(classes.iconLink, iconLinkStyle)} 
            aria-label={ariaLabel} 
            href={href} 
            target='_blank'
            rel='noopener noreferrer' 
        >
            <img src={icon} alt={ariaLabel} title={title}
                 onMouseOver={e => (e.currentTarget.src = iconHover)}
                 onMouseOut={e => (e.currentTarget.src = icon)}
            />
        </IconButton>

    );
};

export default MISocialMediaButton;