import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { isEmpty } from '../../utils/utils';
import { MYRENTOKILSITEID } from '../../pages/Service/FloorPlan/FloorPlanEnums';

export const useGetMyRentokilSiteId = () => {
    const siteStore = useSiteStore();
    const [ myRentokilSiteId, setMyRentokilSiteId ] = useState(null);
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/getDirectFloorPlanSiteId';
    // Hardcoded value for site Id because there is no date in staging
    const stagingSiteId = process.env.REACT_APP_MYRENTOKIL_STAGING_SITE_ID;
    const [ siteIdResponseStatus, setSiteIdResponseStatus ] = useState(null);

    useEffect(() => {
        setSiteIdResponseStatus(responseStatus);
        if (responseStatus === SUCCESS) {
            try {
                if(stagingSiteId) {
                    // Hardcoded value for site id because there is no date in staging
                    siteStore.setSelectedmyRentokilSiteId(stagingSiteId);
                    setMyRentokilSiteId(stagingSiteId);
                } else if(isEmpty(response.data.siteId)) {
                    siteStore.setSelectedmyRentokilSiteId(MYRENTOKILSITEID.NO_ID);
                }else{
                    siteStore.setSelectedmyRentokilSiteId(response.data.siteId);
                    setMyRentokilSiteId(response.data.siteId);
                }
            } catch (error) {
                siteStore.setSelectedmyRentokilSiteId('');
                console.error('useGetMyRentokilSiteId error: ', error);
            }
        }
    }, [ responseStatus, response, stagingSiteId, setMyRentokilSiteId, siteStore ]);

    const getMyRentokilSiteId = useCallback((countryCode, contractNumber, premiseNumber) => {
        const myRentokilSiteId = siteStore.getSelectedmyRentokilSiteId();
        if (!isEmpty(myRentokilSiteId)) {
            setMyRentokilSiteId(myRentokilSiteId);
            setSiteIdResponseStatus(SUCCESS);
        } else {
            setMyRentokilSiteId('');
            setSiteIdResponseStatus(null);
            const url = [ endpoint, countryCode, contractNumber, premiseNumber ].join('/');
            makeRegionalGCPRequestWithParams(url).catch((error) => {
                console.error('useGetMyRentokilSiteId error: ', error);
            });
        }
    }, [ makeRegionalGCPRequestWithParams, siteStore ]);

    return [ myRentokilSiteId, siteIdResponseStatus, getMyRentokilSiteId ];
};
