import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import { useDevicesMappingStore } from '../../contexts/StateProvider/StateProvider';
import { isEmpty } from '../../utils/utils';
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";

export const useGetDevicesMapping = () => {
    const devicesMappingStore = useDevicesMappingStore();
    const [ devicesMapping, setDevicesMapping ] = useState(null);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getDevicesMapping';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                const devicesMappingDict = Object.assign({}, ...response.data.devices.map((x) => ({ [x.id]: x })));
                devicesMappingStore.setDevicesMapping(devicesMappingDict);
                setDevicesMapping(devicesMappingDict);
            } catch (error) {
                console.error('useGetDevicesMapping error: ', error);
                devicesMappingStore.setDevicesMapping({});
                setDevicesMapping(null);
            }
        }
    }, [ responseStatus, response, setDevicesMapping, devicesMappingStore ]);

    const getDevicesMapping = useCallback(() => {
        const devicesMappingStored = devicesMappingStore.getDevicesMapping();
        if (!isEmpty(devicesMappingStored)) {
            setDevicesMapping(devicesMappingStored);
        } else {
            setDevicesMapping(null);
            makeFloorPlanApiRequestWithParams(endpoint).catch((error) => {
                console.error('useGetDevicesMapping makeRequest: ', error);
            });
        }
    }, [ makeFloorPlanApiRequestWithParams, setDevicesMapping, devicesMappingStore ]);

    return [ devicesMapping, devicesMapping !== null ? SUCCESS : responseStatus, getDevicesMapping ];
};