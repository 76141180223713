import React, {useEffect, useCallback} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import MIApiLoader from "../../../components/Widgets/MIApiLoader";
import {useDeleteReport} from "../../../services/useDeleteReport";
import {useTranslation} from "react-i18next";
import {useAuthState} from "../../../contexts/AuthProvider";
import useModal from "../../../services/useModal";
import MIModal from "../../../components/Widgets/MIModal";
import MIButton from "../../../components/Widgets/MIButton";
import MIMobileDialog from "../../../components/Widgets/mobile/MIMobileDialog";
import {useResponsive, ResponsiveComponent} from "../../../services/useResponsive";
import {Typography} from "@material-ui/core";

const desktopStyles = (theme) => {
    return {
        actionIcon: {
            fill: theme.palette.primary.main,
            width: theme.sizes.large_gap,
            cursor: 'pointer',
            marginRight: '5px',
        },
        actionIconContainer: {
            alignItems: 'center',
            display: 'flex',
        },
        actionIconOuter: {
            display: 'flex',
            '&:focus': theme.ariaFocus,
        },
        title: {
            color: theme.colors.mainText,
            display: 'inline',
        },
        titleContainer: {
            display: 'inline',
            '&:focus': theme.ariaFocus,
        },
        bodyContainer: {
            overflowY: 'auto',
            height:'fit-content',
        },
        buttons: {
            textAlign: 'right',
            marginTop: theme.sizes.small_gap,
        },
        cancelButton: {
            marginRight: theme.sizes.small_gap,
            border: 'none',
        },
        typography: {
            lineHeight: '1.5',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
            whiteSpace: 'normal',
            display: 'block',
        },
    }
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        typography: {
            fontSize: theme.fontSizes.mobileBody,
            fontFamily: theme.fontFamily.proximaMedium,
            wordWrap: 'break-word',
            whiteSpace: 'normal'
        },
        bodyContainer: {
            paddingTop: theme.sizes.tiny_gap,
        },
        buttons: {
            textAlign: 'center',
            marginTop: theme.sizes.tiny_gap,
        },
        cancelButton: {
            ...baseStyle.cancelButton,
            fontSize: theme.fontSizes.mobileBody,
            fontFamily: theme.fontFamily.proximaBold,
        },
        okButton: {
            fontSize: theme.fontSizes.mobileBody,
            fontFamily: theme.fontFamily.proximaBold,
        }
    });
};

const DeleteReport = (props) => {
    const {t} = useTranslation();
    const [deleteStatus, responseStatus, deleteReport] = useDeleteReport();
    const {reportId, getExistingReports, setAlert, setIsShowing, closeActionsMenu} = props;
    const {locale, countryCode} = useAuthState();
    const {isShowing, changeStatus} = useModal();
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    const showAlert = useCallback(() => {
        setAlert({message: 'delete_report_dialog_error', severity: 'error'});
        setIsShowing(true);
    },[setAlert, setIsShowing]);

    const handleOnClick = () => {
        changeStatus();
    };

    const handleOnClose = () => {
        changeStatus();
        closeActionsMenu();
    };

    const deleteReportOnClick = () => {
        changeStatus();
        if (countryCode && reportId && locale) {
            deleteReport(countryCode, reportId, locale);
        }
    };

    useEffect(() => {
        if (deleteStatus) {
            try {
                if (deleteStatus === 'success') {
                    closeActionsMenu();
                    //refresh list on report deleted
                    getExistingReports(
                        countryCode,
                        locale
                    )
                } else {
                    showAlert();
                }
            } catch (error) {
                console.error('delete error: ', error);
            }
        }
    }, [deleteStatus, getExistingReports, locale, showAlert, closeActionsMenu]);

    const getHeader = () => {
        return (
            <div className={classes.titleContainer} tabIndex={0} aria-label={t('delete_report_dialog_title')} >
                <h2 className={classes.title}>{t('delete_report_dialog_title')}</h2>
            </div>
        );
    };

    const getBody = () => {
        return (
            <div className={classes.bodyContainer}>
                <div> 
                    <Typography className={classes.typography}>
                        {t('delete_report_dialog_content')}
                    </Typography>
                    <Typography className={classes.typography}>
                        {t('delete_report_are_you_sure')}
                    </Typography>
                </div>
                <div className={classes.buttons}>
                    <MIButton
                        className={classes.cancelButton}
                        mi_size='small'
                        mi_type='secondary'
                        ariaLabel={t('aria_label_cancel_button')}
                        onClick={handleOnClose}
                    >
                        {t('delete_report_dialog_cancel_button')}
                    </MIButton>
                    <MIButton
                        className={classes.okButton}
                        mi_size='fit'
                        mi_type='primary'
                        ariaLabel={t('aria_label_modal_delete_report_button')}
                        data-testid={'delete_icon'}
                        onClick={deleteReportOnClick}
                    >
                        {t('delete_report_dialog_ok_button')}
                    </MIButton>
                </div>
            </div>
            
        );
    };

    return (
        <div className={classes.actionIconContainer}>
            <ResponsiveComponent
                components={{
                    mobile: <MIMobileDialog
                                title={t('delete_report_dialog_title')}
                                content={getBody()}
                                open={isShowing}
                                handleClose={handleOnClose}
                                customStyle={{'containerWidth': '285px'}}/>
                    ,
                    desktop: <MIModal
                                header={getHeader()}
                                body={getBody()}
                                isShowing={isShowing}
                                onClose={handleOnClose}
                                customStyle={{'rootHeight': 'fit-content', 'rootWidth':'517px'}}/>
                }}
            />
            <MIApiLoader
                customStyle={{
                    'spinnerWidth': '20px',
                    'spinnerHeight': '20px',
                    marginTop: '2px',
                    marginLeft: '2px',
                    marginRight: '2px'
                }}
                MIComponent={
                    <div
                        className={classes.actionIconOuter}
                        tabIndex={0}
                        aria-label={t('aria_label_delete_report_button')}
                        onClick={handleOnClick}
                        data-testid={'close_icon'}
                    >
                        <CloseIcon
                            className={classes.actionIcon}
                        />
                        {t('delete_report')}
                    </div>
                }
                responseStatus={responseStatus}
                ariaLabelProgressBar={t('aria_label_progressbar_delete_report')}
            />
        </div>
    );
};

export default DeleteReport;
