import {useState, useEffect, useCallback} from 'react';
import {SUCCESS} from "../requests/requestReducer";
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";
import { MYRENTOKILSITEID } from '../../pages/Service/FloorPlan/FloorPlanEnums';

export const useOpenRecommendationsCount = () => {
    const [openRecommendationsCount, setOpenRecommendationsCount] = useState({});
    const [{responseStatus, response}, makeFloorPlanApiRequestWithParams] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getOpenRecommendationsCount';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setOpenRecommendationsCount(response.data.count);
            } catch (error) {
                console.error('useOpenRecommendations error: ', error);
                setOpenRecommendationsCount([]);
            }
        }
    }, [responseStatus, response, setOpenRecommendationsCount]);

    const getOpenRecommendationsCount = useCallback((siteId) => {
        if(siteId === MYRENTOKILSITEID.NO_ID){
            setOpenRecommendationsCount({});
            return;
        }
        setOpenRecommendationsCount({});
        const url = [endpoint, siteId].join('/');
        makeFloorPlanApiRequestWithParams(url).catch(error => {
            console.error('useOpenRecommendations error: ', error);
        });
        
    }, [makeFloorPlanApiRequestWithParams, setOpenRecommendationsCount]);

    return [openRecommendationsCount, responseStatus, getOpenRecommendationsCount];
};
