export const MONTH = [
    'jan',
    'feb',
    'mar',
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
];

export const PDF_VARIANT = 'PDF';

export const PEST_ACTIVITY_GRAPH_TYPES_ORDER = [ 'THIS_YEAR', 'AVERAGE' ];

export const PEST_ACTIVITY_GRAPH_TYPES = {
    THIS_YEAR: {
        NAME: 'thisYear',
        COLOR: '#f01c24',
        CHECKED: true
    },
    AVERAGE: {
        NAME: 'average',
        COLOR: '#ffc4cc',
        CHECKED: true
    },
};

export const PEST_ACTIVITY_TYPES_ORDER = [ 'INTERNAL', 'EXTERNAL' ];

export const PEST_ACTIVITY_TYPES = {
    INTERNAL: {
        NAME: 'internal',
        COLOR: '#ee1d24',
        CHECKED: true
    },
    EXTERNAL: {
        NAME: 'external',
        COLOR: '#007dc5',
        CHECKED: true
    }
};


export const YEAR_COMPARISON = {
    NAME: 'year_comparison',
    COLOR: '#5c6771',
    STYLE_COLUMN_TYPE: { role: 'style', type: 'string' }
};

export const PEST_TYPE_NAMES = {
    RODENTS: 'RODENTS',
    INSECTS: 'INSECTS',
    BIRDS: 'BIRDS',
};

export const GRAPH_PEST_TYPES = {
    RODENT: 'Rodent',
    INSECT: 'Insect',
    BIRD: 'Bird',
    FLYING_INSECT: 'Flying Insect'
};

export const RECOMMENDATIONS_RANGE = {
    ONE_WEEK: {
        NAME: '0-7',
        LESS_THAN: 8
    },
    ONE_MONTH: {
        NAME: '8-29',
        LESS_THAN: 30
    },
    ONE_MONTH_PLUS: {
        NAME: '30+'
    }
};

export const RECOMMENDATIONS_STATS_TYPES_ORDER = [ 'total', 'avgOpenDays' ];
export const RECOMMENDATIONS_STATS_TYPES_LABELS = {
    avgOpenDays: 'avg_open_days',
    total: 'total_open_recs'
};

export const RECOMMENDATIONS_GRAPH_TYPES_ORDER = [ 'NEW', 'PENDING' ];

export const RECOMMENDATIONS_GRAPH_TYPES = {
    NEW: {
        NAME: 'new',
        COLOR: '#f01c24',
    },
    PENDING: {
        NAME: 'pending',
        COLOR: '#ffa434',
    }
};

export const TASKS_GRAPH_TYPES_ORDER = [ 'OPEN' ];

export const TASKS_GRAPH_TYPES = {
    OPEN: {
        NAME: 'open_task',
        COLOR: '#f01c24',
    }
};

export const TASKS_RANGE = {
    ONE_WEEK: {
        NAME: '0-7',
        LESS_THAN: 8
    },
    ONE_MONTH: {
        NAME: '8-29',
        LESS_THAN: 30
    },
    ONE_MONTH_PLUS: {
        NAME: '30+'
    }
};

export const TASK_STATUS = {
    OPEN: 'Open',
    CLOSED: 'Closed'

};

export const NO_COLOR = '#fff';

export const RECOMMENDATIONS_COUNT_OPTIONS_ORDER = [ 'TOTAL', 'NEW', 'PENDING' ];
export const MAP_TYPE_OPTIONS_ORDER = [ 'BOTH', 'RECOMMENDATIONS', 'ACTIVITY' ];

export const RECOMMENDATIONS_COUNT_OPTIONS = {
    NAME: 'recommendationsCount',
    NEW: 'new',
    PENDING: 'pending',
    TOTAL: 'total'
};

export const RECOMMENDATION_STATUSES = {
    NEW: 'NEW',
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE'
};

export const MAP_TYPE_OPTIONS = {
    NAME: 'mapType',
    BOTH: 'both',
    RECOMMENDATIONS: 'recommendations',
    ACTIVITY: 'activity'
};

export const SAP_NOTE_IDS = {
    RECOMMENDATIONS: 'recommendations',
    REVIEW_ACTIONS: 'review_actions',
    PEST_OVERVIEW_RODENTS: 'pest_overview_rodents',
    PEST_OVERVIEW_INSECTS: 'pest_overview_insects',
    PEST_OVERVIEW_FLYING_INSECTS: 'pest_overview_flying_insects',
    PEST_OVERVIEW_BIRDS: 'pest_overview_birds',
    VISITS_OVERVIEW: 'visits_overview',
    HEAT_MAPS: 'heatmaps',
    TASKS: 'tasks'
};

export const SAP_VISIT_TYPES = {
    'ROUTINE': {
        TYPE: 'ROUTINE',
        NAME: 'routine',
        COLOR: '#3366cc'
    },
    'INSPECTION': {
        TYPE: 'INSPECTION',
        NAME: 'inspection',
        COLOR: '#dc3912',
    },
    'FOLLOW UP': {
        TYPE: 'FOLLOW UP',
        NAME: 'follow_ups',
        COLOR: '#FA892D',
    },
    'CALLOUT': {
        TYPE: 'CALLOUT',
        NAME: 'call_outs',
        COLOR: '#63AB1B'
    },
    'OTHER': {
        TYPE: 'OTHER',
        NAME: 'other',
        COLOR: '#D653E3',
    },
};

export const SAP_VISIT_TYPES_DISPLAY_ORDER = [
    'ROUTINE',
    'INSPECTION',
    'FOLLOW UP',
    'CALLOUT',
    'OTHER'
];

export const POINT_STYLING = 'point {stroke-width 2; stroke-color: white;}';
export const OPTION_ALL = 'ALL';
export const VISIT_TYPE = 'visitType';

export const SAP_SECTIONS = {
    VISITS_OVERVIEW: 'visitsOverview',
    PEST_OVERVIEW: 'pestOverview',
    RECOMMENDATIONS: 'recommendations',
    TASKS: 'tasks',
    HEATMAPS: 'heatmaps',
};
