import {makeAutoObservable} from "mobx";

class DevicesMappingStore {
    devicesMapping = {};

    constructor() {
        makeAutoObservable(this);
    }

    getDevicesMapping() {
        return this.devicesMapping;
    }

    setDevicesMapping(devicesMappingInput) {
        this.devicesMapping = devicesMappingInput;
    }

    reset() {
        this.devicesMapping = {};
    }
}

export {DevicesMappingStore};