export const FETCHING = 'API_REQUEST_FETCHING';
export const SUCCESS = 'API_REQUEST_SUCCESS';
export const ERROR = 'API_REQUEST_ERROR';
export const NOT_FOUND = 'API_REQUEST_NOT_FOUND';
export const UNAUTHORIZED = 'API_REQUEST_UNAUTHORIZED';

export const initialState = {
    responseStatus: null,
    response: null,
};

const requestReducer = (state = initialState, { type, response } = {}) => {
    switch (type) {
        case FETCHING:
            return { ...initialState, responseStatus: FETCHING };
        case SUCCESS:
            return { ...state, responseStatus: SUCCESS, response };
        case ERROR:
            return { ...state, responseStatus: ERROR, response };
        case NOT_FOUND:
            return { ...state, responseStatus: NOT_FOUND, response };
        case UNAUTHORIZED:
            return { ...state, responseStatus: UNAUTHORIZED, response };
        default:
            return state;
    }
};

export default requestReducer;