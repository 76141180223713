import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';
import { useNotificationsStore } from '../../contexts/StateProvider/StateProvider';

export const useSeenNotification = () => {
    const notificationsStore = useNotificationsStore();
    const [ seenNotificationCreated, setSeenNotificationCreated ] = useState([]);
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/seenNotification/create';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setSeenNotificationCreated(response.data.result);
                notificationsStore.setLastNotificationSeen();
            } catch (error) {
                console.error('useSeenNotification error: ', error);
                setSeenNotificationCreated([]);
            }
        }
    }, [ responseStatus, response, setSeenNotificationCreated, notificationsStore ]);

    const createSeenNotification = useCallback((notificationId) => {
        setSeenNotificationCreated([]);
        const params = { notificationId: notificationId };
        makeRegionalGCPRequestWithParams(endpoint, 'post', params).catch((error) => {
            console.error('useSeenNotification error: ', error);
        });
    }, [ makeRegionalGCPRequestWithParams, setSeenNotificationCreated ]);

    return [ seenNotificationCreated, responseStatus, createSeenNotification ];
};
