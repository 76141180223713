import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  spinnerContainer: {
    textAlign: 'center',
    alignItems: 'center',
  },
  spinner: props => ({
    color: props.color? props.color: theme.palette.primary.main,
    width: props.spinnerWidth? props.spinnerWidth+' !important' :'40px !important',
    height: props.spinnerHeight? props.spinnerHeight+'! important' :'40px !important',
    marginTop: props.marginTop? props.marginTop : '0px',
    marginLeft: props.marginLeft? props.marginLeft : '0px',
    marginRight: props.marginRight? props.marginRight : '0px',
    marginBottom: props.marginBottom? props.marginBottom : '0px'
  }),

}));

const MISpinner = (props) => {
  const classes = useStyles(props.customStyle);

  return (
    <div className={classes.spinnerContainer}>
      <CircularProgress className={classes.spinner} data-testid='spinner' aria-label={props.ariaLabelProgressBar}/>
    </div>
  );
};


export default MISpinner;