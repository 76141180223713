import React, { useEffect, useState } from 'react';
import MIGrid, { RenderTooltip } from '../../../components/Widgets/MIGrid';
import { FETCHING } from '../../../services/requests/requestReducer';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';
import { Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { isEmpty } from '../../../utils/utils';
import { HEADER_AND_FOOTER_PRODUCT_TABLE } from '../EntitlementProductDetailsTable.jsx';

const PAGINATION_SIZE = 56;
const ROW_HEIGHT = 58;
const ENTITLEMENT_PRODUCTS_PAGE_SIZE = 5;

const useStyles = makeStyles((theme) => {
    return {
        gridContainer: {
            'marginTop': theme.sizes.small_gap,
            '& div.MuiDataGrid-row': {
                '&:hover': {
                    // We use !important in MIGrid to set a background colour, so we must use it here to overide it
                    background: `${theme.colors.productTableRow} !important`
                }
            }
        },
        infoIcon: {
            display: 'flex',
            cursor: 'pointer',
            color: theme.palette.primary.main
        },
        infoIconDisabled: {
            display: 'flex',
            color: theme.palette.grey['400']
        },
        popoverText: {
            margin: theme.sizes.xsmall_gap
        },
        popover: {
            maxWidth: '50%'
        }
    };
});

const EntitlementProductDetailsTableDesktop = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { responseStatus, rows, setProductTableHeight } = props;
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ selectedRowId, setSelectedRowId ] = useState(null);

    useEffect(() => {
        let height = 0;
        const moreThanOnePage = rows.length > ENTITLEMENT_PRODUCTS_PAGE_SIZE;
        const rowsDisplaying = moreThanOnePage ? ENTITLEMENT_PRODUCTS_PAGE_SIZE : rows.length;
        height = rowsDisplaying * ROW_HEIGHT;
        // Add pagination size if displaying more than one page
        height = height + (moreThanOnePage ? PAGINATION_SIZE : 0);
        // Add header and footer height
        height = height + HEADER_AND_FOOTER_PRODUCT_TABLE;

        setProductTableHeight(height);
    }, [ rows, setProductTableHeight ]);

    const handleClick = (event, selectedRowId) => {
        setSelectedRowId(selectedRowId);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const columns = [
        {
            field: 'infoIcon',
            headerName: ' ',
            type: 'string',
            resizable: false,
            flex: 0.5,
            renderCell: (params) => {
                return <div>
                    {params.rowIndex === selectedRowId && <Popover
                        className={classes.popover}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <Typography className={classes.popoverText}>
                            {params.row.visitNote}
                        </Typography>
                    </Popover>}
                    <InfoIcon className={!isEmpty(params.row.visitNote) ? classes.infoIcon : classes.infoIconDisabled}
                        onClick={!isEmpty(params.row.visitNote) ? (event) => {
                            return handleClick(event, params.rowIndex);
                        } : null}/>
                </div>;
            }
        },
        {
            field: 'visitDate',
            headerName: t('date'),
            description: t('date'),
            type: 'date',
            resizable: false,
            flex: 1.5,
            renderCell: (params) => {
                return <span>{params.row.visitLocDate}</span>;
            }
        },
        {
            field: 'visitType',
            headerName: t('type'),
            description: t('type'),
            type: 'string',
            resizable: false,
            flex: 1,
            renderCell: RenderTooltip
        },
        {
            field: 'productDescription',
            headerName: t('description'),
            description: t('description'),
            type: 'string',
            resizable: false,
            flex: 4,
            renderCell: RenderTooltip
        },
        {
            field: 'servedQuantity',
            headerName: t('served_quantity'),
            description: t('served_quantity'),
            type: 'string',
            resizable: false,
            flex: 1,
        },
        {
            field: 'credit',
            headerName: t('credit'),
            description: t('credit'),
            type: 'string',
            resizable: false,
            flex: 1,
        },
        {
            field: 'debit',
            headerName: t('debit'),
            description: t('debit'),
            type: 'string',
            resizable: false,
            flex: 1,
        },
        {
            field: 'balance',
            headerName: t('balance'),
            description: t('balance'),
            type: 'string',
            resizable: false,
            flex: 1,
        },
    ];

    return (
        <div className={classes.gridContainer}>
            <MIGrid
                showInfoModal={false}
                isLoading={responseStatus === null || responseStatus === FETCHING}
                pagination={true}
                columns={columns}
                rows={rows}
                noElementsText={t('no_product_details')}
                autoheight
                ariaLabel={t('aria_label_entitlement_product_details')}
                pageSize={ENTITLEMENT_PRODUCTS_PAGE_SIZE}
                hideFooterForOnePage={true}
                heightToFitContent={true}
                ariaLabelProgressBar={t('aria_label_progressbar_entitlement_product_details')}
                noEvenRowStyling={true}
                hideFooterSelectedRowCount={true}
            />
        </div>
    );
};

export default EntitlementProductDetailsTableDesktop;
