import React, { memo } from 'react';
import { isObjectEmpty } from '../utils/utils';
import Location from './Location';
import Cluster, { CLUSTER_ICON_SIZE } from './Cluster';
import { isEmpty } from '../utils/utils';
import { getClusterId, isVisible, scalePosition } from '../utils/clusters';

const LocationsLayerImpl = (props) => {
    const { points, clusters, scale, width, height, translation, handleDetectorClick, showLabels, selectedId } = props;

    const getClusters = () => {
        if (isObjectEmpty(clusters)) {
            return [];
        }

        let all = [];
        clusters.singles.forEach((index) => {
            if (points[index] && isVisible(points[index].coordinates, scale, translation, width, height)) {
                all.push(
                    <Location
                        key={points[index].id}
                        properties={index}
                        label={showLabels && points[index].label}
                        component={points[index].component}
                        coordinates={scalePosition(points[index].coordinates, points[index].centre, scale, translation)}
                        centre={points[index].centre}
                        handleDetectorClick={handleDetectorClick}
                        isSelected={selectedId && selectedId === points[index].id}
                    />
                );
            }
        });
        clusters.clusters.forEach((cluster, clusterIndex) => {
            let hasSelection = false;
            const midPoint = clusters.midpoints[clusterIndex];
            if (isVisible(midPoint, scale, translation, width, height)) {
                const ids = cluster.map((index) => {
                    if (!isEmpty(points) && points[index]?.id === selectedId) {
                        hasSelection = true;
                    }
                    return index;
                });
                all.push(
                    <Cluster
                        key={getClusterId('cluster', midPoint)}
                        count={cluster.length}
                        midPoint={scalePosition(midPoint, { x: CLUSTER_ICON_SIZE / 2, y: CLUSTER_ICON_SIZE / 2 }, scale, translation)}
                        ids={ids}
                        scale={scale}
                        translation={translation}
                        hasSelection={hasSelection}
                    />
                );
            }
        });
        return all;
    };


    return (
        <div>
            {getClusters()}
        </div>
    );
};

export const LocationsLayer = memo(LocationsLayerImpl);
