import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const useStyles = makeStyles(() => ({
    sliderRoot: (props) => ({
        'position': 'absolute',
        'bottom': '15%',
        'marginLeft': props.marginLeft ? props.marginLeft : null,
        '&.MuiSlider-vertical': {
            height: '70%',
        }
    }),
}));

const MISlider = (props) => {
    const { marks, min, max, value, onChange, sliderCustomStyle } = props;
    const classes = useStyles(sliderCustomStyle);

    function valuetext(value) {
        return value;
    }

    function valueLabelFormat() {
        return '';
    }

    const StyledSlider = styled(Slider)({
        color: sliderCustomStyle.color ? sliderCustomStyle.color : null,
    });

    return (
        <StyledSlider
            value={value}
            getAriaValueText={valuetext}
            valueLabelFormat={valueLabelFormat}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            orientation="vertical"
            step={10}
            marks={marks}
            min={min}
            max={max}
            classes={{ root: classes.sliderRoot }}
            onChangeCommitted={onChange}
        />
    );
};

export default MISlider;
