import moment from 'moment';
import PostalAddress from 'i18n-postal-address';
import countryCurrencyMap from './currency';
import qs from 'qs';

const SECONDS_PER_HOUR = 3600;
const MILLISECONDS_PER_SECOND = 1000;

export const FALLBACK_LANGUAGES = { en: 'en-GB' };

export function getBool(val) {
    let num = Number(val);
    if (val === undefined) {
        return false;
    }
    return !isNaN(num) ? !!num : !!String(val).toLowerCase().replace(!!0, '');
}

export function isEmpty(obj) {
    if (obj === undefined || obj === null) {
        return true;
    }

    if (typeof obj === 'string') {
        return obj.trim() === '';
    }

    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({}) ||
        JSON.stringify(obj) === JSON.stringify([]);
}

export function addTime(date, hours, minutes) {
    return moment(date).add(hours, 'hours').add(minutes, 'minutes').toDate();
}

export function getGMTOffsetDateFromString(dateString, format) {
    // This is a work around for issues with the XGrid filter.
    // The local timezone is applied to the date object internally within XGrid
    // when it compares two dates. This means that the 'is' filter option fails.
    // We have to actually adjust the time to compensate.
    // Hopefully, in a future iteration of the component, we can remove this adjustment
    const dateFormat = format || 'DD/MM/YYYY';
    const dateObject = moment(dateString, dateFormat).toDate();
    const userTimezoneOffset = dateObject.getTimezoneOffset() * 60000;
    return new Date(dateObject.getTime() - userTimezoneOffset);
}

export function getDateFromString(dateString, format) {
    const dateFormat = format || 'DD/MM/YYYY';
    return moment(dateString, dateFormat).toDate();
}

export function getISOOffsetDate(inputDate) {
    const offset = inputDate.getTimezoneOffset();
    const newDate = new Date(inputDate.getTime() - (offset * 60 * 1000));
    return newDate.toISOString().split('T')[0];
}

export function getTime(dateString, timeString) {
    let timeParts = timeString.split(':');
    let startTime = null;
    if (timeParts.length === 2) {
        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        if (hours + minutes > 0) {
            const startTime = addTime(getDateFromString(dateString), hours, minutes);
            return startTime;
        }
    }
    return startTime;
}

export function isToday(date, format) {
    // Returns true if it is today or false if it's not
    date = moment(date, format);
    return moment(date).isSame(moment(), 'day');
}

export function getLocalizedTime(t, dateString, timeString) {
    const time = getTime(dateString, timeString);
    return time !== null ? t('localised_time', { time: time }) : '-';
}

export function getLocalizedDate(t, dateString, dateFormat) {
    const date = getDateFromString(dateString, dateFormat);
    return t('localised_date', { today: date });
}

export function getLocalizedStatus(t, status){
    return t(status.toLowerCase());
}

export function getLocalizedDateFormatString(t) {
    // Use i18n as a single source of truth for the date formatting.
    // i18n doesn't have a built in feature for returning date format.
    // so we are using a fixed date to retrieve the correct format.
    const dateString = getLocalizedDate(t, '1970/12/31', 'YYYY/MM/DD');
    return dateString.replace('1970', 'YYYY').replace('12', 'MM').replace('31', 'DD');
}

export const findArrayIndex = (array, callback) => {
    const length = array == null ? 0 : array.length;
    if (!length) {
        return -1;
    }
    let index = -1;
    for (let i = 0; i < array.length; ++i) {
        if (callback(array[i])) {
            index = i;
            break;
        }
    }
    return index;
};

export const formatDate = (date, inputFormat, outputFormat) => {
    try {
        const originalDateFormat = inputFormat || 'DD/MM/YYYY';
        const dateAPIFormat = outputFormat || 'YYYY/MM/DD';
        let dataString = formatStringDate(date, originalDateFormat, dateAPIFormat);
        dataString = dataString.replace(/\//g, '-');
        return dataString;
    } catch (error) {
        console.error(error);
        return '';
    }
};

export const getLocaleSections = (locale) => {
    let language = locale;
    let country = '';
    if (!isEmpty(locale) && locale.includes('-')) {
        const parts = locale.split('-');
        language = parts[0];
        country = parts[1];
    }

    return [ language, country ];
};

export const base64Encode = (_str) => {
    return Buffer.from(_str).toString('base64');
};

export const getHost = (url) => {
    const protocolRegex = /(^\w+:|^)\/\//;
    return !isEmpty(url) ? url.trim().replace(protocolRegex, '') : null;
};

export const getCurrentHost = () => {
    return !isEmpty(window) && !isEmpty(window.location) ? getHost(window.location.origin) : '';
};

export const setDocumentTitle = (t, title) => {
    const appTitle = getCurrentHost() === 'mycalmic.co.id' ? 'myCalmic' : t('app_title');
    document.title = title ? [ appTitle, t(title) ].join(' - ') : appTitle;
};

export const extractBaseURL = (url = '') => {
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').replace(/\/+$/, '');
};

export const validatePassword = (value) => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*=~_|])');
    return value.length >= 8 && value.length <= 15 && strongRegex.test(value);
};

export const getSiteAddress = (_premise, countryCode) => {
    const address = new PostalAddress();
    address
        .setAddress1(_premise.siteAddress1)
        .setCity(_premise.siteAddress4)
        .setPostalCode(_premise.premisePinCode)
        .setFormat({
            country: countryCode,
            type: 'default',
        });
    return address.output().flat().join(', ').replace(',,', ',');
};

export const formatStringDate = (date, inputFormat, outputFormat) => {
    return moment(date, inputFormat).locale('en').format(outputFormat);
};

export const getFormattedCurrency = (value, locale, country) => {
    const currency = countryCurrencyMap[country];
    if (!isEmpty(locale) && !isEmpty(currency)) {
        const formatter = Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
        });
        return formatter.format(value);
    }
    return value;
};

export const getRouteIndex = (fullPath, routes) => {
    let path = fullPath;
    let index = routes.findIndex((route) => {
        return route.path === path;
    });
    while (path !== '' && index === -1) {
        const parent = path.substring(0, path.lastIndexOf('/'));
        index = findArrayIndex(routes, (route) => {
            return route.path === parent;
        });
        path = parent;
    }
    return index === -1 ? 0 : index;
};

export const mergeStyles = (...styles) => {
    return styles.join(' ');
}
;

export const getTopRoute = (routes, currentLocation) => {
    return routes.find((route) => {
        return route.path.substring(1) === currentLocation.split('/', 2)[1];
    }
    );
};

export const getSessionTimeout = () => {
    const sessionTimeout = parseInt(
        process.env.REACT_APP_SESSION_TIMEOUT, 10) || SECONDS_PER_HOUR;
    return sessionTimeout * MILLISECONDS_PER_SECOND;
};

export const textContent = (elem) => {
    if (typeof elem === 'string') {
        return elem;
    }

    if (elem instanceof Array) {
        let content = elem.map(textContent).join('');
        return content;
    }

    if (elem instanceof Object && elem.props) {
        const children = elem.props.children;
        return textContent(children);
    }

    return '';
};

export const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
};

export const setCookie = (name, value, maxAge) => {
    let date = new Date();
    date.setTime(date.getTime() + maxAge);
    const expires = `expires=${date.toUTCString()}`;
    const secure = window.location.protocol === 'https:' ? 'secure' : '';
    document.cookie = `${name}=${value}; ${expires}; path=/; SameSite=Lax; ${secure}`;
};

const createScript = (type, src, content, scriptParams = []) => {
    const script = document.createElement(type);
    script.type = 'text/javascript';
    if (src !== null) {
        script.src = src;
        scriptParams.forEach((param) => {
            return script[param.key] = param.value;
        });
    }
    if (content !== null) {
        script.innerHTML = content;
    }
    return script;
};

export const addScript = (type, src, content, scriptParams = []) => {
    const script = createScript(type, src, content, scriptParams);
    document.head.appendChild(script);
};

export const addScriptWithPromise = (type, src, content, scriptParams = []) => {
    return new Promise((resolve, reject) => {
        const script = createScript(type, src, content, scriptParams);
        script.addEventListener('load', () => {
            resolve();
        });
        script.addEventListener('error', (e) => {
            reject(e);
        });
        document.head.appendChild(script);
    });
};


export const getQueryString = () => {
    return qs.parse(
        window.location.search,
        { ignoreQueryPrefix: true }
    );
};

export const removeSSOParams = () => {
    let params = getQueryString();
    delete params.token;
    delete params.redirectURL;
    return qs.stringify(params);
};

export const containsEmpty = (array) => {
    return array.some((el) => {
        return isEmpty(el);
    });
};

export const formatNumber = (value) => {
    return Math.trunc(value).toLocaleString();
};

export const getSiteKey = (contractNumber, premiseNumber) => {
    return `${contractNumber}_${premiseNumber}`;
};
