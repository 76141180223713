import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCommonStyles } from './commonStyles';
import { makeStyles } from '@material-ui/core';
import MIDropDown from '../../widgets/MIDropDown';
import MITextField from '../../widgets/MITextField';
import { isObjectEmpty } from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    buildingSelectionOuter: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const getValidFloors = (floors, floorFormat) => {
    const validFloorFilter = (floor) => !isObjectEmpty(floor.imageRef) && !isObjectEmpty(floor.imageRef.latestFileVersion);
    if (!isObjectEmpty(floors)) {
        const filteredElements = floors.filter(validFloorFilter);
        return filteredElements.map((floor) => floorFormat(floor));
    }
    return [];
};

export const getFloorOptions = (floors) => {
    const floorFormat = (floor) => ({
        label: floor.description,
        value: floor.imageRef.latestFileVersion.fileId,
        floorId: floor.id,
        creationDate: floor.imageRef.creationDate,
        version: floor.imageRef.latestFileVersion.version
    });
    return getValidFloors(floors, floorFormat);
};

export const getFloorIdOptions = (floors) => {
    const floorFormat = (floor) => ({
        label: floor.description,
        value: floor.id,
        building: floor.building.id,
    });
    return getValidFloors(floors, floorFormat);
};

const FloorSelector = (props) => {
    const {t} = useTranslation();
    const {floors, control, onFloorChange, disabled, width} = props;
    const commonStyles = useCommonStyles();
    const classes = useStyles();

    return (
        <div className={classes.buildingSelectionOuter}>
            <label className={commonStyles.textFieldLabel}> {t('floor')}: </label>
            <MIDropDown
                width={width}
                control={control}
                dropDownProps={{
                    renderInput: (params) =>
                        <MITextField
                            {...params}
                            ariaLabel={t('aria_label_floors')}
                            InputProps={{...params.InputProps}}
                            placeholder={t('please_select')}
                            hideBorder={true}
                        />,
                    noOptionsText: props.noOptionsText
                }}
                defaultValue={null}
                disableClearable={true}
                name={'floors'}
                ariaLabel={t('aria_label_floors')}
                options={getFloorOptions(floors)}
                noOptionsText={t(' no_floors')}
                onHandleChange={onFloorChange}
                dataTestId={'floorsAutoComplete'}
                disabled={disabled}
            />
        </div>
    );
};

export default FloorSelector;
