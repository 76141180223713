import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {isEmpty} from "../../utils/utils";
import { useResponsive } from "../../services/useResponsive";

const desktopStyles = theme => {
    return {
        title: {
            margin: theme.sizes.no_gap,
            display: 'inline-block',
            '&:focus': theme.ariaFocus,
        },
        titleOuter: {
            display: "flex",
            marginTop: props => !isEmpty(props.marginTop) ? props.marginTop : theme.sizes.tiny_gap,
            marginBottom: props => !isEmpty(props.marginBottom) ? props.marginBottom : theme.sizes.medium_gap,
            height: theme.sizes.xxlarge_gap,
            alignItems: "center",
            justifyContent: "center-left",
            width: "100%",
        }
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        title: {
            ...baseStyle.title,
            fontSize: theme.fontSizes.mobileTitle
        },
        titleOuter: {
            ...baseStyle.titleOuter,
            marginBottom: props => !isEmpty(props.marginBottom) ? props.marginBottom : theme.sizes.micro_gap,
            height: theme.sizes.large_gap,
            marginLeft: props => !isEmpty(props.marginLeft) ? props.marginLeft : '',
        },
    })
};

const MIPageTitle = (props) => {
    const {title, ariaLabel} = props;
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles(props);

    return (
        <div className={classes.titleOuter} >
            <h1
                className={classes.title}
                aria-label={ariaLabel}
                tabIndex={0}
                aria-live={'polite'}
            >
                {title}
            </h1>
        </div>
    );
};

export default MIPageTitle;