import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import PrintReport from '../PrintReport';
import DownloadWasteNote from '../DownloadWasteNote';
import { Link } from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MIGrid, { STYLE_HEADER_CENTER, RenderTooltip, CLICKABLE_ICON_CELL, STYLE_HEADER_CENTER_OVERFLOW } from '../../Widgets/MIGrid';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { observer } from 'mobx-react-lite';
import { useFlags } from '../../../contexts/FlagsProvider/FlagsProvider';
import MIAlert from '../../../components/Widgets/MIAlert';
import { getStateFromRow, useBaseStyles } from '../VisitHistory';

const useStyles = makeStyles((theme) => {
    return {
        signatureIcon: {
            margin: 'auto',
            textAlign: 'center',
            color: 'grey',
        },
        root: {
            '& .MuiDataGrid-colCellTitle': {
                overflow: 'visible',
                lineHeight: '1rem',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                textAlign: 'left',
                fontFamily: theme.fontFamily.proximaBold
            },
        }
    };
});

const VisitHistoryDesktop = observer((props) => {
    const { t } = useTranslation();
    const { feature_hazardous_waste_consignment_notes } = useFlags();
    const baseStyles = useBaseStyles();
    const classes = { ...baseStyles, ...useStyles() };
    const [ isShowing, setIsShowing ] = useState(false);
    const [ alert, setAlert ] = useState({});
    const { feature_service_receipt } = useFlags();
    const { handleCellClick } = props;

    const columns = [
        {
            field: 'visitDate', headerName: t('visit_date'), type: 'date', resizable: false, flex: 2.2,
            description: t('visit_date'),
            renderCell: (params) => {
                return <span>{params.row.locDate}</span>;
            }

        },
        {
            field: 'visitTime',
            headerName: t('visit_time'),
            type: 'datetime',
            resizable: false,
            width: 110,
            description: t('visit_time')
        },
        {
            field: 'servicedBy',
            headerName: t('serviced_by'),
            type: 'string',
            resizable: false,
            flex: 3,
            description: t('serviced_by'),
            renderCell: RenderTooltip
        },
        {
            field: 'visitType',
            headerName: t('visit_type'),
            type: 'string',
            resizable: false,
            flex: 2,
            description: t('visit_type'),
            renderCell: RenderTooltip
        },
        {
            field: 'signature',
            headerName: t('signature'),
            type: 'string',
            resizable: false,
            filterable: false,
            flex: 2,
            headerClassName: STYLE_HEADER_CENTER,
            description: t('signature'),
            renderCell: (params) => {
                return params.row.signature === true ? <CheckIcon className={classes.signatureIcon}/> :
                    <ClearIcon className={classes.signatureIcon}/>;
            }

        },
        {
            field: 'visitDetails', headerName: t('details'), resizable: false, filterable: false, flex: 2,
            sortable: false,
            headerClassName: STYLE_HEADER_CENTER,
            cellClassName: CLICKABLE_ICON_CELL,
            description: t('details'),
            renderCell: (params) => {
                return <div className={classes.linkContainer}>
                    <Link className={classes.selectLink} to={{
                        pathname: '/service/visit-history/visit-details',
                        state: { data: getStateFromRow(params.row) }
                    }}
                    aria-label={t('aria_label_visit_history_button')}>
                        <AssignmentIcon/>
                    </Link>
                </div>;
            }
            ,
        }
    ];

    if (feature_service_receipt) {
        columns.push(
            {
                field: 'printReport', headerName: t('print_report'), resizable: false, filterable: false, flex: 2,
                sortable: false,
                headerClassName: STYLE_HEADER_CENTER_OVERFLOW,
                cellClassName: CLICKABLE_ICON_CELL,
                description: t('print_report'),
                renderCell: (params) => {
                    return <div className={classes.linkContainer}>
                        <PrintReport
                            premiseVisitNumber={params.row.visitReport.PremiseVisitNumber}
                            visitDate={params.row.visitReport.VisitDate}
                            locDate={params.row.locDate}
                            setAlert={setAlert}
                            setIsShowing={setIsShowing}
                            isIcon={true}
                        />
                    </div>;
                }
                ,
            }
        );
    }

    if (feature_hazardous_waste_consignment_notes) {
        columns.push(
            {
                field: 'wasteNotes', headerName: t('waste_notes'), resizable: false, filterable: false, flex: 2.2,
                sortable: false,
                headerClassName: STYLE_HEADER_CENTER_OVERFLOW,
                cellClassName: CLICKABLE_ICON_CELL,
                description: t('waste_notes'),
                renderCell: (params) => {
                    return <div className={classes.linkContainer}>
                        <DownloadWasteNote
                            {...getStateFromRow(params.row)}
                            setAlert={setAlert}
                            setIsShowing={setIsShowing}
                        />
                    </div>;
                }
                ,
            },
        );
    }

    return (
        <div className={classes.root}>
            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                secondaryMessage={t(alert.secondaryMessage)}
                severity={alert.severity}
                ariaLabel={t('aria_label_operation_response_dialog')}
            />
            <MIGrid
                showInfoModal={true}
                heightToFitContent={true}
                colCellFlex={true}
                columns={columns}
                noElementsText={t('no_visits')}
                ariaLabel={t('aria_label_visit_history_table')}
                ariaLabelProgressBar={t('aria_label_progressbar_visit_history')}
                onCellClick={handleCellClick}
                hasFilters={true}
                hideFooterRowCount={true}
                pagination={true}
                {...props}
            />
        </div>
    );
});

export default VisitHistoryDesktop;
