import TextField from '@material-ui/core/TextField';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useResponsive } from '../../services/useResponsive';

const useStyles = makeStyles((theme) => {
    return {
        textField: {
            'display': 'flex',
            'width': theme.widths.desktop_textField,
            'height': theme.sizes.xxxlarge_gap,
            '& fieldset': {
                borderRadius: '0px',
                border: theme.borders.listBorders,
            },
        },
        inputProps: {
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    boxShadow: 'rgba(0,125,197,0.25) 0px 1px 9px 1px',
                }
            },
            '&.Mui-error': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.dark,
                }
            },
        },
    };
});

const desktopHelperTextStyles = (theme) => {
    return {
        error: {
            '&.MuiFormHelperText-root.Mui-error': {
                fontSize: theme.typography.small_error.fontSize,
                lineHeight: '1.1em',
                marginTop: '4px',
                marginLeft: '0px',
                marginRight: '0px',
                color: theme.palette.error.dark,
            }
        }
    };
};

const mobileHelperTextStyles = (theme) => {
    const baseStyle = desktopHelperTextStyles(theme);

    return Object.assign(baseStyle, {
        error: {
            '&.MuiFormHelperText-root.Mui-error': {
                ...baseStyle.error['&.MuiFormHelperText-root.Mui-error'],
                fontSize: theme.fontSizes.mobileLabel,
            }
        }
    });
};

const MITextField = ({ className, ariaLabel, ...props }) => {
    const classes = useStyles();
    const { getStyles } = useResponsive();

    const useHelperTextStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopHelperTextStyles(theme),
            mobile: mobileHelperTextStyles(theme)
        });
    }
    );
    const helperTestClasses = useHelperTextStyles();

    let compoundClassName = className ? `${classes.textField } ${ className}` : classes.textField;
    return (
        <>
            <TextField
                className={compoundClassName}
                FormHelperTextProps={{ classes: helperTestClasses, role: 'alert' }}
                InputProps={{ className: classes.inputProps }}
                inputProps={{ 'aria-label': ariaLabel }}
                variant="outlined"
                {...props}
                aria-live={'polite'}
            />
        </>
    );
};

export default MITextField;
