import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import HistoryIcon from '@material-ui/icons/History';
import { Link } from 'react-router-dom';
import MIGrid, { RenderTooltip, STYLE_HEADER_CENTER_OVERFLOW } from '../../../components/Widgets/MIGrid';
import { observer } from 'mobx-react-lite';
import { isEmpty } from '../../../utils/utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
    return {
        linkContainer: {
            margin: 'auto',
            textAlign: 'center',
        },
        siteSelectLink: {
            'display': 'flex',
            '&, &:visited, &:hover': {
                color: theme.palette.primary.main
            },
            '&:focus': theme.ariaFocus,
        },
        title: {
            'marginTop': theme.sizes.xlarge_gap,
            'display': 'inline-block',
            '&:focus': theme.ariaFocus,
        },
        titleOuter: {
            display: 'flex',
            height: theme.sizes.xxxlarge_gap,
            alignItems: 'center',
            justifyContent: 'center-left',
            width: '100%',
            marginBottom: '20px',
        },
    };
});

const SiteGridDesktop = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const gridColumns = [];
    const {
        showDesktopInfoModal,
        siteListDesktopHeader,
        hideContractNo,
        hidePremiseNo,
        hideLastVisit,
        hideVisitHistoryLink,
        gridRows,
        isLoading,
        selectionComponent,
        siteStore,
        desktopRowHeight } = props;

    gridColumns.push({
        field: 'customer',
        headerName: t('customer'),
        description: t('customer'),
        type: 'string',
        resizable: false,
        flex: 2,
        renderCell: RenderTooltip
    });

    gridColumns.push({
        field: 'address',
        headerName: t('premise_address'),
        description: t('premise_address'),
        type: 'string',
        resizable: false,
        flex: 3,
        renderCell: RenderTooltip
    });

    if (!hideContractNo) {
        gridColumns.push({
            field: 'contractNo',
            headerName: t('contract_no'),
            description: t('contract_no'),
            type: 'string',
            resizable: false,
            flex: 2
        });
    }

    if (!hidePremiseNo) {
        gridColumns.push({
            field: 'premiseNo',
            headerName: t('premise_no'),
            description: t('premise_no'),
            type: 'number',
            resizable: false,
            width: 160
        });
    }

    if (!hideLastVisit) {
        gridColumns.push({
            field: 'lastVisit',
            headerName: t('last_visit'),
            description: t('last_visit'),
            type: 'date',
            resizable: false,
            flex: 2,
            renderCell: (params) => {
                return <span>{!isEmpty(params.row.locDate) ? params.row.locDate : '-'}</span>;
            }


        });
    }

    const viewSiteHistory = (siteId) => {
        siteStore.setSelectedSiteId(siteId);
        history.push('/service/visit-history');
    };

    if (!hideVisitHistoryLink) {
        gridColumns.push({
            field: 'visitHistory',
            headerName: t('visit_history'),
            description: t('visit_history'),
            headerClassName: STYLE_HEADER_CENTER_OVERFLOW,
            resizable: false,
            filterable: false,
            sortable: false,
            flex: 1.5,
            renderCell: (params) => {
                return <div className={classes.linkContainer}>
                    <Link to="#"
                        onClick={(e) => {
                            e.preventDefault();
                            viewSiteHistory(params.value);
                        }} className={classes.siteSelectLink}
                        aria-label={t('aria_label_visit_history_button')}>
                        <HistoryIcon/>
                    </Link>
                </div>;
            }

        });
    }

    const handleRowClick = (selectedRow) => {
        if(!props.hideVisitHistoryLink) {
            viewSiteHistory(selectedRow.data.id);
        }
    };

    return (
        <div>
            {!isEmpty(siteListDesktopHeader) && siteListDesktopHeader}
            <div>
                <MIGrid
                    showInfoModal={!isEmpty(showDesktopInfoModal) ? showDesktopInfoModal : false}
                    heightToFitContent={true}
                    isLoading={isLoading}
                    columns={gridColumns}
                    colCellFlex={true}
                    rows={gridRows}
                    rowHeight={!isEmpty(desktopRowHeight) && desktopRowHeight}
                    noElementsText={t('no_sites')}
                    hasFilters={true}
                    pagination={true}
                    autoHeight={true}
                    ariaLabel={t('aria_label_site_list_table')}
                    ariaLabelProgressBar={t('aria_label_progressbar_site_grid')}
                    onRowSelected={handleRowClick}
                    hideFooterForOnePage={true}
                    {...selectionComponent}
                    {...props}
                />
            </div>
        </div>

    );
});

export default SiteGridDesktop;
