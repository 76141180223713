import { useCallback } from 'react';
import { useVisitHistory } from './useVisitHistory';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import { useVisitHistoryKony } from './useVisitHistoryKony';

// remove this class in ACD-8571
export const useVisitHistoryByFlag = () => {
    const [ visitsGCP, responseStatusGCP, getVisitHistoryGCP ] = useVisitHistory();
    const [ visitsKony, responseStatusKony, getVisitHistoryKony ] = useVisitHistoryKony();

    const { feature_visit_gcp } = useFlags();
    const visits = feature_visit_gcp ? visitsGCP : visitsKony;
    const responseStatus = feature_visit_gcp ? responseStatusGCP : responseStatusKony;

    const getVisitHistory = useCallback((siteId, contractNumber, premiseNumber) => {
        if (feature_visit_gcp === true) {
            getVisitHistoryGCP(siteId, contractNumber, premiseNumber);
        } else if (feature_visit_gcp === false) {
            getVisitHistoryKony(siteId);
        }
    }, [ getVisitHistoryGCP, getVisitHistoryKony, feature_visit_gcp ]);


    return [ visits, responseStatus, getVisitHistory ];
};
