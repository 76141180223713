import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import MIGrid from '../../../../components/Widgets/MIGrid';
import MIAccordion from '../../../../components/Widgets/MIAccordion';
import useModal from '../../../../services/useModal';
import MIInfoDialog from '../../../../components/Widgets/mobile/MIInfoDialog';
import { isEmpty } from '../../../../utils/utils';

const useStyles = makeStyles((theme) => {
    return {
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
            borderBottom: 'unset',
        },
        grid: {
            borderTop: theme.borders.outline_regular,
        }
    };
});

const ProductTableMobile = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { isShowing, changeStatus } = useModal();
    const [ info, setInfo ] = useState([]);
    const { groupName, columns, rows, hideFooterForOnePage, pageSize, getDataFromRow, isLoading } = props;

    const handleRowClick = (selectedRow) => {
        if (!isShowing && !isEmpty(selectedRow.data)) {
            const data = getDataFromRow(selectedRow);
            setInfo(data);
            changeStatus();
        }
    };

    return (
        <>
            <MIAccordion
                className={classes.ul}
                aria-label={t('aria_label_folder_name')}
                header={groupName}
                id={groupName}
                hideBottomBorder={!isEmpty(hideFooterForOnePage) && !isEmpty(rows) ? hideFooterForOnePage && rows.length <= pageSize : false}
                content={
                    <div className={classes.grid}>
                        <MIGrid
                            showInfoModal={true}
                            columns={columns}
                            rows={rows}
                            noElementsText={t('no_product')}
                            ariaLabel={`${t('aria_label_contract_table_named') } ${ groupName}`}
                            hasFilters={false}
                            pagination={!isEmpty(rows)}
                            autoPageSize={false}
                            headerHeight={0}
                            hideFooter={false}
                            noEvenRowStyling={true}
                            noDataGridPadding={true}
                            heightToFitContent={true}
                            hideFooterForOnePage={hideFooterForOnePage}
                            pageSize={pageSize}
                            waitFullyLoaded={true}
                            showRowCount={true}
                            onRowSelected={handleRowClick}
                            isLoading={isLoading}
                            {...props}
                        />
                    </div>
                }
            />
            {<MIInfoDialog
                title={t('details')}
                info={info}
                content={null}
                open={isShowing}
                handleClose={changeStatus}
            />}
        </>
    );
});

export default ProductTableMobile;
