import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {isEmpty} from "../../utils/utils";
import {sanitize} from 'dompurify';
import {parse, stringify} from "css";
import parseHtml from 'html-react-parser';
import DOMPurify from 'dompurify';
DOMPurify.setConfig({ ADD_ATTR: ['target'] });

const useStyles = makeStyles(theme => ({
    container: {},
}));

const MICmsContainer = (props) => {

    const prependCss = (cssText) => {
        let newCSS = '';
        const cssObj = parse(cssText);
        if (!isEmpty(cssObj) && cssObj.type === 'stylesheet' && !isEmpty(cssObj.stylesheet)) {
            cssObj.stylesheet.rules.forEach(element => {
                if (element.type === 'rule') {
                    element.selectors = element.selectors.map(selector => {
                        return 'div.' + classes.container + ' ' + selector;
                    });
                }
            });
            newCSS = stringify(cssObj) + '\n\n';
        }
        return newCSS;
    }

    const classes = useStyles();
    let content = '';
    let newCSS = '';
    if (!isEmpty(props.html)) {
        const doc = new DOMParser().parseFromString(props.html, 'text/html');
        const styles = doc.getElementsByTagName('style');
        const links = doc.getElementsByTagName('a');
        for(let i=0; i < links.length; i++){
            doc.getElementsByTagName('a')[i].setAttribute('rel', 'noopener noreferrer');
        }

        for (let style of styles) {
            newCSS += prependCss(style.firstChild.data);
        }
        content = parseHtml(sanitize(doc?.body?.innerHTML));
    }

    return (
        <div className={classes.container}>
            <style>{newCSS}</style>
            {content}
        </div>
    );
}

export default MICmsContainer;
