import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {useTranslation} from 'react-i18next';
import {getBool} from '../../utils/utils';

const useStyles = makeStyles(theme => ({
    menuIcon: {
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.primary.main,
    },
    menuContainer: {
        alignItems: 'center',
        margin: 'auto',
    },
}));

const MIActionMenu = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {menuItems, anchorEl, setAnchorEl, closeActionsMenu} = props;

    const handleClick = (event) => {
        if (event.currentTarget !== anchorEl) {
            setAnchorEl(event.currentTarget);
        }
    };

    const getMenuItems = () => {
        let items = [];
        menuItems.forEach((menuItem) => {
            items.push(
                <MenuItem key={menuItem.key}>
                    {menuItem}
                </MenuItem>
            );
        });
        return items;
    }
  
    return (
        <div className={classes.menuContainer}>
            <IconButton
                aria-label={t('aria_label_action_menu_icon')}
                data-testid={'action_menu_icon'}
                className={classes.menuIcon}
                onClick={handleClick}
            >
                <MoreVertIcon className={classes.icon}/>
            </IconButton>
        
            <Menu
                aria-label={t('aria_label_action_menu')}
                anchorEl={anchorEl}
                open={getBool(anchorEl)}
                onClose={closeActionsMenu}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 0,
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style:{ 
                        border: '1px solid #d3d4d5',
                    }
                }}
            >
                {getMenuItems()}
            </Menu>
        </div>
    );
};

export default MIActionMenu;