import React from 'react';
import {Typography, Snackbar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import MuiAlert from "@material-ui/lab/Alert";
import {useResponsive} from "../../services/useResponsive";
import {mergeStyles} from "../../utils/utils";

const desktopStyles = theme => {
    return {
        container: {
            'alignItems': 'center'
        },
        content: {
            fontSize: theme.sizes.small_gap
        },
        contentText: {
            fontFamily: theme.fontFamily.proximaMedium,
        }
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            margin: theme.sizes.micro_gap,
        },
        contentText: {
            ...baseStyle.contentText,
            fontSize: theme.fontSizes.mobileBody
        },
    });
};
const MIAlert = (props) => {
    const { isShowing, setIsShowing, ariaLabel, severity, message, onHide, autoHideDuration, secondaryMessage } = props;
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    const handleClose = () => {
        setIsShowing(false);
        if (onHide){
            onHide()
        }
    };

    return (
        <Snackbar className={classes.root} open={isShowing} autoHideDuration={autoHideDuration? autoHideDuration: 6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} aria-label={ariaLabel}>
            <MuiAlert className={classes.container} onClose={handleClose} severity={severity} elevation={6} variant='standard'>
                <Typography className={mergeStyles(classes.content, classes.contentText)}> {message} </Typography>
                {secondaryMessage ?
                    <Typography className={classes.contentText}> {secondaryMessage} </Typography>:
                    null
                }
            </MuiAlert>
        </Snackbar>
    )
};

export default MIAlert;
