import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { Icon } from './Icon';
import { isEmpty } from '../utils/utils';

export const LEGEND_WIDTH_OUTER = 270;
export const LEGEND_WIDTH = 252;
const LEGEND_PADDING = 28;

const useStyles = makeStyles((theme) => ({
    mapLegend: {
        'display': 'flex',
        'flexDirection': 'column',
        'background': '#fff',
        'paddingLeft': '16px',
        'width': `${LEGEND_WIDTH }px`,
        'maxHeight': (props) => (props.height ? `${props.height - LEGEND_PADDING * 2}px` : ''),
        'padding': '28px 0px',
        '& ul': {
            listStyle: 'none',
            paddingLeft: '0px',
        },
        'borderRight': (props) => (!isEmpty(props.borderRight) ? props.borderRight : 'solid 2px #bdbdbd'),
        'fontFamily': theme.typography.h1.fontFamily,
        'overflow': (props) => (props.disableScroll ? '' : 'scroll'),
    },
    heading: {
        fontWeight: 'bold',
        paddingBottom: theme.sizes.xlarge_gap,
    },
    legendItem: {
        marginBottom: theme.sizes.large_gap,
    },
    group: {
        marginTop: '0px',
        marginBottom: theme.sizes.xlarge_gap,
    },
    legendText: {
        marginLeft: '10px',
        fontFamily: theme.typography.fontFamily,
        wordWrap: 'break-word',
        width: '205px'
    },
    spacer: {
        width: '80%',
        marginLeft: '16px',
        borderTop: 'solid 1px #e0e0e0',
        paddingBottom: theme.sizes.xlarge_gap,
    },
    floorInfoOuter: {
        fontSize: '14px',
        position: 'absolute',
        bottom: 0,
        left: 0,
    },
    floorInfoContainer: {
        position: 'relative',
        paddingTop: theme.sizes.large_gap,
        flexGrow: 1,
    },
    textFieldLabel: {
        fontFamily: theme.typography.fontFamily,
        alignSelf: 'center',
        paddingRight: '4px',
        paddingBottom: '4px'
    },
    textFieldLabelBold: {
        alignSelf: 'center',
        paddingRight: '4px',
        fontWeight: 'bold'
    },
}));

const MapLegend = (props) => {
    const classes = useStyles(props);
    const { iconGroups, selectedFloor, strings } = props;

    const LegendItem = (props) => {
        const { icon } = props;
        return (
            <li className={classes.legendItem}>
                <Grid container wrap="nowrap" direction="row" alignItems="center">
                    <Icon
                        icon={icon.icon}
                        size={icon.size}
                        color={icon.color}
                    />
                    <span className={classes.legendText}>
                        {icon.title}
                    </span>
                </Grid>
            </li>
        );
    };

    const LegendList = (props) => {
        const { group } = props;
        return group.map((icon) =>
            <LegendItem
                icon={icon}
                key={icon.title}/>
        );
    };

    const getLegendItems = () => iconGroups.map((group, index) => {
        const spacer = index < iconGroups.length - 1 ? <div id="legend-spacer" className={classes.spacer}/> : null;
        return (
            <div key={`legendList${ index}`}>
                <ul className={classes.group}>
                    <LegendList group={group} index={index}/>
                </ul>
                {spacer}
            </div>

        );
    });

    const createdText = isEmpty(strings.dateCreatedLabel) && isEmpty(strings.creationDate) ? null :
        <div className={classes.dataCreatedOuter}>
            <label className={classes.textFieldLabel}>
                {strings.dateCreatedLabel}:
            </label>
            <label className={classes.textFieldLabelBold}>
                {isEmpty(selectedFloor) ? '-' : strings.creationDate}
            </label>
        </div>;

    const versionText = isEmpty(strings.versionLabel) && isEmpty(selectedFloor) ? null :
        <div className={classes.versionOuter}>
            <label className={classes.textFieldLabel}>
                {strings.versionLabel}:
            </label>
            <label className={classes.textFieldLabelBold}>
                {isEmpty(selectedFloor) ? '-' : selectedFloor.version}
            </label>
        </div>;

    const getFloorInfo = () => <div className={classes.floorInfoContainer}>
        <div className={classes.floorInfoOuter}>
            {createdText}
            {versionText}
        </div>
    </div>;

    const heading = !isEmpty(strings.headingLabel) &&
        <div className={classes.heading}>
            {strings.headingLabel}
        </div>
    ;

    return (
        <div className={classes.mapLegend}>
            <div id="map-legend">
                {heading}
                {getLegendItems()}
            </div>
            {getFloorInfo()}
        </div>
    );
};

export default MapLegend;
