import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, getGMTOffsetDateFromString, getLocalizedDate } from '../../utils/utils';
import moment from 'moment';
import { useNotifications } from '../../services/notifications/useNotifications';
import { ERROR, FETCHING, SUCCESS } from '../../services/requests/requestReducer';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MIGrid from '../Widgets/MIGrid';
import { useSeenNotification } from '../../services/notifications/useSeenNotification';
import { useHistory } from 'react-router-dom';
import { useNotificationsStore } from '../../contexts/StateProvider/StateProvider';
import { observer } from 'mobx-react-lite';

const DEFAULT_HEIGHT = 760;

const useStyles = makeStyles((theme) => {
    return {
        linkContainer: {
            margin: 'auto',
            textAlign: 'center',
        },
        selectLink: {
            'display': 'flex',
            '&, &:visited, &:hover': {
                color: theme.palette.primary.main
            },
            '&:focus': theme.ariaFocus,
            'textDecoration': 'none',
        },
        typeAndDate: {
            textTransform: 'capitalize',
        },
        dataCell: {
            lineHeight: 0,
            width: '100%',
            whiteSpace: 'normal',
        },
        notificationTitle: {
            fontWeight: 'bold',
            color: theme.palette.primary.main
        },
        notificationType: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.fontSizes.notification,
            textTransform: 'capitalize',
        },
        badge: {
            height: '10px',
            width: '10px',
            backgroundColor: theme.colors.notificationRed,
            borderRadius: '50%',
            marginLeft: theme.sizes.micro_gap,
            float: 'right',
            marginTop: `-${theme.sizes.nano_gap}`,
        },
        noBadge: {
            marginBottom: theme.sizes.pico_gap,
        },
        notificationTypeInner: {
            width: '100%'
        },
        unreadNotification: {
            backgroundColor: theme.palette.grey['100'],
            marginLeft: ['-', theme.sizes.micro_gap].join(''),
            marginRight: ['-', theme.sizes.micro_gap].join(''),
            paddingLeft: theme.sizes.micro_gap,
            paddingRight: theme.sizes.micro_gap,
            width: ['calc(100% + ', theme.sizes.large_gap, ')'].join(''),
            height: '98%',
            display: 'flex',
            alignItems: 'center'
        },
    };
});

const NotificationTable = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [ , apiStatus, getNotifications, getSeenNotifications ] = useNotifications();
    const notificationsStore = useNotificationsStore();
    const [ , , createSeenNotification ] = useSeenNotification();
    const history = useHistory();

    useEffect(() => {
        getNotifications();
        getSeenNotifications();
    }, [ getNotifications, getSeenNotifications ]);

    const getBadge = (notificationSeen) => {
        return isEmpty(notificationSeen) ?
            <div className={classes.badge}/> : <div className={classes.noBadge}/>;
    };

    const getRows = useCallback(() => {
        let rows = [];
        if (!isEmpty(notificationsStore.notifications)) {
            notificationsStore.notifications.forEach((record, index) => {
                const date = !isEmpty(record.CreatedAt) ? moment(record.CreatedAt, 'DD/MM/YYYY') : '';
                rows.push({
                    id: index,
                    locDate: !isEmpty(record.CreatedAt) ? getLocalizedDate(t, record.CreatedAt) : '-',
                    notificationDate: !isEmpty(date) ? getGMTOffsetDateFromString(date) : '-',
                    notificationType: !isEmpty(record.Type) ? record.Type : '-',
                    notificationTitle: !isEmpty(record.Title) ? record.Title : '-',
                    notificationSeen: !isEmpty(record.SeenAt) ? record.SeenAt : '',
                    notificationUrl: !isEmpty(record.Url) ? record.Url : '',
                    notificationId: !isEmpty(record.NotificationId) ? record.NotificationId : '',
                });
            });
        }
        return rows;
    }, [ notificationsStore.notifications, t ]);

    const columns = [
        {
            field: 'dateAndType', resizable: false, flex: 1,
            renderCell: (params) => {
                return (
                    <div className={[classes.dataCell, isEmpty(params.row.notificationSeen) ? classes.unreadNotification : null].join(' ')}
                        aria-label={t('aria_label_message_row')}
                        id={`notification-${params.row.id}`}
                        data-testid={`notification-${params.row.id}`}>
                        <div className={classes.notificationTypeInner}>
                            <Typography className={classes.typeAndDate}>
                                {`${params.row.notificationType} \u2022 ${getLocalizedDate(t, params.row.locDate)}`}
                            </Typography>
                            { getBadge(params.row.notificationSeen) }
                            <Typography nowrap={'true'} className={classes.notificationTitle} variant="body2">
                                {params.row.notificationTitle}
                            </Typography>
                        </div>
                    </div>
                );
            }

        },
    ];

    const handleRowSelection = (target) => {
        history.push({
            pathname: '/support/messages/message-content',
            state: { url: target.row.notificationUrl, title: target.row.notificationTitle }
        });
        if (!isEmpty(target.row.notificationId)) {
            createSeenNotification(target.row.notificationId);
            setTimeout(() => {
                // since we move to a different page the useSeenNotification hook is destroyed
                // and the useEffect inside of it does not run. Use a timeout instead to update the icon bell
                notificationsStore.setLastNotificationSeen();
            }, 2000);
        }
    };

    return (
        <div>
            <MIGrid
                showInfoModal={true}
                isLoading={apiStatus === null || apiStatus === FETCHING}
                height={DEFAULT_HEIGHT}
                headerHeight={0}
                hasFilters={false}
                columns={columns}
                pageSize={10}
                noEvenRowStyling={true}
                rows={getRows()}
                rowHeight={55}
                onCellClick={handleRowSelection}
                pagination={true}
                hideFooterForOnePage={true}
                noElementsText={(apiStatus === SUCCESS || apiStatus === ERROR) && isEmpty(getRows()) ? t('no_messages') : ''}
                ariaLabel={t('aria_label_messages_table')}
                ariaLabelProgressBar={t('aria_label_progressbar_messages_table')}
                {...props}
            />
        </div>
    );
});

export default NotificationTable;
