import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MINavMenu from '../../Widgets/MINavMenu';
import { Link } from 'react-router-dom';
import Profile from '../Profile';
import { mergeStyles } from '../../../utils/utils';

const useStyles = makeStyles((theme) => {
    return {
        outer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            height: theme.heights.sidebar_height,
            background: theme.palette.background.paper,
            margin: 'auto',
            maxWidth: '1440px',
        },
        avatar: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: theme.sizes.micro_gap,
            marginEnd: theme.sizes.micro_gap,
        },
        columns: {
            display: 'flex',
            flex: 1,
            justifyContent: 'space-between',
        },
        sidebarFirst: {
            height: theme.heights.sidebar_height,
            width: '176px',
            background: theme.palette.primary.main,
            marginStart: theme.sizes.small_gap,
            position: 'relative',
        },
        logo: {
            width: '106px',
            top: '50%',
        },
        sidebarSecond: {
            height: theme.heights.sidebar_height,
            flex: 1,
            maxWidth: 'fit-content',
            display: 'flex',
            textAlign: 'center',
            order: 3,
        },
    };
});

const NavBarDesktop = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { showNavBar, routes, onChange, sharedClasses, desktopLogo } = props;
    return (
        <div className={classes.outer}>
            <div className={classes.columns}>
                <Link to="/">
                    <aside className={classes.sidebarFirst} aria-label={t('aria_label_left_aside')}>
                        <img
                            className={mergeStyles(sharedClasses.logo, classes.logo)}
                            src={desktopLogo}
                            alt={t('myInitial_logo')}
                            aria-label={t('aria_label_logo')}
                        />
                    </aside>
                </Link>
                {showNavBar && <MINavMenu routes={routes} onChange={onChange} ariaLabel={t('aria_label_primary_nav')}/>}
                <aside className={classes.sidebarSecond} aria-label={t('aria_label_right_aside')}>
                    <Profile avatar={classes.avatar}/>
                </aside>
            </div>
        </div>
    );
};

export default NavBarDesktop;
