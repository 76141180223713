import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getBool, getGMTOffsetDateFromString, getLocalizedDate, getLocalizedTime } from '../../utils/utils';
import { FETCHING } from '../../services/requests/requestReducer';
import { observer } from 'mobx-react-lite';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import VisitHistoryDesktop from './variant/VisitHistoryDesktop';
import VisitHistoryMobile from './variant/VisitHistoryMobile';
import { isEmpty } from '../../utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import { ResponsiveComponent } from '../../services/useResponsive';
import { useHistory } from 'react-router-dom';
import { CLICKABLE_ICON_CELL } from '../Widgets/MIGrid';
import { useVisitHistoryByFlag } from '../../services/visits/useVisitHistoryByFlag';

export const getStateFromRow = (row) => {
    return {
        premiseVisitNumber: !isEmpty(row.visitReport) ? row.visitReport.PremiseVisitNumber : '-',
        visitDate: !isEmpty(row.visitReport) ? row.visitReport.VisitDate : '-',
        locDate: row.locDate,
        rawDate: row.rawDate
    };
};

export const useBaseStyles = makeStyles((theme) => {
    return {
        linkContainer: {
            margin: 'auto',
            textAlign: 'center',
        },
        selectLink: {
            'display': 'flex',
            '&, &:visited, &:hover': {
                color: theme.palette.primary.main
            },
            '&:focus': theme.ariaFocus,
        },
    };
});

const VisitHistory = observer((props) => {
    const { t } = useTranslation();
    // change this in ACD-8571
    const [ visits, responseStatus, getVisitHistory ] = useVisitHistoryByFlag();
    const siteStore = useSiteStore();
    const history = useHistory();

    useEffect(() => {
        if (!isEmpty(siteStore.selectedSiteId) && !isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber)) {
            getVisitHistory(siteStore.selectedSiteId,
                siteStore.selectedContractNumber,
                siteStore.selectedPremiseNumber);
        }
    }, [ siteStore.selectedSiteId, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, getVisitHistory ]);

    const getRows = (maxRows) => {
        let rows = [];
        if (!isEmpty(visits)) {
            const visitHistory = maxRows ? visits.slice(0, maxRows) : visits;
            visitHistory.forEach((record, index) => {
                const locTime = getLocalizedTime(t, record.VisitDate, record.ServiceTime);
                let techName = record.TechnicianName || '-';
                techName = techName.trim().replace(/ +(?= )/g, '');
                rows.push({
                    id: [ record.PremiseVisitNumber, index ].join('-'),
                    rawDate: record.VisitDate,
                    locDate: getLocalizedDate(t, record.VisitDate),
                    visitDate: getGMTOffsetDateFromString(record.VisitDate),
                    visitTime: locTime || '-',
                    servicedBy: techName,
                    visitType: record.VisitType || '-',
                    signature: getBool(record.HasSignature),
                    visitDetails: record.PremiseVisitNumber,
                    visitReport: record,
                });
            });
        }

        return rows;
    };

    const handleCellClick = (selectedCell) => {
        // Tried using type but did not work, so using cellClassName to distinguish clickable icons whose functionality should not be overridden
        if(selectedCell.colDef.cellClassName !== CLICKABLE_ICON_CELL) {
            history.push({
                pathname: '/service/visit-history/visit-details',
                state: { data: getStateFromRow(selectedCell.row) }
            });
        }
    };

    const params = {
        isLoading: isEmpty(responseStatus) || responseStatus === FETCHING,
        rows: getRows(props.maxRows),
        handleCellClick: handleCellClick,
    };

    const mobileParams = {
        showRowCount: true,
    };

    return (
        <ResponsiveComponent
            components={{
                mobile: <VisitHistoryMobile {...params} {...mobileParams} {...props}/>,
                desktop: <VisitHistoryDesktop {...params} {...props}/>,
            }}
        />
    );
});

export default VisitHistory;
