import React from "react";
import {observer} from "mobx-react-lite";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";
import {isEmpty} from "../../../utils/utils";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    label: {
        fontWeight: theme.typography.fontWeightBold,
    },
    siteSelectLink: {
        marginStart: theme.sizes.micro_gap,
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:focus': theme.ariaFocus,
        '&:hover': {
            textDecoration: 'underline',
        }
    },
}));

const SelectedSiteLinkDesktop = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {selectedSite} = props;

    return (
        <Typography
            className={classes.label}
            noWrap={false}
        >
            {t('selected_site')}
            {isEmpty(selectedSite) ?
                <span className={classes.siteSelectLink}>-</span> :
                <Link to='/service/visit-history' className={classes.siteSelectLink} id={'selected_site'}>
                    {selectedSite.siteName.trim()}, {selectedSite.siteAddress}
                </Link>
            }
        </Typography>
    );
})

export default SelectedSiteLinkDesktop;