import React, { useEffect, useState } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import SiteSelector from "../../components/SiteSelector/SiteSelector";
import WasteConsignmentTable from "../../components/WasteTables/WasteConsignmentTable";
import {setDocumentTitle} from "../../utils/utils";
import {useResponsive, ResponsiveComponent} from '../../services/useResponsive';
import MIPageTitle from '../../components/Widgets/MIPageTitle';
import MIAlert from '../../components/Widgets/MIAlert';
import WasteConsignmentPortugalTable from '../../components/WasteTables/WasteConsignmentPortugalTable';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';

const desktopStyles = (theme) => {
    return {
        root: {
            width: "100%",
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            ":hover": {textDecoration: 'none'},
            ":visited": {textDecoration: 'none'},
            ":active": {textDecoration: 'none'},
            '&:focus': {
                outline:'none'
            },
        }
    }
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.large_gap,
            paddingRight: '0px',
            paddingLeft: '0px',
            maxHeight: 'none',
        },
    });
};

const WasteConsignment = () => {
    const {t} = useTranslation();
    const {getStyles} = useResponsive();
    const [isShowing, setIsShowing] = useState(false);
    const [alert, setAlert] = useState({});
    const { feature_hazardous_waste_portugal } = useFlags();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_waste');
    }, [t]);

    return (
        <Paper className={classes.root} aria-label={t('aria_label_waste_consignment_page')}>
            <SiteSelector/>

            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop:
                        <MIPageTitle
                            title={t('waste_consignment')}
                            ariaLabel={t('aria_label_waste_consignment_title')}
                            marginTop={16}
                            marginBottom={24}
                        />
                }}
            />
            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                severity={alert.severity}
                ariaLabel={t('aria_label_operation_response_dialog')}
            />
            {feature_hazardous_waste_portugal ?
                <WasteConsignmentPortugalTable
                    pagination={true}
                    hideFooterRowCount={true}
                    setAlert={setAlert}
                    setIsShowing={setIsShowing}
                /> :
                <WasteConsignmentTable
                    pagination={true}
                    hideFooterRowCount={true}
                    setAlert={setAlert}
                    setIsShowing={setIsShowing}
                />
            }

        </Paper>
    );
};
export default WasteConsignment;
