import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react-lite";
import SearchTextField from "../SearchTextField";
import SiteSelectorAutocomplete from "../SiteSelelectorAutocomplete";
import {Typography, Popper} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    siteSearchList: {
        paddingInlineStart: '15px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
    searchIcon: {
        color: theme.palette.grey['400'],
        cursor: 'text',
        position: 'relative',
    },
    optionInnerTitle: {
        color: theme.palette.text.primary,
        padding: [theme.sizes.micro_gap, theme.sizes.small_gap].join(' ')
    },
    optionInner: {
        color: theme.palette.text.primary,
    },
    mobilePremise: {
        textAlign: 'left',
        fontSize: theme.fontSizes.mobileBody,
        fontWeight: 'bold'
    },
    mobileContract: {
        textAlign: 'right',
        fontSize: theme.fontSizes.mobileBody,
        fontWeight: 'bold'
    },
    mobileAddress: {
        fontSize: theme.fontSizes.mobileBody,
    },
}));

const SiteSelectorAutocompleteMobile = observer((props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {searchIcon} = props;

    const mobilePopper = (props) => (
        <Popper 
            {...props} 
            style={{width: '100vw', marginTop: '16px'}}
            placement='bottom'
            popperOptions={{
                modifiers: {
                    preventOverflow: {
                        padding: 0,
                        boundariesElement: 'window'
                    },
                    flip: { 
                        enabled: false,
                    },
                },
            }}
        />
    );

    const renderGroup = option => (
        <Fragment key={1}>
            <Grid 
                container   
                direction='row'
                justify='space-between'
                alignItems='center'
                className={classes.optionInnerTitle}
            >
                <Grid item xs={9}>
                    <Typography className={classes.mobilePremise}> {[t('premise_no'), t('premise_address')].join(' / ')} </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.mobileContract}> {t('contract_no')} </Typography>
                </Grid>
            </Grid>
            {option.children}
        </Fragment>
    )

    const renderOption = option => {
        const address = option.siteAddress ? option.siteAddress.trim() : '';
        return (
            <Grid 
                container   
                direction='row'
                justify='space-between'
                alignItems='center'
                className={classes.optionInner}
            >
                <Grid item xs={6}>
                    <Typography className={classes.mobilePremise}> {option.premiseNo} </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography className={classes.mobileContract}> {option.contractId} </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={classes.mobileAddress}> {option.siteName.trim()}, {address} </Typography>
                </Grid>
            </Grid>
        );
    }

    return (          
        <SiteSelectorAutocomplete
            groupBy={(option) => option.groupMobile}
            renderGroup={renderGroup}
            renderOption={renderOption}
            renderInput={(params) => (
                <SearchTextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (searchIcon()),
                        endAdornment: null,
                    }}
                />
            )}
            PopperComponent={mobilePopper}
        />
    )
})

export default SiteSelectorAutocompleteMobile;
