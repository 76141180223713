import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import {AuthProvider} from './contexts/AuthProvider';
import * as serviceWorker from './serviceWorker';
import "./services/i18n";
import MISpinner from "./components/Widgets/MISpinner";
import {MuiThemeProvider} from "@material-ui/core";
import Theme from "./components/Theme/Theme";
import {addProductionAnalytics, addStagingAnalytics} from "./analytics";
import {StateProvider} from "./contexts/StateProvider/StateProvider";
import {FlagsProvider} from "./contexts/FlagsProvider/FlagsProvider";
import {initFirebase} from "./utils/firebaseConfig";
import {BrowserRouter} from "react-router-dom";

initFirebase();

if (process.env.REACT_APP_PRODUCTION_MODE === '1') {
    addProductionAnalytics();
    let disFunc = () => 'console has been disabled in production mode';

    console.log = disFunc
    console.warn = disFunc
    Object.freeze(console);
} else {
    addStagingAnalytics();
}

ReactDOM.render(
    <React.StrictMode>
        <React.Suspense
            fallback={<MuiThemeProvider theme={Theme}>
                <MISpinner
                    customStyle={{
                        'spinnerWidth': '80px',
                        'spinnerHeight': '80px',
                        marginTop: '20%'
                    }}/>
            </MuiThemeProvider>}>
            <StateProvider>
                <AuthProvider>
                     <BrowserRouter>
                        <FlagsProvider defaults={{}}>
                            <App/>
                        </FlagsProvider>
                     </BrowserRouter>
                </AuthProvider>
            </StateProvider>
        </React.Suspense>
    </React.StrictMode>
    ,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
