import React, {useCallback, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import BackgroundImage from '../../assets/marketinglogin.png'
import myInitialLogo from '../../assets/nav_logo.svg';
import {useTranslation} from "react-i18next";
import {useContentFromUrl} from "../../services/useContentFromUrl";
import {isEmpty} from "../../utils/utils";
import MICmsContainer from "../Widgets/MICmsContainer";
import MIApiLoader from "../Widgets/MIApiLoader";
import {useIpCountry} from "../../services/useIpCountry";
import {ERROR, FETCHING} from "../../services/requests/requestReducer";
import {useAppBranding} from "../../services/useAppBranding";

const useStyles = makeStyles(theme => ({
    marketingContainer: {
        width: "50%",
        height: "100%",
        background: theme.palette.primary.main,
        overflow: 'hidden',
        position: 'relative',
    },
    marketingImage: {
        width: "100%",
        height: "100%",
        objectFit: 'cover',
        opacity: '0.1',
        zIndex: 1,
        display: 'flex',
        pointerEvents: 'none',
        position: 'absolute',
    },
    marketingLogo: {
        position: 'absolute',
        zIndex: 4,
        margin: theme.sizes.small_gap,
        width: '25%',
    },
    marketingCopy: {
        display: 'flex',
        height: '100%',
        alignItems: "center",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: '455px',
        paddingLeft: theme.sizes.large_gap,
        paddingRight: theme.sizes.large_gap,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
    },
}));

function LoginMessage() {
    const {t} = useTranslation();
    const classes = useStyles();
    const [content, responseStatus, getContentFromUrl, getContentUrl] = useContentFromUrl();
    const [country, countryResponseStatus, getCountryFromIp] = useIpCountry();
    const [ getAppBrandUrl ] = useAppBranding();

    useEffect(() => {
        getCountryFromIp();
    }, [getCountryFromIp]);

    useEffect(() => {
        if(country) {
            const fullUrl = getContentUrl('login.html', country);
            getContentFromUrl(fullUrl);
        }
    }, [getContentFromUrl, getContentUrl, country]);

    const getStatus = useCallback(() => {
        if(countryResponseStatus === ERROR){
            return countryResponseStatus
        }
        return isEmpty(responseStatus) ? FETCHING : responseStatus
    }, [countryResponseStatus, responseStatus]);

    return (
        <div className={classes.marketingContainer}>
            <img
                className={classes.marketingImage}
                src={BackgroundImage}
                alt='Marketing background'
            />
            <img
                className={classes.marketingLogo}
                src={getAppBrandUrl('logo.png', myInitialLogo)}
                alt={t('myInitial_logo')}
            />
            <div className={classes.marketingCopy}>
                <MIApiLoader
                    customStyle={{
                        color: '#f1f1f1',
                    }}
                    MIComponent={
                        !isEmpty(content) && <MICmsContainer html={content}/>
                    }
                    responseStatus={getStatus()}
                    ariaLabelProgressBar={t('aria_label_progressbar_login_content')}
                />
            </div>
        </div>
    );
}

export default LoginMessage;
