import React from "react";
import { Controller } from "react-hook-form";
import { Radio, RadioGroup, FormControlLabel, Typography } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useResponsive} from '../../services/useResponsive';
import {mergeStyles} from "../../utils/utils";

const desktopStyles = (theme) => {
    return {
        radioGroup: {
            margin: theme.sizes.no_gap,
            padding: mergeStyles(theme.sizes.no_gap, theme.sizes.femto_gap),
            width: '213px',
            border: error => error ? '1px solid ' + theme.palette.error.dark : null,
        },
    }
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        controlLabelText: { 
            fontSize: theme.fontSizes.mobileBody,
            fontFamily: theme.fontFamily.proximaMedium,
        }
    });
};

const MIRadioButton = ({options = [], ariaLabel, error, radioButtonProps, ...props }) => {
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles(error);

    return (
        <Controller
            render={({ ...props }) => (
                <RadioGroup className={classes.radioGroup} aria-label={ariaLabel} {...props}> 
                    {options.map((option, index) => 
                        <FormControlLabel 
                            key={ariaLabel + ' ' + index} 
                            value={option.value} 
                            control={
                                <Radio 
                                    size={getStyles({
                                        desktop: 'medium',
                                        mobile: 'small'
                                    })} 
                                    color='primary' 
                                />
                            } 
                            label={<Typography className={classes.controlLabelText}> {option.label} </Typography>} 
                            {...radioButtonProps}
                        /> 
                    )}
                </RadioGroup>
            )}
            rules={{ required: true }}
            {...props}
        />
    )
}

export default MIRadioButton;