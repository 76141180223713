import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalizedDate, isEmpty } from '../../utils/utils';
import { ResponsiveComponent } from '../../services/useResponsive';
import MIPageTitle from '../Widgets/MIPageTitle';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { observer } from 'mobx-react-lite';
import { useGetHazWasteQuarterlyReturnsDocs } from '../../services/waste/useGetHazWasteQuarterlyReturnsDocs';
import QuarterlyReturnsTableDesktop from './variant/QuarterlyReturnsTableDesktop';
import QuarterlyReturnsTableMobile from './variant/QuarterlyReturnsTableMobile';


const QuarterlyReturnsTable = observer(() => {
    const { t } = useTranslation();
    const [ hazWasteQuarterlyReturnsDocs, responseStatus, getHazWasteQuarterlyReturnsDocs ] = useGetHazWasteQuarterlyReturnsDocs();

    const { selectedContractNumber, selectedPremiseNumber } = useSiteStore();

    useEffect(() => {
        if (!isEmpty(selectedContractNumber) && !isEmpty(selectedPremiseNumber)) {
            getHazWasteQuarterlyReturnsDocs(selectedContractNumber, selectedPremiseNumber);
        }
    }, [ selectedContractNumber, selectedPremiseNumber, getHazWasteQuarterlyReturnsDocs ]);

    const getRows = () => {
        let rows = [];
        if (!isEmpty(hazWasteQuarterlyReturnsDocs)) {
            hazWasteQuarterlyReturnsDocs.forEach((doc, index) => {
                rows.push({
                    id: index,
                    locDate: !isEmpty(doc.Date) ? getLocalizedDate(t, doc.Date) : '-',
                    title: !isEmpty(doc.Title) ? doc.Title : '-',
                    filePath: !isEmpty(doc.Date) ? doc.FilePath : '-',
                });
            });
        }
        return rows;
    };

    return (
        <ResponsiveComponent
            components={{
                mobile:
                    <QuarterlyReturnsTableMobile
                        showRowCount={true}
                        mobilePageSize={10}
                        rows={getRows()}
                        responseStatus={responseStatus} />,
                desktop:
                    <>
                        <MIPageTitle
                            title={t('quarterly_returns')}
                            ariaLabel={t('aria_label_quarterly_returns_title')}
                            marginTop={16}
                            marginBottom={24}
                        />
                        <QuarterlyReturnsTableDesktop
                            rows={getRows()}
                            responseStatus={responseStatus}
                        />
                    </>
            }}
        />
    );
});

export default QuarterlyReturnsTable;
