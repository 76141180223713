import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles"
import {Link} from "react-router-dom";
import AssignmentIcon from '@material-ui/icons/Assignment';
import MIGrid, {STYLE_HEADER_CENTER, CLICKABLE_ICON_CELL} from "../../Widgets/MIGrid";
import {observer} from "mobx-react-lite";
import Typography from "@material-ui/core/Typography";
import {getStateFromRow, useBaseStyles} from "../VisitHistory";

const useStyles = makeStyles(theme => ({
    dataCell: {
        lineHeight: 0,
        marginRight: '-10px',
        marginLeft: '-10px',
        width: '100%'
    },
    selectLinkMobile: {
        height: theme.sizes.large_gap,
        width: theme.sizes.large_gap,
    },
    visitHistoryDate: {
        fontSize: theme.fontSizes.mobileLabel,
        fontFamily: theme.fontFamily.proximaBold,
    },
    visitType: {
        fontSize: theme.fontSizes.mobileBody,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    gridContainer: {
        borderTop: theme.borders.outline_bold,
        minHeight: theme.heights.mobile_grid_min_height
    }
}));

const VisitHistoryMobile = observer((props) => {
    const {t} = useTranslation();
    const baseStyles = useBaseStyles();
    const classes = {...baseStyles, ...useStyles()};
    const {handleCellClick, rows, mobilePageSize, mobileParams} = props;

    const columns = [
        {
            field: 'dateAndType', resizable: false, flex: 3,
            renderCell: (params) => (
                <div className={classes.dataCell}>
                    <Typography className={classes.visitHistoryDate} variant="body2">
                        {params.row.locDate}
                    </Typography>
                    <Typography className={classes.visitType}>
                        {params.row.visitType}
                    </Typography>
                </div>
            )
        },
        {
            field: 'visitDetails', headerName: t('details'), resizable: false, filterable: false, width: 40,
            sortable: false,
            headerClassName: STYLE_HEADER_CENTER,
            cellClassName: CLICKABLE_ICON_CELL,
            renderCell: (params) => (
                <div className={classes.linkContainer}>
                    <Link className={classes.selectLink} to={{
                        pathname: '/service/visit-history/visit-details',
                        state: {data: getStateFromRow(params.row)}
                    }}
                          aria-label={t('aria_label_visit_history_button')}>
                        <AssignmentIcon className={classes.selectLinkMobile}/>
                    </Link>
                </div>
            ),
        }
    ]

    return (
        <div className={classes.gridContainer}>
            <MIGrid
                showInfoModal={false}
                rows={rows}
                columns={columns}
                noElementsText={t('no_visits')}
                ariaLabel={t('aria_label_visit_history_table')}
                ariaLabelProgressBar={t('aria_label_progressbar_visit_history')}
                headerHeight={0}
                noEvenRowStyling={true}
                onCellClick={handleCellClick}
                autoPageSize={false}
                pageSize={mobilePageSize ? mobilePageSize : rows.length}
                heightToFitContent={true}
                hasFilters={false}
                pagination={true}
                hideFooterForOnePage={true}
                {...mobileParams}
                {...props}
            />
        </div>
    )
});

export default VisitHistoryMobile;
