import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { isEmpty } from '../../../../utils/utils';
import ProductTableMobile from './ProductTableMobile';

const ContractGroupTableMobile = observer((props) => {
    const { t } = useTranslation();

    const getDataFromRow = (selectedRow) => {
        const data = [];
        if (!isEmpty(selectedRow.data)) {
            selectedRow.data.product && data.push({ label: t('product'), value: selectedRow.data.product });
            selectedRow.data.location && data.push({ label: t('location'), value: selectedRow.data.location });
            selectedRow.data.serviceGroup && data.push({ label: t('service_group_name'), value: selectedRow.data.serviceGroup });
            selectedRow.data.productCode && data.push({ label: t('product_code'), value: selectedRow.data.productCode });
            selectedRow.data.quantity && data.push({ label: t('quantity'), value: selectedRow.data.quantity });
            selectedRow.data.visitsAnnum && data.push({ label: t('visit_per_year'), value: selectedRow.data.visitsAnnum });
        }
        return data;
    };

    return (
        <ProductTableMobile getDataFromRow={getDataFromRow} {...props} />
    );
});

export default ContractGroupTableMobile;
