import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  textField: {
    background: (props) => (props.background ? props.background : null),
    'display': 'flex',
    'width': (props) => (props.width ? props.width : '448px'),
    'height': (props) => (props.height ? props.height : theme.sizes.xxxlarge_gap),
    '& fieldset': {
      borderRadius: (props) => (props.rounded ? '4px' : '0px'),
      border: (props) => (props.hideBorder ? 0 : theme.borders.listBorders),
    }
  },
  inputProps: {
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderWidth: '1px',
        boxShadow: 'rgba(0,125,197,0.25) 0px 1px 9px 1px',
      }
    },
    '&.Mui-error': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.dark,
      }
    },
    '&.MuiOutlinedInput-adornedStart': {
      height: (props) => (props.outlinedInput && props.outlinedInput.height ? props.outlinedInput.height : 'unset'),
      marginTop: (props) => (props.outlinedInput && props.outlinedInput.marginTop ? props.outlinedInput.marginTop : 'unset'),
    }
  },
}));

const helperTextStyles = makeStyles((theme) => ({
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      fontSize: theme.typography.body2.fontSize,
      lineHeight: '1.1em',
      marginTop: '4px',
      marginLeft: '0px',
      marginRight: '0px',
      color: theme.palette.error.dark,
    }
  }
}));

const MIPasswordField = ({ className, ariaLabel, hideBorder, rounded, InputProps, width, height, background, outlinedInput, ...props }) => {
  const classes = useStyles({ hideBorder: hideBorder, rounded: rounded, width: width, height: height, background: background, outlinedInput: outlinedInput });
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (showPassword) {
      timeoutId = setTimeout(() => {
        setShowPassword(false);
      }, 5000); // 5 seconds
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [showPassword]);

  const helperTestClasses = helperTextStyles();
  let compoundClassName = className ? `${classes.textField} ${className}` : classes.textField;

  const handleMouseUpDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <TextField
      className={compoundClassName}
      aria-live={'polite'}
      FormHelperTextProps={{
        classes: helperTestClasses, role: 'alert'
      }}
      InputProps={{
        className: classes.inputProps,
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}
              onMouseDown={handleMouseUpDownPassword}
              onMouseUp={handleMouseUpDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>,
        ...InputProps
      }}
      inputProps={{
        'aria-label': ariaLabel,
        style: background ? { background: background } : {},
      }}
      type={showPassword ? 'text' : 'password'}
      onMouseLeave={() => setShowPassword(false)}
      variant={'outlined'}
      {...props}
    />
  );
};

MIPasswordField.propTypes = {

  /** Accessibility aria label */
  ariaLabel: PropTypes.string.isRequired,

  /** Optional instruction text */
  placeholder: PropTypes.string,

  /** Optional subtext under the input box */
  helperText: PropTypes.string,

  /** Optional additional style classname */
  className: PropTypes.string,

  /** Optional should hide the border? */
  hideBorder: PropTypes.bool,

  /** Optional use rounded corners */
  rounded: PropTypes.bool,

  /** Optional click handler */
  InputProps: PropTypes.object,

  /** Optional click handler */
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),

  /** Optional background for the inner textField */
  background: PropTypes.string,
};

export default MIPasswordField;