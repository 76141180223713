import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {useDocumentFolders} from '../../services/useDocumentFolders';
import Paper from '@material-ui/core/Paper';
import {useAuthState} from '../../contexts/AuthProvider';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import MITextField from '../../components/Widgets/MITextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import MIDocument from '../../components/Widgets/MIDocument';
import MIAccordion from '../../components/Widgets/MIAccordion';
import {getBool, setDocumentTitle, isEmpty} from '../../utils/utils';
import {useGetAudit} from "../../services/useGetAudit";
import {useHygieneDocumentFolders} from "../../services/useHygieneDocumentFolders";
import {FETCHING} from "../../services/requests/requestReducer";
import {useResponsive, ResponsiveComponent} from "../../services/useResponsive";
import MIPageTitle from '../../components/Widgets/MIPageTitle';

const desktopStyles = theme => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            overflow: 'auto',
            marginBottom: theme.sizes.xlarge_gap,
        },
        listRoot: {
            width: '100%',
            maxWidth: '928px',
            backgroundColor: theme.palette.background.paper,
            position: 'relative',
            paddingTop: theme.sizes.xlarge_gap,
        },
        listSection: {
            backgroundColor: 'inherit',
            '& .MuiAccordion-root.Mui-expanded': {
                minHeight: 0,
            }
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
        },
        noDocuments: {
            marginLeft: theme.sizes.micro_gap,
            color: theme.colors.noData,
            fontSize: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 'bold',
            '&:focus': theme.ariaFocus,
        },
        folderName: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.typography.h2.fontFamily,
            color: theme.palette.text.primary,
            marginTop: theme.sizes.large_gap,
            '&:focus': theme.ariaFocus,
        },
        item: {
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.small_gap,
            borderTop: theme.borders.outline_regular,
        },
        documentSearch: {
            display: 'flex',
            width: '99%',
            marginTop: theme.sizes.small_gap,
            '& fieldset': {
                borderRadius: theme.sizes.no_gap,
                border: '1px solid ' + theme.palette.grey['500'] + ' !important',
            },
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            minHeight: '100vh',
            width: '100vw',
            border: 'none',
            paddingLeft: theme.sizes.no_gap,
            paddingRight: theme.sizes.no_gap,
            fontFamily: theme.typography.fontFamily,
        },
        listRoot: {
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        },
        documentSearch: {
            ...baseStyle.documentSearch,
            width: 'inherit',
            marginTop: theme.sizes.no_gap,
            padding: theme.sizes.small_gap,
            borderBottom: theme.borders.outline_regular,
            height: 'unset'
        },
        item: {
            minHeight: theme.sizes.xxxlarge_gap,
            borderTop: theme.borders.outline_regular,
            alignItems: 'center',
            display: 'flex'
        },
    });
};


const MAX_DOCS_PER_ACCORDION = 100;

const Documents = () => {
    const {t, i18n} = useTranslation();
    const float = (i18n.dir() === 'ltr') ? 'right' : 'left';
    const {locale, countryCode} = useAuthState();
    const [searchTerm, setSearchTerm] = useState('');
    const [documentFolders, responseDocumentStatus, getDocumentFolders] = useDocumentFolders();
    const [hygieneDocumentFolders, responseHygieneStatus, getHygieneDocumentFolders] = useHygieneDocumentFolders();
    const [audit, responseAuditStatus, getAudit] = useGetAudit();
    const [filteredDocumentFolders, setFilteredDocumentFolders] = useState([]);
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles({float: float});

    useEffect(() => {
        setDocumentTitle(t, 'nav_documents');
    }, [t]);

    useEffect(() => {
        getAudit('NA', false);
    }, [getAudit]);

    useEffect(() => {
        if (!isEmpty(audit) && !isEmpty(locale)) {
            if (getBool(audit.useMyAdminDocuments)) {
                getHygieneDocumentFolders(
                    countryCode,
                );
            } else {
                getDocumentFolders(
                    locale,
                );
            }
        }
    }, [locale, getDocumentFolders, audit, countryCode, getHygieneDocumentFolders]);

    const groupByFolder = useCallback((documentList) => {
        const foldersMap = [];
        documentList.forEach((document) => {
            const folder = getFolder(document.folderId, foldersMap);
            if (isEmpty(folder.Docfolder)) {
                foldersMap.push({
                    'Docfolder': [document],
                    'folderName': document.folderName,
                    'folderId': document.folderId
                })
            } else {
                folder.Docfolder.push(document);
            }
        });

        return foldersMap;
    }, []);

    const getFolder = (folderId, foldersMap) => {
        for (let i = 0; i < foldersMap.length; i++) {
            if (foldersMap[i].folderId === folderId) {
                return foldersMap[i];
            }
        }
        return {}
    };

    const getDocumentsPerAccordion = (documentFolder) => {
        let docs = [];
        for (let index = 0; index <  documentFolder.Docfolder.length; index++) {
            const document = documentFolder.Docfolder[index];
            if (index < MAX_DOCS_PER_ACCORDION) {
                docs.push(
                    <div
                        className={classes.item}
                        key={'document-' + documentFolder.folderId + '-' + index}>
                        <MIDocument
                            documentName={
                                getBool(audit.useMyAdminDocuments) ? document.documentName : document.description
                            }
                            documentExtendedName={
                                getBool(audit.useMyAdminDocuments) ? document.docExtendedName : document.documentName
                            }
                            url={getDocumentUrl(document)}
                            title={t('aria_label_link_opened_in_new_tab')}
                            mimeType={document.mimeType}
                        />
                    </div>)
            } else {
                break
            }
        }
        return docs
    };

    useEffect(() => {
        let filtered = [];
        const folders = !isEmpty(documentFolders) ? documentFolders : groupByFolder(hygieneDocumentFolders);

        if (typeof searchTerm === 'string' && searchTerm.length > 0) {
            folders.forEach((folder) => {
                let foundFolder = {};
                foundFolder.Docfolder = [];
                foundFolder.folderName = folder.folderName;
                foundFolder.folderId = folder.folderId;
                //force the accordion expansion on typing
                foundFolder.forceExpansion = true;

                for (let index = 0; index < folder.Docfolder.length; index++) {
                    const document = folder.Docfolder[index];
                    if (index < MAX_DOCS_PER_ACCORDION) {
                        const docName = getBool(audit.useMyAdminDocuments) ? document.documentName : document.description;
                        if (docName.toLowerCase().includes(searchTerm.toLowerCase()) &&
                            foundFolder.Docfolder.indexOf(document) < 0) {
                            foundFolder.Docfolder.push(document);
                        }
                    } else {
                        break
                    }
                }

                if (foundFolder.Docfolder.length > 0) {
                    filtered.push(foundFolder);
                }
            });

            setFilteredDocumentFolders(filtered);
        } else {
            filtered = [].concat(folders);
            setFilteredDocumentFolders(filtered);
        }

    }, [searchTerm, documentFolders, hygieneDocumentFolders , groupByFolder, audit.useMyAdminDocuments]);

    const getDocuments = () => {
        if (filteredDocumentFolders && filteredDocumentFolders.length > 0) {
            return <div className={classes.listRoot} aria-label={t('aria_label_documents_list')}>
                {
                    filteredDocumentFolders.map((documentFolder) => (
                        <div key={'documentFolder-' + documentFolder.folderId} className={classes.listSection}>
                            <MIAccordion
                                className={classes.ul}
                                aria-label={t('aria_label_folder_name')}
                                header={documentFolder.folderName}
                                id={documentFolder.folderId}
                                expandedValue={getBool(documentFolder.forceExpansion)}
                                content={getDocumentsPerAccordion(documentFolder)}
                            />
                        </div>
                    ))
                }
            </div>
        } else {
            return (
                <p className={classes.noDocuments} tabIndex={0}>{t('no_documents')}</p>
            );
        }
    };

    const getDocumentUrl = function (document) {
        return getBool(audit.useMyAdminDocuments) ?
            ['/documentService/downloadDocumentByExtranets', 'I', document.file_id, countryCode, locale].join('/')
            :
            ['/documentService/downloadDocument', document.documentId, locale].join('/')
    };

    const getApiStatus = function () {
        if (responseAuditStatus !== FETCHING && responseAuditStatus !== null && !isEmpty(audit)) {
            if (getBool(audit.useMyAdminDocuments)) {
                if (responseHygieneStatus !== FETCHING && responseHygieneStatus !== null) {
                    return responseHygieneStatus
                }
            } else {
                if (responseDocumentStatus !== FETCHING && responseDocumentStatus !== null) {
                    return responseDocumentStatus
                }
            }
        }
        return FETCHING
    };

    return (
        <Paper className={classes.root} aria-label={t('aria_label_documents_page')}>
            {
                <ResponsiveComponent
                    components={{
                        mobile: null,
                        desktop: <MIPageTitle title={t('documents')} ariaLabel={t('aria_label_documents_title')}/>
                    }}
                />
            }
            <div>
                <MITextField
                    className={classes.documentSearch}
                    ariaLabel={t('aria_label_search_documents')}
                    variant='outlined'
                    name='site-search'
                    placeholder={t('search_documents')}
                    onChange={(event) => {
                        const {value} = event.target;
                        setSearchTerm(value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon className={classes.searchIcon}/>
                            </InputAdornment>
                        )
                    }}
                />
            </div>
            <MIApiLoader
                customStyle={{marginTop: '60px'}}
                MIComponent={
                    getDocuments()
                }
                responseStatus={getApiStatus()}
                ariaLabelProgressBar={t('aria_label_progressbar_documents')}/>
        </Paper>
    );
};

export default Documents;
