import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { mergeStyles, isEmpty } from '../../utils/utils';
import { Typography } from '@material-ui/core';
import { useResponsive } from '../../services/useResponsive';

export const NEW_STATUS = 'NEW';
export const PENDING_STATUS = 'PENDING';
export const COMPLETE_STATUS = 'COMPLETE';

const useDesktopStyles = (theme) => {
    return {
        red: {
            color: 'red',
        },
        orange: {
            color: 'orange',
        },
        green: {
            color: 'green',
        },
        icon: {
            paddingRight: theme.sizes.pico_gap,
            marginTop: (props) => {
                return !isEmpty(props.iconMarginTop) ? props.iconMarginTop : '';
            }
        },
        iconContainer: {
            display: 'inline-flex',
            alignItems: (props) => {
                return !isEmpty(props.iconContainerAlign) ? props.iconContainerAlign : 'center';
            },
            marginLeft: [ '-', theme.sizes.pico_gap ].join(''),
        },
        textFont: {
            fontSize: theme.fontSizes.subHeading
        }
    };
};

const useMobileStyles = (theme) => {
    const baseStyle = useDesktopStyles(theme);

    return Object.assign(baseStyle, {
        textFont: {
            fontSize: theme.fontSizes.mobileBody
        }
    });
};

const MIColoredIcon = observer((props) => {
    const { t } = useTranslation();
    const { item = '', styles } = props;
    const { getStyles } = useResponsive();
    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: useDesktopStyles(theme),
            mobile: useMobileStyles(theme)
        });
    }
    );

    const classes = useStyles(styles);

    const map = {
        [NEW_STATUS]: classes.red,
        [PENDING_STATUS]: classes.orange,
        [COMPLETE_STATUS]: classes.green,
    };

    return (
        <div className={classes.iconContainer}>
            <FiberManualRecordIcon fontSize="small" className={mergeStyles(classes.icon, map[item])}/>
            <Typography className={classes.textFont}>{t(item.toLowerCase())}</Typography>
        </div>
    );
});

export default MIColoredIcon;
