import { useState, useEffect, useCallback } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';

export const useHealthCheck = () => {
    const [ healthStatus, setHealthStatus ] = useState(null);
    const [ { responseStatus, response },
        makeRequestWithParams ] = useApiRequest();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setHealthStatus(response.data);
            } catch (error) {
                console.error('useHealthCheck error: ', error);
            }
        }
    }, [ responseStatus, response, setHealthStatus ]);

    const getHealthCheck = useCallback(() => {
        makeRequestWithParams('/status', 'get')
            .catch((error) => {
                console.error('useHealthCheck makeRequest: ', error);
            });
    }, [ makeRequestWithParams ]);

    return [ healthStatus, responseStatus, getHealthCheck ];
};
