import React, { memo, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import MIPagination from './MIPagination';
import { XGrid, GridOverlay, LicenseInfo } from '@material-ui/x-grid';
import { isEmpty, getBool } from '../utils/utils';
import MIToolTip from './MIToolTip';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import MIInfoModal from './MIInfoModal';
import { FORMFACTOR } from '../utils/enums';


// Using dynammic import for the x-grid for falback compatbility
let GridFilterButton = import('@material-ui/x-grid').GridFilterToolbarButton;
if (!GridFilterButton) {
    import('@material-ui/x-grid').then((xgrid) => {
        GridFilterButton = xgrid.GridToolbarFilterButton;
    });
}

export const STYLE_HEADER_CENTER = 'MIGrid-center-header';
export const CLICKABLE_ICON_CELL = 'clickable-icon';
export const STYLE_HEADER_LEFT = 'MIGrid-left-header';
export const STYLE_HEADER_RIGHT = 'MIGrid-right-header';
export const STYLE_HEADER_CENTER_OVERFLOW = 'MIGrid-center-header-overflow';
export const STYLE_HEADER_START_OVERFLOW = 'MIGrid-start-header-overflow';
const DEFAULT_HEADER_HEIGHT = 50;
const HEADER_PADDING = 24;
const DEFAULT_ROW_HEIGHT = 44;
const ROW_PADDING = 14;
const PAGINATION_HEIGHT = 58;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_HEIGHT = 900;
const FILTER_OFFSET = 70;
LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_LICENSE);

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        marginTop: (props) => (props.hasFilters ? -FILTER_OFFSET : '0px'),
        width: (props) => (props.width ? props.width : '100%'),
    },
    innerContainer: {
        'flexGrow': 1,
        '& .rightAligned': {
            justifyContent: 'flex-end',
        },
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '0px',
    },
    grid: {
        'border': 'none',
        '& div.MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '& div.MuiDataGrid-columnsContainer': {
            backgroundColor: theme.palette.background.paper,
            borderTop: (props) =>
                (props.headerHeight === 0 || props.noHeaderBorder ?
                    'none' :
                    props.error ?
                        `8px solid ${ theme.palette.error.dark}` :
                        `8px solid ${ theme.palette.grey['200']}`),
            border: 'none',
        },
        '& div.MuiDataGrid-columnHeader--alignRight': {
            '& div.MuiDataGrid-columnHeaderDraggableContainer': {
                flexDirection: (props) => (props.leftAlignNumericalCell ? 'row' : 'row-reverse')
            },
            '& div.MuiDataGrid-columnHeaderTitleContainer': {
                flexDirection: (props) => (props.leftAlignNumericalCell ? 'row' : 'row-reverse')
            }
        },
        '& div.MuiDataGrid-cell--textRight.MuiDataGrid-cell--withRenderer': {
            justifyContent: (props) => (props.leftAlignNumericalCell ? 'flex-start' : 'flex-end')
        },
        '& div.MIGrid-center-header': {
            justifyContent: 'center',
            display: 'grid',
        },
        '& div.MIGrid-center-header-overflow': {
            justifyContent: 'center',
            display: 'flex',
            overflow: 'hidden',
        },
        '& div.MIGrid-start-header-overflow': {
            justifyContent: 'start',
            display: 'flex',
            overflow: 'hidden',
        },
        '& div.MIGrid-left-header': {
            justifyContent: 'left',
            display: 'flex',
        },
        '& div.MuiDataGrid-toolbar': {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0px',
        },
        '& button.MuiButton-root': {
            'textTransform': 'none',
            'marginBottom': (props) => (props.filterMargin ? props.filterMargin : theme.sizes.micro_gap),
            'fontFamily': theme.typography.h1.fontFamily,
            'fontSize': theme.typography.body1.fontSize,
            'height': '48px',
            'width': '128px',
            'border': '1px solid #CDD2D5',
            'borderRadius': '4px',
            '&:hover': {
                background: (props) => (props.buttonHoverBackground ? props.buttonHoverBackground : theme.palette.background.paper),
                boxShadow: 'rgba(205, 210, 213, 1) 0px 2px 5px 1px',
            },
            '&.Mui-focusVisible': {
                borderColor: '#007DC5',
                boxShadow: 'theme.palette.primary.main 0px 1px 9px 1px',
            },
            '&.MuiButton-contained.Mui-disabled': {
                color: '#354A55',
            },
            '&.MuiButton-outlinedSizeSmall': {
                width: '15px',
            },
        },
        '& div.MuiDataGrid-colCellWrapper': {
            borderLeft: (props) => (props.error ? `1px solid ${ theme.palette.error.dark}` : ''),
            borderRight: (props) => (props.error ? `1px solid ${ theme.palette.error.dark}` : ''),
        },
        '& div.MuiDataGrid-window': {
            borderLeft: (props) => (props.error ? `1px solid ${ theme.palette.error.dark}` : ''),
            borderRight: (props) => (props.error ? `1px solid ${ theme.palette.error.dark}` : ''),
            overflowY: 'hidden !important',
            overflowX: (props) => (props.hideGridWindowHorizontalScroll ? 'hidden !important' : '')
        },
        '& div.MuiDataGrid-footer': {
            borderLeft: (props) => (props.error ? `1px solid ${ theme.palette.error.dark}` : ''),
            borderRight: (props) => (props.error ? `1px solid ${ theme.palette.error.dark}` : ''),
            borderBottom: (props) => (props.error ? `1px solid ${ theme.palette.error.dark}` : ''),
        },
        '& div.MuiDataGrid-colCell': {
            borderBottom: 'none',
            whiteSpace: 'nowrap',
            height: theme.sizes.mega_gap,
            padding: `0px ${ theme.sizes.micro_gap}`,
        },
        '& div.MuiDataGrid-colCellTitleContainer': {
            flex: (props) => (props.colCellFlex ? '' : 'none'),
        },
        '& div.MuiDataGrid-colCellTitle': {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.body1.fontSize,
        },
        '& div.MuiDataGrid-columnHeaderTitleContainer': {
            padding: '0px',
        },
        '& div.MuiDataGrid-columnHeaderTitle': {
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.body1.fontSize,
        },
        '& div.MuiDataGrid-row': {
            'backgroundColor': (props) => (props.noEvenRowStyling ? '' : theme.palette.grey['50']),
            'borderBottom': (props) => (props.noEvenRowStyling ? `1px solid ${ theme.palette.grey['300']}` : ''),
            'cursor': (props) => (props.onSelectionModelChange ? 'pointer' : 'default'),
            '&:hover': {
                background: (props) => (props.noEvenRowStyling ? 'inherit' : `${theme.palette.grey['300'] } !important`),
            },
        },
        '& div.MuiDataGrid-cell': {
            border: 'none',
            height: theme.sizes.mega_gap,
            padding: (props) => [ theme.sizes.no_gap, props.noDataGridPadding ? theme.sizes.no_gap : theme.sizes.micro_gap ].join(' '),
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '& div.MuiDataGrid-row.Mui-selected': {
            borderBottom: (props) => (props.selectedRowBorder ? props.selectedRowBorder : 'none'),
            width: 'inherit'
        },
        '& div.Mui-even': {
            backgroundColor: (props) => (props.noEvenRowStyling ? '' : theme.palette.grey['200']),
        },
        '& div.MuiDataGrid-overlay': {
            height: theme.sizes.xlarge_gap,
        },
        '& div.MuiDataGrid-iconButtonContainer': {
            width: 'fit-content',
            margin: 'auto',
            paddingRight: '3px',
        },
        '& div.MuiLinearProgress-root': {
            zIndex: 10,
        },
        '& div.MuiDataGrid-selectedRowCount': {
            fontSize: (props) => (props.selectedRowCountFontSize ? props.selectedRowCountFontSize : theme.sizes.xsmall_gap),
            fontFamily: (props) => (props.selectedRowCountFontFamily ? props.selectedRowCountFontFamily : 'unset')
        }
    },
    noElementsText: {
        'color': theme.colors.noData,
        'fontSize': theme.sizes.small_gap,
        'fontFamily': theme.typography.fontFamily,
        'fontWeight': 'bold',
        '&:focus': theme.ariaFocus,
    },
    loadingOverlay: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    typography: {
        width: '650px',
        lineHeight: '2.3',
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
    },
    keyButton: {
        marginRight: '5px',
    },
    inline: {
        display: 'inline',
    },
    icon: {
        fontSize: 'small',
    },
}));

const desktopGetRowCountStyles = (theme) => ({
    rowCount: {
        marginBottom: theme.sizes.xlarge_gap,
        fontSize: theme.fontSizes.subHeading,
    },
});

const mobileGetRowCountStyles = (theme) => {
    const baseStyle = desktopGetRowCountStyles(theme);

    return Object.assign(baseStyle, {
        rowCount: {
            padding: theme.sizes.small_gap,
            fontSize: theme.fontSizes.mobileLabel,
        },
    });
};

export const RenderTooltip = (params) => <MIToolTip {...params} value={params.value}/>;

export const GetRowCount = (params) => {
    const { rows, showRowCount, formFactor } = params;
    const { t } = useTranslation();

    const useCountStyles = makeStyles((theme) => {
        switch (formFactor) {
        case FORMFACTOR.MOBILE:
            return mobileGetRowCountStyles(theme);
        case FORMFACTOR.DESKTOP:
        default:
            return desktopGetRowCountStyles(theme);
        }
    });
    const classes = useCountStyles();

    return (
        getBool(showRowCount) && !isEmpty(rows) &&
            <div
                className={classes.rowCount}
                aria-label={t('aria_label_table_row_count')}
            >
                <span>
                    {[ rows.length, rows.length === 1 ? t('item') : t('items') ].join(' ')}
                </span>
            </div>
    );
};

const MIGrid = (props) => {
    let filterProps = {};
    const { t } = useTranslation();
    const [ page, setPage ] = React.useState(0);
    const [ calcHeight, setCalcHeight ] = React.useState(DEFAULT_HEIGHT);
    const {
        apiRef,
        showInfoModal,
        formFactor,
        hideFooter,
        hideFooterForOnePage,
        rows,
        columns,
        pageSize,
        noElementsText,
        ariaLabelProgressBar,
        hasFilters,
        headerHeight,
        height,
        heightToFitContent,
        ariaLabel,
        isLoading,
        density,
        rowHeight,
        rowPadding,
        pagination,
        hideFooterSelectedRowCount,
        checkboxSelection,
        onSelectionModelChange,
        selectionModel,
        onRowSelected,
        onCellClick,
        filterMode,
        filterModel,
        onFilterModelChange,
        paginationMode,
        onPageChange,
        rowsPerPageOptions,
        rowCount,
        currentPage,
        sortingMode,
        sortModel,
        onSortModelChange,
        disableSelectionOnClick,
        paginationRowCountAriaLabel,
        gridKey
    } = props;

    const localeText = {
        errorOverlayDefaultLabel: t('errorOverlayDefaultLabel'),
        toolbarFilters: t('toolbarFilters'),
        toolbarFiltersLabel: t('toolbarFiltersLabel'),
        toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide'),
        toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow'),
        toolbarFiltersTooltipActive: (count) => `${count } ${ t('toolbarFiltersTooltipActive')}`,
        filterPanelAddFilter: t('filterPanelAddFilter'),
        filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
        filterPanelOperators: t('filterPanelOperators'),
        filterPanelOperatorAnd: t('filterPanelOperatorAnd'),
        filterPanelOperatorOr: t('filterPanelOperatorOr'),
        filterPanelColumns: t('filterPanelColumns'),
        filterOperatorContains: t('filterOperatorContains'),
        filterOperatorEquals: t('filterOperatorEquals'),
        filterOperatorStartsWith: t('filterOperatorStartsWith'),
        filterOperatorEndsWith: t('filterOperatorEndsWith'),
        filterOperatorIs: t('filterOperatorIs'),
        filterOperatorNot: t('filterOperatorNot'),
        filterOperatorAfter: t('filterOperationAfter'),
        filterOperationAfter: t('filterOperationAfter'),
        filterOperatorOnOrAfter: t('filterOperatorOnOrAfter'),
        filterOperatorBefore: t('filterOperatorBefore'),
        filterOperatorOnOrBefore: t('filterOperatorOnOrBefore'),
        columnHeaderSortIconLabel: t('columnHeaderSortIconLabel'),
        footerTotalRows: t('footerTotalRows'),
        footerPaginationRowsPerPage: t('footerPaginationRowsPerPage'),
        footerRowSelected: (count) =>
            (count === 1 ?
                `${count.toLocaleString()} ${t('footerRowSelected')}` :
                `${count.toLocaleString()} ${t('footerRowsSelected')}`),
        filterPanelInputLabel: t('filterPanelInputLabel'),
        filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
    };

    const classes = useStyles(props);
    const isFooterHidden = useCallback(() => {
        let isHidden = hideFooter;
        if (hideFooterForOnePage) {
            isHidden = rowCount ?
                rowCount <= (pageSize || DEFAULT_PAGE_SIZE) :
                rows.length <= (pageSize || DEFAULT_PAGE_SIZE);
        }
        return isHidden;
    }, [ hideFooter, hideFooterForOnePage, pageSize, rows.length, rowCount ]);

    const noRowsOverlay = () =>
        <GridOverlay>
            <p className={classes.noElementsText} tabIndex={0}>{noElementsText}</p>
        </GridOverlay>
        ;

    const loadingOverlay = () =>
        <GridOverlay>
            <div className={classes.loadingOverlay}>
                <LinearProgress aria-label={t(ariaLabelProgressBar)}/>
            </div>
        </GridOverlay>
        ;

    const getKeyButton = (ariaLabel, content) =>
        <Button
            className={classes.keyButton}
            aria-label={ariaLabel}
            color="black"
            variant="outlined"
            tabIndex={0}
            size="small"
        >
            {content}
        </Button>;

    const shiftKeyContent = () =>
        <div className={classes.inline}>
            <ArrowUpwardOutlinedIcon className={classes.icon}/>
            {t('shift_key')}
        </div>;

    const showInformationButton = () => {
        const content =
            <div className={classes.typography}>
                {t('sort_explanation')}
                <br/>
                {getKeyButton(t('aria_label_ctrl_key_button'), t('ctrl_key'))}
                {', '}
                {getKeyButton(t('aria_label_shift_key_button'), shiftKeyContent())}
                {t('small_or')}
                {getKeyButton(t('aria_label_command_key_button'), t('command_key'))}
                {t('on_macOs')}
            </div>
        ;
        return (
            <div>
                <MIInfoModal
                    buttonAriaLabel={t('aria_label_info_button')}
                    buttonContent={<InfoIcon/>}
                    modalHeaderAriaLabel={t('aria_label_table_commands_header')}
                    modalHeader={t('table_commands_header')}
                    modalContent={content}
                />
            </div>
        );
    };

    const customToolbar = () =>
        <div className={classes.toolbar}>
            {getBool(showInfoModal) && showInformationButton()}
            <GridFilterButton/>
        </div>;


    let components = {
        NoRowsOverlay: noRowsOverlay,
        LoadingOverlay: loadingOverlay,
        Pagination: MIPagination,
    };

    if (hasFilters) {
        filterProps.showToolbar = true;
        components.Toolbar = customToolbar;
    }

    useEffect(() => {
        setPage(0);
    }, [ rows.length, setPage ]);

    useEffect(() => {
        let newHeight = 0;
        if (heightToFitContent) {
            const maxRows = pageSize || DEFAULT_PAGE_SIZE;
            const calcRowHeight = (rowHeight || DEFAULT_ROW_HEIGHT) + (rowPadding || ROW_PADDING);
            let calcHeaderHeight = !isEmpty(headerHeight) ? headerHeight : DEFAULT_HEADER_HEIGHT;
            if (calcHeaderHeight > 0) {
                calcHeaderHeight = calcHeaderHeight + HEADER_PADDING;
            }
            const maxHeight = maxRows * calcRowHeight + calcHeaderHeight;
            newHeight = rows.length > maxRows ? maxHeight : rows.length * calcRowHeight + calcHeaderHeight;
            if (!isFooterHidden()) {
                newHeight = newHeight + PAGINATION_HEIGHT;
            }
            if (hasFilters) {
                newHeight = newHeight + FILTER_OFFSET;
            }
        } else {
            newHeight = height ? parseInt(height) : DEFAULT_HEIGHT;
        }
        setCalcHeight(newHeight);
    }, [ rowPadding, rows.length, isFooterHidden, headerHeight, height, heightToFitContent, pageSize, rowHeight, hasFilters, setCalcHeight ]);

    return (
        <div className={classes.container} style={{ height: calcHeight, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div className={classes.innerContainer}>
                    <XGrid
                        key={gridKey}
                        apiRef={apiRef}
                        aria-label={ariaLabel}
                        localeText={localeText}
                        components={components}
                        componentsProps={{
                            pagination: {
                                formFactor: formFactor,
                                rowCountAriaLabel: paginationRowCountAriaLabel
                            },
                        }}
                        loading={isLoading}
                        className={classes.grid}
                        disableColumnMenu={true}
                        disableDensitySelector={true}
                        disableColumnSelector={true}
                        disableSelectionOnClick={disableSelectionOnClick}
                        density={density ? density : 'comfortable'}
                        rowHeight={rowHeight || DEFAULT_ROW_HEIGHT}
                        page={currentPage ? currentPage : page}
                        onPageChange={onPageChange ? onPageChange : (page) => {
                            setPage(page);
                        }}
                        pagination={pagination}
                        hideFooter={isFooterHidden()}
                        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
                        pageSize={pageSize || DEFAULT_PAGE_SIZE}
                        columns={columns}
                        rows={rows}
                        headerHeight={!isEmpty(headerHeight) ? headerHeight : DEFAULT_HEADER_HEIGHT}
                        {...filterProps}
                        checkboxSelection={checkboxSelection}
                        onSelectionModelChange={onSelectionModelChange}
                        selectionModel={selectionModel}
                        onRowSelected={onRowSelected}
                        onCellClick={onCellClick}
                        filterMode={filterMode}
                        filterModel={!isEmpty(filterModel) || filterMode === 'server' ? filterModel : { items: [] }}
                        onFilterModelChange={onFilterModelChange}
                        paginationMode={paginationMode}
                        rowsPerPageOptions={rowsPerPageOptions}
                        rowCount={rowCount}
                        sortingMode={sortingMode}
                        sortModel={sortModel}
                        onSortModelChange={onSortModelChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(MIGrid);
