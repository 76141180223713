import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import OpenReport from "./OpenReport";
import {EDIT_REPORT} from "../../../services/useSaveReport";
import {useTranslation} from "react-i18next";
import MIToolTip from '../../../components/Widgets/MIToolTip';

const useStyles = makeStyles(theme => ({
    actionIcon: {
        color: theme.palette.text.contrastBlue,
        cursor: 'pointer',
        maxWidth: parseInt(theme.widths.desktop_reports_title, 10) - parseInt(theme.sizes.large_gap, 10)
    }
}));


const TitleReport = (props) => {
    const classes = useStyles();
    const {reportTitle} = props;
    const {t} = useTranslation();

    return (
        <OpenReport component={
            MIToolTip({
                value: reportTitle,
                spanParams: {
                    'data-testid': 'title_icon',
                    className: classes.actionIcon
                }
            })
            }
            ariaLabel={t('aria_label_report_title_button')}
            ariaLabelProgressBar={t('aria_label_progressbar_title_report')}
            action={EDIT_REPORT}
            {...props}
        />

    );
};

export default TitleReport;
