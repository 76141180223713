import React, { useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { observer } from 'mobx-react-lite';
import { isEmpty, getGMTOffsetDateFromString, getLocalizedDate } from '../../utils/utils';
import { ResponsiveComponent, useResponsive } from '../../services/useResponsive';
import moment from 'moment';
import MIDatePicker from '../Widgets/MIDatePicker';
import WasteConsignmentTableMobile from './variant/WasteConsignmentTableMobile';
import WasteConsignmentTableDesktop from './variant/WasteConsignmentTableDesktop';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { FETCHING } from '../../services/requests/requestReducer';
import { useWasteConsignmentPortugal } from '../../services/waste/useWasteConsignmentsPortugal';

const desktopStyles = (theme) => {
    return {
        year: {
            'marginBottom': theme.sizes.large_gap,
            'width': '125px',
            '& .MuiFormControl-root': {
                zIndex: 100,
            },
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        year: {
            padding: theme.sizes.small_gap,
        },
    });
};
const WasteConsignmentPortugalTable = observer((props) => {
    const { t } = useTranslation();
    const { getStyles } = useResponsive();
    const [ wasteConsignment, responseStatus, getWasteConsignmentPortugal ] = useWasteConsignmentPortugal();
    const siteStore = useSiteStore();
    const { control, watch } = useForm();
    const year = watch('yearSelector', moment()).format('YYYY');

    const useStyles = makeStyles((theme) => {
            return getStyles({
                desktop: desktopStyles(theme),
                mobile: mobileStyles(theme)
            });
        }
    );

    const classes = useStyles();

    useEffect(() => {
        if (!isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber) && !isEmpty(year)) {
            getWasteConsignmentPortugal(
                siteStore.selectedContractNumber,
                siteStore.selectedPremiseNumber,
                year
            );
        }
    }, [ siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, year, getWasteConsignmentPortugal ]);

    let rows = [];
    if (!isEmpty(wasteConsignment)) {
        wasteConsignment.forEach((record, index) => {
            const date = !isEmpty(record.creationDateUTC) ? moment(record.creationDateUTC).format('DD/MM/YYYY') : null;
            rows.push({
                id: index,
                consignmentNoteCode: record.number,
                url: record.url,
                locDate: !isEmpty(date) ? getLocalizedDate(t, date) : '-',
                visitDate: !isEmpty(date) ? getGMTOffsetDateFromString(date) : null,
            });
        });
    }

    const yearPicker =
        <div className={classes.year}>
            <MIDatePicker
                control={control}
                label={`${t('year')} :`}
                name={'yearSelector'}
                dateFormat={'YYYY'}
                datePickerProps={{
                    views: [ 'year' ],
                    variant: 'dialog',
                    orientation: 'portrait',
                    maxDate: moment(),
                    minDate: moment().subtract(50, 'year'),
                    disabled: responseStatus === FETCHING,
                    cancelLabel : t('cancel'),
                    okLabel: t('ok'),
                    autoOk: false
                }}
                defaultValue={moment()}
                customStyle={{ calendarWidth: '125px', calendarHeight: 'fit-content' }}
            />
        </div>;

    const params = {
        responseStatus: responseStatus,
        rows: rows,
        wasteConsignment: wasteConsignment,
        yearPicker: yearPicker
    };

    const mobileParams = {
        showRowCount: true,
        mobilePageSize: 10,
        mobileHeight: '620px',
    };

    return (
        <ResponsiveComponent
            components={{
                mobile: <WasteConsignmentTableMobile {...params} {...mobileParams} {...props}/>,
                desktop: <WasteConsignmentTableDesktop {...params} {...props}/>,
            }}
        />
    );
});

export default WasteConsignmentPortugalTable;
