import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useContentFromUrl} from '../../services/useContentFromUrl';
import {useAuthState} from '../../contexts/AuthProvider';
import {LOGGED_IN_STATUS} from '../../contexts/AuthProvider/AuthConst';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import FooterDesktop from "./variant/FooterDesktop";
import FooterMobile from './variant/FooterMobile';
import {ResponsiveComponent, useResponsive} from "../../services/useResponsive";
import {useIpCountry} from "../../services/useIpCountry";

const desktopStyles = theme => {
    return {
        copyrightText: {
            '&:focus': theme.ariaFocus,
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        copyrightText: {
            ...baseStyle.copyrightText,
            fontSize: theme.fontSizes.mobileLabel,
        }
    });
};

const useSharedStyles = makeStyles(theme => ({
    footer: {
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        bottom: 0,
        fontFamily: theme.typography.caption.fontFamily,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.caption.fontWeight,
        lineHeight: theme.typography.caption.lineHeight,
    },
    siteLinks: {
        display: 'flex',
        textAlign: 'start',
        justifyContent: 'flex-start',
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            color: theme.palette.primary.main,
        },
        '&:focus': theme.ariaFocus,
    },
    iconLinks: {
        display: 'flex',
    },
}));

const Footer = () => {
    const {t} = useTranslation();
    const {status} = useAuthState();
    const sharedClasses = useSharedStyles();
    const title = t('aria_label_link_opened_in_new_tab');
    const [content, ,getContentFromUrl, getContentUrl] = useContentFromUrl();
    const {getStyles} = useResponsive();
    const [countryFromIp,, getCountryFromIp] = useIpCountry();
    const isLoggedIn = status() === LOGGED_IN_STATUS;

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    useEffect(() => {
        getCountryFromIp();
    }, [getCountryFromIp]);

    useEffect(() => {
        if (isLoggedIn) {
            const url = getContentUrl('../links.json');
            getContentFromUrl(url);
        } else {
            if(countryFromIp) {
                const url = getContentUrl(['..', countryFromIp, 'links.json'].join('/'));
                getContentFromUrl(url);
            }
        }
    }, [getContentFromUrl, getContentUrl, countryFromIp, isLoggedIn]);

    const createLink = (className, link, content) => {
        return <a
            className={className}
            target='_blank'
            rel='noopener noreferrer'
            href={['//', link].join('')}
            title={title}
        >
            {content}
        </a>
    };

    const copyright = () => (
        <Typography variant="body2" tabIndex={0} className={classes.copyrightText}>
            &copy; {[moment().format('YYYY'), t('copyright')].join(' ')}
        </Typography>
    )

    const manageCookies = (className, content) => (
        <a
            className={className + ' optanon-show-settings'}
            target='_self'
            rel='noopener noreferrer'
            href="#/"
            title={title}
        >
            {content}
        </a>
    )

    const params = {
        sharedClasses,
        content,
        createLink,
        copyright,
        manageCookies,
    };

    return (
        <ResponsiveComponent
            components={{
                mobile: <FooterMobile {...params}/>,
                desktop: <FooterDesktop {...params}/>,
            }}
        />

    );
};

export default Footer;
