import React, {useCallback, useEffect} from "react";
import MIApiLoader from "../Widgets/MIApiLoader";
import {useGetFile} from "../../services/useGetFile";
import {isEmpty} from '../../utils/utils';
import {ERROR} from "../../services/requests/requestReducer";
import {useGetFloorPlanFile} from "../../services/floorplan/useGetFloorPlanFile";

const PDF_MIME_TYPE = 'application/pdf';
const PDF_EXTENSION = 'pdf';

const MIFileHandler = (props) => {
    const {isLoading, setIsLoading, url, ariaLabelProgressBar, setAlert, setIsShowing, mimeType, name, useFloorPlanApi, requestMethod, requestParams} = props;
    const [standardFile, responseStatusStandardFile, getStandardFile] = useGetFile();
    const [floorPlanFile, responseStatusFloorPlanFile, getFloorPlanFile] = useGetFloorPlanFile();

    let file = useFloorPlanApi? floorPlanFile: standardFile;
    let responseStatus = useFloorPlanApi? responseStatusFloorPlanFile :responseStatusStandardFile;

    const showWarningAlert = useCallback(() => {
        setAlert({message: 'no_file_dialog_warning', severity: 'warning'});
        setIsShowing(true);
    }, [setAlert, setIsShowing]);

    function decodeBase64File(base64str) {
        // decode base64 string, remove space for IE compatibility
        var binary = atob(base64str.replace(/\s/g, ''));
        var buffer = new ArrayBuffer(binary.length);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < binary.length; i++) {
            view[i] = binary.charCodeAt(i);
        }
        return view;
    }

    function generateLinkElement(fileURL, downloadFile, name) {
        let link = window.document.createElement('a');
        link.target = '_blank';
        link.href = fileURL;
        if (downloadFile && name) {
            link.download = name;
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(link);
            window.URL.revokeObjectURL(fileURL);
        }, 100);
    }

    const getDocumentExtension = useCallback(() => {
        return name ? name.split('.').pop() : '';
    }, [name]);

    const showPDF = useCallback((base64str) => {
        if (base64str && base64str.length > 0) {
            window.URL = window.URL || window.webkitURL;
            if (window.webkitURL) {
                // In Safari popups created in async function are blocked by default so you have to create the popup
                // before the api call: https://stackoverflow.com/questions/2587677/avoid-browser-popup-blockers
                var pdfWindow = window.open('', '_blank');
            }

            const fileBlob = new Blob([decodeBase64File(base64str)], {type: PDF_MIME_TYPE});

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                // IE doesn't allow using a blob object directly as link href
                // instead it is necessary to use msSaveOrOpenBlob
                window.navigator.msSaveOrOpenBlob(fileBlob);
            } else {
                const fileURL = window.URL.createObjectURL(fileBlob);
                if (pdfWindow) {
                    pdfWindow.location.href = fileURL;
                } else {
                    // if the popup has been blocked by the browser use the auto link click instead
                   generateLinkElement(fileURL, false);
                }
            }
        }
    }, []);

    const downloadFile = useCallback((base64str) => {
        if (base64str && base64str.length > 0) {
            window.URL = window.URL || window.webkitURL;
            const fileBlob = new Blob([decodeBase64File(base64str)], {type: mimeType});
            const fileURL = window.URL.createObjectURL(fileBlob);
            generateLinkElement(fileURL, true, name);
        }
    }, [mimeType, name]);

    const handleFileBase64 = useCallback((fileBase64str, name, mimeType) => {
        try {
            if (mimeType === PDF_MIME_TYPE || getDocumentExtension() === PDF_EXTENSION || isEmpty(name)) {
                showPDF(fileBase64str);
            } else {
                downloadFile(fileBase64str);
            }
        } catch (error) {
            console.error('useTabLauncher error: ', error);
        }
    }, [getDocumentExtension, showPDF, downloadFile]);

    useEffect(() => {
        if (responseStatus === ERROR) {
            setIsLoading(false);
        }
    }, [responseStatus, setIsLoading]);

    useEffect(() => {
        if (!isEmpty(file)) {
            const fileBase64str = file.base64;
            const signedUrl = file.signedUrl;

            if (!isEmpty(fileBase64str) && fileBase64str !== 'error' && fileBase64str !== 'FAILURE') {
                handleFileBase64(fileBase64str, name, mimeType);
            } else if (!isEmpty(signedUrl)) {
                generateLinkElement(signedUrl, true, name);
            } else {
                //if the API returned an empty string or an error string show a warning alert
                showWarningAlert();
            }
            setIsLoading(false);
        }
    }, [file, setIsLoading, showWarningAlert, name, mimeType, handleFileBase64]);

    useEffect(() => {
        if (isLoading) {
            try {
                if(useFloorPlanApi) {
                     getFloorPlanFile(url);
                }else {
                    getStandardFile(url, requestMethod, requestParams);
                }
            } catch (error) {
                console.error('useTabLauncher error: ', error);
            }
        }
    }, [isLoading, getStandardFile, url, getFloorPlanFile, useFloorPlanApi, requestMethod, requestParams]);

    return (
        <div>
            {isLoading ?
                <MIApiLoader
                    customStyle={props.customStyle}
                    MIComponent={null}
                    responseStatus={responseStatus}
                    ariaLabelProgressBar={ariaLabelProgressBar}/>
                : null}
        </div>
    );
};

export default MIFileHandler;
