import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import MIGrid from '../../Widgets/MIGrid';
import { isEmpty } from '../../../utils/utils';
import { observer } from 'mobx-react-lite';
import { useFlags } from '../../../contexts/FlagsProvider/FlagsProvider';
import MIPageTitle from '../../../components/Widgets/MIPageTitle';
import { FETCHING } from '../../../services/requests/requestReducer';

const useStyles = makeStyles((theme) => {
    return {
        infoCard: {
            display: 'flex',
            backgroundColor: theme.palette.grey['200'],
            paddingBottom: theme.sizes.small_gap,
        },
        hazardousWasteInfoCard: {
            display: 'flex',
            backgroundColor: theme.palette.grey['200'],
            paddingBottom: theme.sizes.xlarge_gap,
            marginTop: theme.sizes.small_gap,
        },
        gridData: {
            marginTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.xlarge_gap,
        },
        header: {
            '&:focus': theme.ariaFocus,
        },
        noVisitDetails: {
            'color': theme.colors.noData,
            'fontSize': theme.sizes.small_gap,
            'fontFamily': theme.typography.fontFamily,
            'fontWeight': 'bold',
            '&:focus': theme.ariaFocus,
        },
    };
});

const COLUMN_SIZE_WITH_CONSIGNMENT_NOTES = 4;
const COLUMN_SIZE_WITHOUT_CONSIGNMENT_NOTES = 1.5;

const VisitDetailsDesktop = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { feature_hazardous_waste_consignment_notes } = useFlags();

    const { buttons, signature, visit, infoGrid, hazWasteInfoGrid, gridRows, showHazardousWaste, responseStatus } = props;

    let gridColumns = [];
    gridColumns.push({
        field: 'productServiced',
        headerName: t('product_serviced'),
        type: 'string', resizable: false,
        flex: feature_hazardous_waste_consignment_notes ?
            COLUMN_SIZE_WITH_CONSIGNMENT_NOTES : COLUMN_SIZE_WITHOUT_CONSIGNMENT_NOTES }
    );
    if(feature_hazardous_waste_consignment_notes && showHazardousWaste) {
        gridColumns.push(
            { field: 'ewcCode', headerName: t('ewc_code'), type: 'string', resizable: false, flex: 3 },
            { field: 'hazardousWasteType', headerName: t('hazardous_waste_type'), type: 'string', resizable: false, flex: 3 }
        );
    }
    gridColumns.push({ field: 'quantityServiced', headerName: t('quantity_serviced'), align:'left', type: 'number', resizable: false, flex: 3 });


    const getInfoCard = () => {
        return (
            <div className={classes.infoCard}>
                {infoGrid}
                {signature}
            </div>
        );
    };

    const getHazWasteInfoCard = () => {
        return(
            <div>
                <h2 className={classes.header} tabIndex={0} aria-label={t('aria_label_hazardous_waste')}>{t('hazardous_waste_collection_information')}</h2>
                <div className={classes.hazardousWasteInfoCard}>
                    {hazWasteInfoGrid}
                </div>
            </div>
        );
    };

    const getProductServicedTable = () => {
        return (
            <div>
                <h2
                    className={classes.header}
                    tabIndex={0}
                    aria-label={t('aria_label_products_serviced')}>
                    {t('products_serviced')}
                </h2>
                <div className={classes.gridData}>
                    <MIGrid
                        columns={gridColumns}
                        rows={gridRows}
                        noElementsText={t('no_product_serviced')}
                        heightToFitContent={true}
                        hideFooterForOnePage={true}
                        pageSize={10}
                        pagination={true}
                        ariaLabel={t('aria_label_visit_details_table')}
                        ariaLabelProgressBar={t('aria_label_progressbar_visit_details_table')}
                        isLoading={responseStatus === null || responseStatus === FETCHING}
                    />
                </div>
            </div>
        );
    };


    return (
        <div>
            {buttons}
            <MIPageTitle
                title={t('visit_details')}
                ariaLabel={t('aria_label_visit_details_title')}
                marginTop={12}
                marginBottom={12}
            />
            {isEmpty(visit) || isEmpty(visit.visitDetails) ?
                <div className={classes.noVisitDetails} tabIndex={0}>{t('no_visit_details')}</div> :
                <div>
                    {getInfoCard()}
                    {feature_hazardous_waste_consignment_notes && showHazardousWaste && getHazWasteInfoCard()}
                    {getProductServicedTable()}
                </div>
            }
        </div>
    );
});

export default VisitDetailsDesktop;
