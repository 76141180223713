import {makeAutoObservable} from "mobx";

class WasteStore {
    wasteConsignment = {};

    constructor() {
        makeAutoObservable(this);
    }

    getWasteConsignment(siteId) {
        return this.wasteConsignment[siteId];
    }

    setWasteConsignment(siteId, wasteConsignmentList) {
        this.wasteConsignment[siteId] = wasteConsignmentList;
    }

    reset() {
        this.wasteConsignment = {};
    }
}

export {WasteStore};