import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { AppBar, Toolbar, Button, SwipeableDrawer, List, ListItem, Divider, ListItemText, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Footer from '../../Footer/Footer';
import Profile from '../Profile';
import { useHistory, useLocation } from 'react-router-dom';
import { mergeStyles, isEmpty, getTopRoute } from '../../../utils/utils';

const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            zIndex: 100,
        },
        navIcon: {
            color: theme.palette.primary.main,
            width: theme.sizes.xlarge_gap,
            height: theme.sizes.xlarge_gap,
        },
        navIconButton: {
            'padding': theme.sizes.no_gap,
            'minWidth': theme.sizes.no_gap,
            '& .MuiButton-label': {
                justifyContent: 'unset',
            },
        },
        logo: {
            width: theme.heights.mobile_logo,
            textAlign: 'center',
            top: '55%',
        },
        logoForNotifications : {
            width: theme.heights.mobile_logo,
            marginLeft: theme.sizes.small_gap,
        },
        list: {
            padding: theme.sizes.no_gap,
        },
        tabs: {
            height: theme.heights.mobile_tabs,
            padding: mergeStyles(theme.sizes.large_gap, theme.sizes.small_gap),
        },
        drawerPaper: {
            'position': 'fixed',
            'width': theme.widths.mobile_navdrawer,
            'height': '100%',
            'overflowX': 'hidden',
            'overflowY': 'hidden',
            '&:hover': {
                overflowY: 'auto',
            },
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
        avatar: {
            width: theme.sizes.xlarge_gap,
            height: theme.sizes.xlarge_gap,
        },
        title: {
            'fontSize': theme.fontSizes.mobileTitle,
            'fontFamily': theme.typography.h2.fontFamily,
            'color': theme.palette.text.primary,
            '&:focus': theme.ariaFocus,
            'position': 'absolute',
            'top': '50%',
            'left': '50%',
            'transform': 'translate(-50%, -50%)',
        },
    };
});

const NavBarMobile = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { routes, sharedClasses, mobileLogo, showNotifications } = props;
    const location = useLocation();
    const history = useHistory();
    const [ drawerToggle, setDrawerToggle ] = useState(false);
    const mobileNavTabs = [ '/reports', '/account', '/documents', '/support' ];
    const mobileStickyNavTabs = [ '/', '/service', '/waste' ];

    const currentRoute = getTopRoute(routes, location.pathname);

    const title = !isEmpty(currentRoute) && currentRoute.label;
    const ariaLabel = !isEmpty(currentRoute) && currentRoute.ariaLabel;

    const toggleDrawer = (open, route) => {
        return (event) => {
            if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
                return;
            }
            setDrawerToggle(open);
            // When the drawer is opened, temporarily add the current path to history.
            // This stops the page changing when the back button is pressed
            if (open) {
                history.push(location.pathname);
            // If the drawer is closed by clicking away
            // and if we're still on the same page, remove this path
            } else if (!open && event.type === 'click') {
                if (history.location.pathname === location.pathname) {
                    if (route) {
                        // if a route was passed into the function as well, that means a menu item was pressed
                        // and we should navigate away, after history.goBack() has finished. Add an event listener
                        // to detect once the page has gone back
                        window.addEventListener('popstate', () => {
                            return history.push(route);
                        }, { once:true });
                    }
                    history.goBack();
                }
            }
        };
    };

    useEffect(() => {
        return history.listen(() => {
            // When the back button is pressed, the temporary path will be removed automatically
            // And then we can close the drawer afterwards
            if (history.action === 'POP') {
                setDrawerToggle(false);
            }
        });
    }, [ history ]);

    const getRoutes = () => {
        return (
            routes.filter((route) => {
                return mobileNavTabs.includes(route.path);
            }).map((route, index) => {
                return <Fragment key={route.label}>
                    <ListItem
                        button
                        key={index}
                        className={classes.tabs}
                        onClick={toggleDrawer(false, route.path)}
                    >
                        <ListItemText
                            primary={route.label}
                            aria-label={route.ariaLabel}
                        />
                    </ListItem>
                    <Divider />
                </Fragment>;
            })
        );
    };

    return (
        <AppBar position="relative" className={classes.appBar}>
            <Toolbar>
                {!isEmpty(currentRoute) && mobileStickyNavTabs.includes(currentRoute.path) ?
                    <>
                        <Button
                            onClick={toggleDrawer(true)}
                            className={classes.navIconButton}
                            aria-label={t('aria_label_nav_hamburger')}
                        >
                            <MenuIcon className={classes.navIcon}/>
                        </Button>
                        <SwipeableDrawer
                            anchor={'left'}
                            open={drawerToggle}
                            onClose={toggleDrawer(false)}
                            onOpen={toggleDrawer(true)}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div
                                role="presentation"
                            >
                                <List className={classes.list}>
                                    {getRoutes()}
                                </List>
                                <Footer />
                            </div>
                        </SwipeableDrawer>
                        <img
                            className={!isEmpty(showNotifications) && showNotifications ? classes.logoForNotifications : mergeStyles(sharedClasses.logo, classes.logo)}
                            src={mobileLogo}
                            alt="myInitial Logo"
                            aria-label={t('aria_label_logo')}
                        />
                        <Profile avatar={classes.avatar}/>
                    </> :
                    <>
                        <Button
                            onClick={(event) => {
                                history.push('/');
                            }}
                            className={classes.navIconButton}
                            aria-label={t('aria_label_nav_back')}
                        >
                            <NavigateBeforeIcon className={classes.navIcon}/>
                        </Button>
                        <Typography
                            className={classes.title}
                            variant={'h1'}
                            aria-label={ariaLabel}
                            tabIndex={0}
                            aria-live={'polite'}
                        >
                            {title}
                        </Typography>
                    </>
                }
            </Toolbar>
        </AppBar>
    );
};

export default NavBarMobile;
