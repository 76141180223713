import { useCallback, useEffect, useState } from 'react';
import { ERROR, FETCHING, SUCCESS } from '../requests/requestReducer';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';
import { useAuthState } from '../../contexts/AuthProvider/AuthProvider';
import { isEmpty } from '../../utils/utils';
import { useNotificationsStore } from '../../contexts/StateProvider/StateProvider';

export const useNotifications = () => {
    const [ notifications, setNotifications ] = useState([]);
    const notificationStore = useNotificationsStore();
    const [ apiStatus, setApiStatus ] = useState(null);
    const [ { responseStatus, response }, makeRequestWithParams ] = useRegionalGCPApiRequest();
    const [ seenState, makeSeenRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/notification/list';
    const seenEndpoint = '/notification/getUserSeenNotifications';
    const { countryCode, language, businessCode } = useAuthState();

    useEffect(() => {
        if (responseStatus === SUCCESS && seenState.responseStatus === SUCCESS) {
            try {
                let allNotifications = response.data;
                let userSeenNotifications = seenState.response.data;

                if (!isEmpty(userSeenNotifications.userNotifications)) {
                    userSeenNotifications.userNotifications.forEach((n) => {
                        for (let i = 0; i < allNotifications.length; i++) {
                            if (allNotifications[i].NotificationId === n.NotificationId) {
                                allNotifications[i].SeenAt = n.SeenAt;
                            }
                        }
                    });
                }
                notificationStore.setNotifications(allNotifications);
                setApiStatus(SUCCESS);
            } catch (error) {
                console.error('useNotifications error: ', error);
            }
        } else if (responseStatus === ERROR || seenState.responseStatus === ERROR) {
            setApiStatus(ERROR);
        }
    }, [ responseStatus, response, setNotifications, seenState, notificationStore ]);

    const getNotifications = useCallback(() => {
        const url = [ endpoint, countryCode, language, businessCode ].join('/');
        setApiStatus(FETCHING);
        makeRequestWithParams(url).catch((error) => {
            console.error('useNotifications error: ', error);
        });
    }, [ makeRequestWithParams, countryCode, language, businessCode ]);

    const getSeenNotifications = useCallback(() => {
        const url = [ seenEndpoint, countryCode, language, businessCode ].join('/');
        makeSeenRequestWithParams(url).catch((error) => {
            console.error('useNotifications seenNotification error: ', error);
        });
    }, [ makeSeenRequestWithParams, countryCode, language, businessCode ]);

    return [ notifications, apiStatus, getNotifications, getSeenNotifications ];
};
