import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {Grid} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    outer: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        left: 0,
        top: 0,
        background: 'rgba(0,0,0,0.5)',
        zIndex: 9999,
        visibility: props => props.open ? 'visible' : 'hidden',
    },
    container: {
        background: 'white',
        width: props => props.containerWidth ? props.containerWidth : '250px',
        height: 'fit-content',
        margin: 0,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.sizes.micro_gap,
    },
    titleContainer: {
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileTitle,
        marginTop: theme.sizes.tiny_gap,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    contentContainer: {
        width: '100%',
    },
    iconContainer: {
        display: 'inline-flex',
        float: props => props.float ? props.float : 'right',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    icon: {
        fill: theme.palette.primary.main,
        cursor: 'pointer',
        float: props => props.float ? props.float : 'right',
        marginRight: ['-', theme.sizes.pico_gap].join('')
    }
}));

const MIMobileDialog = (props) => {
    const {t, i18n} = useTranslation();
    const float = (i18n.dir() === 'ltr') ? 'right' : 'left';
    const {title, content, open, handleClose, customStyle} = props;
    const style = Object.assign({}, customStyle, {float: float, open: open});
    const classes = useStyles(style);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    handleClose();
                }
            }

            if (open === true) {
                // Stop Scroll & Bind the event listener
                document.body.style.overflow = 'hidden';
                document.addEventListener('touchstart', handleClickOutside);

                // Unbind the event listener on clean up
                return () => document.removeEventListener('touchstart', handleClickOutside);
            } else {
                document.body.style.overflow = 'scroll';
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [ref, open]);
    }

    return (
       open && <div className={classes.outer}>
            <div className={classes.container} ref={wrapperRef}>
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    <Grid item className={classes.titleContainer}>
                        {title}
                        <div className={classes.iconContainer}>
                            <CloseIcon
                                data-testid={'close_modal_icon'}
                                aria-label={t('aria_label_close_modal_button')}
                                className={classes.icon}
                                onClick={handleClose}
                                data-dismiss='modal'
                            />
                        </div>
                    </Grid>
                    <Grid item className={classes.contentContainer}>
                        {content}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default MIMobileDialog;
