import React from "react";
import {useTranslation} from "react-i18next";
import {makeStyles, Typography} from "@material-ui/core";
import {observer} from "mobx-react-lite";
import {isEmpty, getLocalizedDate} from '../../../utils/utils';
import MIGrid from "../../Widgets/MIGrid";
import MIMobileDialog from "../../Widgets/mobile/MIMobileDialog";
import {FETCHING} from "../../../services/requests/requestReducer";

const useStyles = makeStyles(theme => ({
    bodyContainer: {
        minHeight: '150px',
        '& div.MuiDataGrid-row': {
            '&:last-child': {
                borderBottom: theme.sizes.no_gap,
            },
            borderBottom: '1px solid ' + theme.palette.grey['400'],
        },
        "& div.MuiDataGrid-cell": {
            padding: '0px',
            whiteSpace: 'normal',
        }
    },
    dataCell: {
        lineHeight: 0,
        width: '100%',
        whiteSpace: 'normal',
    },
    visitsAnnum: {
        display: 'inline-flex',
        float: 'right',
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileLabel,
    },
    quantity: {
        display: 'inline-flex',
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileLabel,
    },
    productServiced: {
        overflowWrap: 'break-word', 
        fontSize: theme.fontSizes.mobileLabel,
    },
    title: {
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileTitle
    },
    subTitle: {
        marginBottom: theme.sizes.micro_gap,
        marginTop: ['-', theme.sizes.pico_gap].join('')
    },
    date: {
        fontFamily: theme.fontFamily.proximaMedium,
        fontSize: theme.fontSizes.mobileLabel,
    },
    titleContainer: {
        display: 'inline-grid'
    },
    "&.MuiDataGrid-root": {
        "&.MuiDataGrid-cell": {
            whiteSpace: 'normal',
        }
    },
    tableHeaders: {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: theme.fontFamily.proximaMedium,
        fontSize: theme.fontSizes.mobileLabel,
        borderTop: '1px solid ' + theme.palette.grey['400'],
        borderBottom: '1px solid ' + theme.palette.grey['400'],
        padding: [theme.sizes.pico_gap, theme.sizes.no_gap].join(' '),
    }
}));


const PlannedVisitDetailsMobile = observer((props) => { 
    const {t, i18n} = useTranslation();
    const float = (i18n.dir() === 'ltr') ? 'right' : 'left';
    const classes = useStyles({float: float});
    const {plannedVisit, isShowing, onClose, rows, responseStatus} = props;  

    const getRenderCell = (params) => (
        <div className={classes.dataCell}>
            <Typography className={classes.visitsAnnum}>{isEmpty(params.row.visitsAnnum) ? '-' : params.row.visitsAnnum}</Typography>
            <Typography className={classes.quantity}>{isEmpty(params.row.qty) ? '-' : params.row.qty}</Typography>
            <div className={classes.siteAddress}>
                <Typography className={classes.productServiced}>{params.row.productServiced}</Typography>
            </div>
        </div>
    );

    const mobileColumns = [{
        field: 'productsAndQuantity', 
        headerName: [t('quantity'), t('product')].join('/'),
        headerClassName: classes.mobileGridHeader,
        type: 'string', 
        resizable: false, 
        flex: 1,
        renderCell: (params) => getRenderCell(params),
    }];

    const getHeader = () => {
        return (<div className={classes.titleContainer}
                     tabIndex={0}
                     aria-label={t('aria_label_planned_visit_details_title')}>

                    <span className={classes.title}>{t('planned_visit_details_mobile')}</span>
                </div>)
    };

    const getTableHeader = () => (
        <div className={classes.tableHeaders}>
            <span>{[t('quantity'), t('product')].join(' / ')}</span>
            <span>{t('visit_per_year')}</span>
        </div>
    );

    const getBody = () => {
        return (
            <div className={classes.bodyContainer}>
                <div className={classes.subTitle}>
                    <span className={classes.date}>
                        {isEmpty(plannedVisit.date) ? '' : getLocalizedDate(t, plannedVisit.date)}
                    </span>
                </div>
                {getTableHeader()}
                <MIGrid
                    isLoading={responseStatus === null || responseStatus === FETCHING}
                    showInfoModal={false}
                    heightToFitContent={true}
                    columns={mobileColumns}
                    rows={rows}
                    rowHeight={45}
                    noElementsText={t('no_product_serviced')}
                    ariaLabelProgressBar={t('aria_label_progressbar_planned_visit_details')}
                    noEvenRowStyling={true}
                    headerHeight={0}
                    hasFilters={false}
                    hideFooter={false}
                    pagination={true}
                    autoPageSize={false}
                    pageSize={5}
                    hideFooterForOnePage={true}
                    showRowCount={true}
                />
            </div>
        )
    };

    return (
        <MIMobileDialog title={getHeader()}
                        content={getBody()}
                        open={isShowing}
                        handleClose={onClose}
        />
    );
});

export default PlannedVisitDetailsMobile;
