import {useState, useEffect, useCallback} from 'react';
import useApiRequest from "./requests/useApiRequest";
import {SUCCESS} from "./requests/requestReducer";

export const useHygieneDocumentFolders = () => {
    const [hygieneDocumentFolders, setHygieneDocumentFolders] = useState([]);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/documentService/listInitialHygieneDocuments';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            setHygieneDocumentFolders(response.data.documentInfos);
        }
    }, [responseStatus, response, setHygieneDocumentFolders]);

    const getHygieneDocumentFolders = useCallback((countryCode) => {
        const sortBy = 'AA';
        const lobFlag = 'I';
        const serviceType = 'INITIAL';
        const url = [endpoint, lobFlag, countryCode, serviceType, sortBy].join('/');
        makeRequestWithParams(url).catch(error => {
            console.error('useHygieneDocumentFolders error: ', error);
        });
    }, [makeRequestWithParams]);

    return [hygieneDocumentFolders, responseStatus, getHygieneDocumentFolders];
};

