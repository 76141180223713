import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import MIGrid, { RenderTooltip, STYLE_HEADER_START_OVERFLOW } from '../../../components/Widgets/MIGrid';
import RefillLevels from './RefillLevels';
import { isEmpty } from '../../../utils/utils';
import { REFILL_TYPES, REFILL_SOONEST } from './SmartHygieneEnum';
import moment from 'moment';
import { PDF_WIDTH } from '../../../services/smartHygiene/useSmartHygienePDF';

const useStyles = makeStyles((theme) => {
    return {
        refillOuter: {
            display: (props) => {
                return props.asPDF ? 'block' : 'flex';
            },
            flexDirection: 'row',
            width: (props) => {
                return props.asPDF ? PDF_WIDTH : null;
            },
        },
        title: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.fontFamily.proximaBold,
            marginBottom: theme.sizes.tiny_gap,
        },
        estimates: {
            flexGrow: 7,
            marginInlineEnd: (props) => {
                return props.asPDF ? 0 : theme.sizes.smart_hygiene_spacing;
            },
        },
        levels: {
            flexGrow: 9,
        }
    };
});

const RefillEstimates = (props) => {
    const { t } = useTranslation();
    const { className, refillEstimates } = props;
    const classes = useStyles(props);

    const getLevels = useCallback((refillEmpty, refillLow, refillNotLow) => {
        let empty = '';
        let low = '';
        let notLow = '';
        if(!isEmpty(refillEstimates)) {
            empty = refillEstimates[refillEmpty];
            low = refillEstimates[refillLow];
            notLow = refillEstimates[refillNotLow];
        }
        return { empty: empty, low: low, ok: notLow };
    }, [ refillEstimates ]);

    const getNextRefill = useCallback((soonestValue) => {
        if(!isEmpty(refillEstimates)) {
            let refillDate = refillEstimates[soonestValue];
            if(!isEmpty(refillDate)) {
                let today = moment().startOf('day');
                refillDate = moment(refillDate).startOf('day');
                return today.isAfter(refillDate) ? t('Now') : [ refillDate.diff(today, 'days'), t('days') ].join(' ');
            }
        }
        return '-';
    }, [ refillEstimates, t ]);

    let gridColumns = [];
    gridColumns.push({
        field: 'refillProduct',
        headerName: t('refill_products'),
        type: 'string',
        sortable: false,
        resizable: false,
        flex: 1,
        headerClassName: STYLE_HEADER_START_OVERFLOW,
        description: t('refill_products'),
        renderCell: RenderTooltip
    });
    gridColumns.push({
        field: 'refillNext',
        headerName: t('refill_next'),
        type: 'string',
        sortable: false,
        resizable: false,
        flex: 2,
        headerClassName: STYLE_HEADER_START_OVERFLOW,
        description: t('refill_next'),
    });

    const gridRows = () => {
        let rows = [];
        rows.push({
            id: 0,
            refillProduct: t('foam'),
            refillNext: getNextRefill(REFILL_SOONEST.ANY_FOAM),
        });
        rows.push({
            id: 1,
            refillProduct: t('sanitiser'),
            refillNext: !isEmpty(refillEstimates) && refillEstimates[REFILL_TYPES.SANITISER_EMPTY] !== 0 ? getNextRefill(REFILL_SOONEST.ANY_FOAM) : '-',
        });
        rows.push({
            id: 2,
            refillProduct: t('blu'),
            refillNext: getNextRefill(REFILL_SOONEST.BLU),
        });
        return rows;
    };

    return (
        <div className={className}>
            <Typography className={classes.title}>{t('reservoir_levels')}</Typography>
            <div className={classes.refillOuter}>
                <div className={classes.estimates}>
                    <MIGrid
                        showInfoModal={false}
                        heightToFitContent={true}
                        columns={gridColumns}
                        rows={gridRows()}
                        ariaLabel={t('reservoir_levels')}
                        hasFilters={false}
                        hideFooter={true}
                        noHeaderBorder={true}
                        headerHeight={40}
                    />
                </div>
                <RefillLevels
                    className={classes.levels}
                    foam={ getLevels(REFILL_TYPES.SOAP_EMPTY, REFILL_TYPES.SOAP_LOW, REFILL_TYPES.SOAP_NOT_LOW) }
                    sanitiser={ getLevels(REFILL_TYPES.SANITISER_EMPTY, REFILL_TYPES.SANITISER_LOW, REFILL_TYPES.SANITISER_NOT_LOW) }
                    blu={ getLevels(REFILL_TYPES.BLUE_EMPTY, REFILL_TYPES.BLUE_LOW, REFILL_TYPES.BLUE_NOT_LOW) }
                />
            </div>
        </div>
    );
};

export default RefillEstimates;
