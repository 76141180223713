import { useState, useEffect, useCallback } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';
import { base64Encode, formatDate } from '../utils/utils';
import moment from 'moment';

export const EDIT_REPORT = 'EDIT_REPORT';
export const GENERATE_REPORT = 'GENERATE_REPORT';

export const useSaveReport = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const [ saveStatus, setSaveStatus ] = useState('');
    const saveReportEndpoint = '/reportService/saveReport';
    const generateReportEndpoint = '/reportService/generateReportPOST';


    const formatReport = useCallback((report, countryCode) => {
        const today = formatDate(moment());
        return {
            reportId:report.reportId,
            createdDate: today,
            countryCode: countryCode,
            reportType: 'visit information',
            premiseType: 'INITIAL',

            sentToEmail: report.sentToEmail,
            reportTitle: report.reportTitle,
            visitType: report.visitType.value,
            reportFormat: report.reportFormat,
            signatureRequired: report.signatureRequired.value,

            reportPremiseMappings: getReportPremiseMappings(report.reportPremiseMappings.rowIds || [], report.reportPremiseId),

            timePeriod: report.timePeriod !== null ? report.timePeriod.value : 'custom',
            fromDate: report.fromDate ? formatDate(report.fromDate) : formatDate(moment().subtract({ years: 1, days: 1 })),
            toDate: report.toDate ? formatDate(report.toDate) : today,

            isScheduled: report.isScheduled,
            scheduleFrequency: report.scheduleFrequency || '30',
            scheduledStartDate: report.scheduledStartDate ? formatDate(report.scheduledStartDate) : today,
        };
    }, []);

    const getReportPremiseMappings = (rowIds, reportPremiseId) => {
        let reportPremiseMappings = [];
        rowIds.forEach((item, index) => {
            let reportPremiseMapping = { premiseIds: item };
            if (reportPremiseId) {
                reportPremiseMapping.reportPremiseId = parseInt(reportPremiseId) + index;
            }
            reportPremiseMappings.push(reportPremiseMapping);
        });

        return reportPremiseMappings;
    };

    const saveReport = useCallback((endpoint, report, countryCode, locale) => {
        setSaveStatus('');
        const reportCriteria = formatReport(report, countryCode);
        let reportCriteriaStr = JSON.stringify(reportCriteria);
        const params = {
            reportCriteria: base64Encode(reportCriteriaStr),
            inputFormat: 'JSON',
            locale: locale,
            countryCode: countryCode
        };

        makeRequestWithParams(endpoint, 'post', params).catch((error) => {
            console.error('useSaveReport error: ', error);
        });
    }, [ makeRequestWithParams, formatReport ]);

    const generateReport = useCallback((report, countryCode, locale) => {
        saveReport(generateReportEndpoint, report, countryCode, locale);
    }, [ saveReport ]);

    const saveEditedReport = useCallback((report, countryCode, locale) => {
        saveReport(saveReportEndpoint, report, countryCode, locale);
    }, [ saveReport ]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            setSaveStatus(response.data.status);
        }
    }, [ responseStatus, response, setSaveStatus ]);


    return [ saveStatus, responseStatus, generateReport, saveEditedReport ];
};
