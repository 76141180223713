import { useCallback, useEffect, useState } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';
import { base64Encode } from '../utils/utils';
import { useAuthState } from '../contexts/AuthProvider';

export const useGetAudit = () => {
    const [ audit, setAudit ] = useState([]);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const { defaultLanguage, forename, surname, countryCode } = useAuthState();
    const endpoint = '/loginService/doAuditAndPrepareLogin';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setAudit(response.data);
            } catch (error) {
                console.error('useGetAudit error: ', error);
                setAudit([]);
            }
        }
    }, [ responseStatus, response, setAudit ]);

    const getAudit = useCallback((marketingPreference, isLogin) => {
        const input = {
            countryCode: countryCode,
            firstName: forename,
            lastName: surname,
            language: defaultLanguage,
            marketingPreference: marketingPreference,
            isLogin: isLogin
        };

        const params = {
            input: base64Encode(JSON.stringify(input)),
            countryCode: countryCode
        };

        makeRequestWithParams(endpoint, 'post', params).catch((error) => {
            console.error('useGetAudit error: ', error);
        });
    }, [ makeRequestWithParams, countryCode, forename, surname, defaultLanguage ]);

    return [ audit, responseStatus, getAudit ];
};
