import React from 'react';
import { ReactComponent as ClusterIcon } from '../assets/Cluster.svg';
import { makeStyles } from '@material-ui/core/styles';
import Location from './Location';
import Typography from '@material-ui/core/Typography';

export const CLUSTER_ICON_SIZE = 48;

const useStyles = makeStyles((theme) => ({
    icon: {
        position: 'absolute',
        width: `${CLUSTER_ICON_SIZE }px`,
        height: `${CLUSTER_ICON_SIZE }px`,
    },
    outer: {
        width: `${CLUSTER_ICON_SIZE }px`,
        height: `${CLUSTER_ICON_SIZE }px`,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.contrastText,
    },
}));

const Cluster = (props) => {
    const { count, midPoint, ids, hasSelection } = props;
    const classes = useStyles();

    return (
        <Location
            properties={ids}
            component={
                <div size={{ width: CLUSTER_ICON_SIZE, height: CLUSTER_ICON_SIZE }}>
                    <ClusterIcon className={classes.icon}/>
                    <div className={classes.outer}>
                        <Typography variant="body1">{count}</Typography>
                    </div>
                </div>
            }
            centre={{ x: CLUSTER_ICON_SIZE / 2, y: CLUSTER_ICON_SIZE / 2 }}
            coordinates={midPoint}
            isSelected={hasSelection}
        />
    );
};


export default Cluster;
