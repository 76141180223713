import React, { useRef, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import { useResponsive } from '../../services/useResponsive';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import NotificationItem from './NotificationItem';
import { isEmpty } from '../../utils/utils';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { BottomNavigationAction } from '@material-ui/core';
import { BottomNavigation } from '@material-ui/core';
import Theme from '../Theme/Theme';
import { Link } from 'react-router-dom';
import { ResponsiveComponent } from '../../services/useResponsive';
import { useNotifications } from '../../services/notifications/useNotifications';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import { useNotificationsStore} from '../../contexts/StateProvider/StateProvider';
import { observer } from 'mobx-react-lite';
import { useMarkAllAsRead } from '../../services/notifications/useMarkAllAsRead';

const desktopStyles = (theme) => {
    return {
        menu: {
            maxHeight: '340px',
            overflowY: 'scroll',
        },
        notification: {
            paddingRight: theme.sizes.small_gap,
            margin: 'auto',
            flexDirection: 'column',
            justifyContent: 'center',
            display: 'flex',
        },
        notificationIcon: {
            color: theme.colors.notificationsIcon,
            fontSize: 'xx-large',
        },
        notificationHeader: {
            borderBottom: '1px solid #e0e0e0',
            height: '50px',
            lineHeight: '50px',
        },
        notificationTitle: {
            fontWeight: 'bold',
            fontSize: '14px',
            marginLeft: '-15px',
        },
        margin: {
            'margin': theme.spacing(2),
            '& .MuiBadge-colorSecondary': {
                backgroundColor: theme.colors.notificationRed
            }
        },
        popper: {
            zIndex: 200,
            minHeight: '240px',
            maxHeight: '416px',
            display: 'flex',
            overflow: 'auto',
        },
        notificationTitleText: {
            display: 'flex',
            marginLeft: theme.sizes.small_gap,
            paddingTop: theme.sizes.tiny_gap,
            fontWeight: 'bold',
            fontSize: theme.sizes.xsmall_gap,
        },
        topMenu: {
            display: 'flex',
            paddingBottom: theme.sizes.pico_gap,
            borderBottom: `1px solid${ theme.palette.grey['300']}`,
        },
        selectLink: {
            alignContent: 'flex-end',
            marginLeft: 'auto',
            marginTop: 'auto',
            marginRight: theme.sizes.small_gap,
            textDecoration: 'none',
        },
        markAllAsRead: {
            'fontWeight': 'bold',
            'color': theme.palette.primary.main,
            '&:hover': {
                cursor: 'pointer',
            }
        },
        viewAllNotification: {
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            fontSize: theme.fontSizes.subHeading,
        },
        noNotifications: {
            width: '300px',
            marginTop: '80px',
        },
        dataContainer: {
            'backgroundColor': '#fff',
            '& .MuiList-padding': {
                paddingTop: 0,
                paddingBottom: 0,
                marginTop: '-1px',
            }
        },
        bottomNavigation: {
            'width': '100%',
            'height': '40px',
            'position': 'fixed',
            'bottom': 0,
            'borderTop': `1px solid${ theme.palette.grey['300']}`,
            '& .MuiBottomNavigationAction-label': {
                fontSize: theme.fontSizes.subHeading,
            },
        },
        menuList: {
            maxHeight: '300px',
            overflowY: 'scroll',
            marginBottom: '40px',
        }
    };
};
const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        notification: {
            ...baseStyle.notification,
            margin: theme.sizes.no_gap,
        },
        margin: {
            'margin': 0,
            '& .MuiBadge-colorSecondary': {
                backgroundColor: theme.colors.notificationRed
            }
        },
        noNotifications: {
            width: '300px',
            marginTop: '65px',
            marginLeft: theme.sizes.small_gap,
            display: 'flex',
            justifyContent: 'center',
        },
    });
};

const menuItemList = (notifications, handleToggle) => {
    const maxNotifications = 10;
    const notificationsList = notifications.slice(0, maxNotifications);
    return notificationsList.map((notification, index) => {
        return (
            <NotificationItem
                key={index}
                index={index}
                notification={notification}
                handleToggle={handleToggle}
                borderTop={0}
                borderBottom={Theme.borders.outline_regular}
            />
        );
    });
};

const Notification = observer(() => {
    const { t } = useTranslation();
    const [ userOpen, setUserOpen ] = useState(false);
    const notificationsStore = useNotificationsStore();
    const anchorRef = useRef(null);
    const [ value, setValue ] = useState(-1);
    const { isMobile, getStyles } = useResponsive();
    const history = useHistory();
    const { feature_notifications } = useFlags();
    const [ , , getNotifications, getSeenNotifications ] = useNotifications();
    const [ , , markAllAsRead ] = useMarkAllAsRead();

    const getNotificationsList = useCallback(() => {
        getNotifications();
        getSeenNotifications();
    }, [ getNotifications, getSeenNotifications ]);

    useEffect(() => {
        // update list every time the user clicks the bell icon
        if (feature_notifications && userOpen) {
            getNotificationsList();
        }
    }, [ getNotificationsList, feature_notifications, userOpen ]);

    useEffect(() => {
        // update list on the first render (ie. user login)
        if (feature_notifications) {
            getNotificationsList();
        }
    }, [ getNotificationsList, feature_notifications ]);

    useEffect(() => {
        // update list every time the user clicks a notification from the notification table
        if (feature_notifications && !isEmpty(notificationsStore.lastNotificationSeen)) {
            getNotificationsList();
        }
    }, [ getNotificationsList, feature_notifications, notificationsStore.lastNotificationSeen ]);

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    });

    const classes = useStyles();

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setUserOpen(false);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setUserOpen(false);
        }
    };

    const handleMarkAllAsRead = (event) => {
        markAllAsRead();
    };

    const badgeCount = useCallback(() => {
        const count = notificationsStore.notifications.filter((x) => {
            return isEmpty(x.SeenAt);
        }).length;
        return count;
    }, [ notificationsStore.notifications ]);

    const handleToggle = () => {
        if (isMobile) {
            history.push({
                pathname: '/support/messages',
            });
        }

        return setUserOpen((prevOpen) => {
            return !prevOpen;
        });
    };

    const notificationHeader = () => {
        return (
            <div className={classes.topMenu}>
                <Typography className={classes.notificationTitleText}>
                    {t('notification_menu_title')}
                </Typography>
                <Link
                    className={classes.selectLink}
                    onClick={handleMarkAllAsRead}
                    aria-label={t('aria_label_messages_link')}
                >
                    <Typography nowrap={'true'} className={classes.markAllAsRead} variant="body2">
                        {t('mark_all_as_read')}
                    </Typography>
                </Link>
            </div>
        );
    };

    const showList = useCallback(() => {
        return <MenuList
            className={classes.notificationMenu}
            autoFocusItem={userOpen}
            data-testid="menu-list-grow"
            onKeyDown={handleListKeyDown}
        >
            {menuItemList(notificationsStore.notifications, handleToggle)}
        </MenuList>;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ notificationsStore.notifications ]);

    const getPopper = () => {
        return <Popper
            open={userOpen}
            anchorEl={anchorRef.current}
            placement={'bottom-end'}
            role={undefined}
            transition
            disablePortal
            className={classes.popper}>
            {({ TransitionProps, placement }) => {
                return <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'left bottom' ? 'left top' : 'right top' }}
                >
                    <Paper className={classes.notificationMenuOuter}>
                        {notificationHeader()}
                        <div className={classes.menuList}>
                            <ClickAwayListener mouseEvent={'onMouseUp'} onClickAway={handleClose}>
                                <div className={classes.dataContainer}>
                                    {!isEmpty(notificationsStore.notifications) ?
                                        showList() :

                                        <Typography className={classes.noNotifications}>
                                            {t('no_notifications')}
                                        </Typography>
                                    }
                                </div>
                            </ClickAwayListener>
                        </div>
                        <BottomNavigation
                            className={classes.bottomNavigation}
                            showLabels
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue);
                                history.push('/support/messages');
                            }}
                        >
                            <BottomNavigationAction
                                key={'/support/messages'}
                                label={t('view_all')}
                                aria-label={t('view_all')}
                                className={classes.viewAllNotification}
                            />
                        </BottomNavigation>
                    </Paper>
                </Grow>;
            }}
        </Popper>;
    };

    return (
        <div className={classes.notification}>
            <Badge
                ref={anchorRef}
                className={classes.margin}
                badgeContent={badgeCount()}
                overlap="circular"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                color="secondary"
                invisible={badgeCount() === 0}
                onClick={handleToggle}
            >
                <NotificationsIcon
                    className={classes.notificationIcon}
                    alt={t('notifications_icon')}
                    aria-label={t('aria_label_notifications_icon')}
                    id="notification_icon"
                />
            </Badge>
            <ResponsiveComponent
                components={{
                    desktop: getPopper(),
                    mobile: null
                }}
            />
        </div>
    );
});

export default Notification;
