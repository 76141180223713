import React, { memo } from 'react';
import { groupByLocationId, isObjectEmpty } from '../../utils/utils';
import Location from '../Location';
import { CLUSTER_ICON_SIZE } from '../Cluster';
import { getClusterId, isVisible, scalePosition } from '../../utils/clusters';
import Bullseye from '../components/Bullseye';

// add small offset to move the rings around the detector icon
const BULLSEYE_OFFSET = { x: -50, y: -47 };
const BULLSEYE_CLUSTER = { x: -42, y: -38 };

const BullseyeImpl = (props) => {
    const { points, clusters, scale, width, height, translation, handleClick, activities } = props;

    const getBullseyeClusters = () => {
        if (isObjectEmpty(clusters) || isObjectEmpty(activities)) {
            return [];
        }

        const activitiesMap = groupByLocationId(activities);
        let bullseyeClusters = [];

        clusters.singles.forEach((index) => {
            //if is visible and there are activities show the icon
            const point = points[index];
            const pointActivities = activitiesMap[point?.properties?.location?.id]
            if (!isObjectEmpty(point) && isVisible(point.coordinates, scale, translation, width, height, BULLSEYE_OFFSET)
                && !isObjectEmpty(pointActivities)
            ) {
                bullseyeClusters.push(
                    <Location
                        key={point.id}
                        properties={index}
                        label={false}
                        component={<Bullseye pointActivities={pointActivities} id={point.id}/>}
                        coordinates={scalePosition(point.coordinates, point.centre, scale, translation, BULLSEYE_OFFSET)}
                        centre={point.centre}
                        handleDetectorClick={handleClick}
                        isSelected={false}
                    />
                );
            }
        });

        clusters.clusters.forEach((cluster, clusterIndex) => {
            const midPoint = clusters.midpoints[clusterIndex];
            if (isVisible(midPoint, scale, translation, width, height, BULLSEYE_CLUSTER)) {
                // merge total activities for this cluster
                let clusterActivities = cluster.reduce((currentList, index) => {
                    const point = points[index];
                    const pointActivities = activitiesMap[point?.properties?.location?.id]
                    return isObjectEmpty(pointActivities) ? currentList : currentList.concat(pointActivities);
                }, []);

                if (!isObjectEmpty(clusterActivities)) {
                    const clusterId = getClusterId('cluster_bullseye', midPoint)
                    bullseyeClusters.push(
                        <Location
                            key={clusterId}
                            properties={null}
                            component={<Bullseye pointActivities={clusterActivities} id={clusterId} isCluster={true}/>}
                            centre={{ x: CLUSTER_ICON_SIZE / 2, y: CLUSTER_ICON_SIZE / 2 }}
                            coordinates={scalePosition(midPoint, {
                                x: CLUSTER_ICON_SIZE / 2,
                                y: CLUSTER_ICON_SIZE / 2
                            }, scale, translation, BULLSEYE_CLUSTER)}
                            isSelected={false}
                        />
                    );
                }
            }
        });
        return bullseyeClusters;
    };

    return (
        <div id={'bullseye-layer'}>
            {getBullseyeClusters()}
        </div>
    );
};

export const BullseyeLayer = memo(BullseyeImpl);
