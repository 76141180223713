import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { MIButton } from '../../rentokil-react-ui';
// import MIButton from '../../components/Widgets/MIButton';
import PlannedVisits from '../../components/PlannedVisits/PlannedVisits';
import SupportBox from '../../components/SupportBox/SupportBox';
import HelpfulInfo from '../../components/Marketing/HelpfulInfo';
import VisitHistory from '../../components/VisitHistory/VisitHistory';
import SiteSelector from '../../components/SiteSelector/SiteSelector';
import { Link as RouterLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { setDocumentTitle } from '../../utils/utils';
import { useResponsive, ResponsiveComponent } from '../../services/useResponsive';

const desktopStyles = (theme) => {
    return {
        pageOuter: {
            background: theme.palette.background.default,
            display: 'block',
        },
        root: {
            maxWidth: '928px',
            minHeight: '500px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.xlarge_gap,
            paddingBottom: theme.sizes.small_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            border: theme.borders.mainContent,
            background: theme.palette.background.paper,
        },
        title: {
            'display': 'inline-block',
            '&:focus': theme.ariaFocus,
            'padding': '2px',
        },
        historyTitleOuter: {
            display: 'flex',
            height: theme.sizes.xxxlarge_gap,
            alignItems: 'center',
            justifyContent: 'center-left',
            width: '100%',
            marginBottom: '8px',
            marginTop: theme.sizes.xlarge_gap,
        },
        actions: {
            marginStart: 'auto',
        },
        bottomSpacer: {
            height: theme.sizes.xlarge_gap,
        },
        support: {
            display: 'inline-flex',
            marginLeft: theme.sizes.micro_gap,
            width: '288px'
        },
        visitHistoryLink: {
            '&:focus': {
                outline: 'none',
            }
        },
        helpfulInfo: {
            marginTop: theme.sizes.micro_gap,
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.large_gap,
            paddingRight: '0px',
            paddingLeft: '0px',
        },
        content: {
            paddingRight: theme.sizes.small_gap,
            paddingLeft: theme.sizes.small_gap,
        },
        title: {
            fontSize: theme.fontSizes.mobileTitle,
            marginTop: theme.sizes.small_gap,
        },
        historyTitleOuter: {
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
        },
        actions: {
            display: 'none'
        },
        helpfulInfo: {
            marginStart: theme.sizes.small_gap,
            marginEnd: theme.sizes.small_gap,
            paddingLeft: theme.sizes.pico_gap,
            paddingRight: theme.sizes.pico_gap,
        },
    });
};


const Home = () => {
    const { t } = useTranslation();
    const maxRowsInHomePage = 3;
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );
    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_home');
    }, [ t ]);

    return (
        <div className={classes.pageOuter} aria-label={t('aria_label_home_page')}>
            <Paper className={classes.root}>
                <SiteSelector/>
                <div className={classes.content} >
                    <div className={classes.historyTitleOuter}>
                        <h1 className={classes.title} tabIndex={0}
                            aria-label={t('aria_label_visit_history_title')}>
                            {t('visit_history')}
                        </h1>
                        <div className={classes.actions}>
                            <RouterLink to="/service/visit-history" className={classes.visitHistoryLink}>
                                <MIButton
                                    mi_size="small"
                                    mi_type="secondary"
                                    ariaLabel={t('aria_label_view_all_button')}
                                >
                                    {t('view_all')}
                                </MIButton>
                            </RouterLink>
                        </div>
                    </div>
                    <VisitHistory
                        mobilePageSize={3}
                        maxRows={maxRowsInHomePage}
                        hasFilters={false}
                        hideFooter={true}
                    />
                    <PlannedVisits/>
                    <ResponsiveComponent
                        components={{
                            mobile: null,
                            desktop:
                                <>
                                    <div className={classes.support}>
                                        <SupportBox/>
                                    </div>
                                    <div className={classes.helpfulInfo}>
                                        <HelpfulInfo/>
                                    </div>
                                </>
                        }}
                    />
                </div>
            </Paper>
            <div className={classes.bottomSpacer}/>
        </div>
    );
};

export default Home;
