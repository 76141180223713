import { useCallback } from 'react';
import useApiRequest from './useApiRequest';

const useFloorPlanApiRequest = () => {
    const [ state, makeRequestWithParams ] = useApiRequest();

    const makeFloorPlanApiRequestWithParams = useCallback((url, requestMethod = 'get', requestParams = {}) => {
        return makeRequestWithParams(url, requestMethod, requestParams, null);
    }, [ makeRequestWithParams ]);


    return [ state, makeFloorPlanApiRequestWithParams ];
};

export default useFloorPlanApiRequest;
