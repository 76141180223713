import React from 'react';
import { useTranslation } from 'react-i18next';
import { FETCHING } from '../../../services/requests/requestReducer';
import MIGrid, {RenderTooltip, STYLE_HEADER_CENTER} from '../../Widgets/MIGrid';
import QuarterlyReturnsDocument from '../QuarterlyReturnsDocument';

const QuarterlyReturnsTableDesktop = (props) => {
    const { t } = useTranslation();
    const { responseStatus, rows } = props;

    const columns = [
        {
            field: 'date',
            headerName: t('date'),
            type: 'date',
            resizable: false,
            flex: 0.3,
            renderCell: (params) => {
                return <span>{params.row.locDate}</span>;
            }

        },
        {
            field: 'title',
            headerName: t('title'),
            type: 'string',
            sortable: false,
            filterable: false,
            resizable: false,
            flex: 2,
            renderCell: RenderTooltip
        },
        {
            field: 'download',
            headerName: t('download_note'),
            resizable: false,
            filterable: false,
            flex: 0.5,
            sortable: false,
            headerClassName: STYLE_HEADER_CENTER,
            renderCell: (params) => {
                return <QuarterlyReturnsDocument
                    filePath={params.row.filePath}
                />;
            }
        },
    ];

    return (
        <MIGrid
            showInfoModal={true}
            isLoading={responseStatus === FETCHING}
            heightToFitContent={true}
            hasFilters={true}
            columns={columns}
            rows={rows}
            hideFooterForOnePage={true}
            noElementsText={t('no_quarterly_returns')}
            ariaLabel={t('aria_label_quarterly_returns_table')}
            ariaLabelProgressBar={t('aria_label_progressbar_quarterly_returns')}
        />
    );
};

export default QuarterlyReturnsTableDesktop;
