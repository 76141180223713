import React from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import MIGrid, {RenderTooltip} from '../../Widgets/MIGrid';
import {FETCHING} from '../../../services/requests/requestReducer';

const UpdateLogsDesktop = observer((props) => {
    const {t} = useTranslation();
    const {rows, responseStatusComments} = props;

    const columns = [
        {
            field: 'date',
            headerName: t('date'),
            type: 'date',
            resizable: false,
            flex: 1.3,
            description: t('date'),
            renderCell: (params) => (
                <span>{params.row.locDate}</span>
            )
        },
        {
            field: 'updatedBy',
            headerName: t('updated_by'),
            type: 'string',
            resizable: false,
            flex: 2,
            description: t('updated_by'),
            renderCell: RenderTooltip
        },
        {
            field: 'status',
            headerName: t('status'),
            type: 'string',
            resizable: false,
            flex: 2,
            description: t('status'),
            renderCell: RenderTooltip
        },
        {
            field: 'details',
            headerName: t('details_log'),
            type: 'string',
            resizable: false,
            flex: 5,
            description: t('details_log'),
            renderCell: RenderTooltip
        },
    ];

    return (
        <MIGrid
            heightToFitContent={true}
            colCellFlex={true}
            columns={columns}
            noElementsText={t('no_logs')}
            ariaLabel={t('aria_label_logs_table')}
            ariaLabelProgressBar={t('aria_label_progressbar_logs_table')}
            hasFilters={true}
            hideFooterRowCount={true}
            pagination={true}
            rows={rows}
            isLoading={responseStatusComments === FETCHING}
            hideFooterForOnePage={true}
        />
    );
});

export default UpdateLogsDesktop;
