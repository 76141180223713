import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MIButton from '../../Widgets/MIButton';
import { isEmpty} from '../../../utils/utils';
import { observer } from 'mobx-react-lite';
import MIPageTitle from '../../Widgets/MIPageTitle';
import MIApiLoader from '../../Widgets/MIApiLoader';
import MITabs from '../../Widgets/MITabs';
import UpdateLogs from '../UpdateLogs';
import Theme from '../../Theme/Theme';

const useStyles = makeStyles((theme) => {
    return {
        content: {
            paddingLeft: theme.sizes.micro_gap,
            paddingRight: theme.sizes.micro_gap,
        },
        infoGrid: {
            marginTop: theme.sizes.small_gap,
        },
        rowContainer: {
            marginTop: theme.sizes.pico_gap,
            marginBottom: theme.sizes.pico_gap,
        },
        columnLeft: {
            width: theme.widths.recommendation_details_rows,
        },
        buttonContainer: {
            display: 'block',
            marginBottom: theme.sizes.pico_gap,
        },
        pdfLink: {
            fontWeight: 'bold',
            minWidth: theme.sizes.xxlarge_gap,
            fontSize: theme.fontSizes.mobileBody
        },
        icon: {
            marginBottom: [ '-', theme.sizes.pico_gap ].join(''),
        },
        goBack: {
            color: theme.palette.primary.main,
            display: 'inline',
            width: 'fit-content',
            border: 'none',
        },
        title: {
            fontFamily: theme.fontFamily.proximaBold,
            fontSize: theme.fontSizes.mobileBody,
        },
        data: {
            fontFamily: theme.fontFamily.proximaMedium,
            fontSize: theme.fontSizes.mobileBody,
            textAlign: 'justify'
        },
        downloadContainer: {
            minWidth: theme.widths.download_buttons, 
        }
    };
});

const RecommendationDetailsMobile = observer((props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const {
        id,
        rows,
        responseStatusDetails,
        downloadPdf,
    } = props;

    const showInfoGrid = () => (
        <Grid
            container
            direction='column'
            justifycontent='space-between'
            alignItems='flex-start'
            className={classes.infoGrid}
        >
            {rows.map((data, index) => (
                <Grid container key={index} className={classes.rowContainer}>
                    <Grid item xs={12}>
                        <Typography className={classes.title}> {!isEmpty(data.title) ? [data.title, ':'].join('') : ''} </Typography>
                    </Grid>
                    {data.value.map((rowData, index) => (
                        !(rowData === '-' && index > 0) ?
                            <Grid item xs={12} key={index}>
                                {typeof rowData === 'string' ? <Typography className={classes.data}> {!isEmpty(rowData) ? rowData : '-'} </Typography> : rowData}
                            </Grid> : null
                    ))}
                </Grid>
            ))}
        </Grid>
    );

    return (
        <div>
            <div className={classes.buttonContainer}>
                <div>
                    <MIButton
                        className={classes.goBack}
                        variant={'outlined'}
                        startIcon={<ArrowBackIcon className={classes.icon}/>}
                        onClick={history.goBack}>
                        {t('go_back')}
                    </MIButton>
                </div>
            </div>
            <div className={classes.content}>
                <MIPageTitle 
                    title={t('recommendation_details')}
                    ariaLabel={t('aria_label_recommendation_details_title')}
                />
                <MIApiLoader
                    MIComponent={ 
                        <div>
                            {downloadPdf}
                            <MITabs 
                                tabs={[
                                    {
                                        label: t('details'),
                                        groupId: 1,
                                        table: showInfoGrid()
                                    },
                                    {
                                        label: t('update_logs'),
                                        groupId: 2,
                                        table: <UpdateLogs id={id} />
                                    }]} 
                                key='site_level_tabs' 
                            />
                        </div> 
                    }
                    responseStatus={responseStatusDetails}
                    ariaLabelProgressBar={t('aria_label_progressbar_recommendation_details')}
                    customStyle={{
                        marginTop: Theme.sizes.micro_gap,
                    }}
                />
            </div>
        </div>
    );
});

export default RecommendationDetailsMobile;
