import {makeAutoObservable} from "mobx";

class RecommendationsStore {
    recommendations = {};
    PNOLShutdownCountries = []

    constructor() {
        makeAutoObservable(this);
    }

    getRecommendations(siteId) {
        return this.recommendations[siteId];
    }

    setRecommendations(siteId, recommendationsList) {
        this.recommendations[siteId] = recommendationsList;
    }

    setPNOLShutdownCountries(PNOLShutdownCountries) {
        this.PNOLShutdownCountries = PNOLShutdownCountries;
    }

    getPNOLShutdownCountries() {
        return this.PNOLShutdownCountries;
    }

    reset() {
        this.recommendations = {};
        this.PNOLShutdownCountries = [];
    }
}

export {RecommendationsStore};
