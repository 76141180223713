import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FETCHING } from '../../../services/requests/requestReducer';
import MIGrid, { STYLE_HEADER_CENTER } from '../../Widgets/MIGrid';
import GetAppIcon from '@material-ui/icons/GetApp';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => {
    return {
        linkContainer: {
            margin: 'auto',
            textAlign: 'center',
        },
        selectLink: {
            'display': 'flex',
            '&, &:visited, &:hover': {
                color: theme.palette.primary.main
            },
            '&:focus': theme.ariaFocus,
        },
        signatureIcon: {
            margin: 'auto',
            textAlign: 'center',
            color: 'grey',
        },
        pdfIconContainer: {
            display: 'flex',
            marginLeft: '-3px',
            width: 'fit-content',
        },
    };
});


const WasteTransferNotesTableDesktop = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { responseStatus, rows } = props;

    const columns = [
        {
            field: 'date', headerName: t('date'), type: 'date', resizable: false, flex: 1.5,
            renderCell: (params) => {
                return <span>{params.row.date}</span>;
            }

        },
        {
            field: 'title',
            headerName: t('title'),
            type: 'string',
            sortable: false,
            filterable: false,
            resizable: false,
            flex: 2
        },
        {
            field: 'download', headerName: t('download_note'), resizable: false, filterable: false, flex: 1,
            sortable: false,
            headerClassName: STYLE_HEADER_CENTER,
            renderCell: (params) => {
                return <div className={classes.linkContainer}>
                    <Link
                        href={params.row.downloadUrl}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        aria-label={t('aria_label_transfer_notes_download_icon')}
                    >
                        <div className={classes.pdfIconContainer}>
                            <GetAppIcon className={classes.icon}/>
                        </div>
                    </Link>
                </div>;
            }
        },
    ];

    return (
        <MIGrid
            showInfoModal={true}
            isLoading={responseStatus === FETCHING}
            heightToFitContent={true}
            hasFilters={true}
            columns={columns}
            rows={rows}
            hideFooterForOnePage={true}
            noElementsText={t('no_waste_transfer_notes')}
            ariaLabel={t('aria_label_waste_transfer_notes_table')}
            ariaLabelProgressBar={t('aria_label_progressbar_waste_transfer_notes')}
        />
    );
};

export default WasteTransferNotesTableDesktop;
