import React, { memo, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@material-ui/core/LinearProgress';
import MIPagination from './MIPagination';
import { XGrid, GridFilterToolbarButton, GridOverlay, LicenseInfo } from '@material-ui/x-grid';
import { isEmpty, getBool } from '../../utils/utils';
import MIToolTip from './MIToolTip';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import Button from '@material-ui/core/Button';
import InfoIcon from '@material-ui/icons/Info';
import MIInfoModal from './MIInfoModal';
import { useResponsive } from '../../services/useResponsive';

export const STYLE_HEADER_CENTER = 'MIGrid-center-header';
export const CLICKABLE_ICON_CELL = 'clickable-icon';
export const STYLE_HEADER_LEFT = 'MIGrid-left-header';
export const STYLE_HEADER_RIGHT = 'MIGrid-right-header';
export const STYLE_HEADER_CENTER_OVERFLOW = 'MIGrid-center-header-overflow';
export const STYLE_HEADER_START_OVERFLOW = 'MIGrid-start-header-overflow';
const DEFAULT_HEADER_HEIGHT = 50;
const HEADER_PADDING = 24;
const DEFAULT_ROW_HEIGHT = 44;
const ROW_PADDING = 14;
const PAGINATION_HEIGHT = 58;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_HEIGHT = 900;
const FILTER_OFFSET = 70;
const MOBILE_GRID_BASE_HEIGHT = 60;
const MOBILE_GRID_OUTER_ROW_HEIGHT = 71;
LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_LICENSE);

const useStyles = makeStyles((theme) => {
    return {
        container: {
            marginTop: (props) => {
                return props.hasFilters ? -FILTER_OFFSET : '0px';
            },
            width: (props) => {
                return props.width ? props.width : '100%';
            },
        },
        innerContainer: {
            'flexGrow': 1,
            '& .rightAligned': {
                justifyContent: 'flex-end',
            },
        },
        toolbar: {
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '0px',
        },
        grid: {
            'border': 'none',
            '& div.MuiDataGrid-columnSeparator': {
                display: 'none',
            },
            '& div.MuiDataGrid-columnsContainer': {
                backgroundColor: theme.palette.background.paper,
                borderTop: (props) => {
                    return props.headerHeight === 0 || props.noHeaderBorder ?
                        'none' :
                        props.error ?
                            `8px solid ${ theme.palette.error.dark}` :
                            `8px solid ${ theme.palette.grey['200']}`;
                },
                border: 'none',
            },
            '& div.MIGrid-center-header': {
                justifyContent: 'center',
                display: 'grid',
            },
            '& div.MIGrid-center-header-overflow': {
                justifyContent: 'center',
                display: 'flex',
                overflow: 'hidden',
            },
            '& div.MIGrid-start-header-overflow': {
                justifyContent: 'start',
                display: 'flex',
                overflow: 'hidden',
            },
            '& div.MIGrid-left-header': {
                justifyContent: 'left',
                display: 'flex',
            },
            '& div.MuiDataGrid-toolbar': {
                display: 'flex',
                justifyContent: 'flex-end',
                padding: '0px',
            },
            '& button.MuiButton-root': {
                'textTransform': 'none',
                'marginBottom': (props) => {
                    return props.filterMargin ? props.filterMargin : theme.sizes.micro_gap;
                },
                'fontFamily': theme.typography.h1.fontFamily,
                'fontSize': theme.typography.body1.fontSize,
                'height': '48px',
                'width': '128px',
                'border': '1px solid #CDD2D5',
                'borderRadius': '4px',
                '&:hover': {
                    background: theme.palette.background.paper,
                    boxShadow: 'rgba(205, 210, 213, 1) 0px 2px 5px 1px',
                },
                '&.Mui-focusVisible': {
                    borderColor: '#007DC5',
                    boxShadow: 'theme.palette.primary.main 0px 1px 9px 1px',
                },
                '&.MuiButton-contained.Mui-disabled': {
                    color: '#354A55',
                },
                '&.MuiButton-outlinedSizeSmall': {
                    width: '15px',
                },
            },
            '& div.MuiDataGrid-colCellWrapper': {
                borderLeft: (props) => {
                    return props.error ? `1px solid ${ theme.palette.error.dark}` : '';
                },
                borderRight: (props) => {
                    return props.error ? `1px solid ${ theme.palette.error.dark}` : '';
                },
            },
            '& div.MuiDataGrid-window': {
                borderLeft: (props) => {
                    return props.error ? `1px solid ${ theme.palette.error.dark}` : '';
                },
                borderRight: (props) => {
                    return props.error ? `1px solid ${ theme.palette.error.dark}` : '';
                },
                overflowY: 'hidden !important',
            },
            '& div.MuiDataGrid-footer': {
                borderLeft: (props) => {
                    return props.error ? `1px solid ${ theme.palette.error.dark}` : '';
                },
                borderRight: (props) => {
                    return props.error ? `1px solid ${ theme.palette.error.dark}` : '';
                },
                borderBottom: (props) => {
                    return props.error ? `1px solid ${ theme.palette.error.dark}` : '';
                },
            },
            '& div.MuiDataGrid-colCell': {
                borderBottom: 'none',
                whiteSpace: 'nowrap',
                height: theme.sizes.mega_gap,
                padding: `0px ${ theme.sizes.micro_gap}`,
            },
            '& div.MuiDataGrid-colCellTitleContainer': {
                alignItems: 'center',
                flex: (props) => {
                    return props.colCellFlex ? '' : 'none';
                },
            },
            '& div.MuiDataGrid-colCellTitle': {
                fontWeight: theme.typography.fontWeightBold,
                fontSize: theme.typography.body1.fontSize,
            },
            '& div.MuiDataGrid-row': {
                'backgroundColor': (props) => {
                    return props.noEvenRowStyling ? '' : theme.palette.grey['50'];
                },
                'borderBottom': (props) => {
                    return props.noEvenRowStyling ? `1px solid ${ theme.palette.grey['300']}` : '';
                },
                'cursor': (props) => {
                    return props.onRowSelected ? 'pointer' : 'default';
                },
                '&:hover': {
                    background: (props) => {
                        return props.noEvenRowStyling ? 'inherit' : `${theme.palette.grey['300'] } !important`;
                    },
                },
            },
            '& div.MuiDataGrid-cell': {
                border: 'none',
                height: theme.sizes.mega_gap,
                padding: (props) => {
                    return [ theme.sizes.no_gap, props.noDataGridPadding ? theme.sizes.no_gap : theme.sizes.micro_gap ].join(' ');
                },
                fontSize: theme.typography.body1.fontSize,
                fontWeight: theme.typography.fontWeightMedium,
            },
            '& div.Mui-even': {
                backgroundColor: (props) => {
                    return props.noEvenRowStyling ? '' : theme.palette.grey['200'];
                },
            },
            '& div.MuiDataGrid-overlay': {
                height: theme.sizes.xlarge_gap,
            },
            '& div.MuiDataGrid-iconButtonContainer': {
                width: 'fit-content',
                margin: 'auto',
                paddingRight: '3px',
            }
        },
        noElementsText: {
            'color': theme.colors.noData,
            'fontSize': theme.sizes.small_gap,
            'fontFamily': theme.typography.fontFamily,
            'fontWeight': 'bold',
            '&:focus': theme.ariaFocus,
        },
        loadingOverlay: {
            position: 'absolute',
            top: 0,
            width: '100%',
        },
        typography: {
            width: '650px',
            lineHeight: '2.3',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightMedium,
        },
        keyButton: {
            marginRight: '5px',
        },
        inline: {
            display: 'inline',
        },
        icon: {
            fontSize: 'small',
        },
    };
});

const desktopGetRowCountStyles = (theme) => {
    return {
        rowCount: {
            marginBottom: theme.sizes.xlarge_gap,
            fontSize: theme.fontSizes.subHeading,
        },
    };
};

const mobileGetRowCountStyles = (theme) => {
    const baseStyle = desktopGetRowCountStyles(theme);

    return Object.assign(baseStyle, {
        rowCount: {
            padding: theme.sizes.small_gap,
            fontSize: theme.fontSizes.mobileLabel,
        },
    });
};

export const RenderTooltip = (params) => {
    return <MIToolTip {...params} value={params.value}/>;
};

export const GetRowCount = (params) => {
    const { rows, showRowCount } = params;
    const { t } = useTranslation();
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopGetRowCountStyles(theme),
            mobile: mobileGetRowCountStyles(theme)
        });
    }
    );

    const classes = useStyles();

    return (
        getBool(showRowCount) && !isEmpty(rows) &&
            <div
                className={classes.rowCount}
                aria-label={t('aria_label_table_row_count')}
            >
                <span>
                    {[ rows.length, rows.length === 1 ? t('item') : t('items') ].join(' ')}
                </span>
            </div>
    );
};

const MIGrid = (props) => {
    let filterProps = {};
    const { t } = useTranslation();
    const [ page, setPage ] = React.useState(0);
    const [ height, setHeight ] = React.useState(DEFAULT_HEIGHT);
    const { showInfoModal } = props;

    const localeText = {
        errorOverlayDefaultLabel: t('errorOverlayDefaultLabel'),
        toolbarFilters: t('toolbarFilters'),
        toolbarFiltersLabel: t('toolbarFiltersLabel'),
        toolbarFiltersTooltipHide: t('toolbarFiltersTooltipHide'),
        toolbarFiltersTooltipShow: t('toolbarFiltersTooltipShow'),
        toolbarFiltersTooltipActive: (count) => {
            return `${count } ${ t('toolbarFiltersTooltipActive')}`;
        },
        filterPanelAddFilter: t('filterPanelAddFilter'),
        filterPanelDeleteIconLabel: t('filterPanelDeleteIconLabel'),
        filterPanelOperators: t('filterPanelOperators'),
        filterPanelOperatorAnd: t('filterPanelOperatorAnd'),
        filterPanelOperatorOr: t('filterPanelOperatorOr'),
        filterPanelColumns: t('filterPanelColumns'),
        filterOperatorContains: t('filterOperatorContains'),
        filterOperatorEquals: t('filterOperatorEquals'),
        filterOperatorStartsWith: t('filterOperatorStartsWith'),
        filterOperatorEndsWith: t('filterOperatorEndsWith'),
        filterOperatorIs: t('filterOperatorIs'),
        filterOperatorNot: t('filterOperatorNot'),
        filterOperatorAfter: t('filterOperationAfter'),
        filterOperationAfter: t('filterOperationAfter'),
        filterOperatorOnOrAfter: t('filterOperatorOnOrAfter'),
        filterOperatorBefore: t('filterOperatorBefore'),
        filterOperatorOnOrBefore: t('filterOperatorOnOrBefore'),
        columnHeaderSortIconLabel: t('columnHeaderSortIconLabel'),
        footerTotalRows: t('footerTotalRows'),
        footerPaginationRowsPerPage: t('footerPaginationRowsPerPage'),
        footerRowSelected: (count) => {
            return count === 1 ?
                `${count.toLocaleString()} ${t('footerRowSelected')}` :
                `${count.toLocaleString()} ${t('footerRowsSelected')}`;
        },
        filterPanelInputLabel: t('filterPanelInputLabel'),
        filterPanelInputPlaceholder: t('filterPanelInputPlaceholder'),
    };


    const classes = useStyles(props);
    const isFooterHidden = useCallback(() => {
        let hideFooter = props.hideFooter;
        if (props.hideFooterForOnePage) {
            hideFooter = props.rows.length <= (props.pageSize || DEFAULT_PAGE_SIZE);
        }
        return hideFooter;
    }, [ props.hideFooter, props.hideFooterForOnePage, props.pageSize, props.rows.length ]);

    const noRowsOverlay = () => {
        return (
            <GridOverlay>
                <p className={classes.noElementsText} tabIndex={0}>{props.noElementsText}</p>
            </GridOverlay>
        );
    };

    const loadingOverlay = () => {
        return (
            <GridOverlay>
                <div className={classes.loadingOverlay}>
                    <LinearProgress aria-label={t(props.ariaLabelProgressBar)}/>
                </div>
            </GridOverlay>
        );
    };

    const getKeyButton = (ariaLabel, content) => {
        return (
            <Button
                className={classes.keyButton}
                aria-label={ariaLabel}
                color="black"
                variant="outlined"
                tabIndex={0}
                size="small"
            >
                {content}
            </Button>
        );
    };
    const shiftKeyContent = () => {
        return(
            <div className={classes.inline}>
                <ArrowUpwardOutlinedIcon className={classes.icon}/>
                {t('shift_key')}
            </div>
        );
    };
    const showInformationButton = () => {
        const content =
            <div className={classes.typography}>
                {t('sort_explanation')}
                <br/>
                {getKeyButton(t('aria_label_ctrl_key_button'), t('ctrl_key'))}
                {', '}
                {getKeyButton(t('aria_label_shift_key_button'), shiftKeyContent())}
                {t('small_or')}
                {getKeyButton(t('aria_label_command_key_button'), t('command_key'))}
                {t('on_macOs')}
            </div>
        ;
        return (
            <div>
                <MIInfoModal
                    buttonAriaLabel={t('aria_label_info_button')}
                    buttonContent={<InfoIcon/>}
                    modalHeaderAriaLabel={t('aria_label_table_commands_header')}
                    modalHeader={t('table_commands_header')}
                    modalContent={content}
                />
            </div>
        );
    };

    const customToolbar = () => {
        return (
            <div className={classes.toolbar}>
                {getBool(showInfoModal) && showInformationButton()}
                <GridFilterToolbarButton/>
            </div>
        );
    };

    let components = {
        NoRowsOverlay: noRowsOverlay,
        LoadingOverlay: loadingOverlay,
        Pagination: MIPagination,
    };

    if (props.hasFilters) {
        filterProps.showToolbar = true;
        components.Toolbar = customToolbar;
    }

    useEffect(() => {
        setPage(0);
    }, [ props.rows.length ]);

    useEffect(() => {
        let newHeight = 0;
        if (props.heightToFitContent) {
            if (props.isMobile) {
                newHeight = (props.hideFooter ? 0 : MOBILE_GRID_BASE_HEIGHT) +
                    (Number.isInteger(props.headerHeight) ? props.headerHeight : 0) +
                    Math.min(props.rows.length, props.pageSize) * MOBILE_GRID_OUTER_ROW_HEIGHT;
            } else {
                const maxRows = props.pageSize || DEFAULT_PAGE_SIZE;
                const rowHeight = (props.rowHeight || DEFAULT_ROW_HEIGHT) + ROW_PADDING;
                let headerHeight = !isEmpty(props.headerHeight) ? props.headerHeight : DEFAULT_HEADER_HEIGHT;
                if (headerHeight > 0) {
                    headerHeight = headerHeight + HEADER_PADDING;
                }
                const maxHeight = maxRows * rowHeight + headerHeight;
                newHeight = props.rows.length > maxRows ? maxHeight : props.rows.length * rowHeight + headerHeight;
                if (!isFooterHidden()) {
                    newHeight = newHeight + PAGINATION_HEIGHT;
                }
                if (props.hasFilters) {
                    newHeight = newHeight + FILTER_OFFSET;
                }
            }
        } else {
            newHeight = props.height ? parseInt(props.height) : DEFAULT_HEIGHT;
        }
        setHeight(newHeight);
    }, [ props.rows.length, isFooterHidden, props.headerHeight, props.height, props.heightToFitContent, props.pageSize, props.rowHeight, props.hasFilters ]);

    return (
        <div className={classes.container} style={{ height: height, width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div className={classes.innerContainer}>
                    <XGrid
                        aria-label={props.ariaLabel}
                        localeText={localeText}
                        components={components}
                        loading={props.isLoading}
                        className={classes.grid}
                        disableColumnMenu={true}
                        disableDensitySelector={true}
                        disableColumnSelector={true}
                        density={props.density ? props.density : 'comfortable'}
                        rowHeight={props.rowHeight || DEFAULT_ROW_HEIGHT}
                        page={page}
                        onPageChange={(params) => {
                            setPage(params.page);
                        }}
                        pagination={props.pagination}
                        hideFooter={isFooterHidden()}
                        hideFooterSelectedRowCount={props.hideFooterSelectedRowCount}
                        pageSize={props.pageSize || DEFAULT_PAGE_SIZE}
                        columns={props.columns}
                        rows={props.rows}
                        headerHeight={!isEmpty(props.headerHeight) ? props.headerHeight : DEFAULT_HEADER_HEIGHT}
                        {...filterProps}
                        checkboxSelection={props.checkboxSelection}
                        onSelectionModelChange={props.onSelectionModelChange}
                        selectionModel={props.selectionModel}
                        onRowSelected={props.onRowSelected}
                        onCellClick={props.onCellClick}
                    />
                </div>
            </div>
        </div>
    );
};

export default memo(MIGrid);
