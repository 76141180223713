import React, { memo, useEffect, useState } from 'react'
import {useTranslation} from 'react-i18next';
import {useSiteList} from '../../services/useSiteList';
import {RenderTooltip} from '../../components/Widgets/MIGrid';
import {getGMTOffsetDateFromString, getLocalizedDate} from '../../utils/utils';
import {FETCHING} from '../../services/requests/requestReducer';
import {useSiteStore} from "../../contexts/StateProvider/StateProvider";
import {observer} from "mobx-react-lite";
import {isEmpty} from "../../utils/utils";
import SiteGridMobile from './variant/SiteGridMobile';
import SiteGridDesktop from './variant/SiteGridDesktop';
import {ResponsiveComponent} from '../../services/useResponsive';

const SiteGrid = observer((props) => {
    const {t} = useTranslation();
    const {sites, premiseListStatus} = useSiteList();
    const gridColumns = [];
    const gridRows = [];
    const siteStore = useSiteStore();
    const [selectionModel, setSelectionModel] = useState([]);
    //this component has too many renders. it will be fixed in ACD-6674
    const selectionComponent = {};
    const {showInfoModal, hideLastVisit, hideVisitHistoryLink, hidePremiseNo, hideContractNo, showRowCount, waitFullyLoaded} = props;

    const selectionModelFunction = (newSelection) => {
        setSelectionModel(newSelection.selectionModel);
        if (props.onChange) {
            //if the component is controlled by a controller component we need to update the inner status
            props.onChange({rowIds: newSelection.selectionModel})
        }
    };

    if (props.usePreSelection) {
        selectionComponent.onSelectionModelChange = selectionModelFunction;
        selectionComponent.selectionModel = selectionModel;
    }

    gridColumns.push({
        field: 'customer', 
        headerName: t('customer'), 
        type: 'string', 
        resizable: false, 
        flex: 2,
        renderCell: RenderTooltip
    });

    gridColumns.push({
        field: 'address',
        headerName: t('premise_address'),
        type: 'string',
        resizable: false,
        flex: 3,
        renderCell: RenderTooltip
    });

    sites.forEach((site) => {
        if (props.contracts &&
            props.contracts.length > 0 &&
            !props.contracts.includes(site.contractId)) {
            return;
        }
        const row = {};
        row.id = site.siteId;
        row.customer = site.contractName ? site.contractName.trim() : '-';
        row.address = site.siteAddress || '-';
        row.contractNo = site.contractId || '-';
        row.premiseNo = site.premiseNo || '-';
        row.locDate = site.lastVisit ? getLocalizedDate(t, site.lastVisit) : null;
        row.lastVisit = site.lastVisit ? getGMTOffsetDateFromString(site.lastVisit) : null;
        row.visitHistory = site.siteId;
        gridRows.push(row);
    });

    const preSelectedSitesIdsLength = (props.value && props.value.rowIds) ? props.value.rowIds.length : 0;
    const rowIds = (preSelectedSitesIdsLength > 0) ? props.value.rowIds : [];
    useEffect(() => {
        if (preSelectedSitesIdsLength === 0) {
            setSelectionModel([]);
        }
    }, [preSelectedSitesIdsLength])

    useEffect(() => {
        if (sites && sites.length > 0 && preSelectedSitesIdsLength > 0) {
            const preSelectedSitesIds = rowIds;
            // If we set a value in the selection model that is not found in the rows model it generates a loop render
            // so make sure that the preSelectedSitesIds are really in the sites available.
            let selectedIds = [];
            sites.forEach((site) => {
                if (preSelectedSitesIds.includes(site.siteId)) {
                    selectedIds.push(site.siteId)
                }
            });
            setSelectionModel(selectedIds);
        }
    // DO NOT ADD rowIds in order to prevent rendering loop. it should be fixed in ACD-6774
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sites, preSelectedSitesIdsLength]);

    let isLoading = isEmpty(sites) || premiseListStatus === FETCHING;
    if (props.waitFullyLoaded) {
        isLoading = isLoading || !siteStore.loadComplete;
    }

    const params = {
        waitFullyLoaded: waitFullyLoaded,
        showRowCount: showRowCount,
        showInfoModal: showInfoModal,
        selectionModelFunction: selectionModelFunction,
        hideLastVisit: hideLastVisit,
        hideVisitHistoryLink: hideVisitHistoryLink,
        hidePremiseNo: hidePremiseNo, 
        hideContractNo: hideContractNo,
        gridRows: gridRows, 
        sites: sites, 
        isLoading: isLoading,
        siteStore: siteStore,
        selectionComponent: selectionComponent,
    };

    return (
        <ResponsiveComponent
            components={{
                mobile: <SiteGridMobile {...params} {...props}/>,
                desktop: <SiteGridDesktop {...params} {...props}/>,
            }}
        />
    );
});

export default memo(SiteGrid);
