import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import MINavMenu from '../../components/Widgets/MINavMenu';
import UserGuide from './UserGuide';
import FAQs from './FAQs';
import OurServices from './OurServices';
import ContactUs from './ContactUs';
import { ResponsiveComponent } from '../../services/useResponsive';
import Notifications from './Notifications';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import NotificationContent from './NotificationContent';


const getSupportResponsiveComponent = (url, Component) => {
    return (
        <ResponsiveComponent
            components = {{
                mobile: <Component url={`mobile/${ url}`}/>,
                desktop: <Component url={url}/>
            }}/>
    );
};

const Support = () => {
    const { t } = useTranslation();
    const { feature_notifications } = useFlags();

    const navigation = [
        { path: '/support/user-guide', label: t('nav_user_guide'), ariaLabel: t('aria_label_user_guide_tab') },
        { path: '/support/faqs', label: t('nav_faqs'), ariaLabel: t('aria_label_faqs_tab') },
        { path: '/support/our-services', label: t('nav_our_services'), ariaLabel: t('aria_label_our_services_tab') },
        { path: '/support/contact-us', label: t('nav_contact_us'), ariaLabel: t('aria_label_contact_us_tab') }
    ];

    if(feature_notifications) {
        navigation.push({
            path: '/support/messages',
            label: t('nav_messages'),
            ariaLabel: t('aria_label_messages_tab')
        });
    }

    return (
        <>
            <MINavMenu routes={navigation} level={'secondary'}/>
            <Switch>
                <Route path={navigation[0].path} render={ () => {
                    return getSupportResponsiveComponent('user-guide.html', UserGuide);
                }}/>
                <Route path={navigation[1].path} component={FAQs}/>
                <Route path={navigation[2].path} render={ () => {
                    return getSupportResponsiveComponent('our-services.html', OurServices);
                }}/>
                <Route path={navigation[3].path} render={ () => {
                    return getSupportResponsiveComponent('contact-us.html', ContactUs);
                }}/>
                {feature_notifications ? <Route path={`${ navigation[4].path }/message-content`} component={NotificationContent}/> : null}
                {feature_notifications ? <Route path={navigation[4].path} component={Notifications}/> : null}
                <Route path="/support" render={ () => {
                    return getSupportResponsiveComponent('user-guide.html', UserGuide);
                }}/>
            </Switch>
        </>
    );
};

export default Support;
