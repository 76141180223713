import {useCallback, useEffect, useState} from "react";
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";
import {SUCCESS} from "../requests/requestReducer";

export const useGetFloorPlanFile = () => {
    const [file, setFile] = useState(null);
    const [{responseStatus, response}, makeFloorPlanApiRequestWithParams] = useFloorPlanApiRequest();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setFile({
                    signedUrl: response.data.signedUrl,
                });
            } catch (error) {
                console.error('useGetFloorPlanFile error: ', error);
            }
        }
    }, [responseStatus, response, setFile]);

    const getFloorPlanFile = useCallback((url) => {
        setFile(null);
        makeFloorPlanApiRequestWithParams(url).catch(error => {
            console.error('useGetFloorPlanFile makeRequest: ', error)
        });
    }, [makeFloorPlanApiRequestWithParams, setFile]);

    return [file, responseStatus, getFloorPlanFile];
};