import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { useWasteConsignment } from '../../services/waste/useWasteConsignment';
import { observer } from 'mobx-react-lite';
import { isEmpty, getGMTOffsetDateFromString, getLocalizedDate } from '../../utils/utils';
import WasteConsignmentTableMobile from './variant/WasteConsignmentTableMobile';
import WasteConsignmentTableDesktop from './variant/WasteConsignmentTableDesktop';
import { ResponsiveComponent } from '../../services/useResponsive';
import moment from 'moment';

const WasteConsignmentTable = observer((props) => {
    const { t } = useTranslation();
    const [ wasteConsignment, responseStatus, getWasteConsignment ] = useWasteConsignment();
    const siteStore = useSiteStore();

    useEffect(() => {
        if (!isEmpty(siteStore.selectedSiteId) && !isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber)) {
            getWasteConsignment(
                siteStore.selectedSiteId,
                siteStore.selectedContractNumber,
                siteStore.selectedPremiseNumber
            );
        }
    }, [ siteStore.selectedSiteId, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, getWasteConsignment ]);

    let rows = [];
    if (!isEmpty(wasteConsignment)) {
        wasteConsignment.forEach((record, index) => {
            const date = moment(record.VisitDueDate).format('DD/MM/YYYY');
            rows.push({
                id: index,
                locDate: getLocalizedDate(t, date),
                consignmentNoteCode: record.WasteConsignmentNoteNumber,
                premiseVisitNumber: record.PremiseNumber,
                visitDate: getGMTOffsetDateFromString(date),
            });
        });
    }

    const params = {
        responseStatus: responseStatus,
        rows: rows,
        wasteConsignment: wasteConsignment
    };

    const mobileParams = {
        showRowCount: true,
        mobilePageSize: 10,
    };

    return (
        <div>
            <ResponsiveComponent
                components={{
                    mobile: <WasteConsignmentTableMobile {...params} {...mobileParams} {...props}/>,
                    desktop: <WasteConsignmentTableDesktop {...params} {...props}/>,
                }}
            />
        </div>
    );
});

export default WasteConsignmentTable;
