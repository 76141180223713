import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import MIGrid from '../../Widgets/MIGrid';
import { Grid, Typography } from '@material-ui/core';
import { getBool, isEmpty, mergeStyles } from '../../../utils/utils';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { FETCHING } from '../../../services/requests/requestReducer';
import useModal from '../../../services/useModal';
import MIInfoDialog from '../../Widgets/mobile/MIInfoDialog';

const useStyles = makeStyles(theme => ({
    dataCell: {
        lineHeight: 0,
        width: '100%',
        whiteSpace: 'normal',
    },
    columnName: {
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileBody,
        marginRight: theme.sizes.pico_gap
    },
    bodyText: {
        fontFamily: theme.fontFamily.proximaMedium,
        fontSize: theme.fontSizes.mobileBody
    },
    status: {
        height: theme.sizes.mobileBody,
        overflowWrap: 'break-word',
        whiteSpace: 'nowrap',
        fontSize: theme.fontSizes.mobileBody
    },
    gridContainer: {
        '& div.MuiDataGrid-cell': {
            paddingRight: theme.sizes.micro_gap,
            whiteSpace: 'normal',
        },
        '& div.MuiDataGrid-columnsContainer': {
            borderBottom: theme.borders.outline_regular,
        },
        '& div.MuiDataGrid-colCellTitle': {
            color: theme.palette.primary.main,
        },
    },
    selectLinkMobile: {
        height: theme.sizes.large_gap,
        width: theme.sizes.large_gap,
        display: 'flex',
        '&, &:visited, &:hover': {
            color: theme.palette.primary.main
        },
        '&:focus': theme.ariaFocus,
    },
}));

const UpdateLogsMobile = observer((props) => {
    const { t } = useTranslation();
    const { isShowing, changeStatus } = useModal();
    const [ updateLogData, setUpdateLogData ] = useState([{}]);
    const {
        rows,
        responseStatusComments,
        mobilePageSize,
        headerHeight,
        hideFooter,
    } = props;
    const classes = useStyles();

    const getGridRow = (columnName, value) => (
        <Grid container item xs={12}>
            <Grid item xs={4}>
                <Typography className={classes.columnName}>{columnName + ': '} </Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography className={classes.bodyText} noWrap> {value} </Typography>
            </Grid>
        </Grid>
    );


    const getDateAndUpdatedByCell = (params) => (
        <Grid
            container
            direction={'row'}
            className={classes.dataCell}
        >
            <Grid container item xs={11}>
                {getGridRow(t('date'), params.row.locDate)}
                {getGridRow(t('updated_by'), params.row.updatedBy)}
                {!isEmpty(params.row.status) && getGridRow(t('status'), params.row.status)}
            </Grid>
            <Grid item xs={1} className={classes.linkContainer}>
                <AssignmentIcon className={mergeStyles(classes.selectLink, classes.selectLinkMobile)}/>
            </Grid>
        </Grid>
    );

    const columns = [{
        field: 'updateLogs',
        headerName: t('update_logs'),
        type: 'string',
        resizable: false,
        filterable: false,
        sortable: false,
        flex: 1,
        renderCell: (params) => {return getDateAndUpdatedByCell(params);},
    }];

    const getPopupInfo = (data) => {
        let info = [];
        info.push(
            { label: t('date'), value: data.locDate || '-'},
            { label: t('updated_by'), value: data.updatedBy || '-'},
            { label: t('status'), value: data.status || '-'},
            { label: t('details_log'), value: data.details || '-'}
        );
        return info;
    };

    const handleRowClick = (selectedRow) => {
        if (!isShowing) {
            setUpdateLogData(getPopupInfo(selectedRow.data))
            changeStatus();
        }
    };

    return (
        <div className={classes.gridContainer}>
            <MIGrid
                isMobile={true}
                showInfoModal={false}
                isLoading={responseStatusComments === FETCHING}
                columns={columns}
                noElementsText={t('no_update_logs')}
                rowHeight={55}
                rows={rows}
                hasFilters={false}
                pagination={true}
                autoPageSize={false}
                pageSize={mobilePageSize ? mobilePageSize : rows.length}
                ariaLabel={t('aria_label_update_logs_table')}
                ariaLabelProgressBar={t('aria_label_progressbar_update_logs')}
                onRowSelected={handleRowClick}
                heightToFitContent={true}
                headerHeight={!isEmpty(headerHeight) ? headerHeight : 0}
                hideFooter={getBool(hideFooter)}
                hideFooterForOnePage={true}
                noEvenRowStyling={true}
            />
            <MIInfoDialog
                title={t('update_logs')}
                info={updateLogData}
                open={isShowing}
                handleClose={changeStatus}
            />
        </div>
    );
});

export default UpdateLogsMobile;
