import {useState, useEffect, useCallback} from 'react';
import useApiRequest from "../requests/useApiRequest";
import {SUCCESS} from "../requests/requestReducer";
import moment from "moment";

export const usePlannedVisitDetails = () => {
    const [plannedVisitDetails, setPlannedVisitDetails] = useState([]);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/premiseService/premiseVisitPlanningPopUp';

    function getDate(date) {
        if (date) {
            //this API uses this specif data format
            const dateAPIFormat = 'YYYY/MM/DD';
            const originalDateFormat = 'DD/MM/YYYY';
            let dataString = moment(date, originalDateFormat).locale('en').format(dateAPIFormat);
            dataString = dataString.replace(/\//g, '-');
            return dataString
        } else {
            return ''
        }
    }

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setPlannedVisitDetails(response.data.visitPlan);
            } catch (error) {
                console.error('usePlannedVisitDetails error: ', error);
                setPlannedVisitDetails([]);
            }
        }
    }, [responseStatus, response, setPlannedVisitDetails]);

    const getPlannedVisitDetails = useCallback((siteId, locale, visitType, inputDate) => {
        // reset the content otherwise it can show the content of another card
        setPlannedVisitDetails([]);
        const date = getDate(inputDate);
        const url = endpoint + '/' + siteId + '/' + locale + '/' + visitType + '/' + date;
        makeRequestWithParams(url).catch(error => {
            console.error('usePlannedVisitDetails error: ', error);
        });
    }, [makeRequestWithParams])

    return [plannedVisitDetails, responseStatus, getPlannedVisitDetails];
};
