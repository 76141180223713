import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import MIButton from '../../../components/Widgets/MIButton';
import {useTranslation} from 'react-i18next';
import LaunchIcon from '@material-ui/icons/Launch';
import {isEmpty} from '../../../utils/utils';
import MIAlert from '../../../components/Widgets/MIAlert';
import MITopBorder from '../../../components/Widgets/MITopBorder';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginRight: theme.sizes.xxlarge_gap,
        marginBottom: theme.sizes.xlarge_gap,
        background: '#F9F9F9', 
        boxShadow:'none',
        display: 'flex',
        borderRadius: theme.sizes.no_gap,
    },
    cardHeading: {
        display: 'flex',
    },
    cardContent: {
        width: '100%',
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.fontSizes.mobileBody,
        '&:focus': theme.ariaFocus,
    },
    cardTitle: {
        fontSize: theme.fontSizes.mobileTitle,
        lineHeight: '40px',
    },
    cardText: {
        display: 'flex',
    },
    launchButton: {
        borderRadius: '4px',
    },
    launchButtonContainer: {
        marginTop: theme.sizes.small_gap,
    },
    buttonContent: {
        display: 'inline-flex',
    },
    launchText: {
        marginRight: '5px',
    },
    launchIcon: {
        color: '#fff',
        alignContent: 'center',
        margin: 'auto',
    },
    topBorder: {
        width: '100%',
    },
    icon: {
        width: '44px',
        height: '40px',
        marginRight: theme.sizes.small_gap,
    },
}));

const MILinkCardMobile = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [isShowing, setIsShowing] = useState(false);
    const {url, title, info, buttonAriaLabel, image} = props;
    const [alert, setAlert] = useState({});

    const showAlert = () => {
        setAlert({
            message: 'no_url_error',
            severity: 'error'
        })
        setIsShowing(true);
    }

    const openLinkInNewTab = () => {
        if(!isEmpty(url) && url !== 'error'){
            window.open(url);
        }else{
            if(url === 'error') {
                //Allows the alert onClose method to close the snackbar before opening a new one in the event of clicking consecutively
                setTimeout(() => {
                    showAlert()
                }, 0);
            }
        }
    };

    return ( 
        <div>
            <MITopBorder className={classes.topBorder} />
            <Card className={classes.root}>
                <CardContent className={classes.cardContent} tabIndex={0}>
                    <div className={classes.cardHeading}>
                        <CardMedia
                            className={classes.icon}
                            image={image}
                        />
                        <span className={classes.cardTitle}>{title}</span>

                    </div>
                    <div className={classes.cardText}>
                        <p>{info}</p>
                    </div>
                    <div className={classes.launchButtonContainer}>
                        <MIButton
                            className={classes.launchButton}
                            ariaLabel={buttonAriaLabel}
                            mi_size='small'
                            mi_type='primary'
                            type='button'
                            onClick={openLinkInNewTab}
                            disabled={isEmpty(url)}
                        >
                            <div className={classes.buttonContent}>
                                <span className={classes.launchText}>{t('launch_button')}</span>
                                <LaunchIcon className={classes.launchIcon}/>
                            </div>
                        </MIButton>
                    </div>
                </CardContent> 
            </Card>
            <MIAlert className={classes.linkCardAlert} isShowing={isShowing} setIsShowing={setIsShowing} message={t(alert.message)} severity={alert.severity} ariaLabel={t('aria_success_message')}/>
        </div>
    );
};

export default MILinkCardMobile;
