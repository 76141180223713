import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from 'mobx-react-lite';
import {useGetRecommendationComments} from '../../services/recommendations/useGetRecommendationComments';
import {isEmpty, getLocalizedDate, formatDate, getGMTOffsetDateFromString} from '../../utils/utils';
import UpdateLogsMobile from './variant/UpdateLogsMobile';
import UpdateLogsDesktop from './variant/UpdateLogsDesktop';
import {ResponsiveComponent} from '../../services/useResponsive';

const UpdateLogs = observer((props) => {
    const {t} = useTranslation();
    const {id} = props;
    const [recommendationComments, responseStatusComments, getRecommendationComments] = useGetRecommendationComments();

    useEffect(() => {
        if (!isEmpty(id)) {
            getRecommendationComments(id);
        }
    }, [getRecommendationComments, id]);

    const getStatusAndDetails = (text) => {
        let status = '';
        let details = text;
        if (!isEmpty(text)) {
            // this will try to match Previous status: "New" & Current status: "Complete"
            const oldMyRentokilStatusRegex = /\([\w\s:]*["\w\s]*&[\w\s:]*["\w\s]*\)/;
            let match = text.match(oldMyRentokilStatusRegex);
            if (isEmpty(match)) {
                // this will try to match  (Complete -> Pending)
                const newStatusRegex = /\([\w\s]+->[\w\s]+\)/;
                match = text.match(newStatusRegex);
            }
            if (!isEmpty(match)) {
                status = match[0].replace(/[()]/g, '');
                details = details.replace(match[0], '');
            }
        }
        return [status, details];
    };


    const getRows = () => {
        const rows = [];
        if (!isEmpty(recommendationComments)) {
            recommendationComments.forEach((comment) => {
                const [status, details] = getStatusAndDetails(comment.text);
                const filteredDate = !isEmpty(comment.localDatetime) ? formatDate(comment.localDatetime.split(' ')[0], 'YYYY-MM-DD', 'DD/MM/YYYY') : null; // Remove time from date and format
                rows.push({
                    id: comment.id,
                    updatedBy: comment.author || '-',
                    locDate: !isEmpty(filteredDate) ? getLocalizedDate(t, filteredDate) : '-',
                    date: !isEmpty(filteredDate) ? getGMTOffsetDateFromString(filteredDate) : null,
                    status: status,
                    details: !isEmpty(details) ? details.trim() : '',
                });
            });
        }
        return rows;
    };

    const params = {
        rows: getRows(),
        responseStatusComments: responseStatusComments
    };

    const mobileParams = {
        mobilePageSize: 10,
    };

    return (
        <ResponsiveComponent
                components={{
                    mobile: <UpdateLogsMobile {...params} {...mobileParams}/>,
                    desktop: <UpdateLogsDesktop {...params}/>
                }}
            />
    );
});

export default UpdateLogs;
