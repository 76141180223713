import {useCallback, useEffect, useState} from "react";
import {SUCCESS} from "./requests/requestReducer";
import useApiRequest from "./requests/useApiRequest";

export const useGetFile = () => {
    const [file, setFile] = useState(null);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setFile({
                    signedUrl: response.data.signedUrl,
                    base64: response.data.base64
                });
            } catch (error) {
                console.error('useGetFile error: ', error);
            }
        }
    }, [responseStatus, response, setFile]);

    const getFile = useCallback((url, requestMethod, requestParams) => {
        setFile(null);
        makeRequestWithParams(url, requestMethod, requestParams).catch(error => {
            console.error('useGetFile makeRequest: ', error)
        });
    }, [makeRequestWithParams]);

    return [file, responseStatus, getFile]
};