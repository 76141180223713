import React, { memo, useCallback, useEffect, useRef } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from './Icon';
import { Typography } from '@material-ui/core';
import { isObjectEmpty, isEmpty } from '../utils/utils';
import noImageIconUrl, { ReactComponent as noImageIcon } from '../assets/icons_system_noimage.svg';

const useStyles = makeStyles(() => ({
    img: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: (props) => `${props.width }px`,
        maxHeight: (props) => `${props.height }px`,
        visibility: (props) => (props.forceVisibility ? 'visible' : 'hidden'),
    },
    controls: {
        position: 'absolute',
        right: '10px',
        bottom: (props) => (!isEmpty(props?.customStyle?.floorplanControlsBottonPadding)
            ? props.customStyle.floorplanControlsBottonPadding
            : '0px'
        ),
    },
    buttons: {
        background: 'white',
        width: '32px',
        height: '32px',
        marginBottom: '5px',
        fontSize: '24px',
        paddingBottom: '32px',
        border: '2px solid #80808047',
    },
    mapLayer: {
        height: '100%',
        width: '100%',
    },
    maskLayer: {
        height: '100%',
        width: '100%',
        zIndex: '100',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        background: 'transparent',
    },
    unavailableContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    },
    unavailableText: {
        fontFamily: 'proxima nova bold,Roboto,Helvetica',
        fontSize: '25px',
        color: '#0f202e',
    },
}));

export const MIN_SCALE = 0.85; // Use 0.85 instead of 1 so the point will not cut off on the page.
export const MAX_SCALE = 20;
export const IMAGE_UNAVAILABLE = 'imageUnavailable';
const IMAGE_UNAVAILABLE_SIZE = { width: 312, height: 312 };

const MIN_TRANSLATION_BORDER = 200;
const TOP_PADDING = 50; // Add top padding to avoid the points overlap to the filter UI and support the device labeling

const MapLayerImpl = (props) => {
    const {
        image,
        imageAlt,
        imageAriaLabel,
        scale,
        translationX,
        translationY,
        width,
        height,
        setValue,
        setDetectorScale,
        no_floor_text,
        hideControls
    } = props;
    const classes = useStyles(props);
    const imgElement = useRef(null);

    const removeActiveFocus = () => {
        document.activeElement.blur();
    };

    const onChange = (newValue) => {
        if (newValue.scale !== scale ||
            newValue.translation.x !== translationX ||
            newValue.translation.y !== translationY
        ) {
            removeActiveFocus();
            const imageScaleX = imgElement.current.naturalWidth > width ? width / imgElement.current.naturalWidth : 1;
            const imageScaleY = imgElement.current.naturalHeight > height ? height / imgElement.current.naturalHeight : 1;
            const imageScale = imageScaleX < imageScaleY ? imageScaleX : imageScaleY;
            const scaledWidth = imgElement.current.naturalWidth * imageScale * newValue.scale;
            const scaledHeight = imgElement.current.naturalHeight * imageScale * newValue.scale;
            const minX = -scaledWidth + MIN_TRANSLATION_BORDER;
            const maxX = width - MIN_TRANSLATION_BORDER;
            const minY = -scaledHeight + MIN_TRANSLATION_BORDER;
            const maxY = height - MIN_TRANSLATION_BORDER;

            newValue.translation.x =
                newValue.translation.x < minX ? minX :
                    newValue.translation.x > maxX ? maxX :
                        newValue.translation.x;
            newValue.translation.y =
                newValue.translation.y < minY ? minY :
                    newValue.translation.y > maxY ? maxY :
                        newValue.translation.y;
            setValue(newValue);
        }
    };

    const value = {
        scale: scale,
        translation: {
            x: translationX,
            y: translationY
        }
    };

    const calcLocationScale = useCallback(() => {
        if (imgElement.current !== null) {
            const clientWidth = imgElement.current.clientWidth * scale;
            let centerTransX = clientWidth < width ?
                (width - clientWidth) / 2.0 : 0;
            let centerTransY = imgElement.current.clientHeight < height ?
                (height - imgElement.current.clientHeight) / 2.0 : 0;
            const centeredValue = {
                scale: scale,
                translation: { x: centerTransX, y: centerTransY + TOP_PADDING }
            };
            onChange(centeredValue);
            setDetectorScale(imgElement.current.clientWidth / imgElement.current.naturalWidth);
        }
        // should not update on changes to scale
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ width, height, setDetectorScale ]);

    useEffect(() => {
        calcLocationScale();
    }, [ calcLocationScale ]);

    const getMapLayer = () => (image !== IMAGE_UNAVAILABLE ?
        <MapInteractionCSS
            showControls={!hideControls}
            value={value}
            onChange={onChange}
            controlsClass={classes.controls}
            btnClass={classes.buttons}
            minScale={MIN_SCALE}
            maxScale={MAX_SCALE}
        >
            <img
                id={'floor-plan-image'}
                src={image}
                alt={imageAlt}
                aria-label={imageAriaLabel}
                className={classes.img}
                ref={imgElement}
                onLoad={() => {
                    calcLocationScale();
                    imgElement.current.style.visibility = 'visible';
                }}
            />
        </MapInteractionCSS> :
        <div className={classes.unavailableContainer}>
            <Icon
                icon={noImageIcon}
                iconUrl={noImageIconUrl}
                size={IMAGE_UNAVAILABLE_SIZE}
                title={imageAlt}
            />
            <Typography className={classes.unavailableText}>{no_floor_text}</Typography>
        </div>)
        ;

    return (
        <div className={classes.mapLayer} onMouseDown={removeActiveFocus}>
            {isObjectEmpty(image) ? null : getMapLayer()}
        </div>
    );
};

export const MapLayer = memo(MapLayerImpl);
