import { isEmpty } from "../../utils/utils";
import {makeAutoObservable} from "mobx";

const AVAILABLE_REGIONS_LOCAL_STORAGE_KEY = 'available_regions_key';
const REGION_LOCAL_STORAGE_KEY = 'region_key';
const COUNTRY_LOCAL_STORAGE_KEY = 'country_key';

class RegionStore {

    constructor() {
        this.availableRegions = JSON.parse(sessionStorage.getItem(AVAILABLE_REGIONS_LOCAL_STORAGE_KEY));
        this.region = JSON.parse(sessionStorage.getItem(REGION_LOCAL_STORAGE_KEY));
        this.country = JSON.parse(sessionStorage.getItem(COUNTRY_LOCAL_STORAGE_KEY));
        makeAutoObservable(this);
    }

    setAvailableRegions(availableRegions) {
        this.availableRegions = availableRegions;
        sessionStorage.setItem(AVAILABLE_REGIONS_LOCAL_STORAGE_KEY, JSON.stringify(availableRegions));
    }

    setRegion(region) {
        this.region = region;
        return sessionStorage.setItem(REGION_LOCAL_STORAGE_KEY, JSON.stringify(region));
    }

    setCountry(country) {
        this.country = country;
        sessionStorage.setItem(COUNTRY_LOCAL_STORAGE_KEY, JSON.stringify(country));
    }

    reset() {
        this.setCountry({});
        this.setRegion('');
        this.resetAvailableRegions();
    }

    resetAvailableRegions() {
        this.setAvailableRegions({});
    }

    getAvailableRegions() {
        return this.availableRegions;
    }

    getSelectedRegion() {
        return this.region;
    }

    getSelectedCountry() {
        return this.country;
    }

    getCurrentCountryCode() {
        return !isEmpty(this.country) ? this.country.countryCode.toLowerCase() : '';
    }
}

export {RegionStore};