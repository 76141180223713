import { useEffect, useState } from 'react';
import h337 from 'heatmap.js';
import { isObjectEmpty, RENTOKIL_POSITION_ADJUSTMENT } from '../utils/utils';

const HeatMap = (props) => {
    const { containerRef, points, transform, detectorScale, timePeriod, getHeatMapSpot, showHeatMap, width, height } = props;
    const [ heatMapInstance, setHeatMapInstance ] = useState(null);
    const settings = {
        radius: 200,
        maxOpacity: 0.7,
        minOpacity: 0,
        blur: 0.95
    };

    const transformCoordinate = (pointCoordinate, translation, scale, transformScale) => Math.round(
        pointCoordinate * scale * transformScale +
        translation + RENTOKIL_POSITION_ADJUSTMENT
    );

    const getDefaultHeatMapSpot = (point, scale, transformScale, translationX, translationY, timePeriodRange) => {
        const location = point.location;
        if (!isObjectEmpty(location) && !isObjectEmpty(point.activity)) {
            const activity = point.activity[timePeriodRange];
            const value = !isObjectEmpty(activity) ? parseInt(activity, 10) : 0;
            return {
                spot: {
                    x: transformCoordinate(location.screenLeft, translationX, scale, transformScale),
                    y: transformCoordinate(location.screenTop, translationY, scale, transformScale),
                    value: value
                },
                value: value,
            };
        }
    };

    useEffect(() => {
        if (!isObjectEmpty(heatMapInstance)) {
            let hotspots = [];
            let max = 0;
            if (showHeatMap) {
                points.forEach((point) => {
                    const heatMapSpotFunction = !isObjectEmpty(getHeatMapSpot) ? getHeatMapSpot : getDefaultHeatMapSpot;
                    const { spot, value } = heatMapSpotFunction(point,
                        detectorScale,
                        transform.scale,
                        transform.translation.x,
                        transform.translation.y,
                        timePeriod);
                    if (!isObjectEmpty(spot) && value > 0) {
                        hotspots.push(spot);
                        max = Math.max(max, value);
                    }
                });
            }
            heatMapInstance.setData({
                max: max,
                data: hotspots
            });
        }
    }, [
        points,
        heatMapInstance,
        transform.scale,
        transform.translation.x,
        transform.translation.y,
        detectorScale,
        timePeriod,
        showHeatMap,
        getHeatMapSpot ]
    );


    useEffect(() => {
        // the heatmap instance is not responsive, this useEffect will change the dimensions of the active layer.
        if (!isObjectEmpty(width) && !isObjectEmpty(height) && !isObjectEmpty(heatMapInstance)) {
            heatMapInstance.configure({
                width: width,
                height: height,
                ...settings
            });
        }
    }, [ width, height, heatMapInstance ]);

    useEffect(() => {
        let heatMap = null;
        if (containerRef && containerRef.current) {
            heatMap = h337.create({
                container: containerRef.current,
                ...settings
            });
        }
        setHeatMapInstance(heatMap);
    }, [ containerRef, setHeatMapInstance ]);

    return null;
};

export default HeatMap;
