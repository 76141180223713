import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { isEmpty } from '../../../utils/utils';
import MIApiLoader from '../../../components/Widgets/MIApiLoader';
import Theme from '../../../components/Theme/Theme';
import { observer } from 'mobx-react-lite';
import { NO_INTERACTION_TYPE } from './SmartHygieneEnum';
import InteractionChart from './InteractionChart';
import InteractionTitle from './InteractionTitle';

const useStyles = makeStyles((theme) => {
    return {
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: theme.sizes.small_gap,
        },
        title: {
            flexGrow: 1,
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.fontFamily.proximaBold,
        },
        actions: {
            background: 'red',
        },
        otherIcon: {
            width: '42px',
            height: '42px',
            color: theme.palette.primary.main,
        },
        otherContainer: {
            display: 'inline-block',
            marginBottom: theme.sizes.large_gap,
        },
        otherCard: {
            marginBottom: theme.sizes.large_gap,
        },
        timePeriodSelector: {
            'width': '200px',
            '&& div.MuiOutlinedInput-root': {
                borderRadius: '0px',
            },
        },
        interactionChart: {
            height: theme.heights.interaction_chart,
            width: '100%'
        },
        noData: {
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
        }
    };
});

const getRow = (name, values) => {
    return [ name, ...values ];
};

const removeZeroColumns = (data) => {
    // Check if column is just zeros
    const entryTotal = data[0].length;
    const allZero = new Array(entryTotal).fill(true);
    allZero[0] = false;
    for(let i = 1; i < data.length; i++) {
        for(let j = 1; j < data[i].length; j++) {
            if (data[i][j] !== 0) {
                allZero[j] = false;
            }
        }
    }
    return data.map((row) => {
        return row.filter((column, index) => {
            return !allZero[index];
        });
    });
};

const noInteractions = (type) => {
    return [ [ type, NO_INTERACTION_TYPE.NO_DATA ], [ '', 0 ] ];
};

export const parseGraphData = (t, type, typeMap, enabledTypes, interactionData) => {
    let data = [];
    if (!isEmpty(enabledTypes)) {
        data.push([ t(type), ...enabledTypes ]);

        if (interactionData) {
            for (const [ key, values ] of Object.entries(interactionData)) {
                // Delete the day value in the day
                delete values.day;
                const enabledTypeInteractions = enabledTypes.map((interaction) => {
                    return values[interaction];
                });
                const row = getRow(t(typeMap[key]), enabledTypeInteractions);
                data.push(row);
            }
        }
        data = removeZeroColumns(data);
        if (data[0].length === 1) {
            data = noInteractions(t(type));
        }
    } else {
        data = noInteractions(t(type));
    }
    return data;
};

export const getEnabledTypes = (interactionTypes) => {
    const enabledTypes = Object.values(interactionTypes).filter((type) => {
        return type.CHECKED;
    }).map((type) => {
        return type.NAME;
    });
    return enabledTypes;
};

const InteractionPatterns = observer((props) => {
    const { t } = useTranslation();
    const {
        className,
        interactions,
        responseStatus,
        timePeriod,
        setTimePeriod,
        interactionTypes,
        setInteractionTypes
    } = props;
    const classes = useStyles();
    const [ patternData, setPatternData ] = useState([]);

    const changeTimePeriod = (event) => {
        setTimePeriod(event.target.value);
    };

    useEffect(() => {
        if (!isEmpty(interactions)) {
            setPatternData(parseGraphData(
                t, timePeriod.name, timePeriod.options, getEnabledTypes(interactionTypes),
                interactions[timePeriod.apiReturn]
            ));
        }
    }, [ t, timePeriod, interactions, interactionTypes, parseGraphData ]);

    return (
        <div className={className}>
            <InteractionTitle
                interactionTypes={interactionTypes}
                setInteractionTypes={setInteractionTypes}
                timePeriod={timePeriod}
                changeTimePeriod={changeTimePeriod}
            />
            <div className={classes.interactionChart}>
                <MIApiLoader
                    customStyle={{
                        marginTop: Theme.heights.interaction_chart_spinner
                    }}
                    MIComponent={
                        !isEmpty(patternData) && Array.isArray(patternData) && patternData.length > 1 ?
                            <InteractionChart data={patternData} /> :
                            <div className={classes.noData}> <Typography> {t('no_data_available')} </Typography> </div>
                    }
                    responseStatus={responseStatus}
                    ariaLabelProgressBar={t('aria_label_progressbar_app')}
                />
            </div>
        </div>
    );
});

export default InteractionPatterns;
