import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import ContactImage from '../../assets/contact.png'
import AccountHelpImage from '../../assets/account_help.png'
import DocumentsImage from '../../assets/documents.png'
import ImageCard from '../Widgets/ImageCard';
import {  Link } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
    root: {
        width: '288px',
    },
    paper: {
        width: 'max-content',
    },
    calendar: {
        display: 'inline-flex',
        width: 'min-content',
    },
    card: {
        display: 'block',
    },
    title: {
        '&:focus': theme.ariaFocus,
        padding:'2px',
        marginTop: theme.sizes.xlarge_gap,
        marginBottom: theme.sizes.large_gap,
    },
    link: {
        textDecoration: 'none',
        ':hover': {textDecoration: 'none'},
        ':visited': {textDecoration: 'none'},
        ':active': {textDecoration: 'none'},
        '&:focus': {
            outline:'none'
        },
    }
}));

const SupportBox = () => {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h1 tabIndex={0} className={classes.title} aria-label={t('aria_label_support_title')}>{t('support')}</h1>
            <div>
                <Link className={classes.link} to={'/support/contact-us'}>
                    <ImageCard image={ContactImage} className={classes.card} text={t('contact_us')}/>
                </Link>
                <Link className={classes.link} to={'/support'}>
                    <ImageCard image={AccountHelpImage} className={classes.card} text={t('account_help')}/>
                </Link>
                <Link className={classes.link} to={'/documents'}>
                    <ImageCard image={DocumentsImage} className={classes.card} text={t('documents')}/>
                </Link>
            </div>
        </div>
    );
}

export default SupportBox;
