import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MITextField from '../../components/Widgets/MITextField';
import MIButton from '../../components/Widgets/MIButton';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { isEmpty } from '../../utils/utils';
import { useResetPassword } from '../../services/useResetPassword';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import MIAlert from '../../components/Widgets/MIAlert';
import { useResponsive } from '../../services/useResponsive';

const desktopStyles = (theme) => {
    return {
        resetPasswordForm: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: theme.sizes.large_gap,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.text.primary,
        },
        formField: {
            marginBottom: theme.sizes.xlarge_gap,
        },
        resetPasswordTitle: {
            marginBlockEnd: theme.sizes.xlarge_gap,
        },
        emailLabel: {
            display: 'flex',
            marginBottom: theme.sizes.micro_gap,
            fontWeight: 500,
        },
        forgottenPassword: {
            marginInlineStart: 'auto',
            marginInlineEnd: 0,
            fontSize: theme.typography.body2.fontSize,
        },
        resetPasswordButtonContainer: {
            marginLeft: theme.sizes.xlarge_gap,
            display: 'inline-block',
            width: theme.widths.login_button,
            height: theme.sizes.xxlarge_gap
        },
        cancelButtonContainer: {
            display: 'inline-block',
            width: theme.widths.login_button,
            height: theme.sizes.xxlarge_gap,
            marginTop: theme.sizes.planck_gap,
        },
        buttonsContainer: {
            display: 'flex'
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        resetPasswordForm: {},
        formField: {
            marginBottom: theme.sizes.xlarge_gap,
            width: theme.widths.mobile_textField,
        },
        resetPasswordTitle: {
            fontSize: theme.fontSizes.small,
            textAlign: 'center',
            marginTop: theme.sizes.no_gap,
            marginBottom: theme.sizes.xmlarge_gap,
        },
        buttonsContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: theme.sizes.tiny_gap,
        },
        resetPasswordButtonContainer: {
            width: '48%',
        },
        cancelButtonContainer: {
            width: '48%',
        },
        cancelButton: {
            width: '100%',
            fontSize: theme.fontSizes.mobile,
            padding: theme.sizes.no_gap
        },
        resetPasswordButton: {
            width: '100%',
            fontSize: theme.fontSizes.mobile,
            padding: theme.sizes.no_gap
        }
    });
};

const ForgottenPassword = (props) => {
    const { t, i18n } = useTranslation();
    const [ resetStatus, responseStatus, resetPassword ] = useResetPassword();
    const [ alert, setAlert ] = useState({});
    const [ isShowing, setIsShowing ] = useState(false);
    const { onCancel, callback, username } = props;
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    });
    const classes = useStyles();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm({
        shouldFocusError: false
    });

    const doSendResetEmail = (values) => {
        resetPassword(values.username, i18n.language);
    };

    const showAlert = useCallback(() => {
        setAlert({ message: 'forgotten_password_dialog', severity: 'success' });
        setIsShowing(true);
    }, []);

    useEffect(() => {
        callback(watch('username'));
    }, [ callback, watch ]);

    useEffect(() => {
        if (resetStatus) {
            try {
                showAlert(resetStatus);
            } catch (error) {
                console.error('Forgotten password error: ', error);
            }
        }
    }, [ resetStatus, showAlert ]);

    const emailErrorText = (error) => {
        return error && error.type === 'required' && t('email_required') ||
            error && error.type === 'validate' && t('email_invalid');
    };

    return (
        <div className={classes.resetPasswordForm}>
            <form onSubmit={handleSubmit(doSendResetEmail)}>
                <h1 className={classes.resetPasswordTitle}
                    tabIndex={0}
                    aria-live={'polite'}>{t('forgotten_password')}</h1>
                <label className={classes.emailLabel}>
                    {t('email_address')}
                </label>
                <MITextField
                    className={classes.formField}
                    ariaLabel={t('aria_label_email')}
                    inputRef={register({
                        required: true,
                        validate: (value) => {
                            return isEmail(value);
                        }
                    })}
                    id="txtUserName"
                    variant="outlined"
                    name="username"
                    error={errors.username && errors.username.type === 'required' ||
                    errors.username && errors.username.type === 'validate'}
                    helperText={emailErrorText(errors.username)}
                    defaultValue={!isEmpty(username) ? username : null}
                />
                <div className={classes.buttonsContainer}>
                    <div className={classes.cancelButtonContainer}>
                        <MIButton
                            className={classes.cancelButton}
                            ariaLabel={t('reset_password_cancel_button')}
                            mi_size="large"
                            mi_type="primary"
                            variant="outlined"
                            type="button"
                            onClick={onCancel}
                        >
                            {t('reset_password_cancel_button')}
                        </MIButton>
                    </div>
                    <div className={classes.resetPasswordButtonContainer}>
                        <MIApiLoader
                            customStyle={{
                                spinnerWidth: '30px',
                                spinnerHeight: '30px',
                                marginLeft: '2px',
                                marginRight: '2px',
                                marginTop: '10px'
                            }}
                            MIComponent={<MIButton
                                className={classes.resetPasswordButton}
                                ariaLabel={t('reset_password')}
                                mi_size={'large'}
                                mi_type={'primary'}
                                type={'submit'}
                            >
                                {t('reset_password')}
                            </MIButton>}
                            responseStatus={responseStatus}
                            ariaLabelProgressBar={t('aria_label_progressbar_forgotten_password')}
                        />
                    </div>
                </div>
            </form>
            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                severity={alert.severity}
                ariaLabel={t('aria_forgotten_password_message')}
                onHide={() => {
                    if (resetStatus) {
                        onCancel(null);
                    }
                }}
                autoHideDuration={5000}
            />
        </div>
    );
};

export default ForgottenPassword;
