import React from 'react';
import {useTranslation} from "react-i18next";
import {Route, Switch} from "react-router-dom";
import WasteConsignment from "./WasteConsignment";
import MINavMenu from "../../components/Widgets/MINavMenu";
import WasteTransferNotes from "./WasteTransferNotes";
import {useFlags} from "../../contexts/FlagsProvider/FlagsProvider";
import QuarterlyReturns from "./QuarterlyReturns";

const Waste = () => {
    const {t} = useTranslation();
    const {feature_hazardous_waste_consignment_notes,
        feature_hazardous_waste_portugal,
        feature_hazardous_waste_transfer_notes,
        feature_quarterly_returns} = useFlags();
    const hazardousWasteEnabled = feature_hazardous_waste_consignment_notes || feature_hazardous_waste_portugal;

    const wasteConsignment = {
        path: '/waste/waste-consignment',
        label: t('nav_waste_consignment'),
        ariaLabel: t('aria_label_waste_consignment_tab')
    };
    const wasteTransferNotes = {
        path: '/waste/waste-transfer-notes',
        label: t('annual_waste_transfer_notes'),
        ariaLabel: t('aria_label_annual_waste_transfer_notes_tab')
    };
    const quarterlyReturns = {
        path: '/waste/quarterly-returns',
        label: t('quarterly_returns'),
        ariaLabel: t('aria_label_quarterly_returns_tab')
    };

    const navigation = [ ];

    // Show the Consignment notes if feature_hazardous_waste_consignment_notes or feature_hazardous_waste_portugal is true
    if (hazardousWasteEnabled) {
        navigation.push(wasteConsignment);
    }

    // show the transfer notes if feature_hazardous_waste_transfer_notes is true
    if (feature_hazardous_waste_transfer_notes) {
        navigation.unshift(wasteTransferNotes);
    }

    if(feature_quarterly_returns){
        navigation.push(quarterlyReturns);
    }


    return (
        <>
            <MINavMenu routes={navigation} level={'secondary'}/>
            <Switch>
                {hazardousWasteEnabled && <Route path={wasteConsignment.path} component={WasteConsignment}/>}
                {feature_hazardous_waste_transfer_notes && <Route path={wasteTransferNotes.path} component={WasteTransferNotes}/>}
                {feature_quarterly_returns && <Route path={quarterlyReturns.path} component={QuarterlyReturns}/>}
                <Route path='/waste' component={feature_hazardous_waste_transfer_notes ? WasteTransferNotes : WasteConsignment}/>
            </Switch>
        </>
    );
};

export default Waste;