import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import { FORMFACTOR } from '../utils/enums';

const desktopStyles = (theme) => ({
    root: (props) => ({
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: theme.palette.background.paper,
        padding: 10,
        width: props.rootWidth ? props.rootWidth : '704px',
        height: props.rootHeight ? props.rootHeight : '600px',
        paddingTop: props.paddingTop ? props.paddingTop : theme.sizes.xlarge_gap,
        paddingBottom: props.paddingBottom ? props.paddingBottom : theme.sizes.xlarge_gap,
        paddingLeft: props.paddingLeft ? props.paddingLeft : theme.sizes.large_gap,
        paddingRight: props.paddingRight ? props.paddingRight : theme.sizes.large_gap,
        fontFamily: theme.typography.fontFamily,
        border:'1px solid',
        borderColor:  theme.palette.primary.light,
        borderRadius: props.borderRadius ? props.borderRadius : '0px',
        '&:focus': theme.ariaFocus,
    }),

    icon: {
        fill: theme.palette.primary.main,
        cursor: 'pointer',
        '&:focus': theme.ariaFocus,
    },
    titleContainer: (props) => ({
        paddingBottom: props.customBottomPadding ? props.customBottomPadding : theme.sizes.large_gap,
        textAlign: props.centerTitle ? 'center' : 'left',
        paddingRight: props.centerTitle ? theme.sizes.xmlarge_gap : '',
        paddingLeft: props.centerTitle ? theme.sizes.xmlarge_gap : '',
    }),
    iconContainer: (props) => ({
        width: '30px',
        height: props.iconContainerHeight ? props.iconContainerHeight : '30px',
        display: 'inline-flex',
        position: 'absolute',
        right: theme.sizes.large_gap,
        alignItems: props.iconContainerAlignItems ? props.iconContainerAlignItems : 'unset'
    })
});

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        titleContainer: {
            ...baseStyle.titleContainer,
            paddingRight: theme.sizes.small_gap,
            paddingLeft: theme.sizes.small_gap,
        },
        iconContainer: {
            ...baseStyle.iconContainer,
            right: theme.sizes.tiny_gap,
        },
    });
};


const MIModal = (props) => {
    const { isShowing, customStyle, onClose, body, header, hideCloseIcon, formFactor, ariaLabel } = props;
    const { t, i18n } = useTranslation();
    const float = i18n.dir() === 'ltr' ? 'right' : 'left';
    const style = Object.assign({}, customStyle, { float:float });

    const useStyles = makeStyles((theme) => {
        switch (formFactor) {
        case FORMFACTOR.MOBILE:
            return mobileStyles(theme);
        case FORMFACTOR.DESKTOP:
        default:
            return desktopStyles(theme);
        }
    });
    const classes = useStyles(style);

    if (isShowing) {
        return (
            <Modal open={isShowing} onClose={onClose} aria-label={ariaLabel} role={'main'}>
                <Container dir={i18n.dir()} className={classes.root} aria-label={ariaLabel}>
                    <div className={classes.titleContainer}>
                        {header}
                        {!hideCloseIcon &&
                            <div className={classes.iconContainer}>
                                <CloseIcon
                                    data-testid={'close_modal_icon'}
                                    aria-label={t('aria_label_close_modal_button')}
                                    className={classes.icon}
                                    onClick={onClose}
                                    data-dismiss={'modal'}
                                    aria-live={'polite'}
                                    tabIndex={0}
                                />
                            </div>
                        }
                    </div>
                    {body}
                </Container>
            </Modal>
        );
    }
    return null;
};

export default MIModal;
