import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import { formatDate, isEmpty } from '../../utils/utils';
import useApiRequest from '../requests/useApiRequest';

export const useExtraVisitDetails = () => {
    const [ extraVisitDetails, setExtraVisitDetails ] = useState({});
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/premiseService/extraPremiseVisitDetails';

    const formatProduct = (product) => {
        // change the format in order to be the same of the GCP output
        return {
            EWCCode: product.ewcCode,
            ProductName: product.productServiced,
            QuantityServiced: product.qty,
            WasteHazardType: product.hazardousWasteFlag,
        };
    };

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                const konyInfo = response.data;
                const siteOverview = !isEmpty(konyInfo?.siteOverview) ? konyInfo.siteOverview[0] : {};

                // change the format in order to be the same of the GCP output
                const formattedInfo = !isEmpty(siteOverview) ? {
                    visitDetails: {
                        DepotAddressLine1: siteOverview.wasteTransferredTo,
                        PremiseName: siteOverview.siteName,
                        ServiceTime: siteOverview.timeOut,
                        TechnicianForename: !isEmpty(siteOverview.technician) ? siteOverview.technician : '',
                        TechnicianSurname : '',
                        VisitDate: siteOverview.visitDate,
                        VisitType: siteOverview.visitType,
                        WasteConsignmentNoteNumber: siteOverview.hazardousWasteNo
                    },
                } : {};

                if(!isEmpty(konyInfo?.sign)){
                    formattedInfo.Sign = konyInfo?.sign
                }

                if(!isEmpty(konyInfo?.productDetailsList)){
                    formattedInfo.visitProducts = konyInfo.productDetailsList.map((product) => {
                        return formatProduct(product);
                    });
                }

                setExtraVisitDetails(formattedInfo);
            } catch (error) {
                console.error('useExtraVisitDetails error: ', error);
                setExtraVisitDetails({});
            }
        }
    }, [ responseStatus, response, setExtraVisitDetails ]);

    const getVisitDetails = useCallback((siteId, premiseVisitNo, visitDate, countryCode, locale) => {
        setExtraVisitDetails({});
        const date = formatDate(visitDate);
        const url = [ endpoint, siteId, premiseVisitNo, date, countryCode, locale ].join('/');
        makeRequestWithParams(url).catch((error) => {
            console.error('useExtraVisitDetails error: ', error);
        });
    }, [ makeRequestWithParams ]);

    return [ extraVisitDetails, responseStatus, getVisitDetails ];
};
