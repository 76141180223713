import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import {setDocumentTitle} from '../../utils/utils';
import ExistingReportsTable from '../../components/ExistingReportsTables/ExistingReportsTable';
import {useResponsive, ResponsiveComponent} from '../../services/useResponsive';
import MIPageTitle from '../../components/Widgets/MIPageTitle';

const desktopStyles = (theme) => {
    return {
        root: {
            width: "100%",
            maxWidth: '928px',
            minHeight: '500px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        actionIcon: {
            fill: theme.palette.primary.main,
            marginRight: theme.sizes.small_gap,
            width: theme.sizes.large_gap,
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.large_gap,
            paddingRight: '0px',
            paddingLeft: '0px',
            maxHeight: 'none',
        },
    });
};

const ExistingReport = () => {
    const {t} = useTranslation();
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_existing_reports');
     }, [t]);

    return (
        <Paper className={classes.root} aria-label={t('aria_label_existing_report_page')}>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: <MIPageTitle title={t('existing_report')} ariaLabel={t('aria_label_existing_report_title')}/>
                }}
            />

            <ExistingReportsTable
                pagination={true}
                hideFooterRowCount={true}
            />
        </Paper>
    );
};

export default ExistingReport;
