import { makeAutoObservable } from 'mobx';
import moment from 'moment';

class NotificationsStore {
    notifications = [];
    seenNotifications = [];
    lastNotificationSeen = null;

    constructor() {
        makeAutoObservable(this);
    }

    setNotifications(notificationsList) {
        this.notifications = notificationsList;
    }

    setSeenNotifications(seenNotificationsList) {
        this.seenNotifications = seenNotificationsList;
    }

    setLastNotificationSeen() {
        this.lastNotificationSeen = moment().milliseconds();
    }

    reset() {
        this.notifications = [];
        this.seenNotifications = [];
        this.lastNotificationSeen = null;
    }
}

export { NotificationsStore };
