import { useState, useCallback, useEffect } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";
import {useAuthState} from "../../contexts/AuthProvider";
import { isEmpty } from '../../utils/utils';
import axios from 'axios';

export const useGetFloorPlanImage = () => {
    const [ floorPlanImage, setFloorPlanImage ] = useState(null);
    const { countryCode } = useAuthState();
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getFloorPlanImage';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                if (response.data && response.data.signedUrl) {
                    setFloorPlanImage(response.data.signedUrl);
                }
            } catch (error) {
                console.error('useGetFloorPlanImage error: ', error);
                setFloorPlanImage(null);
            }
        }
    }, [ responseStatus, response, setFloorPlanImage ]);

    const getFloorPlanImage = useCallback((floorId) => {
        setFloorPlanImage(null);
        const url = [ endpoint, countryCode, floorId ].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetFloorPlanImage makeRequest: ', error);
        });
    }, [ makeFloorPlanApiRequestWithParams, countryCode ]);

    const hasUrlExpired = async () => {
        if (!isEmpty(floorPlanImage)) {
            try {
                const res = await axios.get(floorPlanImage);
                return res.status !== 200;
            } catch (e) {
                return true;
            }
        }
    };

    const checkUrlExpiry = (floorId) => {
        hasUrlExpired().then((hasExpired) => {
            if (hasExpired) {
                getFloorPlanImage(floorId);
            }
        });
    };

    return [ floorPlanImage, responseStatus, getFloorPlanImage, checkUrlExpiry ];
};

