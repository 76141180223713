import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuthState } from '../../contexts/AuthProvider/AuthProvider';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@material-ui/icons/Person';
import { Avatar, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import { mergeStyles, isEmpty } from '../../utils/utils';
import CountrySelector from '../CountrySelector/CountrySelector';
import useModal from '../../services/useModal';
import { ResponsiveComponent, useResponsive } from '../../services/useResponsive';
import { useRegionStore } from '../../contexts/StateProvider/StateProvider';
import Notification from './Notification';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import { Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

const desktopStyles = (theme) => {
    return {
        actions: {
            display: 'flex',
            marginInlineStart: 'auto',
        },
        languageSwitcher: {
            display: 'inline',
            marginTop: theme.sizes.micro_gap,
        },
        avatar: {
            cursor: 'pointer',
        },
        avatarOuter: {
            paddingBottom: '8px',
        },
        avatarMenu: {
            paddingBottom: '0px',
            width: '160px',
        },
        profileNameContainer: {
            alignItems: 'center',
            marginTop: 10,
            height: theme.heights.sidebar_height,
            maxWidth: theme.widths.profile_name_container_width,
        },
        profileNameText: {
            margin: theme.sizes.no_gap,
            marginLeft: theme.sizes.micro_gap,
            marginRight: theme.sizes.small_gap,
            color: theme.colors.mainText,
            fontSize: theme.fontSizes.nav,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        },
        popper: {
            zIndex: 200,
            marginTop: theme.sizes.nano_gap
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        languageSwitcher: {
            ...baseStyle.languageSwitcher,
            marginLeft: theme.sizes.micro_gap,
        },
    });
};

const Profile = observer((props) => {
    const { t } = useTranslation();
    const [ userOpen, setUserOpen ] = useState(false);
    const anchorRef = useRef(null);
    const ssoFormRef = useRef(null);
    const tokenRef = useRef(null);
    const { logout, user, status } = useAuthState();
    const { avatar } = props;
    const { isShowing:isShowingCountrySelector, changeStatus:toggleCountrySelector } = useModal();
    const regionStore = useRegionStore();
    const { feature_notifications } = useFlags();
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );
    const classes = useStyles();

    const forename = (status && status() === 'logged_in' && user && user().forename) || '';
    const surname = (status && status() === 'logged_in' && user && user().surname) || '';
    const fullName = [ forename, surname ].join(' ');

    const handleLogout = () => {
        return logout();
    };

    const handleToggle = () => {
        return setUserOpen((prevOpen) => {
            return !prevOpen;
        });
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setUserOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setUserOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(userOpen);
    useEffect(() => {
        if (prevOpen.current && !userOpen) {
            anchorRef.current.focus();
        }
        prevOpen.current = userOpen;
    }, [ userOpen ]);

    return (
        <div className={classes.actions}>
            {
                <ResponsiveComponent
                    components={{
                        mobile: null,
                        desktop:
                            <div className={classes.profileNameContainer}>
                                <Tooltip title={forename}>
                                    <Typography className={classes.profileNameText}>
                                        {forename}
                                    </Typography>
                                </Tooltip>
                                 <Tooltip title={surname}>
                                    <Typography className={classes.profileNameText}>
                                        {surname}
                                    </Typography>
                                </Tooltip>
                            </div>,
                    }}
                />
            }
            {feature_notifications && <Notification/>}
            <Avatar
                ref={anchorRef}
                className={mergeStyles(classes.avatar, avatar)}
                alt={t('profile_avatar')}
                onClick={handleToggle}
                aria-label={t('profile_avatar')}
                id="profile_avatar"
            >
                <PersonIcon/>
            </Avatar>
            <Popper
                open={userOpen}
                anchorEl={anchorRef.current}
                placement={'bottom-end'}
                role={undefined}
                transition
                disablePortal
                className={classes.popper}>
                {({ TransitionProps, placement }) => {
                    return <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'left bottom' ? 'left top' : 'right top' }}
                    >
                        <Paper className={classes.avatarOuter}>
                            <ClickAwayListener mouseEvent={'onMouseUp'} onClickAway={handleClose}>
                                <div>
                                    <MenuList
                                        className={classes.avatarMenu}
                                        autoFocusItem={userOpen}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}>
                                        <MenuItem onClick={toggleCountrySelector} id={'country_language_selector'}>
                                            {t('language_or_country')}
                                        </MenuItem>
                                        <MenuItem onClick={handleLogout}> {t('logout')} </MenuItem>
                                        <form method="post" action="" target="_blank" ref={ssoFormRef}>
                                            <input type="hidden" name="email" value=" "/>
                                            <input type="hidden" name="language" value=" "/>
                                            <input type="hidden" name="form" value=" "/>
                                            <input type="hidden" ref={tokenRef} name="token" value=""/>
                                        </form>
                                    </MenuList>
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>;
                }
                }
            </Popper>
            {!isEmpty(regionStore) && !isEmpty(regionStore.getAvailableRegions()) &&
                <CountrySelector
                    countriesList={regionStore.getAvailableRegions()}
                    isShowing={isShowingCountrySelector}
                    changeStatus={toggleCountrySelector}
                    hideCloseIcon={false}
                    changeLanguage={false}
                />
            }
        </div>
    );
});

export default Profile;
