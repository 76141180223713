import React, {useState} from 'react';
import MIGrid, {GetRowCount} from "../../../components/Widgets/MIGrid";
import {makeStyles} from "@material-ui/core/styles"
import {Typography} from '@material-ui/core';
import {FETCHING} from "../../../services/requests/requestReducer";
import {useTranslation} from "react-i18next";
import useModal from '../../../services/useModal';
import {isEmpty} from '../../../utils/utils';
import ExistingReportsActionMenu from "../ExistingReportsActions/ExistingReportsActionMenu";
import MIInfoDialog from '../../../components/Widgets/mobile/MIInfoDialog';

const useStyles = makeStyles(theme => ({
    dataCell: {
        lineHeight: 0,
        width: '100%',
        whiteSpace: 'normal',
        paddingLeft: theme.sizes.small_gap,
        paddingRight: theme.sizes.small_gap,
    },
    linkContainer: {
        display: 'inline-flex',
        float: 'right',
    },
    visitType: {
        display: 'inline-flex',
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileBody
    },
    reportTitle: {
        overflowWrap: 'break-word', 
        fontSize: theme.fontSizes.mobileBody
    },
    gridContainer: {
        "& div.MuiDataGrid-cell": {
            padding: '0px',
            whiteSpace: 'normal',
        },
        marginBottom: theme.sizes.micro_gap,
    },
    tableHeaders: {
        fontFamily: theme.fontFamily.proximaMedium,
        borderTop: theme.borders.outline_bold,
        borderBottom: theme.borders.outline_bold,
        padding: theme.sizes.micro_gap,
    },
    reportActionsHeader: {
        float: 'right',
        display: 'flex-end',
    },
}));

const ExistingReportsTableMobile = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {setIsShowing, rows, getExistingReports, setAlert, responseStatus, showRowCount, mobilePageSize} = props;
    const {isShowing:isInfoDialogShowing, changeStatus:changeInfoDialogStatus} = useModal();
    const [info, setInfo] = useState([]);

    const getPremiseAndAddressCell = (params) => {
        return (
            <div className={classes.dataCell}>
                <Typography className={classes.visitType}>{params.row.visitType}</Typography>
                <Typography className={classes.reportTitle}>{params.row.reportTitle}</Typography>
            </div>
        );
    };

    const columns = [
        {
            field: 'reportTitle',
            type: 'string',
            resizable: false,
            flex: 3,
            renderCell: (params) => getPremiseAndAddressCell(params),
        },
        {
            field: 'actions', headerName: t('report_actions'), type:'component', filterable: false, resizable: false, width: 45,
            sortable: false,
            renderCell: (params) => ( <ExistingReportsActionMenu params={params} setIsShowing={setIsShowing} getExistingReports={getExistingReports} setAlert={setAlert}/> )
        },
    ];

    const handleRowClick = (selectedRow) => {
        if (selectedRow.field === "reportTitle" && !isInfoDialogShowing & !isEmpty(selectedRow.row)) {
            const data = [];
            data.push(
                {label: t('report_title'), value: selectedRow.row.reportTitle},
                {label: t('visit_type'), value: selectedRow.row.visitType},
                {label: t('is_scheduled'), value: selectedRow.row.isScheduled},
                {label: t('next_run_date'), value: selectedRow.row.nextRunLocDate},
            );
            setInfo(data);
            changeInfoDialogStatus();
        }
    };

    const getHeader = () => (
        <div className={classes.tableHeaders}>
            <span>{[t('visit_type'), t('report_title')].join(' / ')}</span>
            <span className={classes.reportActionsHeader}>{t('report_actions')}</span>
        </div>
    );

    return (
        <div>
            {GetRowCount({rows, showRowCount})}
            <div className={classes.gridContainer}>
                {getHeader()}
                <MIGrid
                    isMobile={true}
                    showInfoModal={true}
                    isLoading={responseStatus===null || responseStatus === FETCHING}
                    columns={columns}
                    rows={rows}
                    rowHeight={55}
                    hasFilters={false}
                    pagination={true}
                    autoPageSize={false}
                    pageSize={mobilePageSize ? mobilePageSize : rows.length}
                    headerHeight={0}
                    heightToFitContent={true}
                    hideFooter={false}
                    noEvenRowStyling={true}
                    noElementsText={t('no_reports')}
                    ariaLabel={t('aria_label_existing_reports_table')}
                    ariaLabelProgressBar={t('aria_label_progressbar_existing_reports')}
                    hideFooterForOnePage={true}
                    waitFullyLoaded={true}
                    onCellClick={handleRowClick}
                    {...props}
                />
            </div>
            {<MIInfoDialog
                title={t('existing_report')}
                info={info}
                content={null}
                open={isInfoDialogShowing}
                handleClose={changeInfoDialogStatus}
            />}
        </div>
    );
};

export default ExistingReportsTableMobile;