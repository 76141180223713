import { useEffect } from 'react';
import { useContractList } from './useContractList';
import { usePremiseList } from './usePremiseList';
import { isEmpty } from '../utils/utils';
import { useAuthState } from '../contexts/AuthProvider';
import { useSiteStore } from '../contexts/StateProvider/StateProvider';

export const useSiteList = () => {
    const [ contracts, contractStatus, getContracts ] = useContractList();
    const [ premiseList, getContractPremises, premiseListStatus, pendingRequests ] = usePremiseList();
    const { locale, countryCode } = useAuthState();
    const siteStore = useSiteStore();
    const sites = siteStore.sites;

    useEffect(() => {
        if (isEmpty(contracts) && !isEmpty(locale) && !isEmpty(countryCode)) {
            getContracts(locale, countryCode);
        }
    }, [ contracts, getContracts, locale, countryCode ]);

    useEffect(() => {
        if (contracts.length > 0 && !siteStore.loadComplete && premiseList.length === 0) {
            getContractPremises(contracts);
        }
    }, [ getContractPremises, contracts, premiseList.length, siteStore.loadComplete ]);

    useEffect(() => {
        if (contracts.length > 0 && premiseList.length > 0) {
            let siteList = [];
            contracts.forEach((contract) => {
                contract.premiseList.forEach((premise) => {
                    let newPremise = JSON.parse(JSON.stringify(premise));
                    premiseList.forEach((_premise) => {
                        if (_premise.siteId === premise.siteId) {
                            newPremise.siteAddress = _premise.siteAddress;
                            newPremise.contractId = contract.contractNo;
                            newPremise.lastVisit = _premise.lastVisit;
                            newPremise.nextVisit = _premise.nextVisit;
                            newPremise.contractName = _premise.siteName;
                            newPremise.premiseNo = _premise.premiseNo;
                            return;
                        }
                    });
                    siteList.push(newPremise);
                });
            });
            if (pendingRequests < 1) {
                siteStore.setSites(siteList);
                siteStore.setLoadComplete(true);
            }
        }
    }, [ contracts, siteStore, premiseList, pendingRequests ]);

    return { sites, contractStatus, premiseListStatus, pendingRequests };
};
