import {useCallback} from 'react';
import {getCurrentHost} from "../utils/utils";
import {useFlags} from "../contexts/FlagsProvider/FlagsProvider";
import {useContentFromUrl} from "./useContentFromUrl";

export const useAppBranding = () => {
    const { feature_logo_branding_by_country, feature_logo_branding_by_address } = useFlags();
    const [, , , getContentUrl, getBaseUrl] = useContentFromUrl();

    const getAppBrandUrl = useCallback((fileName, defaultValue) => {
        if (feature_logo_branding_by_address) {
            return [getBaseUrl(), getCurrentHost(), 'assets', fileName].join('/');
        }
        if (feature_logo_branding_by_country) {
            return getContentUrl(['../assets', fileName].join('/'));
        }
        return defaultValue;
    }, [feature_logo_branding_by_address, feature_logo_branding_by_country, getBaseUrl, getContentUrl]);


    return [getAppBrandUrl];
};