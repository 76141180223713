import { useEffect } from 'react';
import { useAuthState } from '../../contexts/AuthProvider';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { isEmpty, addScriptWithPromise, containsEmpty } from '../../utils/utils';
import { observer } from 'mobx-react-lite';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

export const CHATBOT_ID = 'filament-ai-chat-widget';
export const CHATBOT_SCRIPT = 'chatbot-script';

const Chatbot = observer(() => {
    const { forename, surname, emailAddress } = useAuthState();
    const siteStore = useSiteStore();

    const selectedAccountNumber = siteStore.getAccountNumberWithContractNumber(siteStore.selectedContractNumber);

    // Show chatbot desktop only. useResponsive rendered isMobile False first before isMobile is True
    const showChatbot = useMediaQuery(useTheme().breakpoints.up('sm'));

    const url = process.env.REACT_APP_CHATBOT_URL;

    const addParameter = (elementID, paramName, value) => {
        elementID.setAttribute([ 'parameter', paramName ].join('-'), value);
    };

    const removeChatbot = () => {
        // Cleans up after Chatbot is unrendered.
        const chatbot = document.getElementById(CHATBOT_ID);
        const chatbotScript = document.getElementById(CHATBOT_SCRIPT);
        if (chatbot) {
            chatbot.remove();
        }
        if (chatbotScript) {
            chatbotScript.remove();
        }
    };

    useEffect(() => {
        // Only add/remove the chatbot if we haven't added the script already and check that all the parameters have been fetched.
        const siteStoreFetched = !isEmpty(siteStore.selectedContractNumber) && !isEmpty(selectedAccountNumber) && !isEmpty(siteStore.selectedPremiseNumber);
        const siteAddressesFetched = !containsEmpty(siteStore.siteAddresses);

        if (!document.getElementById(CHATBOT_SCRIPT) && siteStoreFetched && siteAddressesFetched) {
            if (showChatbot && !isEmpty(url)) {
                const scriptParams = [
                    { key: 'id', value: CHATBOT_SCRIPT },
                    { key: 'async', value: true }
                ];
                const chatbotScript = addScriptWithPromise('script', url, null, scriptParams);
                chatbotScript.then(() => {
                    const chatbot = document.getElementById(CHATBOT_ID);
                    addParameter(chatbot, 'forename', forename);
                    addParameter(chatbot, 'surname', surname);
                    addParameter(chatbot, 'email_address', emailAddress);
                    addParameter(chatbot, 'contract_no', siteStore.selectedContractNumber);
                    addParameter(chatbot, 'premise_no', siteStore.selectedPremiseNumber);
                    addParameter(chatbot, 'account_no', selectedAccountNumber);
                });
            } else {
                removeChatbot();
            }
        }
        return () => {
            removeChatbot();
        };
    }, [ showChatbot,
        url,
        siteStore.selectedContractNumber,
        siteStore.selectedPremiseNumber,
        siteStore.siteAddresses,
        emailAddress,
        forename,
        selectedAccountNumber,
        surname,
    ]);

    return (
        null
    );
});

export default Chatbot;
