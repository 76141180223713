import {useState, useEffect, useCallback} from 'react';
import {SUCCESS} from '../requests/requestReducer';
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";
import {useAuthState} from "../../contexts/AuthProvider";
import {useTranslation} from "react-i18next";
import {getLogStatus} from "./useUpdateRecommendation";

export const useGetCommentId = () => {
    const [commentId, setCommentId] = useState(null);
    const [{responseStatus, response}, makeFloorPlanApiRequestWithParams] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/updateRecommendationDetails';
    const {forename, surname, emailAddress, locale} = useAuthState();
    const {t} = useTranslation();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setCommentId(response.data.comment_id);
            } catch (error) {
                console.error('useUpdateRecommendation error: ', error);
            }
        }
    }, [responseStatus, response, setCommentId]);

    const getKonyRecommendationId = (recommendationId) => {
        // remove the countryCode inside the recommendationId
        return recommendationId.replace(/[A-Z]*/g, '');
    };

    const getCommentId = useCallback((recommendationId, comment, currentStatus, newStatus) => {
        setCommentId(null);

        const reason = getLogStatus(t(currentStatus.toLowerCase()), t(newStatus.toLowerCase()));
        const params = {
            recId: getKonyRecommendationId(recommendationId),
            locale: locale,
            status: newStatus,
            comment: `${comment} ${reason}`,
            username: `${forename} ${surname}`,
            beforeValue: currentStatus,
            afterValue: newStatus,
            reason: reason,
            email: emailAddress,
        };

        makeFloorPlanApiRequestWithParams(endpoint, 'post', params).catch((error) => {
            console.error('useUpdateRecommendation error: ', error);
        });
    }, [makeFloorPlanApiRequestWithParams, forename, surname, emailAddress, locale, t]);

    return [commentId, responseStatus, getCommentId];
};
