import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import SiteSelector from "../../components/SiteSelector/SiteSelector";
import MIPageTitle from '../../components/Widgets/MIPageTitle';
import {useSiteInfo} from "../../services/useSiteInfo";
import {getLocalizedDate, isEmpty, setDocumentTitle} from '../../utils/utils';
import VisitHistory from "../../components/VisitHistory/VisitHistory";
import MIInfoBlock from "../../components/Widgets/MIInfoBlock";
import {useSiteStore} from "../../contexts/StateProvider/StateProvider";
import {observer} from "mobx-react-lite";
import Theme from '../../components/Theme/Theme';
import isEmail from "validator/lib/isEmail";
import {useResponsive, ResponsiveComponent} from '../../services/useResponsive';

const desktopStyles = (theme) => {
    return {
        root: {
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        link: {
            color: theme.palette.primary.main,
            textDecoration: 'none',
            ":hover": {textDecoration: 'none'},
            ":visited": {textDecoration: 'none'},
            ":active": {textDecoration: 'none'},
            '&:focus': {
                outline:'none'
            },
        }
    }
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.large_gap,
            paddingRight: '0px',
            paddingLeft: '0px',
            maxHeight: 'none',
        },
        content: {
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            marginBottom: theme.sizes.xxlarge_gap,
        },
        infoBlock: {
            minHeight: theme.heights.mobile_info_block
        }
    });
};

const FALLBACK_VALUE = '-';

const VisitHistoryPage = observer(() => {
    const {t} = useTranslation();
    const [siteInfo, siteInfoStatus, getSiteInfo] = useSiteInfo();
    const siteStore = useSiteStore();
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    useEffect(() => {
        if (!isEmpty(siteStore.selectedContractNumber) &&
            !isEmpty(siteStore.selectedPremiseNumber)) {
            getSiteInfo(siteStore.selectedContractNumber, siteStore.selectedPremiseNumber);

        }
    }, [siteStore, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, getSiteInfo]);

    useEffect(() => {
        setDocumentTitle(t, 'nav_visit_history');
    }, [t]);

    const getLabelsMapping = (site, siteInfo) => {
        return {
            contract_number: site.contractId,
            contact: siteInfo.Contact,
            premise_number: site.premiseNo,
            contact_number: siteInfo.PhoneNumber,
            next_visit_date: site.nextVisit ? getLocalizedDate(t, site.nextVisit) : null,
            contact_mobile: siteInfo.MobileNumber,
            ...(getStyles({desktop: {spaceHolder: 'spaceHolder'}})),
            contact_email: emailLink(siteInfo.Email),
        }
    };

    const emailLink = (email) => {
        return !isEmpty(email) ? (
            <a href={isEmail(email) ? `mailto:${email}` : null} className={classes.link}>{email.toLowerCase()}</a>) : null;
    };

    const createInfoRow = (label, value) => {
        return {label: label !== 'spaceHolder' ? t(label) : '', value: value || FALLBACK_VALUE};
    };

    const getInfo = () => {
        let info = [];
        const labelsMapping = getLabelsMapping(siteStore.selectedSite || {}, siteInfo || {});
        for (let key in labelsMapping) {
            info.push(createInfoRow(key, labelsMapping[key]));
        }
        return info;
    };

    return (
        <Paper className={classes.root} aria-label={t('aria_label_visit_history_page')}>
            <SiteSelector/>
            <div className={classes.content}> 
                <div className={classes.infoBlock}> 
                <MIInfoBlock
                    infoStatus={siteInfoStatus}
                    optionalCondition={isEmpty(siteStore.selectedSiteId)}
                    info={getInfo()}
                    backgroundColor={Theme.palette.action.hover}
                    hasColumns={getStyles({desktop: true, mobile: false})}
                    marginTop={getStyles({desktop: null, mobile: Theme.sizes.pico_gap})}
                />
                </div>
                <ResponsiveComponent
                    components={{
                        mobile: null,
                        desktop: 
                            <MIPageTitle
                                title={t('visit_history')}
                                ariaLabel={t('aria_label_visit_history_title')}
                                marginTop={16}
                                marginBottom={24}
                            />
                    }}
                />
                <VisitHistory mobilePageSize={10} />
            </div>
        </Paper>
    );
});

export default VisitHistoryPage;
