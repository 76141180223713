import {useState, useEffect, useCallback} from 'react';
import useApiRequest from "../requests/useApiRequest";
import {SUCCESS} from "../requests/requestReducer";
import {useVisitStore} from "../../contexts/StateProvider/StateProvider";
import {isEmpty} from "../../utils/utils";

export const usePlannedVisits = () => {
    const visitStore = useVisitStore();
    const [currentSiteId, setCurrentSiteId] = useState(null);
    const [plannedVisits, setPlannedVisits] = useState(null);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/premiseService/premiseVisitPlanning/';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                visitStore.setPlannedVisits(currentSiteId, response.data.visitPlan);
                setPlannedVisits(response.data.visitPlan);
            } catch (error) {
                console.error('usePlannedVisits error: ', error);
                visitStore.setPlannedVisits(currentSiteId, []);
                setPlannedVisits(null);
            }
        }
    }, [responseStatus, response, setPlannedVisits, visitStore, currentSiteId]);

    const getPlannedVisits = useCallback((siteId, locale) => {
        const storedPlannedVisits = visitStore.getPlannedVisits(siteId);
        if (!isEmpty(storedPlannedVisits)) {
            setPlannedVisits(storedPlannedVisits);
        } else {
            setPlannedVisits(null);
            setCurrentSiteId(siteId);
            const url = endpoint + siteId + '/' + locale;
            makeRequestWithParams(url).catch(error => {
                console.error('getPlannedVisits makeRequestWithParams: ', error)
            });
        }
    }, [makeRequestWithParams, visitStore])

    return [plannedVisits, (plannedVisits !== null) ? SUCCESS : responseStatus, getPlannedVisits];
};
