import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";

export const useGetFloors = () => {
    const [ floors, setFloors ] = useState(null);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getFloors';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setFloors(response.data.results);
            } catch (error) {
                console.error('useGetFloors error: ', error);
                setFloors(null);
            }
        }
    }, [ responseStatus, response, setFloors ]);

    const getFloors = useCallback((buildingId) => {
        const url = [ endpoint, buildingId ].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetFloors makeRequest: ', error);
        });
    }, [ makeFloorPlanApiRequestWithParams ]);

    return [ floors, responseStatus, getFloors ];
};
