import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import SiteList from './SiteList';
import VisitHistoryPage from './VisitHistoryPage';
import MINavMenu from '../../components/Widgets/MINavMenu';
import ContractOverview from './ContractOverview/ContractOverview';
import VisitDetails from './VisitDetails';
import SmartHygiene from './SmartHygiene/SmartHygiene';
import RecommendationsPage from './Recommendations/RecommendationsPage';
import FloorPlan from './FloorPlan/FloorPlan';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import RecommendationDetailsPage from './Recommendations/RecommendationDetailsPage';
import { useResponsive } from '../../services/useResponsive';
import Entitlements from './Entitlements/Entitlements';

const Service = () => {
    const { t } = useTranslation();
    const { feature_rapid_hygiene, feature_recommendations, feature_floorplan, feature_entitlements } = useFlags();
    const { isMobile } = useResponsive();

    const navigation = [
        { path: '/service/site-list', label: t('nav_site_list'), ariaLabel: t('aria_label_site_list_tab') },
        { path: '/service/visit-history', label: t('nav_visit_history'), ariaLabel: t('aria_label_visit_history_tab') },
        { path: '/service/contract-overview', label: t('nav_contract_overview'), ariaLabel: t('aria_label_contract_overview_tab') },
    ];

    const routes = [ 'site-list', 'visit-history', 'contract-overview' ];

    if (feature_entitlements && !isMobile) {
        navigation.push({
            path: '/service/entitlements',
            label: t('nav_entitlements'),
            ariaLabel: t('aria_label_entitlements_tab')
        });
        routes.push('entitlements');
    }

    if (feature_rapid_hygiene) {
        navigation.push({
            path: '/service/smart-hygiene',
            label: t('nav_smart_hygiene'),
            ariaLabel: t('aria_label_smart_hygiene_tab')
        });
        routes.push('smart-hygiene');
    }

    if (feature_floorplan && !isMobile) {
        navigation.push({
            path: '/service/floorplan',
            label: t('nav_floorplan'),
            ariaLabel: t('aria_label_floorplan_tab')
        });
        routes.push('floorplan');
    }

    if (feature_recommendations) {
        navigation.push({
            path: '/service/recommendations',
            label: t('nav_recommendations'),
            ariaLabel: t('aria_label_recommendations_tab')
        });
        routes.push('recommendations');
    }

    return (
        <>
            <MINavMenu routes={navigation} level={'secondary'}/>
            <Switch>
                <Route path={navigation[0].path} component={SiteList}/>
                <Route path={`${navigation[1].path }/visit-details`} component={VisitDetails}/>
                <Route path={navigation[1].path} component={VisitHistoryPage}/>
                <Route path={navigation[2].path} component={ContractOverview}/>
                {feature_entitlements && !isMobile && <Route path={navigation[routes.indexOf('entitlements')].path} component={Entitlements}/>}
                {feature_rapid_hygiene && <Route path={navigation[routes.indexOf('smart-hygiene')].path} component={SmartHygiene}/>}
                {feature_recommendations && <Route path={`${navigation[routes.indexOf('recommendations')].path }/recommendation-details`} component={RecommendationDetailsPage}/>}
                {feature_recommendations && <Route path={navigation[routes.indexOf('recommendations')].path} component={RecommendationsPage}/>}
                {feature_floorplan && !isMobile && <Route path={navigation[routes.indexOf('floorplan')].path} component={FloorPlan}/>}
                <Route path="/service" component={SiteList}/>
            </Switch>
        </>
    );
};

export default Service;
