import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SocialIcons from '../SocialIcons';
import { isEmpty, extractBaseURL, mergeStyles } from '../../../utils/utils';
import LogoImage from '../../../assets/footer-logo.svg';
import { useTranslation } from 'react-i18next';
import { useAppBranding } from '../../../services/useAppBranding';

const useStyles = makeStyles(theme => ({
    footerSpacer: {
        height: '104px',
        background: theme.palette.background.default,
    },
    footer: {
        display: 'block',
        border: theme.borders.mainContent,
        marginLeft: '-2px',
        marginRight:'-2px',
        width: '100%',
        height: '104px',
        background: theme.palette.background.paper,
        color: theme.palette.text.secondary,
        textAlign: 'center',
    },
    footerTop: {
        display: 'flex',
        height: theme.sizes.xxxlarge_gap,
        maxWidth: '1440px',
        margin: 'auto',
    },
    footerBottom: {
        display: 'flex',
        height: theme.sizes.xxlarge_gap,
        maxWidth: '1440px',
        margin: 'auto',
    },
    siteLinks: {
        alignItems: 'center',
        flexGrow: 3,
        paddingLeft: theme.sizes.small_gap,
        paddingRight: theme.sizes.small_gap,
    },
    link: {
        color: theme.palette.text.primary,
    },
    link_separator: {
        margin: '4px',
    },
    iconLinks: {
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    footerWebsites: {
        height: 'fill',
        width: '192px',
        borderRadius: '0px',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        '&:hover': {
            textTransform: 'none',
            backgroundColor: theme.palette.background.highlight
        },
    },
    footerWebsiteText: {
        textTransform: 'none',
        color: theme.palette.text.primary,
    },
    premiumScenting: {
        borderTop: '4px solid #450A4C',
    },
    ambius: {
        borderTop: '4px solid #265900',
    },
    rentokilInitial: {
        borderTop: '4px solid #0F202E',
    },
    initial: {
        borderTop: '4px solid #003d81',
    },
    rentokil: {
        borderTop: '4px solid #AD0300',
    },
    copyright: {
        color: theme.palette.text.primary,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'start',
        flexGrow: 1,
        borderTop: theme.borders.mainContent,
        paddingLeft: theme.sizes.small_gap,
        paddingRight: theme.sizes.small_gap,
    },
    footerImageOuter: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'start',
        paddingLeft: theme.sizes.small_gap,
        paddingRight: theme.sizes.small_gap,
    },
    footerImage: {
        height: theme.sizes.xxxlarge_gap,
        width: '67px',
    },
}));

const FooterDesktop = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { sharedClasses, content, createLink, copyright, manageCookies } = props;
    const { companyInfo, legalStatement, privacyPolicy, cookiePolicy, supplierCode, rentokil, initial, premiumScenting, rentokilInitial } = content;
    const [ getAppBrandUrl ] = useAppBranding();

    const createFooterLink = (className, link) => {
        return createLink(
                mergeStyles(classes.footerWebsites, className),
                link,
                <Typography
                    variant='body2'
                    className={classes.footerWebsiteText}
                >
                    {extractBaseURL(link)}
                </Typography>)
    };

    return (
        <div aria-label={t('aria_footer')}>
            <div className={classes.footerSpacer}/>
            <div className={mergeStyles(sharedClasses.footer, classes.footer)}>
                <div className={classes.footerTop}>
                    <div className={mergeStyles(sharedClasses.siteLinks, classes.siteLinks)}>
                        <Typography variant="body2">
                            {createLink(
                                mergeStyles(sharedClasses.link, classes.link),
                                companyInfo,
                                t('link_company_info'))
                            }
                            <span className={classes.link_separator}>&bull;</span>
                            {createLink(
                                mergeStyles(sharedClasses.link, classes.link),
                                legalStatement,
                                t('link_legal_statement'))
                            }
                            <span className={classes.link_separator}>&bull;</span>
                            {createLink(
                                mergeStyles(sharedClasses.link, classes.link),
                                privacyPolicy,
                                t('link_privacy_policy'))
                            }
                            <span className={classes.link_separator}>&bull;</span>
                            {createLink(
                                mergeStyles(sharedClasses.link, classes.link),
                                cookiePolicy,
                                t('link_cookie_policy'))
                            }
                            <span className={classes.link_separator}>&bull;</span>
                            {manageCookies(
                                mergeStyles(sharedClasses.link, classes.link),
                                t('link_manage_cookies'))
                            }
                            <span className={classes.link_separator}>&bull;</span>
                            {createLink(
                                mergeStyles(sharedClasses.link, classes.link),
                                supplierCode,
                                t('link_supplier_code'))
                            }
                        </Typography>
                    </div>
                    <SocialIcons classes={mergeStyles(sharedClasses.iconLinks, classes.iconLinks)} content={content} />
                    <div className={classes.footerImageOuter}>
                        <img className={classes.footerImage} src={getAppBrandUrl('footer_logo.jpeg', LogoImage)} alt={t('footer_logo')}/>
                    </div>
                </div>
                <div className={classes.footerBottom}>
                    <div className={classes.copyright}>
                        {copyright()}
                    </div>
                    {!isEmpty(rentokil) && createFooterLink(classes.rentokil, rentokil)}
                    {!isEmpty(initial) && createFooterLink(classes.initial, initial)}
                    {!isEmpty(premiumScenting) && createFooterLink(!isEmpty(premiumScenting) && premiumScenting.includes('ambius') ? classes.ambius : classes.premiumScenting, premiumScenting)}
                    {!isEmpty(rentokilInitial) && createFooterLink(classes.rentokilInitial, rentokilInitial)}
                </div>
            </div>
    </div>
    );
};

export default FooterDesktop;
