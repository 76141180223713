import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintReport from '../../components/VisitHistory/PrintReport';
import MIButton from '../../components/Widgets/MIButton';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import { SUCCESS } from '../../services/requests/requestReducer';
import { useAuthState } from '../../contexts/AuthProvider';
import { isEmpty, getLocalizedDate, setDocumentTitle, mergeStyles, getLocalizedTime } from '../../utils/utils';
import { observer } from 'mobx-react-lite';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import MIAlert from '../../components/Widgets/MIAlert';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import DownloadWasteNote from '../../components/VisitHistory/DownloadWasteNote';
import { ResponsiveComponent, useResponsive } from '../../services/useResponsive';
import VisitDetailsMobile from '../../components/VisitDetails/variant/VisitDetailsMobile';
import VisitDetailsDesktop from '../../components/VisitDetails/variant/VisitDetailsDesktop';
import { useExtraVisitDetails } from '../../services/visits/useExtraVisitDetails';
import { useVisitDetails } from '../../services/visits/useVisitDetails';

const desktopStyles = (theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        columnName: {
            'fontWeight': 'bold',
            '&:focus': theme.ariaFocus,
        },
        gridItem: {
            'width': 'fit-content',
            '&:focus': theme.ariaFocus,
            'textTransform': 'capitalize',
        },
        noWrap: {
            whiteSpace: 'nowrap',
        },
        noData: {
            color: theme.palette.error.dark,
            margin: 'auto'
        },
        signatureImage: {
            display: 'flex',
            resizeMode: 'contain',
            maxHeight: '180px',
            maxWidth: '270px',
        },
        report: {
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
        info: {
            marginTop: '16px',
        },
        infoGrid: {
            flexGrow: 1,
            width: '40%',
            float: (props) => {
                return props.floatMain ? props.floatMain : 'right';
            },
            marginLeft: theme.sizes.micro_gap,
        },
        reportTitle: {
            display: 'inline',
        },
        reportTitleContainer: {
            display: 'inline-block',
            width: '480px',
        },
        signatureContainer: {
            'border': theme.borders.mainContent,
            'marginTop': '22px',
            'width': '440px',
            'height': '180px',
            'resizeMode': 'contain',
            'float': (props) => {
                return props.floatOther ? props.floatOther : 'right';
            },
            'marginRight': theme.sizes.large_gap,
            'display': 'flex',
            'justifyContent': 'center',
            'marginBottom': theme.sizes.small_gap,
            'backgroundColor': 'white',
            '&:focus': theme.ariaFocus,
        },
        columnLeft: {
            width: '180px',
        },
        gridData: {
            marginTop: theme.sizes.small_gap,
        },
        signatureAndName: {
            width: '468px',
            float: (props) => {
                return props.floatOther ? props.floatOther : 'right';
            },
        },
        floatMain: {
            float: (props) => {
                return props.floatMain ? props.floatMain : 'left';
            },
        },
        floatOther: {
            float: (props) => {
                return props.floatOther ? props.floatOther : 'right';
            },
        },
        buttonContainer: {
            display: 'block',
            height: theme.sizes.xxlarge_gap,
        },
        goBack: {
            color: theme.palette.primary.main,
            display: 'inline',
            marginRight: theme.sizes.micro_gap,
            width: '160px',
        },
        icon: {
            marginBottom: '-4px',
        },
        infoCard: {
            display: 'flex',
            backgroundColor: theme.palette.grey['200'],
            paddingBottom: theme.sizes.small_gap,
        },
        hazardousWasteInfoCard: {
            display: 'flex',
            backgroundColor: theme.palette.grey['200'],
            paddingBottom: theme.sizes.xlarge_gap,
            marginTop: theme.sizes.small_gap,
        },
        header: {
            '&:focus': theme.ariaFocus,
        },
        downloadLink: {
            'color': theme.palette.primary.main,
            'cursor': 'pointer',
            'width': 'auto',
            'marginLeft': '-7px',
            '&:hover': {
                textDecoration: 'none',
            },
            '&:focus': theme.ariaFocus,
        },
        downloadText: {
            marginLeft: theme.sizes.nano_gap
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        goBack: {
            color: theme.palette.primary.main,
            display: 'inline',
            width: 'fit-content',
            border: 'none',
        },
        infoCard: {
            display: 'flex',
            flexDirection: 'column',
        },
        hazardousWasteInfoCard: {
            flexGrow: 1,
            width: '100%',
        },
        infoGrid: {
            flexGrow: 1,
            width: '90%',
            float: (props) => {
                return props.floatMain ? props.floatMain : 'right';
            },
            marginLeft: theme.sizes.micro_gap,
        },
        signatureAndName: {
            width: '87%',
            paddingTop: theme.sizes.small_gap,
            paddingLeft: theme.sizes.small_gap,
            marginBottom: theme.sizes.small_gap,
            float: (props) => {
                return props.floatOther ? props.floatOther : 'right';
            },
        },
        signatureContainer: {
            'border': theme.borders.mainContent,
            'resizeMode': 'contain',
            'float': (props) => {
                return props.floatOther ? props.floatOther : 'right';
            },
            'display': 'flex',
            'justifyContent': 'center',
            'backgroundColor': 'white',
            '&:focus': theme.ariaFocus,
            'minHeight': '45px',
            'width': '100%',
            'paddingLeft': '2px',
            'marginBottom': theme.sizes.large_gap,
        },
        signatureImage: {
            display: 'flex',
            resizeMode: 'contain',
            maxHeight: '180px',
            maxWidth: '270px',
        },
        root: {
            'width': '100%',
            'maxWidth': '928px',
            'minHeight': '600px',
            'marginLeft': 'auto',
            'marginRight': 'auto',
            'paddingTop': theme.sizes.large_gap,
            'paddingBottom': theme.sizes.large_gap,
            'fontFamily': theme.typography.fontFamily,
            '& .MuiGrid-container': {
                flexDirection: 'column',
            },
        },
        columnName: {
            'fontWeight': 'bold',
            '&:focus': theme.ariaFocus,
        },
        gridItem: {
            '&:focus': theme.ariaFocus,
            'paddingRight': theme.sizes.small_gap,
        },
        buttonContainer: {
            display: 'block',
            height: theme.sizes.xxlarge_gap,
            marginTop: `-${ theme.sizes.small_gap}`,
        },
        columnLeft: {
            width: '90%',
        },
        wasteTransferredTo: {
            marginTop: `-${ theme.sizes.small_gap}`,
        },
        wasteConsignmentNoteCode: {
            marginTop: theme.sizes.no_gap,
            height: theme.fontSizes.mobileLabel,
        },
        floatOther: {
            ...baseStyle.floatOther,
            marginLeft: theme.sizes.micro_gap,
            marginRight: theme.sizes.micro_gap,
        }
    });
};

const VisitDetails = observer((props) => {
    const { t, i18n } = useTranslation();
    const floatMain = i18n.dir() === 'ltr' ? 'left' : 'right';
    const floatOther = i18n.dir() === 'ltr' ? 'right' : 'left';
    const history = useHistory();
    const siteStore = useSiteStore();
    const { premiseVisitNumber, visitDate, locDate, rawDate } = props.location && props.location.state ? props.location.state.data : {};
    const { countryCode, locale } = useAuthState();
    const [ visit, responseStatus, getVisitDetails ] = useVisitDetails();
    // these field are not available in GCP at the moment so we still need to use kony for them
    const [ extraVisitDetails, responseStatusExtra, getExtraVisitDetails ] = useExtraVisitDetails();


    const [ isShowing, setIsShowing ] = useState(false);
    const [ alert, setAlert ] = useState({});
    const { feature_service_receipt, feature_hazardous_waste_consignment_notes, feature_contact_name } = useFlags();
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );
    const classes = useStyles({ floatMain: floatMain, floatOther: floatOther });

    useEffect(() => {
        setDocumentTitle(t, 'nav_visit_details');
    }, [ t ]);

    useEffect(() => {
        if(window && window.scrollTo) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        if (!isEmpty(premiseVisitNumber) && !isEmpty(countryCode) && !isEmpty(locale) && !isEmpty(siteStore.selectedSiteId) && !isEmpty(visitDate)) {
            getExtraVisitDetails(siteStore.selectedSiteId,
                premiseVisitNumber,
                visitDate,
                countryCode,
                locale
            );
        }
    }, [ getExtraVisitDetails, premiseVisitNumber, countryCode, locale, visitDate, siteStore.selectedSiteId ]);

    useEffect(() => {
        if (!isEmpty(premiseVisitNumber) && !isEmpty(countryCode) && !isEmpty(siteStore.selectedPremiseNumber) &&
            !isEmpty(siteStore.selectedContractNumber) && !isEmpty(premiseVisitNumber) && !isEmpty(locale) &&
            !isEmpty(siteStore.selectedSiteId) && !isEmpty(visitDate)) {
            getVisitDetails(
                countryCode,
                siteStore.selectedContractNumber,
                siteStore.selectedPremiseNumber,
                premiseVisitNumber,
                siteStore.selectedSiteId,
                visitDate,
                locale
            );
        }
    }, [ getVisitDetails, premiseVisitNumber, countryCode, siteStore.selectedPremiseNumber, siteStore.selectedContractNumber, locale, visitDate, siteStore.selectedSiteId ]);

    const showRow = (columnName, value, rowClass) => {
        return (
            <Grid container spacing={2} className={classes.info}>
                <Grid item className={rowClass ? classes.rowClass : classes.columnLeft}>
                    <span className={classes.columnName} tabIndex={0}>{columnName}:</span>
                </Grid>
                <Grid item xs>
                    <span className={typeof value === 'string' ? classes.gridItem : ''} tabIndex={0}>{!isEmpty(value) ? value : '-'}</span>
                </Grid>
            </Grid>
        );
    };

    const getLocalTime = (siteOverview) => {
        let locTime = '-';
        try {
            locTime = !isEmpty(siteOverview.VisitDate) && !isEmpty(siteOverview.ServiceTime) ? getLocalizedTime(t, siteOverview.VisitDate, siteOverview.ServiceTime) : '-';
        } catch (error) {
            console.error(error);
        }
        return locTime;
    };

    const getLocaleDate = (siteOverview) => {
        let localDate = '-';
        try {
            localDate = !isEmpty(siteOverview.VisitDate) ? getLocalizedDate(t, siteOverview.VisitDate) : '-';
        } catch (error) {
            console.error(error);
        }
        return localDate;
    };
    const showInfoGrid = () => {
        if (!isEmpty(visit)) {
            const siteOverview = visit.visitDetails;
            return (
                <div className={classes.infoGrid}>
                    {showRow(t('site_name'), siteOverview.PremiseName)}
                    {showRow(t('tech_name'), `${siteOverview.TechnicianForename} ${siteOverview.TechnicianSurname}`)}
                    {showRow(t('visit_date'), getLocaleDate(siteOverview))}
                    {showRow(t('visit_type'), siteOverview.VisitType)}
                    {showRow(t('visit_time'), getLocalTime(siteOverview))}
                    {feature_contact_name && showRow(t('contact_name'), !isEmpty(siteOverview.ContactName) ? siteOverview.ContactName.toLowerCase() : '-')}
                </div>
            );
        }
    };

    const wasteConsignmentDownloadLink = () => {
        if (!isEmpty(visit) && !isEmpty(visit.visitDetails)) {
            const wasteNumber = visit.visitDetails.WasteConsignmentNoteNumber;
            if (!isEmpty(wasteNumber)) {
                return (
                    <div className={classes.wasteConsignmentNoteCode}>
                        <Grid style={{ flexDirection: 'row' }} container direction="row" alignItems="center">
                            <DownloadWasteNote
                                consignmentNoteCode={wasteNumber}
                                visitDate={visitDate}
                                setAlert={setAlert}
                                setIsShowing={setIsShowing}
                            />
                            <span className={mergeStyles(classes.downloadText, classes.gridItem)} tabIndex={0}>{wasteNumber}</span>
                        </Grid>
                    </div>
                );
            }
        }
        return '-';
    };

    const getHazWasteAddress = (visitDetails) => {
        // join only not null fields
        let address = !isEmpty(visitDetails) ? [ visitDetails.DepotName,
            visitDetails.DepotAddressLine1,
            visitDetails.DepotAddressLine2,
            visitDetails.DepotAddressLine3,
            visitDetails.DepotAddressLine4,
            visitDetails.DepotAddressLine5 ].filter(Boolean).join(', ') : '';

        // the kony api sometimes returns an address with a comma at the end.
        if (address.charAt(address.length - 1) === ',') {
            address = address.substring(0, address.length - 1);
        }
        return address;
    };

    const reformatTransferredTo = (visitDetails) => {
        return (
            <div className={classes.gridItem} tabIndex={0}>
                <span>{getHazWasteAddress(visitDetails)}</span>
                {!isEmpty(visitDetails.DepotPostCode) &&
                <span className={classes.noWrap}>{visitDetails.DepotPostCode}</span>}
            </div>
        );
    };

    const showHazardousWasteInfoGrid = () => {
        if (!isEmpty(visit) && !isEmpty(visit.visitDetails)) {
            return (
                <div className={classes.infoGrid} tabIndex={0} aria-label={t('aria_label_hazardous_waster_section')}>
                    {showRow(t('consignment_note_code'), wasteConsignmentDownloadLink())}
                    {showRow(t('waste_transferred_to'), reformatTransferredTo(visit.visitDetails))}
                </div>
            );
        }
    };

    const gridRows = () => {
        let rows = [];
        // get the products from extraVisitDetails
        const productDetails = !isEmpty(extraVisitDetails) ? extraVisitDetails.visitProducts : [];
        productDetails.forEach(function (product, index) {
            if (feature_hazardous_waste_consignment_notes) {
                rows.push({
                    id: index,
                    productServiced: product.ProductName,
                    ewcCode: product.EWCCode,
                    hazardousWasteType: product.WasteHazardType,
                    quantityServiced: product.QuantityServiced
                });
            } else {
                rows.push({
                    id: index,
                    productServiced: product.ProductName,
                    quantityServiced: product.QuantityServiced
                });
            }
        });
        return rows;
    };

    const showButtons = () => {
        return (
            <div className={classes.buttonContainer}>
                <div className={classes.floatMain}>
                    <MIButton
                        className={classes.goBack}
                        variant={'outlined'}
                        startIcon={<ArrowBackIcon className={classes.icon}/>}
                        onClick={history.goBack}>
                        {t('go_back')}
                    </MIButton>
                </div>
                {feature_service_receipt && !isEmpty(visit) && !isEmpty(visit.visitDetails) &&
                <div className={classes.floatOther}>
                    <PrintReport
                        premiseVisitNumber={premiseVisitNumber}
                        locDate={locDate}
                        customStyle={{ spinnerWidth: '24px', spinnerHeight: '24px', marginTop: '12px' }}
                        visitDate={rawDate}
                        setAlert={setAlert}
                        setIsShowing={setIsShowing}
                        title={t('download')}
                    />
                </div>
                }
            </div>
        );
    };

    const showSignature = () => {
        return (
            <div className={classes.signatureAndName}>
                <div className={classes.signatureContainer} tabIndex={0}>
                    {responseStatus === SUCCESS && !isEmpty(extraVisitDetails?.Sign) ?
                        <img className={classes.signatureImage} alt={t('aria_label_signature')} src={`data:image/png;base64,${extraVisitDetails.Sign}`}/> :
                        <h4 className={classes.noData}> {t('no_signature_available')}</h4>
                    }
                </div>
            </div>
        );
    };

    const showHazardousWaste = !isEmpty(visit) &&
        !isEmpty(visit.visitDetails) &&
        (!isEmpty(visit.visitDetails.WasteConsignmentNoteNumber) ||
            !isEmpty(getHazWasteAddress(visit.visitDetails)));

    const params = {
        buttons: showButtons(),
        signature: showSignature(),
        infoGrid: showInfoGrid(),
        hazWasteInfoGrid: showHazardousWasteInfoGrid(),
        gridRows: gridRows(),
        visit: visit,
        showHazardousWaste: showHazardousWaste,
        responseStatus: responseStatusExtra,
    };

    return (
        <Paper className={classes.root} aria-label={t('aria_label_visit_details_page')}>
            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                secondaryMessage={t(alert.secondaryMessage)}
                severity={alert.severity}
                ariaLabel={t('aria_label_operation_response_dialog')}/>
            <MIApiLoader
                MIComponent={
                    <ResponsiveComponent
                        components={{
                            mobile: <VisitDetailsMobile {...params} {...props}/>,
                            desktop: <VisitDetailsDesktop {...params} {...props}/>,
                        }}
                    />
                }
                responseStatus={responseStatus}
                ariaLabelProgressBar={t('aria_label_progressbar_visit_details')}
            />
        </Paper>
    );
});

export default VisitDetails;
