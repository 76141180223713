import React, { memo } from 'react';
import { groupByLocationId, isObjectEmpty } from '../../utils/utils';
import Location from '../Location';
import { CLUSTER_ICON_SIZE } from '../Cluster';
import RecsLocationIcon from '../components/RecsLocationIcon';
import { getClusterId, isVisible, scalePosition } from '../../utils/clusters';

// add small offset to move the icon on top of the detector icon
const RECS_LOCATION_OFFSET = { x: -5, y: -43 };
const RECS_LOCATION_CLUSTER_OFFSET = { x: 5, y: -35 };

const RecsLayerImpl = (props) => {
    const { points, clusters, scale, width, height, translation, handleClick, recommendations, recsCountFilter } = props;

    const getRecsClusters = () => {
        if (isObjectEmpty(clusters) || isObjectEmpty(recommendations)) {
            return [];
        }

        const recsMap = groupByLocationId(recommendations);
        let recsClusters = [];

        clusters.singles.forEach((index) => {
            //if is visible and there are recs show the icon
            const point = points[index];
            const recs = recsMap[point?.properties?.location?.id]
            if (!isObjectEmpty(point) && isVisible(point.coordinates, scale, translation, width, height, RECS_LOCATION_OFFSET)
                && !isObjectEmpty(recs)
            ) {
                recsClusters.push(
                    <Location
                        key={point.id}
                        properties={index}
                        label={false}
                        component={<RecsLocationIcon recommendations={recs} recsCountFilter={recsCountFilter}/>}
                        coordinates={scalePosition(point.coordinates, point.centre, scale, translation, RECS_LOCATION_OFFSET)}
                        centre={point.centre}
                        handleDetectorClick={handleClick}
                        isSelected={false}
                    />
                );
            }
        });
        clusters.clusters.forEach((cluster, clusterIndex) => {
            const midPoint = clusters.midpoints[clusterIndex];
            if (isVisible(midPoint, scale, translation, width, height, RECS_LOCATION_CLUSTER_OFFSET)) {
                // total recommendations for this cluster
                let clusterRecs = cluster.reduce((currentList, index) => {
                    const point = points[index];
                    const recs = recsMap[point?.properties?.location?.id]
                    return isObjectEmpty(recs) ? currentList : currentList.concat(recs);
                }, [])

                if (!isObjectEmpty(clusterRecs)) {
                    recsClusters.push(
                        <Location
                            key={getClusterId('cluster_recs', midPoint)}
                            properties={null}
                            component={<RecsLocationIcon recommendations={clusterRecs} recsCountFilter={recsCountFilter}/>}
                            centre={{ x: CLUSTER_ICON_SIZE / 2, y: CLUSTER_ICON_SIZE / 2 }}
                            coordinates={scalePosition(midPoint, {
                                x: CLUSTER_ICON_SIZE / 2,
                                y: CLUSTER_ICON_SIZE / 2
                            }, scale, translation, RECS_LOCATION_CLUSTER_OFFSET)}
                            isSelected={false}
                        />
                    );
                }
            }
        });

        return recsClusters;
    };

    return (
        <div id={'recs-layer'}>
            {getRecsClusters()}
        </div>
    );
};

export const RecsLayer = memo(RecsLayerImpl);
