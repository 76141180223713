import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import { useGetFloorPlanImage } from '../../../services/floorplan/useGetFloorPlanImage';
import MIApiLoader from '../../../components/Widgets/MIApiLoader';
import { useGetBuildings } from '../../../services/floorplan/useGetBuildings';
import { useGetFloors } from '../../../services/floorplan/useGetFloors';
import { useDetectors } from '../../../services/floorplan/useDetectors';
import { isEmpty } from '../../../utils/utils';
import { HEADER_HEIGHT } from './ActionBar';
import FloorPlanTabs from './FloorPlanTabs';
import MapTab from './MapTab';
import DevicesTab from './DevicesTab';
import { useGetFloorPlanDevices } from '../../../services/floorplan/useGetFloorPlanDevices';
import { useGetDevicesMapping } from '../../../services/floorplan/useGetDevicesMapping';
import { FETCHING, SUCCESS } from '../../../services/requests/requestReducer';
import { observer } from 'mobx-react-lite';
import { useWindowSize } from '../../../services/useWindowSize';
import { useDetectorStore, useFilterStore, useSiteStore } from '../../../contexts/StateProvider/StateProvider';
import MIAlert from '../../../components/Widgets/MIAlert';
import FilterPopup from './FilterPopup';
import { ResponsiveComponent, useResponsive } from '../../../services/useResponsive';
import { setDocumentTitle } from '../../../utils/utils';
import ActionBar from './ActionBar';
import SiteSelector from '../../../components/SiteSelector/SiteSelector';
import { useAuthState } from '../../../contexts/AuthProvider';
import { useGetMyRentokilSiteId } from '../../../services/recommendations/useGetMyRentokilSiteId';
import { Typography } from '@material-ui/core';
import useModal from '../../../services/useModal';
import { LEGEND_WIDTH_OUTER } from '../../../rentokil-react-ui';
import { useLocationIcon } from '../../../services/floorplan/useLocationIcon';
import { MAP_SIZE, MYRENTOKILSITEID } from './FloorPlanEnums';
import useModalSessionStorage from '../../../services/useModalSessionStorage';

const desktopStyles = (theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: '960px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        floorPlanSiteContainer: {
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.xlarge_gap,
            borderBottom: theme.borders.outline_regular,
        },
        noData: {
            color: theme.palette.error.dark,
            textAlign: 'center',
            marginTop: '20%',
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: '100vh',
            paddingTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.no_gap,
            paddingBottom: theme.sizes.no_gap,
        },
    });
};

const SHOW_LEGEND_KEY = 'show_legend';

const Floorplan = observer(() => {
    const { t } = useTranslation();
    const { getStyles } = useResponsive();
    const { countryCode } = useAuthState();


    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );
    const classes = useStyles();

    // we need to covert the siteId in myInitial to the one in myRentokil
    const [ myRentokilSiteId, responseStatusGetMyRentokilSiteId, getMyRentokilSiteId ] = useGetMyRentokilSiteId();

    const [ floorPlanImage, responseStatusFloorPlanImage, getFloorPlanImage, checkUrlExpiry ] = useGetFloorPlanImage();
    const [ floors, floorsResponseStatus, getFloors ] = useGetFloors();
    const [ buildings, buildingsResponseStatus, getBuildings ] = useGetBuildings();
    const [ , getDetectors ] = useDetectors();
    const [ floorPlanDetectorsStatus, getFloorPlanDetectors ] = useGetFloorPlanDevices();
    const [ , responseStatusDeviceMapping, getDevicesMapping ] = useGetDevicesMapping();
    const [ selectedFloor, setSelectedFloor ] = useState({});
    const { getLegendIconGroups } = useLocationIcon();
    const detectorStore = useDetectorStore();
    const filterStore = useFilterStore();
    const siteStore = useSiteStore();
    const containerRef = useRef(null);
    const [ windowSize ] = useWindowSize();
    let mapSize = {
        width: MAP_SIZE.MIN_WIDTH,
        height: MAP_SIZE.MIN_HEIGHT,
    };
    const [alert,] = useState({});
    const [isShowing, setIsShowing] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const { isShowing: showLegend, changeStatus: toggleShowLegend } = useModalSessionStorage(false, SHOW_LEGEND_KEY);
    const { isShowing: endOfLegendTransition, changeStatus: toggleEndOfLegendTransition } = useModal();

    useEffect(() => {
        if(!isEmpty(countryCode) && !isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber)) {
            getMyRentokilSiteId(countryCode, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber);
        }
    }, [ getMyRentokilSiteId, countryCode, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber ]);

    const containerPadding = 32;
    if (!isEmpty(containerRef) && !isEmpty(containerRef.current)) {
        const rect = containerRef.current.getBoundingClientRect();
        mapSize = {
            width: MAP_SIZE.MIN_WIDTH - (showLegend || endOfLegendTransition ? LEGEND_WIDTH_OUTER : 0),
            height: windowSize.height - (rect.top + HEADER_HEIGHT + containerPadding),
        };
        mapSize.height = mapSize.height < MAP_SIZE.MIN_HEIGHT ? MAP_SIZE.MIN_HEIGHT : mapSize.height;
    }

    useEffect(() => {
        setDocumentTitle(t, 'nav_floorplan');
    }, [ t ]);

    const onTabChange = () => {
        if (!isEmpty(selectedFloor)) {
            checkUrlExpiry(selectedFloor.value);
        }
    };

    useEffect(() => {
        // wait until the devicesMapping api is completed before starting to get the buildings
        if (!isEmpty(myRentokilSiteId) && responseStatusDeviceMapping === SUCCESS && responseStatusGetMyRentokilSiteId === SUCCESS) {
            getBuildings(myRentokilSiteId);
        }
    }, [ getBuildings, myRentokilSiteId, responseStatusDeviceMapping, responseStatusGetMyRentokilSiteId ]);

    useEffect(() => {
        if (!isEmpty(myRentokilSiteId)) {
            getDevicesMapping();
        }
    }, [ getDevicesMapping, myRentokilSiteId ]);

    const onFloorSelected = useCallback((floor) => {
        if (!isEmpty(floor)) {
            setSelectedFloor(floor);
            getFloorPlanImage(floor.value);
            getDetectors(floor.floorId);
            getFloorPlanDetectors(floor.floorId);
        }
    }, [ getFloorPlanImage, getDetectors, getFloorPlanDetectors ]);

    const getFloorDetectors = useCallback(() => {
        const detectors = detectorStore.getDetectors(selectedFloor.floorId);
        return filterStore.filterList(detectors);
    }, [ detectorStore, selectedFloor.floorId, filterStore ]);

    const [ detectors, detectorTotal ] = getFloorDetectors();

    const getLoadStatus = useCallback(() => {
        return detectorStore.detectorsLoaded(selectedFloor.floorId) &&
        responseStatusFloorPlanImage === SUCCESS ?
            SUCCESS : FETCHING;
    }, [ detectorStore, responseStatusFloorPlanImage, selectedFloor.floorId ]);

    const handleFilters = (event) => {
        setShowFilters(true);
    };

    const onFilterClose = () => {
        setShowFilters(false);
    };

    const getApiStatus = useCallback(() => {
        if (responseStatusFloorPlanImage === FETCHING ||
            responseStatusDeviceMapping === FETCHING ||
            buildingsResponseStatus === FETCHING ||
            floorsResponseStatus === FETCHING) {
            return FETCHING;
        }
        return responseStatusFloorPlanImage || responseStatusDeviceMapping || buildingsResponseStatus || floorsResponseStatus;
    }, [ responseStatusFloorPlanImage, responseStatusDeviceMapping, buildingsResponseStatus, floorsResponseStatus]);

    const loadStatus = getLoadStatus();
    const disabled = isEmpty(loadStatus) ? false : loadStatus !== SUCCESS;

    const getFloorPlanPage = () => {
        if(siteStore.getSelectedmyRentokilSiteId() === MYRENTOKILSITEID.NO_ID) {
            return(
                <div className={classes.floorPlanContainer} ref={containerRef}>
                    <Typography className={classes.noData}> {t('no_floor_plan_available')}</Typography>
                </div>);
        } else if (!isEmpty(siteStore.getSelectedmyRentokilSiteId())) {
            return(
                <div className={classes.floorPlanContainer} ref={containerRef}>
                    <FloorPlanTabs
                        header={<ActionBar
                            buildings={buildings}
                            floors={floors}
                            getFloors={getFloors}
                            onFloorSelected={onFloorSelected}
                            filter={filterStore}
                            disabled={disabled}
                            handleFilters={handleFilters}
                            toggleShowLegend={toggleShowLegend}
                        />}
                        mapTab={<MapTab
                            size={mapSize}
                            detectors={detectors}
                            floorPlanImage={floorPlanImage}
                            responseStatusFloorPlanImage={getApiStatus()}
                            heatMapEnabled={false}
                            showLabels={false}
                            showSlider={false}
                        />}
                        devicesTab={<DevicesTab
                            detectors={detectors}
                            status={floorPlanDetectorsStatus}
                        />}
                        showLegend={showLegend}
                        mapSize={mapSize}
                        getLegendIconGroups={getLegendIconGroups}
                        selectedFloor={selectedFloor}
                        toggleEndOfLegendTransition={toggleEndOfLegendTransition}
                        onTabChange={onTabChange}
                    />
                    <FilterPopup
                        isShowing={showFilters}
                        onClose={onFilterClose}
                        filter={filterStore}
                        deviceCount={!isEmpty(detectors) ? detectors.length : 0}
                        deviceTotal={detectorTotal}
                    />
                </div>);
        }
        return <div> </div>;
    };

    const getFloorPlanSiteStatus = useCallback(() => {
        if(!responseStatusGetMyRentokilSiteId ||
            responseStatusGetMyRentokilSiteId === FETCHING) {
            return FETCHING;
        }
        return responseStatusGetMyRentokilSiteId;
    }, [ responseStatusGetMyRentokilSiteId ]);

    const apiLoader =
        <div className={classes.pageOuter} aria-label={t('aria_label_floor_plan_page')}>
            <div className={classes.floorPlanSiteContainer}>
                <SiteSelector/>
            </div>
            <MIApiLoader
                MIComponent={getFloorPlanPage()}
                responseStatus={getFloorPlanSiteStatus()}
                customStyle={{ marginTop: '5%' }}
            />
            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                severity={alert.severity}
                ariaLabel={t('aria_label_operation_response_dialog')}
            />
        </div>;

    return (
        <Paper className={classes.root} aria-label={t('aria_label_floorplan_page')}>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: apiLoader
                }}/>
        </Paper>
    );
});

export default Floorplan;
