import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import TodayIcon from '@material-ui/icons/Today';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useResponsive } from '../../services/useResponsive';
import {isEmpty} from '../../utils/utils';

const desktopStyles = (theme) => {
    return {
        calendar: {
            'marginTop': theme.sizes.nano_gap,
            'display': 'flex-inline',
            'width': (props) => {
                return !isEmpty(props.calendarWidth) ? props.calendarWidth : '221px';
            },
            'height': theme.sizes.xxxlarge_gap,
            'paddingRight':theme.sizes.nano_gap,
            '& fieldset': {
                borderRadius: theme.sizes.no_gap,
                border: theme.borders.listBorders,
            },
            '& .MuiOutlinedInput-adornedEnd': {
                paddingRight: theme.sizes.no_gap,
            },
            '& .MuiFormLabel-root.Mui-error': {
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.error.dark,
            },
            '& .MuiFormLabel-root': {
                fontWeight: theme.typography.fontWeightBold,
                color: theme.colors.mainText,
            },
            '& .MuiOutlinedInput-input': {
                padding: (props) => {
                    return !isEmpty(props.padding) ? props.padding : null;
                }
            }
        },
        inputProps: {
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    boxShadow: 'rgba(0,125,197,0.25) 0px 1px 9px 1px',
                }
            },
            '&.Mui-error': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.dark,
                }
            },
        },
        highlight: {
            backgroundColor: theme.palette.primary.main,
        },
        middleHighlight: {
            'backgroundColor': theme.palette.primary.main,
            'borderRadius': theme.sizes.no_gap,
            '& .MuiTypography-colorInherit': {
                color: theme.palette.background.white
            }
        },
        firstHighlight: {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        },
        endHighlight: {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        },
        currentDayInside: {
            border: [ '2px', 'solid', theme.palette.background.white ].join(' '),
            backgroundColor: theme.palette.primary.main,
        },
        currentDayOutside: {
            border: [ '2px', 'solid', theme.palette.primary.main ].join(' '),
            backgroundColor: theme.palette.background.white,
        },
        currentDayInner: {
            borderRadius: '50%',
            height: theme.sizes.large_gap,
            width: theme.sizes.large_gap,
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: theme.sizes.pico_gap
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        mobileDateFrom: {
            marginBottom: theme.sizes.small_gap
        },
    });
};

const desktopHelperTextStyles = (theme) => {
    return {
        error: {
            '&.MuiFormHelperText-root.Mui-error': {
                fontSize: theme.typography.small_error.fontSize,
                marginTop: theme.sizes.pico_gap,
                marginLeft: theme.sizes.no_gap,
                marginRight: theme.sizes.no_gap,
                color: theme.palette.error.dark,
                width: 'max-content',
            }
        }
    };
};

const mobileHelperTextStyles = (theme) => {
    const baseStyle = desktopHelperTextStyles(theme);

    return Object.assign(baseStyle, {
        error: {
            '&.MuiFormHelperText-root.Mui-error': {
                ...baseStyle.error['&.MuiFormHelperText-root.Mui-error'],
                fontSize: theme.fontSizes.mobileLabel,
            }
        }
    });
};

const MIDateRangePicker = ({ control, watch, disabled, dateFromProps, dateToProps, dateFromPickerProps, dateToPickerProps, minDateValue, maxDateValue, dateFormat, ...props }) => {
    const { t } = useTranslation();
    let fromDate = isEmpty(watch('fromDate')) ? dateFromProps.defaultValue : watch('fromDate');
    let toDate = isEmpty(watch('toDate')) ? dateToProps.defaultValue : watch('toDate');
    const minDate = !isEmpty(minDateValue) ? minDateValue : moment('01-01-2000', 'DD-MM-YYYY');
    const maxDate = !isEmpty(maxDateValue) ? maxDateValue : moment('01-01-2030', 'DD-MM-YYYY');
    const { getStyles } = useResponsive();
    const currentLocale = moment().locale();


    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );

    const useHelperTextStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopHelperTextStyles(theme),
            mobile: mobileHelperTextStyles(theme)
        });
    }
    );

    const classes = useStyles(props);
    const helperTextClasses = useHelperTextStyles();

    const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
        let wrapperClassName = null;
        let wrapperInnerClassName = null;
        if (!isEmpty(fromDate) && !isEmpty(toDate)) {
            const dayIsBetween = !fromDate.startOf('day').isSame(toDate.startOf('day')) && moment(day).startOf('day').isBetween(fromDate, toDate, 'day', '[]');
            const dayIsSame = moment(day).startOf('day').isSame(fromDate.startOf('day')) && fromDate.startOf('day').isSame(toDate.startOf('day'));
            const isFirstDay = moment(day).startOf('day').isSame(fromDate.startOf('day')) || moment(day).isoWeekday() === moment().startOf('week').isoWeekday() || moment(day).date() === moment(day).startOf('month').date();
            const isLastDay = moment(day).startOf('day').isSame(toDate.startOf('day')) || moment(day).isoWeekday() === moment().endOf('week').isoWeekday() || moment(day).date() === moment(day).endOf('month').date();
            const todayDate = moment(day).startOf('day').isSame(moment().startOf('day'));

            wrapperClassName = clsx({
                [classes.highlight]: dayIsSame,
                [classes.middleHighlight]: dayInCurrentMonth && dayIsBetween,
                [classes.firstHighlight]: dayInCurrentMonth && isFirstDay,
                [classes.endHighlight]: dayInCurrentMonth && isLastDay,
            });

            wrapperInnerClassName = clsx({
                [classes.currentDayInner]: todayDate,
                [classes.currentDayInside]: todayDate && dayIsBetween,
                [classes.currentDayOutside]: todayDate && !dayIsBetween,
            });
        }

        return (
            <div className={wrapperClassName}>
                <div className={wrapperInnerClassName}>
                    {dayComponent}
                </div>
            </div>
        );
    };

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={currentLocale}>
            <Controller
                render={({ ref, ...props }) => {
                    return <KeyboardDatePicker
                        className={[ classes.calendar, classes.mobileDateFrom ].join(' ')}
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{ shrink: true }}
                        FormHelperTextProps={{ classes: helperTextClasses, role: 'alert' }}
                        autoOk
                        disabled={disabled}
                        variant="inline"
                        inputVariant="outlined"
                        label={t('dates_from')}
                        aria-label={t('aria_dates_from')}
                        invalidDateMessage={t('invalid_date_format')}
                        minDate={minDate}
                        minDateMessage={t('invalid_date')}
                        maxDateMessage={
                            toDate && toDate.isValid() &&
                            toDate.startOf('day').isSameOrBefore(maxDate.startOf('day')) ? t('dates_overlap') : t('invalid_date')
                        }
                        maxDate={
                            toDate && toDate.isValid() &&
                            toDate.startOf('day').isSameOrBefore(maxDate.startOf('day')) ? toDate : maxDate}
                        orientation={getStyles({
                            desktop: 'landscape',
                            mobile: 'portrait'
                        })}
                        format={dateFormat}
                        keyboardIcon={<TodayIcon />}
                        renderDay={renderDay}
                        placeholder={dateFormat}
                        KeyboardButtonProps={{
                            'aria-label': t('aria_dates_from'),
                        }}
                        leftArrowButtonProps={{ 'aria-label': t('aria_label_left_arrow_calendar_from') }}
                        rightArrowButtonProps={{ 'aria-label': t('aria_label_right_arrow_calendar_from') }}
                        {...props}
                        {...dateFromPickerProps}
                    />;
                }
                }
                name="fromDate"
                control={control}
                {...dateFromProps}
            />
            <Controller
                render={({ ref, ...props }) => {
                    return <KeyboardDatePicker
                        className={classes.calendar}
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{ shrink: true }}
                        FormHelperTextProps={{ classes: helperTextClasses, role: 'alert' }}
                        autoOk
                        disabled={disabled}
                        variant="inline"
                        inputVariant="outlined"
                        label={t('dates_to')}
                        aria-label={t('aria_dates_to')}
                        invalidDateMessage={t('invalid_date_format')}
                        maxDate={maxDate}
                        maxDateMessage={t('invalid_date')}
                        minDateMessage={
                            fromDate && fromDate.isValid() &&
                            fromDate.startOf('day').isSameOrAfter(minDate.startOf('day')) ? t('dates_overlap') : t('invalid_date')
                        }
                        minDate={
                            fromDate && fromDate.isValid() &&
                            fromDate.startOf('day').isSameOrAfter(minDate.startOf('day')) ? fromDate : minDate
                        }
                        orientation={getStyles({
                            desktop: 'landscape',
                            mobile: 'portrait'
                        })}
                        format={dateFormat}
                        keyboardIcon={<InsertInvitationIcon />}
                        renderDay={renderDay}
                        placeholder={dateFormat}
                        KeyboardButtonProps={{
                            'aria-label': t('aria_dates_to'),
                        }}
                        leftArrowButtonProps={{ 'aria-label': t('aria_label_left_arrow_calendar_to') }}
                        rightArrowButtonProps={{ 'aria-label': t('aria_label_right_arrow_calendar_to') }}
                        {...props}
                        {...dateToPickerProps}
                    />;
                }
                }
                name="toDate"
                control={control}
                {...dateToProps}
            />
        </MuiPickersUtilsProvider>
    );
};

export default MIDateRangePicker;
