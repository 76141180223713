import { useState, useEffect, useCallback } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';

export const useEditReport = () => {
    const [ report, setReport ] = useState({});
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/reportService/editReport';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            setReport(response.data);
        }
    }, [ responseStatus, response, setReport ]);

    const getReport = useCallback((reportId, locale, countryCode) => {
        // reset the status before we make the API call so that the caller can detect a change if the response is the same
        setReport();
        const url = [ endpoint, reportId, countryCode, locale ].join('/');
        makeRequestWithParams(url).catch((error) => {
            console.error('useEditReport error: ', error);
        });
    }, [ makeRequestWithParams ]);

    return [ report, responseStatus, getReport ];
};
