import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MISpinner from '../../../components/Widgets/MISpinner';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useSmartHygienePDF } from '../../../services/smartHygiene/useSmartHygienePDF';
import MIButton from '../../../components/Widgets/MIButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useTheme } from '@material-ui/styles';
import { getISOOffsetDate } from '../../../utils/utils';

const useStyles = makeStyles((theme) => {
    return {
        listItem: {
            width: '140px',
            paddingLeft: theme.sizes.small_gap,
        },
        exportButtonText: {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.h1.fontFamily,
        },
        icon: {
            marginBottom: [ '-', theme.sizes.nano_gap ].join(''),
        },
    };
});

const ExportPDF = observer((props) => {
    const { t } = useTranslation();
    const { type, setAlert, setIsShowing, onComplete, siteName, disabled, benefitsData,
        benefitsPeriod, refillValues, interactionTypes, interactionsPeriod, patternData } = props;
    const classes = useStyles();
    const [ isLoading, setIsLoading ] = useState(false);
    const theme = useTheme();
    const { exportSmartHygiene } = useSmartHygienePDF();

    const handleOnClick = () => {
        setIsLoading(true);
        exportSmartHygiene({
            generatedAt: getISOOffsetDate(new Date()),
            siteName: siteName,
            benefitsData: benefitsData,
            benefitsPeriod: benefitsPeriod,
            refillValues: refillValues,
            interactionTypes: interactionTypes,
            interactionsPeriod: interactionsPeriod,
            patternData: patternData,
        }).catch(() => {
            setAlert({ message: t('failed_to_export_pdf'), severity: 'error' });
            setIsShowing(true);
        }).finally(() => {
            setIsLoading(false);
            onComplete();
        });
    };

    return (
        <MIButton
            className={classes.pdfLink}
            endIcon={isLoading ?
                <MISpinner customStyle={{
                    spinnerWidth: theme.sizes.small_gap,
                    spinnerHeight: theme.sizes.small_gap,
                    color: theme.palette.background.paper
                }}/> :
                <GetAppIcon className={classes.icon}/>
            }
            mi_type={'primary'}
            disabled={disabled}
            onClick={handleOnClick}
        >
            {t(type)}
        </MIButton>
    );
});

export default ExportPDF;
