import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import { useTranslation } from 'react-i18next';
import MIModal from '../../../components/Widgets/MIModal';
import { isEmpty } from '../../../utils/utils';
import { useGetDetectorImage } from '../../../services/floorplan/useGetDetectorImage';
import { useLocationIcon } from '../../../services/floorplan/useLocationIcon';
import { useGetDeviceHistory } from '../../../services/floorplan/useGetDeviceHistory';
import DeviceHistoryTable from './DeviceHistoryTable';
import { useGetLastLocationScan } from '../../../services/floorplan/useGetLastLocationScan';

const useStyles = makeStyles((theme) => {
    return {
        titleContainer: {
            width: '530px',
            display: 'inline-flex',
        },
        title: {
            fontFamily: theme.typography.h1.fontFamily,
            fontWeight: 'bold',
            fontSize: theme.sizes.large_gap
        },
        bodyContainer: {
            width: '100%',
        },
        infoContainer: {
            display: 'inline-grid',
        },
        infoOuter: {
            display: 'inline-grid',
            width: '50%',
            height: '60%',
        },
        infoBlock: {
            display: 'inline-flex',
            paddingBottom: '10px',
            fontSize: '14px'
        },
        infoBox: {
            height: '270px',
        },
        imgContainer: {
            width: '302px',
            height: '242px',
            border: `2px solid${theme.palette.grey['300']}`,
            float: (props) => {
                return props.float ? props.float : 'right';
            },
        },
        detectorImage: {
            width: '302px',
            height: '242px',
        },
        label: {
            paddingBottom: '10px',
            paddingRight: '4px',
        },
        value: {
            fontWeight: 'bold',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontFamily: theme.typography.h1.fontFamily,
        },
        noData: {
            color: theme.palette.error.light,
            margin: 'auto',
            marginTop: '35%',
            marginLeft: '12%',
        }
    };
});

const IconPopup = (props) => {
    const { properties, isShowing, onClose } = props;
    const locationData = properties.properties;
    const { t, i18n } = useTranslation();
    const float = i18n.dir() === 'ltr' ? 'right' : 'left';
    const classes = useStyles({ float: float });
    const [ detectorImage, responseStatusDetectorImage, getDetectorImage ] = useGetDetectorImage();
    const [ deviceHistory, responseStatusDeviceHistory, getDeviceHistory ] = useGetDeviceHistory();
    const [ lastLocationScan, , getLastLocationScan ] = useGetLastLocationScan();

    const { getDetectorFromMapping } = useLocationIcon();

    useEffect(() => {
        if (isShowing && !isEmpty(locationData) && !isEmpty(locationData.location) && !isEmpty(locationData.location.id) ) {
            // the detectorType in locationData is not always right use the one in the device mapping
            // to be sure that is the right type
            const detector = getDetectorFromMapping(locationData.id);
            const deviceImage = !isEmpty(detector) && !isEmpty(detector.deviceImage) ? detector.deviceImage : '-';
            const type = !isEmpty(detector) && !isEmpty(detector.type) ? detector.type : '-';
            getDetectorImage(deviceImage, type);
            getDeviceHistory(locationData.location.id);
            getLastLocationScan(locationData.location.id);
        }
    }, [ isShowing, locationData, getDetectorFromMapping, getDetectorImage, getLastLocationScan, getDeviceHistory ]);

    const getHeader = () => {
        return (
            <div className={classes.titleContainer} tabIndex={0} aria-label={t('aria_label_icon_popup_title')}>
                <span className={classes.title}>{t('device_information')}</span>
            </div>
        );
    };

    const getInfoBlock = (label, value) => {
        return (
            <div className={classes.infoBlock}>
                <span className={classes.label}>{t(label)}:</span>
                <span className={classes.value}>{value}</span>
            </div>
        );
    };

    const getBodyPopup = () => {
        return (
            <div className={classes.infoContainer}>
                {getInfoBlock('zone', !isEmpty(locationData.location) && !isEmpty(locationData.location.department) ? locationData.location.department.description : '-')}
                {getInfoBlock('location', !isEmpty(locationData.location) ? locationData.location.description : '-')}
                {getInfoBlock('detector_type', !isEmpty(locationData.detectorType) ? locationData.detectorType.descriptionTranslated : '-')}
                {getInfoBlock('detector', !isEmpty(locationData.descriptionTranslated) ? locationData.descriptionTranslated : '-')}
                {getInfoBlock('barcode', !isEmpty(locationData.location) ? locationData.location.barcode : '-')}

                {getInfoBlock('last_scan_time', !isEmpty(lastLocationScan) && !isEmpty(lastLocationScan.localLastScanTime) ? lastLocationScan.localLastScanTime : '-')}
                {getInfoBlock('recommendations', !isEmpty(lastLocationScan) && !isEmpty(lastLocationScan.openRecommendation) ? lastLocationScan.openRecommendation.toString() : '-')}
                {getInfoBlock('last_recorded_activity', !isEmpty(lastLocationScan) && !isEmpty(lastLocationScan.localLastActivityTime) ? lastLocationScan.localLastActivityTime : '-')}

            </div>
        );
    };

    const showDetectorImage = () => {
        if (!isEmpty(detectorImage)) {
            return (
                <div tabIndex={0}>
                    {responseStatusDetectorImage && !isEmpty(detectorImage.signedUrl) ?
                        <img
                            className={classes.detectorImage}
                            alt={t('aria_label_detector_image')}
                            src={detectorImage.signedUrl}/> :
                        <h4 className={classes.noData}> {t('no_detector_image_available')}</h4>
                    }
                </div>
            );
        }
        return null;
    };

    const getBody = () => {
        return (
            <div className={classes.bodyContainer}>
                <div className={classes.infoBox}>
                    <div className={classes.infoOuter}>
                        {getBodyPopup()}
                    </div>
                    <div className={classes.imgContainer}>
                        {showDetectorImage()}
                    </div>
                </div>

                <DeviceHistoryTable
                    deviceHistory={deviceHistory}
                    responseStatus={responseStatusDeviceHistory}
                />
            </div>
        );
    };

    return (
        <MIModal
            header={getHeader()}
            body={getBody()}
            isShowing={isShowing}
            onClose={onClose}
            customStyle={{ rootHeight: 'fit-content', rootWidth: '800px'}}
        />
    );
};

export default IconPopup;
