import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {getLocalizedDate, isEmpty} from '../../utils/utils';
import MIInfoDialog from '../Widgets/mobile/MIInfoDialog';
import {Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import {observer} from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileBody,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    linkContentContainer: {
        marginTop: theme.sizes.nano_gap,
        marginBottom: theme.sizes.small_gap,
        width: 'fit-content',
    },
    linkContentText: {
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileTitle,
    },
}));

const SiteInfoDialog = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {open, handleClose, selectedSite} = props;

    const info = () => {
        let siteInfo = [];
        if(!isEmpty(selectedSite)){
            siteInfo.push(
                {label: t('site_customer'), value: selectedSite.siteName},
                {label: t('site_address'), value: selectedSite.siteAddress},
                {label: t('site_contract_no'), value: selectedSite.contractId},
                {label: t('site_last_visit'), value: selectedSite.lastVisit ? getLocalizedDate(t, selectedSite.lastVisit) : '-'},
                {label: t('site_next_visit'), value: selectedSite.nextVisit ? getLocalizedDate(t, selectedSite.nextVisit) : '-'}
            );
        }
        return siteInfo;
    };

    const content = (
        <Link to='/service/visit-history' onClick={handleClose} className={classes.link}>
            <div className={classes.linkContentContainer}>
                <Typography className={classes.linkContentText}> {t('visit_history')} </Typography>
            </div>
        </Link>
    );

    return (
        <MIInfoDialog
            title={t('site_information')}
            info={info()}
            content={content}
            open={open}
            handleClose={handleClose}
        />
    )

});

export default SiteInfoDialog;
