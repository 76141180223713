import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SocialIcons from '../SocialIcons';
import {mergeStyles} from '../../../utils/utils'
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    footer: {
        display: 'flex',
        marginLeft: theme.sizes.small_gap,
        marginRight: theme.sizes.small_gap,
        marginBottom: theme.sizes.micro_gap,
        color: theme.palette.text.primary,
    },
    siteLinks: {
        flexDirection: 'column',
        marginBottom: theme.sizes.nano_gap,
    },
    link: {
        color: theme.palette.primary.main,
        marginBottom: theme.sizes.pico_gap,
    },
    iconLinks: {
        justifyContent: 'flex-start',
        marginBottom: theme.sizes.nano_gap,
        marginLeft: ['-', theme.sizes.nano_gap].join(''),
    },
    iconLinkStyle: {
        padding: theme.sizes.pico_gap,
    }
}));

const FooterMobile = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {sharedClasses, content, createLink, copyright, manageCookies} = props;
    const {companyInfo, legalStatement, privacyPolicy, cookiePolicy, supplierCode} = content;

    return (
        <div aria-label={t('aria_footer')}>
            <div className={mergeStyles(sharedClasses.footer, classes.footer)}>
                <div className={mergeStyles(sharedClasses.siteLinks, classes.siteLinks)}>
                    {createLink(
                        mergeStyles(sharedClasses.link, classes.link), 
                        companyInfo, 
                        <Typography variant="body2"> 
                            {t('link_company_info')} 
                        </Typography>)
                    }
                    {createLink(
                        mergeStyles(sharedClasses.link, classes.link), 
                        legalStatement, 
                        <Typography variant="body2"> 
                            {t('link_legal_statement')} 
                        </Typography>)
                    }
                    {createLink(
                        mergeStyles(sharedClasses.link, classes.link), 
                        privacyPolicy, 
                        <Typography variant="body2"> 
                            {t('link_privacy_policy')} 
                        </Typography>)
                    }
                    {createLink(
                        mergeStyles(sharedClasses.link, classes.link), 
                        cookiePolicy, 
                        <Typography variant="body2"> 
                            {t('link_cookie_policy')} 
                        </Typography>)
                    }
                    {manageCookies(
                        mergeStyles(sharedClasses.link, classes.link), 
                        <Typography variant="body2"> 
                            {t('link_manage_cookies')} 
                        </Typography>)
                    }
                    {createLink(
                        mergeStyles(sharedClasses.link, classes.link), 
                        supplierCode, 
                        <Typography variant="body2"> 
                            {t('link_supplier_code')} 
                        </Typography>)
                    }
                </div>
                <SocialIcons classes={mergeStyles(sharedClasses.iconLinks, classes.iconLinks)} iconLinkStyle={classes.iconLinkStyle} content={content} />
                {copyright()}
            </div>
        </div>
    );
};

export default FooterMobile;
