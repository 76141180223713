import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, getGMTOffsetDateFromString, getLocalizedDate } from '../../utils/utils';
import { observer } from 'mobx-react-lite';
import { ResponsiveComponent } from '../../services/useResponsive';
import { useEntitlementProductDetails } from '../../services/entitlements/useEntitlementProductDetails';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useEntitlementsStore, useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { useAuthState } from '../../contexts/AuthProvider';
import EntitlementProductDetailsTableDesktop from './variant/EntitlementProductDetailsTableDesktop';
import EntitlementPeriod from './EntitlementPeriod';
import { useForm } from 'react-hook-form';

export const HEADER_AND_FOOTER_PRODUCT_TABLE = 214;

const useStyles = makeStyles((theme) => {
    return {
        productTable: {
            'marginTop': theme.sizes.xlarge_gap,
            'marginBottom': theme.sizes.xlarge_gap,
            'width': '100%',
            'minHeight': `${HEADER_AND_FOOTER_PRODUCT_TABLE}px`,
            '& div.MuiInputAdornment-positionEnd': {
                marginLeft: '0px',
            }
        },
        productTableName: {
            'fontSize': theme.typography.h4.fontSize,
            'fontFamily': theme.typography.h2.fontFamily,
            'color': theme.palette.text.primary,
            'marginTop': theme.sizes.tiny_gap,
            '&:focus': theme.ariaFocus,
        },
        heading: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
    };
});

const EntitlementProductDetailsTable = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { productCode, contractAnniversaryDate, annualQuantity, setProductTableHeight } = props;
    const { countryCode } = useAuthState();
    const [ getEntitlementProductDetails ] = useEntitlementProductDetails();
    const entitlementsStore = useEntitlementsStore();
    const siteStore = useSiteStore();
    const productDetailsKey = entitlementsStore.getProductDetailsKey(siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, productCode);
    const storedProductDetails = entitlementsStore.productDetails[productDetailsKey]?.values;

    const { control, watch, reset } = useForm();
    const dateFormat = 'DD/MM/YYYY';
    const fromDate = !isEmpty(watch('fromDate')) ? moment(watch('fromDate'), dateFormat) : null;
    const toDate = !isEmpty(watch('toDate')) ? moment(watch('toDate'), dateFormat) : null;

    useEffect(() => {
        if (!isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber) &&
            !isEmpty(countryCode) && !isEmpty(productCode)) {
            getEntitlementProductDetails(countryCode,
                siteStore.selectedContractNumber,
                siteStore.selectedPremiseNumber,
                productCode,
                contractAnniversaryDate,
                annualQuantity);
        }
    }, [ siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, countryCode, getEntitlementProductDetails,
        productCode, contractAnniversaryDate, annualQuantity ]);


    const getRows = useCallback(() => {
        let rows = [];
        if (!isEmpty(storedProductDetails)) {
            storedProductDetails.forEach((product, index) => {
                const productDate = !isEmpty(product.VisitDate) ? moment(product.VisitDate, dateFormat) : null;
                // Only sort if both fromDate and toDate are selected
                if((fromDate === null || toDate === null) || (
                    fromDate !== null && toDate !== null &&
                    !isEmpty(productDate) &&
                    productDate.startOf('day').isSameOrAfter(fromDate.startOf('day')) &&
                    productDate.startOf('day').isSameOrBefore(toDate.startOf('day')))
                ) {
                    rows.push({
                        id: index,
                        visitType: !isEmpty(product.VisitType) ? product.VisitType : '-',
                        productDescription: !isEmpty(product.Description) ? product.Description : '-',
                        visitLocDate: !isEmpty(product.VisitDate) ? getLocalizedDate(t, product.VisitDate, dateFormat) : '-',
                        visitDate: !isEmpty(product.VisitDate) ? getGMTOffsetDateFromString(product.VisitDate, dateFormat) : '-',
                        servedQuantity: !isEmpty(product.Quantity) ? product.Quantity : '-',
                        visitNote: product.VisitNote,
                        credit: !isEmpty(product.Credit) ? product.Credit : '-',
                        debit: !isEmpty(product.Debit) ? product.Debit : '-',
                        balance: !isEmpty(product.Balance) ? product.Balance : '-',
                    });
                }
            });
        }
        return rows;
    }, [ storedProductDetails, t, fromDate, toDate ]);

    const params = {
        rows: getRows(),
        responseStatus: entitlementsStore.productDetails[productDetailsKey]?.status,
        setProductTableHeight: setProductTableHeight
    };

    return (
        <div className={classes.productTable}>
            <div className={classes.heading}>
                <h2 className={classes.productTableName}
                    aria-label={t('product_detail_title')}
                    tabIndex={0}>
                    {t('product_detail')}
                </h2>
                <EntitlementPeriod
                    control={control}
                    watch={watch}
                    reset={reset}
                    rows={params.rows}
                />
            </div>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: <EntitlementProductDetailsTableDesktop {...params}/>,
                }}
            />
        </div>
    );
});

export default EntitlementProductDetailsTable;
