import {useCallback} from "react";
import useApiRequest from "./useApiRequest";
import {useAuthState} from "../../contexts/AuthProvider";
import mixedRegionRentokilMap from '../../utils/mixedRegionRentokilMap';

const useRegionalGCPApiRequest = () => {
    const [state, makeRequestWithParams] = useApiRequest();
    const {countryCode} = useAuthState();

    const makeRegionalGCPRequestWithParams = useCallback((url, requestMethod = 'get', requestParams = {}) => {
        // For a few countries the GCP region is not the same of the region where is coming from the login API.
        // For example the login API returns AU and NZ in the europe region but they should use the asia region
        // for the GCP API calls instead. If the country is not in the mixedRegionRentokilMap we will use the default
        // region otherwise we will use the one set in the map

        const region = mixedRegionRentokilMap[countryCode];
        return makeRequestWithParams(url, requestMethod, requestParams, region);
    }, [makeRequestWithParams, countryCode]);


    return [state, makeRegionalGCPRequestWithParams];
};

export default useRegionalGCPApiRequest;
