import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FETCHING } from '../../../services/requests/requestReducer';
import { getGMTOffsetDateFromString, getLocalizedDate, isEmpty } from '../../../utils/utils';
import MIGrid, { RenderTooltip } from '../../../components/Widgets/MIGrid';
import moment from 'moment';

const DEVICE_HISTORY_DATE_FORMAT = 'YYYY/MM/DD';

const DeviceHistoryTable = (props) => {
    const { t } = useTranslation();
    const { deviceHistory, responseStatus } = props;

    const gridColumns = [
        {
            field: 'visitDate',
            headerName: t('visit_date_device'),
            description: t('visit_date_device'),
            type: 'date',
            resizable: false,
            flex: 1,
            renderCell: (params) => {
                return <span>{!isEmpty(params.row.locVisitDate) ? getLocalizedDate(t, params.row.locVisitDate, DEVICE_HISTORY_DATE_FORMAT) : '-'}</span>;
            }
        },
        {
            field: 'scanned',
            headerName: t('scanned'),
            description: t('scanned'),
            type: 'string',
            resizable: false,
            flex: 1,
            renderCell: RenderTooltip
        },
        {
            field: 'preparations',
            headerName: t('preparations'),
            description: t('preparations'),
            type: 'string',
            resizable: false,
            flex: 1,
            renderCell: RenderTooltip
        },
        {
            field: 'amountUsed',
            headerName: t('amount_used'),
            description: t('amount_used'),
            type: 'string',
            resizable: false,
            flex: 1,
            renderCell: RenderTooltip
        }
    ];


    const getDeviceHistoryRows = useCallback(() => {
        let rows = [];
        if (!isEmpty(deviceHistory)) {
            deviceHistory.forEach((properties, index) => {
                const preparation = properties.preparation;
                rows.push({
                    id: index,
                    locVisitDate: properties.localScanDateTime,
                    visitDate: !isEmpty(properties.localScanDateTime) ? getGMTOffsetDateFromString(properties.localScanDateTime, DEVICE_HISTORY_DATE_FORMAT) : null,
                    scanned: !isEmpty(properties.localScanDateTime) ? moment(properties.localScanDateTime, DEVICE_HISTORY_DATE_FORMAT).format('hh:mm') : '-',
                    preparations: !isEmpty(preparation.prepUsedTranslated) ? preparation.prepUsedTranslated : '-',
                    amountUsed: !isEmpty(preparation.valueUsed) && !isEmpty(preparation.measureByTranslated) ? [ preparation.valueUsed, preparation.measureByTranslated ].join(' ') : '-',
                });
            });
        }
        return rows;
    }, [ deviceHistory ]);

    return (
        <MIGrid
            isLoading={responseStatus === null || responseStatus === FETCHING}
            columns={gridColumns}
            rows={getDeviceHistoryRows()}
            noElementsText={t('no_recent_visits')}
            heightToFitContent={true}
            hideFooterForOnePage={true}
            pageSize={3}
            pagination={true}
            ariaLabel={t('aria_label_recent_visits_table')}
            ariaLabelProgressBar={t('aria_label_progressbar_recent_visits')}
        />
    );
};

export default DeviceHistoryTable;
