import React, {useEffect} from "react";
import {usePlannedVisitDetails} from "../../services/plannedVisits/usePlannedVisitDetails";
import {useAuthState} from "../../contexts/AuthProvider";
import {observer} from "mobx-react-lite";
import {useSiteStore} from "../../contexts/StateProvider/StateProvider";
import {getBool, isEmpty} from '../../utils/utils';
import {ResponsiveComponent} from "../../services/useResponsive";
import PlannedVisitDetailsDesktop from "./variant/PlannedVisitDetailsDesktop";
import PlannedVisitDetailsMobile from "./variant/PlannedVisitDetailsMobile";
import {useFlags} from "../../contexts/FlagsProvider/FlagsProvider";
import {usePlannedVisitDetailsGCP} from "../../services/plannedVisits/usePlannedVisitDetailsGCP";

const PlannedVisitDetails = observer((props) => {
    const {plannedVisit, isShowing} = props;
    const [plannedVisitDetailsKony, responseStatusKony, getPlannedVisitDetails] = usePlannedVisitDetails();
    const [plannedVisitDetailsGCP, responseStatusGCP, getPlannedVisitDetailsGCP] = usePlannedVisitDetailsGCP();
    const {feature_planned_visits_gcp} = useFlags();
    const {locale, countryCode} = useAuthState();
    const siteStore = useSiteStore();
    const contractNumber = siteStore.getSelectedContractNumber();
    const premiseNumber = siteStore.getSelectedPremiseNumber();
    const plannedVisitDetails = feature_planned_visits_gcp? plannedVisitDetailsGCP : plannedVisitDetailsKony;
    const responseStatus = feature_planned_visits_gcp? responseStatusGCP :responseStatusKony;

    useEffect(() => {
        if (feature_planned_visits_gcp === false && siteStore.selectedSiteId && locale && isShowing) {
            // 'actualDate' is the real date of the planned visit, date instead can change based of a myAdmin configuration
            getPlannedVisitDetails(siteStore.selectedSiteId,
                locale,
                plannedVisit.visitType,
                plannedVisit.actualDate,
            )
        }
    }, [siteStore.selectedSiteId, locale, getPlannedVisitDetails,
        plannedVisit.visitType, plannedVisit.actualDate, isShowing,
        feature_planned_visits_gcp]);

    useEffect(() => {
        if (getBool(feature_planned_visits_gcp)
            && isShowing
            && !isEmpty(countryCode)
            && !isEmpty(contractNumber)
            && !isEmpty(premiseNumber)
            && !isEmpty(plannedVisit.date)) {
            getPlannedVisitDetailsGCP(countryCode, contractNumber, premiseNumber, plannedVisit.date)
        }
    }, [getPlannedVisitDetailsGCP, contractNumber, premiseNumber, countryCode,
        plannedVisit.date, feature_planned_visits_gcp, isShowing]);

    const getRows = () => {
        let rows = [];
        if (!isEmpty(plannedVisitDetails)) {
            plannedVisitDetails.forEach((row, index) => {
                rows.push({
                        id:index,
                        productServiced: row.productServiced,
                        qty: row.qty,
                        visitsAnnum: row.visitsAnnum
                    }
                );
            });
        }
        return rows;
    };

    const params = {
        rows: getRows(),
        responseStatus:responseStatus,
    };

    return (
       <ResponsiveComponent
            components={{
                mobile: <PlannedVisitDetailsMobile {...params} {...props}/>,
                desktop: <PlannedVisitDetailsDesktop {...params} {...props}/>,
            }}
        />
    );
});

export default PlannedVisitDetails;