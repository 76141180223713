import { useCallback, useEffect, useState } from 'react';
import useRawRequest from '../requests/useRawRequest';
import { isEmpty } from '../../utils/utils';
import { SUCCESS } from '../requests/requestReducer';

export const useMagnolia = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useRawRequest();
    const [ content, setContent ] = useState('');
    // TODO: Use the staging url instead
    const magnoliaBaseUrl = 'http://localhost:8080';
    const magnoliaBaseEndpoint = 'magnoliaAuthor/.rest/delivery';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            if (!isEmpty(response) && !isEmpty(response) && !isEmpty(response)) {
                setContent(response.data.results[0]);
            } else {
                console.log('No content', response.data);
            }
        }
    }, [ responseStatus, response, setContent ]);

    const getContentFromMagnolia = useCallback((path) => {
        setContent('');
        const endpoint = [ magnoliaBaseUrl, magnoliaBaseEndpoint, path ].join('/');
        makeRequestWithParams(endpoint).catch((error) => {
            console.error('getContentFromUrl error: ', error);
        });
    }, [ makeRequestWithParams ]);

    return [ content, responseStatus, getContentFromMagnolia ];
};
