import React, { useEffect } from 'react';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import { useMagnolia } from '../../services/magnolia/useMagnolia';
import { makeStyles } from '@material-ui/core/styles';
import { useResponsive } from '../../services/useResponsive';
import { Typography } from '@material-ui/core';
import MIPageTitle from '../../components/Widgets/MIPageTitle';


const desktopStyles = () => {
    return {
        container: {
            display: 'inline'
        },
        image: {
            width: '302px',
            height: '242px',
        },
    };
};

const MagnoliaPOC = (props) => {
    const { url } = props;
    const { feature_magnolia_poc } = useFlags();
    const [ magnoliaContent,, getContentFromMagnolia ] = useMagnolia();
    const { getStyles } = useResponsive();
    // TODO: Use the staging url instead
    const magnoliaBaseUrl = 'http://localhost:8080';

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: null
        });
    }
    );

    const classes = useStyles();

    useEffect(() => {
        if (feature_magnolia_poc) {
            getContentFromMagnolia(url);
        }
    }, [ feature_magnolia_poc, getContentFromMagnolia, url ]);

    const getMagnoliaPOC = () => {
        if (magnoliaContent) {
            return <div className={classes.container}>
                <MIPageTitle title={'CSM CONTENT FROM MAGNOLIA!'}/>
                <Typography> {magnoliaContent.text} </Typography>
                <img
                    alt={'Magnolia marketing content'}
                    className={classes.image}
                    src={`${magnoliaBaseUrl}${ magnoliaContent.image.renditions['480x360'].link}`}
                />
            </div>;
        }
    };

    return (
        <div>
            {feature_magnolia_poc && getMagnoliaPOC()}
        </div>
    );
};

export default MagnoliaPOC;
