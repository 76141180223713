import React, {useCallback, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import MIGrid from "../Widgets/MIGrid";
import {observer} from "mobx-react-lite";
import {getLocalizedDate} from "../../utils/utils";
import PlannedVisitDetails from "./PlannedVisitDetails";
import useModal from "../../services/useModal";
import {isEmpty} from '../../utils/utils';

const useStyles = makeStyles(theme => ({
    dataCell: {
        lineHeight: 0,
        marginRight: '-10px',
        marginLeft: '-10px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    gridContainer: {
        minHeight: theme.heights.mobile_grid_min_height,
        borderTop: '1px solid' + theme.palette.grey['300'],
        '& div.MuiDataGrid-row': {
            '&:last-child': {
                borderBottom: theme.sizes.no_gap,
            },
        }
    },
    plannedVisitsDate: {
        fontSize: theme.fontSizes.mobileLabel,
    },
    plannedVisitsType: {
        fontSize: theme.fontSizes.mobile,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));

const PlannedVisitsGrid = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {plannedVisits, maxCards, setSelectedPlannedVisitDateString, selectedPlannedVisitDateString} = props;
    const {isShowing, changeStatus} = useModal();
    const [plannedVisitSelected, setPlannedVisitSelected] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);

    const selectionModelFunction = (newSelection) => {
        setSelectionModel(newSelection.selectionModel);
    };

    const getRows = useCallback((maxRows) => {
        let rows = [];
        if (!isEmpty(plannedVisits)) {
            const plannedVisitsRows = maxRows ? plannedVisits.slice(0, maxRows) : plannedVisits;
            plannedVisitsRows.forEach((record, index) => {
                rows.push({
                    id: index,
                    date: record.date,
                    locDate: getLocalizedDate(t, record.date),
                    visitTypeDesc: record.visitTypeDesc || '-',
                    visitType: record.visitType || '-',
                    actualDate: record.actualDate || '-'

                });
            });
        }

        return rows;
     }, [t, plannedVisits]);


    useEffect(() => {
        if (selectedPlannedVisitDateString) {
            let selectedRow = [];
            const rows = getRows(maxCards);
            rows.forEach((row) => {
                if (row.locDate === selectedPlannedVisitDateString) {
                    selectedRow.push(row.id)
                }
            });
            setSelectionModel(selectedRow);
        }
    }, [selectedPlannedVisitDateString, getRows, maxCards]);

    const handleOnClick  = (row) => {
        if(!isShowing) {
            setPlannedVisitSelected(row.data);
            setSelectedPlannedVisitDateString(row.data.locDate);
            changeStatus();
        }
    };

    const columns = [
        {
            field: 'dateAndType', resizable: false, flex: 1,
            renderCell: (params) => (
                <div className={classes.dataCell} aria-label={t('aria_label_planned_visit_row')}>
                    <Typography className={classes.plannedVisitsDate} variant="body2">
                        {params.row.locDate}
                    </Typography>
                    <Typography nowrap={'true'} className={classes.plannedVisitsType} variant="body2">
                        {params.row.visitTypeDesc}
                    </Typography>
                </div>
            )
        },
    ];

    return (
        <div className={classes.gridContainer}>
            <MIGrid
                showInfoModal={false}
                heightToFitContent={true}
                columns={columns}
                rows={getRows(maxCards)}
                noElementsText={t('no_planned_visits')}
                ariaLabel={t('aria_label_planned_visits_title')}
                ariaLabelProgressBar={t('aria_label_progressbar_planned_visits')}
                headerHeight={0}
                noEvenRowStyling={true}
                hasFilters={false}
                hideFooter={true}
                onRowSelected={handleOnClick}
                onSelectionModelChange={selectionModelFunction}
                selectionModel={selectionModel}
                {...props}
            />
            {!isEmpty(plannedVisitSelected) ?
                <PlannedVisitDetails isShowing={isShowing}
                                     onClose={changeStatus}
                                     plannedVisit={plannedVisitSelected}/> :
                null
            }
        </div>

    );
});

export default PlannedVisitsGrid;
