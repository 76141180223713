import { makeAutoObservable } from 'mobx';
import { getSiteKey } from '../../utils/utils';

class EntitlementsStore {
    productDetails = {};
    entitlements = {};

    constructor() {
        makeAutoObservable(this);
    }

    setProductDetails(key, productDetails, status) {
        this.productDetails[key] = { values: productDetails, status: status };
    }

    getProductDetailsKey(contractNumber, premiseNumber, productCode) {
        return `${getSiteKey(contractNumber, premiseNumber)}_${productCode}`;
    }

    setEntitlements(key, entitlements) {
        this.entitlements[key] = entitlements;
    }

    reset() {
        this.productDetails = {};
        this.entitlements = {}
    }
}

export { EntitlementsStore };
