import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import MIGrid from '../../../components/Widgets/MIGrid';
import {observer} from 'mobx-react-lite';
import {getBool, isEmpty, mergeStyles} from '../../../utils/utils';
import {Typography, Grid} from '@material-ui/core';
import MIColoredIcon from '../../../components/Widgets/MIColoredIcon'; 
import AssignmentIcon from '@material-ui/icons/Assignment';
import {useBaseStyles} from '../Recommendations';
import theme from '../../../components/Theme/Theme';

const useStyles = makeStyles(theme => ({
    dataCell: {
        lineHeight: 0,
        width: '100%',
        whiteSpace: 'normal',
    },
    columnName: {
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileBody,
        marginRight: theme.sizes.pico_gap
    },
    priority: {
        fontFamily: theme.fontFamily.proximaMedium,
        fontSize: theme.fontSizes.mobileBody
    },
    location: {
        height: theme.sizes.mobileBody,
        overflowWrap: 'break-word',
        whiteSpace: 'nowrap',
        fontSize: theme.fontSizes.mobileBody
    },
    gridContainer: {
        '& div.MuiDataGrid-cell': {
            paddingRight: theme.sizes.micro_gap,
            whiteSpace: 'normal',
        },
        '& div.MuiDataGrid-columnsContainer': {
            borderBottom: theme.borders.outline_regular, 
        },
        '& div.MuiDataGrid-colCellTitle': {
            color: theme.palette.primary.main,
        },
        marginBottom: theme.sizes.xxlarge_gap,
    },
    selectLinkMobile: {
        height: theme.sizes.large_gap,
        width: theme.sizes.large_gap,
    },
}));

const RecommendationMobile = observer((props) => {
    const {t} = useTranslation();
    const baseStyles = useBaseStyles();
    const classes = {...baseStyles, ...useStyles()};
    const {
        isLoading, 
        rows, 
        selectionComponent, 
        mobilePageSize,
        hideFooter, 
        headerHeight,
        goToRecommendationDetails
    } = props;

    const getGridRow = (columnName, value) => (
        <Grid container item xs={12}>
            <Grid item xs={2}>
                <Typography className={classes.columnName}>{columnName + ': '} </Typography>
            </Grid>
            <Grid item xs={8}>
                {value}
            </Grid>
        </Grid>
    )

    const coloredIconStyles = {
        iconMarginTop: ['-', theme.sizes.femto_gap].join(''),
    }

    const getStatusAndPriorityCell = (params) => (
        <Grid
            container
            direction='row'
            className={classes.dataCell}
        >
            <Grid container item xs={11}>
                {getGridRow(t('status'), <MIColoredIcon item={params.row.status} styles={coloredIconStyles}/>)}
                {getGridRow(t('priority'), <Typography className={classes.priority}> {params.row.priority} </Typography>)}
            </Grid>
            <Grid item xs={1} className={classes.linkContainer}>
                    <AssignmentIcon className={mergeStyles(classes.selectLink, classes.selectLinkMobile)}/>
            </Grid>
            <Typography className={classes.location} noWrap>{params.row.location}</Typography>
        </Grid>
    );

    const gridColumns = [{
        field: 'recommendations',
        headerName: t('recommendations'),
        type: 'string',
        resizable: false,
        filterable: false,
        sortable: false,
        flex: 1,
        renderCell: (params) => getStatusAndPriorityCell(params),
    }];

    const handleRowClick = (selectedRow) => {
        goToRecommendationDetails(selectedRow.data)
    };

    return (
        <div className={classes.gridContainer}>
            <MIGrid
                isMobile={true}
                showInfoModal={false}
                isLoading={isLoading}
                columns={gridColumns}
                rows={rows}
                noElementsText={t('no_recommendations')}
                rowHeight={55}
                hasFilters={false}
                pagination={true}
                autoPageSize={false}
                pageSize={mobilePageSize ? mobilePageSize : rows.length}
                ariaLabel={t('aria_label_recommendations_table')}
                ariaLabelProgressBar={t('aria_label_progressbar_recommendations')}
                onRowSelected={handleRowClick}
                heightToFitContent={true}
                headerHeight={!isEmpty(headerHeight) ? headerHeight : 0}
                hideFooter={getBool(hideFooter)}
                hideFooterForOnePage={true}
                noEvenRowStyling={true}
                {...selectionComponent}
                {...props}
            />
        </div>
    );
});

export default RecommendationMobile;
