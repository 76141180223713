import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { useResponsive } from '../../services/useResponsive';

const useStyles = makeStyles((theme) => {
    return {
        calendar: (props) => {
            return {
                'marginTop': theme.sizes.nano_gap,
                'display': 'flex-inline',
                'width': props.calendarWidth ? props.calendarWidth : '221px',
                'height': props.calendarHeight ? props.calendarHeight : theme.sizes.xxxlarge_gap,
                'paddingRight':theme.sizes.nano_gap,
                '& fieldset': {
                    borderRadius: theme.sizes.no_gap,
                    border: theme.borders.listBorders,
                },
                '& .MuiOutlinedInput-adornedEnd': {
                    paddingRight: theme.sizes.no_gap,
                },
                '& .MuiFormLabel-root.Mui-error': {
                    fontWeight: theme.typography.fontWeightRegular,
                    color: theme.palette.error.dark,
                }
            };
        },
        inputProps: {
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    boxShadow: 'rgba(0,125,197,0.25) 0px 1px 9px 1px',
                }
            },
            '&.Mui-error': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.error.dark,
                }
            },
            '& .MuiPickersToolbar-toolbar': {
                height: '60px'
            }
        },
    };
});

const desktopHelperTextStyles = (theme) => {
    return {
        error: {
            '&.MuiFormHelperText-root.Mui-error': {
                fontSize: theme.typography.small_error.fontSize,
                marginTop: theme.sizes.pico_gap,
                marginLeft: theme.sizes.no_gap,
                marginRight: theme.sizes.no_gap,
                color: theme.palette.error.dark,
                width: 'max-content',
            }
        }
    };
};

const mobileHelperTextStyles = (theme) => {
    const baseStyle = desktopHelperTextStyles(theme);

    return Object.assign(baseStyle, {
        error: {
            '&.MuiFormHelperText-root.Mui-error': {
                ...baseStyle.error['&.MuiFormHelperText-root.Mui-error'],
                fontSize: theme.fontSizes.mobileLabel,
            }
        }
    });
};

const MIDatePicker = ({ watch, label, ariaLabel, datePickerProps, dateFormat, customStyle, ...props }) => {
    const { t, i18n } = useTranslation();
    const classes = useStyles(customStyle);
    const { getStyles } = useResponsive();
    const currentLocale = moment().locale();

    const useHelperTextStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopHelperTextStyles(theme),
            mobile: mobileHelperTextStyles(theme)
        });
    }
    );

    const helperTextClasses = useHelperTextStyles();

    return (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={currentLocale}>
            <Controller
                render={({ ref, ...props }) => {
                    return <KeyboardDatePicker
                        className={classes.calendar}
                        InputProps={{ className: classes.inputProps }}
                        InputLabelProps={{ shrink: true }}
                        FormHelperTextProps={{ classes: helperTextClasses, role: 'alert' }}
                        DialogProps={{ className: classes.inputProps }}
                        aria-label={ariaLabel}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label={label}
                        orientation={getStyles({
                            desktop: 'landscape',
                            mobile: 'portrait'
                        })}
                        invalidDateMessage={t('invalid_date_format')}
                        format={dateFormat}
                        placeholder={dateFormat}
                        leftArrowButtonProps={{ 'aria-label': t('aria_label_left_arrow_calendar_picker') }}
                        rightArrowButtonProps={{ 'aria-label': t('aria_label_right_arrow_calendar_picker') }}
                        {...datePickerProps}
                        {...props}
                    />;
                }
                }
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
};

export default MIDatePicker;
