import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';

const OneTrustCookie = () => {
    const id = process.env.REACT_APP_ONE_TRUST_COOKIE_ID;

    const helmetContext = {};

    return ( 
        <HelmetProvider context={helmetContext}>
            <Helmet>
                <script type="text/javascript" src={"https://cdn.cookielaw.org/consent/" + id + "/OtAutoBlock.js"}></script>
                <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script={id}></script>
                <script type="text/javascript">{' function OptanonWrapper() { }'}</script>
            </Helmet>     
        </HelmetProvider>
       
    );
};

export default OneTrustCookie;
