import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import { useAuthState } from '../../contexts/AuthProvider';
import useFloorPlanApiRequest from '../requests/useFloorPlanApiRequest';

export const useGetLastLocationScan = () => {
    const [ lastLocationScan, setLastLocationScan ] = useState(null);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const { countryCode, locale } = useAuthState();
    const endpoint = '/floorPlanApi/getLastLocationScanDetails';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setLastLocationScan(response.data);
            } catch (error) {
                console.error('useGetLastLocationScan error: ', error);
                setLastLocationScan(null);
            }
        }
    }, [ responseStatus, response, setLastLocationScan ]);

    const getLastLocationScan = useCallback((locationId) => {
        const url = [ endpoint, countryCode, locale, locationId ].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetLastLocationScan makeRequest: ', error);
        });
    }, [ makeFloorPlanApiRequestWithParams, countryCode, locale ]);

    return [ lastLocationScan, responseStatus, getLastLocationScan ];
};
