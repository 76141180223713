import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';

export const useNotificationSignedUrl = () => {
    const [ notificationSignedUrl, setNotificationSignedUrl ] = useState('');
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/notification/contentSignedUrl';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setNotificationSignedUrl(response.data.signedUrl);
            } catch (error) {
                console.error('useNotificationSignedUrl error: ', error);
                setNotificationSignedUrl('');
            }
        }
    }, [ responseStatus, response, setNotificationSignedUrl ]);

    const getNotificationContentSignedUrl = useCallback((notificationUrl) => {
        setNotificationSignedUrl('');
        const params = { notificationUrl: notificationUrl };
        makeRegionalGCPRequestWithParams(endpoint, 'post', params).catch((error) => {
            console.error('useNotificationSignedUrl error: ', error);
        });
    }, [ makeRegionalGCPRequestWithParams, setNotificationSignedUrl ]);

    return [ notificationSignedUrl, responseStatus, getNotificationContentSignedUrl ];
};
