import { useCallback, useEffect, useState } from 'react';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';
import { SUCCESS } from '../requests/requestReducer';
import { getFromDate, RAPID_FORMAT_DATE } from '../../utils/smartHygieneUtils';
import { useAuthState } from '../../contexts/AuthProvider/AuthProvider';
import moment from 'moment';
import { isEmpty } from '../../utils/utils';

export const useBenefitUnits = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/rapidService/rapidBenefits/units';
    const [ unitResults, setUnitResults ] = useState({});
    const { countryCode } = useAuthState();
    // Hardcoded value for Benefit Units values because there is no data in staging
    const smartHygienePresetContractAndPremise = process.env.REACT_APP_PRESET_SMART_HYGIENE_CONTRACT_AND_PREMISE;

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setUnitResults(response.data);
            } catch (error) {
                console.error('useBenefitUnits error: ', error);
                setUnitResults([]);
            }
        }
    }, [ responseStatus, response ]);

    const getBenefitUnits = useCallback((contractNumber, premiseNumber, timePeriod) => {
        let toDate = moment().format(RAPID_FORMAT_DATE);
        let fromDate = getFromDate(timePeriod);
        const contractAndPremiseNo = isEmpty(smartHygienePresetContractAndPremise) ? [ contractNumber, premiseNumber ].join('/') : smartHygienePresetContractAndPremise;
        const url = [ endpoint, contractAndPremiseNo, countryCode, fromDate, toDate ].join('/');

        makeRequestWithParams(url).catch((error) => {
            console.error('useBenefitUnits error: ', error);
        });
    }, [ makeRequestWithParams, countryCode, smartHygienePresetContractAndPremise ]);

    return [ unitResults, responseStatus, getBenefitUnits ];
};
