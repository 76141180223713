import {useState, useEffect, useCallback} from 'react';
import {SUCCESS} from "../requests/requestReducer";
import {useSiteStore, useVisitStore} from "../../contexts/StateProvider/StateProvider";
import {isEmpty} from "../../utils/utils";
import useRegionalGCPApiRequest from "../requests/useRegionalGCPApiRequest";

export const usePlannedVisitsGCP = () => {
    const visitStore = useVisitStore();
    const siteStore = useSiteStore();
    const [currentSiteId, setCurrentSiteId] = useState(null);
    const [plannedVisits, setPlannedVisits] = useState(null);
    const [{responseStatus, response}, makeRegionalGCPRequestWithParams] = useRegionalGCPApiRequest();
    const endpoint = '/premiseService/plannedVisits/visitPlan';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                visitStore.setPlannedVisits(currentSiteId, response.data.visitPlan);
                setPlannedVisits(response.data.visitPlan);
            } catch (error) {
                console.error('usePlannedVisitsGCP error: ', error);
                visitStore.setPlannedVisits(currentSiteId, []);
                setPlannedVisits(null);
            }
        }
    }, [responseStatus, response, setPlannedVisits, visitStore, currentSiteId]);

    const getPlannedVisitsGCP = useCallback((contractNumber, premiseNumber, countryCode) => {
        const storedPlannedVisits = visitStore.getPlannedVisits(siteStore.selectedSiteId);
        if (!isEmpty(storedPlannedVisits)) {
            setPlannedVisits(storedPlannedVisits);
        } else {
            setPlannedVisits(null);
            setCurrentSiteId(siteStore.selectedSiteId);
            const url = [endpoint, countryCode, contractNumber, premiseNumber].join('/');
            makeRegionalGCPRequestWithParams(url).catch(error => {
                console.error('getPlannedVisitsGCP makeRequestWithParams: ', error)
            });
        }
    }, [makeRegionalGCPRequestWithParams, visitStore, siteStore.selectedSiteId]);

    return [plannedVisits, (plannedVisits !== null) ? SUCCESS : responseStatus, getPlannedVisitsGCP];
};
