import clustering from 'density-clustering';
import { isObjectEmpty } from './utils';

const CLUSTER_RADIUS = 36;
const CLUSTER_MIN_POINTS = 2;

const calcMidPoint = (points, cluster) => {
    let xTotal = 0;
    let yTotal = 0;
    cluster.forEach((index) => {
        xTotal = xTotal + points[index].coordinates.x;
        yTotal = yTotal + points[index].coordinates.y;
    });
    return { x: xTotal / cluster.length, y: yTotal / cluster.length };
};

export const calcClusters = (points, scale) => {
    if (isObjectEmpty(points)) {
        return null;
    }
    let dataset = points.map((point) => [ point.coordinates.x * scale, point.coordinates.y * scale ]);
    const dbscan = new clustering.DBSCAN();
    const clusterGroups = {
        clusters: dbscan.run(dataset, CLUSTER_RADIUS, CLUSTER_MIN_POINTS, undefined),
        singles: dbscan.noise,
    };
    clusterGroups.midpoints = clusterGroups.clusters.map((cluster) => calcMidPoint(points, cluster));
    return clusterGroups;
};


export const scalePosition = (position, centre, scale, translation, offset) => {
    const coords = {
        x: position.x * scale + translation.x - centre.x,
        y: position.y * scale + translation.y - centre.y
    };
    if (offset) {
        return { x: coords.x + offset.x, y: coords.y + offset.y };
    }
    return coords;
};

export const isVisible = (coordinates, scale, translation, width, height, offset) => {
    const coords = scalePosition(coordinates, { x: 0, y: 0 }, scale, translation, offset);
    return coords.x > 0 && coords.x < width && coords.y > 0 && coords.y < height;
};

export const getClusterId = (prefix, midPoint) => [ prefix, midPoint?.x.toString(), midPoint?.y.toString() ].join('-');
