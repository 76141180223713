import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useResponsive } from '../../services/useResponsive';
import { Paper } from '@material-ui/core';
import WasteTransferNotesTable from '../../components/WasteTables/WasteTransferNotesTable';
import SiteSelector from "../../components/SiteSelector/SiteSelector";


const desktopStyles = (theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.large_gap,
            paddingRight: '0px',
            paddingLeft: '0px',
            maxHeight: 'none',
        },
    });
};


const WasteTransferNotes = () => {
    const { t } = useTranslation();

    const { getStyles } = useResponsive();
    const useStyles = makeStyles((theme) => {
        return (
            getStyles({
                desktop: desktopStyles(theme),
                mobile: mobileStyles(theme)
            })
        );
    });

    const classes = useStyles();


    return (
        <Paper className={classes.root} aria-label={t('aria_label_waste_consignment_page')}>
            <SiteSelector/>
            <WasteTransferNotesTable/>
        </Paper>
    );
};

export default WasteTransferNotes;
