import React, {useEffect} from "react";
import MICmsContainer from "../Widgets/MICmsContainer";
import {useContentFromUrl} from "../../services/useContentFromUrl";
import MIApiLoader from "../Widgets/MIApiLoader";
import {isEmpty} from "../../utils/utils";
import {useTranslation} from "react-i18next";

const HelpfulInfo = () => {
    const {t} = useTranslation();
    const [content, responseStatus, getContentFromUrl, getContentUrl] = useContentFromUrl();

    useEffect(() => {
        const url = getContentUrl('homepage.html');
        getContentFromUrl(url);
    }, [getContentFromUrl, getContentUrl]);

    return (
        <MIApiLoader
            MIComponent={
                isEmpty(content) ? '' : <MICmsContainer html={content}/>
            }
            responseStatus={responseStatus}
            ariaLabelProgressBar={t('aria_label_progressbar_helpful_info')}
        />
    );
}

export default HelpfulInfo;
