import {useCallback, useEffect, useState} from "react";
import useApiRequest from '../requests/useApiRequest'
import {SUCCESS} from "../requests/requestReducer";
import {isEmpty} from "../../utils/utils";
import {useWasteStore} from "../../contexts/StateProvider/StateProvider";

export const useWasteConsignment = () => {
    const wasteStore = useWasteStore();
    const [currentSiteId, setCurrentSiteId] = useState(null);
    const [wasteConsignment, setWasteConsignment] = useState(null);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/hazardWasteService/notes';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                wasteStore.setWasteConsignment(currentSiteId, response.data);
                setWasteConsignment(response.data);
            } catch (error) {
                console.error('useWasteConsignment error: ', error);
                wasteStore.setWasteConsignment(currentSiteId, []);
                setWasteConsignment(null);
            }
        }
    }, [responseStatus, response, wasteStore, currentSiteId]);

    const getWasteConsignment = useCallback((siteId, contractNo, premiseNo) => {
        const wasteVisits = wasteStore.getWasteConsignment(siteId);

        if (!isEmpty(wasteVisits)) {
            setWasteConsignment(wasteVisits);
        } else {
            setWasteConsignment(null);
            setCurrentSiteId(siteId);
            let wasteNoteSite;
            /* Due to there is no staging data for waste sites, in the staging 
            environment we had to use a preset site number to test the download
            pdf */
            if (!isEmpty(process.env.REACT_APP_PRESET_SITE)) {
                wasteNoteSite = process.env.REACT_APP_PRESET_SITE;
            } else {
                wasteNoteSite = [contractNo, premiseNo].join('/');
            }

            const url = [endpoint, wasteNoteSite].join('/');
            makeRequestWithParams(url).catch(error => {
                console.error('useWasteConsignment error: ', error);
            });
        }
        
    }, [makeRequestWithParams, wasteStore])

    return [wasteConsignment, responseStatus, getWasteConsignment];
};
