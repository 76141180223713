import {makeAutoObservable} from "mobx";

class DetectorStore {
    detectors = {};

    constructor() {
        makeAutoObservable(this);
    }

    getDetectors(floorId) {
        return this.detectors[floorId];
    }

    setDetectors(floorId, detectors) {
        this.detectors[floorId] = detectors;
    }

    findDetector(floorId, id, barcode) {
        if (Symbol.iterator in Object(this.detectors[floorId])) {
            for (const detector of this.detectors[floorId]) {
                if (detector.properties && detector.properties.id === id &&
                    detector.properties.location && detector.properties.location.barcode === barcode
                ) {
                    return detector;
                }
            }
        }
        return null;
    }

    getDetectorCount(floorId) {
        return this.detectors[floorId] ? this.detectors[floorId].length : 0;
    }

    detectorsLoaded(floorId) {
        return this.detectors[floorId] !== undefined;
    } 

    updateDetectors(floorId, detectors) {
        detectors.forEach(newDetector => {
            let detector = this.findDetector(floorId, newDetector.id, newDetector.location.barcode);
            if (detector !== null) {
                detector.properties.openRecommendations = newDetector.openRecommendations;
                if (newDetector.location && newDetector.location.connectSensor) {
                    detector.properties.message = newDetector.location.connectSensor.statusDescription;
                }
                detector.properties.localLatestInfestationDate = newDetector.localLatestInfestationDate;
            }
        });
    }

    reset() {
        this.detectors = {};
    }
}

export {DetectorStore};