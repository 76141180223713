import {addScript} from "./utils/utils";

export const addStagingAnalytics = () => {
    addScript('script', 'https://www.googletagmanager.com/gtag/js?id=G-0FMZY8KTY4', null);
    addScript('script', null,
        "window.dataLayer = window.dataLayer || []; " +
        "function gtag() { " +
        "  dataLayer.push(arguments); " +
        "} " +
        "gtag('js', new Date()); " +
        "gtag('config', 'G-0FMZY8KTY4');"
    );
}

export const addProductionAnalytics = () => {
    addScript('script', null,
        "window.dataLayer = window.dataLayer || []; " +
        "dataLayer.push({ " +
        "  'ga_account_id': 'UA-192627196-1', " +
        "  'event': 'ga_pageview' " +
        "});"
    );

    addScript('script', null,
        "(function(w,d,s,l,i){" +
        "  w[l]=w[l]||[]; " +
        "  w[l].push({" +
        "    'gtm.start':new Date().getTime()," +
        "    event:'gtm.js'" +
        "  });" +
        "  var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';" +
        "  j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;" +
        "  j.setAttribute('data-ot-ignore', ''); " +
        "  j.classList.add('optanon-category-C0001'); " +
        "  f.parentNode.insertBefore(j,f); " +
        "})(window,document,'script','dataLayer','GTM-WFQ3P9R');"
    );

    addScript('noscript', null,
        "<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-WFQ3P9R' height='0' width='0' style='none;visibility:hidden'></iframe>"
    );
}
