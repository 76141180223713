import {useCallback, useEffect, useState} from "react";
import {SUCCESS} from "../requests/requestReducer";
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";

export const useGetRecommendationComments = () => {
    const [recommendationComments, setRecommendationComments] = useState([]);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getRecommendationComments';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setRecommendationComments(response.data.results);
            } catch (error) {
                console.error('useGetRecommendationComments error: ', error);
                setRecommendationComments([]);
            }
        }
    }, [responseStatus, response, setRecommendationComments]);

    const getRecommendationComments = useCallback((recommendationId) => {
        const url = [endpoint, recommendationId].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetRecommendationComments makeRequestWithParams: ', error);
        });
    }, [makeFloorPlanApiRequestWithParams]);

    return [recommendationComments, responseStatus, getRecommendationComments];
};
