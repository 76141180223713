import { useState, useEffect, useCallback } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';

export const useSessionCookie = () => {
    const [ session, setSession ] = useState(false);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setSession(true);
            } catch (error) {
                console.error('useSSO error: ', error);
            }
        }
    }, [ responseStatus, response, setSession ]);

    const createSession = useCallback((idToken) => {
        const params = {
            idToken: idToken,
        };
        makeRequestWithParams('/createSession', 'post', params).catch(error => {
            console.error('createSession makeRequestWithParams: ', error)
        });
    }, [ makeRequestWithParams ]);

    return [ session, setSession, responseStatus, createSession ];
};
