import { useState, useEffect, useCallback, useRef } from 'react';
import { ERROR, FETCHING, SUCCESS } from '../requests/requestReducer';
import { useAuthState } from '../../contexts/AuthProvider';
import { isEmpty } from '../../utils/utils';
import { useDetectorStore } from '../../contexts/StateProvider/StateProvider';
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";

const PAGE_LIMIT = 1000;

export const useGetFloorPlanDevices = () => {
    const detectorStore = useDetectorStore();
    const [ floorPlanId, setFloorPlanId ] = useState(null);
    const downloadCount = useRef(0);
    const total = useRef(0);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const { countryCode, locale } = useAuthState();
    const [ status, setStatus ] = useState('');
    const endpoint = '/floorPlanApi/getFloorPlanDetectors';

    const hasAdditionalData = (entry) => {
        if (isEmpty(entry) || isEmpty(entry.properties)) {
            return false;
        }
        const properties = entry.properties;
        const hasConnectData = !isEmpty(properties.location) && !isEmpty(properties.location.connectSensor);
        const hasRecs = !isEmpty(properties.openRecommendations);
        const hasActivity = !isEmpty(properties.localLatestInfestationDate);

        return hasConnectData || hasRecs || hasActivity;
    };

    const getDetectorPage = useCallback((floorId, start, limit) => {
        const connectEnabled = 'yes';
        // filtering done frontend side
        const filterBy = '';
        const url = [ endpoint, countryCode, locale, floorId, start, limit, connectEnabled, filterBy ].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetFloorPlanDevices makeRequest: ', error);
        });
    }, [ makeFloorPlanApiRequestWithParams, countryCode, locale ]);

    const getFloorPlanDetectors = useCallback((floorId) => {
        setFloorPlanId(floorId);
        const detectors = detectorStore.getDetectors(floorId);
        if (isEmpty(detectors) || !hasAdditionalData(detectors[0])) {
            setStatus(FETCHING);
            downloadCount.current = 0;
            total.current = 0;
            getDetectorPage(floorId, 0, PAGE_LIMIT);
        } else {
            setStatus(SUCCESS);
        }
    }, [ getDetectorPage, detectorStore, setStatus ]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                detectorStore.updateDetectors(floorPlanId, response.data.results);
                const downloaded = downloadCount.current + response.data.results.length;
                downloadCount.current = downloaded;
                total.current = response.data.count;
                if (downloaded < response.data.count) {
                    getDetectorPage(floorPlanId, downloaded, PAGE_LIMIT);
                } else {
                    setStatus(SUCCESS);
                }
            } catch (error) {
                setStatus(ERROR);
                console.error('useGetFloorPlanDevices error: ', error);
            }
        }
    }, [ responseStatus, response, floorPlanId, getDetectorPage, detectorStore ]);

    return [ status, getFloorPlanDetectors ];
};
