import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
import {setDocumentTitle} from '../../../utils/utils';
import SiteSelector from '../../../components/SiteSelector/SiteSelector';
import MIPageTitle from '../../../components/Widgets/MIPageTitle';
import Recommendations from '../../../components/Recommendations/Recommendations';
import {ResponsiveComponent, useResponsive} from '../../../services/useResponsive';

const desktopStyles = theme => {
    return {
        root: {
            width: '100',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
    };
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: ['calc(100vh - ', theme.heights.recommendation_detail_min_height, ')'].join(''),
            paddingBottom: theme.sizes.large_gap,
        },
    })
};

const RecommendationsPage = () => {
    const {t} = useTranslation();
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_recommendations');
    }, [t]);

    return (
        <Paper className={classes.root} aria-label={t('aria_label_recommendations_page')}>
            <SiteSelector/>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop:
                        <MIPageTitle
                            title={t('recommendations')}
                            ariaLabel={t('aria_label_recommendations')}
                            marginTop={8}
                            marginBottom={0}
                        />
                }} />
            <Recommendations />
        </Paper>
    );
};

export default RecommendationsPage;
