import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {observer} from "mobx-react-lite";
import {useSiteStore} from "../../contexts/StateProvider/StateProvider";
import {ResponsiveComponent, useResponsive} from "../../services/useResponsive";
import BackgroundImage from '../../assets/landing_banner_small_desktop.png';
import SelectedSiteLinkMobile from "./variant/SelectedSiteLinkMobile";
import SelectedSiteLinkDesktop from "./variant/SelectedSiteLinkDesktop";
import SiteSelectorAutocompleteDesktop from "./variant/SiteSelectorAutocompleteDesktop";
import SiteSelectorAutocompleteMobile from "./variant/SiteSelectorAutocompleteMobile";

const desktopStyles = theme => {
    return {
        searchIcon: {
            color: theme.palette.grey['400'],
            cursor: 'text',
            position: 'relative',
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        container: {
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: 'cover',
        },
    });
};

const SiteSelector = observer(() => {
    const [open, setOpen] = useState(false);
    const siteStore = useSiteStore();
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();


    const toggleOpen = () => {
        if (open) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }

    const searchIcon = () => {
        return (
            <InputAdornment position='start'>
                <SearchIcon className={classes.searchIcon} onClick={toggleOpen}/>
            </InputAdornment>
        );
    }

    const params = {
        searchIcon
    };

    return (
        <div className={classes.container}>
            <ResponsiveComponent
                components={{
                    mobile: <SelectedSiteLinkMobile selectedSite={siteStore.selectedSite}/>,
                    desktop: <SelectedSiteLinkDesktop selectedSite={siteStore.selectedSite}/>,
                }}
            />
            <ResponsiveComponent
                components={{
                    mobile: <SiteSelectorAutocompleteMobile {...params} />,
                    desktop: <SiteSelectorAutocompleteDesktop {...params} />
                }}
            />
        </div>
    )

})

export default SiteSelector;
