import React from "react";
import { Controller } from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {useResponsive} from '../../services/useResponsive';

const desktopStyles = (theme) => {
    return {
        option: {
            height: theme.sizes.xxxlarge_gap,
            display: 'flex',
            alignItems: 'center',
        },
        optionInner: {
            marginTop: theme.sizes.nano_gap,
            color: theme.palette.text.primary,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
        },
        formField: {
            display: 'flex',
            width: '221px',
            height: theme.sizes.xxxlarge_gap,
            '& fieldset': {
                borderRadius: '0px',
                border: theme.borders.listBorders,
            },
        },
        listbox: {
            borderRadius: '0px',
            border: theme.borders.listBorders,
            borderWidth: '1px',
            padding: '0px',
        },
    }
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        text: {
            fontFamily: theme.fontFamily.proximaMedium,
            fontSize: theme.fontSizes.mobileBody
        },
    });
};

const MIDropDown =  ({options = [], ariaLabel, dropDownProps, disableClearable, ...props }) => {
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();
    return (
        <Controller
            render={({ onChange, ...props }) => (
                <Autocomplete
                    className={classes.formField}
                    aria-label={ariaLabel}
                    options={options}
                    classes={{ listbox: classes.listbox, option: classes.option, paper: classes.text }}
                    popupIcon={<KeyboardArrowDownIcon />}
                    handleHomeEndKeys
                    getOptionLabel={(option) => option.label}
                    getOptionSelected={(option, value) => option.value === value.value}
                    onChange={(e, data) => onChange(data)}
                    disableClearable={disableClearable}
                    {...props}
                    {...dropDownProps}
                />
            )}
            onChange={([, data]) => data}
            rules={{ required: true }}
            {...props}
        />
    )
}

export default MIDropDown;