import React from 'react';
import {FETCHING} from "../../services/requests/requestReducer";
import MISpinner from "./MISpinner";


const MIApiLoader = (props) => {
    if (props.responseStatus === FETCHING) {
        return (<MISpinner customStyle={props.customStyle} ariaLabelProgressBar={props.ariaLabelProgressBar}/>);
    } else {
        return (props.MIComponent);
    }
};

export default MIApiLoader;