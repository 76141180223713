import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const getWidth = (props) => {
    switch(props.mi_size){
        case 'fit':
            return 'fit-content';
        case 'large':
            return '208px';
        default: 
            return '128px'; 
    }
}

const useStyles = makeStyles(theme => ({
    miButton: {
        textTransform: 'none',
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        display: 'inline-block',
        height: '48px',
        width: props => getWidth(props),
        '&.Mui-focusVisible': {
            boxShadow: 'theme.palette.primary.main 0px 1px 9px 1px',
        },
        '&.MuiButton-outlinedPrimary': {
            borderColor: '#CDD2D5',
            '&:hover': {
                background: '#fff',
                boxShadow: 'rgba(205, 210, 213, 1) 0px 2px 5px 1px',
            },
            '&.Mui-focusVisible': {
                borderColor: '#007DC5',
            },
        },
        '&.MuiButton-contained.Mui-disabled': {
            color: '#354A55',
        },
        '&:focus': theme.ariaFocus,
    },
    miGhost: {
        textTransform: 'none',
        marginTop: theme.sizes.small_gap,
        fontFamily: theme.typography.h1.fontFamily,
        fontSize: theme.typography.body1.fontSize,
        display: 'inline-block',
        height: '48px',
        width: props => getWidth(props),
        color: theme.palette.common.white,
        borderColor: theme.palette.common.white,
        '&.Mui-focusVisible': {
            boxShadow: 'rgba(255, 255, 255, 0.5) 0px 3px 10px 1px',
        },
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 2px 5px 1px',
        },
    },
}));

const MIButton = ({children, className, ariaLabel, ...props}) => {
    ariaLabel = ariaLabel ? ariaLabel : '';
    const classes = useStyles(props);
    let miClassName = classes.miButton;
    let buttonProps = {};
    switch (props.mi_type) {
        case 'primary':
        default:
            buttonProps = {
                disableFocusRipple: true,
                variant: 'contained',
                color: 'primary',
            }
            break;
        case 'secondary':
            buttonProps = {
                disableFocusRipple: true,
                variant: 'outlined',
                color: 'primary',
            }
            break;
        case 'ghost':
            miClassName = classes.miGhost;
            buttonProps = {
                disableFocusRipple: true,
                variant: 'outlined',
            }
            break;
        case 'disabled':
            buttonProps = {
                disabled: true,
                variant: 'contained',
            }
            break;
    }

    let compoundClassName = className ? miClassName + ' ' + className : miClassName;
    return (
        <Button
            className={compoundClassName}
            aria-label={ariaLabel}
            {...buttonProps}
            {...props}
        >
            {children}
        </Button>
    )
}

export default MIButton;