import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import OpenReport from "./OpenReport";

const useStyles = makeStyles(theme => ({
    actionIcon: {
        fill: theme.palette.primary.main,
        width: theme.sizes.large_gap,
        cursor: 'pointer',
        marginRight: '5px',
    },
    actionIconOuter: {
        alignItems: 'center',
        display: 'flex',
        '&:focus': theme.ariaFocus,
    },
}));


const CopyReport = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {reportTitle} = props;

    return (
        <OpenReport
            component={
                <div
                    className={classes.actionIconOuter}
                    tabIndex={0}
                >
                    <FileCopyOutlinedIcon
                        data-testid={'copy_icon'}
                        className={classes.actionIcon}
                    />
                   {t('copy_report')}
                </div>
            }
            newReportTitle={t('copy_of')+reportTitle}
            ariaLabel={t('aria_label_create_copy_button')}
            ariaLabelProgressBar={t('aria_label_progressbar_copy_report')}
            {...props}
        />

    );
};

export default CopyReport;
