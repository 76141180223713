import {useState, useEffect, useCallback} from 'react';
import useApiRequest from "./requests/useApiRequest";
import {SUCCESS} from "./requests/requestReducer";

export const useDeleteReport = () => {
    const [deleteStatus, setDeleteStatus] = useState('');
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/reportService/deleteReport';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            setDeleteStatus(response.data.status);
        }
    }, [responseStatus, response, setDeleteStatus]);

    const deleteReport = useCallback((countryCode, reportId, locale) => {
        //reset the status before we make the API call so that the caller can detect a change if the response is the same
        setDeleteStatus()
        const url = endpoint + '/' + countryCode + '/' + reportId + '/' + locale;
        makeRequestWithParams(url, 'delete').catch(error => {
            console.error('useDeleteReport error: ', error);
        });
    }, [makeRequestWithParams]);

    return [deleteStatus, responseStatus, deleteReport];

};