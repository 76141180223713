import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIcon from '@material-ui/icons/Assignment';
import WarningIcon from '@material-ui/icons/Warning';
import {useFlags} from "../../contexts/FlagsProvider/FlagsProvider";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {getRouteIndex} from "../../utils/utils";
import {isEmpty, getTopRoute} from '../../utils/utils'

const useStyles = makeStyles(theme => ({
    sticky: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        borderTop: '1px solid' + theme.palette.grey['300'],
    },
}));

const FooterStickyMenu = () => {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [value, setValue] = useState(-1);
    const {feature_hazardous_waste_consignment_notes,
        feature_hazardous_waste_portugal,
        feature_hazardous_waste_transfer_notes} = useFlags();

    const navigation = [
        {path: '/', label: t('nav_home'), ariaLabel: t('aria_label_homepage_tab'), icon: <HomeIcon/>},
        {path: '/service', label: t('nav_service'), ariaLabel: t('aria_label_service_tab'), icon: <AssignmentIcon/>},
    ];

    if (feature_hazardous_waste_consignment_notes ||
        feature_hazardous_waste_portugal ||
        feature_hazardous_waste_transfer_notes) {
        navigation.push(
            {path: '/waste', label: t('nav_waste'), ariaLabel: t('aria_label_waste_tab'), icon: <WarningIcon/>}
        );
    }

    const currentRoute = getTopRoute(navigation,location.pathname);
    
    useEffect(() => {
        const index = getRouteIndex(location.pathname, navigation);
        setValue(index);
    }, [location.pathname, navigation])

    const navItems = () => {
        return navigation.map((route) => (
            <BottomNavigationAction
                key={route.path}
                label={route.label}
                aria-label={route.ariaLabel}
                icon={route.icon}
            />
        ))
    }

    return (
        (!isEmpty(currentRoute) && navigation.some(route => route.path === currentRoute.path)) && 
            <BottomNavigation
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    history.push(navigation[newValue].path);
                }}
                showLabels
                className={classes.sticky}
            >
                {navItems()}
            </BottomNavigation> 
    )
}

export default FooterStickyMenu;
