import React, {useEffect, useState, useCallback, useRef} from 'react';
import { useTranslation } from "react-i18next";
import { useForm, Controller } from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import MITextField from "../../components/Widgets/MITextField";
import MIDropDown from "../../components/Widgets/MIDropDown";
import MIDateRangePicker from '../../components/Widgets/MIDateRangePicker';
import MIRadioButton from "../../components/Widgets/MIRadioButton";
import SiteGrid from "../../components/SiteSelector/SiteGrid";
import {Typography} from "@material-ui/core";
import ScheduleIcon from '@material-ui/icons/Schedule';
import MIAlert from "../../components/Widgets/MIAlert";
import MIDatePicker from "../../components/Widgets/MIDatePicker";
import MIButton from "../../components/Widgets/MIButton";
import moment from "moment";
import {useVisitType} from '../../services/useVisitType';
import {useAuthState} from "../../contexts/AuthProvider";
import { getLocalizedDateFormatString, isEmpty } from '../../utils/utils';
import Theme from "../../components/Theme/Theme";
import isEmail from 'validator/lib/isEmail';
import {EDIT_REPORT, GENERATE_REPORT, useSaveReport} from "../../services/useSaveReport";
import MIApiLoader from "../../components/Widgets/MIApiLoader";
import {observer} from "mobx-react-lite";
import {useSiteStore } from '../../contexts/StateProvider/StateProvider';
import {useResponsive} from '../../services/useResponsive';

const desktopStyles = (theme) => {
    return {
        textFieldLabel: {
            display: 'flex',
            marginBottom: theme.sizes.micro_gap,
            fontFamily: theme.fontFamily.proximaMedium,
            fontWeight: 600,
        },
        textFooter: {
            marginBottom: theme.sizes.small_gap,
        },
        container: {
            width: '448px',
        },
        extraTopMargin: {
            marginTop: theme.sizes.large_gap,
        },
        orText: {
            marginTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.small_gap,
        },
        siteGridContainer: {
            marginTop: theme.sizes.xlarge_gap,
        },
        error: {
            fontSize: theme.typography.small_error.fontSize,
            fontFamily: theme.fontFamily.proximaMedium,
            marginTop: theme.sizes.pico_gap,
            marginLeft: theme.sizes.no_gap,
            marginRight: theme.sizes.no_gap,
            color: theme.palette.error.dark,
        },
        root: {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderWidth: '1px',
                boxShadow: 'rgba(0,125,197,0.25) 0px 1px 9px 1px',
             },
             '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.error.dark,
            },
        },
        buttonContainer: {
            width: '210px',
        },
    }
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        textFieldLabel: {
            ...baseStyle.textFieldLabel,
            fontSize: theme.fontSizes.mobileBody
        },
        formField: {
            width: '100%',
        },
        siteGridContainer: {
            marginTop: theme.sizes.micro_gap,
        },
        error: {
            ...baseStyle.error,
            fontSize: theme.fontSizes.mobileLabel,
        },
        buttonContainer: {
            width: '100%',
        },
        extraTopMargin: {
            marginTop: theme.sizes.medium_gap,
        },
        textFooter: {
            marginBottom: theme.sizes.micro_gap,
        },
        noMarginBottom: {
            marginBottom: theme.sizes.planck_gap
        },
        inputText: {
            fontFamily: theme.fontFamily.proximaMedium,
            fontSize: theme.fontSizes.mobileBody,
        }
    });
};

const REPORT_SAVED = 'success';
const SCHEDULED_REPORT_SAVED = 'SCHEDULED_REPORT_SAVED';
const REPORT_NO_DATA = 'NO_DATA';
const REPORT_FAILURE = 'failure';

const NewReportForm = observer((props) => {
    const {report, action, clearInput} = props;
    const {t} = useTranslation();
    const {locale, countryCode, emailAddress} = useAuthState();
    const [visitTypes, getVisitTypes] = useVisitType();
    const [saveStatus, responseStatus, generateReport, saveEditedReport] = useSaveReport();
    const [isShowing, setIsShowing] = useState(false);
    const [alert, setAlert] = useState({});
    const { handleSubmit, register, control, watch, reset, setValue, formState: { errors } } = useForm();
    const siteStore = useSiteStore();
    const visitTypeRef = useRef();
    const visitSignatureRef = useRef();
    const reportFormatRef = useRef();
    const isScheduledRef = useRef();
    const timePeriodRef = useRef();
    const scheduledStartDateRef = useRef();
    const minDate = moment("01-01-2000", "DD-MM-YYYY");
    const maxDate = moment("01-01-2030", "DD-MM-YYYY");
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles(errors);


     // current behaviour in the old myInitial
    const scheduleMinDate = moment().add(1, 'days');
    
    const showAlert = useCallback((saveStatus) => {
        if (saveStatus === REPORT_SAVED) {
            setAlert({
                message: 'save_report_dialog_report_saved_and_sent',
                severity: 'success'
            })
        } else if (saveStatus === SCHEDULED_REPORT_SAVED) {
            //report saved and scheduled for a further date
            setAlert({
                message: 'save_report_dialog_report_saved_and_scheduled',
                severity: 'success'
            })
        } else if (saveStatus === REPORT_NO_DATA) {
            //this is not a failure status. this status means that the report has been saved but
            // is not linked with any data at the moment
            setAlert({
                message: 'save_report_dialog_success_and_no_data',
                severity: 'success'
            })
        } else {
            setAlert({
                message: 'save_report_dialog_error',
                secondaryMessage: 'save_report_dialog_error_secondary',
                severity: 'error'
            })
        }
        setIsShowing(true);
    }, []);

    const resetValues = useCallback(() => {
        reset({
                reportTitle: null,
                timePeriod: null,
                visitType: null,
                signatureRequired: null,
                isScheduled: null,
                reportFormat: null,
                fromDate: moment().subtract({years: 1, days: 1}),
                toDate: moment(),
                scheduleFrequency: '30',
                scheduledStartDate: moment().add(1, 'days'),
                reportPremiseMappings: {rowIds: []}
            },
            {
                keepDefaultValues: true,
                keepValues: false,
                keepDirty: true
            });
    }, [reset]);

    useEffect(() => {
        if (siteStore.selectedSiteId && locale && countryCode) {
            getVisitTypes(siteStore.selectedSiteId, locale, countryCode)
        }
    }, [locale, countryCode, siteStore.selectedSiteId, getVisitTypes]);

    useEffect(() => {
        if (saveStatus) {
            showAlert(saveStatus);
            if (saveStatus !== REPORT_FAILURE) {
                //reset values only on success
                resetValues();
                clearInput();
                //scroll to the top of the page
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }, [saveStatus, showAlert, resetValues, clearInput]);

    const onSubmit = (values) => {

        if(!isEmpty(report)){
            // if we are creating a copy of a report or we are modifying an existing
            // report the API requires the reportId and reportPremiseId
            const premiseList = report.premiseList;
            values.reportPremiseId = premiseList && premiseList.length > 0 ? premiseList[0].reportPremiseId : null;
            values.reportId = report.reportId;
        }

        switch (action) {
            case EDIT_REPORT:
                saveEditedReport(values, countryCode, locale);
                break;
            case GENERATE_REPORT:
            default:
                // use generateReport as default
                generateReport(values, countryCode, locale);
                break;
        }
    };

    const timePeriodOptions = [
        {label: t('custom'), value: 'custom'},
        {label: t('last_24_hours'), value: '1_DAY'},
        {label: t('ytd'), value: 'YTD'},
        {label: t('last_1_week'), value: '1_WEEK'},
        {label: t('last_2_week'), value: '2_WEEK'},
        {label: t('last_3_week'), value: '3_WEEK'},
        {label: t('last_1_month'), value: '1_MONTH'},
        {label: t('last_2_month'), value: '2_MONTH'},
        {label: t('last_6_month'), value: '6_MONTH'},
        {label: t('last_12_month'), value: '12_MONTH'},
        {label: t('last_1_quarter'), value: '1_QUARTER'},
        {label: t('last_2_quarter'), value: '2_QUARTER'},
        {label: t('last_1_visit'), value: '1_VISIT'},
        {label: t('last_2_visit'), value: '2_VISIT'},
        {label: t('last_3_visit'), value: '3_VISIT'},
        {label: t('last_6_visit'), value: '6_VISIT'},
        {label: t('last_9_visit'), value: '9_VISIT'},
        {label: t('last_12_visit'), value: '12_VISIT'}
    ];
    const reportScheduleOptions = [
        {label: t('daily'), value: '1'},
        {label: t('weekly'), value: '7'},
        {label: t('monthly'), value: '30'},
        {label: t('quarterly'), value: '90'}
    ];
    const visitSignatureOptions = [
        {label: t('all'), value: 'ALL'},
        {label: t('with_signature'), value: 'WITH'},
        {label: t('without_signature'), value: 'WITHOUT'}
    ];
    const reportWhenOptions = [
        {label: t('one_off'), value: 'N'},
        {label: t('scheduled'), value: 'Y'}
    ];
    const reportFormatOptions = [
        {label: t('excel'), value: 'xls'},
        {label: t('pdf'), value: 'pdf'}
    ];

    const visitTypeOptions = useCallback(() => {
        if (! isEmpty(visitTypes)) {
            return visitTypes.map(x => {return {label: x.VisitTypeDesc, value: x.VisitTypeCode}});
        }
    },[visitTypes]);

    const getOption = (list, value) => {
        let element_found = null;
        if (!isEmpty(list) && !isEmpty(value)) {
            list.forEach((element) => {
                if (element.value === value.trim()) {
                    element_found = element;
                }
            })
        }
        return element_found;
    };

    useEffect(() => {
        if (!isEmpty(visitTypes) && !isEmpty(report.visitType)) {
            setValue('visitType', getOption(visitTypeOptions(), report.visitType))
        }
    }, [visitTypes, setValue, report.visitType, visitTypeOptions]);

    const reportTitleError = (error) => {
        return (
            error?.type === 'required' && t('report_name_required')
        ) || (
            error?.type === 'validate' && t('characters_invalid')
        ) || (
            error?.type === 'maxLength' && t('maximum_length')
        )
    };

    const sentToEmail = (error) => {
        return (
            error?.type === 'required' && t('addressed_to_required')
        ) || (
            error?.type === 'validate' && t('email_invalid')
        ) || (
            error?.type === 'maxLength' && t('maximum_length')
        )
    };

    const timePeriod = watch('timePeriod');
    if (timePeriod) {
        const currentTimePeriod = getOption(timePeriodOptions, timePeriod.value);
        if (timePeriod.label !== currentTimePeriod.label) (
            setValue('timePeriod', currentTimePeriod)
        )
    }

    const signatureRequired = watch('signatureRequired');
    if (signatureRequired) {
        const currentSignatureRequired = getOption(visitSignatureOptions, signatureRequired.value);
        if (signatureRequired.label !== currentSignatureRequired.label) (
            setValue('signatureRequired', currentSignatureRequired)
        )
    }
    const onError = (values) => {
        if (!isEmpty(values)) {
            let objectRef = null
            //these errors are the top two fields, and the scroll already works for them
            if (!values.reportTitle && !values.sentToEmail) {
                //We need these statements to make sure we go to the highest validation error on the form
                if (values.timePeriod || values.toDate || values.fromDate) {
                    objectRef = timePeriodRef
                } else if (values.visitType) {
                    objectRef = visitTypeRef
                } else if (values.signatureRequired) {
                    objectRef = visitSignatureRef
                } else if (values.isScheduled) {
                    objectRef = isScheduledRef
                } else if (values.scheduledStartDate) {
                    objectRef = scheduledStartDateRef
                } else if (values.reportFormat) {
                    objectRef = reportFormatRef
                } 
            }
            if (objectRef) {
                objectRef.current.scrollIntoView({block: 'center'})
            }
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <div className={classes.textFooter}>
                    <label className={classes.textFieldLabel}> {t('report_name')} </label>
                    <MITextField
                        className={classes.formField}
                        InputProps={{classes: {input: classes.inputText, root: classes.root}}}
                        inputRef={register({
                            required: true,
                            maxLength: 40,
                            validate: value => value === value.replace(/[.,/#?!$£€§±<>%^&*;:{}=\-_`~()]/g,'')
                        })}
                        placeholder={t('default_name')}
                        ariaLabel={t('aria_report_time_period')}
                        name="reportTitle"
                        error={errors.reportTitle?.type === 'required' ||
                               errors.reportTitle?.type === 'validate' ||
                               errors.reportTitle?.type === 'maxLength'}
                        helperText={reportTitleError(errors.reportTitle)}
                        defaultValue={!isEmpty(report) ? report.reportTitle : null}
                    />
                </div>

                <div className={classes.textFooter}>
                    <label className={classes.textFieldLabel}> {t('addressed_to')} </label>
                    <MITextField
                        className={classes.formField}
                        InputProps={{classes: {input: classes.inputText, root: classes.root}}}
                        inputRef={register({
                            required: true,
                            maxLength: 256,
                            validate: value => isEmail(value)
                        })}
                        placeholder={t('default_addressed_to')}
                        ariaLabel={t('aria_report_addressed_to')}
                        name="sentToEmail"
                        error={errors.sentToEmail?.type === "required" ||
                               errors.sentToEmail?.type === "validate" ||
                               errors.sentToEmail?.type === 'maxLength'}
                        helperText={sentToEmail(errors.sentToEmail)}
                        defaultValue={!isEmpty(report) ? report.sentToEmail : emailAddress}
                    />
                </div>

                <div className={classes.textFooter}>
                    <label ref={timePeriodRef} className={classes.textFieldLabel}> {t('time_period')} </label>
                    <div className={classes.textFooter}>
                        <MIDropDown
                            control={control}
                            name='timePeriod'
                            ariaLabel={t('aria_report_time_period')}
                            options={timePeriodOptions}
                            dropDownProps={{ renderInput: (params) =>
                                <MITextField
                                    {...params}
                                    InputProps={{...params.InputProps, classes: {root: classes.root, input: classes.inputText}}}
                                    error={errors.timePeriod?.type === 'datePickerEmpty'}
                                    helperText={errors.timePeriod && t('error_message')}
                                    placeholder={t('please_select')}
                                />
                            }}
                            defaultValue={!isEmpty(report) ? getOption(timePeriodOptions, report.timePeriod) : null}
                            rules={{ required: false, validate: {datePickerEmpty: value => value !== null} }}
                            disableClearable={true}
                        />
                     </div>

                    {watch('timePeriod') && watch('timePeriod').value === 'custom' ?
                        <MIDateRangePicker
                            control={control}
                            watch={watch}
                            dateFormat={getLocalizedDateFormatString(t)}
                            dateFromProps={{
                                defaultValue: !isEmpty(report) && report.fromDate ? moment(report.fromDate, 'DD-MM-YYYY') : moment().subtract({
                                    years: 1,
                                    days: 1
                                }),
                                rules:{
                                    required: true, 
                                    validate: {isValidDate: 
                                        value => !isEmpty(value)
                                        && watch('toDate') 
                                        && value.startOf('day').isSameOrBefore(watch('toDate').startOf('day'))
                                        && value.startOf('day').isSameOrAfter(minDate)
                                        && value.startOf('day').isSameOrBefore(maxDate)
                                    }
                                }
                            }}
                            dateToProps={{
                                defaultValue: !isEmpty(report) && report.toDate ? moment(report.toDate, 'DD-MM-YYYY') : moment(),
                                rules:{
                                    required: true, 
                                    validate: {isValidDate: 
                                        value => !isEmpty(value)
                                        && watch('fromDate') 
                                        && value.startOf('day').isSameOrAfter(watch('fromDate').startOf('day'))
                                        && value.startOf('day').isSameOrAfter(minDate)
                                        && value.startOf('day').isSameOrBefore(maxDate)
                                    }
                                }
                            }}
                            dateFromPickerProps={{
                                InputProps: {classes: {input: classes.inputText}},
                                ...(watch('fromDate') === null || errors.timePeriod?.type === 'datePickerEmpty' ? {error: true} : {}),
                                ...(watch('fromDate') === null || errors.timePeriod ? {helperText: t('error_message')} : {})
                            }}
                            dateToPickerProps={{
                                InputProps: {classes: {input: classes.inputText}},
                                ...(watch('toDate') === null || errors.timePeriod?.type === 'datePickerEmpty' ? {error: true} : {}),
                                ...(watch('toDate') === null || errors.timePeriod ? {helperText: t('error_message')} : {})
                            }}
                        />
                    :null
                    }
                </div>

                <div className={[classes.textFooter, classes.extraTopMargin].join(' ')}>
                    <label ref={visitTypeRef} className={classes.textFieldLabel}> {t('visit_type')} </label>
                    <MIDropDown
                        control={control}
                        name='visitType'
                        ariaLabel={t('aria_report_type')}
                        options={visitTypeOptions()}
                        dropDownProps={{
                            renderInput: (params) =>
                                <MITextField
                                    {...params}
                                    InputProps={{...params.InputProps, classes: {root: classes.root, input: classes.inputText}}}
                                    error={errors.visitType?.type === 'required'}
                                    helperText={errors.visitType && t('error_message')}
                                    placeholder={t('please_select')}
                                />,
                            noOptionsText: t('no_visit_type_options')
                        }}
                        defaultValue={null}
                        disableClearable={true}
                    />
                </div>

                <div className={[classes.textFooter, classes.extraTopMargin].join(' ')}>
                    <label ref={visitSignatureRef} className={classes.textFieldLabel}> {t('visit_signature')} </label>
                    <MIDropDown
                        control={control}
                        name='signatureRequired'
                        ariaLabel={t('aria_report_signature')}
                        options={visitSignatureOptions}
                        dropDownProps={{ renderInput: (params) =>
                            <MITextField
                                {...params}
                                InputProps={{...params.InputProps, classes: {root: classes.root, input: classes.inputText}}}
                                error={errors.signatureRequired?.type === 'required'}
                                helperText={errors.signatureRequired && t('error_message')}
                                placeholder={t('please_select')}
                            />
                        }}
                        defaultValue={!isEmpty(report) ? getOption(visitSignatureOptions, report.signatureRequired) : null}
                        disableClearable={true}
                    />
                </div>

                <div className={[!(errors.isScheduled) && classes.textFooter, classes.extraTopMargin].join(' ')}>
                    <label ref={isScheduledRef} className={[classes.textFieldLabel, classes.noMarginBottom].join(' ')}> {t('report_when')} </label>
                    <MIRadioButton
                        control={control}
                        ariaLabel={t('aria_report_when')}
                        name='isScheduled'
                        options={reportWhenOptions}
                        error={errors.isScheduled?.type === 'required'}
                        defaultValue={!isEmpty(report) ? getOption(reportWhenOptions, report.isScheduled)?.value || null : null}
                    />
                    <Typography className={errors.isScheduled?.type === 'required' ? classes.error : ''}>
                        {errors.isScheduled && t('error_message')}
                    </Typography>
                </div>

                { watch('isScheduled') === 'Y' ?
                    <>
                        <div className={[classes.textFooter, classes.extraTopMargin].join(' ')}>
                            <label className={[classes.textFieldLabel, classes.noMarginBottom].join(' ')}> {t('schedule_frequency')} </label>
                            <MIRadioButton
                                control={control}
                                ariaLabel={t('aria_report_schedule_frequency')}
                                name='scheduleFrequency'
                                options={reportScheduleOptions}
                                error={errors.scheduleFrequency?.type === 'required'}
                                defaultValue={report && report.scheduleFrequency ? report.scheduleFrequency : '30'}
                            />
                            <Typography className={errors.scheduleFrequency?.type === 'required' ? classes.error : ''}>
                                {errors.scheduleFrequency && t('error_message')}
                            </Typography>
                        </div>

                        <div className={classes.textFooter}>
                            <label ref={scheduledStartDateRef} className={classes.textFieldLabel}> {t('schedule_start')} </label>
                            <MIDatePicker
                                control={control}
                                watch={watch}
                                name='scheduledStartDate'
                                label={t('start_date')}
                                ariaLabel={t('aria_report_schedule_start')}
                                dateFormat={getLocalizedDateFormatString(t)}
                                datePickerProps={{
                                    InputProps: {classes: {input: classes.inputText}},
                                    keyboardIcon: <ScheduleIcon />,
                                    minDate: scheduleMinDate,
                                    maxDate: maxDate,
                                    minDateMessage: t('min_start_date_message'),
                                    maxDateMessage: t('invalid_date'),
                                    KeyboardButtonProps: { 'aria-label': t('start_date') },
                                    ...(watch('scheduledStartDate') === null && errors.scheduledStartDate?.type === 'required' ? { error: true } : {}),
                                    ...(watch('scheduledStartDate') === null && errors.scheduledStartDate ? { helperText: t('error_message') } : {})
                                }}
                                rules={{ required: true, validate: {isInFuture: value => !isEmpty(value) && value.startOf('day').isAfter(moment().startOf('day'))}} }
                                defaultValue={!isEmpty(report) && report.scheduledStartDate ? moment(report.scheduledStartDate, 'DD-MM-YYYY') : scheduleMinDate }
                            />
                        </div>
                    </>
                : null}

                <div className={!errors.reportFormat? classes.textFooter:''}>
                    <label ref={reportFormatRef} className={[classes.textFieldLabel, classes.noMarginBottom].join(' ')}> {t('report_format')} </label>
                    <MIRadioButton
                        control={control}
                        ariaLabel={t('aria_report_format')}
                        name='reportFormat'
                        options={reportFormatOptions}
                        error={errors.reportFormat?.type === 'required'}
                        defaultValue={!isEmpty(report) ? getOption(reportFormatOptions, report.reportFormat)?.value || null : null}
                    />
                    <Typography className={errors.reportFormat?.type === 'required' ? classes.error : ''}>
                        {errors.reportFormat && t('error_message')}
                    </Typography>
                </div>

                <div className={classes.textFooter}>
                    <label className={classes.textFieldLabel}> {t('select_site')} </label>
                    <div className={classes.siteGridContainer}>
                        <Controller
                            render={({ref, onChange, ...props}) => (
                                <SiteGrid
                                    inputRef={ref}
                                    aria-label={t('aria_report_select_site')}
                                    hideLastVisit={true}
                                    hideVisitHistoryLink={true}
                                    checkboxSelection={true}
                                    autoHeight={false}
                                    height='530px'
                                    rowHeight={55}
                                    heightToFitContent={true}
                                    showRowCount={false}
                                    mobilePageSize={5}
                                    pageSize={5}
                                    hideFooter={false}
                                    pagination={true}
                                    filterMargin={Theme.sizes.micro_gap}
                                    onChange={onChange}
                                    headerHeight={getStyles({
                                        desktop: undefined,
                                        mobile: 30
                                    })}
                                    error={errors.reportPremiseMappings?.type === 'isNotEmptyArray'}
                                    usePreSelection={true}
                                    value={props.value}
                                    onRowSelected={() => {}}
                                />
                            )}
                            name='reportPremiseMappings'
                            control={control}
                            defaultValue={{rowIds: report && report.premiseList? report.premiseList.reduce((obj, item) => ([...obj, item.premiseIds]), []) : []}}
                            rules={{ validate: {isNotEmptyArray: value => value.rowIds.length !== 0}} }
                        />
                        <Typography className={errors.reportPremiseMappings?.type === 'isNotEmptyArray' ? classes.error : ''}>
                            {errors.reportPremiseMappings && t('error_message')}
                        </Typography>
                    </div>
                </div>

                <div className={classes.buttonContainer}>
                    <MIApiLoader
                        customStyle={{
                            spinnerWidth: '30px',
                            spinnerHeight: '30px',
                            marginTop: '2px',
                            marginLeft: '2px',
                            marginRight: '2px'
                        }}
                        MIComponent={<MIButton
                            className={classes.buttonContainer}
                            ariaLabel={t('aria_report_save')}
                            mi_size='large'
                            mi_type='primary'
                            type='submit'>
                            {t('save_report')}
                        </MIButton>}
                        responseStatus={responseStatus}
                        ariaLabelProgressBar={t('aria_label_progressbar_new_report')}
                    />
                </div>
            </form>

            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                secondaryMessage={t(alert.secondaryMessage)}
                severity={alert.severity}
                ariaLabel={t('aria_success_message')}/>

        </>
    );
});

export default NewReportForm;
