import { makeAutoObservable } from "mobx";
import { SUCCESS } from "../../services/requests/requestReducer";

const MIN_INTERVAL = 60 * 1000;

class HealthStatusStore {
    healthStatus = SUCCESS;
    lastStatusUpdate;

    constructor() {
        makeAutoObservable(this);
    }

    getHealthStatus() {
        return this.healthStatus;
    }

    setHealthStatus(status) {
        this.healthStatus = status;
        this.lastStatusUpdate = Date.now();
    }

    checkHealthStatus() {
        const elapsed = this.lastStatusUpdate ?
            Date.now() - this.lastStatusUpdate : MIN_INTERVAL;
        return elapsed >= MIN_INTERVAL;
    }

    reset(){
       this.healthStatus = SUCCESS;
    }
}

export {HealthStatusStore};