import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";
import {mergeStyles} from "../../utils/utils";

const useStyles = makeStyles(theme => ({
    tableCellTruncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const MIToolTip = (props) => {
  const classes = useStyles();
  const {value, placement, spanParams={}} = props;
  const {className='', ...params} = spanParams;

  return (
    <Tooltip title={value} placement={placement ? placement : 'right'} aria-label={value}>
        <span className={mergeStyles(classes.tableCellTruncate, className)} {...params}>{value}</span>
    </Tooltip>
  );
};

export default MIToolTip;
