import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MIGrid from '../../Widgets/MIGrid';
import {isEmpty, mergeStyles} from '../../../utils/utils';
import {observer} from 'mobx-react-lite';
import {useFlags} from '../../../contexts/FlagsProvider/FlagsProvider';
import { FETCHING } from '../../../services/requests/requestReducer';

const useStyles = makeStyles(theme => ({
    mobileGridHeader: {
        fontSize: theme.fontSizes.mobileBody,
        fontFamily: theme.fontFamily.proximaMedium,
        marginLeft: theme.sizes.micro_gap,
        borderBottom: theme.borders.mainContent,
        marginTop: theme.sizes.small_gap,
    },
    productsGridContainer: {
        "& div.MuiDataGrid-cell": {
            padding: theme.sizes.no_gap,
            paddingRight: theme.sizes.micro_gap,
            whiteSpace: 'normal',
        },
        marginBottom:theme.sizes.large_gap,
    },
    dataCell: {
        marginLeft: theme.sizes.no_gap,
        marginRight: theme.sizes.tiny_gap,
        lineHeight: 1,
        width: '95%',
    },
    noVisitDetails: {
        marginLeft: theme.sizes.micro_gap,
        marginRight: theme.sizes.micro_gap,
        marginTop: theme.sizes.small_gap,
        color: theme.colors.noData,
        fontSize: theme.sizes.small_gap,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',
        '&:focus': theme.ariaFocus,
    },
    infoCard: {
        display: 'flex',
        flexDirection: 'column',
    },
    hazardousWasteInfoCardContents: {
        flexGrow: 1,
        width: '100%',
    },
    infoGrids: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '-' + theme.sizes.micro_gap,
        marginBottom: theme.sizes.small_gap,
    },
    signatureColumn: {
        fontWeight: 'bold',
        '&:focus': theme.ariaFocus,
        marginLeft: theme.sizes.micro_gap,
        marginTop: theme.sizes.small_gap,
    },
    cellLabel: {
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileBody,
    },
    cellValue: {
        fontFamily: theme.fontFamily.proximaMedium,
        fontSize: theme.fontSizes.mobileBody,
        marginLeft: '2px',
    },
    ewcCode: {
        display: 'inline-flex',
        float: 'right',
    },
    quantityServiced: {
        display: 'inline-flex',
    },
    hazWasteType:{
        display: 'inline-flex',
        paddingBottom: theme.sizes.micro_gap,
    },
    header: {
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: '20px',
    },
    productValue: {
        lineHeight: '1'
    },
    heading: {
        display: 'inline-block',
        marginLeft: theme.sizes.micro_gap,
        marginRight: theme.sizes.micro_gap,
    },
    hazardousWasteHeadingAndContents: {
        marginBottom: theme.sizes.small_gap,
    },
    visitDetailsContents:{
        marginBottom: theme.sizes.xxlarge_gap,
    },
}));

const VisitDetailsMobile = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {feature_hazardous_waste_consignment_notes} = useFlags();

    const {buttons, signature, visit, infoGrid, hazWasteInfoGrid, gridRows, showHazardousWaste, responseStatus} = props;

    const getRenderCell = (params) => {
        return (
            <div className={classes.dataCell}>
                <div className={classes.quantityServiced}>
                    <Typography className={classes.cellLabel}>{t('quantity')}:</Typography>
                    <Typography className={classes.cellValue}>{params.row.quantityServiced}</Typography>
                </div>
                {feature_hazardous_waste_consignment_notes && showHazardousWaste ?
                    <div className={classes.ewcCode}>
                        <Typography className={classes.cellLabel}>{t('ewc_code')}:</Typography>
                        <Typography className={classes.cellValue}>{isEmpty(params.row.ewcCode) ? '-' : params.row.ewcCode}</Typography>
                    </div> :
                    ''
                }
                <Typography className={classes.productValue} variant='body1'>{params.row.productServiced}</Typography>
                {feature_hazardous_waste_consignment_notes && showHazardousWaste ?
                    <div className={classes.hazWasteType}>
                        <Typography className={classes.cellLabel}>{t('waste_type')}:</Typography>
                        <Typography className={classes.cellValue}>{isEmpty(params.row.hazardousWasteType) ? '-' : params.row.hazardousWasteType}</Typography>
                    </div> :
                    ''
                }
            </div>
        );
    }

    const mobileColumns = [{
        field: 'productsAndQuantity', 
        headerName: [t('quantity'), t('product')].join('/'),
        headerClassName: classes.mobileGridHeader,
        type: 'string', 
        resizable: false, 
        flex: 1,
        renderCell: (params) => getRenderCell(params),
    }];

    const getInfoCard = () => {
        return (
            <div className={classes.infoCard}>
                {infoGrid}
                <Typography className={classes.signatureColumn}>{t('signature')}: </Typography>
                {signature}
            </div>
        );
    }

    const getHazWasteInfoCard = () => {
        return(
            <div className={classes.hazardousWasteHeadingAndContents}>
                <span 
                    className={mergeStyles(classes.header, classes.heading)}
                    tabIndex={0} 
                    aria-label={t('aria_label_hazardous_waste')}
                >
                    {t('hazardous_waste_collection_information')}
                </span>
                <div className={classes.hazardousWasteInfoCardContents}>
                    {hazWasteInfoGrid}
                </div>
            </div>
        );
    }

    const getProductServicedTable = () => {
        return (
            <div className={classes.heading}>
                <span 
                    className={classes.header} 
                    tabIndex={0} 
                    aria-label={t('aria_label_products_serviced')}
                >
                    {t('products_serviced')}
                </span>
                <div className={classes.productsGridContainer}>
                    <MIGrid
                        showInfoModal={false}
                        heightToFitContent={true}
                        columns={mobileColumns}
                        height={'fit-content'}
                        rows={gridRows}
                        rowHeight={showHazardousWaste ? 80 : 50}
                        noElementsText={t('no_product_serviced')}
                        ariaLabel={t('aria_label_visit_details_table')}
                        ariaLabelProgressBar={t('aria_label_progressbar_visit_details_table')}
                        noEvenRowStyling={true}
                        hideFooterForOnePage={true}
                        headerHeight={0}
                        hasFilters={false}
                        hideFooter={true}
                        isLoading={responseStatus === null || responseStatus === FETCHING}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={classes.visitDetailsContents}>
            {buttons}
            {isEmpty(visit) || isEmpty(visit.visitDetails) ?
                <div className={classes.noVisitDetails} tabIndex={0}>{t('no_visit_details')}</div> :
                <div>
                    {getInfoCard()}
                    <div className={classes.infoGrids}>
                        {feature_hazardous_waste_consignment_notes && showHazardousWaste && getHazWasteInfoCard()}
                        {getProductServicedTable()}
                    </div>
                </div>
            }
        </div>
    );
});

export default VisitDetailsMobile;
