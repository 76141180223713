import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { setDocumentTitle } from '../../utils/utils';
import { useResponsive, ResponsiveComponent } from '../../services/useResponsive';
import MIPageTitle from '../../components/Widgets/MIPageTitle';
import NotificationTable from '../../components/NotificationTables/NotificationTable';
import { useMarkAllAsRead } from '../../services/notifications/useMarkAllAsRead';
import Link from '@material-ui/core/Link';
import { Typography } from '@material-ui/core';

const desktopStyles = (theme) => {
    return {
        root: {
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        link: {
            'color': theme.palette.primary.main,
            'textDecoration': 'none',
            ':hover': { textDecoration: 'none' },
            ':visited': { textDecoration: 'none' },
            ':active': { textDecoration: 'none' },
            '&:focus': {
                outline:'none'
            },
        },
        headerContainer: {
            'display': 'flex',
            '& .MuiTypography-root': {
                marginLeft: 'auto',
                marginTop: 'auto',
                marginBottom: 'auto',
            }

        },
        markAllAsRead: {
            'alignContent': 'flex-end',
            'textDecoration': 'none',
            'fontWeight': 'bold',
            'fontSize': theme.fontSizes.subHeading,
            '&:hover': {
                cursor: 'pointer',
            }
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: '100vh',
            paddingTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.no_gap,
            paddingBottom: theme.sizes.no_gap,
        },
        markAllAsRead: {
            marginLeft: theme.sizes.small_gap,
            fontWeight: 'bold',
            fontSize: theme.fontSizes.subHeading,
            marginBottom : theme.sizes.small_gap,
        },
    });
};

const Notifications = () => {
    const { t } = useTranslation();
    const { getStyles } = useResponsive();
    const [ , markAllAsReadResponse, markAllAsRead ] = useMarkAllAsRead();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    });

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_messages');
    }, [ t ]);

    const handleMarkAllAsRead = (event) => {
        markAllAsRead();
    };

    const getMarkAllAsReadButton = () => {
        return <Link
            className={classes.selectLink}
            onClick={handleMarkAllAsRead}
            aria-label={t('aria_label_messages_link')}
        >
            <Typography nowrap={'true'} className={classes.markAllAsRead} variant="body2">
                {t('mark_all_as_read')}
            </Typography>
        </Link>;
    };

    return (
        <Paper className={classes.root} aria-label={t('aria_label_messages_page')}>
            <ResponsiveComponent
                components={{
                    mobile: <div>{getMarkAllAsReadButton()}</div>,
                    desktop:
                        <div className={classes.headerContainer}>
                            <div>
                                <MIPageTitle title={t('messages')} ariaLabel={t('aria_label_messages_title')}/>
                            </div>
                            {getMarkAllAsReadButton()}
                        </div>
                }}
            />
            <NotificationTable
                pagination={true}
                hideFooterRowCount={true}
                markAllAsReadResponse={markAllAsReadResponse}
            />
        </Paper>
    );
};
export default Notifications;
