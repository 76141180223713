import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {Link, Grid} from '@material-ui/core';
import {observer} from 'mobx-react-lite';
import MIColoredIcon from '../../components/Widgets/MIColoredIcon';
import theme from '../Theme/Theme';
import {ResponsiveComponent, useResponsive} from '../../services/useResponsive';
import RecommendationDetailsMobile from './variant/RecommendationDetailsMobile';
import RecommendationDetailsDesktop from './variant/RecommendationDetailsDesktop';
import UpdateStatusModal from './UpdateStatusModal';
import { setDocumentTitle, mergeStyles, getLocalizedDate } from '../../utils/utils';
import {useAuthState} from '../../contexts/AuthProvider';
import {useGetRecommendationDetails} from '../../services/recommendations/useGetRecommendationDetails';
import {isEmpty} from '../../utils/utils';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {useFlags} from '../../contexts/FlagsProvider/FlagsProvider';
import DownloadRecommendations from './DownloadRecommendations';

const useStyles = makeStyles(theme => ({
    statusLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        display: 'flex',
        fontFamily: theme.fontFamily.proximaBold,
        '&:hover': {
            textDecoration: 'none',
        }
    },
    statusLinkDesktop: {
        fontSize: theme.fontSizes.subHeading,
        marginTop: theme.sizes.tiny_gap,
        marginBottom: ['-', theme.sizes.planck_gap].join(''),
    },
    statusLinkMobile: {
        fontSize: theme.fontSizes.mobileBody,
        marginTop: theme.sizes.planck_gap,
    },
    recImage: {
        width: theme.widths.recommendation_image,
        marginInlineEnd: theme.sizes.tiny_gap,
        cursor: 'pointer',
    },
    imagesContainer: {
        marginTop: theme.sizes.tiny_gap
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    moreLink: {
        'color': theme.palette.primary.main,
        'textDecoration': 'none',
        'cursor': 'pointer',
        '&:focus': theme.ariaFocus,
        '&:hover': {
            textDecoration: 'underline',
        }
    },
}));

const MAX_THUMBNAILS = 5;

const RecommendationDetails = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [isShowing, setIsShowing] = useState(false);
    const {countryCode, locale} = useAuthState();
    const state = !isEmpty(props.location) ? props.location.state : {};
    const {data, myRentokilSiteId, PNOLShutdownCountries } = !isEmpty(state) ? state : {data: {}, myRentokilSiteId: '', PNOLShutdownCountries: ''};
    const {id} = data;
    const {getStyles} = useResponsive();
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const {feature_update_status} = useFlags();

    const [recommendationDetails, responseStatusDetails, getRecommendationDetails] = useGetRecommendationDetails();

    const urlExportButton = [
        '/floorPlanApi/exportRecommendationDetails',
        countryCode,
        locale,
        myRentokilSiteId,
        id,
    ].join('/');

    const getInfo = useCallback(() => {
        if (!isEmpty(countryCode) && !isEmpty(locale) && !isEmpty(id)) {
            getRecommendationDetails(countryCode, locale, id);
        }
    }, [locale, countryCode, id, getRecommendationDetails]);

    useEffect(() => {
        setDocumentTitle(t, 'nav_recommendation_details');
    }, [ t ]);

    useEffect(() => {
        if (window && window.scrollTo) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        getInfo();
    }, [getInfo]);

    const descriptionImages = !isEmpty(recommendationDetails) && !isEmpty(recommendationDetails.imageFileUrls) ? recommendationDetails.imageFileUrls.map((item) => item.signedUrl) : [];

    const openLightbox = (photo) => {
        setPhotoIndex(photo);
        setIsLightboxOpen(true);
    };

    const showImages = (images) => {
        const items = [];
        const imagesToShow = images.length > MAX_THUMBNAILS ? images.slice(0, MAX_THUMBNAILS) : images;
        imagesToShow.forEach((image, index) => {
            items.push(
                <img
                    key={index}
                    className={classes.recImage}
                    src={image}
                    alt={t('Illustration')}
                    onClick={() => {
                        openLightbox(index);
                    }}
                />
            );
        });

        const moreLink = images.length > MAX_THUMBNAILS &&
            <span
                className={classes.moreLink}
                id={'selected_site'}
                onClick={() => {
                    openLightbox(MAX_THUMBNAILS);
                }}
            >
                {`+ ${images.length - MAX_THUMBNAILS} ${t('rec_more_images')}`}
            </span>;

        return (
            <Grid container spacing={2}>
                <Grid item xs>
                    <div className={classes.imageContainer}>
                        {items}
                        {moreLink}
                    </div>
                </Grid>
            </Grid>
        );
    };

    const handleOnClick = useCallback(() => {
        setIsShowing((prevValue) => {
            return !prevValue;
        });
    }, [setIsShowing]);

    const coloredIconStyles = {
        iconMarginTop: ['-', getStyles({
            desktop: theme.sizes.planck_gap,
            mobile: theme.sizes.femto_gap
        })].join(''),
    }

    const getStatus = (currentStatus) => {
        return (
            <div>
                <MIColoredIcon item={currentStatus} styles={coloredIconStyles}/>
                {feature_update_status &&
                    <Link
                        className={mergeStyles(classes.statusLink,getStyles({
                            desktop: classes.statusLinkDesktop,
                            mobile: classes.statusLinkMobile
                        }))}
                        component='button'
                        onClick={handleOnClick}
                    >
                        {t('update_status')}
                    </Link>
                }
            </div>
        );
    };

    const getDate = (dataString) => {
        return getLocalizedDate(t, dataString.split(' ')[0].replaceAll('-', '/'), 'YYYY/MM/DD');
    };

    const getValue = (element, value) => {
        return !isEmpty(element) ? element[value] : '-';
    };

    const priority = !isEmpty(recommendationDetails) ? recommendationDetails.priority : {};
    const recommendationType = !isEmpty(recommendationDetails) ? recommendationDetails.recommendationType : {};
    const location = !isEmpty(recommendationDetails) ? recommendationDetails.location : {};
    const department = !isEmpty(location) ? location.department : {};
    const floor = !isEmpty(department) ? department.floor : {};
    const building = !isEmpty(floor) ? floor.building : {};

    const rows = [
        {
            title: t('status'),
            value: [getStatus(getValue(recommendationDetails, 'status'))]
        },
        {
            title: t('priority'),
            value: [getValue(priority, 'descriptionTranslated')]
        },
        {
            title: t('date'),
            value: [!isEmpty(recommendationDetails) && !isEmpty(recommendationDetails.localDate)? getDate(recommendationDetails.localDate).toString() : '-']
        },
        {
            title: t('reference'),
            value: [getValue(recommendationDetails, 'reference').toString()]
        },
        {
            title: t('type'),
            value: [getValue(recommendationType, 'descriptionTranslated')]
        },
        {
            title: t('location'),
            value: [getValue(building, 'description'), getValue(department, 'description'), getValue(location, 'description')]
        },
        {
            title: t('description'),
            value: [getValue(recommendationDetails, 'text')]
        },
        {
            title: '',
            value: [showImages(descriptionImages)]
        }
    ];

    const params = {
        id,
        responseStatusDetails,
        rows,
        downloadPdf: <DownloadRecommendations buttonData={[{title: t('pdf'), url: urlExportButton}]} myRentokilSiteId={myRentokilSiteId}/>,
    };

    const paramsUpdateStatus = {
        handleOnClick,
        isShowing,
        id,
        status : isEmpty(recommendationDetails) ? null : recommendationDetails.status,
        getInfo,
        PNOLShutdownCountries: PNOLShutdownCountries,
    };

    return (
        <div>
            <UpdateStatusModal {...paramsUpdateStatus}/>
            <ResponsiveComponent
                components={{
                    mobile: <RecommendationDetailsMobile {...params} {...props}/>,
                    desktop: <RecommendationDetailsDesktop {...params} {...props}/>
                }}
            />
            {isLightboxOpen && <Lightbox
                mainSrc={descriptionImages[photoIndex]}
                nextSrc={descriptionImages[(photoIndex + 1) % descriptionImages.length]}
                prevSrc={descriptionImages[(photoIndex + descriptionImages.length - 1) % descriptionImages.length]}
                onCloseRequest={() => {
                    setIsLightboxOpen(false);
                }}
                onMovePrevRequest={() => {
                    setPhotoIndex((photoIndex + descriptionImages.length - 1) % descriptionImages.length);
                }}
                onMoveNextRequest={() => {
                    setPhotoIndex((photoIndex + 1) % descriptionImages.length);
                }}
            />}
        </div>
    );
});

export default RecommendationDetails;
