import { useEffect, useState, useCallback } from 'react';
import useApiRequest from '../requests/useApiRequest';
import { SUCCESS } from '../requests/requestReducer';
import { useContractStore } from '../../contexts/StateProvider/StateProvider';
import { isEmpty } from '../../utils/utils';

export const useContractInfo = () => {
    const contractStore = useContractStore();
    const [ currentId, setCurrentId ] = useState(null);
    const [ contractInfo, setContractInfo ] = useState(null);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/contractService/contractDetail';

    useEffect(() => {
        if (responseStatus === SUCCESS && !isEmpty(currentId)) {
            try {
                contractStore.setContractDetail(currentId, response.data);
                setContractInfo(response.data);
            } catch (error) {
                console.error('useContractInfo error: ', error);
                contractStore.setContractDetail(currentId, null);
                setContractInfo(null);
            }
        }
    }, [ responseStatus, response, setContractInfo, contractStore, currentId ]);

    const getContractInfo = useCallback((contractNo, locale, countryCode) => {
        const storedContractInfo = contractStore.getContractDetail(contractNo);
        if (!isEmpty(storedContractInfo)) {
            setContractInfo(storedContractInfo);
        } else {
            setContractInfo(null);
            setCurrentId(contractNo);
            const url = [ endpoint, contractNo, countryCode, locale ].join('/');
            makeRequestWithParams(url).catch((error) => {
                console.error('useContractInfo error: ', error);
            });
        }
    }, [ makeRequestWithParams, contractStore ]);

    return [ contractInfo, contractInfo !== null ? SUCCESS : responseStatus, getContractInfo ];
};
