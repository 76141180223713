import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty } from '../../../utils/utils';
import MIApiLoader from '../../../components/Widgets/MIApiLoader';
import { FloorPlanMap } from '../../../rentokil-react-ui';
import IconPopup from './IconPopup';

const useStyles = makeStyles((theme) => {
    return {
        main: {
            flex: 1,
            background: theme.palette.background.paper,
            display: 'flex',
            flexDirection: 'column',
        },
        map: {
            height: (props) => {
                return props.size.height;
            },
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
        },
        floorPlanContainer: {
            flex: 1,
            background: 'red',
            display: 'flex',
            flexDirection: 'row',
        },
        showLabelsSwitch: {
            position: 'absolute',
            bottom: 0,
            marginInlineStart: theme.sizes.small_gap,
            marginBlockEnd: theme.sizes.nano_gap,
        }
    };
});

const MapTab = (props) => {
    const { size,
        floorPlanImage,
        detectors,
        responseStatusFloorPlanImage,
        showLabels,
    } = props;
    const classes = useStyles(props);
    const [ selectedDetector, setSelectedDetector ] = useState({});

    const handleDetectorClick = (detectorIndex) => {
        setSelectedDetector(detectors[detectorIndex]);
    };

    const getMap = () => {
        return (
            <MIApiLoader
                MIComponent={
                    <FloorPlanMap
                        image={floorPlanImage}
                        points={detectors}
                        handleDetectorClick={handleDetectorClick}
                        width={size.width}
                        height={size.height}
                        showLabels={showLabels}
                        showHeatMap={false}
                        heatMapEnabled={false}
                        showSlider={false}
                        controlBottom={15}
                    />
                }
                responseStatus={responseStatusFloorPlanImage}
            />
        );
    };

    return (
        <div className={classes.map}>
            {getMap()}
            {!isEmpty(selectedDetector) && !isEmpty(selectedDetector.id) &&
            <IconPopup
                isShowing={!isEmpty(selectedDetector.id)}
                onClose={handleDetectorClick}
                properties={selectedDetector}
            />}
        </div>
    );
};

export default MapTab;
