import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import MILinkCard from '../../components/Widgets/MILinkCard';
import MILinkCardMobile from '../../components/Widgets/mobile/MILinkCardMobile';
import { useTranslation } from 'react-i18next';
import { useAuthState } from '../../contexts/AuthProvider';
import { useGetInvoiceURL } from '../../services/useGetInvoiceURL';
import { useContractList } from '../../services/useContractList';
import { isEmpty, setDocumentTitle } from '../../utils/utils';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { observer } from 'mobx-react-lite';
import RapidImage from '../../assets/initial_rapid_smart_hygiene_icon.png';
import EbillingImage from '../../assets/billing_icon.png';
import ShoppingCartImage from '../../assets/shopping_cart_icon.png';
import medicalTrainingIcon from '../../assets/medical_training_icon.png';
import { useContentFromUrl } from '../../services/useContentFromUrl';
import MIApiLoader from '../../components/Widgets/MIApiLoader';
import MICmsContainer from '../../components/Widgets/MICmsContainer';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import { useResponsive, ResponsiveComponent } from '../../services/useResponsive';
import { useSiteList } from '../../services/useSiteList';
import MIPageTitle from '../../components/Widgets/MIPageTitle';

const desktopStyles = (theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            overflow: 'auto',
            marginBottom: theme.sizes.xlarge_gap,
        },
        link: {
            'textDecoration': 'none',
            ':hover': { textDecoration: 'none' },
            ':visited': { textDecoration: 'none' },
            ':active': { textDecoration: 'none' },
            '&:focus': {
                outline: 'none'
            },
        },
        smartHygiene: {
            marginBottom: theme.sizes.xlarge_gap
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: '100vh',
            paddingTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.no_gap,
            paddingBottom: theme.sizes.no_gap,
            marginTop: theme.sizes.no_gap,
        },
        linkCards: {
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        },
        accountTitleOuter: {
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        },
        title: {
            marginTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.xlarge_gap,
        },
        gap: {
            height: theme.sizes.large_gap,
        }
    }
    );
};

const Account = observer(() => {
    const { t } = useTranslation();
    const { user, locale, countryCode } = useAuthState();
    // make sure the site list has been populated
    // eslint-disable-next-line no-unused-vars
    const { sites } = useSiteList();

    const [ contracts, , getContracts ] = useContractList();
    const [ invoiceURL, , getInvoiceURL ] = useGetInvoiceURL();
    const emailAddress = !isEmpty(user()) ? user().email_address : '';
    const siteStore = useSiteStore();
    const siteId = siteStore.selectedSiteId;
    const {
        feature_rapid_smart_hygiene,
        feature_ebilling,
        feature_medical_training,
        feature_web_shop
    } = useFlags();
    const [ content, responseStatus, getContentFromUrl, getContentUrl ] = useContentFromUrl();
    const [ linkContent, linkResponseStatus, getLinkContentFromUrl, getLinkContentUrl ] = useContentFromUrl();
    const { webShop, rapidPortal, medicalTraining } = linkContent;
    const { getStyles } = useResponsive();

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );

    const classes = useStyles();

    useEffect(() => {
        const url = getContentUrl('smart-hygiene.html');
        const linksUrl = getLinkContentUrl('../links.json');
        getContentFromUrl(url);
        getLinkContentFromUrl(linksUrl);
    }, [ getContentFromUrl, getContentUrl, getLinkContentFromUrl, getLinkContentUrl ]);

    useEffect(() => {
        setDocumentTitle(t, 'nav_account');
    }, [ t ]);

    useEffect(() => {
        if (!isEmpty(locale) && !isEmpty(countryCode)) {
            getContracts(locale, countryCode);
        }
    }, [ getContracts, locale, countryCode ]);

    useEffect(() => {
        if (contracts && contracts.length > 0) {
            const siteIdString = !isEmpty(siteId) ? siteId.toString() : '';
            contracts.forEach((contract) => {
                contract.premiseList.forEach((premise) => {
                    if (premise.siteId === siteIdString && emailAddress && countryCode) {
                        getInvoiceURL(emailAddress, countryCode, contract.contractNo);
                    }
                });
            });
        }
    }, [ contracts, siteId, getInvoiceURL, emailAddress, countryCode ]);

    const getEbillingUrl = () => {
        if (invoiceURL === 'error') {
            return 'error';
        }
        return invoiceURL;
    };
    const getResponsiveLinkCard = (title, info, buttonAriaLabel, urlFunction, image) => {
        return (
            <ResponsiveComponent
                components={{
                    mobile: <MILinkCardMobile
                        title={t(title)}
                        info={t(info)}
                        buttonAriaLabel={t(buttonAriaLabel)}
                        url={urlFunction}
                        image={image}
                    />,
                    desktop: <MILinkCard
                        title={t(title)}
                        info={t(info)}
                        buttonAriaLabel={t(buttonAriaLabel)}
                        url={urlFunction}
                        image={image}
                    />
                }} />
        );
    };

    return (
        <Paper className={classes.root} aria-label={t('aria_label_account_page')}>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: <MIPageTitle title={t('account')} ariaLabel={t('aria_label_account')}/>
                }} />
            <div className={classes.linkCards}>
                {feature_ebilling && getResponsiveLinkCard('ebilling', 'ebilling_info', 'aria_label_ebilling_button', getEbillingUrl(), EbillingImage)}
                {feature_web_shop &&
                    <MIApiLoader
                        MIComponent={
                            !isEmpty(webShop) && getResponsiveLinkCard('web_shop', 'web_shop_info', 'aria_label_web_shop_button', webShop, ShoppingCartImage)
                        }
                        responseStatus={linkResponseStatus}
                    />
                }
                {feature_rapid_smart_hygiene &&
                    <MIApiLoader
                        MIComponent={
                            !isEmpty(rapidPortal) ?
                                <div className={classes.smartHygiene}>
                                    {getResponsiveLinkCard('rapid_portal', 'rapid_portal_info', 'aria_label_rapid_portal_button', rapidPortal, RapidImage)}
                                    <MIApiLoader
                                        MIComponent={
                                            !isEmpty(content) &&
                                            <MICmsContainer html={content}/>
                                        }
                                        responseStatus={responseStatus}
                                    />
                                </div> :
                                ''
                        }
                        responseStatus={linkResponseStatus}
                    />
                }
                {feature_medical_training &&
                    <MIApiLoader
                        MIComponent={
                            !isEmpty(medicalTraining) && getResponsiveLinkCard('online_training', 'online_training_info', 'aria_label_online_training_button', medicalTraining, medicalTrainingIcon)
                        }
                        responseStatus={linkResponseStatus}
                    />
                }
            </div>
            <div className={classes.gap}></div>
        </Paper>
    );
});

export default Account;
