import React from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import MIGrid, {GetRowCount} from '../../../components/Widgets/MIGrid';
import {observer} from "mobx-react-lite";
import {getBool, isEmpty} from "../../../utils/utils";
import {Typography} from '@material-ui/core';
import useModal from "../../../services/useModal";
import SiteInfoDialog from '../SiteInfoDialog';

const useStyles = makeStyles(theme => ({
    dataCell: {
        lineHeight: 0,
        width: '100%',
        whiteSpace: 'normal',
    },
    contractNo: {
        display: 'inline-flex',
        float: 'right',
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileBody
    },
    premiseNo: {
        display: 'inline-flex',
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileBody
    },
    siteAddress: {
        overflowWrap: 'break-word', 
        fontSize: theme.fontSizes.mobileBody
    },
    tableHeaders: {
        fontFamily: theme.fontFamily.proximaMedium,
        borderTop: theme.borders.outline_bold,
        borderBottom: theme.borders.outline_bold,
        padding: theme.sizes.micro_gap,
        display: 'flex',
        justifyContent: 'space-between'
    },
    gridContainer: {
        "& div.MuiDataGrid-cell": {
            paddingRight: theme.sizes.micro_gap,
            whiteSpace: 'normal',
        },
        "& div.MuiDataGrid-columnsContainer": {
            borderBottom: theme.borders.outline_regular, 
        },
        "& div.MuiDataGrid-colCellTitle": {
            color: theme.palette.primary.main,
        }
    },
}));

const SiteGridMobile = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const {isShowing, changeStatus} = useModal();
    const {
        showRowCount, 
        siteStore,
        gridRows, 
        isLoading, 
        sites, 
        selectionComponent, 
        mobilePageSize,
        hideFooter, 
        headerHeight,
    } = props;

    const getHeader = () => (
        <div className={classes.tableHeaders}>
            <span>{[t('premise_no'), t('premise_address')].join(' / ')}</span>
            <span>{t('contract_no')}</span>
        </div>
    );

    const getPremiseAndAddressCell = (params) => (
        <div className={classes.dataCell}>
            <Typography className={classes.premiseNo}>{params.row.premiseNo}</Typography>
            <Typography className={classes.contractNo}>{params.row.contractNo}</Typography>
            <div className={classes.siteAddress}>
                <Typography className={classes.siteAddress}>{params.row.address}</Typography>
            </div>
        </div>
    );

    const gridColumns = [{
        field: 'selectAllHeader',
        headerName: t('select_all'),
        type: 'string',
        resizable: false,
        filterable: false,
        sortable: false,
        flex: 3,
        renderCell: (params) => getPremiseAndAddressCell(params),
    }];

    const handleRowClick = (selectedRow) => {
        if (!isShowing) {
            siteStore.setSelectedSiteId(selectedRow.data.id);
            changeStatus();
        }
    };

    return (
        <div>
            {GetRowCount({rows: sites, showRowCount})}
            <div className={classes.gridContainer}>
                {getHeader()}
                <MIGrid
                    isMobile={true}
                    showInfoModal={false}
                    isLoading={isLoading}
                    columns={gridColumns}
                    rows={gridRows}
                    noElementsText={t('no_sites')}
                    rowHeight={55}
                    hasFilters={false}
                    pagination={true}
                    autoPageSize={false}
                    pageSize={mobilePageSize ? mobilePageSize : sites.length}
                    ariaLabel={t('aria_label_site_list_table')}
                    ariaLabelProgressBar={t('aria_label_progressbar_site_grid')}
                    onRowSelected={handleRowClick}
                    heightToFitContent={true}
                    headerHeight={!isEmpty(headerHeight) ? headerHeight : 0}
                    hideFooter={getBool(hideFooter)}
                    hideFooterForOnePage={true}
                    noEvenRowStyling={true}
                    {...selectionComponent}
                    {...props}
                />
                <SiteInfoDialog
                    open={isShowing}
                    handleClose={changeStatus}
                    selectedSite={siteStore.selectedSite}
                />
            </div>
        </div>
    );
});

export default SiteGridMobile;
