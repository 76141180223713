import { useReducer, useCallback } from "react";
import axios from "axios";
import reducer, {initialState} from "./requestReducer";
import { ERROR, FETCHING, SUCCESS, NOT_FOUND, UNAUTHORIZED } from "./requestReducer";

import firebase from "firebase/app";
import { firebaseConfig } from "../../utils/firebaseConfig";
import { useHealthStatusStore, useRegionStore } from "../../contexts/StateProvider/StateProvider";
import { isEmpty, getCookie, getSessionTimeout, setCookie } from "../../utils/utils";

export const fetching = () => ({type: FETCHING});
export const success = response => ({type: SUCCESS, response});
export const error = response => ({type: ERROR, response});

const useApiRequest = () => {
    const [ state, dispatch ] = useReducer(reducer, initialState);
    
    const api = axios.create({withCredentials: true});
    const healthStatus = useHealthStatusStore();

    const regionStore = useRegionStore();

    const baseUrl = (region, forceRemote = false) => {
        const backendRegion = isEmpty(region)? regionStore.getSelectedRegion(): region;
        const cloudFunctionUrl = 'https://' + backendRegion + '-' + firebaseConfig.projectId + '.cloudfunctions.net';

        if (forceRemote) {
            return cloudFunctionUrl;
        }
    
        if (window.location.hostname === "localhost" ||
            window.location.hostname === "127.0.0.1" ||
            window.location.hostname.startsWith('192.168.')) {
            return 'http://' + window.location.hostname + ':5001/' + firebaseConfig.projectId + '/' + backendRegion ;
        }
    
        return cloudFunctionUrl;
    };

    api.interceptors.request.use(async (config) => {
        const user = firebase.auth().currentUser;
        let token = user ? await user.getIdToken() : null;
        config.headers['Authorization'] = `Bearer ${token}`;

        const sessionId = getCookie('sessionId');
        if (sessionId) {
            config.headers['Session-Id'] = sessionId;
        }

        const headerRegion = config.headers['Region'];

        if (isEmpty(headerRegion)) {
            config.headers['Region'] = regionStore.getSelectedRegion();
        }
        return config
    }, (error) => {
        return Promise.reject(error);
    });

    api.interceptors.response.use((response) => {
        const sessionId = response.headers['session-id'];

        if (sessionId) {
            setCookie('sessionId', sessionId, getSessionTimeout())
        }
        healthStatus.setHealthStatus(SUCCESS);
        return response;
    }, (error) => {
        switch (error.response.status) {
            case 401:
                healthStatus.setHealthStatus(UNAUTHORIZED);
                break;
            case 500:
                healthStatus.setHealthStatus(ERROR);
                break;
            case 404:
                healthStatus.setHealthStatus(NOT_FOUND);
                break;
            default:
                break;
        }

        console.error('useApiRequest error.response.data', JSON.stringify(error.response.data));
        console.error('useApiRequest error.response.status', error.response.status);
        return Promise.reject(error);
    });

    const makeRequestWithParams = useCallback(async (url, requestMethod = 'get', requestParams = {}, region = null, forceRemote = false) => {
        dispatch(fetching());
        try {
            const requestUrl = url.startsWith('/floorPlanApi') ? url : `/api${url}`;
            console.log('makeRequest :', requestUrl, requestMethod);
            const fullPath = [baseUrl(region, forceRemote), requestUrl].join('');
            if (region) {
                // only needed for local development
                api.defaults.headers = {
                    Region: region,
                }
            }
            const response = await api[requestMethod](fullPath, requestParams);
            dispatch(success(response));
        } catch (e) {
            dispatch(error(e));
        }
        // Wrapping the api variable in useCallback would make the code unnecessarily complex. Better to disable the warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [state, makeRequestWithParams];
};

export default useApiRequest;
