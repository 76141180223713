import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetWasteTransferNotes } from '../../services/waste/useGetWasteTransferNotes';
import { getLocalizedDate, isEmpty } from '../../utils/utils';
import { ResponsiveComponent } from '../../services/useResponsive';
import WasteTransferNotesTableDesktop from './variant/WasteTransferNotesTableDesktop';
import MIPageTitle from '../Widgets/MIPageTitle';
import WasteTransferNotesTableMobile from './variant/WasteTransferNotesTableMobile';
import { useSiteStore } from '../../contexts/StateProvider/StateProvider';
import { observer } from 'mobx-react-lite';


const WasteTransferNotesTable = observer(() => {
    const { t } = useTranslation();

    const [ wasteNotes, responseStatus, getWasteTransferNotes ] = useGetWasteTransferNotes();
    const { selectedContractNumber, selectedPremiseNumber } = useSiteStore();

    useEffect(() => {
        if (!isEmpty(selectedContractNumber) && !isEmpty(selectedPremiseNumber)) {
            getWasteTransferNotes(selectedContractNumber, selectedPremiseNumber);
        }
    }, [ getWasteTransferNotes, selectedContractNumber, selectedPremiseNumber ]);


    const getRows = () => {
        // The API always returns dates in the format below
        const apiDateFormat = 'YYYY/MM/DD';
        let rows = [];
        if (!isEmpty(wasteNotes)) {
            wasteNotes.forEach((note, index) => {
                rows.push({
                    id: index,
                    date: getLocalizedDate(t, note.dateStamp, apiDateFormat),
                    title: t('annual_waste_transfer_note'),
                    downloadUrl: note.url
                });
            });
        }
        return rows;
    };


    return (
        <ResponsiveComponent
            components={{
                mobile: <WasteTransferNotesTableMobile
                    rows={getRows()}
                    responseStatus={responseStatus}
                />,
                desktop:
                    <>
                        <MIPageTitle
                            title={t('annual_waste_transfer_notes')}
                            ariaLabel={t('aria_label_annual_waste_transfer_notes_title')}
                            marginTop={16}
                            marginBottom={24}
                        />
                        <WasteTransferNotesTableDesktop rows={getRows()} responseStatus={responseStatus} />
                    </>
            }}
        />
    );
});

export default WasteTransferNotesTable;
