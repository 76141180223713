import { useCallback, useEffect, useState } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import { isEmpty } from '../../utils/utils';
import { useVisitStore } from '../../contexts/StateProvider/StateProvider';
import { useAuthState } from '../../contexts/AuthProvider';
import useApiRequest from '../requests/useApiRequest';

// remove this class in ACD-8571
export const useVisitHistoryKony = () => {
    const visitStore = useVisitStore();
    const [ currentSiteId, setCurrentSiteId ] = useState(null);
    const [ visits, setVisits ] = useState(null);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/premiseService/kony/visitHistory';
    const { locale } = useAuthState();

    const formatVisit = (visit) => {
        // change the format in order to be the same of the GCP output
        return {
            HasSignature: visit.hasSignature,
            PremiseVisitNumber: visit.premiseVisitNumber,
            ServiceTime: visit.timeOut,
            TechnicianName: visit.technician,
            VisitDate: visit.visitDate,
            VisitType: visit.vistitType,
        };
    };

    useEffect(() => {
        if (responseStatus === SUCCESS && !isEmpty(currentSiteId)) {
            try {
                const formattedVisits = response.data.visitHistory.map((visit) => {return formatVisit(visit);});
                visitStore.setVisitHistory(currentSiteId, formattedVisits);
                setVisits(formattedVisits);
            } catch (error) {
                console.error('useVisitHistory error: ', error);
                visitStore.setVisitHistory(currentSiteId, []);
                setVisits(null);
            }
        }
    }, [ responseStatus, response, visitStore, currentSiteId ]);

    const getVisitHistory = useCallback((siteId) => {
        const siteVisits = visitStore.getVisitHistory(siteId);
        if (!isEmpty(siteVisits)) {
            setVisits(siteVisits);
        } else {
            setVisits(null);
            setCurrentSiteId(siteId);
            const url = [ endpoint, siteId, locale ].join('/');
            makeRequestWithParams(url).catch((error) => {
                console.error('useVisitHistory error: ', error);
            });
        }
    }, [ makeRequestWithParams, visitStore, locale ]);

    return [ visits, visits !== null ? SUCCESS : responseStatus, getVisitHistory ];
};
