import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MIButton from '../../Widgets/MIButton';
import { isEmpty } from '../../../utils/utils';
import {observer} from 'mobx-react-lite';
import MIApiLoader from '../../Widgets/MIApiLoader';
import MIPageTitle from '../../Widgets/MIPageTitle';
import UpdateLogs from '../UpdateLogs';

const useStyles = makeStyles((theme) => {
    return {
        columnName: {
            'fontWeight': 'bold',
            '&:focus': theme.ariaFocus,
        },
        gridItem: {
            '&:focus': theme.ariaFocus,
            'paddingRight': theme.sizes.small_gap,
            width: 'fit-content'
        },
        info: {
            marginTop: theme.sizes.small_gap,
        },
        infoGrid: {
            flexGrow: 1,
            width: '100%',
        },
        columnLeft: {
            width: theme.widths.recommendation_details_rows,
        },
        floatMain: {
            float: 'left',
        },
        buttonContainer: {
            display: 'block',
            height: theme.sizes.xxlarge_gap,
        },
        pdfLink: {
            fontWeight: 'bold',
            minWidth: theme.sizes.xxlarge_gap,
        },
        icon: {
            marginBottom: [ '-', theme.sizes.pico_gap ].join(''),
        },
        goBack: {
            color: theme.palette.primary.main,
            display: 'inline',
            marginRight: theme.sizes.micro_gap,
            width: theme.widths.go_back_button,
        },
        valueClass: {
            paddingTop: 'inherit'
        },
        comments: {
            paddingTop: theme.sizes.micro_gap,
        },
        commentsTitle: {
            'fontWeight': 'bold',
            '&:focus': theme.ariaFocus,
            display: 'block',
            paddingBottom: theme.sizes.xlarge_gap
        },
        pdfDownloadContainer: {
            width: theme.widths.download_buttons, 
        },
        rowData: {
            textAlign: 'justify'
        }
    };
});

const RecommendationDetailsDesktop = observer((props) => {
    const {t} = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const {
        id,
        responseStatusDetails,
        rows,
        downloadPdf
    } = props;


    const showDetails = () => (   
        <div>
            {rows.map((data, index) => (
                <Grid container spacing={2} className={classes.info} key={index}>
                    <Grid item xs={2} className={classes.columnLeft}>
                        <span className={classes.columnName}
                            tabIndex={0}>{!isEmpty(data.title) ? [data.title, ':'].join('') : ''}</span>
                    </Grid>
                    <Grid container item xs={10}>
                        {data.value.map((rowData, index) => (
                            !(rowData === '-' && index > 0) ?
                                <Grid item xs={12} key={index} className={classes.rowData}>
                                    <div className={classes.gridItem} tabIndex={0}>{!isEmpty(rowData) ? rowData : '-'}</div>
                                </Grid> : null
                        ))}
                    </Grid>
                </Grid>
            ))}
            <div className={classes.comments}>
                <span className={classes.commentsTitle}>{t('update_logs')}:</span>
                <UpdateLogs id={id}/>
            </div>
        </div>
    );

    return (
        <div>
            <div className={classes.buttonContainer}>
                <div>
                    <MIButton
                        className={classes.goBack}
                        variant={'outlined'}
                        startIcon={<ArrowBackIcon className={classes.icon}/>}
                        onClick={history.goBack}>
                        {t('go_back')}
                    </MIButton>
                </div>
            </div>
            <MIPageTitle 
                title={t('recommendation_details')}
                ariaLabel={t('aria_label_recommendation_details_title')}
                marginTop={12}
                marginBottom={12}
            />
            <MIApiLoader
                    MIComponent={
                        <div>
                            {downloadPdf}
                            <div className={classes.infoGrid}>
                                {showDetails()}
                            </div>
                        </div>
                    }
                    responseStatus={responseStatusDetails}
                    ariaLabelProgressBar={t('aria_label_progressbar_recommendation_details')}
                />
        </div>
    );
});

export default RecommendationDetailsDesktop;
