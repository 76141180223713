import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';
import { useAuthState } from '../../contexts/AuthProvider/AuthProvider';
import { useNotifications } from './useNotifications';

export const useMarkAllAsRead = () => {
    const [ allRead, setMarkAllAsRead ] = useState([]);
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/seenNotification/markAllAsRead';
    const { countryCode, language, businessCode } = useAuthState();
    const [ , , getNotifications, getSeenNotifications ] = useNotifications();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setMarkAllAsRead(response.data.result);
                getNotifications();
                getSeenNotifications();
            } catch (error) {
                console.error('useMarkAllAsRead error: ', error);
                setMarkAllAsRead([]);
            }
        }
    }, [ responseStatus, response, setMarkAllAsRead, getNotifications, getSeenNotifications ]);

    const markAllAsRead = useCallback(() => {
        setMarkAllAsRead([]);
        const data = {
            countryCode: countryCode,
            language: language,
            businessCode:businessCode
        };
        makeRegionalGCPRequestWithParams(endpoint, 'post', data).catch((error) => {
            console.error('useMarkAllAsRead error: ', error);
        });
    }, [ makeRegionalGCPRequestWithParams, setMarkAllAsRead, countryCode, language, businessCode ]);

    return [ allRead, responseStatus, markAllAsRead ];
};
