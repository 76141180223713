import { useCallback, useEffect } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';
import { useContractStore } from '../contexts/StateProvider/StateProvider';
import { isEmpty } from '../utils/utils';

export const useContractList = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const contractStore = useContractStore();
    const endpoint = '/contractService/contractAndPremisesForUser';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                contractStore.setContracts(response.data.contractList);
            } catch (error) {
                console.error('useContractList error: ', error);
                contractStore.setContracts([]);
            }
        }
    }, [ responseStatus, response, contractStore ]);

    const getContracts = useCallback((locale, countryCode) => {
        if (isEmpty(contractStore.contracts)) {
            const url = [ endpoint, countryCode, locale ].join('/');
            makeRequestWithParams(url).catch((error) => {
                console.error('getContracts makeRequestWithParams: ', error);
            });
        }
    }, [ makeRequestWithParams, contractStore.contracts ]);

    return [ contractStore.contracts, responseStatus, getContracts ];
};
