import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import SearchTextField from "../SearchTextField";
import SiteSelectorAutocomplete from "../SiteSelelectorAutocomplete";

const useStyles = makeStyles(theme => ({
        container: {},
        siteSearchList: {
            paddingInlineStart: '15px',
            color: theme.palette.primary.main,
            cursor: 'pointer',
        },
        optionInner: {
            marginTop: '6px',
            color: theme.palette.text.primary,
            fontSize: theme.typography.body2.fontSize,
            fontWeight: theme.typography.fontWeightRegular,
        },
        optionInnerTitle: {
            fontWeight: theme.typography.fontWeightBold,
        },
        searchIcon: {
            color: theme.palette.grey['400'],
            cursor: 'text',
            position: 'relative',
        },
}));

const SiteSelectorAutocompleteDesktop = observer((props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {searchIcon} = props;

    return (
        <SiteSelectorAutocomplete
            groupBy={(option) => option.group}
            renderOption={(option) => {
                const address = option.siteAddress ? option.siteAddress.trim() : '';
                return (
                    <div>
                        <span className={classes.optionAddress}> {option.siteName.trim()}, {address} </span>
                        <div className={classes.optionInner}>
                            <span
                                className={classes.optionInnerTitle}>{t('contract_number')}</span> {option.contractId}
                            <span style={{marginInlineStart: '10px'}}
                                className={classes.optionInnerTitle}>{t('premise_number')}</span> {option.premiseNo}
                        </div>
                    </div>
                );
            }}
            renderInput={(params) => (
                <SearchTextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (searchIcon()),
                        endAdornment: (
                            <InputAdornment position='end'>
                                <Link to='/service/site-list'
                                    aria-label={t('aria_label_site_list_icon_shortcut')}>
                                    <FormatListBulleted className={classes.siteSearchList}/>
                                </Link>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    )

})

export default SiteSelectorAutocompleteDesktop;
