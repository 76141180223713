import React, {useState} from "react";
import MICalendar from "../Widgets/MICalendar";
import PlannedVisitDetails from "./PlannedVisitDetails";
import useModal from "../../services/useModal";
import moment from "moment";
import {useHistory} from "react-router-dom";
import { getLocalizedDate, isEmpty } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

const PlannedVisitsCalendar = (props) => {
    const { t } = useTranslation();
    const {isShowing, changeStatus} = useModal();
    const history = useHistory();
    const [selectedPlannedVisit, setSelectedPlannedVisit] = useState('');
    const {plannedVisits, previousVisits, selectedPlannedVisitDateString, setSelectedPlannedVisitDateString} = props;
    const dateFormat = 'DD/MM/YYYY';

    let plannedVisitsMap = {};
    let previousVisitsMap = {};

    const getPlannedVisitsMap = function () {
        if (plannedVisits) {
            plannedVisits.forEach(function (e) {
                plannedVisitsMap[e.date] = e;
            });
        }
        return plannedVisitsMap
    };

    const getPreviousVisitsMap = function () {
        if (previousVisits) {
            previousVisits.forEach(function (e) {
                const twoYearOld = moment().subtract(2, 'year').startOf('day');
                const visitDate = moment(e.VisitDate, dateFormat).locale('en');
                if (visitDate.isAfter(twoYearOld)) {
                    previousVisitsMap[e.VisitDate] = e;
                }
            });
        }
        return previousVisitsMap
    };


    const onDayClick = (date) => {
        const dataString = moment(date).locale('en').format(dateFormat);
        const foundPlannedVisit = plannedVisitsMap[dataString];
        if (foundPlannedVisit) {
            setSelectedPlannedVisit(foundPlannedVisit);
            changeStatus();
        }
        const foundPreviousVisit = previousVisitsMap[dataString];
        if (foundPreviousVisit){
            history.push({
                pathname: '/service/visit-history/visit-details',
                state: {
                    data: {
                        premiseVisitNumber: !isEmpty(foundPreviousVisit) ? foundPreviousVisit.PremiseVisitNumber : '-',
                        visitDate: !isEmpty(foundPreviousVisit) ? foundPreviousVisit.VisitDate : '-',
                        rawDate: foundPreviousVisit.VisitDate,
                        locDate: !isEmpty(foundPreviousVisit) && !isEmpty(foundPreviousVisit.VisitDate) ? getLocalizedDate(t, foundPreviousVisit.VisitDate): '',
                    }
                }
            });
        }
        setSelectedPlannedVisitDateString(dataString);
    };

    return (
        <div>
        <MICalendar
            plannedVisits={getPlannedVisitsMap()}
            previousVisits={getPreviousVisitsMap()}
            onDayClick={onDayClick}
            selectedPlannedVisitDateString={selectedPlannedVisitDateString}
        />
        <PlannedVisitDetails isShowing={isShowing} onClose={changeStatus} plannedVisit={selectedPlannedVisit}/>
        </div>
    );
};

export default PlannedVisitsCalendar;
