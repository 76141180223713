import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { FETCHING } from '../../../services/requests/requestReducer';
import MIGrid, { STYLE_HEADER_CENTER } from '../../Widgets/MIGrid';
import { observer } from 'mobx-react-lite';
import DownloadWasteNote from '../../VisitHistory/DownloadWasteNote';
import { useFlags } from '../../../contexts/FlagsProvider/FlagsProvider';
import GetAppIcon from '@material-ui/icons/GetApp';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => {
    return {
        linkContainer: {
            margin: 'auto',
            textAlign: 'center',
        },
        selectLink: {
            'display': 'flex',
            '&, &:visited, &:hover': {
                color: theme.palette.primary.main
            },
            '&:focus': theme.ariaFocus,
        },
        signatureIcon: {
            margin: 'auto',
            textAlign: 'center',
            color: 'grey',
        }
    };
});


const WasteConsignmentTableDesktop = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { setAlert, setIsShowing, responseStatus, rows, yearPicker } = props;
    const { feature_hazardous_waste_portugal } = useFlags();

    const columns = [
        {
            field: 'visitDate', headerName: t('visit_date'), type: 'date', resizable: false, flex: 1.5,
            renderCell: (params) => {
                return <span>{params.row.locDate}</span>;
            }

        },
        {
            field: 'consignmentNoteCode',
            headerName: t('consignment_note_code'),
            type: 'string',
            resizable: false,
            flex: 2
        },
        {
            field: 'download', headerName: t('download_note'), resizable: false, filterable: false, flex: 1,
            sortable: false,
            headerClassName: STYLE_HEADER_CENTER,
            renderCell: (params) => {
                return <div className={classes.linkContainer}>
                    {feature_hazardous_waste_portugal ?
                        <Link href={params.row.url} target={'_blank'} rel={'noopener noreferrer'}>
                            <GetAppIcon className={classes.icon}/>
                        </Link> :
                        <DownloadWasteNote
                            consignmentNoteCode={params.row.consignmentNoteCode}
                            visitDate={params.row.locDate}
                            setAlert={setAlert}
                            setIsShowing={setIsShowing}
                        />
                    }
                </div>;
            }
        },
    ];

    return (
        <div>
            {feature_hazardous_waste_portugal && yearPicker}
            <MIGrid
                showInfoModal={true}
                isLoading={responseStatus === FETCHING}
                heightToFitContent={true}
                hasFilters={true}
                columns={columns}
                rows={rows}
                hideFooterForOnePage={true}
                noElementsText={t('no_waste_notes')}
                ariaLabel={t('aria_label_visit_history_table')}
                ariaLabelProgressBar={t('aria_label_progressbar_waste_notes')}
                {...props}
            />
        </div>
    );
});

export default WasteConsignmentTableDesktop;
