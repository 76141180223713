import { useState, useEffect, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import useFloorPlanApiRequest from "../requests/useFloorPlanApiRequest";

export const useGetDetectorImage = () => {
    const [ detectorImage, setDetectorImage ] = useState(null);
    const [ { responseStatus, response }, makeFloorPlanApiRequestWithParams ] = useFloorPlanApiRequest();
    const endpoint = '/floorPlanApi/getDetectorImage';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setDetectorImage(response.data);
            } catch (error) {
                console.error('useGetDetectorImage error: ', error);
                setDetectorImage(null);
            }
        }
    }, [ responseStatus, response, setDetectorImage ]);

    const getDetectorImage = useCallback((detectorId, type) => {
        const url = [ endpoint, detectorId, type ].join('/');
        makeFloorPlanApiRequestWithParams(url).catch((error) => {
            console.error('useGetDetectorImage makeRequest: ', error);
        });
    }, [ makeFloorPlanApiRequestWithParams ]);

    return [ detectorImage, responseStatus, getDetectorImage ];
};