import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import SelectionHighlight from "./SelectionHighlight";

const useStyles = makeStyles(() => ({
    icon: {
        position: 'absolute',
        cursor: (props) => (props.handleDetectorClick ? 'pointer' : 'auto'),
    },
    component: {
        position: 'absolute',
        zIndex: 99,
    },
    labelOuter: {
        marginTop: (props) => (props.isSelected ? '-28px' : '-16px'),
        marginInlineStart: 'calc(-50% + 16px)',
        marginInlineEnd: 'calc(50% - 16px)',
    },
    label: {
        marginTop: '0px',
        marginBottom: '0px',
        padding: '0px',
        background: '#fff',
        fontSize: '14px',
    }
}));

const Location = (props) => {
    const { component, coordinates, centre, properties, handleDetectorClick, label, isSelected } = props;
    const x = `${coordinates.x.toString() }px`;
    const y = `${coordinates.y.toString() }px`;
    const { t } = useTranslation();
    const classes = useStyles(props);

    const handleClick = () => {
        handleDetectorClick && handleDetectorClick(properties);
    };

    const getLabel = () => (label ?
        <div className={classes.labelOuter}>
            <p className={classes.label} aria-label={t('aria_label_device_id_label')}>{label}</p>
        </div> :
        null);

    return (
        <div className={classes.icon} style={{ left: x, top: y }} onClick={handleClick} aria-label={t('detector')} role='figure'>
            {isSelected &&
                <SelectionHighlight
                    size={component.props.size}
                    centre={centre}
                />
            }
            <div className={classes.component}>
                {component}
            </div>
            {getLabel()}
        </div>
    );
};

export default Location;
