import { useCallback } from 'react';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { useLocation } from 'react-router-dom';
import { LOG_EVENT_PARAMS } from './FirebaseAnalyticsEnum';

export const useAnalytics = () => {
    const currentUrl = useLocation();
    const logEvent = useCallback((eventName, eventParams) => {
        try {
            const defaultParams = { [LOG_EVENT_PARAMS.PAGE_VIEW.PARAM]: currentUrl.pathname }
            firebase.analytics().logEvent(eventName, Object.assign(defaultParams, eventParams));
        } catch (e) {
            console.error('Unable to tag analytics log event:', e);
        }
    }, [currentUrl.pathname]);

    const setUserProperties = useCallback((properties) => {
        try {
            firebase.analytics().setUserProperties(properties);
        } catch (e) {
            console.error('Unable to tag analytics setUserProperties event:', e);
        }
    }, []);

    return {logEvent, setUserProperties};
}; 
