import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useGridSlotComponentProps } from '@material-ui/x-grid';
import { FORMFACTOR } from '../utils/enums';

const desktopStyles = (theme) => ({
    pagination: {
        'display': 'inline-flex',
        '&:focus': theme.ariaFocus,
    },
    resultContainer: {
        'display': 'inline-flex',
        'paddingEnd': '8px',
        'paddingStart': '4px',
        'fontSize': '14px',
        '&:focus': theme.ariaFocus,
    },
    of: {
        display: 'inline-block',
        paddingEnd: '4px',
    },
    text: {
        display: 'inline-block',
        paddingEnd: '4px',
    },
    paginationContainer: {
        display: 'flex',
    },
});

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        paginationContainer: {
            display: 'flex',
            margin: 'auto',
            justifyContent: 'center',
        },
    });
};

function MIPagination(props) {
    const { formFactor, rowCountAriaLabel } = props;
    const { state, apiRef } = useGridSlotComponentProps();
    const { t, i18n } = useTranslation();

    const useStyles = makeStyles((theme) => {
        switch (formFactor) {
        case FORMFACTOR.MOBILE:
            return mobileStyles(theme);
        case FORMFACTOR.DESKTOP:
        default:
            return desktopStyles(theme);
        }
    });
    const classes = useStyles();

    let from = state.pagination.rowCount === 0 ? 0 : state.pagination.page * state.pagination.pageSize + 1;
    let to = Math.min(state.pagination.rowCount, (state.pagination.page + 1) * state.pagination.pageSize);

    const getDesktopPagination = () =>
        <div aria-label={rowCountAriaLabel} className={classes.resultContainer} tabIndex={0}>
            <p className={classes.text}>{from}-{to}</p>
            <p className={classes.of}>{t('of')}</p>
            <p className={classes.text}>{state.pagination.rowCount} </p>
        </div>;

    const getViewportSizeSpecificPagination = () => {
        return formFactor === FORMFACTOR.DESKTOP ? getDesktopPagination() : null;
    }

    return (
        <div dir={i18n.dir()} className={classes.paginationContainer}>
            {getViewportSizeSpecificPagination()}
            <Pagination
                className={classes.pagination}
                color="primary"
                page={state.pagination.page + 1}
                count={state.pagination.pageCount}
                onChange={(event, value) => {
                    apiRef.current.setPage(value - 1);
                }}
                tabIndex={0}
                size={formFactor === 'desktop' ? 'medium' : 'small'}
            />
        </div>
    );
}

export default MIPagination;
