import React from 'react';
import {useTranslation} from 'react-i18next';
import {useCommonStyles} from './commonStyles';
import {makeStyles} from '@material-ui/core';
import MIDropDown from '../../widgets/MIDropDown';
import MITextField from '../../widgets/MITextField';
import {isObjectEmpty} from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    buildingSelectionOuter: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const getBuildingOptions = (buildings) => {
    const filter = (building) => building.floorWithImageAvailable;
    const buildingFormat = (building) => ({ label: building.description, value: building.id });
     if (!isObjectEmpty(buildings)) {
        const filteredElements = buildings.filter(filter);
        return filteredElements.map((building) => buildingFormat(building));
    }
    return [];
};

const BuildingSelector = (props) => {
    const {t} = useTranslation();
    const {buildings, control, onBuildingChange, disabled, width} = props;
    const commonStyles = useCommonStyles();
    const classes = useStyles();

    return (
        <div className={classes.buildingSelectionOuter}>
            <label className={commonStyles.textFieldLabel}> {t('building')}: </label>
            <MIDropDown
                width={width}
                control={control}
                dropDownProps={{
                    renderInput: (params) =>
                        <MITextField
                            {...params}
                            ariaLabel={t('aria_label_buildings')}
                            InputProps={{...params.InputProps}}
                            placeholder={t('please_select')}
                            hideBorder={true}
                        />,
                    noOptionsText: props.noOptionsText
                }}
                defaultValue={null}
                disableClearable={true}
                name={'buildings'}
                ariaLabel={t('aria_label_buildings')}
                options={getBuildingOptions(buildings)}
                noOptionsText={t(' no_buildings')}
                onHandleChange={onBuildingChange}
                dataTestId={' buildingsAutoComplete'}
                disabled={disabled}
            />
        </div>
    );
};

export default BuildingSelector;
