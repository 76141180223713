import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { isEmpty } from '../../../utils/utils';
import { getBuildingOptions, getFloorOptions, MIDropDown, MITextField } from '../../../rentokil-react-ui';
import MapIcon from '@material-ui/icons/Map';
import FilterIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';

export const HEADER_HEIGHT = 72;

const useStyles = makeStyles((theme) => {
    return {
        dropdownOuter: {
            paddingLeft: theme.sizes.small_gap,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        actionsOuter: {
            display: 'flex',
            flexDirection: 'row',
        },
        headerOuter: {
            'fontSize': theme.sizes.small_gap,
            'height': `${HEADER_HEIGHT}px`,
            'fontFamily': theme.typography.fontFamily,
            'paddingRight': theme.sizes.small_gap,
            'display': 'flex',
            'flex': 'auto',
            'flexDirection': 'row',
            '& div.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
                padding: 0,
                justifyContent: 'center',
                margin: 'auto'
            },
            '& div.MuiFormControl-fullWidth': {
                marginRight: theme.sizes.tiny_gap,
            }
        },
        filterCount: {
            position: 'absolute',
            top: '-2px',
            right: '-2px',
        },
        option: {
            height: theme.sizes.xxxlarge_gap,
            display: 'flex',
            alignItems: 'center',
        },
        formField: {
            'border': theme.borders.outline_bold,
            'width': '145px',
            'marginInlineEnd': theme.sizes.tiny_gap,
            'paddingInlineStart': theme.sizes.nano_gap,
            'paddingInlineEnd': theme.sizes.nano_gap,
            '& fieldset': {
                borderRadius: '0px',
                border: theme.borders.listBorders,
            },
            '& div.MuiInput-underline:after': {
                borderBottom: 'none',
            },
            '& div.MuiInput-underline:before': {
                borderBottom: 'none',
            },
        },
        listbox: {
            borderRadius: '0px',
            border: theme.borders.listBorders,
            borderWidth: '1px',
            padding: '0px',
        },
        actionButton: {
            alignSelf: 'flex-start',
            color: theme.palette.primary.main,
            fontSize: theme.fontSizes.subHeading,
            marginInlineStart: theme.sizes.tiny_gap,
            paddingLeft: theme.sizes.tiny_gap,
            paddingRight: theme.sizes.tiny_gap,
            textTransform: 'none',
        },
    };
});

const ActionBar = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        buildings,
        floors,
        getFloors,
        onFloorSelected,
        filter,
        disabled,
        handleFilters,
        toggleShowLegend
    } = props;
    const { control, setValue } = useForm();

    const onBuildingChange = (building) => {
        if (!isEmpty(building) && !isEmpty(building.value)) {
            setValue('buildings', building);
            setValue('floors', null);
            getFloors(building.value);
            return building.value;
        }
        return null;
    };

    const onFloorChange = (floor) => {
        if (!isEmpty(floor) && !isEmpty(floor.value)) {
            setValue('floors', floor);
            onFloorSelected(floor);
        }
    };

    useEffect(() => {
        // after loading the buildings select the first one in the list
        if (!isEmpty(buildings)) {
            const options = getBuildingOptions(buildings);
            if (!isEmpty(options)) {
                const defaultBuilding = options[0];
                setValue('buildings', defaultBuilding);
                getFloors(defaultBuilding.value);
            }
        }
    }, [ buildings, setValue, getFloors ]);

    useEffect(() => {
        // after loading the floors of the building select the first one in the list
        if (!isEmpty(floors)) {
            const options = getFloorOptions(floors);
            if (!isEmpty(options)) {
                const defaultFloor = options[0];
                setValue('floors', defaultFloor);
                onFloorSelected(defaultFloor);
            }
        }
    }, [ floors, setValue, onFloorSelected ]);

    const getDropDown = (props) => {
        return <MIDropDown
            className={classes.formField}
            control={control}
            dropDownProps={{
                renderInput: (params) => {
                    return <MITextField
                        {...params}
                        InputProps={{ ...params.InputProps, classes: { root: classes.root } }}
                        placeholder={t('unavailable')}
                    />;
                },
                noOptionsText: props.noOptionsText
            }}
            defaultValue={null}
            disableClearable={true}
            displayOptions={{ noBorder: true, width: '145px' }}
            {...props}
        />;
    };

    const filterCount = filter?.filterCount() ? filter.filterCount() : null;

    const getFilterButton = () => {
        return isEmpty(handleFilters) ? null :
            <Button
                className={classes.actionButton}
                display={'flex'}
                onClick={handleFilters}
                startIcon={<FilterIcon/>}
                aria-label={t('aria_label_filters_button')}
            >
                {t('filters')}
                <div className={classes.filterCount}>
                    {filterCount}
                </div>
            </Button>;
    };

    const getLegendButton = () => {
        return isEmpty(handleFilters) ? null :
            <Button
                className={classes.actionButton}
                onClick={toggleShowLegend}
                startIcon={<MapIcon/>}
                aria-label={t('icon_legend')}
            >
                {t('icon_legend')}
            </Button>;
    };

    return (
        <div className={classes.headerOuter}>
            <div className={classes.dropdownOuter}>
                {getDropDown({
                    name: 'buildings',
                    ariaLabel: t('aria_label_buildings'),
                    options: getBuildingOptions(buildings),
                    noOptionsText: t('no_buildings'),
                    onHandleChange: onBuildingChange,
                    dataTestId: 'buildingsAutoComplete',
                    disabled: disabled,
                })}

                {getDropDown({
                    name: 'floors',
                    ariaLabel: t('aria_label_floors'),
                    options: getFloorOptions(floors),
                    noOptionsText: t('no_floors'),
                    onHandleChange: onFloorChange,
                    dataTestId: 'floorsAutoComplete',
                    disabled: disabled,
                })}
                <div className={classes.actionsOuter}>
                    {getFilterButton()}
                    {getLegendButton()}
                </div>
            </div>
        </div>
    );
});

export default ActionBar;
