import React from 'react';
import MIGrid, {STYLE_HEADER_CENTER, RenderTooltip} from "../../../components/Widgets/MIGrid";
import {FETCHING} from "../../../services/requests/requestReducer";
import {useTranslation} from "react-i18next";
import Theme from "../../../components/Theme/Theme";
import TitleReport from "../ExistingReportsActions/TitleReport";
import ExistingReportsActionMenu from "../ExistingReportsActions/ExistingReportsActionMenu";

const REPORT_PAGE_SIZE = 5;
const DEFAULT_HEIGHT = 500;

const ExistingReportsTableDesktop = (props) => {
    const {t} = useTranslation();
    const {setAlert, setIsShowing, responseStatus, rows, getExistingReports} = props;
    
    const columns = [
        {field: 'reportTitle', headerName: t('report_title'),  description: t('report_title'), type:'component', resizeable: true, width: parseInt(Theme.widths.desktop_reports_title, 10),
            renderCell: (params) =>
                <TitleReport key={'title_report_'+params.row.id} reportId={params.row.id} reportTitle={params.row.reportTitle} setAlert={setAlert} setIsShowing={setIsShowing}/>
        },
        {field: 'visitType', headerName: t('visit_type'),  description: t('visit_type'), type: 'string', resizable: false, width:200, renderCell: RenderTooltip},
        {field: 'isScheduled', headerName: t('is_scheduled'),  description: t('is_scheduled'), type: 'string', resizable: false, width:200},
        {field: 'nextRunDate', headerName: t('next_run_date'),  description: t('next_run_date'), type: 'date', resizable: false, width:165,
            renderCell: (params) => <span>{params.row.nextRunLocDate}</span>
        },
        {
            field: 'actions', headerName: t('report_actions'),  description: t('report_actions'), type:'component', filterable: false, resizable: false, width: 130,
            sortable: false,
            headerClassName: STYLE_HEADER_CENTER,
            renderCell: (params) => <ExistingReportsActionMenu params={params} setIsShowing={setIsShowing} getExistingReports={getExistingReports} setAlert={setAlert}/>
        },
    ]

    return (
        <MIGrid
            showInfoModal={true}
            isLoading={responseStatus===null || responseStatus===FETCHING}
            pagination={true}
            columns={columns}
            rows={rows}
            noElementsText={t('no_reports')}
            hasFilters
            autoheight
            ariaLabel={t('aria_label_existing_reports_table')}
            pageSize={REPORT_PAGE_SIZE}
            hideFooterForOnePage={true}
            height={DEFAULT_HEIGHT}
            ariaLabelProgressBar={t('aria_label_progressbar_existing_reports')}
        />
    );
};

export default ExistingReportsTableDesktop;
