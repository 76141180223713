import React, {useCallback, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import NewReportForm from "./NewReportForm";
import {useLocation} from "react-router-dom";
import {setDocumentTitle} from '../../utils/utils';
import {useResponsive, ResponsiveComponent} from '../../services/useResponsive';
import MIPageTitle from '../../components/Widgets/MIPageTitle';

const desktopStyles = (theme) => {
    return {
        root: {
            width: "100%",
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        newReportLabel: {
            display: 'flex',
            marginBottom: theme.sizes.micro_gap,
            fontWeight: 500,
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.large_gap,
            paddingRight: theme.sizes.no_gap,
            paddingLeft: theme.sizes.no_gap,
            maxHeight: 'none',
        },
        content: {
            padding: [theme.sizes.large_gap, theme.sizes.small_gap, theme.sizes.small_gap].join(' ')
        }
    });
};

const NewReport = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const inputState = location.state? location.state : {} ;
    const [input, setInput] = useState(inputState);
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));

    const classes = useStyles();

    const clearInput = useCallback(() => {
        setInput({});
    }, [setInput]);

    useEffect(() => {
        setDocumentTitle(t, 'nav_new_report');
     }, [t]);

    return (
        <Paper className={classes.root} aria-label={t('aria_label_new_report_page')}>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: <MIPageTitle title={t('new_report')} ariaLabel={t('aria_label_new_report_title')}/>
                }}
            />

            <div className={classes.content}>
                <NewReportForm report={input.report || {} } action={input.action} clearInput={clearInput}/>
            </div>
        </Paper>
    );
};

export default NewReport;
