import React from 'react';
import {useTranslation} from 'react-i18next';
import {isEmpty} from '../../utils/utils'
import YoutubeIcon from '../../assets/youtube.svg';
import LinkedinIcon from '../../assets/linkedin.svg';
import FacebookIcon from '../../assets/facebook.svg';
import TwitterIcon from '../../assets/twitter.svg';

import YoutubeIconHover from '../../assets/youtube-hover.svg';
import LinkedinIconHover from '../../assets/linkedin-hover.svg';
import FacebookIconHover from '../../assets/facebook-hover.svg';
import TwitterIconHover from '../../assets/twitter-hover.svg';

import MISocialMediaButton from '../Widgets/MISocialMediaButton';

const SocialIcons = (props) => {
    const {t} = useTranslation();
    const {classes, title, content, iconLinkStyle} = props;
    const {youtube, linkedin, facebook, twitter} = content;

    return (
        <div className={classes}>
            {!isEmpty(youtube) ?
                <MISocialMediaButton
                    ariaLabel={t('youtube_button')}
                    icon={YoutubeIcon}
                    iconHover={YoutubeIconHover}
                    href={['//', youtube].join('')}
                    title={title}
                    iconLinkStyle={iconLinkStyle}
                />
            : ''}
            {!isEmpty(linkedin) ?
                <MISocialMediaButton
                    ariaLabel={t('linkedin_button')}
                    icon={LinkedinIcon}
                    iconHover={LinkedinIconHover}
                    href={['//', linkedin].join('')}
                    title={title}
                    iconLinkStyle={iconLinkStyle}
                />
            : ''}
            {!isEmpty(facebook) ?
                <MISocialMediaButton
                    ariaLabel={t('facebook_button')}
                    icon={FacebookIcon}
                    iconHover={FacebookIconHover}
                    href={['//', facebook].join('')}
                    title={title}
                    iconLinkStyle={iconLinkStyle}
                />
            : ''}
            {!isEmpty(twitter) ?
                <MISocialMediaButton
                    ariaLabel={t('twitter_button')}
                    icon={TwitterIcon}
                    iconHover={TwitterIconHover}
                    href={['//', twitter].join('')}
                    title={title}
                    iconLinkStyle={iconLinkStyle}
                />
            : ''}
        </div>
    );
}

export default SocialIcons;
