import React from 'react';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MICheckbox from '../../../components/Widgets/MICheckbox';
import { useTranslation } from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import { INTERACTION_COLORS, INTERACTION_NAMES, TIME_PERIOD } from './SmartHygieneEnum';
import { makeStyles } from '@material-ui/core/styles';
import { PDF_WIDTH } from '../../../services/smartHygiene/useSmartHygienePDF';

const useStyles = makeStyles((theme) => {
    return {
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginBottom: theme.sizes.small_gap,
        },
        titlePDF: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.sizes.small_gap,
            width: PDF_WIDTH
        },
        title: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.fontFamily.proximaBold,
        },
        secondaryTitle: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.fontFamily.proximaBold,
            marginInlineStart: theme.sizes.tiny_gap,
            marginBottom: theme.sizes.small_gap,
            color: theme.palette.grey['500'],
        },
        grow: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
        timePeriodSelector: {
            'width': '200px',
            '&& div.MuiOutlinedInput-root': {
                borderRadius: '0px',
            },
        },
    };
});

const InteractionTitle = (props) => {
    const { t } = useTranslation();
    const { interactionTypes, setInteractionTypes, timePeriod, changeTimePeriod, asPDF } = props;
    const classes = useStyles();

    const getTimePeriods = () => {
        return [
            <MenuItem key={TIME_PERIOD.BY_MONTH.key} value={TIME_PERIOD.BY_MONTH}>{t(TIME_PERIOD.BY_MONTH.key)}</MenuItem>,
            <MenuItem key={TIME_PERIOD.BY_DAY.key} value={TIME_PERIOD.BY_DAY}>{t(TIME_PERIOD.BY_DAY.key)}</MenuItem>,
        ];
    };

    const addCheckbox = (name, type, color) => {
        return <MICheckbox
            name={name}
            label={t(name)}
            checkboxColor={color}
            key={name}
            handleChange={() => {
                setInteractionTypes((prevState) => {
                    const changedState = {
                        [type]: {
                            NAME: prevState[type].NAME,
                            CHECKED: !prevState[type].CHECKED
                        }
                    };
                    const newState = Object.assign(prevState, changedState);

                    // This Deep Clones the state - Required since otherwise the state won't trigger a rerender.
                    return JSON.parse(JSON.stringify(newState));
                });
            }}
            checked={interactionTypes[type].CHECKED}
        />;
    };

    return (
        <div className={asPDF ? classes.titlePDF : classes.titleContainer}>
            <div className={asPDF ? classes.row : classes.grow}>
                <Typography className={classes.title}>{t('interaction_patterns')}</Typography>
                {asPDF && <Typography className={classes.secondaryTitle}>{t(timePeriod.key)}</Typography>}
            </div>
            <div className={classes.row}>
                {addCheckbox(t('water'), INTERACTION_NAMES.WATER, INTERACTION_COLORS.WATER)}
                {addCheckbox(t('foam'), INTERACTION_NAMES.FOAM, INTERACTION_COLORS.FOAM)}
                {addCheckbox(t('sanitiser'), INTERACTION_NAMES.SANITISER, INTERACTION_COLORS.SANITISER)}
                {addCheckbox(t('blu'), INTERACTION_NAMES.BLU, INTERACTION_COLORS.BLU)}
            </div>

            {!asPDF &&
                <div className={classes.timePeriodSelector}>
                    <FormControl variant={'outlined'} fullWidth={true}>
                        <Select
                            value={timePeriod}
                            onChange={changeTimePeriod}
                        >
                            {getTimePeriods()}
                        </Select>
                    </FormControl>
                </div>
            }
        </div>
    );
};

export default InteractionTitle;
