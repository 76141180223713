import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const Theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(0,117,184)',
            dark: 'rgb(1,82,148)',
            light: 'rgb(0,148,222)',
        },
        secondary: {
            main: 'rgb(173,0,0)',
            dark: 'rgb(150,0,0)',
            light: 'rgb(197,49,49)',
        },
        text: {
            primary: 'rgb(53,74,85)',
            contrastBlue: 'rgb(0,105,166)',
            contrastText: '#fff',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#ad0000',
            contrastText: '#fff',
        },
        background: {
            default: '#f1f1f1',
            highlight: '#F3F4F4',
            white: '#fff',
        },
        refillLevels: {
            empty: '#E95C46',
            low: '#EBAA40',
            full: '#75A347',
            noData: '#869399',
        }
    },
    typography: {
        // default font family of the theme
        fontFamily: [ 'proxima nova medium',
            'Roboto',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(','),
        h1: {
            fontFamily: [ 'proxima nova bold', 'Roboto', 'Helvetica', ].join(','),
            fontSize: '50px',
        },
        h2: {
            fontFamily: [ 'proxima nova bold', 'Roboto', 'Helvetica', ].join(','),
            fontSize: '30px',
        },
        h3: {
            fontFamily: [ 'proxima nova bold', 'Roboto', 'Helvetica', ].join(','),
            fontSize: '25px',
        },
        h4: {
            fontFamily: [ 'proxima nova bold', 'Roboto', 'Helvetica', ].join(','),
            fontSize: '20px',
        },
        small_error: {
            fontFamily: [ 'proxima nova bold', 'Roboto', 'Helvetica', ].join(','),
            fontSize: '12px',
        },
    },
    colors: {
        mainText: '#354A55',
        primaryHighlight: '#06B49A',
        noData: '#000000de',
        previousVisitIcon: '#669933',
        plannedVisitIcon: '#0075B8',
        notificationsIcon: '#bdbdbd',
        savedValue: '#75A347',
        notificationRed: '#ED1C24',
        productTableRow: '#c6cbcf',
        selectedRowDefault: 'rgba(0, 117, 184, 0.08)'
    },
    fontSizes: {
        mobile: '0.875em',
        notification: '0.875em',
        notificationContent: '0.75em',
        nav: '1em',
        small: '1em',
        medium: '2em',
        large: '3em',
        title: '32px',
        heading: '24px',
        subHeading: '16px',
        mobileTitle: '16px',
        mobileBody: '14px',
        mobileLabel: '12px',
    },
    fontFamily: {
        proximaBold: 'proxima nova bold',
        proximaMedium: 'proxima nova medium',
    },
    borders: {
        mainContent: '1px solid #e0e0e0',
        outline_regular: `1px solid${ grey['300']}`,
        outline_bold: `1px solid${ grey['400']}`,
        navigation_top: '1px solid #354A55',
        listBorders: '1px solid #9aa5aa',
        chartBorder: `4px solid${ grey['300']}`,
    },
    sizes: {
        no_gap: '0px',
        planck_gap: '2px',
        femto_gap: '3px',
        pico_gap: '4px',
        nano_gap: '6px',
        tiny_gap: '8px',
        micro_gap: '12px',
        xsmall_gap: '14px',
        small_gap: '16px',
        medium_gap: '20px',
        large_gap: '24px',
        xlarge_gap: '32px',
        xmlarge_gap: '36px',
        xxlarge_gap: '48px',
        xxxlarge_gap: '56px',
        mega_gap: '64px',
        giga_gap: '96px',
        avatar: '32px',
        support_card: '90px',
        smart_hygiene_spacing: '29px',
        empty_benefit_card: '10px',
    },
    heights: {
        top_banner: '160px',
        sidebar_height: '64px',
        recommendation_detail_min_height: '104px',
        benefit_card_min_height: '21px',
        mobile_tabs: '55px',
        mobile_logo: '95px',
        mobile_login_logo: '45px',
        desktop_documents_download_icon: '24px',
        mobile_documents_download_icon: '20px',
        mobile_grid_min_height: '100px',
        mobile_info_block: '370px',
        mobile_loading_spinner_margin: '120px',
        interaction_chart: '350px',
        interaction_chart_spinner: '155px'
    },
    widths: {
        login_button: '208px',
        desktop_textField: '448px',
        desktop_reports_title: '230px',
        mobile_textField: '288px',
        mobile_login_logo: '148px',
        desktop_documents_download_icon: '24px',
        mobile_documents_download_icon: '20px',
        mobile_navdrawer: '240px',
        recommendation_details_rows: '160px',
        go_back_button: '160px',
        download_buttons: '140px',
        recommendation_image: '50px',
        profile_name_container_width: '150px',
        mainPageWidth: 928,
    },
    ariaFocus:{
        outline: 'none',
        border:'1px solid',
        borderColor: 'rgb(0,148,222)',
    },
    overrides: {
        MuiOutlinedInput: {
            input: {
                fontSize: 16,
                borderWidth: '1px',
                borderRadius: 0,
                position: 'relative',
                backgroundColor: '#fff',
                borderColor: '#9aa5aa',
                padding: '15px 14px',
            },
        },
        MuiPickersCalendarHeader: {
            dayLabel: {
                color: '#000000de',
                headerColor: '#000000de',
                fontWeight: 'bold',
            }
        },
        MuiToolbar: {
            regular: {
                height: '48px',
                backgroundColor: '#fff'
            }
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                height: '70px',
            },
            toolbarLandscape: {
                height: 'auto'
            }
        },
        MuiBottomNavigationAction: {
            label: {
                'fontSize': '12px',
                '&.Mui-selected': {
                    fontSize: '12px'
                }
            }
        }
    },
});

export default Theme;
