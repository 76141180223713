import { useState, useEffect } from 'react';

export const useStateWithLocalStorage = (localStorageKey: string) => {
  const stringValue = localStorage.getItem(localStorageKey);
  const [value, setValue] = useState(stringValue ? JSON.parse(stringValue) : '');

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  return [value, setValue];
};

export const useStateWithSessionStorage = (localStorageKey: string, initialState: boolean) => {
  const stringValue = sessionStorage.getItem(localStorageKey);
  const [value, setValue] = useState(stringValue ? JSON.parse(stringValue) : initialState);

  useEffect(() => {
    sessionStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  return [value, setValue];
};
