import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useTheme from "@material-ui/core/styles/useTheme";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {getRouteIndex, isEmpty} from '../../utils/utils';
import {ResponsiveComponent, useResponsive} from "../../services/useResponsive";


const primaryLevel = theme => {
    return {
        tabContainer: {
            height: theme.sizes.mega_gap,
            width: 'fit-content',
            marginInlineStart: 'auto',
            "& span.MuiTabs-indicator": {
                height: '8px',
            },
            marginLeft: 'auto',
        },
        tab: {
            textTransform: "none",
            padding: [theme.sizes.small_gap, theme.sizes.small_gap].join(' '),
            borderRight: '1px solid ' + theme.palette.grey["100"],
            minWidth: "40px",
            color: theme.colors.mainText,
            fontSize: theme.fontSizes.nav,
            "&.Mui-selected": {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        main: {
            height: theme.sizes.mega_gap,
            flex: 1,
            maxWidth: '960px',
            order: 2,
        },
    }
};

const secondaryLevel = theme => {
    return {
        tabContainer: {
            height: theme.sizes.xxlarge_gap,
            maxWidth: '960px',
            "& span.MuiTabs-indicator": {
                height: '0px',
            },
            "& div.MuiTabs-flexContainer": {
                height: theme.sizes.xxlarge_gap,
                justifyContent: 'left',
            },
            margin: 'auto',

        },
        tab: {
            textTransform: "none",
            padding: [theme.sizes.small_gap, theme.sizes.small_gap].join(' '),
            borderRight: '1px solid ' + theme.palette.grey["100"],
            minWidth: "40px",
            color: theme.colors.mainText,
            fontSize: theme.fontSizes.nav,
            "&.Mui-selected": {
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.error.contrastText,
                backgroundColor: theme.colors.mainText,
                borderBottom: '0px',

            },
        },
        main: {
            height: theme.sizes.xxlarge_gap,
            flex: 1,
            maxWidth: 'inherit',
            order: 2,
            backgroundColor: theme.palette.error.contrastText,
            borderBottom: theme.borders.outline_regular,

        },
    }
};

const mobileStylesSecondaryLevel = theme => {
    const baseStyle = secondaryLevel(theme);
    
    return Object.assign(baseStyle, {
         tabContainer: {
            height: theme.sizes.xxlarge_gap,
            maxWidth: '100vw',
            "& span.MuiTabs-indicator": {
                height: theme.sizes.femto_gap,
            },
            "& div.MuiTabs-flexContainer": {
                height: theme.sizes.xxlarge_gap,
                justifyContent: 'left',
            },
            margin: 'auto',
         },
         tab: {
            textTransform: "none",
            padding: [theme.sizes.small_gap, theme.sizes.small_gap].join(' '),
            borderRight: 'none',
            minWidth: "40px",
            color: theme.colors.mainText,
            fontSize: theme.fontSizes.nav,
            "&.Mui-selected": {
                fontWeight: theme.typography.fontWeightMedium,
                backgroundColor: 'none',
                borderBottom: '0px',

             },
         },
         main: {
            height: theme.sizes.xxlarge_gap,
            flex: 1,
            maxWidth: 'inherit',
            order: 2,
            backgroundColor: theme.palette.error.contrastText,
            borderBottom: theme.borders.outline_regular,
         },
        
    })

}

const MINavMenu = (props) => {
    const history = useHistory();
    const location = useLocation();
    const {t, i18n} = useTranslation();

    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: props.level === 'secondary' ? secondaryLevel(theme) : primaryLevel(theme),
            mobile: props.level === 'secondary' ? mobileStylesSecondaryLevel(theme) : primaryLevel(theme),
        })
    ));

    const navClass = useStyles();


    const ariaLabel = props.ariaLabel ? props.ariaLabel : t('aria_label_secondary_nav');

    const [value, setValue] = useState(0);
    const actionRef = useRef();

    useEffect(() => {
        setValue(getRouteIndex(location.pathname, props.routes));
        // This is a work around for a bug in the Tabs material UI component
        setTimeout(() => {
            if (!isEmpty(actionRef.current)) {
                actionRef.current.updateIndicator()
            }
        }, 1000);
    }, [location.pathname, props.routes]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        window.scrollTo({top: 0, behavior: 'smooth'});
        history.push(props.routes[newValue].path);
        if (props.onChange) {
            props.onChange(newValue);
        }

    };

    const border = {borderLeft: '1px solid ' + useTheme().palette.grey["100"]};
    const firstTabStyle = i18n.dir() === 'ltr' ? border : {};
    const lastTabStyle = i18n.dir() === 'ltr' ? {} : border;

    let tabs = [];
    props.routes.forEach((route, index) => {
        if (index === 0) {
            tabs.push(<Tab className={navClass.tab} key={index} style={firstTabStyle} label={route.label}
                           aria-label={route.ariaLabel}/>);
        } else if (index === props.routes.length - 1) {
            tabs.push(<Tab className={navClass.tab} key={index} style={lastTabStyle} label={route.label}
                           aria-label={route.ariaLabel}/>);
        } else {
            tabs.push(<Tab className={navClass.tab} key={index} label={route.label} aria-label={route.ariaLabel}/>);
        }
    });

    const getTabs = (props) => {
        return (
        <div className={navClass.main} role='navigation' aria-label={ariaLabel}>
                <Tabs
                    className={navClass.tabContainer}
                    action={actionRef}
                    value={value}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor="primary"
                    {...props}
                >
                    {tabs}
                </Tabs>
            </div>
        )
    }
    return (
        <ResponsiveComponent
            components = {{
                mobile: getTabs({variant: "scrollable", centered: false}),
                desktop: getTabs({centered: true})
            }}/>
    );
};

export default MINavMenu;