import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useStateWithLocalStorage } from '../../utils/localStorage.tsx';
import { useLanguageStore, useRegionStore, useStoreRoot, useHealthStatusStore } from '../../contexts/StateProvider/StateProvider';
import { useTranslation } from 'react-i18next';
import { getLocaleSections, isEmpty } from '../../utils/utils';
import { UNAUTHORIZED } from '../../services/requests/requestReducer';
import { getSuccessRegionsKeys, handleMultiLoginLogic } from '../../utils/multyCountryUtils';
import { getSessionTimeout } from '../../utils/utils';
import { useHistory } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';

import {
    ERROR_STATUS,
    FAILED_STATUS,
    LOCK_STATUS,
    NO_SITE,
    LOGGED_IN_STATUS,
    LOGGED_OUT_STATUS,
    PASSWORD_EXPIRED,
    PASSWORD_CHANGED,
    PENDING_STATUS,
    DIRECT_LOGIN_STATUS,
    SUCCESS_STATUS,
} from './AuthConst';

import { SUCCESS } from '../../services/requests/requestReducer';
import { useSSOData } from '../../services/useSSO';
import { useSessionCookie } from '../../services/useSessionCookie';
import { useLogin } from '../../services/login/useLogin';
import { useLoginViaSSO } from '../../services/login/useLoginViaSSO';


const AUTH_STORAGE_KEY = 'authState';

export const AuthContext = createContext();
const sessionTimeout = getSessionTimeout();

const AuthProvider = ({ children }) => {
    const [ state, setState ] = useStateWithLocalStorage(AUTH_STORAGE_KEY);
    const [ status, setStatus ] = useState(PENDING_STATUS);
    const [ userId, setUserId ] = useState('');
    const [ session, setSession, sessionStatus, createSession ] = useSessionCookie();
    const [ token, setToken ] = useState(null);
    const regionStore = useRegionStore();
    const healthStatus = useHealthStatusStore();

    firebase.auth().onAuthStateChanged((user) => {
        if (user) {
            if (user.uid !== userId) {
                setUserId(user.uid);
                user.getIdTokenResult().then((result) => {
                    setToken(result.token);
                });
            }
        } else {
            setUserId('');
            setSession(false);
            setToken(null);
            if (status === PENDING_STATUS) {
                setStatus(LOGGED_OUT_STATUS);
            }
        }
    });

    useEffect(() => {
        if (!isEmpty(token) && !session &&
            !isEmpty(regionStore.getSelectedRegion())) {
            createSession(token);
        }

        if (isEmpty(token) && isEmpty(regionStore.getSelectedRegion())) {
            healthStatus.setHealthStatus(UNAUTHORIZED);
        }
    }, [ token, createSession, session, regionStore, healthStatus ]);

    useEffect(() => {
        if (sessionStatus === SUCCESS) {
            if (!isEmpty(userId) && session) {
                setStatus(LOGGED_IN_STATUS);
            }
        }
    }, [ sessionStatus, setStatus, userId, session ]);

    return (
        <AuthContext.Provider value={{ state: state, setState: setState, status: status, setStatus: setStatus }}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuthState = () => {
    const { i18n } = useTranslation();
    const context = useContext(AuthContext);
    const storeRoot = useStoreRoot();
    const regionStore = useRegionStore();
    const languageStore = useLanguageStore();
    const history = useHistory();
    const isError = context.status === ERROR_STATUS;
    const isFailed = context.status === FAILED_STATUS;
    const isLocked = context.status === LOCK_STATUS;
    const isNoSite = context.status === NO_SITE;
    const isExpired = context.status === PASSWORD_EXPIRED;
    const isPasswordChanged = context.status === PASSWORD_CHANGED;
    const { setStatus, setState } = context;
    const [ ssoFormRef, setSsoFormRef ] = useState(null);
    const [ tokenRef, setTokenRef ] = useState(null);
    const [ ssoData, ssoDataStatus, getSso ] = useSSOData();
    const [ userDataRegions, responseStatus, doLogin ] = useLogin();
    const [ userinfoSSO, , doLoginViaSSO ] = useLoginViaSSO();

    const setError = useCallback((operation, error) => {
        setStatus(ERROR_STATUS);
        console.error(`${operation } Error: `, error);
    }, [ setStatus ]);

    const logout = useCallback(() => {
        setStatus(PENDING_STATUS);
        firebase.auth().signOut()
            .then(() => {
                if (!isEmpty(history)) {
                    history.push({ pathname: '/' });
                }
                setState('');
                storeRoot.reset();
                languageStore.resetLanguage(i18n);
            })
            .catch((error) => {
                console.error(error);
                setStatus(ERROR_STATUS);
            });
    }, [ setStatus, i18n, languageStore, setState, storeRoot, history ]);

    const setLogoutTimer = useCallback(() => {
        if (window.logoutTimer) {
            clearTimeout(window.logoutTimer);
        }

        window.logoutTimer = setTimeout(() => {
            logout();
        }, sessionTimeout);
    }, [ logout ]);

    const signIn = useCallback(async(userData, regionName) => {
        if (userData) {
            if (!userData.token) {
                setStatus(userData.status);
            } else {
                firebase.auth().setPersistence(
                    firebase.auth.Auth.Persistence.SESSION).then(async() => {
                    // the firebase IdToken is per region so only signIn in firebase if the region changes
                    if (regionStore.getSelectedRegion() !== regionName) {
                        regionStore.setRegion(regionName);
                        await firebase.auth().signInWithCustomToken(userData.token);
                    }
                    userData.locale = `${userData.defaultLanguage }_${ userData.countryCode}`;
                    setState(userData);
                }).catch((error) => {
                    logout();
                    setError('Login: ', error);
                    console.error('Sign with token error: ', error);
                });
            }
        }
    }, [ setStatus, setState, setError, regionStore, logout ]);

    const countrySignIn = useCallback((regionData, countryData, regionName, changeLanguage) => {
        if (!isEmpty(regionData) && !isEmpty(countryData) && !isEmpty(regionName)) {
            regionData.countryCode = countryData.countryCode;
            regionData.defaultLanguage = countryData.defaultLanguage;
            regionStore.setCountry(countryData);
            signIn(regionData, regionName).then(() => {
                // remove all the cached data from the previous country
                storeRoot.resetCountry();
                if (changeLanguage) {
                    // change the current language to the user's language
                    languageStore.updateLanguageOnLogin(countryData.defaultLanguage, i18n);
                } else {
                    // only update language locale
                    languageStore.setLanguage(i18n.language, i18n);
                }
            }).catch((error) => {
                console.error('Sign in: ', error);
            });
        }
    }, [ signIn, storeRoot, regionStore, languageStore, i18n ]);

    useEffect(() => {
        if (!isEmpty(userDataRegions)) {
            try {
                const loginStatus = handleMultiLoginLogic(userDataRegions);
                if (!isEmpty(loginStatus) && loginStatus !== SUCCESS_STATUS) {
                    if (loginStatus === DIRECT_LOGIN_STATUS) {
                        // if loginStatus === DIRECT_LOGIN_STATUS means that
                        // we have only 1 region and 1 country with SUCCESS_STATUS

                        const successRegionKey = getSuccessRegionsKeys(userDataRegions);
                        const regionData = isEmpty(successRegionKey) ? {} : userDataRegions[successRegionKey[0]];
                        const countryData = isEmpty(regionData.adminDetails) ? {} : regionData.adminDetails[0];
                        countrySignIn(regionData, countryData, successRegionKey, true);
                    } else {
                        // is an not SUCCESS_STATUS ( PASSWORD_EXPIRED, PASSWORD_CHANGED, ..)
                        setStatus(loginStatus);
                    }
                }
                // else if the status is SUCCESS_STATUS we show the popup
            } catch (error) {
                setError('Login: ', error);
            }
        }
    }, [ userDataRegions, countrySignIn, setError, setStatus ]);

    useEffect(() => {
        // SSO LOGIN
        if (!isEmpty(userinfoSSO)) {
            try {
                // DIRECT LOGIN VIA SSO
                const countryData = isEmpty(userinfoSSO.adminDetails) ? {} : userinfoSSO.adminDetails[0];
                countrySignIn(userinfoSSO, countryData, userinfoSSO.region, true);
            } catch (error) {
                setError('Login: ', error);
            }
        }
    }, [ userinfoSSO, countrySignIn, setError ]);

    const login = (email, password) => {
        setStatus(LOGGED_OUT_STATUS);
        doLogin(email, password);
    };

    const loginViaSSO = useCallback((encryptedToken, redirectURL) => {
        setStatus(LOGGED_OUT_STATUS);
        doLoginViaSSO(encryptedToken, redirectURL);
    }, [ setStatus, doLoginViaSSO ]);


    const user = () => {
        if (context.state.token) {
            let { token, status, ...y } = context.state;
            return y;
        }
        return '';
    };

    const status = () => {
        return context.status;
    };

    const sso = (ssoFormRef, tokenRef) => {
        setSsoFormRef(ssoFormRef);
        setTokenRef(tokenRef);
        getSso(
            context.state.email_address,
            context.state.countryCode,
            context.state.defaultLanguage);
    };

    useEffect(() => {
        if (ssoDataStatus === SUCCESS && !isEmpty(ssoData)) {
            ssoFormRef.current.method = 'POST';
            ssoFormRef.current.action = ssoData.url;
            tokenRef.current.type = 'hidden';
            tokenRef.current.name = 'token';
            tokenRef.current.value = ssoData.token;
            ssoFormRef.current.submit();
        }
    }, [ ssoDataStatus, ssoData, ssoFormRef, tokenRef ]);

    const getLocale = useCallback(() => {
        return context.state.locale;
    }, [ context.state.locale ]);

    const getLocaleISOCode = useCallback(() => {
        const locale = context.state.locale;
        return !isEmpty(locale) ? locale.replace('_', '-') : '';
    }, [ context.state.locale ]);

    const getBusinessCode = useCallback((userInfo) => {
        // Germany is the only one with a different business code
        return userInfo?.countryCode === 'DE' ? 'D' : 'K';
    }, []);

    const userInfo = user();

    const countryCode = userInfo ? userInfo.countryCode : '';
    const defaultLanguage = userInfo ? userInfo.defaultLanguage : '';
    const forename = userInfo ? userInfo.forename : '';
    const surname = userInfo ? userInfo.surname : '';
    const emailAddress = userInfo ? userInfo.email_address : '';
    const locale = getLocale();
    const localeISOCode = getLocaleISOCode();
    const businessCode = getBusinessCode(userInfo);
    let [ language ] = getLocaleSections(i18n.language);

    return {
        login: login,
        loginViaSSO: loginViaSSO,
        logout: logout,
        user: user,
        countryCode: countryCode,
        defaultLanguage: defaultLanguage,
        forename: forename,
        surname: surname,
        language: language,
        locale: locale,
        emailAddress: emailAddress,
        localeISOCode: localeISOCode,
        status: status,
        sso: sso,
        setStatus: setStatus,
        isError: isError,
        isFailed: isFailed,
        isLocked: isLocked,
        isExpired: isExpired,
        isNoSite: isNoSite,
        getLocale: getLocale,
        isPasswordChanged: isPasswordChanged,
        responseStatus: responseStatus,
        userDataRegions: userDataRegions,
        countrySignIn: countrySignIn,
        setLogoutTimer: setLogoutTimer,
        businessCode: businessCode
    };
};

export { useAuthState, AuthProvider };
