import { useCallback, useEffect, useState } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import { useAuthState } from '../../contexts/AuthProvider';
import useApiRequest from '../requests/useApiRequest';

export const useGetHazWasteQuarterlyReturnsDocs = () => {
    const [ hazWasteQuarterlyReturnsDocs, setHazWasteQuarterlyReturnsDocs ] = useState(null);
    const { countryCode } = useAuthState();
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/hazardWasteService/quarterlyReturnsDocuments';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setHazWasteQuarterlyReturnsDocs(response?.data.Documents);
            } catch (error) {
                console.error('useGetHazWasteQuarterlyReturnsDocs error: ', error);
            }
        }
    }, [ responseStatus, response, setHazWasteQuarterlyReturnsDocs ]);

    const getHazWasteQuarterlyReturnsDocs = useCallback((contractNumber, premiseNumber) => {
        const url = [ endpoint, countryCode, contractNumber, premiseNumber ].join('/');
        makeRequestWithParams(url)
            .catch((error) => {
                console.error('useGetHazWasteQuarterlyReturnsDocs makeRequest: ', error);
            });
    }, [ makeRequestWithParams, countryCode ]);

    return [ hazWasteQuarterlyReturnsDocs, responseStatus, getHazWasteQuarterlyReturnsDocs ];
};
