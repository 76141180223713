import {useState, useEffect, useCallback} from 'react';
import useApiRequest from "./requests/useApiRequest";
import {ERROR, SUCCESS} from "./requests/requestReducer";

const COMPLETE = 'COMPLETE';

export const useResetPassword = () => {
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/loginService/validateEmailAndSendPassword/';
    const [resetStatus, setResetStatus] = useState(null);
    const regions = process.env.REACT_APP_BACKEND_REGIONS.split(',');
    const [loginRequestCount, setLoginRequestCount] = useState(0);

    const resetPassword = useCallback((email, language) => {
        setResetStatus();
        const url = endpoint + email +'/'+ language;
        regions.forEach(function (region) {
            makeRequestWithParams(url, 'get', {}, region).catch(error => {
                console.error('error resetPassword: ', error)
            });
        });

    }, [makeRequestWithParams, regions]);

    useEffect(() => {
        if (responseStatus === SUCCESS || responseStatus === ERROR) {
            // responseStatus === ERROR only if the backend is not available or it returns 500
            setLoginRequestCount(prevValue => prevValue + 1);
        }
    }, [responseStatus, response]);

    useEffect(() => {
        if (loginRequestCount === regions.length) {
            setResetStatus(COMPLETE);
            setLoginRequestCount(0);
        }
    }, [loginRequestCount, setLoginRequestCount, regions.length, setResetStatus]);

    return [resetStatus, responseStatus, resetPassword];

};