import { useState, useEffect, useCallback } from 'react';
import useApiRequest from '../requests/useApiRequest';
import { SUCCESS } from '../requests/requestReducer';
import { formatDate, isEmpty } from '../../utils/utils';

export const useWasteNotePdf = () => {
    const [ wasteNotePdf, setWasteNotePdf ] = useState({});
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/hazardWasteService/pdf';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setWasteNotePdf(response.data);
            } catch (error) {
                console.error('useWasteNotePdf  error: ', error);
                setWasteNotePdf({});
            }
        }
    }, [ responseStatus, response, setWasteNotePdf ]);

    const getWasteNoteUrl = (wasteNoteNumber, contractNumber, premiseNumber, visitDate) => {
        /* Due to there is no staging data for wasteNote, in the staging
        environment we had to use a preset noteNumber to test the download
        pdf
        */
        if (!isEmpty(process.env.REACT_APP_PRESET_NOTE_NUMBER) &&
            !isEmpty(process.env.REACT_APP_PRESET_SITE)) {
            wasteNoteNumber = process.env.REACT_APP_PRESET_NOTE_NUMBER;
            [ contractNumber, premiseNumber ] = process.env.REACT_APP_PRESET_SITE.split('/');
            visitDate = process.env.REACT_APP_PRESET_HAZ_WASTE_NOTE_DATE;
        }
        const urlParams = new URLSearchParams({
            noteNumber: wasteNoteNumber,
            contractNumber: contractNumber,
            premiseNumber: premiseNumber,
            visitDate: formatDate(visitDate, 'DD/MM/YYYY', 'DD/MM/YYYY')
        });
        return [ endpoint, `?${ urlParams.toString() }` ].join('/');
    };

    const getWasteNotePdf = useCallback((wasteNoteUrl) => {
        setWasteNotePdf({});
        makeRequestWithParams(wasteNoteUrl).catch((error) => {
            console.error('useWasteNotePdf error: ', error);
        });
    }, [ makeRequestWithParams ]);

    return [ wasteNotePdf, responseStatus, getWasteNoteUrl, getWasteNotePdf ];
};
