import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { FETCHING } from '../../../services/requests/requestReducer';
import MIGrid, { GetRowCount } from '../../Widgets/MIGrid';
import Link from '@material-ui/core/Link';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => {
    return {
        dataCell: {
            lineHeight: 0,
            width: '100%',
            whiteSpace: 'normal',
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
        },
        linkContainer: {
            display: 'inline-flex',
            float: 'right',
        },
        locDate: {
            fontSize: theme.fontSizes.mobileBody,
            display: 'inline-flex',
            fontFamily: theme.fontFamily.proximaBold,
        },
        noteTitle: {
            fontSize: theme.fontSizes.mobileBody,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        gridContainer: {
            '& div.MuiDataGrid-cell': {
                padding: theme.sizes.no_gap,
                whiteSpace: 'normal',
            },
            'marginBottom': theme.sizes.xxlarge_gap,
            'borderTop': theme.borders.outline_bold,
        },
        pdfIconContainer: {
            display: 'flex',
            marginLeft: '-3px',
            width: 'fit-content',
        },
        rowCountContainer: {
            display: 'flex'
        },
        rowCountText: {
            marginLeft: 'auto',
            marginTop: 'auto',
        },
    };
});

const PAGE_SIZE = 10;

const WasteTransferNotesTableMobile = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { responseStatus, rows } = props;

    const columns = [
        {
            field: 'title',
            type: 'string',
            resizable: false,
            flex: 3,
            renderCell: (params) => {
                return (
                    <div className={classes.dataCell}>
                        <Typography className={classes.locDate}>{params.row.date}</Typography>
                        <Typography className={classes.noteTitle}>{params.row.title}</Typography>
                    </div>
                );
            },
        },
        {
            field: 'download', headerName: t('download_note'), resizable: false, filterable: false, width: 40,
            sortable: false,
            renderCell: (params) => {
                return <div className={classes.linkContainer}>
                    <Link
                        href={params.row.downloadUrl}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                        aria-label={t('aria_label_transfer_notes_download_icon')}>
                        <div className={classes.pdfIconContainer}>
                            <GetAppIcon className={classes.icon}/>
                        </div>
                    </Link>

                </div>;
            }
            ,
        }
    ];

    return (
        <div>
            <div className={classes.rowCountContainer}>
                <div className={classes.rowCountText}>
                    {GetRowCount({ rows: rows, showRowCount: true })}
                </div>
            </div>
            <div className={classes.gridContainer}>
                <MIGrid
                    isMobile={true}
                    showInfoModal={true}
                    isLoading={responseStatus === FETCHING}
                    columns={columns}
                    rows={rows}
                    rowHeight={55}
                    hasFilters={false}
                    pagination={true}
                    autoPageSize={false}
                    pageSize={PAGE_SIZE}
                    headerHeight={0}
                    heightToFitContent={true}
                    hideFooterForOnePage={true}
                    hideFooter={false}
                    noEvenRowStyling={true}
                    noElementsText={t('no_waste_transfer_notes')}
                    ariaLabel={t('aria_label_waste_transfer_notes_table')}
                    ariaLabelProgressBar={t('aria_label_progressbar_waste_transfer_notes')}
                    waitFullyLoaded={true}
                    {...props}
                />
            </div>
        </div>
    );
};

export default WasteTransferNotesTableMobile;
