import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useContractDetails } from '../../../services/contract/useContractDetails';
import MIApiLoader from '../../../components/Widgets/MIApiLoader';
import { useAuthState } from '../../../contexts/AuthProvider';
import { observer } from 'mobx-react-lite';
import { useSiteStore } from '../../../contexts/StateProvider/StateProvider';
import { FETCHING } from '../../../services/requests/requestReducer';
import { ResponsiveComponent } from '../../../services/useResponsive';
import { isEmpty } from '../../../utils/utils';
import SiteLevelMobile from './variant/SiteLevelMobile';
import SiteLevelDesktop from './variant/SiteLevelDesktop';

export const createRow = (product) => {
    return {
        id: [ product.productCode, product.location ].join('_'),
        location: product.location ? product.location : '-',
        serviceGroup: product.serviceGroup ? product.serviceGroup : '-',
        product: product.productServiced ? product.productServiced.trim() : '-',
        productCode: product.productCode ? product.productCode : '-',
        quantity: product.qty ? product.qty : '-',
        visitsAnnum: product.visitsAnnum ? product.visitsAnnum : '-',
    };
};

export const getTables = (group) => {
    let tables = {};
    if (!isEmpty(group)) {
        group.forEach((groups) => {
            let groupName = groups.groupByItem;
            tables[groupName] = [];
            groups.prodItem.forEach((product) => {
                tables[groupName].push(createRow(product));
            });
        });
    }
    return tables;
};

const SiteLevel = observer(() => {
    const { t } = useTranslation();
    const [ contractDetails, responseStatus, getContractDetails, contractGroupId ] = useContractDetails();
    const { locale, countryCode } = useAuthState();
    const siteStore = useSiteStore();

    useEffect(() => {
        if (!isEmpty(siteStore.selectedSiteId) && !isEmpty(locale) && !isEmpty(countryCode)) {
            getContractDetails(siteStore.selectedSiteId, countryCode, locale);
        }
    }, [ siteStore.selectedSiteId, locale, getContractDetails, countryCode ]);

    return (
        <MIApiLoader
            customStyle={{
                marginTop: '140px',
            }}
            MIComponent={
                <ResponsiveComponent
                    components={{
                        mobile:
                            <SiteLevelMobile
                                contractDetails={contractDetails}
                                contractGroupId={contractGroupId}
                            />,
                        desktop:
                            <SiteLevelDesktop
                                contractDetails={contractDetails}
                                contractGroupId={contractGroupId}
                            />,
                    }}
                />
            }
            responseStatus={ !siteStore.hasSelectedSite ? FETCHING : responseStatus }
            ariaLabelProgressBar={ t('aria_label_progressbar_site_level') }
        />
    );
});

export default SiteLevel;
