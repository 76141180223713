import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MIGrid, {STYLE_HEADER_CENTER, RenderTooltip, CLICKABLE_ICON_CELL} from '../../Widgets/MIGrid';
import {observer} from 'mobx-react-lite';
import {useBaseStyles} from '../Recommendations';
import MIColoredIcon from '../../Widgets/MIColoredIcon'; 
import {isEmpty} from '../../../utils/utils';

const useStyles = makeStyles(theme => ({
    signatureIcon: {
        margin: 'auto',
        textAlign: 'center',
        color: 'grey',
    },
}));

const RecommendationsDesktop = observer((props) => {
    const {t} = useTranslation();
    const baseStyles = useBaseStyles();
    const classes = {...baseStyles, ...useStyles()};
    const {myRentokilSiteId, goToRecommendationDetails, PNOLShutdownCountries} = props;
    
    const columns = [
        {
            field: 'locStatus',
            headerName: t('status'),
            type: 'string',
            resizable: false,
            flex: 2.1,
            description: t('status'),
            renderCell: (params) => <MIColoredIcon item={params.row.status}/>
        },
        {
            field: 'priority',
            headerName: t('priority'),
            type: 'string',
            resizable: false,
            flex: 1.5,
            description: t('priority'),
            renderCell: RenderTooltip
        },
        {
            field: 'date', 
            headerName: t('date'), 
            type: 'date', 
            resizable: false, 
            flex: 2.2,
            description: t('date'),
            renderCell: (params) => (
                <span>{!isEmpty(params.row.locDate) ? params.row.locDate : '-'}</span>
            )
        },
        {
            field: 'ref',
            headerName: t('reference'),
            type: 'string',
            resizable: false,
            flex: 1.5,
            description: t('reference'),
            renderCell: RenderTooltip
        },
        {
            field: 'type',
            headerName: t('type'),
            type: 'string',
            resizable: false,
            flex: 2,
            description: t('type'),
            renderCell: RenderTooltip
        },
        {
            field: 'location',
            headerName: t('location'),
            type: 'string',
            resizable: false,
            flex: 4,
            description: t('location'),
            renderCell: RenderTooltip
        },
        {
            field: 'visitDetails', headerName: t('details'), resizable: false, filterable: false, flex: 1,
            sortable: false,
            headerClassName: STYLE_HEADER_CENTER,
            cellClassName: CLICKABLE_ICON_CELL,
            description: t('details'),
            renderCell: (params) => (
                <div className={classes.linkContainer}>
                    <Link 
                        className={classes.selectLink} 
                        to={{
                            pathname: '/service/recommendations/recommendation-details',
                            state: {data: params.row, myRentokilSiteId: myRentokilSiteId, PNOLShutdownCountries: isEmpty(PNOLShutdownCountries) ? '' : PNOLShutdownCountries.toString()}
                        }}
                        aria-label={t('aria_label_recommendations_button')}
                    >
                        <AssignmentIcon/>
                    </Link>
                </div>
            ),
        }
    ]

    const handleCellClick = (selectedCell) => {
        //Tried using type but did not work, so using cellClassName to distinguish clickable icons whose functionality should not be overridden
        if(selectedCell.colDef.cellClassName !== CLICKABLE_ICON_CELL){
            goToRecommendationDetails(selectedCell.row)
        }
    };

    return (
        <MIGrid
            showInfoModal={true}
            heightToFitContent={true}
            colCellFlex={true}
            columns={columns}
            noElementsText={t('no_recommendations')}
            ariaLabel={t('aria_label_recommendations_table')}
            ariaLabelProgressBar={t('aria_label_progressbar_recommendations')}
            onCellClick={handleCellClick}
            hasFilters={true}
            hideFooterRowCount={true}
            pagination={true}
            {...props}
        />
    )
});

export default RecommendationsDesktop;
