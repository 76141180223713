import {useReducer, useCallback} from "react";
import axios from "axios";
import reducer, {initialState} from "./requestReducer";
import {ERROR, FETCHING, SUCCESS} from "./requestReducer";

export const fetching = () => ({type: FETCHING});
export const success = response => ({type: SUCCESS, response});
export const error = response => ({type: ERROR, response});

const useRawRequest = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const axiosClient = axios.create();

    const makeRequestWithParams = useCallback(async (url, requestMethod = 'get', requestParams = {}) => {
        dispatch(fetching());
        try {
            const response = await axiosClient[requestMethod](url, requestParams);
            dispatch(success(response));
        } catch (e) {
            dispatch(error(e));
        }
        // Wrapping the api variable in useCallback would make the code unnecessarily complex. Better to disable the warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [state, makeRequestWithParams];
};

export default useRawRequest;
