import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import BackgroundImage from '../../assets/landing_banner_small_desktop.png';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    logo: {
        background: theme.palette.primary.main,
        height: "160px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "calc(10px + 2vmin)",
        color: "white",
    },
    banner: {
        width: '100%',
        height: '160px',
    },
}));

const HomeBanner = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <div className={classes.logo}>
            <img 
                src={BackgroundImage} 
                alt='myInitial' 
                className={classes.banner}
                aria-label={t('aria_label_banner')}
            />
        </div>
    );
}

export default HomeBanner;
