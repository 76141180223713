import {useState, useEffect, useCallback} from 'react';
import useApiRequest from "./requests/useApiRequest";
import {SUCCESS} from "./requests/requestReducer";

export const useSSOData = () => {
    const [sso, setSso] = useState(null);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setSso(response.data);
            } catch (error) {
                console.error('useSSO error: ', error);
            }
        }
    }, [responseStatus, response, setSso]);

    const getSSO = useCallback((email_address, countryCode, defaultLanguage) => {
        const params = {
            email: email_address,
            country: countryCode,
            language: defaultLanguage
        }
        makeRequestWithParams('/konySSO', 'post', params).catch(error => {console.error('useSSO makeRequest: ', error)});
    },[makeRequestWithParams])

    return [sso, responseStatus, getSSO];
};
