import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import { setDocumentTitle, isEmpty } from '../../../utils/utils';
import { USAGE_TIME_PERIOD } from '../../../utils/smartHygieneUtils';
import SiteSelector from '../../../components/SiteSelector/SiteSelector';
import MIPageTitle from '../../../components/Widgets/MIPageTitle';
import { ResponsiveComponent, useResponsive } from '../../../services/useResponsive';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Benefits from './Benefits';
import MIAlert from '../../../components/Widgets/MIAlert';
import RefillEstimates from './RefillEstimates';
import InteractionPatterns, { getEnabledTypes, parseGraphData } from './InteractionPatterns';
import { useSiteStore } from '../../../contexts/StateProvider/StateProvider';
import { useBenefitUnits } from '../../../services/smartHygiene/useBenefitUnits';
import { useBenefitVolumes } from '../../../services/smartHygiene/useBenefitVolumes';
import { observer } from 'mobx-react-lite';
import { useRefillLevels } from '../../../services/smartHygiene/useRefillLevels';
import ExportPDF from './ExportPDF';
import { useInteractions } from '../../../services/smartHygiene/useInteractions';
import { INTERACTION_TYPES, TIME_PERIOD } from './SmartHygieneEnum';
import { FETCHING } from '../../../services/requests/requestReducer';

const desktopStyles = (theme) => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: theme.sizes.large_gap,
            marginTop: theme.sizes.tiny_gap,
        },
        hygieneDownload: {
            color: theme.palette.primary.main,
            textTransform: 'none',
            fontSize: theme.typography.body1.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            marginInlineEnd: theme.sizes.tiny_gap,
        },
        timePeriodSelector: {
            'width': '200px',
            '&& div.MuiOutlinedInput-root': {
                borderRadius: theme.sizes.no_gap,
            },
            'marginTop': theme.sizes.nano_gap,
        },
        benefits: {
            display: 'inline-block',
        },
        estimates: {
            marginBottom: theme.sizes.large_gap,
        },
        titleText: {
            flexGrow: 1,
        },
        pdfOuter: {
            marginInlineEnd: theme.sizes.small_gap,
            marginTop: theme.sizes.nano_gap,
        },
        savingDisclaimer: {
            border: '1px solid grey',
            background: theme.palette.background.highlight,
            marginTop: theme.sizes.xlarge_gap,
            padding: `${theme.sizes.small_gap} ${theme.sizes.tiny_gap}`,
        }
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: '100vh',
            paddingTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.no_gap,
            paddingBottom: theme.sizes.no_gap,
        },
    });
};

export const getUsageData = (t, volumeResults, unitResults) => {
    return {
        water: {
            used: {
                value: !isEmpty(volumeResults?.volumes?.waterVolume) ? volumeResults.volumes.waterVolume : 0,
                label: 'liters'
            },
            units: {
                value: !isEmpty(unitResults?.units?.rapid_water) ? unitResults.units.rapid_water : 0,
            },
            saved: {
                value: !isEmpty(volumeResults?.volumes?.waterSaved) ? volumeResults.volumes.waterSaved : 0,
                label: 'liters'
            },
        },
        foam: {
            used: {
                value: !isEmpty(volumeResults?.volumes?.foamVolume) ? volumeResults.volumes.foamVolume : 0,
                label: 'liters'
            },
            units: {
                value: !isEmpty(unitResults?.units?.rapid_foam) ? unitResults.units.rapid_foam : 0,
            },
            saved: {
                value: !isEmpty(volumeResults?.volumes?.foamSaved) ? volumeResults.volumes.foamSaved : 0,
                label: 'liters'
            },
        },
        sanitiser: {
            used: {
                value: !isEmpty(volumeResults?.volumes?.sanitiserVolume) ? volumeResults.volumes.sanitiserVolume : 0,
                label: 'liters'
            },
            units: {
                value: !isEmpty(unitResults?.units?.rapid_sanitiser) ? unitResults.units.rapid_sanitiser : 0,
            },
            saved: {
                value: !isEmpty(volumeResults?.volumes?.sanitiserSaved) ? volumeResults.volumes.sanitiserSaved : 0,
                label: 'liters'
            },
        },
        blu: {
            used: {
                value: !isEmpty(volumeResults?.volumes?.bluVolume) ? volumeResults.volumes.bluVolume : 0,
                label: 'liters'
            },
            units: {
                value: !isEmpty(unitResults?.units?.rapid_flush) ? unitResults.units.rapid_flush : 0,
            },
            saved: {
                value: 0,
            },
        },
    };
};

const SmartHygiene = observer(() => {
    const { t } = useTranslation();
    const { getStyles } = useResponsive();
    const [ timePeriod, setTimePeriod ] = useState('last_year');
    const [ isShowing, setIsShowing ] = useState(false);
    const [ alert, setAlert ] = useState({});

    const [ volumeResults, volumeResponseStatus, getBenefitVolumes ] = useBenefitVolumes();
    const [ unitResults, , getBenefitUnits ] = useBenefitUnits();
    const [ refillValues, , getRefillLevels ] = useRefillLevels();
    const siteStore = useSiteStore();
    const [ interactionsPeriod, setInteractionsPeriod ] = useState(TIME_PERIOD.BY_MONTH);
    const [ interactionTypes, setInteractionTypes ] = useState(INTERACTION_TYPES);
    const [ interactions, interactionsStatus, getInteractions ] = useInteractions();

    useEffect(() => {
        if(!isEmpty(siteStore) && !isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber)) {
            getInteractions(siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, interactionsPeriod.apiEndpoint);
        }
    }, [ siteStore, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, getInteractions, interactionsPeriod ]);

    useEffect(() => {
        if(!isEmpty(siteStore) && !isEmpty(siteStore.selectedContractNumber) && !isEmpty(siteStore.selectedPremiseNumber)) {
            getBenefitVolumes(siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, timePeriod);
            getBenefitUnits(siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, timePeriod);
            getRefillLevels(siteStore.selectedContractNumber, siteStore.selectedPremiseNumber);
        }
    }, [ siteStore, siteStore.selectedContractNumber, siteStore.selectedPremiseNumber, getBenefitUnits, getBenefitVolumes, timePeriod, getRefillLevels ]);

    const getTimePeriods = () => {
        return [
            <MenuItem
                key={USAGE_TIME_PERIOD.LAST_YEAR}
                value={USAGE_TIME_PERIOD.LAST_YEAR}>
                {t(USAGE_TIME_PERIOD.LAST_YEAR)}
            </MenuItem>,
            <MenuItem
                key={USAGE_TIME_PERIOD.LAST_30_DAYS}
                value={USAGE_TIME_PERIOD.LAST_30_DAYS}>
                {t(USAGE_TIME_PERIOD.LAST_30_DAYS)}
            </MenuItem>,
            <MenuItem
                key={USAGE_TIME_PERIOD.SINCE_INSTALL}
                value={USAGE_TIME_PERIOD.SINCE_INSTALL}>
                {t(USAGE_TIME_PERIOD.SINCE_INSTALL)}
            </MenuItem>,
        ];
    };

    const changeTimePeriod = (event) => {
        setTimePeriod(event.target.value);
    };

    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    });

    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_smart_hygiene');
    }, [ t ]);

    const getBody = () => {
        return (
            <>
                <div className={classes.titleContainer}>
                    <div className={classes.titleText}>
                        <MIPageTitle
                            title={t('smart_hygiene')}
                            ariaLabel={t('aria_label_smart_hygiene')}
                            marginTop={8}
                            marginBottom={0}
                        />
                    </div>
                    <MIAlert
                        isShowing={isShowing}
                        setIsShowing={setIsShowing}
                        message={t(alert.message)}
                        severity={alert.severity}
                        ariaLabel={t('aria_label_operation_response_dialog')}
                    />
                    <div className={classes.pdfOuter}>
                        <ExportPDF
                            disabled={isEmpty(volumeResults) || interactionsStatus === FETCHING}
                            setAlert={setAlert}
                            setIsShowing={setIsShowing}
                            type={'pdf'}
                            siteName={siteStore?.selectedSite?.siteName}
                            onComplete={() => {
                                console.log('close');
                            }}
                            benefitsData={getUsageData(t, volumeResults, unitResults)}
                            benefitsPeriod={timePeriod}
                            refillValues={refillValues}
                            interactionTypes={interactionTypes}
                            interactionsPeriod={interactionsPeriod}
                            patternData={parseGraphData(
                                t, interactionsPeriod.name, interactionsPeriod.options, getEnabledTypes(interactionTypes),
                                interactions[interactionsPeriod.apiReturn]
                            )}
                        />
                    </div>
                    <div className={classes.timePeriodSelector}>
                        <FormControl variant={'outlined'} fullWidth={true}>
                            <Select
                                value={timePeriod}
                                onChange={changeTimePeriod}
                            >
                                {getTimePeriods()}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <Benefits
                    className={classes.benefits}
                    usageData={getUsageData(t, volumeResults, unitResults)}
                    apiStatus={volumeResponseStatus}
                />
                <RefillEstimates className={classes.estimates} refillEstimates={refillValues}/>
                <InteractionPatterns
                    interactions={interactions}
                    responseStatus={interactionsStatus}
                    timePeriod={interactionsPeriod}
                    setTimePeriod={setInteractionsPeriod}
                    interactionTypes={interactionTypes}
                    setInteractionTypes={setInteractionTypes}
                />
                <div className={classes.savingDisclaimer}>
                    {t('saving_estimate_disclaimer')}
                </div>
            </>
        );
    };

    return (
        <Paper className={classes.root} aria-label={t('aria_label_smart_hygiene_page')}>
            <SiteSelector/>
            <ResponsiveComponent
                components={{
                    mobile: null,
                    desktop: getBody()
                }}/>
        </Paper>
    );
});

export default SmartHygiene;
