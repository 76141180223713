import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Chart from 'react-google-charts';
import MISpinner from '../../../components/Widgets/MISpinner';
import { INTERACTION_COLORS, INTERACTION_TYPES, NO_INTERACTION_TYPE } from './SmartHygieneEnum';

const getColors = (data) => {
    const colors = [];
    data[0].forEach((column) => {
        switch(column) {
        case INTERACTION_TYPES.BLU.NAME:
            colors.push(INTERACTION_COLORS.BLU);
            break;
        case INTERACTION_TYPES.SANITISER.NAME:
            colors.push(INTERACTION_COLORS.SANITISER);
            break;
        case INTERACTION_TYPES.FOAM.NAME:
            colors.push(INTERACTION_COLORS.FOAM);
            break;
        case INTERACTION_TYPES.WATER.NAME:
            colors.push(INTERACTION_COLORS.WATER);
            break;
        case NO_INTERACTION_TYPE.NO_DATA:
            colors.push(INTERACTION_COLORS.NULL);
            break;
        default:
            break;
        }
    });
    return colors;
};

const InteractionChart = (props) => {
    const { data, width } = props;
    const theme = useTheme();
    return (
        <Chart
            width={width ? width : theme.widths.mainPageWidth}
            height={theme.heights.interaction_chart}
            chartType="ColumnChart"
            loader={
                <MISpinner
                    customStyle={{
                        spinnerWidth: '24px',
                        spinnerHeight: '24px',
                    }}/>
            }
            data={data}
            options={{
                chartArea: { left: 50, top: 10, width: '100%', height: '90%' },
                legend: { position: 'none' },
                colors: getColors(data),
                hAxis: {
                    textStyle: {
                        color: theme.palette.text.primary,
                        fontName: theme.typography.fontFamily,
                        fontSize: 14,
                    }
                },
                vAxis: {
                    textStyle: {
                        color: theme.palette.text.primary,
                        fontName: theme.typography.fontFamily,
                        fontSize: 14,
                    }
                },
            }}
            // For tests
            rootProps={{ 'data-testid': '1' }}
        />
    );
};

export default InteractionChart;
