import { makeAutoObservable } from 'mobx';
import { getLocaleSections, isEmpty } from '../../utils/utils';
import moment from 'moment';
import 'moment/min/locales';

export const LOCAL_STORAGE_KEY = 'language_key';
export const SUPPORTED_LANGUAGES = [ 'en', 'it', 'id', 'no', 'es', 'ko', 'de', 'pt', 'th', 'fr', 'zh', 'nl'];
export const FALLBACK_LANGUAGE = 'en';


class LanguageStore {
    constructor(regionStore) {
        this.regionStore = regionStore;
        this.language = localStorage.getItem(LOCAL_STORAGE_KEY);
        makeAutoObservable(this);
    }

    getCountryCode(countryCode) {
        switch(countryCode) {
        case 'uk':
            return 'gb';
        default:
            return countryCode.toLowerCase();
        }
    }

    getFormattedLanguage(language) {
        // The kony lang code for Indonesia is wrong, it should be id' while instead kony returns 'in'
        const formattedLanguages = {
            in: 'id',
        };
        return !isEmpty(formattedLanguages[language]) ? formattedLanguages[language] : language;
    }

    setLanguage(locale, i18n) {
        const [ language ] = this.getFormattedLanguage(getLocaleSections(locale));
        const countryCode = this.getCountryCode(this.regionStore.getCurrentCountryCode());

        const fullLocale = !isEmpty(countryCode) ? [ language, countryCode ].join('-') : language;
        this.language = fullLocale;
        i18n.changeLanguage(fullLocale);
        if (!isEmpty(this.language)) {
            localStorage.setItem(LOCAL_STORAGE_KEY, fullLocale);
        }
    }

    resetLanguage(i18n) {
        const language = this.getBrowserLanguage();
        this.setLanguage(language, i18n);
    }

    getBrowserLanguage() {
        // isEmpty check is not applied to window.navigator because it is an edge case which returns isEmpty true even though it is not. It fails at the serialization part of the isEmpty utils method.
        const browserLanguage =
            !isEmpty(window) && !isEmpty(window.navigator.language) ?
                window.navigator.language.toLowerCase() : '';
        const [ language ] = getLocaleSections(browserLanguage);
        return this.getSupportedLanguage(language);
    }

    updateLanguageOnLogin(userDefaultLanguage, i18n) {
        // if the language is not set or the language set is different than the one used by the user change the language.
        // (userDefaultLanguage is default language saved in the kony backend)
        // userDefaultLanguage does not contain any lang variations at the moment so extract the base language from this.language
        const [ language ] = getLocaleSections(this.language);
        if (isEmpty(language) || (!isEmpty(language) && !isEmpty(userDefaultLanguage) && language !== userDefaultLanguage)) {            
            let lng = null;
            if (isEmpty(userDefaultLanguage)) {
                lng = this.getBrowserLanguage();
            } else {
                lng = this.getSupportedLanguage(userDefaultLanguage);
            }
            this.setLanguage(lng, i18n);
        } else {
        // the lang is the same but we need to update the country
            this.setLanguage(i18n.language, i18n);
        }
    }

    getSupportedLanguage(language) {
        const formattedLanguage = this.getFormattedLanguage(language);
        return SUPPORTED_LANGUAGES.includes(formattedLanguage) ? formattedLanguage : FALLBACK_LANGUAGE;
    }

    get momentLocale() {
        let locale;

        if(this.language.toLocaleLowerCase() === 'en-us'){
            return 'en-us';
        }

        let [ language ] = getLocaleSections(this.language.toLocaleLowerCase());
        switch(language) {
            // moment uses en-us as default for en, we want to use en-gb as default instead
            case 'en':
                locale = 'en-gb';
                break;
            case 'in':
                //indonesia locale in moment is id
                locale = 'id';
                break;
            case 'no':
                //Norway locale in moment is nn
                locale = 'nn';
                break;
            case 'zh':
                locale = 'zh-hk';
                break;
            default:
                locale = language;
        }
        return locale;
    }

    importMomentLocale() {

        if (!isEmpty(this.language)) {
            // most of moment locales are with the 'lang' only format instead of 'lang-country'
            // we use this function to map any known difference between the locale and the moment locale
            const locale = this.momentLocale;
            // this import is mainly used to set the calendar in the correct language
            // the date format is retrieved using i18n with getLocalizedDateFormatString
            import(`moment/locale/${locale}`).then(() => {
                moment.locale(locale);
            }).catch(() => {
                // The locale for en-us is just the default 'en', which does not need to be imported
                const fallbackLanguage = locale === 'en-us' ? 'en' : FALLBACK_LANGUAGE;
                moment.locale(fallbackLanguage)
            });
        }
    };
}

export { LanguageStore };
