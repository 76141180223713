import { useEffect, useState, useCallback } from 'react';
import useApiRequest from '../requests/useApiRequest';
import { SUCCESS } from '../requests/requestReducer';
import { useContractStore } from '../../contexts/StateProvider/StateProvider';
import { useFlags } from '../../contexts/FlagsProvider/FlagsProvider';
import { isEmpty } from '../../utils/utils';
import { useTranslation } from 'react-i18next';

export const SERVICE_GROUP_ID = 'SG';
export const LOCATION_ID = 'LOC';
export const CONSUMABLES_ID = 'CN';

export const useContractDetails = () => {
    const { t } = useTranslation();
    const contractStore = useContractStore();
    const [ currentSiteId, setCurrentSiteId ] = useState(null);
    const [ contractDetails, setContractDetails ] = useState(null);
    const [ tempContractDetails, setTempContractDetails ] = useState(null);
    const [ requestCount, setRequestCount ] = useState(0);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const { feature_contract_details_loc, feature_contract_details_cn } = useFlags();
    const featureCount = [ feature_contract_details_loc, feature_contract_details_cn ].filter(Boolean).length + 1;
    const currentResponseStatus = contractDetails !== null && requestCount === featureCount ? SUCCESS : responseStatus;
    const endpoint = '/premiseService/premiseContractDetail';

    const contractGroupId = [
        { id: SERVICE_GROUP_ID, name: t('service_group_name'), enabled: true },
        { id: LOCATION_ID, name: t('location_name'), enabled: true },
        { id: CONSUMABLES_ID, name: t('consumables_name'), enabled: feature_contract_details_cn }
    ];


    useEffect(() => {
        if (requestCount === featureCount) {
            setContractDetails(tempContractDetails);
            contractStore.setSiteContractDetail(currentSiteId, tempContractDetails);
        }
    }, [ requestCount, setContractDetails, tempContractDetails, featureCount, contractStore, currentSiteId ]);

    useEffect(() => {
        if (responseStatus === SUCCESS && !isEmpty(currentSiteId)) {
            try {
                contractGroupId.forEach((groupElement) => {
                    if (response.request.responseURL.includes(`/${ groupElement.id }/`)) {
                        setTempContractDetails((currentContractDetails) => {
                            const oldContractDetails = isEmpty(currentContractDetails) ? {} : currentContractDetails;
                            return Object.assign(oldContractDetails, { [groupElement.id]: response.data.product });
                        });
                        setRequestCount((prevValue) => {
                            return prevValue + 1;
                        });
                    }
                });
            } catch (error) {
                console.error('useContractDetail error: ', error);
            }
        }
    }, [ responseStatus, response, setTempContractDetails, currentSiteId ]);

    const resetState = useCallback(() => {
        setContractDetails(null);
        setTempContractDetails(null);
        setRequestCount(0);
    }, [ setContractDetails, setTempContractDetails, setRequestCount ]);

    const makeRequest = useCallback(({ storedContractDetail, groupId, siteId, locale, countryCode }) => {
        if (!isEmpty(storedContractDetail) && !isEmpty(storedContractDetail[groupId])) {
            // retrieve the cached result and increase the request count
            setTempContractDetails((currentContractDetails) => {
                return Object.assign(isEmpty(currentContractDetails) ? {} : currentContractDetails, { [groupId]: storedContractDetail[groupId] });
            });
            setRequestCount((prevValue) => {
                return prevValue + 1;
            });
        } else {
            const url = [ endpoint, siteId, groupId, countryCode, locale ].join('/');
            makeRequestWithParams(url).catch((error) => {
                console.error('useContractDetail error: ', error);
            });
        }
    }, [ makeRequestWithParams ]);

    const getContractDetails = useCallback((siteId, countryCode, locale) => {
        const storedContractDetail = contractStore.getSiteContractDetail(siteId);
        resetState();
        setCurrentSiteId(siteId);
        contractGroupId.forEach((groupElement) => {
            const groupId = groupElement.id;
            const params = { storedContractDetail: storedContractDetail, groupId: groupId, siteId: siteId, locale: locale, countryCode: countryCode };
            if (groupElement.enabled) {
                makeRequest(params);
            }
        });
    }, [ contractStore,
        resetState,
        setCurrentSiteId,
        feature_contract_details_cn,
        feature_contract_details_loc,
        makeRequest ]);

    return [ contractDetails, currentResponseStatus, getContractDetails, contractGroupId ];
};
