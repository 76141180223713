import React, { useEffect } from 'react';
import { useAuthState } from '../../../contexts/AuthProvider';
import { useContractInfo } from '../../../services/contract/useContractInfo';
import { isEmpty, getSiteAddress, getLocalizedDate, getFormattedCurrency } from '../../../utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import MIInfoBlock from '../../../components/Widgets/MIInfoBlock';
import { useTranslation } from 'react-i18next';
import SiteGrid from '../../../components/SiteSelector/SiteGrid';
import { observer } from 'mobx-react-lite';
import { useSiteStore } from '../../../contexts/StateProvider/StateProvider';
import Theme from '../../../components/Theme/Theme';
import { useExtraContractDetails } from '../../../services/contract/useExtraContractDetails';
import { useFlags } from '../../../contexts/FlagsProvider/FlagsProvider';
import { FETCHING } from '../../../services/requests/requestReducer';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Typography } from '@material-ui/core';
import { ResponsiveComponent, useResponsive } from '../../../services/useResponsive';
import ProductTableMobile from './variant/ProductTableMobile';
import ProductTableDesktop from './variant/ProductTableDesktop';

const desktopStyles = (theme) => {
    return {
        associatedTitle: {
            marginTop: theme.sizes.small_gap,
            marginBottom: theme.sizes.small_gap,
            marginLeft: theme.sizes.micro_gap,
            marginRight: theme.sizes.micro_gap,
            fontSize: theme.sizes.small_gap,
        },
        associatedContainer: {
            border: `1px solid ${ theme.palette.grey['300']}`,
        },
        groupName: {
            'fontSize': theme.typography.h4.fontSize,
            'fontFamily': theme.typography.h2.fontFamily,
            'color': theme.palette.text.primary,
            'marginTop': theme.sizes.large_gap,
            '&:focus': theme.ariaFocus,
        },
    };
};

const mobileStyles = (theme) => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            border: 'none',
            paddingBottom: theme.sizes.large_gap,
            paddingRight: theme.sizes.no_gap,
            paddingLeft: theme.sizes.no_gap,
            maxHeight: 'none',
        },
        associatedTitle: {
            marginTop: theme.sizes.small_gap,
            fontSize: theme.fontSizes.mobileTitle,
        },
        associatedContainer: {
            borderBottom: `1px solid ${ theme.palette.grey['300']}`,
        },
        dataCell: {
            lineHeight: 0,
            width: '100%'
        },
        quantity: {
            fontSize: theme.fontSizes.mobileLabel,
            display: 'inline-flex',
            fontFamily: theme.fontFamily.proximaBold,
        },
        product: {
            fontSize: theme.fontSizes.mobileBody,
            overflowWrap: 'break-word',
        },
        linkContainer: {
            display: 'inline-flex',
            justifyContent: 'flex-end',
            width: '100%'
        },
        selectLinkMobile: {
            height: theme.sizes.large_gap,
            width: theme.sizes.large_gap,
            color: theme.palette.primary.main
        },
    });
};

const ContractLevel = observer(() => {
    const { t } = useTranslation();
    const [ contractInfo, contractInfoStatus, getContractInfo ] = useContractInfo();
    const [ extraContractDetails, extraContractDetailsStatus, getExtraContractDetails ] = useExtraContractDetails();
    const { countryCode, locale, localeISOCode } = useAuthState();
    const siteStore = useSiteStore();
    const contractId = !isEmpty(siteStore.selectedSite) ? siteStore.selectedSite.contractId : null;
    const { feature_contract_start_date_and_value, feature_contract_expiry } = useFlags();

    const { getStyles } = useResponsive();
    const useStyles = makeStyles((theme) => {
        return getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        });
    }
    );
    const classes = useStyles();

    useEffect(() => {
        if (siteStore.selectedSiteId && !isEmpty(contractId) && !isEmpty(locale) && !isEmpty(countryCode)) {
            getContractInfo(contractId, locale, countryCode);
            if(feature_contract_start_date_and_value || feature_contract_expiry) {
                getExtraContractDetails(contractId, countryCode);
            }
        }
    }, [
        getExtraContractDetails,
        getContractInfo,
        siteStore.selectedSiteId,
        contractId,
        locale,
        countryCode,
        feature_contract_start_date_and_value,
        feature_contract_expiry
    ]);

    const getAddress = (details) => {
        const _premise = {
            siteAddress1: details.premiseAddress1,
            siteAddress4: details.premiseAddress4,
            premisePinCode: details.premisePinCode,
        };
        return getSiteAddress(_premise, countryCode);
    };

    const getAnnualValue = (contractAnnualValue, localeISOCode, countryCode) => {
        return !isEmpty(contractAnnualValue) ? getFormattedCurrency(contractAnnualValue, localeISOCode, countryCode) : '-';
    };

    const getQuantityAndProduct = (params) => {
        return (
            <div className={classes.dataCell}>
                <Typography className={classes.quantity}>{[ t('quantity'), params.row.quantity ].join(': ')}</Typography>
                <Typography className={classes.product}>{params.row.product}</Typography>
            </div>
        );
    };

    const getInfo = () => {
        let info = [];
        if (!isEmpty(contractInfo) && !isEmpty(contractInfo.contractDetails)) {
            const details = contractInfo.contractDetails[0];
            info.push({ label: t('info_account_no'), value: details.acntNo || '-' });
            info.push({ label: t('info_contract_no'), value: details.contractNo || '-' });
            info.push({ label: t('info_address'), value: getAddress(details) });
        }
        if (!isEmpty(extraContractDetails)) {
            if(feature_contract_start_date_and_value) {
                info.push({
                    label: t('contract_commence_date'),
                    value: !isEmpty(extraContractDetails.ContractCommenceDate) ? getLocalizedDate(t, extraContractDetails.ContractCommenceDate) : '-'
                });
                info.push({
                    label: t('contract_annual_value'),
                    value: getAnnualValue(extraContractDetails.ContractAnnualValue, localeISOCode, countryCode)
                });
            }

            if(feature_contract_expiry) {
                // do to show this field is empty
                if (!isEmpty(extraContractDetails.ContractExpiryDate)) {
                    info.push({
                        label: t('renewal_date'),
                        value: getLocalizedDate(t, extraContractDetails.ContractExpiryDate)
                    });
                }
            }
        }
        return info;
    };

    const handleRowSelection = (selection) => {
        siteStore.setSelectedSiteId(selection.data.id);
    };

    const columnsDesktop = [
        {
            field: 'product',
            headerName: t('product_type'),
            description: t('product_type'),
            type: 'string',
            resizable: false,
            flex: 4
        },
        {
            field: 'quantity',
            headerName: t('quantity'),
            description: t('quantity'),
            type: 'number',
            resizable: false,
            flex: 1,
        },
        {
            field: 'visitsAnnum',
            headerName: t('visit_per_year'),
            description: t('visit_per_year'),
            type: 'number',
            resizable: false,
            flex: 1,
        },
    ];

    const columnsMobile = [
        {
            field: 'quantityAndProduct',
            type: 'string',
            resizeable: false,
            flex: 3,
            renderCell: (params) => {
                return getQuantityAndProduct(params);
            }
        },
        {
            field: 'info',
            resizable: false,
            filterable: false,
            width: 40,
            sortable: false,
            renderCell: (params) => {
                return <div className={ classes.linkContainer }>
                    <InfoOutlinedIcon className={ classes.selectLinkMobile }/>
                </div>;
            }
            ,
        }
    ];

    const getRows = (data) => {
        let rows = [];
        data.forEach((record) => {
            rows.push({
                id: rows.length,
                product: record.productServiced,
                quantity: record.qty,
                visitsAnnum: record.visitsAnnum,
            });
        });
        return rows;
    };

    const getDataFromProductRow = (selectedRow) => {
        if (!isEmpty(selectedRow.data)) {
            const info = selectedRow.data;
            return [
                { label: t('product'), value: info.product },
                { label: t('quantity'), value: info.quantity },
                { label: t('visit_per_year'), value: info.visitsAnnum },
            ];
        }
        return [];
    };

    const productsSection = () => {
        let products = [];
        if (contractInfo && contractInfo.productList) {
            contractInfo.productList.forEach((product) => {
                const rows = getRows(product.productDetails);
                products.push(
                    <div key={product.group}>
                        <ResponsiveComponent
                            components={{
                                mobile:
                                    <ProductTableMobile
                                        groupName={product.groupDesc}
                                        rows={rows}
                                        columns={columnsMobile}
                                        hideFooterForOnePage={true}
                                        pageSize={5}
                                        getDataFromRow={getDataFromProductRow}
                                    />,
                                desktop: <ProductTableDesktop groupName={product.groupDesc} rows={rows} columns={columnsDesktop}/>,
                            }}
                        />
                    </div>
                );
            });
        }

        return (
            <div>
                {products}
            </div>
        );
    };

    return (
        <div>
            <MIInfoBlock
                infoStatus={contractInfoStatus}
                optionalCondition={(feature_contract_start_date_and_value || feature_contract_expiry) && (isEmpty(extraContractDetailsStatus) || extraContractDetailsStatus === FETCHING)}
                info={getInfo()}
                backgroundColor={Theme.palette.background.default}
            />
            <h2 className={classes.associatedTitle}>{t('associated_sites')}</h2>
            <div className={classes.associatedContainer}>
                <SiteGrid
                    aria-label={t('aria_label_site_list_table')}
                    desktopRowHeight={38}
                    hideContractNo={true}
                    hideLastVisit={true}
                    hideVisitHistoryLink={true}
                    hasFilters={false}
                    hideFooterSelectedRowCount={true}
                    mobilePageSize={5}
                    hideFooter={false}
                    autoHeight={true}
                    contracts={isEmpty(siteStore.selectedSite) ? [] : [ siteStore.selectedSite.contractId ]}
                    onRowSelected={handleRowSelection}
                    waitFullyLoaded={true}
                    heightToFitContent={true}
                    showInfoModal={true}
                    showRowCount={false}
                />
            </div>
            {productsSection()}
        </div>
    );
});

export default ContractLevel;
