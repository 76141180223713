import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getLocalizedDate, isEmpty } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { useSeenNotification } from '../../services/notifications/useSeenNotification';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
    return {
        notificationContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '300px',
            borderTop: (props) => {
                return props.borderTop ? props.borderTop : theme.borders.outline_regular;
            },
            borderBottom: (props) => {
                return props.borderBottom ? props.borderBottom : '';
            },
            marginTop: `-${theme.sizes.nano_gap}`,
            paddingTop: theme.sizes.nano_gap,
            marginBottom: `-${theme.sizes.nano_gap}`,
        },
        notificationTypeInner: {
            flex: 1,
            lineHeight: 0,
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        notificationDate: {
            flex: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.fontSizes.notificationContent,
            color: theme.palette.grey['500'],
        },
        notificationTypeIcon: {
            marginInlineEnd: theme.sizes.tiny_gap,
            height: '20px',
        },
        notificationType: {
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.fontSizes.notification,
            textTransform: 'capitalize',
        },
        notificationTitleText: {
            fontSize: theme.fontSizes.notification,
            marginBottom: theme.sizes.planck_gap,
            textOverflow: 'ellipsis',
            maxWidth: '295px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },
        notificationContent: {
            whiteSpace: 'normal',
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
        },
        notificationContentText: {
            fontSize: theme.fontSizes.notificationContent,
        },
        badge: {
            height: '10px',
            width: '10px',
            backgroundColor: theme.colors.notificationRed,
            borderRadius: '50%',
            marginLeft: theme.sizes.micro_gap,
            float: 'right',
            marginTop: `-${theme.sizes.nano_gap}`,
        },
        noBadge: {
            marginBottom: theme.sizes.pico_gap,
        },
        unreadNotification: {
            backgroundColor: theme.palette.grey['100'],
        }
    };
});

const NotificationItem = (props) => {
    const { t } = useTranslation();
    const { notification, handleToggle, index } = props;
    const classes = useStyles();
    const [ , , createSeenNotification ] = useSeenNotification();
    const history = useHistory();

    const redirectToContent = () => {
        history.push({
            pathname: '/support/messages/message-content',
            state: { url: notification.Url, title: notification.Title }
        });
    };

    const getBadge = () => {
        return isEmpty(notification.SeenAt) ?
            <div className={classes.badge}/> : <div className={classes.noBadge}/>;
    };

    const getMenuItemStyle = () => {
        if(!isEmpty(notification) && isEmpty(notification.SeenAt)) {
            return classes.unreadNotification;
        }
        return classes.noStyle;
    };

    return (
        <MenuItem className={getMenuItemStyle()} onClick={() => {
            if(!isEmpty(notification) && !isEmpty(notification.NotificationId)) {
                createSeenNotification(notification.NotificationId);

                notification.SeenAt = new Date().toISOString();

                if(!isEmpty(notification.Url)) {
                    redirectToContent();
                    if(!isEmpty(handleToggle)) {
                        handleToggle();
                    }
                }
            }
        }} id={`notification-${index}`}>
            <div className={classes.notificationContainer}>
                <div className={classes.notificationTypeInner}>
                    <Typography className={classes.notificationType}>
                        {`${notification.Type} \u2022 ${getLocalizedDate(t, notification.CreatedAt)}`}
                    </Typography>
                    { getBadge() }
                </div>
                <Typography className={classes.notificationTitleText}>
                    {notification.Title}
                </Typography>
            </div>
        </MenuItem>
    );
};

export default NotificationItem;

