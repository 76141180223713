import React from 'react';
import { SvgIcon } from '@material-ui/core';

const createViewBox = (size) => `0 0 ${size.width} ${size.height}`;

const iconStyle = (size, color) => ({
    width: `${size.width }px`,
    height: `${size.height }px`,
    color: color ? color : null,
});

const Icon = (props) => {
    let { icon, size, title, color } = props;

    const viewBox = createViewBox(size);
    const style = iconStyle(size, color);

    return (
        <SvgIcon
            title={title}
            component={icon}
            viewBox={viewBox}
            style={style}
        />
    );
};

export { Icon };
