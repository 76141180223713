import React from "react";
import {makeStyles} from "@material-ui/core";
import MIModal from "./MIModal";
import useModal from "../../services/useModal";
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.colors.mainText,
        display: 'inline',
    },
    titleContainer: {
        display: 'inline',
        paddingTop: theme.sizes.small_gap,
        '&:focus': theme.ariaFocus,
    },
    bodyContainer: {
        overflowY: 'auto',
        height:'fit-content',
        display: 'inline',
    },
    button: {
        marginRight: '5px',
    },
}));


const MIInfoModal = (props) => {
    const {buttonAriaLabel, buttonContent, modalHeader, modalContent, modalHeaderAriaLabel} = props;

    const classes = useStyles();
    const {isShowing, changeStatus} = useModal();

    const getHeader = () => {
        return (
            <div className={classes.titleContainer} tabIndex={0}  aria-label={modalHeaderAriaLabel} >
                <h2 className={classes.title}>{modalHeader}</h2>
            </div>
        );
    };

    const getBody = () => {
        return (
            <div className={classes.bodyContainer}>
                {modalContent}
            </div>
        );
    };

    const handleClick = () => {
        changeStatus();
    }

    return (
        <div>
            <Button
                className={classes.button}
                aria-label={buttonAriaLabel}
                color='primary'
                variant='outlined'
                type='button'
                tabIndex={0}
                size='small'
                onClick={handleClick}
            >
                {buttonContent}
            </Button>
            <MIModal 
                header={getHeader()}
                body={getBody()}
                isShowing={isShowing}
                onClose={handleClick}
                customStyle={{'rootHeight': 'fit-content'}}
            />
        </div>
    );
};

export default MIInfoModal;
