import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Badge, Box } from '@material-ui/core';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import { RECOMMENDATIONS_COUNT_OPTIONS } from '../../SiteActionPlans/SiteActionPlansEnum';

const useStyles = makeStyles(() => ({
    icon: {
        position: 'absolute',
        cursor: (props) => (props.handleDetectorClick ? 'pointer' : 'auto'),
    },
    locationIcon: {
        color: '#354A55',
        fontSize: 40
    },
    badge: {
        border: '1px solid #354A55',
        color: '#354A55',
        backgroundColor: '#ffffff',
        fontSize: 18,
        fontWeight: 'bold',
    },
    box: {
        width: '40px',
        height: '40px',
        border: '1px solid #354A55',
        backgroundColor: '#ffffff',
    },
}));

const REC_STATUS = {
    NEW: 'NEW',
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE'
}

const RecsLocation = (props) => {
    const { recommendations, recsCountFilter } = props;
    const { t } = useTranslation();
    const classes = useStyles(props);

    const getCount = (recommendations, filter) => {
        return recommendations.reduce((count, rec) => {
            const status = rec?.status
            if (status === REC_STATUS.PENDING) {
                return filter === RECOMMENDATIONS_COUNT_OPTIONS.TOTAL || filter === RECOMMENDATIONS_COUNT_OPTIONS.PENDING? count + 1 : count;
            } else if (status === REC_STATUS.NEW) {
                return filter === RECOMMENDATIONS_COUNT_OPTIONS.TOTAL || filter === RECOMMENDATIONS_COUNT_OPTIONS.NEW ? count + 1 : count;
            }
            return count;
        }, 0)
    }

    return (
        <div className={classes.icon} aria-label={t('recs_location_icon')}>
            <Badge color={'secondary'} badgeContent={getCount(recommendations, recsCountFilter)}
                   classes={{ 'colorSecondary': classes.badge }}>
                <Box borderRadius={'50%'} className={classes.box}>
                    <LocationOnRoundedIcon className={classes.locationIcon} fontSize={'inherit'}/>
                </Box>
            </Badge>
        </div>
    );
};

export default RecsLocation;
