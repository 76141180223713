import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MIMobileDialog from './MIMobileDialog';
import {Typography} from '@material-ui/core';
import {observer} from "mobx-react-lite";

const useStyles = makeStyles(theme => ({
    infoRow: {
        paddingBottom: '10px',
    },
    label: { 
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileBody,
        paddingBottom: '5px',
        wordWrap: "break-word",
        whiteSpace: 'normal'
    },
    value: {
        fontFamily: 'proxima nova medium',
        fontSize: theme.fontSizes.mobileBody,
        wordWrap: "break-word",
        whiteSpace: 'normal'
    },
    title: {
        fontFamily: theme.fontFamily.proximaBold,
        fontSize: theme.fontSizes.mobileTitle,
        wordWrap: "break-word",
        whiteSpace: 'normal'
    },
    container: {
        marginTop: theme.sizes.tiny_gap
    }
}));

const MIInfoDialog = observer((props) => {
    const classes = useStyles();
    const {open, handleClose, info, content, title} = props;
    
    const createInfoRows = (label, value, index) => (
        <div key={label + index} className={classes.infoRow}>
            <Typography className={classes.label}>{label}:</Typography>
            <Typography className={classes.value}>{value}</Typography>
        </div>
    );

    const getContent = () => (
        <div className={classes.container}>
            {info.map(({label, value}, index) => createInfoRows(label, value, index))}
            {content}
        </div>
    );

    return (
        <MIMobileDialog
            title={<span className={classes.title}>{title}</span>}
            content={getContent()}
            open={open}
            handleClose={handleClose}
        />
    )

});

export default MIInfoDialog;
