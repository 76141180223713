import { useEffect, useCallback, useState } from 'react';
import { ERROR, FETCHING, SUCCESS } from '../requests/requestReducer';
import { useEntitlementsStore } from '../../contexts/StateProvider/StateProvider';
import { formatDate, isEmpty } from '../../utils/utils';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';

export const useEntitlementProductDetails = () => {
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const entitlementsStore = useEntitlementsStore();
    const endpoint = '/contractService/entitlements/product/history';
    const [ key, setKey ] = useState(null);

    useEffect(() => {
        if (responseStatus === SUCCESS && !isEmpty(key)) {
            try {
                const history = response?.data?.history;
                entitlementsStore.setProductDetails(key, history, SUCCESS);
            } catch (error) {
                console.error('useEntitlementProductDetails error: ', error);
                entitlementsStore.setProductDetails(key, [], ERROR);
            }
        }
    }, [ responseStatus, response, entitlementsStore, key ]);

    const getEntitlementProductDetails = useCallback((countryCode, contractNumber, premiseNumber, productCode,
                                                      contractAnniversaryDate, annualQuantity) => {
        const productDetailsKey = entitlementsStore.getProductDetailsKey(contractNumber, premiseNumber, productCode);
        const storedProductDetails = entitlementsStore.productDetails[productDetailsKey]?.values;
        if (isEmpty(storedProductDetails)) {
            setKey(productDetailsKey);
            entitlementsStore.setProductDetails(productDetailsKey, null, FETCHING);

            const dateString = !isEmpty(contractAnniversaryDate) ? formatDate(contractAnniversaryDate, 'DD/MM/YYYY', 'DD/MM/YYYY') : '-';
            const url = [ endpoint, countryCode, contractNumber, premiseNumber, productCode, dateString, annualQuantity ].join('/');
            makeRegionalGCPRequestWithParams(url).catch((error) => {
                console.error('useEntitlementProductDetails error: ', error);
            });
        }
    }, [ makeRegionalGCPRequestWithParams, entitlementsStore, setKey ]);

    return [ getEntitlementProductDetails ];
};
