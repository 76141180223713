import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { RenderTooltip } from '../../../../components/Widgets/MIGrid';
import ProductTableDesktop from './ProductTableDesktop';
import { isEmpty } from '../../../../utils/utils';
import MIAccordion from '../../../../components/Widgets/MIAccordion';
import { getTables } from '../SiteLevel';
import { SERVICE_GROUP_ID } from '../../../../services/contract/useContractDetails';

const useStyles = makeStyles((theme) => {
    return {
        noElementsText: {
            'color': theme.palette.text.disabled,
            '&:focus': theme.ariaFocus,
        },
        groupTitle: {
            fontSize: theme.fontSizes.nav,
        },
    };
});

const SiteLevelDesktop = observer((props) => {
    const { t } = useTranslation();
    const { contractDetails, contractGroupId } = props;
    const theme = useTheme();
    const classes = useStyles();

    const columnsDesktop = [
        {
            field: 'product',
            headerName: t('product'),
            description: t('product'),
            type: 'string',
            resizable: false,
            width: 240,
            renderCell: RenderTooltip
        },
        {
            field: 'productCode',
            headerName: t('product_code'),
            description: t('product_code'),
            type: 'string',
            resizable: false,
            width: 150
        },
        {
            field: 'quantity',
            headerName: t('quantity'),
            description: t('quantity'),
            type: 'number',
            resizable: false,
            width: 130
        },
        {
            field: 'visitsAnnum',
            headerName: t('visit_per_year'),
            description: t('visit_per_year'),
            type: 'number',
            resizable: false,
            width: 165
        },
    ];

    const renderTables = (id, group) => {
        const tables = getTables(group);
        const items = Object.keys(tables).map((groupName, index) => {
            let columns = [
                id === SERVICE_GROUP_ID ?
                    {
                        field: 'location',
                        headerName: t('location'),
                        description: t('location'),
                        type: 'string',
                        resizeable: true,
                        width: 230,
                    } :
                    {
                        field: 'serviceGroup',
                        headerName: t('service_group_name'),
                        description: t('service_group_name'),
                        type: 'string',
                        resizeable: true,
                        width: 230,
                    },
                ...columnsDesktop
            ];
            return <div key={ index }>
                <ProductTableDesktop
                    groupName={ groupName }
                    rows={ tables[groupName] }
                    columns={ columns }
                />
            </div>;
        });
        if (items.length === 0) {
            return (
                <div>
                    <h2 className={classes.noElementsText} tabIndex={0}>
                        { t('no_contracts_available') }
                    </h2>
                </div>
            );
        }

        return <div>{ items }</div>;
    };

    const displayTable = (group) => {
        const { label, groupId, table } = group;
        return (
            <div key={ label }>
                <MIAccordion
                    aria-label={groupId}
                    header={label}
                    id={groupId}
                    content={table}
                    customStyle={
                        {
                            accordion: {
                                '& div.MuiAccordionSummary-root.Mui-expanded': {
                                    background: theme.palette.grey['200'],
                                    minHeight: theme.sizes.xxxlarge_gap,
                                },
                            },
                            summary: {
                                paddingLeft: theme.sizes.tiny_gap,
                                paddingRight: theme.sizes.tiny_gap,
                            },
                            heading: {
                                fontSize: theme.fontSizes.subHeading,
                            },
                        }
                    }
                />
            </div>
        );
    };

    const getGroups = () => {
        if (isEmpty(contractDetails)) {
            return null;
        }

        const renderedTable = [];

        contractGroupId.forEach((contractGroup) => {
            const groupId = contractGroup.id;
            if (!isEmpty(contractDetails[groupId])) {
                const group = {
                    label: contractGroup.name,
                    groupId: groupId,
                    table: renderTables(groupId, contractDetails[groupId])
                };
                renderedTable.push(displayTable(group));
            }
        });

        return renderedTable;
    };

    return (
        <>
            <div>
                {getGroups()}
            </div>
        </>
    );
});

export default SiteLevelDesktop;
