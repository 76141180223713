import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mergeStyles} from '../../utils/utils';

const useStyles = makeStyles(theme => ({
    topBorder: {
      backgroundColor: theme.palette.primary.main,
      height: '8px',
    },
}));

const MITopBorder = (props) => {
    const classes = useStyles();
    const {topBorderStyle} = props; 
    return (
        <div className={mergeStyles(classes.topBorder, topBorderStyle)}></div>
    );
};

export default MITopBorder