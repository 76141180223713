import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import { mergeStyles } from '../../../utils/utils';
import CircleIcon from '@material-ui/icons/Brightness1';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
    return {
        levelsContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        levelsHeader: {
            height: '52px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        levelsHeaderLeft: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
        },
        levelsHeaderItem: {
            display: 'flex',
            marginInlineEnd: theme.sizes.tiny_gap,
        },
        levelBarOuter: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '41px',
            marginTop: theme.sizes.tiny_gap,
            marginBottom: theme.sizes.tiny_gap,
        },
        levelBar: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            borderInlineEnd: `1px solid ${theme.palette.background.paper}`,
        },
        icon: {
            width: theme.sizes.small_gap,
            marginInlineStart: theme.sizes.tiny_gap,
            marginInlineEnd: theme.sizes.tiny_gap,
        },
        helpIcon: {
            color: theme.palette.primary.main,
            marginTop: theme.sizes.pico_gap,
        },
        levelBarEmpty: {
            backgroundColor: (props) => {
                return props.low === 0 && props.empty === 0 && props.ok === 0 ?
                    theme.palette.refillLevels.noData :
                    theme.palette.refillLevels.empty;
            },
            flexGrow: (props) => {
                return props.empty ? props.empty : 1;
            },
        },
        iconEmpty: {
            color: theme.palette.refillLevels.empty,
        },
        levelBarLow: {
            backgroundColor: (props) => {
                return props.low === 0 && props.empty === 0 && props.ok === 0 ?
                    theme.palette.refillLevels.noData :
                    theme.palette.refillLevels.low;
            },
            flexGrow: (props) => {
                return props.low ? props.low : 1;
            },
        },
        iconLow: {
            color: theme.palette.refillLevels.low,
        },
        levelBarFull: {
            backgroundColor: (props) => {
                return props.low === 0 && props.empty === 0 && props.ok === 0 ?
                    theme.palette.refillLevels.noData :
                    theme.palette.refillLevels.full;
            },
            flexGrow: (props) => {
                return props.ok ? props.ok : 1;
            },
        },
        iconFull: {
            color: theme.palette.refillLevels.full,
        },
        levelItem: {
            color: theme.palette.text.contrastText,
        },
        tooltip: {
            backgroundColor: theme.palette.background.white,
            color: theme.palette.text.primary,
            boxShadow: theme.shadows[1],
            fontSize: theme.fontSizes.small,
        }
    };
});


const RefillBar = (props) => {
    const { empty, low, ok } = props;
    const classes = useStyles(props);

    return (
        <div className={classes.levelBarOuter}>
            <div className={mergeStyles(classes.levelBarEmpty, classes.levelBar)}>
                <Typography className={classes.levelItem}>{empty}</Typography>
            </div>
            <div className={mergeStyles(classes.levelBarLow, classes.levelBar)}>
                <Typography className={classes.levelItem}>{low}</Typography>
            </div>
            <div className={mergeStyles(classes.levelBarFull, classes.levelBar)}>
                <Typography className={classes.levelItem}>{ok}</Typography>
            </div>
        </div>
    );
};

const RefillLevels = (props) => {
    const { t } = useTranslation();
    const { className } = props;
    const classes = useStyles();

    return (
        <div className={className}>
            <div className={classes.levelsContainer}>
                <div className={classes.levelsHeader}>
                    <div className={classes.levelsHeaderLeft}>
                        <Typography className={classes.levelsHeaderItem}>
                            { t('empty') } <CircleIcon className={mergeStyles(classes.icon, classes.iconEmpty)}/>
                        </Typography>
                        <Typography className={classes.levelsHeaderItem}>
                            { t('low') } <CircleIcon className={mergeStyles(classes.icon, classes.iconLow)}/>
                        </Typography>
                        <Typography className={classes.levelsHeaderItem}>
                            { t('level_ok') } <CircleIcon className={mergeStyles(classes.icon, classes.iconFull)}/>
                        </Typography>
                    </div>
                    <Tooltip title={t('levels_information')} classes={{ tooltip: classes.tooltip }} placement={'top'} arrow>
                        <Typography><HelpOutlineIcon className={classes.helpIcon}/></Typography>
                    </Tooltip>
                </div>
                <RefillBar {...props.foam} />
                <RefillBar {...props.sanitiser}/>
                <RefillBar {...props.blu}/>
            </div>
        </div>
    );
};

export default RefillLevels;
