import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MIFileHandler from "./MIFileHandler";
import GetAppIcon from '@material-ui/icons/GetApp';
import MIAlert from "../../components/Widgets/MIAlert";
import {useTranslation} from "react-i18next";
import {useResponsive} from "../../services/useResponsive";
import {Typography} from '@material-ui/core';

const desktopStyles = theme => {
    return {
        itemContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        documentName: {
            fontSize: theme.typography.body1.fontSize,
        },
        spinnerContainer: {
            marginRight: theme.sizes.nano_gap,
            marginTop: theme.sizes.planck_gap,
        },
        downloadIcon: {
            color: theme.palette.primary.main,
            height: theme.heights.desktop_documents_download_icon,
            width: theme.widths.desktop_documents_download_icon,
            marginRight: theme.sizes.pico_gap,
            cursor: 'pointer',
            '&:focus': theme.ariaFocus,
        }
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        itemContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        documentName: {
            fontSize: theme.fontSizes.small,
        },
        spinnerContainer: {
            marginRight: theme.sizes.pico_gap,
            marginTop: theme.sizes.pico_gap,
        },
        downloadIcon: {
            ...baseStyle.downloadIcon,
            height: theme.heights.mobile_documents_download_icon,
            width: theme.widths.mobile_documents_download_icon,
            marginRight: theme.sizes.planck_gap,
        }
    });
};

const desktopSpinnerContainerStyles = () => (
    {
        'spinnerWidth': '20px',
        'spinnerHeight': '20px',
    }
);


const mobileSpinnerContainerStyles = () => (
    {
        'spinnerWidth': '16px',
        'spinnerHeight': '16px',
    }
);


const MIDocument = (props) => {
    const {t} = useTranslation();
    const [isShowing, setIsShowing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState({});
    const {url, documentName, documentExtendedName, title, mimeType} = props;
    const {getStyles} = useResponsive();

    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    const spinnerContainer = getStyles({
        desktop: desktopSpinnerContainerStyles(),
        mobile: mobileSpinnerContainerStyles()
    })

    const handleOnClick = () => {
        setIsLoading(true);
    };

    return (
        <div className={classes.itemContainer} >
            <MIAlert
                isShowing={isShowing}
                setIsShowing={setIsShowing}
                message={t(alert.message)}
                severity={alert.severity}
                ariaLabel={t('aria_label_operation_response_dialog')}
            />
            <Typography className={classes.documentName} > {documentName} </Typography>
            <div className={classes.spinnerContainer} >
                <MIFileHandler
                        isLoading={isLoading}
                        url={url}
                        setIsLoading={setIsLoading}
                        setIsShowing={setIsShowing}
                        setAlert={setAlert}
                        customStyle={spinnerContainer}
                        ariaLabelProgressBar={'aria_label_progressbar_document'}
                        name={documentExtendedName}
                        mimeType={mimeType}
                    />
            </div>
            {!isLoading && 
                <GetAppIcon 
                    data-testid={'download_icon'}
                    className={classes.downloadIcon} 
                    title={title} 
                    onClick={handleOnClick}
                    tabIndex={0}
                />
            }
        </div>
    );
};


export default MIDocument;
