import React, {useCallback, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import {useTranslation} from 'react-i18next';
import MIApiLoader from '../../../components/Widgets/MIApiLoader';
import {useAuthState} from '../../../contexts/AuthProvider/AuthProvider'
import {useRunReport} from '../../../services/useRunReport';

const useStyles = makeStyles(theme => ({
    actionIcon: {
        fill: theme.palette.primary.main,
        width: theme.sizes.large_gap,
        cursor: 'pointer',
        marginRight: '5px',
    },
    actionIconContainer: {
        alignItems: 'center',
        display: 'flex',
    },
    actionIconOuter: {
        display: 'flex',
        '&:focus': theme.ariaFocus,
    },
}));

const RunReport = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const { locale, countryCode } = useAuthState();
    const {reportId, setAlert, setIsShowing, closeActionsMenu} = props;
    const [runStatus, responseStatus, runReport] = useRunReport();
    
    const showAlert = useCallback(() => {
        if (runStatus === 'NO_DATA') {
            setAlert({message: 'run_report_dialog_no_data', severity: 'warning'})
        } else if (runStatus === 'success') {
            setAlert({message: 'run_report_dialog_success', severity: 'success'})
        } else {
            setAlert({message: 'run_report_dialog_error', severity: 'error'})
        }
        setIsShowing(true);
    },[runStatus, setAlert, setIsShowing]);

    const handleOnClick = () => {
        runReport(reportId, countryCode, locale);
    }

    useEffect(() => {
        if (runStatus) {
            try {
                showAlert();
                closeActionsMenu();
            } catch (error) {
                console.error('useRunReport error: ', error);
            }
        }
    }, [runStatus, showAlert, closeActionsMenu]);

    return (
        <div className={classes.actionIconContainer}>
            <MIApiLoader
                customStyle={{ 'spinnerWidth': '20px', 'spinnerHeight': '20px', marginTop: '2px', marginLeft: '2px', marginRight: '2px' }}
                MIComponent={
                    <div
                        className={classes.actionIconOuter}
                        tabIndex={0}
                        aria-label={t('aria_label_run_report_button')}
                        onClick={handleOnClick}
                        data-testid={'run_icon'}
                    >
                        <PlayCircleOutlineIcon
                            className={classes.actionIcon}
                        />
                        {t('run_report')}
                    </div>
                }
                responseStatus={responseStatus}
                ariaLabelProgressBar={t('aria_label_progressbar_run_report')}
            />
        </div>
    )
}

export default RunReport