import moment from 'moment';

export const USAGE_TIME_PERIOD = {
    LAST_YEAR: 'last_year',
    LAST_30_DAYS: 'last_30_days',
    SINCE_INSTALL: 'since_install',
};

export const RAPID_FORMAT_DATE = 'YYYY-MM-DD';

export const getFromDate = (timePeriod) => {
    let fromDate = moment();

    switch(timePeriod) {
    case USAGE_TIME_PERIOD.LAST_30_DAYS:
        fromDate.subtract(30, 'days');
        break;
    case USAGE_TIME_PERIOD.SINCE_INSTALL:
        // Big number to get a very old date allowing us to view all records as we do not know the installation date
        fromDate.subtract(1000, 'years');
        break;
    case USAGE_TIME_PERIOD.LAST_YEAR:
    default:
        fromDate.subtract(1, 'years');
    }
    return fromDate.format(RAPID_FORMAT_DATE);
};
