import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import MIGrid, {
    RenderTooltip,
    STYLE_HEADER_CENTER,
    STYLE_HEADER_CENTER_OVERFLOW
} from '../../../components/Widgets/MIGrid';
import { isEmpty } from '../../../utils/utils';
import { FETCHING } from '../../../services/requests/requestReducer';
import { useLocationIcon } from '../../../services/floorplan/useLocationIcon';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => {
    return {
        title: {
            marginTop: '34px',
            display: 'inline-block'
        },
        titleOuter: {
            display: 'flex',
            height: theme.sizes.xxxlarge_gap,
            alignItems: 'center',
            justifyContent: 'center-left',
            width: '100%',
            marginBottom: theme.sizes.small_gap,
            fontFamily: theme.typography.h1.fontFamily,
            fontSize: '14px',
            color: theme.palette.text.primary,

        },
        tableOuter: {
            alignItems: 'center',
            justifyContent: 'center-left',
        },
        root: {
            marginRight: theme.sizes.small_gap,
            marginLeft: theme.sizes.small_gap,
            width: '100%'
        },
        icon: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
        },
        barcode: {
            width: '100%'
        }
    };
});

const DevicesTab = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { status, detectors } = props;
    const { getIcon } = useLocationIcon();

    const columns = [
        {
            field: 'barcode',
            headerName: t('barcode'),
            description: t('barcode'),
            headerAlign: 'left',
            type: 'string',
            resizable: false,
            flex: 1.5,
            align: 'left',
        },
        {
            field: 'icon',
            headerName: t('detector_type'),
            description: t('detector_type'),
            headerClassName: STYLE_HEADER_CENTER,
            resizable: false,
            width: 110,
            sortable: false,
            renderCell: (params) => {
                return <span className={classes.icon}>{params.row.icon}</span>;
            }
        }, 
        {
            field: 'zone',
            headerName: t('zone'),
            description: t('zone'),
            type: 'string',
            resizable: false,
            flex: 1,
            renderCell: RenderTooltip
        },
        {
            field: 'location',
            headerName: t('location'),
            description: t('location'),
            type: 'string',
            resizable: false,
            flex: 1.2,
            renderCell: RenderTooltip
        },
        {
            field: 'openRecommendations',
            headerName: t('recommendations_table'),
            description: t('recommendations_table'),
            type: 'number',
            resizable: false,
            flex: 1,
            align: 'center',
            headerClassName: STYLE_HEADER_CENTER_OVERFLOW,
        },
        {
            field: 'message',
            headerName: t('message'),
            description: t('message'),
            type: 'string',
            resizable: false,
            flex: 2,
            renderCell: RenderTooltip
        },

    ];

    const getField = (field) => {
        return isEmpty(field) ? '-' : field;
    };

    const getRows = () => {
        let rows = [];
        if (!isEmpty(detectors)) {
            detectors.forEach((detector, index) => {
                const properties = detector.properties;
                const location = properties.location;
                const typeDescription = !isEmpty(properties.detectorType) ?
                    getField(properties.detectorType.descriptionTranslated) : '';
                const [ icon ] = getIcon(properties.id, typeDescription);
                rows.push({
                    id: !isEmpty(location) ? location.id : index,
                    icon: icon,
                    zone: !isEmpty(location) && !isEmpty(location.department) ? getField(location.department.description) : '-',
                    location: !isEmpty(location) ? getField(location.description) : '-',
                    message: !isEmpty(properties) ? getField(properties.message) : '-',
                    barcode: !isEmpty(location) ? getField(location.barcode) : '-',
                    openRecommendations: getField(properties.openRecommendations)
                });
            });
        }
        return rows;
    };


    return (
        <div className={classes.root}>
            <div className={classes.titleOuter}>
                <h1 className={classes.title}
                    aria-label={t('aria_label_devices_list_title')}
                    aria-live={'polite'}
                    tabIndex={0}>
                    {t('devices_list_title')}
                </h1>
            </div>
            <div className={classes.tableOuter}>
                <MIGrid
                    isLoading={status === null || status === FETCHING}
                    showInfoModal={true}
                    columns={columns}
                    noElementsText={t('no_devices_list')}
                    ariaLabel={t('aria_label_devices_list_table')}
                    ariaLabelProgressBar={t('aria_label_progressbar_devices_list')}
                    rows={getRows(detectors)}
                    colCellFlex={true}
                    autoHeight={true}
                    pagination={true}
                    hideFooterForOnePage={true}
                    heightToFitContent={true}
                    pageSize={10}
                />
            </div>

        </div>
    );
});

export default DevicesTab;
