import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import MIModal from '../../Widgets/MIModal';
import { observer } from 'mobx-react-lite';
import { getLocalizedDate } from '../../../utils/utils';
import { isEmpty } from '../../../utils/utils';
import { FETCHING } from '../../../services/requests/requestReducer';
import MIGrid, { STYLE_HEADER_START_OVERFLOW } from '../../Widgets/MIGrid';

const useStyles = makeStyles((theme) => {
    return {
        title: {
            color: theme.colors.mainText,
            display: 'inline',
        },
        titleContainer: {
            'display': 'inline',
            'paddingTop': theme.sizes.small_gap,
            '&:focus': theme.ariaFocus,
        },
        bodyContainer: {
            overflowY: 'auto',
            minHeight:'150px'
        }
    };
});


const PlannedVisitDetailsDesktop = observer((props) => {
    const { t } = useTranslation();
    const { plannedVisit, isShowing, onClose, rows, responseStatus } = props;
    const classes = useStyles();

    const columns = [
        { field: 'productServiced', headerName: t('products'), type: 'string',
            headerClassName: STYLE_HEADER_START_OVERFLOW, resizable: false, flex: 3 },
        { field: 'qty', headerName: t('quantity'), type: 'number', align: 'left',
            headerClassName: STYLE_HEADER_START_OVERFLOW, resizable: false, flex: 1 },
        { field: 'visitsAnnum', headerName: t('visit_per_year'), type: 'number', align: 'left',
            headerClassName: STYLE_HEADER_START_OVERFLOW, resizable: false, flex: 2 },
    ];

    const getHeader = () => {
        return <div className={classes.titleContainer} tabIndex={0} aria-label={t('aria_label_planned_visit_details_title')} >
            <h2 className={classes.title}>{t('planned_visit_details')} {isEmpty(plannedVisit.date) ? '' : `- ${ getLocalizedDate(t, plannedVisit.date)}`}</h2>
        </div>;
    };


    const getBody = () => {
        return <div className={classes.bodyContainer}>
            <MIGrid
                isLoading={responseStatus === null || responseStatus === FETCHING}
                heightToFitContent={true}
                hasFilters={false}
                pagination={true}
                pageSize={5}
                hideFooterForOnePage={true}
                columns={columns}
                rows={rows}
                noElementsText={t('no_planned_visit_details')}
                ariaLabelProgressBar={t('aria_label_progressbar_planned_visit_details')}
                {...props}
            />
        </div>;
    };


    return (
        <MIModal header={getHeader()}
            body={getBody()}
            isShowing={isShowing}
            onClose={onClose}
            customStyle={{ rootHeight: 'fit-content' }}/>
    );
});

export default PlannedVisitDetailsDesktop;
