import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalizedDateFormatString, isEmpty } from '../../utils/utils';
import { observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MIDateRangePicker from '../Widgets/MIDateRangePicker';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => {
    return {
        dateRangeInfo: {
            alignItems: 'center',
            display: 'flex',
            fontFamily: theme.typography.h2.fontFamily,
        },
        clearIcon: {
            align: 'center',
            marginLeft: theme.sizes.pico_gap,
            cursor: 'pointer',
        },
        periodQuantity: {
            marginRight: theme.sizes.small_gap,
            paddingBottom: theme.sizes.micro_gap
        }
    };
});

const EntitlementPeriod = observer((props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const minDate = moment('01-01-2000', 'DD-MM-YYYY');
    const maxDate = moment();
    const { control, watch, reset, rows } = props;

    const fromDate = watch('fromDate');
    const toDate = watch('toDate');

    const resetDateRange = useCallback(() => {
        reset({
            fromDate: null,
            toDate: null,
        });
    }, [ reset ]);

    // Sum of servicedQuantity for a given period
    const getTotalServicedQuantity = useCallback(() => {
        if (!isEmpty(rows) && !isEmpty(fromDate) && !isEmpty(toDate)) {
            return rows.reduce((previousProductVisit, currentProductVisit) => {
                return previousProductVisit + currentProductVisit?.servedQuantity;
            }, 0);
        }
        return null;
    }, [ rows, fromDate, toDate ]);

    return (
        <div className={classes.dateRangeInfo}>
            <span className={classes.periodQuantity}>{`${t('serviced_period_quantity')}: ${!isEmpty(getTotalServicedQuantity()) ? getTotalServicedQuantity() : '--'}`}</span>
            <MIDateRangePicker
                control={control}
                watch={watch}
                minDateValue={minDate}
                maxDateValue={maxDate}
                dateFormat={getLocalizedDateFormatString(t)}
                calendarWidth={'174px'}
                padding={'20px 0px 20px 10px'}
                dateFromProps={{
                    defaultValue: null,
                    rules:{
                        required: false,
                        validate: { isValidDate:
                                    (value) => {
                                        return !isEmpty(value) &&
                                            watch('toDate') &&
                                            value.startOf('day').isSameOrBefore(watch('toDate').startOf('day'));
                                    }
                        }
                    }
                }}
                dateToProps={{
                    defaultValue: null,
                    rules:{
                        required: false,
                        validate: { isValidDate:
                                    (value) => {
                                        return !isEmpty(value) &&
                                            watch('fromDate') &&
                                            value.startOf('day').isSameOrAfter(watch('fromDate').startOf('day')) &&
                                            value.startOf('day').isSameOrAfter(minDate) &&
                                            value.startOf('day').isSameOrBefore(maxDate);
                                    }
                        }
                    }
                }}
                dateFromPickerProps={{
                    InputProps: { classes: { input: classes.inputText } },
                    ...watch('fromDate') === null && watch('toDate') !== null ? { error: true } : {}
                }}
                dateToPickerProps={{
                    InputProps: { classes: { input: classes.inputText } },
                    ...watch('toDate') === null && watch('fromDate') !== null ? { error: true } : {},
                }}
            />
            <ClearIcon className={classes.clearIcon} aria-label={t('clear_date_range_icon')} onClick={resetDateRange}/>
        </div>
    );
});

export default EntitlementPeriod;
