import { useState, useEffect, useCallback } from 'react';
import useApiRequest from '../requests/useApiRequest';
import { SUCCESS, ERROR } from '../requests/requestReducer';
import {isEmpty, removeSSOParams} from '../../utils/utils';
import rentokilRegionsMap from '../../utils/rentokilRegionsMap.json';
import { useRegionStore } from '../../contexts/StateProvider/StateProvider';
import { useHistory } from 'react-router-dom';

export const useLoginViaSSO = () => {
    const [ userInfo, setUserInfo ] = useState(null);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/authenticate/SSO';
    const regionStore = useRegionStore();
    const history = useHistory();

    const selectRegion = useCallback((redirectURL) => {
        let regionKey = null;
        if (!isEmpty(redirectURL)) {
            regionKey = redirectURL;
        } else {
            const documentReferrer = document.referrer;
            const referrer = Object.keys(rentokilRegionsMap).find((rentokilRegionKey) => {
                return !isEmpty(documentReferrer) && rentokilRegionKey.includes(documentReferrer);
            });
            if (isEmpty(referrer)) {
                console.error(`Region not found using referrer ${documentReferrer}`);
                return '-';
            }
            regionKey = referrer;
        }
        return rentokilRegionsMap[regionKey];
    }, []);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                const data = response.data;
                if (!isEmpty(data)) {
                    regionStore.setAvailableRegions({ [data.region]: data });
                    setUserInfo(data);
                } else {
                    console.error('useSSO error received empty response');
                }
            } catch (error) {
                console.error('useSSO error: ', error);
            }
        }

        if (responseStatus === ERROR) {
            // remove SSO params on error
            if (history) {
                history.replace({
                    search: removeSSOParams(),
                });
            }
        }
    }, [ responseStatus, response, setUserInfo, history, regionStore ]);

    const doLoginViaSSO = useCallback((encryptedToken, redirectURL) => {
        regionStore.reset();
        const params = {
            token: encryptedToken,
        };
        makeRequestWithParams(endpoint, 'post', params, selectRegion(redirectURL))
            .catch((error) => {
                console.error('useLoginViaSSO makeRequest: ', error);
            });
    }, [ makeRequestWithParams, selectRegion, regionStore ]);

    return [ userInfo, responseStatus, doLoginViaSSO ];
};
