import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {getLocalizedDate} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import MIToolTip from "./MIToolTip";

const useStyles = makeStyles(theme => ({
    root: {
        width: '224px',
        height: '72px',
        marginBottom: theme.sizes.small_gap,
        borderRadius: 0,
        border: theme.borders.outline_regular,
        borderColor: "#9AA5AA",
        '&:hover': {
            boxShadow: 'rgba(0, 0, 0, 0.5) 0px 2px 5px 1px',
        },
        '&:focus': theme.ariaFocus,
        boxShadow:'none',
        cursor: 'pointer',
    },
    cardSelected: {
        width: '224px',
        height: '72px',
        marginBottom: theme.sizes.small_gap,
        borderRadius: 0,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        cursor: 'pointer',
        boxShadow: 'rgba(0,125,197,0.25) 0px 1px 9px 1px',
        '&:focus': theme.ariaFocus,
    },
    content: {
        maxWidth: '224px',
        maxHeight: '72px',
        fontSize: theme.fontSizes.small,
    },
    textbox: {
        display: 'inline-flex',
        marginTop: 0,
        marginBottom: '4px',
    },
    text: {
        fontFamily: theme.typography.fontFamily,
    },
    textType: {
        fontFamily: theme.typography.fontFamily,
        textOverflow: 'ellipsis',
        float: 'left', 
        overflow: 'hidden',
        maxWidth: '150px',
    },
    title: {
        fontFamily: theme.typography.h1.fontFamily,
        paddingRight: '5px'
    }
}));

const MICard = (props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    let borderClass = props && props.selectedPlannedVisitDateString === props.plannedVisit.date ? classes.cardSelected : classes.root;

    const handleOnClick = () => {
        props.onClick(props.plannedVisit.date)
    };

    return (
        <Card className={borderClass} onClick={handleOnClick} tabIndex={0}>
            <CardContent className={classes.content}>
                <div className={classes.textbox}>
                    <span className={classes.title}>{t('date')}:</span>
                    <span className={classes.text}>{getLocalizedDate(t, props.plannedVisit.date)}</span>
                </div>
                <div className={classes.textbox}>
                    <span className={classes.title}>{t('type')}:</span>
                    <span className={classes.textType}>
                        <MIToolTip placement={'bottom'}
                                   value={props.plannedVisit.visitTypeDesc}/>
                    </span>
                </div>
            </CardContent>
        </Card>
    );
};

export default MICard;
