import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import MIButton from '../../widgets/MIButton';
import FilterIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles((theme) => ({
    actionButton: {
        alignSelf: 'flex-start',
        color: theme.palette.primary.main,
        width: '128px',
        marginLeft: theme.sizes.micro_gap,
        marginTop: 'auto',
        fontSize: '15px',
    },
    filterCount: {
        position: 'absolute',
        top: '0px',
        right: theme.sizes.micro_gap,
    },
}));

const FilterButton = (props) => {
    const { t } = useTranslation();
    const { handleFilters, filterCount, buttonText, startIcon } = props;
    const classes = useStyles();

    const getButtonText = () => buttonText || t('filters');
    const getStartIcon = () => startIcon || <FilterIcon/>;

    return (
        <MIButton
            className={classes.actionButton}
            display={'flex'}
            bordercolor={'primary'}
            borderradius={'0px'}
            variant={'outlined'}
            onClick={handleFilters}
            startIcon={getStartIcon()}
            ariaLabel={t('aria_label_filters_button')}
        >
            {getButtonText()}
            <div className={classes.filterCount}>
                {filterCount}
            </div>
        </MIButton>
    );
};

export default FilterButton;
