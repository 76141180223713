import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import BenefitCard from './BenefitCard';
import WaterIcon from '../../../assets/water.svg';
import FoamIcon from '../../../assets/foam.svg';
import BluIcon from '../../../assets/blu.svg';
import SanitiserIcon from '../../../assets/sanitiser.svg';
import { formatNumber, isEmpty } from '../../../utils/utils';
import MIApiLoader from '../../../components/Widgets/MIApiLoader';
import { mergeStyles } from '../../../utils/utils';

const useStyles = makeStyles((theme) => {
    return {
        title: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.fontFamily.proximaBold,
            marginBottom: theme.sizes.small_gap,
        },
        secondaryTitle: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.fontFamily.proximaBold,
            marginInlineStart: theme.sizes.tiny_gap,
            marginBottom: theme.sizes.small_gap,
            color: theme.palette.grey['500'],
        },
        card: {
            'display': 'inline-block',
            'marginBottom': theme.sizes.large_gap,
            '& img': {
                width: '42px',
                height: '42px',
                marginBottom: theme.sizes.micro_gap,
            }
        },
        normalCard: {
            marginInlineEnd: (props) => {
                return props.cardSpacing ? props.cardSpacing : theme.sizes.smart_hygiene_spacing;
            },
        },
        lastCard: {
            marginInlineEnd: 0,
        },
        inlineTitle: {
            display: 'flex',
        }
    };
});

const Benefits = (props) => {
    const { t } = useTranslation();
    const { className, usageData, apiStatus, customStyles, secondaryTitle } = props;
    const classes = useStyles(customStyles);

    const getCard = (title, icon, usage, className, isBlu = false) => {
        return (
            <BenefitCard
                className={!isEmpty(className) ? mergeStyles(classes.card, className) : classes.card}
                customStyles={customStyles}
                title={title}
                key={title}
                icon={icon}
                used={usage.used}
                units={usage.units}
                saved={usage.saved}
                apiStatus={apiStatus}
                isBlu={isBlu}
            />
        );
    };

    const getUsageValue = (valueType) => {
        if(!isEmpty(usageData)) {
            return {
                used: `${formatNumber(usageData[valueType].used.value)} ${t(usageData[valueType].used.label)}`,
                units: `${formatNumber(usageData[valueType].units.value)}`,
                saved: `${formatNumber(usageData[valueType].saved.value)} ${t(usageData[valueType].saved.label)}`,
            };
        }
        return '--';
    };

    const getCards = () => {
        let cards = [];
        cards.push(getCard(t('water'), <img src={WaterIcon} alt={t('water')}/>, getUsageValue('water'), classes.normalCard));
        cards.push(getCard(t('foam'), <img src={FoamIcon} alt={t('foam')}/>, getUsageValue('foam'), classes.normalCard));
        cards.push(getCard(t('sanitiser'), <img src={SanitiserIcon} alt={t('sanitiser')}/>, getUsageValue('sanitiser'), classes.normalCard));
        cards.push(getCard(t('blu'), <img src={BluIcon} alt={t('blu')}/>, getUsageValue('blu'), classes.lastCard, true));
        return cards;
    };

    return (
        <div className={className}>
            <div className={classes.inlineTitle}>
                <Typography className={classes.title}>{t('products_and_benefits')}</Typography>
                {secondaryTitle && <Typography className={classes.secondaryTitle}>{t(secondaryTitle)}</Typography>}
                <MIApiLoader
                    customStyle={{
                        spinnerWidth: '20px',
                        spinnerHeight: '20px',
                        marginTop: '4px',
                        marginLeft: '4px',
                        marginRight: '4px'
                    }}
                    MIComponent={<p></p>}
                    responseStatus={apiStatus}
                    ariaLabelProgressBar={t('aria_label_progressbar_smart_hygiene_benefits')}
                />
            </div>
            {getCards()}
        </div>
    );
};

export default Benefits;
