import { useCallback, useEffect, useState } from 'react';
import useApiRequest from '../requests/useApiRequest';
import { ERROR, SUCCESS } from '../requests/requestReducer';
import { useRegionStore } from '../../contexts/StateProvider/StateProvider';
import { isEmpty } from '../../utils/utils';

export const useLogin = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/loginService/login';
    const [ userData, setUserData ] = useState({});
    const [ userDataPartial, setUserDataPartial ] = useState({});
    const [ loginRequestCount, setLoginRequestCount ] = useState(0);
    const regionStore = useRegionStore();
    const regions = process.env.REACT_APP_BACKEND_REGIONS.split(',');

    const addRegion = useCallback((_response) => {
        const region = _response.data.region;
        if (!isEmpty(region)) {
            let regionData = {};
            regionData[region] = _response.data;
            setUserDataPartial((currentUserDataPartial) => {
                return Object.assign({}, regionData, currentUserDataPartial);
            });
        }
    }, []);

    useEffect(() => {
        if (responseStatus === SUCCESS || responseStatus === ERROR) {
            // responseStatus === ERROR only if the backend is not available or it returns 500 otherwise
            // in case of either wrong user or wrong password kony returns success
            setLoginRequestCount((prevValue) => {
                return prevValue + 1;
            });
        }
    }, [responseStatus, response]);

    useEffect(() => {
        if (loginRequestCount > 0 && !isEmpty(response) && !isEmpty(response.data)) {
            addRegion(response);
        }
    }, [loginRequestCount, addRegion, response]);

    useEffect(() => {
        if (!isEmpty(userData)) {
            regionStore.setAvailableRegions(userData);
        }
    }, [userData, regionStore]);

    useEffect(() => {
        if (loginRequestCount === regions.length && !isEmpty(userDataPartial)) {
            setUserData(userDataPartial);
            setLoginRequestCount(0);
        }
    }, [loginRequestCount, setLoginRequestCount, regions.length, userDataPartial]);

    const doLogin = useCallback((email, password) => {
        const params = {
            username: email,
            password: password
        };

        //reset all on every api call
        setUserData([]);
        setUserDataPartial([]);
        regionStore.reset();
        setLoginRequestCount(0);

        regions.forEach((region) => {
            makeRequestWithParams(endpoint, 'post', params, region)
                .catch((error) => {
                    console.error('login makeRequestWithParams: ', error);
                });
        });
    }, [ makeRequestWithParams, regions, regionStore, setUserData, setUserDataPartial ]);

    return [ userData, responseStatus, doLogin ];
};
