import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import firebase from "firebase/app";
import semverSatisfies from 'semver/functions/satisfies';
import {initFirebase} from "../../utils/firebaseConfig";
import {useAuthState} from "../AuthProvider";
import {getCurrentHost, getHost, isEmpty} from "../../utils/utils";
import {useSiteStore} from "../StateProvider/StateProvider";
import {observer} from "mobx-react-lite";
import axios from "axios";

const FlagsContext = createContext({});
initFirebase();
const remoteConfig = firebase.remoteConfig();

remoteConfig.settings = {
    minimumFetchIntervalMillis: process.env.REACT_APP_FETCH_INTERVAL,
};

const FlagsProvider = observer(({defaults, children}) => {
    const [flags, setFlags] = useState(defaults);
    const siteStore = useSiteStore();
    const {countryCode} = useAuthState();

    const correctVersion = (data => {
        return isEmpty(data.version) ? true : semverSatisfies(process.env.REACT_APP_VERSION, data.version);
    });

    const correctToggle = (data => {
        let correct = true;
        if (!isEmpty(data.toggle)) {
            data.toggle === 'on' ? correct = true : correct = false;
        }
        return correct;
    });

    const correctCountry = useCallback((data) => {
        let correct = true;
        if (!isEmpty(data.countries)) {
            const countries = data.countries.map(item => item.trim().toUpperCase());
            correct = countries.includes(countryCode);
        }
        return correct;
    }, [countryCode]);

    const correctAddress = (data) => {
        let correct = true;
        if (!isEmpty(data.addresses)) {
            const addresses = data.addresses.map(item => getHost(item));
            const currentAddress = getCurrentHost()
            correct = !isEmpty(currentAddress) && addresses.includes(currentAddress);
        }
        return correct;
    };

    const correctPremiseTypes = useCallback((data) => {
        if (!isEmpty(data.premiseType)) {
            return siteStore.premiseTypes.includes(data.premiseType);
        }
        return true;

    // siteStore.premiseTypes.length is needed for the unit test
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [siteStore.premiseTypes.length]);

    const getLocalOrRemote = async () => {
        await remoteConfig.fetchAndActivate();
        let config = {}
        const remoteFlags = remoteConfig.getAll();
        for (const [key, item] of Object.entries(remoteFlags)) {
            config[key] = JSON.parse(item.asString());
        }
        if (process.env.NODE_ENV === 'development') {
            const api = axios.create({baseURL: process.env.PUBLIC_URL});
            try {
                const response = await api['get']('/remoteConfig.json', {});
                config = Object.assign(config, response.data);
            } catch (e) {
                // ignore if doesn't exist
                console.warn(e);
            }
        }
        return config;
    }

    const isEnabled = (data) => {
        // todo: remove  isEmpty(data.enabled)  when all the flags have this value set
        return isEmpty(data.enabled) || data.enabled
    }

    const getConfig = useCallback(async () => {
        remoteConfig.defaultConfig = defaults;
        const remoteFlags = await getLocalOrRemote();
        const newFlags = {...defaults};
        for (const [key, config] of Object.entries(remoteFlags)) {
            newFlags[key] =
                !isEmpty(config) &&
                isEnabled(config) &&
                correctVersion(config) &&
                correctCountry(config) &&
                correctToggle(config) &&
                correctPremiseTypes(config) &&
                correctAddress(config);
        }

        setFlags(newFlags);
    }, [defaults, correctCountry, correctPremiseTypes])

    useEffect(() => {
        getConfig().catch(error => console.error(error));
    }, [getConfig]);

    return (
        <FlagsContext.Provider value={flags}>
            {children}
        </FlagsContext.Provider>
    );
});

const useFlags = () => {
    return useContext(FlagsContext);
}

export {FlagsProvider, useFlags};
