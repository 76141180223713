import React, {useCallback, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import RunReport from "./RunReport";
import CopyReport from "./CopyReport";
import DeleteReport from "./DeleteReport";
import EditReport from "./EditReport";
import MIActionMenu from "../../../components/Widgets/MIActionMenu";

const useStyles = makeStyles(theme => ({
    actionIcon: {
        fill: theme.palette.primary.main,
        marginRight: theme.sizes.small_gap,
        width: theme.sizes.large_gap,
    }
}));

const ExistingReportsActionMenu = (props) => {
    const classes = useStyles();
    const {params, setAlert, setIsShowing, getExistingReports} = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const closeActionsMenu = useCallback(() => {
        setAnchorEl(null);
    },[setAnchorEl]);

    const getMenuItems = (params) => {
        return [
            <RunReport key={0} className={classes.actionIcon} reportId = {params.row.id} setAlert={setAlert} setIsShowing={setIsShowing} closeActionsMenu={closeActionsMenu}/>,
            <EditReport key={'title_report_'+params.row.id} reportId={params.row.id} reportTitle={params.row.reportTitle} setAlert={setAlert} setIsShowing={setIsShowing}/>,
            <CopyReport key={'copy_report_'+params.row.id} reportId={params.row.id} reportTitle={params.row.reportTitle} setAlert={setAlert} setIsShowing={setIsShowing}/>,
            <DeleteReport key={'delete_report_'+params.row.id} reportId={params.row.id} getExistingReports={getExistingReports} setAlert={setAlert} setIsShowing={setIsShowing} closeActionsMenu={closeActionsMenu}/>,
        ];
    };

    return (
        <MIActionMenu
            menuItems={getMenuItems(params)}
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            closeActionsMenu={closeActionsMenu}
        />
    );
};

export default ExistingReportsActionMenu;