import {useState, useEffect, useCallback} from 'react';
import useApiRequest from "./requests/useApiRequest";
import {SUCCESS} from "./requests/requestReducer";

export const useDocumentFolders = () => {
    const [documentFolders, setDocumentFolders] = useState([]);
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/documentService/listDocuments';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            setDocumentFolders(response.data.DocumentList);
        }
    }, [responseStatus, response, setDocumentFolders]);

    const getDocumentFolders = useCallback((locale) => {
        const sortBy= 'AA';
        const url = endpoint + '/' + sortBy + '/' + locale;
        makeRequestWithParams(url).catch(error => {
            console.error('useDocumentFolders error: ', error);
        });
    }, [makeRequestWithParams]);

    return [documentFolders, responseStatus, getDocumentFolders];
};

