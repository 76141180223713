export const PENDING_STATUS = 'pending';
export const ERROR_STATUS = 'error';
export const FAILED_STATUS = 'failure';
export const LOCK_STATUS = 'LOCK_OUT';
export const LOGGED_IN_STATUS = 'logged_in';
export const LOGGED_OUT_STATUS = 'logged_out';
export const PASSWORD_EXPIRED = 'PASSWORD_EXPIRED';
export const PASSWORD_CHANGED = 'PASSWORD_CHANGED';
export const NO_SITE = 'no_site';
export const SUCCESS_STATUS = 'success';
export const DIRECT_LOGIN_STATUS = 'DIRECT_LOGIN_STATUS';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_INVALID = 'PASSWORD_INVALID';