import React, {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import MIButton from "../Widgets/MIButton";
import {isEmpty} from "../../utils/utils";
import {observer} from "mobx-react-lite";
import MIModal from "../Widgets/MIModal";
import MIRadioButton from "../Widgets/MIRadioButton";
import {useForm} from "react-hook-form";
import MITextField from "../Widgets/MITextField";
import {useGetCommentId} from "../../services/recommendations/useGetCommentId";
import {useUpdateRecommendation} from "../../services/recommendations/useUpdateRecommendation";
import {FETCHING, SUCCESS} from "../../services/requests/requestReducer";
import MIApiLoader from "../Widgets/MIApiLoader";
import MIMobileDialog from '../Widgets/mobile/MIMobileDialog';
import { ResponsiveComponent } from '../../services/useResponsive';
import { useAuthState } from '../../contexts/AuthProvider';

const useStyles = makeStyles(theme => ({
    dialogTitle: {
        fontFamily: theme.fontFamily.proximaMedium,
        color: theme.colors.mainText,
        display: 'inline',
    },
    dialogTitleContainer: {
        display: 'inline',
        '&:focus': theme.ariaFocus,
    },
    bodyContainer: {
        overflowY: 'auto',
        height: '35%',
        paddingLeft: theme.sizes.tiny_gap,
        marginLeft: ['-', theme.sizes.tiny_gap].join(''),
    },
    buttons: {
        float: 'right',
        display: 'inline-flex',
        marginTop: '10%',
    },
    cancelButton: {
        marginRight: theme.sizes.small_gap,
        border: 'none',
    },
    updateNotes: {
        marginTop: theme.sizes.micro_gap,
        width: '100%',
        height: 'fit-content'
    },
    statusBody: {
        display: 'grid',
    },
    updateButton: {
        width: '128px',
    }
}));

const LOG_STATUS = {
    NEW: 'NEW',
    PENDING: 'PENDING',
    COMPLETE: 'COMPLETE'
};

const PNOLSHUTDOWN = 'PNOLShutdown';

const UpdateStatusModal = observer((props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const { countryCode} = useAuthState();
    const {handleSubmit, control, formState: {errors}, register, getValues} = useForm();
    const updateStatusOptions = [
        {label: t(LOG_STATUS.NEW.toLowerCase()), value: LOG_STATUS.NEW},
        {label: t(LOG_STATUS.PENDING.toLowerCase()), value: LOG_STATUS.PENDING},
        {label: t(LOG_STATUS.COMPLETE.toLowerCase()), value: LOG_STATUS.COMPLETE}
    ];
    const {handleOnClick, isShowing, status, id, getInfo, PNOLShutdownCountries} = props;

    const [ commentId, responseStatusCommentId, getCommentId ] = useGetCommentId();
    const [ updateResponse, responseStatusUpdate, updateRecommendation ] = useUpdateRecommendation();

    useEffect(() => {
        if (!isEmpty(updateResponse)) {
            handleOnClick()
            getInfo()
        }
    }, [getInfo, updateResponse, handleOnClick]);

    useEffect(() => {
        if (!isEmpty(commentId)) {
            updateRecommendation(id, commentId, getValues('updateNotes'), status, getValues('newStatus'));
        }
        // the status value will change after the update but this api call should only be done after the onClick,
        // so do not add the status in the dep. list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, commentId, updateRecommendation, getValues]);

    const onSubmit = (values) => {
        if (PNOLShutdownCountries !== null || PNOLShutdownCountries !== undefined) {
            // if the current country is in PNOLShutdownCountries we do not have to call the getCommentId
            // only the updateRecommendation API

            let shutdownCountries = PNOLShutdownCountries;
            if (shutdownCountries instanceof String) {
                shutdownCountries = PNOLShutdownCountries.split(',');
            }
            if (!isEmpty(shutdownCountries) && shutdownCountries.includes(countryCode)) {
                updateRecommendation(id, PNOLSHUTDOWN, values.updateNotes, status, values.newStatus);
            } else {
                getCommentId(id, values.updateNotes, status, values.newStatus);
            }
        }
    };

    const getHeader = () => {
        return (
            <div className={classes.dialogTitleContainer} tabIndex={0} aria-label={t('update_status_dialog_title')}>
                <h2 className={classes.dialogTitle}>{t('update_status_dialog_title')}</h2>
            </div>
        );
    };

    const textBoxError = (error) => {
        return !isEmpty(error) && error.type === 'required' && t('notes_required');
    };

    const getApiStatus = useCallback(() => {
        if (responseStatusCommentId === FETCHING) {
            return FETCHING;
        }
        if (responseStatusCommentId === SUCCESS && (responseStatusUpdate === null || responseStatusUpdate === FETCHING)) {
            return FETCHING;
        }
        return responseStatusCommentId || responseStatusUpdate;

    }, [responseStatusCommentId, responseStatusUpdate]);

    const getBody = () => {
        return (
            <div className={classes.bodyContainer}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={classes.statusBody}>
                        <MIRadioButton
                            control={control}
                            ariaLabel={t('aria_label_status_radio_buttons')}
                            name='newStatus'
                            options={updateStatusOptions}
                            error={false}
                            defaultValue={status}
                        />
                        <MITextField
                            multiline
                            rows={8}
                            className={classes.updateNotes}
                            InputProps={{classes: {input: classes.updateNotes}}}
                            inputRef={register({
                                required: true,
                            })}
                            placeholder={t('add_notes')}
                            ariaLabel={t('aria_label_update_notes')}
                            name='updateNotes'
                            error={errors.updateNotes?.type === 'required'}
                            helperText={textBoxError(errors.updateNotes)}
                        />
                    </div>
                    <div className={classes.buttons}>
                        <MIButton
                            className={classes.cancelButton}
                            mi_size='small'
                            mi_type='secondary'
                            ariaLabel={t('aria_label_cancel_button')}
                            onClick={handleOnClick}
                        >
                            {t('update_status_dialog_cancel_button')}
                        </MIButton>
                        <div className={classes.updateButton}>
                            <MIApiLoader
                                customStyle={{
                                    spinnerWidth: '30px',
                                    spinnerHeight: '30px',
                                    marginTop: '2px',
                                    marginLeft: '2px',
                                    marginRight: '6px',
                                    marginBottom: '4px'
                                }}
                                MIComponent={<MIButton
                                    mi_size='small'
                                    mi_type='primary'
                                    ariaLabel={t('aria_label_update_status_button')}
                                    type='submit'
                                >
                                    {t('update_status_dialog_update_button')}
                                </MIButton>}
                                responseStatus={getApiStatus()}
                                ariaLabelProgressBar={t('aria_label_progressbar_update_status_button')}
                            />
                        </div>
                    </div>
                </form>
            </div>

        );
    };
    return (
        <ResponsiveComponent
            components={{
                mobile:
                    <MIMobileDialog
                        title={t('update_status_dialog_title')}
                        content={getBody()}
                        open={isShowing}
                        handleClose={handleOnClick}
                        customStyle={{'containerWidth': '285px'}}/>,
                desktop:
                    <MIModal
                        header={getHeader()}
                        body={getBody()}
                        isShowing={isShowing}
                        onClose={handleOnClick}
                        customStyle={{'rootHeight': 'fit-content', 'rootWidth':'517px'}}
                    />
            }}
        />
    );
});

export default UpdateStatusModal;
