import { useEffect, useState, useCallback } from 'react';
import { SUCCESS } from '../requests/requestReducer';
import { useContractStore } from '../../contexts/StateProvider/StateProvider';
import { isEmpty } from '../../utils/utils';
import useRegionalGCPApiRequest from '../requests/useRegionalGCPApiRequest';


export const useExtraContractDetails = () => {
    const contractStore = useContractStore();
    const [ currentId, setCurrentId ] = useState(null);
    const [ extraContractDetails, setExtraContractDetails ] = useState(null);
    const [ { responseStatus, response }, makeRegionalGCPRequestWithParams ] = useRegionalGCPApiRequest();
    const endpoint = '/contractService/extraContractDetails';

    useEffect(() => {
        if (responseStatus === SUCCESS && !isEmpty(currentId)) {
            try {
                contractStore.setExtraContractDetails(currentId, response.data);
                setExtraContractDetails(response.data);
            } catch (error) {
                console.error('useExtraContractDetails error: ', error);
                contractStore.setExtraContractDetails(currentId, null);
                setExtraContractDetails(null);
            }
        }
    }, [ responseStatus, response, setExtraContractDetails, contractStore, currentId ]);


    const getExtraContractDetails = useCallback((contractNumber, countryCode) => {
        const storedExtraContractDetails = contractStore.getExtraContractDetails(contractNumber);

        if (!isEmpty(storedExtraContractDetails)) {
            setExtraContractDetails(storedExtraContractDetails);
        } else {
            setExtraContractDetails(null);
            const url = [ endpoint, countryCode, contractNumber ].join('/');
            makeRegionalGCPRequestWithParams(url).catch((error) => {
                console.error('useExtraContractDetails error: ', error);
            });

            setCurrentId(contractNumber);
        }
    }, [ makeRegionalGCPRequestWithParams, contractStore ]);


    return [ extraContractDetails, extraContractDetails !== null ? SUCCESS : responseStatus, getExtraContractDetails ];
};
