import {useStateWithSessionStorage} from "../utils/localStorage";

const useModalSessionStorage = (initialState, key) => {
  const [isShowing, setIsShowing] = useStateWithSessionStorage(key, initialState);

  function changeStatus() {
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    changeStatus,
  }
};

export default useModalSessionStorage;