import React from "react";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

export const useResponsive = () => {
    const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'));
    const isDesktop = !isMobile;

    const getStyles = (styles) => {
        return isMobile ? styles.mobile : styles.desktop;
    };

    return {isDesktop, isMobile, getStyles};
};

export const ResponsiveComponent = (props) => {
    const {isDesktop, isMobile} = useResponsive();
    const {mobile, desktop} = props.components;
    return (
        <>
            {isMobile && mobile}
            {isDesktop && desktop}
        </>
    );
};