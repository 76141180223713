import React from 'react';
import { alpha } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as HighlightIcon } from '../assets/Highlight.svg';
import {isEmpty} from "../utils/utils";

const DEFAULT_SIZE = 51;
const SHADOW_HEIGHT = 1;

const useStyles = makeStyles((theme) => ({
    selected: {
        position: 'absolute',
        width: (props) => props.highlightSize * 2,
        height: (props) => props.highlightSize * 2,
        color: alpha(theme.palette.primary.main, 0.4),
    },
}));

const SelectionHighlight = (props) => {
    let highlightSize = DEFAULT_SIZE;
    let adjustment = { width: 0, height: -SHADOW_HEIGHT };
    if (!isEmpty(props.size)) {
        highlightSize = props.size.width;
        if (props.size.width > props.size.height) {
            adjustment.width = (props.size.width - props.size.height) / 2;
        } else if (props.size.height > props.size.width) {
            adjustment.height = (props.size.height - props.size.width) / 2;
        }
    }
    const classes = useStyles({highlightSize: highlightSize, ...props});

    const selectedOffset = {
        x: -(highlightSize / 2) + adjustment.width,
        y: -(highlightSize / 2) + adjustment.height,
    };

    return <HighlightIcon className={classes.selected} style={{ left: selectedOffset.x, top: selectedOffset.y }} />;
};

export default SelectionHighlight;
