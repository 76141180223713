import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { FETCHING } from '../../../services/requests/requestReducer';
import { mergeStyles } from '../../../utils/utils';

const useStyles = makeStyles((theme) => {
    return {
        title: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.fontFamily.proximaBold,
            marginBottom: theme.sizes.small_gap,
        },
        card: {
            borderRadius: theme.sizes.pico_gap,
            width: (props) => {
                return props.cardWidth ? props.cardWidth : 210;
            },
        },
        contentTop: {
            background: theme.palette.primary.main,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.contrastText,
            paddingTop: theme.sizes.medium_gap,
            paddingBottom: theme.sizes.micro_gap,
        },
        noData: {
            background: theme.palette.refillLevels.noData,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: theme.palette.text.contrastText,
            paddingTop: theme.sizes.medium_gap,
            paddingBottom: theme.sizes.micro_gap,
        },
        contentLower: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: theme.sizes.micro_gap,
        },
        cardTitle: {
            fontSize: theme.typography.h4.fontSize,
            fontFamily: theme.fontFamily.proximaBold,
            marginBottom: theme.sizes.tiny_gap,
        },
        cardIcon: {
            width: '48px',
            height: '48px',
            marginBottom: theme.sizes.micro_gap,
        },
        topDetailContainer: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
        },
        topDetailContainerEmpty: {
            padding: theme.sizes.empty_benefit_card,
        },
        topDetail: {
            display: 'flex',
            flexDirection: (props) => {
                return props.cardFlexDirection ? props.cardFlexDirection : 'row';
            },
        },
        topDetailStart: {
            flexGrow: 1,
        },
        contentLabel: {
            display: 'inline-flex',
            fontFamily: theme.fontFamily.proximaBold,
            marginInlineEnd: theme.sizes.nano_gap,
            fontSize: theme.fontSizes.mobileBody,
        },
        topValue: {
            display: 'inline-flex',
            fontSize: theme.fontSizes.mobileBody,
        },
        saving: {
            fontFamily: theme.fontFamily.proximaBold,
            fontSize: theme.typography.h4.fontSize,
            color: theme.colors.savedValue,
            lineHeight: '8px',
            marginTop: theme.sizes.nano_gap,
            minHeight: theme.heights.benefit_card_min_height
        },
        bluContentRow: {
            display: 'inline-flex',
        },
        bluLabel: {
            fontFamily: theme.fontFamily.proximaBold,
            fontSize: theme.fontSizes.subHeading,
        },
        bluValue: {
            fontFamily: theme.fontFamily.proximaMedium,
            fontSize: theme.fontSizes.subHeading,
            marginLeft: 'auto',
        },
        bluContent: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
        }
    };
});

const BenefitCard = (props) => {
    const { t } = useTranslation();
    const { title, icon, used, units, saved, className, apiStatus, customStyles, isBlu } = props;
    const classes = useStyles(customStyles);

    const getTopDetailContainer = () => {
        return !isBlu ?
            <div className={classes.topDetailContainer}>
                <span className={mergeStyles(classes.topDetailStart, classes.topDetail)}>
                    <Typography className={classes.contentLabel}>
                        {t('used')}:
                    </Typography>
                    <Typography className={classes.topValue}>
                        {apiStatus === FETCHING ? '--' : used}
                    </Typography>
                </span>
                <span className={classes.topDetail}>
                    <Typography className={classes.contentLabel}>
                        {t('units')}:
                    </Typography>
                    <Typography className={classes.topValue}>
                        {apiStatus === FETCHING ? '--' : units}
                    </Typography>
                </span>
            </div> :
            <div className={classes.topDetailContainerEmpty}>
            </div>;
    };

    const getLowerContent = () => {
        return !isBlu ?
            <div>
                <Typography className={classes.contentLabel}>
                    {t('saved_volume')}
                </Typography>
                <Typography className={classes.saving}>
                    {apiStatus === FETCHING ? '--' : saved}
                </Typography>
            </div> :
            <div className={classes.bluContent}>
                <span className={classes.bluContentRow}>
                    <Typography className={classes.bluLabel}>
                        {t('used')}:
                    </Typography>
                    <Typography className={classes.bluValue}>
                        {apiStatus === FETCHING ? '--' : used}
                    </Typography>
                </span>
                <span className={classes.bluContentRow}>
                    <Typography className={classes.bluLabel}>
                        {t('units')}:
                    </Typography>
                    <Typography className={classes.bluValue}>
                        {apiStatus === FETCHING ? '--' : units}
                    </Typography>
                </span>
            </div>;
    };

    return (
        <div className={className}>
            <Card className={classes.card}>
                <CardContent
                    className={ used?.charAt(0) === '0' && units?.charAt(0) === '0' && saved?.charAt(0) === '0' ?
                        classes.noData :
                        classes.contentTop}>
                    <Typography className={classes.cardTitle}>
                        {title}
                    </Typography>
                    {icon}
                    {getTopDetailContainer()}
                </CardContent>
                <CardContent
                    className={classes.contentLower}
                >
                    {getLowerContent()}
                </CardContent>
            </Card>
        </div>
    );
};

export default BenefitCard;
