import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import {setDocumentTitle} from '../../../utils/utils';
import {observer} from 'mobx-react-lite';
import RecommendationDetails from '../../../components/Recommendations/RecommendationDetails';
import { useResponsive } from '../../../services/useResponsive';

const desktopStyles = theme => {
    return {
        root: {
            width: '100%',
            maxWidth: '928px',
            minHeight: '600px',
            marginTop: theme.sizes.large_gap,
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: theme.sizes.large_gap,
            paddingBottom: theme.sizes.large_gap,
            paddingLeft: theme.sizes.small_gap,
            paddingRight: theme.sizes.small_gap,
            fontFamily: theme.typography.fontFamily,
            marginBottom: theme.sizes.xlarge_gap,
        },
    }
};

const mobileStyles = theme => {
    const baseStyle = desktopStyles(theme);

    return Object.assign(baseStyle, {
        root: {
            width: '100vw',
            minHeight: ['calc(100vh - ', theme.heights.top_banner, ')'].join(''),
            paddingTop: theme.sizes.small_gap,
            paddingBottom: theme.sizes.mega_gap,
        },
    })
};

const RecommendationDetailsPage = observer((props) => {
    const {t} = useTranslation();
    const {getStyles} = useResponsive();
    const useStyles = makeStyles(theme => (
        getStyles({
            desktop: desktopStyles(theme),
            mobile: mobileStyles(theme)
        })
    ));
    const classes = useStyles();

    useEffect(() => {
        setDocumentTitle(t, 'nav_recommendation_details');
    }, [t]);

    useEffect(() => {
        if(window && window.scrollTo){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, []);

    return (
        <Paper className={classes.root} aria-label={t('aria_label_recommendation_details_page')}>
            <RecommendationDetails {...props} />
        </Paper>
    );
});

export default RecommendationDetailsPage;
