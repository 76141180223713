import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import OpenReport from "./OpenReport";
import {EDIT_REPORT} from "../../../services/useSaveReport";
import {useTranslation} from "react-i18next";
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
    actionIcon: {
        fill: theme.palette.primary.main,
        width: theme.sizes.large_gap,
        cursor: 'pointer',
        marginRight: '5px',
    },
    actionIconOuter: {
        alignItems: 'center',
        display: 'flex',
        '&:focus': theme.ariaFocus,
    },
}));


const EditReport = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <OpenReport component={
            <div
                className={classes.actionIconOuter}
                tabIndex={0}
            >
                <EditIcon
                    data-testid={'edit_icon'}
                    className={classes.actionIcon}
                />
                {t('edit_report')}
            </div>
        }
            ariaLabel={t('aria_label_report_edit_button')}
            ariaLabelProgressBar={t('aria_label_progressbar_edit_report')}
            action={EDIT_REPORT}
            {...props}
        />

    );
};

export default EditReport;