import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import MIGrid from '../../../components/Widgets/MIGrid';
import { isEmpty } from '../../../utils/utils';
import { FETCHING } from '../../../services/requests/requestReducer';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Grid } from '@material-ui/core';
import EntitlementProductDetailsTable from '../../../components/EntitlementsTables/EntitlementProductDetailsTable';
import { HEADER_AND_FOOTER_PRODUCT_TABLE } from '../../../components/EntitlementsTables/EntitlementProductDetailsTable';
import { mergeStyles } from '../../../rentokil-react-ui/src/utils/utils';

const DEFAULT_ROW_HEIGHT = 64;
const HEADER_AND_FOOTER = 64;
const PAGINATION_SIZE = 56;
const PAGE_SIZE = 10;

const useStyles = makeStyles((theme) => {
    return {
        // Some styles are added as inline styles. You have to use !important to override these styles
        gridContainer: {
            '& .MuiDataGrid-renderingZone': {
                maxHeight: 'none !important',
                minHeight: '0px',
                height: 'unset'
            },
            '& .MuiDataGrid-cell': {
                lineHeight: 'unset !important',
                maxHeight: 'none !important',
                whiteSpace: 'normal',
                minHeight: '0px',
                height: 'unset',
                display: 'flex',
                alignItems: 'center'
            },
            '& .MuiDataGrid-row': {
                maxHeight: 'none !important',
                minHeight: '0px',
                height: 'unset'
            },
            '& .MuiDataGrid-row.Mui-selected':{
                '&:hover': {
                    background: `${theme.colors.selectedRowDefault} !important`
                }
            },
            '& div.MuiDataGrid-cell': {
                height: 'unset'
            }
        },
        row: {
            height: `${DEFAULT_ROW_HEIGHT}px`
        },
        rowContainer: {
            display: 'flex',
            width: '900px'
        },
        productTableContainer: {
            'width': '100%',
            'borderTop': `solid 1px ${theme.palette.grey['400']}`,
            'paddingLeft': theme.sizes.micro_gap,
            'paddingRight': theme.sizes.micro_gap,
            'paddingTop': theme.sizes.micro_gap,

            '& div.MuiDataGrid-row': {
                maxHeight: 'none !important',
                minHeight: '0px',
                height: 'unset',
                backgroundColor: theme.palette.background.white,
                borderBottom: `1px solid ${ theme.palette.grey['300']}`,
            },
        },
        tableRow: {
            display: 'flex',
            marginLeft: theme.sizes.xsmall_gap,
            marginRight: theme.sizes.xsmall_gap,
            height: `${DEFAULT_ROW_HEIGHT}px`,
            alignItems: 'center'
        },
        expandedRow: {
            display: 'flex',
            flexDirection: 'column'
        }
    };
});

const EntitlementsTable = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { entitlements, responseStatusEntitlements } = props;

    const [ rows, setRows ] = useState([]);
    const [ openRow, setOpenRow ] = useState();
    const [ tableHeight, setTableHeight ] = useState();
    const [ productTableHeight, setProductTableHeight ] = useState(`${HEADER_AND_FOOTER_PRODUCT_TABLE}px`);

    useEffect(() => {
        const rows = [];
        // If the entitlements change (i.e the site was changed) then close the open row
        setOpenRow();
        if(!isEmpty(entitlements)) {
            entitlements.forEach((record, index) => {
                rows.push({
                    id: index,
                    product: record.ProductDesc,
                    productCode:record.ProductCode,
                    contractStartDate: record.ContractStartDate,
                    contractAnniversaryDate: record.ContractAnniversaryDate,
                    annualQuantity: record.AnnualQuantity,
                    visitsAnnum: record.AnnualVisits,
                    quantityYTD: record.QuantityYTD,
                    balance: record.Balance
                });
            });
        }
        setRows(rows);
    }, [ entitlements ]);

    const closeRow = () => {
        setOpenRow(null);
    };

    const expandRow = (params) => {
        setOpenRow(params.rowIndex);
    };

    // We need the table to have only one column, but the illusion of many. This means when we can have a full width row.
    // To achieve this, this function takes 8 values and returns a consistent grid
    const getConsistentGrid = (gridValues, tableRow, showDetails, rowData) => {
        // Material UI v4.12.3 does not support increasing the number of columns in a grid. There has to be 12 columns
        // For more granular control of the sizes of the columns, I have used two grids and split the items amongst them
        const gridSizes = [ [ 1, 2, 5, 4 ], [ 3, 3, 3, 3 ] ];
        return (
            <div className={classes.expandedRow}>
                <div className={mergeStyles(classes.rowContainer, tableRow ? classes.tableRow : null)}>
                    {gridSizes.map((sizeItem, sizeIndex) => {
                        return <Grid key={sizeIndex} container spacing={3} direction="row" className={classes.rowSection}>
                            {sizeItem.map((item, index) => {
                                return <Grid className={classes.row} key={index} item xs={!isEmpty(item) ? item : 1}>
                                    <div>{gridValues[index + sizeIndex * sizeItem.length]}</div>
                                </Grid>;
                            })}
                        </Grid>;
                    })}
                </div>
                { showDetails && <div className={classes.productTableContainer}>
                    <EntitlementProductDetailsTable
                        productCode={rowData.productCode}
                        contractAnniversaryDate={rowData.contractAnniversaryDate}
                        annualQuantity={rowData.annualQuantity}
                        setProductTableHeight={setProductTableHeight}/>
                </div> }
            </div>

        );
    };

    const getActivityRow = (params) => {
        const rowData = params.row;
        const tableData = [ rowData.productCode,
            rowData.product,
            rowData.contractAnniversaryDate,
            rowData.annualQuantity,
            rowData.visitsAnnum,
            rowData.quantityYTD,
            rowData.balance,
        ];
        const tableDataParsed = tableData.map((item) => {
            if (isEmpty(item)) {
                return '-';
            }
            return item;
        });
        return (
            <div>
                {getConsistentGrid([ openRow === params.rowIndex ? <ExpandMore onClick={() => {
                    closeRow(params);
                }}/> : <ChevronRight onClick={() => {
                    expandRow(params);
                }}/>,
                // Pass the product code in again separately for the EntitlementProductDetailsTable
                ...tableDataParsed ], true, openRow === params.rowIndex, rowData)}
            </div>
        );
    };
    // In the table the first column has no heading because it's for the dropdown icon
    const headings = [ '', t('code'), t('product'), t('contract_anniversary_date'), t('annual_quantity_short'), t('entitlements_visit_per_year'), t('entitlements_visits_per_year'), t('entitlements_balance') ];

    const columns = [
        {
            field: 'entitlements',
            type: 'string',
            description: t('entitlements'),
            headerName: <div>{getConsistentGrid(headings)}</div>,
            resizable: false,
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return getActivityRow(params);
            }
        },
    ];

    useEffect(() => {
        const calcTableHeight = () => {
            let tableHeight = 0;
            if (!isEmpty(rows)) {
                const rowsDisplaying = rows.length > PAGE_SIZE ? PAGE_SIZE : rows.length;
                tableHeight = rowsDisplaying * DEFAULT_ROW_HEIGHT;
                if (!isEmpty(openRow)) {
                    tableHeight = tableHeight + productTableHeight;
                }
            } else {
                // The "No product" row adds extra height
                tableHeight = tableHeight + DEFAULT_ROW_HEIGHT;
            }
            tableHeight = tableHeight + HEADER_AND_FOOTER + PAGINATION_SIZE;
            return tableHeight;
        };
        setTableHeight(calcTableHeight());
    }, [ rows, openRow, productTableHeight ]);

    return (
        <div className={classes.gridContainer}>
            <MIGrid
                columns={columns}
                rows={rows}
                noElementsText={t('no_product')}
                ariaLabel={t('aria_label_entitlements_table')}
                height={tableHeight}
                pagination={true}
                hideFooterSelectedRowCount={true}
                isLoading={responseStatusEntitlements === null || responseStatusEntitlements === FETCHING}
                noDataGridPadding={true}
                {...props}
            />
        </div>
    );
};

export default EntitlementsTable;
