import { useState, useEffect, useCallback } from 'react';
import useApiRequest from './requests/useApiRequest';
import { SUCCESS } from './requests/requestReducer';

export const useRunReport = () => {
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/reportService/runReport';
    const [ runStatus, setRunStatus ] = useState('');


    const runReport = useCallback((reportId, countryCode, locale) => {
        // reset the status before we make the API call so that the caller can detect a change if the response is the same
        setRunStatus();
        const url = [ endpoint, reportId, countryCode, locale ].join('/');
        makeRequestWithParams(url).catch((error) => {
            console.error('RunReport error: ', error);
        });
    }, [ makeRequestWithParams ]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            setRunStatus(response.data.status);
        }
    }, [ responseStatus, response, setRunStatus ]);


    return [ runStatus, responseStatus, runReport ];
};
