import { useCallback, useEffect, useState } from 'react';
import useRegionalGCPApiRequest from './requests/useRegionalGCPApiRequest';
import { SUCCESS } from './requests/requestReducer';
import { useSiteStore } from '../contexts/StateProvider/StateProvider';
import { isEmpty } from '../utils/utils';
import { useAuthState } from '../contexts/AuthProvider';

export const useSiteInfo = () => {
    const siteStore = useSiteStore();
    const [ currentSiteKey, setCurrentSiteKey ] = useState(null);
    const [ info, setInfo ] = useState(null);
    const [ { responseStatus, response }, makeRequestWithParams ] = useRegionalGCPApiRequest();
    const { countryCode } = useAuthState();

    const endpoint = '/contractService/premiseOverview';

    useEffect(() => {
        if (responseStatus === SUCCESS && !isEmpty(currentSiteKey)) {
            try {
                const premiseOverviewData = !isEmpty(response.data) && !isEmpty(response.data.premiseOverview) ?
                    response.data.premiseOverview[0] : [];
                siteStore.setSiteInfo(currentSiteKey, premiseOverviewData);
                setInfo(premiseOverviewData);
            } catch (error) {
                console.error('useSiteInfo error: ', error);
                setInfo(null);
            }
        }
    }, [ responseStatus, response, setInfo, siteStore, currentSiteKey ]);

    const getSiteInfo = useCallback((contractNumber, premiseNumber) => {
        const siteKey = siteStore.getSiteKey(contractNumber, premiseNumber);
        const storedSiteInfo = siteStore.getSiteInfo(siteKey);
        if (!isEmpty(storedSiteInfo)) {
            setInfo(storedSiteInfo);
        } else {
            setInfo(null);
            setCurrentSiteKey(siteKey);
            const url = [ endpoint, countryCode, contractNumber, premiseNumber ].join('/');
            makeRequestWithParams(url).catch((error) => {
                console.error('getSiteInfo error: ', error);
            });
        }
    }, [ makeRequestWithParams, siteStore, countryCode ]);

    return [ info, (info !== null) ? SUCCESS : responseStatus, getSiteInfo ];
};
