import { useState, useEffect, useCallback, useRef } from 'react';
import useApiRequest from '../requests/useApiRequest';
import { FETCHING, SUCCESS } from '../requests/requestReducer';
import { isEmpty } from '../../utils/utils';

const PAGE_LIMIT = 50;

export const useWasteConsignmentPortugal = () => {
    const [ wasteConsignment, setWasteConsignment ] = useState(null);
    const [ { responseStatus, response }, makeRequestWithParams ] = useApiRequest();
    const endpoint = '/hazardWasteService/hazardWastePortugal';
    const downloadCount = useRef(0);
    const total = useRef(0);
    const wasteConsignmentPartial = useRef([]);
    const [ params, setParams ] = useState({});

    const getDeviceHistoryPage = useCallback((contractId, premiseId, year, start, limit) => {
        const url = [ endpoint, contractId, premiseId, year, start, limit ].join('/');
        makeRequestWithParams(url).catch((error) => {
            console.error('useWasteConsignmentPortugal makeRequest: ', error);
        });
    }, [ makeRequestWithParams ]);

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                if (!isEmpty(response.data)) {
                    downloadCount.current = downloadCount.current + response.data.result.items.length;
                    total.current = response.data.result.totalItems;
                    wasteConsignmentPartial.current.push(...response.data.result.items);

                    if (downloadCount.current < total.current) {
                        getDeviceHistoryPage(params.contractId, params.premiseId, params.year, downloadCount.current, PAGE_LIMIT);
                    } else if (downloadCount.current >= total.current) {
                        setWasteConsignment(wasteConsignmentPartial.current);
                    }
                }
            } catch (error) {
                console.error('useWasteConsignmentPortugal error: ', error);
                setWasteConsignment(null);
            }
        }
    }, [ responseStatus, response, setWasteConsignment, getDeviceHistoryPage, params ]);


    const getWasteConsignmentPortugal = useCallback((contractId, premiseId, year) => {
        setParams({
            contractId: contractId,
            premiseId: premiseId,
            year: year
        });
        total.current = 0;
        downloadCount.current = 0;
        wasteConsignmentPartial.current = [];

        getDeviceHistoryPage(contractId, premiseId, year, 0, PAGE_LIMIT);
    }, [ getDeviceHistoryPage, setParams ]);

    const getStatus = useCallback(() => {
        let status = responseStatus;
        if (responseStatus === SUCCESS) {
            status = downloadCount.current < total.current ? FETCHING : SUCCESS;
        }
        return status;
    }, [ responseStatus ]);


    return [ wasteConsignment, getStatus(), getWasteConsignmentPortugal ];
};
