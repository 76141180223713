import {useState, useEffect, useCallback} from 'react';
import useApiRequest from './requests/useApiRequest';
import {SUCCESS} from './requests/requestReducer';

export const useGetInvoiceURL = () => {
    const [invoiceURL, setInvoiceURL] = useState('');
    const [{responseStatus, response}, makeRequestWithParams] = useApiRequest();
    const endpoint = '/premiseService/getInvoiceURL';

    useEffect(() => {
        if (responseStatus === SUCCESS) {
            try {
                setInvoiceURL(response.data.url);
            } catch (error) {
                console.error('useGetInvoiceURL error: ', error);
            }
        }
    }, [responseStatus, response, setInvoiceURL]);

    const getInvoiceURL = useCallback((emailAddress, countryCode, accountNumber) => {
        const params = {
            emailID: emailAddress,
            portal: 'I',
            countryCode: countryCode,
            accountNumber: accountNumber,
        }
        makeRequestWithParams(endpoint, 'post', params).catch(error => {console.error('useGetInvoiceURL makeRequest: ', error)});
    },[makeRequestWithParams]);

    return [invoiceURL, responseStatus, getInvoiceURL];
};
